window.sankeyDataInit = {
    data: undefined,
    width: undefined,
    height: undefined,
    json: undefined,
    cluster: undefined,
    diagonal: undefined,
    svg: undefined,
    nodes: undefined,
    link: undefined,
    node: undefined,
    isConnectorsLines: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    stopFlag: false,
    wordsListReset: true,
    wordsListColorChange: false,
    unicItems: {},
    unicDates: {},
    allDataKeys: {},
    timeFormatter: undefined,
    margin: { top: 20, right: 20, bottom: 10, left: 50 },
    x: undefined,
    y: undefined,
    chartAreaWidth: undefined,
    chartAreaHeight: undefined,
    zoom: undefined,
    colorsArr: undefined,
    colorsDrawArr: undefined,
    dashStyles: undefined,
    sourceTitle: undefined,
    dateFrom: undefined,
    dateTo:undefined,
    addData: undefined,
    seriesData: [],
    seriesDates: [],
    plotBands: [],
    customWordsColors: {},
    changedColors: {}
};
window.sankeyData = {};
window.sankeyData[0] = window.sankeyDataInit;
var isTitle = true;
var isChartMenu = false;
var dataArrLength;
var htmlContent = '';
var isInverted = false;

if (typeof chartIndex === "undefined") {
    var chartIndex = 0;
}

var chartSankeyHC;

//CHART_TYPE_SANKEY_NEIGHBORS1
//curDataChartSK
/**
 * Show Sankey Chart
 * @param data
 * @param formdata
 * @param chartIndex
 * @returns
 */      
function showSankeyChart(dataChart, formdata, chartIndex) {
    console.log('line start showSankeyChart');
    console.time('line');
    $('#dataContaner').html($('#chartSankeyDiv').html());
    
    isTitle = formdata.checkboxShowHideTitleValue;
    isChartMenu = formdata.checkboxShowHideMenuValue;
    isInverted = formdata.checkboxOrientationHorizontalVertical;
    
    if (typeof dataChart.result === "string") {
        var resData  = JSON.parse(dataChart.result);
    } else {
        var resData  = dataChart.result;
    }

    //var categoriesDates = resDataAll.categories;

    isChartMenu = formdata.checkboxShowHideMenuValue;
    
    var colors = Highcharts.getOptions().colors;
    
    var md5Arr = [];
    var wordsList = dataChart.wordsList;
    if (typeof chartIndex === "undefined") {
        var chartIndex = 0;
    }
    if (typeof dataChart.dates !== "undefined") {
        window.sankeyData[chartIndex].dates = dataChart.dates;
    } else {
        window.sankeyData[chartIndex].dates = [];
    }
    
    initGetColors(CHART_TYPE_SANKEY_NEIGHBORS1, chartIndex);

    var wordsListArr = getWordsListSK(resData);
    wordsListArr = wordsListArr.sort();
   
    if (typeof formdata.dropdownColorsSet === "undefined") {
        formdata.dropdownColorsSet = 0;
    }
    window.sankeyData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
    
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.sankeyData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.sankeyData[chartIndex].dropdownColorsSelection = 1;
    }
    
    if (formdata.dropdownColorsSet == 0) {
        if (wordsListArr.length <= 8) {
            window.colorsArraySetIndex = 6;
        } else if (wordsListArr.length <= 16) {
            window.colorsArraySetIndex = 7;
        } else if (wordsListArr.length <= 24) {
            window.colorsArraySetIndex = 8;
        } else if (wordsListArr.length <= 32) {
            window.colorsArraySetIndex = 9;
        } else {
            window.colorsArraySetIndex = 1;
        }
    } else {
        window.sankeyData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    }

    var wordsListObj = [];
    for (var i = 0; i < wordsListArr.length; i++) {
        var md5 = CryptoJS.MD5(wordsListArr[i]).toString();
        var curColor = getColor(hexToDecColor(md5), undefined, undefined, md5, wordsListArr[i],
        i, formdata);
        wordsListObj.push({'name': wordsListArr[i], 'md5': md5, 'color': curColor});
    }

    if ((formdata.dropdownColorsSet == 0) && (window.sankeyData[chartIndex].dropdownColorsSelection == 1)) {
        var wordsListColorsAuto = getAutoUnicColorsForWordsList(wordsListArr, CHART_TYPE_SANKEY_NEIGHBORS1, chartIndex, undefined, undefined, undefined);
    }

    if (Object.keys(window.sankeyData[chartIndex].wordsList).length == 0) {
        window.sankeyData[chartIndex].wordsListReset = true;
    }
    if (window.sankeyData[chartIndex].wordsListReset == true
        || typeof window.sankeyData[chartIndex].colorsArr === "undefined"
        || window.sankeyData[chartIndex].colorsArr.length == 0) {
        window.sankeyData[chartIndex].colorsArr = [];
        window.sankeyData[chartIndex].colorsDrawArr = [];
    }
    if (Object.keys(window.sankeyData[chartIndex].wordsList).length == 0) {
        window.sankeyData[chartIndex].wordsListReset = true;
    }

    if (Object.keys(window.sankeyData[chartIndex].wordsList).length != wordsListArr.length) {
        window.sankeyData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    if (window.sankeyData[chartIndex].wordsListReset == true) {
        window.sankeyData[chartIndex].wordsListFirst = {};
        window.sankeyData[chartIndex].wordsList = {};
    }
    if (typeof formdata.wordsList !== "undefined") {
        window.sankeyData[chartIndex].wordsListSelected = formdata.wordsList;
    }

    var lineWidthMult = 1;
    if (typeof formdata.lineWidthMult !== "undefined") {
        if (formdata.lineWidthMult == 0) {
            formdata.lineWidthMult = 0;
        }
        formdata.lineWidthMult = formdata.lineWidthMult * 2;
        lineWidthMult = formdata.lineWidthMult;
        window.sankeyData[chartIndex].lineWidthMult = formdata.lineWidthMult;
    }
    
    $.each(resData.nodes, function(index, item) {
        md5Arr.push({'md5': CryptoJS.MD5(item.name).toString(), 'name': item.name});
    });
    var nodesCnt = resData.nodes.length;
     
    curDataChartSK = dataChart;

    var fontSizeMult = 1;
    if (typeof formdata.fontSizeMult !== "undefined") {
        if (formdata.fontSizeMult == 0) {
            formdata.fontSizeMult = 0.1;
        }
        fontSizeMult = formdata.fontSizeMult * 1;
    }
    window.dendrogramHorizontalData[chartIndex].fontSizeMult = fontSizeMult;

    var fontSize = 11;
    if (typeof formdata.nubmberBoxValueFontSize !== "undefined") {
        fontSize = formdata.nubmberBoxValueFontSize;
    }
    if (fontSize == '') {
        fontSize = 11;
    }
    window.sankeyData[chartIndex].fontSize = fontSize;

    var addData = dataChart.addData;
    if (typeof addData !== "undefined") {
        addData = JSON.parse(addData);
        window.sankeyData[chartIndex].addData = addData;
    } else {
        addData = window.sankeyData[chartIndex].addData;
        if (typeof addData == "undefined") {
            addData = {};
        }
    }
    
    if (Object.keys(md5Arr).length == 0) {
        var md5ArrIsEmpty = true;
    } else {
        var md5ArrIsEmpty = false;
    }

     var colorsArr = [];
    var curDataChartCsv = '';
    var i = 0;
    var datesArr = [];
    var resData0 = [];
    var resDataVal = [];
    var emptyDataCnt = 0;
    var noEmptyDataCnt = 0;

    $.each(resData.data, function(index1, item1) {
        //resData.data[index1].color = '#9933FF';
        curDataChartCsv = curDataChartCsv + item1 + ',';
        var dateArr = resData.data[index1][0].split('::');
        if (item1[2] == 0) {
            emptyDataCnt++;
            resDataVal.push({'from': item1[0], 'to': item1[1], 'weight':item1[2], 'arr': item1[3], 'str': item1[4], 'id': 'empty'});
        } else {
            noEmptyDataCnt++;
            resDataVal.push({'from': item1[0], 'to': item1[1], 'weight':item1[2], 'arr': item1[3], 'str': item1[4], 'id': item1[4]});
        }

        if (resData.data[index1][2] == 0) {
            //resData.data[index1][5] = 'empty';
            if (typeof datesArr[dateArr[0]] !== "undefined") {
                datesArr[dateArr[0]].val++;
                if (typeof datesArr[dateArr[0]].arr0 == "undefined") {
                    datesArr[dateArr[0]].arr0 = [];
                }
                datesArr[dateArr[0]].arr0.push(resData.data[index1]);
            } else {
                datesArr[dateArr[0]] = {};
                datesArr[dateArr[0]].val = 1;
                if (typeof datesArr[dateArr[0]].arr0 == "undefined") {
                    datesArr[dateArr[0]].arr0 = [];
                }
                datesArr[dateArr[0]].arr0.push(resData.data[index1]);
            }
            //console.log(Math.ceil(datesArr[dateArr[0]].val/2));
            datesArr[dateArr[0]].dIndex = Math.ceil(datesArr[dateArr[0]].val/2);
        } else {
            //resData.data[index1][5] = (index1);
            if (typeof datesArr[dateArr[0]] !== "undefined") {
                if (typeof datesArr[dateArr[0]].arrVal == "undefined") {
                    datesArr[dateArr[0]].arrVal = [];
                }
                datesArr[dateArr[0]].arrVal.push(resData.data[index1]);
            } else {
                datesArr[dateArr[0]] = [];
                if (typeof datesArr[dateArr[0]].arrVal == "undefined") {
                    datesArr[dateArr[0]].arrVal = [];
                }
                datesArr[dateArr[0]].arrVal.push(resData.data[index1]);
            }
        }
        i++;
    });
    /*
    function compare( a, b ) {
        if ( a.last_nom < b.last_nom ){
          return -1;
        }
        if ( a.last_nom > b.last_nom ){
          return 1;
        }
        return 0;
      }
      
    function sortFunction(a, b) {
        if (a[0] === b[0]) {
            return 0;
        }
        else {
            return (a[0] < b[0]) ? -1 : 1;
        }
    }
    console.log(resDataVal);
    resDataVal.sort(sortFunction);*/
    //console.log(datesArr, Object.keys(datesArr), Object.keys(datesArr).length);
    //console.log(resDataVal);

    var newDataArr = [];
    var keys1 = Object.keys(datesArr);
    for (var i = 0; i < keys1.length; i++) {
        //for (datesArr, function(indexD, itemD) {
        var secondStart = 1;
        //console.log(datesArr[keys1[i]]);
        if (typeof datesArr[keys1[i]].arr0 !== "undefined") {
            for (var j = 0; j < datesArr[keys1[i]].arr0.length; j++) {
                //$.each(datesArr, function(index0, item0) {
                //console.log(datesArr[keys1[i]].arr0[j]);
                if (j < datesArr[keys1[i]].dIndex) {
                    //console.log(item0)
                    //newDataArr.push(datesArr[keys1[i]].arr0[j]);
                } else {
                    if (secondStart == 1) {
                        for (var k = 0; k < datesArr[keys1[i]].arrVal.length; k++) {
                            //$.each(itemD.arrVal, function(indexV, itemV) {
                            //console.log(datesArr[keys1[i]].arrVal[k]);
                            newDataArr.push(datesArr[keys1[i]].arrVal[k]);
                        }
                        secondStart = 0;
                    }
                    //newDataArr.push(datesArr[keys1[i]].arr0[j]);
                }
            }
        } else if (typeof datesArr[keys1[i]].arrVal !== "undefined") {
            for (var k = 0; k < datesArr[keys1[i]].arrVal.length; k++) {
                //$.each(itemD.arrVal, function(indexV, itemV) {
                //console.log(datesArr[keys1[i]].arrVal[k]);
                newDataArr.push(datesArr[keys1[i]].arrVal[k]);
            }
        } 
    }

    $.each(resData.nodes, function(indexN, itemN) {
        for (var i = 0; i < resData.dates.length; i++) {
            if (resData.dates[i] == itemN.date) {
                //resData.nodes[indexN].level = i;
            }
        }
        
    });
    //console.log(newDataArr);
    //resData.data = newDataArr;
    resDataVal;
    window.sankeyData[chartIndex].curDataChartCsv = curDataChartCsv;
    $.each(resData.nodes, function(index, item) {
        if (formdata.dropdownColorsSelection != 10) {
            if (typeof window.sankeyData[chartIndex].wordsList[item.name] !== "undefined") {
                resData.nodes[index].color = window.sankeyData[chartIndex].wordsList[item.name].colornew;
            }
        } else {
            resData.nodes[index].color = getColor(index, undefined, undefined, resData.nodes[index].value, undefined, undefined, formdata);
            $.each(wordsListObj, function(indexW, itemW) {
                if (wordsListObj[indexW].name == resData.nodes[index].name) {
                    //console.log(resData.nodes[index].value, index);
                    if (typeof wordsListObj[indexW].value == "undefined") {
                        wordsListObj[indexW].value = resData.nodes[index].value;
                    } else {
                        wordsListObj[indexW].value += resData.nodes[index].value;
                    }
                    wordsListObj[indexW].color = getColor(index, undefined, undefined, wordsListObj[indexW].value, undefined, undefined, formdata);
                }
            });
        }
    });
    //console.log(resData.nodes, wordsListObj);
    //console.log(formdata.changedColors);
    
    if (typeof wordsListObj !== "undefined") {
        if ((window.sankeyData[chartIndex].wordsListReset == true) || (window.sankeyData[chartIndex].colorsArr.length == 0)) {
            window.sankeyData[chartIndex].colorsArr = [] ;
            window.sankeyData[chartIndex].colorsDrawArr = [];
            var curColor = 'ffffff';
            for (var i = 0 ; i < wordsListObj.length; i++) {
                if (firstLoadChart == 1 && formDataStr != '') {
                    curColor = formdata.curWordColor;
                    formDataStr = '';
                } else {
                    if (typeof formdata.changedColors[wordsListObj[i].md5] !== "undefined") {
                        curColor = formdata.changedColors[wordsListObj[i].md5].color;
                        wordsListObj[i].color = curColor;
                        console.log(wordsListObj[i].name, curColor);
                    } else {
                        if (formdata.dropdownColorsSelection == '1') {
                            curColor = getColor(hexToDecColor(wordsListObj[i].md5), undefined, undefined, wordsListObj[i].md5,
                                wordsListObj[i].name, i, formdata);
                        } else {
                            curColor = getColor(i, undefined, undefined, wordsListObj[i].md5,
                                wordsListObj[i].name, i, formdata);
                        }
                    }
                }

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[i].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[i].name];
                } else {
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    var curColor = wordsListObj[i].color;
                }

                window.sankeyData[chartIndex].colorsArr[i] = curColor;
                window.sankeyData[chartIndex].colorsDrawArr[i] = curColor;
            }
            for (var j = 0; j < wordsListObj.length; j++) {
                var curName = wordsListObj[j].name;
                //formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j], chartIndex);
                var curColor = ((typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined")
                    ? (formdata.changedColors[wordsListObj[j].md5].color)
                    : (
                        window.sankeyData[chartIndex].colorsArr[j]
                    )
                );
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colorsArr[j] = curColor;
                }
                var colornew = window.sankeyData[chartIndex].colorsArr[j];
                var colornewIsChange = 0;
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colornew = formdata.changedColors[wordsListObj[j].md5].color;
                    window.sankeyData[chartIndex].colorsDrawArr[j] = colornew;
                    colornewIsChange = 1;
                }
                formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j].md5, chartIndex);

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[j].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[j].name];
                } else {
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    var curColor = wordsListObj[j].color;
                }

                if (window.sankeyData[chartIndex].wordsListReset == true) {
                    window.sankeyData[chartIndex].wordsList[curName] = {
                        'name': curName,
                        'size': 1, //item.sizeReal,
                        'color': window.sankeyData[chartIndex].colorsArr[j],
                        'colornew': curColor,
                        'id': j,
                        'fore': 0,
                        'md5': wordsListObj[j].md5,
                        'colorIndex': hexToDecColor(wordsListObj[j].md5)
                    };
                }
                if (window.sankeyData[chartIndex].wordsList[curName] !== "undefined") {
                    window.sankeyData[chartIndex].wordsList[curName].colornew = curColor;
                }
                window.sankeyData[chartIndex].colorsArr[j] = curColor;
                window.sankeyData[chartIndex].colorsDrawArr[j] = curColor;
            }
            window.sankeyData[chartIndex].wordsListFirst = window.sankeyData[chartIndex].wordsList;
        } else if (typeof formdata.changedColors !== "undefined") {
            for (var j = 0; j < wordsListObj.length; j++) {
                var curName = wordsListObj[j].name;
                formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j].md5, chartIndex);
                var curColor = ((typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined")
                    ? formdata.changedColors[wordsListObj[j].md5].color
                    : (
                        window.sankeyData[chartIndex].colorsArr[j]
                    )
                );
                
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    curColor = formdata.changedColors[wordsListObj[j].md5].color;
                    wordsListObj[j].color = curColor;
                    console.log(wordsListObj[j].name, curColor);
                } else {
                    if (formdata.dropdownColorsSelection == '1') {
                        curColor = getColor(hexToDecColor(wordsListObj[j].md5), undefined, undefined, wordsListObj[j].md5,
                            wordsListObj[j].name, j, formdata);
                    } else if (formdata.dropdownColorsSelection == 10) {
                        curColor = wordsListObj[j].color;
                    } else {
                        //curColor = getColor(j);
                        curColor = getColor(j, undefined, undefined, wordsListObj[j].md5,
                            wordsListObj[j].name, j, formdata);
                    }
                }
                
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colorsArr[j] = curColor;
                }
                var colornew = window.sankeyData[chartIndex].colorsArr[j];
                var colornewIsChange = 0;
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colornew = formdata.changedColors[wordsListObj[j].md5].color;
                    window.sankeyData[chartIndex].colorsDrawArr[j] = colornew;
                    colornewIsChange = 1;
                }
                formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j].md5, chartIndex);

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[j].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[j].name];
                } else {
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    var curColor = wordsListObj[j].color;
                }

                if (window.sankeyData[chartIndex].wordsListReset == true) {
                    window.sankeyData[chartIndex].wordsList[curName] = {
                        'name': curName,
                        'size': 1, //item.sizeReal,
                        'color': window.sankeyData[chartIndex].colorsArr[j],
                        'colornew': curColor,
                        'id': j,
                        'fore': 0,
                        'md5': wordsListObj[j].md5,
                        'colorIndex': hexToDecColor(wordsListObj[j].md5)
                    };
                }
                if (window.sankeyData[chartIndex].wordsList[curName] !== "undefined") {
                    window.sankeyData[chartIndex].wordsList[curName].colornew = curColor;
                }
                window.sankeyData[chartIndex].colorsArr[j] = curColor;
                window.sankeyData[chartIndex].colorsDrawArr[j] = curColor;
            }
            window.sankeyData[chartIndex].wordsListFirst = window.sankeyData[chartIndex].wordsList;
        }
    }

    if (typeof formdata.dropdownValueVIndicator !== "undefined") {
        var valueVIndicator = getValueIndicatorTextById(formdata.dropdownValueVIndicator);
    } else {
        var valueVIndicator = getValueIndicatorTextById(valueDefault);
    }
    
    //Start title block
    if (typeof addData.sourceTitle !== "undefined") {
        window.sankeyData[chartIndex].sourceTitle = addData.sourceTitle;
    }
    if (typeof addData.dateFrom !== "undefined") {
        window.sankeyData[chartIndex].dateFrom = addData.dateFrom;
    }
    if (typeof addData.dateTo !== "undefined") {
        window.sankeyData[chartIndex].dateTo = addData.dateTo;
    }
    
    if (data.countDates <= 31) {
        var intervalInDays = 24 * 3600 * 1000;
    } else {
        var intervalInDays = undefined;
    }

    if (typeof formdata.textBoxTitleTimeCharts !== "undefined") {
        var textBoxTitleTimeCharts = formdata.textBoxTitleTimeCharts;
    } else {
        var textBoxTitleTimeCharts = '';
    }
    var dataSourceLabel = '';
    if (typeof formdata.dropdownValueDSource !== "undefined") {
        $.each(DataSelectOptions, function(index, item) {
            if (item.value == formdata.dropdownValueDSource) {
                dataSourceLabel = item.label;
            }
        });
    }
    var chartViewLabel = '';
    
    textBoxTitleTimeCharts = textBoxTitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.sankeyData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.sankeyData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxSubtitleTimeCharts !== "undefined") {
        var textBoxSubtitleTimeCharts = formdata.textBoxSubtitleTimeCharts;
    } else {
        var textBoxSubtitleTimeCharts = 'Data source: %DATA_SOURCE% (%CHART_VIEW%)';
    }
    textBoxSubtitleTimeCharts = textBoxSubtitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.sankeyData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.sankeyData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxDescriptionTimeCharts !== "undefined") {
        var textBoxDescriptionTimeCharts = formdata.textBoxDescriptionTimeCharts;
    } else {
        var textBoxDescriptionTimeCharts = 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%';
    }
    textBoxDescriptionTimeCharts = textBoxDescriptionTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.sankeyData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.sankeyData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    //end ttle block

    
    $('#chartSankeyContaner .chart svg').remove();
    $('#chartSankeyContaner .legend').html('');
    
    window.sankeyData[chartIndex].margin = { top: 20, right: 10, bottom: 0, left: 10 };
    window.sankeyData[chartIndex].width = 
        $('#dataContaner').parent().width() - window.sankeyData[chartIndex].margin.left 
        - window.sankeyData[chartIndex].margin.right ;
    if (isWidget == 0) {
        window.sankeyData[chartIndex].height =
            $('#dataContaner').parent().height() - window.sankeyData[chartIndex].margin.top 
            - window.sankeyData[chartIndex].margin.bottom;
    } else {
        window.sankeyData[chartIndex].height = $('#dataContaner').parent().height() - 20;
    }
   
    if (firstLoadChart == 1 && isShare && hash != '') {
        //firstLoadChart = 0;
        isShare = false;
        addWordsCheckboxesAndInfo(
            window.sankeyData[chartIndex].wordsListFirst,
            window.sankeyData[chartIndex].wordsListSelected,
            CHART_TYPE_SANKEY_NEIGHBORS1
        );
    } else {
        //firstLoadChart = 0;
        addWordsCheckboxesAndInfo(
            window.sankeyData[chartIndex].wordsListFirst,
            window.sankeyData[chartIndex].wordsListSelected,
            CHART_TYPE_SANKEY_NEIGHBORS1
        );
    }
    window.sankeyData[chartIndex].wordsListReset = false;
    /*$.each(resData.data, function(index1, item1) {
        resData.data[index1].color = '#9933FF';
    });
    console.log(resData.data);*/

    //console.log(wordsListObj);

    $.each(resData.nodes, function(index, item) {
        if (formdata.dropdownColorsSelection != 10) {
            if (typeof window.sankeyData[chartIndex].wordsList[item.name] !== "undefined") {
                resData.nodes[index].color = window.sankeyData[chartIndex].wordsList[item.name].colornew;
            }
        } else {
            //if (typeof window.sankeyData[chartIndex].wordsList[item.name] !== "undefined") {
            //    resData.nodes[index].color = window.sankeyData[chartIndex].wordsList[item.name].colornew;
            //}
        }
    });


    var H = Highcharts;
    H.seriesTypes.sankey.prototype.pointAttribs = function(point, state) {
        var opacity = this.options.linkOpacity,
            color = point.color;

        if (state) {
            opacity = this.options.states[state].linkOpacity || opacity;
            color = this.options.states[state].color || point.color;
        }

        return {
            fill: point.isNode ?
                color : {
                    linearGradient: {
                        x1: 0,
                        x2: 1,
                        y1: 0,
                        y2: 0
                    },
                    stops: [
                        [0, H.color(color).setOpacity(opacity).get()],
                        [1, H.color(point.toNode.color).setOpacity(opacity).get()]
                    ]
                }
        };
    }
    //console.log(nodesCnt, 100 / lineWidthMult/5 / (nodesCnt < 30 ? 1 : (nodesCnt < 50 ? 1.5 : (nodesCnt < 100 ? 2 : (nodesCnt < 150 ? 2.5 : 3)))));
    Highcharts.setOptions({
        global: {
            useUTC:  true
        },
        time: {
            timezoneOffset: new Date().getTimezoneOffset()
        }
    });
    //console.log(resData.nodes);
    
    chartSankeyHC = Highcharts.chart('chartSankeyContaner', {
        chart: {
            type: 'sankey',
            //borderWidth: 1,
            //plotBorderWidth: 1,
            spacingBottom: 60,
            //height: 250
            //marginBottom: 30,
            //colorByPoint: false,
            zoomType: 'x',
            events: {
                load: function () {
                    /*var plotHeight = $('.highcharts-plot-border').height();
                    var el   = document.getElementsByClassName("highcharts-sankey-series")[0]; // or other selector like querySelector()
                    var rect = el.getBoundingClientRect(); // get the bounding rectangle
                    var graphHeight = rect.height;
                    $('.highcharts-sankey-series').attr('transform', 'translate(10,53) scale(1 ' + ((plotHeight - 30)/graphHeight) + ')');
                    */
                   

                    setTimeout(function () {
                        if (isWidget == 1) {
                            $('#jqxLoaderWidget').jqxLoader('close');
                        } else {
                            $('#jqxLoader').jqxLoader('close');
                        }
   
                        //const highest = chartSankeyHC.get('empty');
                        //if (highest) {
                        //    highest.remove();
                        //}
                    }, 1100);
                },
                render: function() {
                    var positions = [];//[30, this.chartWidth / 2, this.chartWidth - 30];
                    //console.log('isInverted=', isInverted, typeof isInverted);
                    if (isInverted === true) {
                        //console.log('isInverted');
                        //if ((this.chartHeight / resData.dates.length) < 70) {
                        //    var rotationVal = 0;//270;
                        //    var yHeight = 25;
                        //    var shift = 30;
                        //} else {
                            var rotationVal = 0;
                            var yHeight = 0;
                            var shift = 0;
                        //}
                        resData.dates.forEach((label, i) => {
                            if (isTitle == true) {
                                var titleShift = 42;
                            } else {
                                var titleShift = 0;
                            }
                            if (i == 0) {
                                
                                positions.push(30 + titleShift);
                            } else if (i == (resData.dates.length - 1)) {
                                positions.push(this.chartHeight - 30);
                            } else {
                                positions.push(((this.chartHeight - 20 - titleShift) / (resData.dates.length - 1)) * i + titleShift);
                            }
                        });
                        //console.log(positions);
                
                        if (this.customLabels) {
                          this.customLabels.forEach((customLabel, i) => {
                            customLabel.attr({
                              x: 10,
                              y: positions[i],
                              rotation: 270
                            });
                          });
                        } else {
                          this.customLabels = [];
                          resData.dates.forEach((label, i) => {
                            this.customLabels.push(
                              this.renderer.text(resData.dates[i])
                              .attr({
                                x: 10,
                                y:  positions[i],
                                align: 'left',
                                rotation: 270//rotationVal
                              })
                              .css({
                                fontSize: (fontSize * fontSizeMult) + 'px'
                              })
                              .add()
                            );
                          });
                        }
                    } else {
                        var rotationVal = 0;
                        var yHeight = 0;
                        var shift = 0;
                    }
                    resData.dates.forEach((label, i) => {
                        if (i == 0) {
                            positions.push(30);
                        } else if (i == (resData.dates.length - 1)) {
                            positions.push(this.chartWidth - 35 + shift);
                        } else {
                            positions.push(((this.chartWidth - 58 + shift) / (resData.dates.length - 1)) * i + 30);
                        }
                    });
            
                    if (this.customLabels) {
                      this.customLabels.forEach((customLabel, i) => {
                        customLabel.attr({
                          x: positions[i],
                          y: this.chartHeight -  15
                        });
                      });
                    } else {
                      this.customLabels = [];
                      resData.dates.forEach((label, i) => {
                        this.customLabels.push(
                          this.renderer.text(resData.dates[i])
                          .attr({
                            x: positions[i],
                            y: this.chartHeight - 30 - yHeight,
                            align: 'center',
                            rotation: 300//rotationVal
                          })
                          .css({
                            fontSize: (fontSize * fontSizeMult) + 'px'
                          })
                          .add()
                        );
                      });
                    }
                    setTimeout(function () {
                        var ii = 0;
                        //console.log(emptyDataCnt, noEmptyDataCnt);
                        /*if (((emptyDataCnt <= 100) || (noEmptyDataCnt <= 20)) && (emptyDataCnt > noEmptyDataCnt) && (noEmptyDataCnt <= 30)){
                            $.each(resData.data, function(index1, item1) {
                                if (resData.data[index1][2] == 0) {
                                    //this.get(item1[4]).remove();
                                    var highest = chartSankeyHC.get('empty');
                                    if (highest) {
                                        highest.remove();
                                        ii++;
                                        //console.log(ii);
                                    }
                                }
                            });
                        }*/
                    }, 500);
                }
            },
            //backgroundColor: chartBackgroundColor
            height: window.sankeyData[chartIndex].height,
            width: window.sankeyData[chartIndex].width,
            //spacingBottom: 100,
            //spacing: [50, 30, 75, 40],
            //inverted: isInverted
            //lineWidth: 1 * lineWidthMult,
            //minLinkWidth: 10 * lineWidthMult,
            //inverted: true, //Highcharts Inverted Sankey Diagram
        },
        tooltip: {
            headerFormat: null,
            //pointFormat: '{point.fromNode.name} → {point.toNode.name}:</b> is <b>{point.weight}</b><br>{point.arr}',
            //nodeFormat: '<b>Node:</b>{point.name}, {point.date}',
            pointFormatter: function() { 
                //console.log(this);
                 return this.name + ": <b>" + this.date + '<br></b>'
            },
            useHTML: true,
            pointFormatter: function() { 
                //console.log(this);
                 return this.fromNode.name  
                 + ' → ' + this.toNode.name + ": <b>" + this.weight + '<br>Intersected:</b><ol><li>'
                 + (this.arr.join(', </li><li>')) + '</li></ol>'
            },
            //shared: true             
        },
    
        // Make sure connected countries have similar colors
        //colors: window.sankeyData[chartIndex].colorsArr,
        //title: {
        //    text: 'Highcharts Sankey Diagram'
        //},
        title: {
            text: isTitle == true ? textBoxTitleTimeCharts : ' '
        },
        subtitle: isTitle == true ? {
            text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
            useHTML: true
        } : {
            text: ' ',
            useHTML: false
        },
        /*tooltip: {
            headerFormat: '',
            //pointFormat: 'The value for <b>{point.name} {point.x}</b> is <b>{point.y}</b><br>{point.custom.extraInformation}',
            nodeFormat: '{point.name}, {point.date}',
            pointFormat: '{point.fromNode.name} → {point.toNode.name}:</b> is <b>{point.y}</b><br>{point.arr}',
        },
        tooltip: {
            pointFormat: '{point.fromNode.name} → {point.toNode.name}:</b> is <b>{point.y}</b><br>{point.arr}',
            nodeFormat: '{point.name}, {point.date}',
            useHTML: true,
        },*/
        accessibility: {
            keyboardNavigation: {
                enabled:true,
                focusBorder: {
                    enabled: false,
                    hideBrowserFocusOutline:true,
                    margin:2,
                    style: {

                    }
                },
                order:undefined,
                seriesNavigation: {},
                wrapAround:true
            }            
            /*point: {
                valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.',
                descriptionFormatter: function (point) {
                    return (
                        point.description
                    );
                }
            }*/
        },
        series: [{
            keys: ['from', 'to', 'weight', 'arr', 'str'],
            data: resDataVal, //resData.data, //newDataArr, resDataVal
            nodes: resData.nodes,
            //colorByPoint:false,
            //clip: true,
            //categories: ['Apples', 'Bananas', 'Oranges'],
            type: 'sankey',
            //name: null, //'<b>Relation:</b>',
            dataLabels: {
                //crop: false,
                //overflow: "allow",
                //inside: true,
                allowOverlap: true,
                enabled:true,
                style: {
                    fontSize: (fontSize * fontSizeMult) + 'px',
                    fontWeight: 'bold'
                },
                color:"#000000",

            },
            //centerInCategory: true,
            nodePadding: 100 / lineWidthMult / 5 / (nodesCnt < 30 ? 1 : (nodesCnt < 50 ? 1.5 : (nodesCnt < 100 ? 2 : (nodesCnt < 150 ? 2.5 : 3)))),
            label: {
                //maxFontSize: (fontSize * fontSizeMult),
                //minFontSize: (fontSize * fontSizeMult)
            },
            turboThreshold: 0
            //minLinkWidth: 10,
            //minLinkWidth: 1 * lineWidthMult,
            //lineWidth: 1 * lineWidthMult,
            //selected:true,
            //showCheckbox:true,
            //showInLegend:true
        }],
        credits: {
            enabled: false
        },
        navigation: {
            buttonOptions: {
                enabled: isChartMenu
            }
        },
        xAxis: {
            //categories: ['2022-03-23', '2022-03-24', '2022-03-25']
        }
    });
    
}    
    
    
