/**
*       Site-specific configuration settings for Highslide JS
*/
//hs.graphicsDir = 'https://www.highcharts.com/highslide/graphics/';
hs.graphicsDir = window.location.origin + '/scripts/highcharts/highslide/graphics/outlines/'
hs.outlineType = 'rounded-white';
hs.wrapperClassName = 'draggable-header';
hs.captionEval = 'this.a.title';
hs.showCredits = false;
hs.marginTop = 20;
hs.marginRight = 20;
hs.marginBottom = 20;
hs.marginLeft = 20;
var hsOutlineTypeLImg = hs.outlineType;
