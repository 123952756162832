var widgetUrlParamsMapping = {
    search: {formField: 'textBoxValueSearchWord', macro: '%SEARCH%'},
    exclude: {formField: 'textBoxValueExcludeWord', macro: '%EXCLUDE%'},
    dataSource: {formField: 'dropdownValueDSource', macro: '%DATA_SOURCE%'},
    valMin: {formField: 'nubmberBoxValue1', macro: '%VAL_MIN%'}, // value indicator min
    valMax: {formField: 'nubmberBoxValue2', macro: '%VAL_MAX%'}, // value indicator max
    datesinterval: {formField: 'dropdownDatesInterval', macro: '%DATES_INTERVAL%'},
    maxDepth: {formField: 'dropdownValueMaxDepth', macro: '%MAX_DEPTH%'}, // for all exclude textual
    valueIndicator: {formField: 'drawingValueIndicators', macro: '%VAL_INDICATOR%'},
    wordsDailyMax: {formField: 'nubmberBoxValueMaxWordsTotal', macro: '%WORDS_DAILY_MAX%'},
    appearanceMin: {formField: 'nubmberBoxAppearanceMin', macro: '%APPEARANCE_MIN%'},
    appearanceMax: {formField: 'nubmberBoxAppearanceMax', macro: '%APPEARANCE_MAX%'},
    datesrange: {formField: 'textBoxValueDatesRange', macro: '%DATESRANGE%'}
};


var dropdownDatesIntervalOptions = [
    {label: 'Calendar', value: '0'},
    {label: 'From calendar up to now', value: '17'},
    {label: 'Last Day', value: '1'},
    {label: 'Prev Day', value: '2'},
    {label: 'Last Week', value: '3'},
    {label: 'Prev Week', value: '4'},

    {label: '2 days in past', value: '22'},
    {label: '3 days in past', value: '23'},
    {label: '4 days in past', value: '24'},
    {label: '5 days in past', value: '25'},
    {label: '6 days in past', value: '26'},
    {label: '7 days in past', value: '27'},

    {label: '10 days in past', value: '12'},
    {label: '20 days in past', value: '13'},
    {label: '30 days in past', value: '14'},
    {label: '60 days in past', value: '15'},
    {label: '90 days in past', value: '16'},
    {label: 'Last Month', value: '5'},
    {label: 'Prev Month', value: '6'},
    {label: 'Last Quarter', value: '7'},
    {label: 'Prev Quarter', value: '8'},
    {label: 'Last Year', value: '9'},
    {label: 'Prev Year', value: '10'},
    {label: 'Last two years', value: '11'}
];

var isBtnMainClick;


/**
 * Set Dates Interval by selected interval type
 * 
 * @param dropdownDatesInterval
 * @param textBoxValueDatesRange
 * @param isIDE
 * @param daysMax
 * @param daysMaxStart
 * 
 * @returns object {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval}
 */
function setDatesInterval(dropdownDatesInterval, textBoxValueDatesRange, isIDE, daysMax, daysMaxStart) {
    if (typeof isIDE === "undefined") {
        isIDE = true;
    }
    var ret = {};
    textBoxValueDatesRange = formatDatesRangeStr(textBoxValueDatesRange);
    switch(dropdownDatesInterval) {
        case '1'://Last Day
            var dateFrom = getNowDate();
            var dateTo = getNowDate();
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '2'://Prev Day
            var dateFrom = new Date(getNowDate().getFullYear(), getNowDate().getMonth(), getNowDate().getDate()-1);
            var dateTo = new Date(getNowDate().getFullYear(), getNowDate().getMonth(), getNowDate().getDate()-1);
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '3'://Last Week
            if (getNowDate().getDay() < 3) {
                if (firstDayOfTheWeek == -1) {
                    var dateFrom =  moment().day(-7).weekday(0);
                    var dateTo = moment().day(-7).weekday(6);
                } else {
                    var dateFrom =  moment().day(-7).weekday(firstDayOfTheWeek);
                    var dateTo = moment().day(-7).weekday(firstDayOfTheWeek + 6);
                }
            } else {
                if (firstDayOfTheWeek == -1) {
                    var dateFrom =  moment().weekday(0);
                } else {
                    var dateFrom =  moment().weekday(firstDayOfTheWeek);
                }
                var dateTo = moment();
            }
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '4'://Prev Week
            if (getNowDate().getDay() < 3) {
                if (firstDayOfTheWeek == -1) {
                    var dateFrom =  moment().day(-14).weekday(0);
                    var dateTo = moment().day(-14).weekday(6);
                } else {
                    var dateFrom =  moment().day(-14).weekday(firstDayOfTheWeek);
                    var dateTo = moment().day(-14).weekday(firstDayOfTheWeek + 6);
                }
            } else {
                if (firstDayOfTheWeek == -1) {
                    var dateFrom =  moment().day(-7).weekday(0);
                    var dateTo = moment().day(-7).weekday(6);
                } else {
                    var dateFrom =  moment().day(-7).weekday(firstDayOfTheWeek);
                    var dateTo = moment().day(-7).weekday(firstDayOfTheWeek + 6);
                }
            }
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
            
        case '22'://2 days in past
            var dateFrom = moment().subtract(2, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '23'://3 days in past
            var dateFrom = moment().subtract(3, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '24'://4 days in past
            var dateFrom = moment().subtract(4, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '25'://5 days in past
            var dateFrom = moment().subtract(5, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '26'://6 days in past
            var dateFrom = moment().subtract(6, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '27'://7 days in past
            var dateFrom = moment().subtract(7, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
            
        case '12'://10 days in past
            var dateFrom = moment().subtract(10, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '13'://20 days in past
            var dateFrom = moment().subtract(20, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '14'://30 days in past
            var dateFrom = moment().subtract(30, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '15'://60 days in past
            var dateFrom = moment().subtract(60, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '16'://90 days in past
            var dateFrom = moment().subtract(90, "days");
            var dateTo = moment();
            var dateFromRet = new Date(dateFrom.year(), dateFrom.month(), dateFrom.date());
            var dateToRet = new Date(dateTo.year(), dateTo.month(), dateTo.date());
            var datesInterval = getDatesIntervalStr(dateFromRet, dateToRet);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFromRet, dateToRet);
            }
            ret = {'from': dateFromRet, 'to': dateToRet, 'datesInterval': datesInterval};
            break;
        case '5'://Last Month
            var dateFrom = new Date(getNowDate().getFullYear(), getNowDate().getMonth(), 1);
            var dateTo = getNowDate();
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '6'://Prev Month
            var dateFrom = new Date(getNowDate().getFullYear(), getNowDate().getMonth()-1, 1);
            var dateTo = new Date(getNowDate().getFullYear(), getNowDate().getMonth(), 0);
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '7'://Last Quarter
            var datesIntervalObj = getCurrentPreviousQuarter("current");
            var dateFrom = datesIntervalObj.StartDate;
            var dateTo = datesIntervalObj.EndDate;
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '8'://Prev Quarter
            var datesIntervalObj = getCurrentPreviousQuarter("previous");
            var dateFrom = datesIntervalObj.StartDate;
            var dateTo = datesIntervalObj.EndDate;
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '9'://Last Year
            var dateFrom = new Date(getNowDate().getFullYear(), 0, 1);
            var dateTo = getNowDate();
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '10'://Prev Year
            var dateFrom = new Date(getNowDate().getFullYear() - 1, 0, 1);
            var dateTo = new Date(getNowDate().getFullYear(), 0, 0);
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '11'://Last two years
            var dateFrom = new Date(getNowDate().getFullYear() - 1, 0, 1);
            var dateTo = getNowDate();
            var datesInterval = getDatesIntervalStr(dateFrom, dateTo);
            if (isIDE == true) {
                window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                $('input[name=textBoxValueDatesRange]').val(datesInterval);
                $('#inputjqxWidgetDateRange').val(datesInterval);
                $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', dateFrom, dateTo);
            }
            ret = {'from': dateFrom, 'to': dateTo, 'datesInterval': datesInterval};
            break;
        case '17'://From calendar up to now
            if (typeof textBoxValueDatesRange !== "undefined") {
                var dateTo = getNowDate();
                //Set dates for the calendar selection
                var datesArr = textBoxValueDatesRange.split(' - ');
                
                var dateStartArr = datesArr[0].split('-');
                var setDayStart = new Date(dateStartArr[0], dateStartArr[1]-1, dateStartArr[2]);
                var datesInterval = getDatesIntervalStr(setDayStart, dateTo);

                if (isIDE == true) {
                    window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(datesInterval);
                    $('input[name=textBoxValueDatesRange]').val(datesInterval);
                    $('#inputjqxWidgetDateRange').val(datesInterval);
                    $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', setDayStart, dateTo);
                }
                ret = {'from': setDayStart, 'to': dateTo, 'datesInterval': datesInterval};
            }
            break;
        default://Calendar
            if (typeof textBoxValueDatesRange !== "undefined") {
                var datesArr = textBoxValueDatesRange.split(' - ');
                
                var dateEndArr = datesArr[1].split('-');
                var setDayEnd = new Date(dateEndArr[0], dateEndArr[1]-1, dateEndArr[2]);
                var dateStartArr = datesArr[0].split('-');
                var setDayStart = new Date(dateStartArr[0], dateStartArr[1]-1, dateStartArr[2]);
                var datesInterval = getDatesIntervalStr(setDayStart, setDayEnd);
                if (isIDE == true) {
                    window.sampleForm.jqxForm('getComponentByName', 'textBoxValueDatesRange').val(textBoxValueDatesRange);
                    $('input[name=textBoxValueDatesRange]').val(datesInterval);
                    $('#inputjqxWidgetDateRange').val(datesInterval);
                    //Set dates for the calendar selection
                    $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', setDayStart, setDayEnd);
                }
                ret = {'from': setDayStart, 'to': setDayEnd, 'datesInterval': datesInterval};
            }
            break;
    }
    
    if (isIDE == false) {
        if ((typeof daysMax !== "undefined") && (typeof daysMaxStart !== "undefined")) {
            var datesArr = getDatesArr(ret.from, ret.to);
            if (datesArr.length > daysMax) {
                if (daysMaxStart == 0) {
                    var dareArrTo = datesArr[daysMax - 1].split('-');
                    ret.to = new Date(dareArrTo[0], dareArrTo[1], dareArrTo[2]);
                } else {
                    var dareArrFrom = datesArr[datesArr.length - (daysMax +1)].split('-');
                    ret.from = new Date(dareArrFrom[0], dareArrFrom[1], dareArrFrom[2]);
                }
            }
            var datesInterval = getDatesIntervalStr(ret.from, ret.to);
            ret.datesInterval = datesInterval;
        }
        ret.datesInterval = ret.datesInterval.replace(/ - /g, '-');
        //console.log('ret = ', ret);
        return ret;
    }
}

function formatDatesRangeStr(textBoxValueDatesRange) {
    var retStr = textBoxValueDatesRange.replace(RegExp("^(?:.*?-){3}"),
        function(x){return x.replace(RegExp('-' + "$"), " - ")}
    ).replace(/  /g, ' ');
    
    if (retStr.search('/') > 0) {
        var datesArr = retStr.split(' - ');
        
        var dateStartArr1 = datesArr[0].split('/');
        var date1Year = dateStartArr1[2];
        var date1Month = dateStartArr1[1];
        var date1Day = dateStartArr1[0];
        
        var dateStartArr2 = datesArr[1].split('/');
        var date2Year = dateStartArr2[2];
        var date2Month = dateStartArr2[1];
        var date2Day = dateStartArr2[0];
        
        retStr = date1Year + '-' + date1Month + '-' + date1Day
            + ' - '
            + date2Year + '-' + date2Month + '-' + date2Day;
    }
    
    return retStr;
}

function getDatesIntervalStr(dateFrom, dateTo) {
    var detesIntervalStr = moment(dateFrom).format('YYYY-MM-DD')
        + ' - '
        + moment(dateTo).format('YYYY-MM-DD');
    
    return detesIntervalStr;
}

function getDatesArr(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    
    return dateArray;
}

function getNowDate() {
    return new Date(new Date().toString());
}

var getNowDate2 = (function() {
    return new Date(new Date().toString());
})();

function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
    const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff = dayOfWeek >= firstDayOfWeekIndex ?
    dayOfWeek - firstDayOfWeekIndex :
    6 - dayOfWeek;

    firstDayOfWeek.setDate(dateObject.getDate() - diff);
    firstDayOfWeek.setHours(0,0,0,0);

    return firstDayOfWeek;
}

function getCurrentPreviousQuarter(value) {
    var today = getNowDate(),
        quarter = Math.floor((today.getMonth() / 3)),
        startDate,
        endDate;

    switch (value) {
        case "previous":
            startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
            break;
        default:
            startDate = new Date(today.getFullYear(), quarter * 3, 1);
            endDate = getNowDate();
            break;
    }

    return {
        StartDate: startDate,
        EndDate: endDate
    };
}

/**
 * Shows a warning when the date range is more than three days.
 * Sets the limit to 15 days if the range is larger.
 * @returns bool
 */
function set15DaysLimit() {
    if (isWidget == 1) {
        return true;
    } else {
        var textBoxValueDatesRange = $('#inputjqxWidgetDateRange').val()
        var datesArr = textBoxValueDatesRange.split(' - ');

        var dateEndArr = datesArr[1].split('-');
        var setDayEnd = new Date(dateEndArr[0], dateEndArr[1]-1, dateEndArr[2]);
        var dateStartArr = datesArr[0].split('-');
        var setDayStart = new Date(dateStartArr[0], dateStartArr[1]-1, dateStartArr[2]);

        var datesArr = getDatesArr(setDayStart, setDayEnd);
        var daysMaxLimit = 15;
        if (($('#chartType').val() == 5) || ($('#chartType').val() == 4)) {
            if (datesArr.length > 3) {
                var result = window.confirm("Selected dates range is greater than 15 days, " 
                    + "chart can be overloaded with data and data select process can take a long time. "
                    + "Continue with last 15 days limited interval?");
                return result;
            }
        }
        return true;
    }
}

/**
 * prepare pararmeters for the open share in the new tab or window
 * @param openIn
 * @returns
 */
function prepareSharedIn(openIn) {
    window.newValuesFormTab1.isOpenSharedIn = true;
    window.newValuesFormTab1.openSharedIn = openIn;
    window.shareButtonTab1.click();
}

/**
 * prepare pararmeters for the open share in the new tab or window
 * @param openIn
 * @returns
 */
 function prepareSharedMetaData(openIn, wordStr) {
    window.newValuesFormTab1.isMetadata = true;
    window.newValuesFormTab1.openSharedIn = openIn;

    window.newValuesFormTab1.metadataWord = wordStr;

    if (typeof window.newValuesFormTab1.metadataDate == "undefined") {
        window.newValuesFormTab1.metadataDate = {};
        if (typeof window.newValuesFormTab1.textBoxValueDatesRange !== "undefined") {
            var datesArr = window.newValuesFormTab1.textBoxValueDatesRange.split(' - ');
            var dateStartArr = datesArr[0].split('-');
            //var setDayStart = new Date(dateStartArr[2], dateStartArr[1]-1, dateStartArr[0]);
            window.newValuesFormTab1.metadataDate.year = dateStartArr[2];
            window.newValuesFormTab1.metadataDate.month = dateStartArr[1];
            window.newValuesFormTab1.metadataDate.day = dateStartArr[0];
        }
    }

    
    window.shareButtonTab1.click();
}


/**
 * open share in the new tab or window or as widget
 * @param urlStr
 * @returns
 */
function openSharedIn(urlStr) {
    if (typeof window.newValuesFormTab1.isOpenSharedIn !== "undefined") {
        var openIn = window.newValuesFormTab1.openSharedIn;
    } else {
        var openIn = 'tab';
    }

    switch(openIn) {
    case 'tab':
        window.open(urlStr, "_blank");
       break;
    case 'window':
        window.open(urlStr, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes");
        break;
    case 'widget':
        window.open(urlStr + '&widget=1', "_blank", "toolbar=yes,scrollbars=yes,resizable=yes");
        break;
    //default:
    }
    setTimeout(function () {
        window.newValuesFormTab1.isOpenSharedIn = false;
        window.newValuesFormTab1.isMetadata = false;
    }, 500);
}
/**
 * Prepare chart title string (html) by formdata parameters
 * @param formdata
 * @returns string (HTML)
 */
function getChartTitle(formdata, titleWidth) {
    if (typeof titleWidth == "undefined") {
        titleWidth = '100%';
    }
    var dataSourceLabel = '';
    if (typeof formdata.dropdownValueDSource !== "undefined") {
        $.each(DataSelectOptions, function(index, item) {
            if (item.value == formdata.dropdownValueDSource) {
                dataSourceLabel = item.label;
            }
        });
    }

    if (typeof window.addData.dateFrom !== "undefined") {
        var dateFrom = window.addData.dateFrom;
    } else {
        var dateFrom = '';
    }
    
    if (typeof window.addData.dateTo !== "undefined") {
        var dateTo = window.addData.dateTo;
    } else {
        var dateTo = '';
    }
    if (typeof formdata.dropdownValueVIndicator !== "undefined") {
        var valueVIndicator = getValueIndicatorTextById(formdata.dropdownValueVIndicator);
    } else {
        var valueVIndicator = getValueIndicatorTextById(valueDefault);
    }

    var chartViewLabel = '';
    if ($('#chartType').val() == 21) {
        if (typeof formdata.dropdownChartView !== "undefined") {
            $.each(sourceChartView, function(index, item) {
                if (item.value == formdata.dropdownChartView) {
                    chartViewLabel = item.label;
                }
            });
        }
    }
    
    if (typeof formdata.textBoxTitleTimeCharts !== "undefined") {
        var textBoxTitleTimeCharts = formdata.textBoxTitleTimeCharts;
    } else {
        var textBoxTitleTimeCharts = '';
    }
    textBoxTitleTimeCharts.replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', dateFrom)
        .replace('%DATE_TO%', dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxSubtitleTimeCharts !== "undefined") {
        var textBoxSubtitleTimeCharts = formdata.textBoxSubtitleTimeCharts;
    } else {
        var textBoxSubtitleTimeCharts = 'Data source: %DATA_SOURCE%';
    }
    textBoxSubtitleTimeCharts = textBoxSubtitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', dateFrom)
        .replace('%DATE_TO%', dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxDescriptionTimeCharts !== "undefined") {
        var textBoxDescriptionTimeCharts = formdata.textBoxDescriptionTimeCharts;
    } else {
        var textBoxDescriptionTimeCharts = 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%';
    }
    textBoxDescriptionTimeCharts = textBoxDescriptionTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', dateFrom)
        .replace('%DATE_TO%', dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    
    return '<div class="chart-title-contaner" style="width: ' + titleWidth + '"><center>' + textBoxTitleTimeCharts + '<center>'
        + '<center>' + textBoxSubtitleTimeCharts + '<center>'
        +'<center>' + textBoxDescriptionTimeCharts + '</cener></div>';
}

/**
 * Count chart title rows by formdata parameters
 * @param formdata
 * @returns int
 */
function getChartTitleRawsCnt(formdata) {
    var chartTitleRawsCnt = 0;
    
    if (typeof formdata.textBoxTitleTimeCharts !== "undefined") {
        if (formdata.textBoxTitleTimeCharts != '') {
            chartTitleRawsCnt++;
        }
    } else {
        chartTitleRawsCnt++;
    }

    if (typeof formdata.textBoxSubtitleTimeCharts !== "undefined") {
        if (formdata.textBoxSubtitleTimeCharts != '') {
            chartTitleRawsCnt++;
        }
    } else {
        chartTitleRawsCnt++;
    }
    if (typeof formdata.textBoxDescriptionTimeCharts !== "undefined") {
        if (formdata.textBoxDescriptionTimeCharts != '') {
            chartTitleRawsCnt++;
        }
    } else {
        chartTitleRawsCnt++;
    }
    
    return chartTitleRawsCnt;
}

function getChangedRadLevelColors() {
    var colorInputs = $('td.colorColumnLevel');
    var changedColors = {};
    $.each(colorInputs, function(index, item) {
        if ($(item).find('.node-color-new-value').val() != '') {
            changedColors[$(item).find('.node-color-new-value').attr('name')] = {
                'color' : $(item).find('.node-color-new-value').val()
            };
        }
    });
    return changedColors;
}

function setChangedRadLevelColors(changedColors) {
    var colorLevelsItems = $('td.colorColumnLevel');
    $.each(changedColors, function(index, item) {
        if ($('#level-color-' + index).length > 0) {
            $('#level-color-' + index).css('background-color', item.color);
            $('input[name=level-color-old-value' + index + ']').val(item.color);
            $('input[name=' + index + '].node-color-new-value').val(item.color);
        }
    });
    setTimeout(function() { 
        $.each(changedColors, function(index, item) {
            if ($('#level-color-' + index).length > 0) {
                $('#level-color-' + index).css('background-color', item.color);
                $('input[name=level-color-old-value' + index + ']').val(item.color);
                $('input[name=' + index + '].node-color-new-value').val(item.color);
            }
        });
   }, 1000);
    
    //return changedColors;
}

function getChangedCartTextColor() {
    var colorInputs = $('td.colorColumnChartText');
    var changedColors = {};
    $.each(colorInputs, function(index, item) {
        if ($(item).find('.chart-text-color-new-value').val() != '') {
            changedColors[$(item).find('.chart-text-color-new-value').attr('name')] = {
                'color' : $(item).find('.chart-text-color-new-value').val()
            };
        }
    });
    return changedColors;
}

function setChangedCartTextColor(changedColors) {
    var colorLevelsItems = $('td.colorColumnChartText');
    $.each(changedColors, function(index, item) {
        if ($('#chart-text-color-' + index).length > 0) {
            $('#chart-text-color-' + index).css('background-color', item.color);
            $('input[name=chart-text-color-old-value' + index + ']').val(item.color);
            $('input[name=' + index + '].node-color-new-value').val(item.color);
        }
    });
    setTimeout(function() { 
        $.each(changedColors, function(index, item) {
            if ($('#chart-text-color-' + index).length > 0) {
                $('#chart-text-color-' + index).css('background-color', item.color);
                $('input[name=chart-text-color-old-value' + index + ']').val(item.color);
                $('input[name=' + index + '].node-color-new-value').val(item.color);
            }
        });
   }, 1000);
    
    //return changedColors;
}



function addslashes(str) {
    str = str.replace(/\\/g, '\\\\');
    str = str.replace(/\'/g, '\\\'');
    str = str.replace(/\"/g, '\\"');
    str = str.replace(/\0/g, '\\0');
    return str;
}


function getIconSVG(iconSvgName) {
    var iconSvgStr = '';
    
    switch (iconSvgName) {
    case "downloadCsv":
        if (($('#chartType').val() == CHART_TYPE_TEXTUALWORDS)
            || ($('#chartType').val() == CHART_TYPE_TIMESERIES)
            || ($('#chartType').val() == CHART_TYPE_TIMELINE)
            || ($('#chartType').val() == CHART_TYPE_STREAMGRAPH)
            || ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1)) {
            iconSvgStr = '<span onclick="exportCsv()" id="downloadCsv" title="Download CSV">'
                + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" '
                + 'fill="currentColor" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">'
                + '<path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 '
                + '.708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"></path>'
                + '<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 '
                + '3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"></path>'
                + '</svg></span>';
        } else {
            iconSvgStr = '';
        }
        break;
    case "downloadImg":
        if (($('#chartType').val() == CHART_TYPE_MOLECULAR) 
            || ($('#chartType').val() == CHART_TYPE_RADIAL)
            || ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL)
            || ($('#chartType').val() == CHART_TYPE_TIMESERIES) 
            || ($('#chartType').val() == CHART_TYPE_TIMELINE)
            || ($('#chartType').val() == CHART_TYPE_STREAMGRAPH)
            || ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1)) {
            iconSvgStr = '<span onclick="exportPNG()" id="downloadPNG" title="Download PNG">'
                + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" '
                + 'class="bi bi-file-earmark-image" viewBox="0 0 16 16">'
                + '<path d="M6.502 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path>'
                + '<path d="M14 14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5V14zM4 1a1 1 0 0 '
                + '0-1 1v10l2.224-2.224a.5.5 0 0 1 .61-.075L8 11l2.157-3.02a.5.5 0 0 1 .76-.063L13 '
                + '10V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4z"></path>'
                + '</svg></span>';
        } else {
            iconSvgStr = '';
        }
        break;
    default:
        iconSvgStr = '';
        break;
    }
    
    return iconSvgStr;
}

function getchartTypeById(id) {
    var selectedIndex = 0;
    if (id == 'chartMolecular') {
        var selectedIndex = CHART_TYPE_MOLECULAR;
    }
    if (id == 'chartRadial') {
         selectedIndex = CHART_TYPE_RADIAL;
    }
    if (id == 'chartDendrogram') {
        selectedIndex = CHART_TYPE_DENDROGRAMHORIZONTAL;
    }
    if (id == 'chartTimeSeries') {
         selectedIndex = CHART_TYPE_TIMESERIES;
    }
    if (id == 'chartTimeLine') {
         selectedIndex = CHART_TYPE_TIMELINE;
    }
    if (id == 'chartStreamgraph') {
         selectedIndex = CHART_TYPE_STREAMGRAPH;
    }
    if (id == 'chartSankey') {
        selectedIndex = CHART_TYPE_SANKEY_NEIGHBORS1;
    }
   
    if (id == 'chartTextualWords') {
        selectedIndex = CHART_TYPE_TEXTUALWORDS;
    }
    if (id == 'chartTextualWordsMetadata') {
        selectedIndex = CHART_TYPE_TEXTUALWORDSMETADATA;
    }
    return selectedIndex;
}

function getIdBychartType(chartType) {
    var selectedIndex = '';
    if (chartType == CHART_TYPE_MOLECULAR) {
        var selectedIndex = 'chartMolecular';
    }
    if (chartType == CHART_TYPE_RADIAL) {
        var selectedIndex = 'chartRadial';
    }
    if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
         var selectedIndex = 'chartDendrogram';
    }
    if (chartType == CHART_TYPE_TIMESERIES) {
         var selectedIndex = 'chartTimeSeries';
    }
    if (chartType == CHART_TYPE_TIMELINE) {
         var selectedIndex = 'chartTimeLine';
    }
    if (chartType == CHART_TYPE_STREAMGRAPH) {
        var selectedIndex = 'chartSankey';
    }
    if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
        var selectedIndex = 'chartSankey';
    }

    if (chartType == CHART_TYPE_TEXTUALWORDS) {
        var selectedIndex = 'chartTextualWords';
    }
    if (chartType == CHART_TYPE_TEXTUALWORDSMETADATA) {
        var selectedIndex = 'chartTextualWordsMetadata';
    }
    return selectedIndex;
}

/**
 * Copy text to clipboard
 * @param text
 * @returns
 */
function copyTextToClipboard(text) {
    //console.log(text);
    var textArea = document.createElement("textarea");
    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}


function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (typeof alpha !== "undefined") {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

var exportCsv = function() {
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    var curDataChartCsv = '';
    var fileNamePrefix = '';
    if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) {
        fileNamePrefix = 'Textual ';
        if (typeof window.textualWordsData[chartIndex].curDataChartCsv != "undefined") {
            curDataChartCsv = window.textualWordsData[chartIndex].curDataChartCsv;
        }
    }
    if ($('#chartType').val() == CHART_TYPE_TIMESERIES) {
        fileNamePrefix = 'TimeSeries ';
        if (typeof window.timeSeriesData[chartIndex].curDataChartCsv != "undefined") {
            curDataChartCsv = window.timeSeriesData[chartIndex].curDataChartCsv;
        }
    }
    if ($('#chartType').val() == CHART_TYPE_TIMELINE) {
        fileNamePrefix = 'TimeLine ';
        if (typeof window.timelineData[chartIndex].curDataChartCsv != "undefined") {
            curDataChartCsv = window.timelineData[chartIndex].curDataChartCsv;
        }
    }
    if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {
        fileNamePrefix = 'Streamgraph ';
        if (typeof window.sankeyData[chartIndex].curDataChartCsv != "undefined") {
            curDataChartCsv = window.sankeyData[chartIndex].curDataChartCsv;
        }
    }
    if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {
        fileNamePrefix = 'Sankey ';
        if (typeof window.sankeyData[chartIndex].curDataChartCsv != "undefined") {
            curDataChartCsv = window.sankeyData[chartIndex].curDataChartCsv;
        }
    }
    var uri = encodeURI('data:text/csv;charset=utf-8,' + curDataChartCsv);

    var a = document.createElement("a");
    
    var dataSourceLabel = '';
    if (typeof window.newValuesFormTab1.dropdownValueDSource !== "undefined") {
        $.each(DataSelectOptions, function(index, item) {
            if (item.value == window.newValuesFormTab1.dropdownValueDSource) {
                dataSourceLabel = item.label;
            }
        });
    }

    a.download = fileNamePrefix + dataSourceLabel + ' ' + $('#inputjqxWidgetDateRange').val() + '.csv';
    a.href = uri;
    document.querySelector("body").appendChild(a);
    a.click();
    document.querySelector("body").removeChild(a);
}

var exportPNG = function() {
    var chartType = $('#chartType').val();
    var dpr = 2;
    var fileNamePrefix = '';
    var svgData;
    
    if (chartType == CHART_TYPE_RADIAL) { //radial
        fileNamePrefix = 'Radial ';
        svgData = '<svg id="exportPngImg" xmlns="http://www.w3.org/2000/svg" width="'
            + $("svg").attr('width') + '" height="'
            + $("svg").attr('height') + '" id="radialSVG">' + getSVGString(window.radialData[chartIndex].svg.node()) + '</svg>';
    }
    if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) { //Dendrogram horizontal
        fileNamePrefix = 'DendrogramHorizontal ';
        svgData = '<svg id="exportPngImg" xmlns="http://www.w3.org/2000/svg" width="'
            + $("svg").attr('width') + '" height="'
            + $("svg").attr('height') + '" id="radialSVG">' + getSVGString(window.dendrogramHorizontalData[chartIndex].svg.node()) + '</svg>';
    }
    if (chartType == CHART_TYPE_MOLECULAR) { //molecular
        fileNamePrefix = 'Molecular ';
        svgData = '<svg id="exportPngImg" xmlns="http://www.w3.org/2000/svg" width="'
            + $("#dataContaner svg").attr('width') + '" height="'
            + $("#dataContaner svg").attr('height') + '" id="radialSVG">' + getSVGString(window.molecularData[chartIndex].svg.node()) + '</svg>';
        //return false;
    }
    if (chartType == CHART_TYPE_TIMESERIES) { //timeSeries
        fileNamePrefix = 'TimeSeries ';
        chartHC;
        //return false;
    }
    if ((chartType == CHART_TYPE_TEXTUALWORDS) || (chartType == CHART_TYPE_TEXTUALWORDSMETADATA)) { //words
        return false;
    }
    if (chartType == CHART_TYPE_TIMELINE) { //timeLine
        fileNamePrefix = 'TimeLine ';
        chartTimelineHC;
        //return false;
    }
    if (chartType == CHART_TYPE_STREAMGRAPH) { //sankey
        fileNamePrefix = 'Streamgraph ';
        chartStreamgraphHC;
        //return false;
    }
    if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) { //sankey
        fileNamePrefix = 'Sankey ';
        chartSankeyHC;
        //return false;
    }

    var svg = document.querySelector("exportPngImg");
    //var svgData = new XMLSerializer().serializeToString(svg);
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    var svg = document.querySelector("#dataContaner svg");
    var svgData = new XMLSerializer().serializeToString(svg);

    var canvas = document.createElement( "canvas" );
    canvas.width = $("#dataContaner svg").attr('width') * dpr;
    canvas.height = $("#dataContaner svg").attr('height') * dpr;
    
    function save( dataBlob, filesize ){
        saveAs( dataBlob, 'D3 vis exported to PNG.png' ); // FileSaver.js function
    }

    var ctx = canvas.getContext( "2d" );
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.scale(dpr, dpr);
    //ctx.drawImage(image, 0, 0);
 
    var dataUri = '';
    try {
        dataUri = 'data:image/svg+xml;base64,'+ btoa( unescape( encodeURIComponent( svgData ) ) ); // Convert SVG string to data URL
    } catch (ex) {
        // For browsers that don't have a btoa() method, send the text off to a webservice for encoding
        /* Uncomment if needed (requires jQuery)
        $.ajax({
            url: "http://www.mysite.com/webservice/encodeString",
            data: { svg: svgData },
            type: "POST",
            async: false,
            success: function(encodedSVG) {
                dataUri = 'data:image/svg+xml;base64,' + encodedSVG;
            }
        })
        */
    }
    var img = document.createElement("img");
    img.onload = function() {
        ctx.drawImage( img, 0, 0 );
        try {
            // Try to initiate a download of the image
            var a = document.createElement("a");
            
            var dataSourceLabel = '';
            if (typeof window.newValuesFormTab1.dropdownValueDSource !== "undefined") {
                $.each(DataSelectOptions, function(index, item) {
                    if (item.value == window.newValuesFormTab1.dropdownValueDSource) {
                        dataSourceLabel = item.label;
                    }
                });
            }

            a.download = fileNamePrefix + dataSourceLabel + ' ' + $('#inputjqxWidgetDateRange').val() + '.png';
            a.href = canvas.toDataURL("image/png");
            document.querySelector("body").appendChild(a);
            a.click();
            document.querySelector("body").removeChild(a);
        } catch (ex) {
            // If downloading not possible (as in IE due to canvas.toDataURL() security issue)
            // then display image for saving via right-click
            var imgPreview = document.createElement("div");
            imgPreview.appendChild(img);
            document.querySelector("body").appendChild(imgPreview);
        }
    };
    img.src = dataUri;
}


//Below are the functions that handle actual exporting:
//getSVGString ( svgNode ) and svgString2Image( svgString, width, height, format, callback )
function getSVGString( svgNode ) {
    svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
    var cssStyleText = getCSSStyles( svgNode );
    //svgNode.append('<link rel="stylesheet" href="http://test3.hce-project.com/css/site.css" type="text/css" />');
    var styleElement = document.createElement("link");
    styleElement.setAttribute("type","text/css"); 
    styleElement.setAttribute("href","stylesheet"); 
    styleElement.setAttribute("href","http://test3.hce-project.com/css/site.css"); 
    svgNode.prepend(styleElement);

    appendCSS( cssStyleText, svgNode );

    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svgNode);
    svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
    svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

    return svgString;
    
    function getAllCSS() {
        var ret = '';
        var styleSheets = document.styleSheets;
        $.each(styleSheets, function(index, styleSheet) {
            $.each(styleSheet.cssRules, function(index2, cssRules) {
                //cssText
                ret = ret + cssRules.cssText;
            });
        });
        
        return ret;
    }

    function getCSSStyles( parentElement ) {
        var selectorTextArr = [];
        // Add Parent element Id and Classes to the list
        selectorTextArr.push( '#'+parentElement.id );
        for (var c = 0; c < parentElement.classList.length; c++)
                if ( !contains('.'+parentElement.classList[c], selectorTextArr) )
                    selectorTextArr.push( '.'+parentElement.classList[c] );
        // Add Children element Ids and Classes to the list
        var nodes = parentElement.getElementsByTagName("*");
        for (var i = 0; i < nodes.length; i++) {
            var id = nodes[i].id;
            if ( !contains('#'+id, selectorTextArr) )
                selectorTextArr.push( '#'+id );
            var classes = nodes[i].classList;
            for (var c = 0; c < classes.length; c++)
                if ( !contains('.'+classes[c], selectorTextArr) )
                    selectorTextArr.push( '.'+classes[c] );
        }
        // Extract CSS Rules
        var extractedCSSText = "";
        for (var i = 0; i < document.styleSheets.length; i++) {
            var s = document.styleSheets[i];
            try {
                if(!s.cssRules) continue;
            } catch( e ) {
                    if(e.name !== 'SecurityError') throw e; // for Firefox
                    continue;
            }
            var cssRules = s.cssRules;
            for (var r = 0; r < cssRules.length; r++) {
                if ( contains( cssRules[r].selectorText, selectorTextArr ) )
                    extractedCSSText += cssRules[r].cssText;
            }
        }
        
        return extractedCSSText;

        function contains(str,arr) {
            return arr.indexOf( str ) === -1 ? false : true;
        }
    }


    function appendCSS( cssText, element ) {
        //var allCSS = getAllCSS();
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        var chartType = $('#chartType').val();
        if (chartType == CHART_TYPE_RADIAL) { //Radial
            var fontSize = 11;
            var fontSizeMult = 1;
            if (typeof window.radialData[chartIndex].fontSizeMult !== "undefined") {
                fontSizeMult = window.radialData[chartIndex].fontSizeMult;
            }
            if (typeof window.radialData[chartIndex].fontSize !== "undefined") {
                if (window.radialData[chartIndex].fontSize !== "") {
                    fontSize = window.radialData[chartIndex].fontSize;
                }
            }
        }
        if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) { //Dendrogram horizontal
            var fontSize = 11;
            var fontSizeMult = 1;
            if (typeof window.dendrogramHorizontalData[chartIndex].fontSizeMult !== "undefined") {
                fontSizeMult = window.dendrogramHorizontalData[chartIndex].fontSizeMult;
            }
            if (typeof window.dendrogramHorizontalData[chartIndex].fontSize !== "undefined") {
                if (window.dendrogramHorizontalData[chartIndex].fontSize !== "") {
                    fontSize = window.dendrogramHorizontalData[chartIndex].fontSize;
                }
            }
        }
        if (chartType == CHART_TYPE_MOLECULAR) { //Molecular
            var fontSize = 10;
            var fontSizeMult = 1;
            if (typeof window.molecularData[chartIndex].fontSizeMult !== "undefined") {
                fontSizeMult = window.molecularData[chartIndex].fontSizeMult;
            }
            if (typeof window.molecularData[chartIndex].fontSize !== "undefined") {
                if (window.molecularData[chartIndex].fontSize !== "") {
                    fontSize = window.molecularData[chartIndex].fontSize;
                }
            }
        }
        

         var fontSizeStr = '';
        var addCSSStr = ';'
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        if (chartType == CHART_TYPE_RADIAL) { //radial
            fileNamePrefix = 'Radial ';
            //var fontSizeMult = window.radialData[chartIndex].circleSizeMult;
            //fontSizeStr = 'font-size: '+ (fontSize * fontSizeMult) + 'px !important;'
            fontSizeStr = ''
        }
        if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) { //Dendrogram horizontal
            fileNamePrefix = 'DendrogramHorizontal ';
            //var fontSizeMult = window.radialData[chartIndex].circleSizeMult;
            //fontSizeStr = 'font-size: '+ (fontSize * fontSizeMult) + 'px !important;'
            //fontSizeStr = ''
            fontSizeStr = 'font-size: ' + (fontSize * fontSizeMult) + 'px;';
        }
        if (chartType == CHART_TYPE_MOLECULAR) { //molecular
            fileNamePrefix = 'Molecular ';
            //var fontSizeMul = window.radialData[chartIndex].circleSizeMult;
            fontSizeStr = 'font-size: ' + (fontSize * fontSizeMult) + 'px;';
            addCSSStr = 'text.black {'
            + 'fill: #000000;'
            + 'text-shadow: 0.5px 0.5px 0px #b6b6b6;'
            + '}'
            + '.node text {'
            + 'font: ' + (fontSize * fontSizeMult) + 'px sans-serif;'
            + 'font-size: ' + (fontSize * fontSizeMult) + 'px;'
            + 'pointer-events: none;'
            + 'text-shadow: 0.5px 0.5px 0px text-shadow;'
            + '}'
            + 'text.white {'
            + 'fill: #c9c9c9;'
            + 'text-shadow: 1px 1px 0px black;'
            + '}';
        }
        if (chartType == CHART_TYPE_TIMESERIES) { //TimeSeries
            fileNamePrefix = 'TimeSeries ';
            //var fontSizeMul = window.radialData[chartIndex].circleSizeMult;
            fontSizeStr = 'font-size: 10px;';
            addCSSStr = 'text, text tspan {'
            + 'font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;'
            + 'font-size: 12px;'
            + '}';
        }
        if (chartType == CHART_TYPE_TIMELINE) { //Timeline
            fileNamePrefix = 'TimeLine ';
            //var fontSizeMul = window.radialData[chartIndex].circleSizeMult;
            fontSizeStr = 'font-size: 10px;';
            addCSSStr = 'text, text tspan {'
            + 'font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;'
            + 'font-size: 12px;'
            + '}';
         }
        if (chartType == CHART_TYPE_STREAMGRAPH) { //Streamgraph
            fileNamePrefix = 'Streamgraph ';
            //var fontSizeMul = window.radialData[chartIndex].circleSizeMult;
            fontSizeStr = 'font-size: 10px;';
            addCSSStr = 'text, text tspan {'
            + 'font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;'
            + 'font-size: 12px;'
            + '}';
        }
        if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) { //Sankey
            fileNamePrefix = 'Sankey ';
            //var fontSizeMul = window.radialData[chartIndex].circleSizeMult;
            fontSizeStr = 'font-size: 10px;';
            addCSSStr = 'text, text tspan {'
            + 'font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;'
            + 'font-size: 12px;'
            + '}';
        }

        var cssStr = 
            'svg, g, peth {'
            + 'color: ' + $('.jqx-widget').css('color') + ';'
            + fontSizeStr
            + '-moz-text-size-adjust: none;'
            + 'font-family: Verdana, Arial, sans-serif;'
            + 'font-style: normal;'
            + 'line-height: 1.231;'
            + 'direction: ltr !important;'
            + 'cursor: default;'
            + '}'
            + ' .link {'
            + '    fill: none;'
            + '    stroke: rgb(204, 204, 204);'
            + '    stroke-width: 1.5px;'
            + '}'
            + ' .node circle {'
            + 'fill: #fff;'
            //+ 'stroke: steelblue;'
            + 'stroke-width: 1.5px;'
            + '}' + addCSSStr;
        var styleElement = document.createElement("style");
        styleElement.setAttribute("type","text/css"); 
        styleElement.innerHTML = cssText + cssStr; '.link {fill: none; stroke: #ccc; stroke-width: 1.5px;}';
        var refNode = element.hasChildNodes() ? element.children[0] : null;
        element.insertBefore( styleElement, refNode );
    }
}


function svgString2Image( svgString, width, height, format, callback ) {
    var format = format ? format : 'png';

    var imgsrc = 'data:image/svg+xml;base64,'+ btoa( unescape( encodeURIComponent( svgString ) ) ); // Convert SVG string to data URL

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = height;

    var image = new Image();
    image.onload = function() {
        context.clearRect ( 0, 0, width, height );
        context.drawImage(image, 0, 0, width, height);

        canvas.toBlob( function(blob) {
            var filesize = Math.round( blob.length/1024 ) + ' KB';
            if ( callback ) callback( blob, filesize );
        });
    };

    image.src = imgsrc;
}


function sortObjByKey(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}


/**
 * get color by color index, number from hash and array color set
 * @param n
 * @returns
 */
function getColor(n, cIndex, isCluster, itemVal, wordStr, wordIndex, formdata) {
    //console.log(n);
    var retColor = '';

    if (typeof(cIndex) === 'undefined') {
        cIndex = 0;
    }
    if (typeof(isCluster) === 'undefined') {
        isCluster = 0;
    }
    if (typeof itemVal === 'string') {
        var md5Str = itemVal;
    } else {
        var md5Str = '';
    }
    if ((typeof(itemVal) === 'undefined') || (typeof itemVal === 'string')) {
        if ((window.useAutoUnicColors == 1) && (typeof(window.wordsListColorsAuto) !== 'undefined')
            && (Object.keys(window.wordsListColorsAuto).indexOf(md5Str) > -1)) {
            retColor = window.wordsListColorsAuto[md5Str];
            //console.log('color from window.wordsListColorsAuto', retColor);
        }
        if (retColor == '') {
            if (typeof(window.colorsArrayUsedMinIndex[cIndex]) === 'undefined') {
                window.colorsArrayUsedMinIndex[cIndex] = 0;
            }
            if ((typeof formdata !== "undefined") && (typeof formdata.dropdownColorsSelection !== "undefined")) {
                dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
            } else {
                dropdownColorsSelection = 1;
            }
            if (window.colorsArraySetIndex == 13) {
                if (dropdownColorsSelection == 1) {
                    retColor = getColorByStr(wordStr);
                } else if (dropdownColorsSelection == 10) {
                    if (itemVal > 0) {
                        retColor = getColorByStr(CryptoJS.MD5('1').toString());
                    } else if (itemVal < 0) {
                        retColor = getColorByStr(CryptoJS.MD5('2').toString());
                    } else {
                        retColor = getColorByStr(CryptoJS.MD5('3').toString());
                    }
                } else {
                    retColor = getColorByStr(CryptoJS.MD5(wordIndex*10+'').toString());
                }
            } else if(window.colorsArraySetIndex == 14) {
                if (dropdownColorsSelection == 1) {
                    retColor = stringToColour(wordStr);
                } else if (dropdownColorsSelection == 10) {
                    if (itemVal > 0) {
                        retColor = stringToColour(CryptoJS.MD5('1').toString());
                    } else if (itemVal < 0) {
                        retColor = stringToColour(CryptoJS.MD5('2').toString());
                    } else {
                        retColor = stringToColour(CryptoJS.MD5('3').toString());
                    }
                } else {
                    retColor = stringToColour(CryptoJS.MD5(wordIndex*10+'').toString());
                }
                //retColor = (wordStr);
            } else {
                var colorNumber = (n) % window.colorsArray[window.colorsArraySetIndex].length;
                retColor = window.colorsArray[window.colorsArraySetIndex][colorNumber];
                if (typeof(window.colorsArrayUsed[cIndex]) === 'undefined') {
                    window.colorsArrayUsed[cIndex] = [];
                }
                if (isCluster  == 1) {
                    retColor = window.colorsArray[window.colorsArraySetIndex][colorNumber];
                } else {
                    retColor = window.colorsArray[window.colorsArraySetIndex][colorNumber];
                    /*if (typeof(window.colorsArrayUsed[cIndex][colorNumber]) === 'undefined') {
                        window.colorsArrayUsed[cIndex][colorNumber] = 1;
                    } else {
                        var isColorChanged = 0;
                        for (var i = 0; i < window.colorsArray[window.colorsArraySetIndex].length; i++) {
                            if (typeof(window.colorsArrayUsed[cIndex][i]) === 'undefined') {
                                //console.log('color changed index= ', i, ' color= ', retColor, ' new color= ', window.colorsArray[i]);
                                retColor = window.colorsArray[window.colorsArraySetIndex][i];
                                window.colorsArrayUsed[cIndex][i] = 1;
                                isColorChanged = 1;
                                return retColor;
                            }
                        }
                        if (isColorChanged == 0) {
                            for (var i = 0; i < window.colorsArray[window.colorsArraySetIndex].length; i++) {
                                if (window.colorsArrayUsed[cIndex][i] == window.colorsArrayUsedMinIndex[cIndex]) {
                                    //console.log('222 color changed index= ', i, ' color= ', retColor, ' new color= ', window.colorsArray[i]);
                                    retColor = window.colorsArray[window.colorsArraySetIndex][i];
                                    window.colorsArrayUsed[cIndex][i]++;
                                    isColorChanged = 1;
                                    return retColor;
                                }
                            }
                            if (isColorChanged == 0) {
                                window.colorsArrayUsedMinIndex[cIndex]++;
                            }
                            for (var i = 0; i < window.colorsArray[window.colorsArraySetIndex].length; i++) {
                                if (window.colorsArrayUsed[cIndex][i] < window.colorsArrayUsedMinIndex[cIndex]) {
                                    //console.log('333 color changed index= ', i, ' color= ', retColor, ' new color= ', window.colorsArray[i]);
                                    retColor = window.colorsArray[window.colorsArraySetIndex][i];
                                    window.colorsArrayUsed[cIndex][i]++;
                                    isColorChanged = 1;
                                    return retColor;
                                }
                            }
                        }
                    }*/
                }
            }
        }
    } else {
        if (itemVal > 0) {
            retColor = window.colorsArray[window.colorsArraySetIndex][0];
        } else if (itemVal < 0) {
            retColor = window.colorsArray[window.colorsArraySetIndex][1];
        } else {
            retColor = window.colorsArray[window.colorsArraySetIndex][2];
        }
    }
    /*{"%VALUE%>0": {"html-tag": "<span class=\"ICONPOSITIVE\"></span>"}}, 
    {"%VALUE%<0": {"html-tag": "<span class=\"ICONNEGATIVE\"></span>"}},
    {"%VALUE%==0": {"html-tag": "<span class=\"ICONNEUTRAL\"></span>"}}
    */
    //console.log('getColor ', itemVal, wordStr, wordIndex, n, cIndex, retColor);
    return retColor;
}

function getSentimentColor(index, averageValues, maxValues, minValues, formdata) {
    var curColor = getColor(index, undefined, undefined, averageValues[index], undefined, undefined, formdata);
    var maxColor = getColor(index, undefined, undefined, Math.round(maxValues[index]), undefined, undefined, formdata);
    var minColor = getColor(index, undefined, undefined, Math.round(minValues[index]), undefined, undefined, formdata);
    var opacityMax, opacityMin;

    if ((maxValues[index] == 0) && (minValues[index] == 0)) {
        opacityMax = 0.5;
        opacityMin = 0.5;
    } else if ((maxValues[index] < 0) && (minValues[index] < 0)) {
        opacityMax = 0;
        opacityMin = 0.5;
    } else if ((maxValues[index] > 0) && (minValues[index] > 0)) {
        opacityMax = 0.5;
        opacityMin = 0.1;
    } else if ((maxValues[index] > 0) && (minValues[index] < 0)) {
        opacityMax = 0.3;
        opacityMin = 0.3;
    } else if ((maxValues[index] > 0) && (minValues[index] == 0)) {
        opacityMax = 0.6;
        opacityMin = 0;
    } else if ((maxValues[index] == 0) && (minValues[index] < 0)) {
        opacityMax = 0;
        opacityMin = 0.5;
    } else {
        opacityMax = 0.5;
        opacityMin = 0.5;
    }

    return {'curColor': curColor, 'maxColor': maxColor, 'minColor': minColor, 'opacityMax': opacityMax, 'opacityMin': opacityMin};
}

function initGetColors(chartType, chartIndex) {
    //console.log(chartType, chartIndex);
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    if (typeof(window.md5ToColors) === 'undefined') {
        window.md5ToColors = {};
        window.md5ToColorsDirty = 0;
    }
    if (window.md5ToColorsDirty > 1) {
        window.md5ToColorsDirty = 0;
        window.md5ToColors = {};
    }
    window.wordsListColorsAuto = {};
    if (typeof(window.usedColorsArr) === 'undefined') {
        window.usedColorsArr = [];
        window.usedColorsArr[chartIndex] = [];
    }
    window.usedColorsArr[chartIndex][chartType] = [];
    //console.log(window.usedColorsArr[chartIndex][chartType]);
    window.useAutoUnicColors = 0;
    window.getColorSettings = {
        'chartType': chartType,
        'chartIndex': chartIndex
    }
}

function getColorUnic(md5Str, chartType, chartIndex, n, cIndex, isCluster, itemVal, md5Str2) {
    var retColor;
    var resColor;
    //window.usedColorsArr = [];
    //window.usedColorsArr[chartIndex][CHART_TYPE_DENDROGRAMHORIZONTAL]
    if (typeof(itemVal) === 'undefined') {
        if (Object.keys(window.wordsListColorsAuto).length == 0) {
            //console.log('1');
            if (typeof(window.md5ToColors[md5Str]) === 'undefined') {
            //console.log('2');
                resColor = getColor(n, cIndex, isCluster, itemVal);
                //console.log(chartType, chartIndex);
                //console.log(window.usedColorsArr[chartIndex][chartType]);
                if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                //console.log('3');
                    window.usedColorsArr[chartIndex][chartType].push(resColor);
                } else {
                //console.log('4');
                    resColor = getFreeColor(md5Str, resColor, chartType, chartIndex);
                    if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                //console.log('5');
                        window.usedColorsArr[chartIndex][chartType].push(resColor);
                    }
                }
                window.md5ToColors[md5Str] = resColor;
                if (typeof md5Str2 !== "undefined") {
                //console.log('31');
                    window.md5ToColors[md5Str2] = resColor;
                }
            } else {
            //console.log('6');
                resColor = window.md5ToColors[md5Str];
                if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
            //console.log('7');
                    window.usedColorsArr[chartIndex][chartType].push(resColor);
                } else {
            //console.log('8');
                    resColor = getFreeColor(md5Str, resColor, chartType, chartIndex);
                    if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
            //console.log('10');
                        window.usedColorsArr[chartIndex][chartType].push(resColor);
                    }
                    window.md5ToColors[md5Str] = resColor;
                    if (typeof md5Str2 !== "undefined") {
         //console.log('30');
                        window.md5ToColors[md5Str2] = resColor;
                    }
                }
            }
        } else {
            //console.log('11');
            if (typeof(window.wordsListColorsAuto[md5Str]) === 'undefined') {
                //console.log('');
                if (typeof(window.md5ToColors[md5Str]) === 'undefined') {
                    //console.log('12');
                    resColor = getColor(n, cIndex, isCluster, itemVal);
                    //console.log(chartType, chartIndex);
                    //console.log(window.usedColorsArr[chartIndex][chartType]);
                    if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                        //console.log('13');
                        window.usedColorsArr[chartIndex][chartType].push(resColor);
                    } else {
                        //console.log('14');
                        resColor = getFreeColor(md5Str, resColor, chartType, chartIndex);
                        if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                            //console.log('15');
                            window.usedColorsArr[chartIndex][chartType].push(resColor);
                        }
                    }
                    window.md5ToColors[md5Str] = resColor;
                    if (typeof md5Str2 !== "undefined") {
                        //console.log('29');
                        window.md5ToColors[md5Str2] = resColor;
                    }
                } else {
                    //console.log('16');
                    resColor = window.md5ToColors[md5Str];
                    if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                    //console.log('17');
                        window.usedColorsArr[chartIndex][chartType].push(resColor);
                    } else {
                        //console.log('18');
                        resColor = getFreeColor(md5Str, resColor, chartType, chartIndex);
                        //window.usedColorsArr[chartIndex][chartType].push(resColor);
                        if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                        //console.log('20');
                            window.usedColorsArr[chartIndex][chartType].push(resColor);
                        }
                        window.md5ToColors[md5Str] = resColor;
                        if (typeof md5Str2 !== "undefined") {
                        //console.log('28');
                            window.md5ToColors[md5Str2] = resColor;
                        }
                    }
                }
            } else {
                //console.log('21');
                resColor = window.wordsListColorsAuto[md5Str];
                //console.log(window.wordsListColorsAuto);
                if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                //console.log('22');
                    window.usedColorsArr[chartIndex][chartType].push(resColor);
                } else {
                    //console.log('23');
                    if (typeof md5Str2 !== "undefined") {
                        //console.log('25');
                       delete window.md5ToColors[md5Str2];
                    }
                    resColor = getFreeColor(md5Str, resColor, chartType, chartIndex);
                    //window.usedColorsArr[chartIndex][chartType].push(resColor);
                    if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
                        //console.log('26');
                        window.usedColorsArr[chartIndex][chartType].push(resColor);
                    }
                    window.md5ToColors[md5Str] = resColor;
                    if (typeof md5Str2 !== "undefined") {
                        //console.log('27');
                        window.md5ToColors[md5Str2] = resColor;
                    }
                }
            }
        }
    } else {
        //console.log('24');
        resColor = getColor(n, cIndex, isCluster, itemVal);
    }
    if (window.usedColorsArr[chartIndex][chartType].indexOf(resColor) == -1) {
        //console.log('33');
        window.usedColorsArr[chartIndex][chartType].push(resColor);
    }
    retColor = resColor;
    //console.log('getColorUnic result color: ', retColor);
    
    return retColor;
}

function getFreeColor(md5Str, resColor, chartType, chartIndex) {
    var setNewColor = 0;
    var j = 0;
    for (j = 0; j < window.colorsArray[window.colorsArraySetIndex].length; j++) {
        if (setNewColor == 0) {
            if (window.usedColorsArr[chartIndex][chartType].indexOf(window.colorsArray[window.colorsArraySetIndex][j]) == -1) {
                setNewColor = 1;
                resColor = window.colorsArray[window.colorsArraySetIndex][j];
                window.wordsListColorsAuto[md5Str] = window.colorsArray[window.colorsArraySetIndex][j];
                window.usedColorsArr[chartIndex][chartType].push(window.colorsArray[window.colorsArraySetIndex][j]);
            }
        }
    }
    
    if (setNewColor == 0) {
        if ((window.colorsArraySetIndex >= 6) && (window.colorsArraySetIndex < 9)) {
            window.colorsArraySetIndex++;
        } else if (window.colorsArraySetIndex == 9) {
            window.colorsArraySetIndex = 1;
        }
    }
    for (j = 0; j < window.colorsArray[window.colorsArraySetIndex].length; j++) {
        if (setNewColor == 0) {
            if (window.usedColorsArr[chartIndex][chartType].indexOf(window.colorsArray[window.colorsArraySetIndex][j]) == -1) {
                setNewColor = 1;
                resColor = window.colorsArray[window.colorsArraySetIndex][j];
                window.wordsListColorsAuto[md5Str] = window.colorsArray[window.colorsArraySetIndex][j];
                window.usedColorsArr[chartIndex][chartType].push(window.colorsArray[window.colorsArraySetIndex][j]);
            }
        }
    }
    if (setNewColor == 0) {
        window.md5ToColorsDirty++;
    }

    return resColor;
}

function getColorIndex(val) {
    var colorIndex;
    if (val < window.colorsArray[window.colorsArraySetIndex].length) {
        colorIndex = val;
    } else {
        colorIndex = Math.ceil(val / window.colorsArray[window.colorsArraySetIndex].length) + val % window.colorsArray[window.colorsArraySetIndex].length;
        if (colorIndex >= window.colorsArray[window.colorsArraySetIndex].length) {
            colorIndex = getColorIndex(colorIndex);
        } else if (colorIndex == 0) {
            colorIndex = val % window.colorsArray[window.colorsArraySetIndex].length;
        }
    }
    return colorIndex;
}

function getAutoUnicColorsForWordsList(wordsListArr, chartType, chartIndex, cIndex, isCluster, itemVal, wordsListMd5) {
    //console.log('getAutoUnicColorsForWordsList', wordsListArr, wordsListArr.length, wordsListArr[0]);
    window.useAutoUnicColors = 0;
    var usedColorsArr = [];
    var autoUnicColorsForWordsList = [];
    var i, k;
    var wordsListMd5New = [];
    //window.colorsArraySetIndex
    //window.colorsArray[window.colorsArraySetIndex]
    for (i = 0; i < wordsListArr.length; i++) {
        var md5Str = CryptoJS.MD5(wordsListArr[i]).toString();
        //wordsListMd5New.push(md5Str);
        wordsListMd5New[i] = md5Str;
        var colorIndex = hexToDecColor(md5Str);
        
        //console.log('getAutoUnicColorsForWordsList ', colorIndex, getColorIndex(colorIndex));
        //console.log(wordsListArr[i], colorIndex);
        //var wordStr = wordsListArr[i];
        //var itemWordColor = {};
        //itemWordColor[wordsListArr[i]] = getColor(colorIndex, undefined, undefined, undefined);
        //console.log(itemWordColor);
        var newColor = getColorUnic(md5Str, chartType, chartIndex, colorIndex, cIndex, isCluster, itemVal);
        //console.log('getColorUnic ', wordsListArr[i], md5Str, newColor);
        autoUnicColorsForWordsList[wordsListArr[i]] = newColor;
        window.wordsListColorsAuto[md5Str] = newColor;
        /*if (typeof wordsListMd5 !== "undefined") {
            if (typeof wordsListMd5[i] !== "undefined") {
                window.wordsListColorsAuto[wordsListMd5[i]] = newColor;
            }
        }*/
    }
    //console.log(autoUnicColorsForWordsList, autoUnicColorsForWordsList.length);
    window.autoUnicColorsForWordsList = autoUnicColorsForWordsList;
    //window.wordsListMd5 = wordsListMd5;
    //window.wordsListMd5New = wordsListMd5New;
    window.useAutoUnicColors = 1;

    return autoUnicColorsForWordsList;
}


function getAutoUnicColorsForWordsList2(wordsListArr) {
    //console.log('getAutoUnicColorsForWordsList', wordsListArr, wordsListArr.length, wordsListArr[0]);
    var usedColorsArr = [];
    var autoUnicColorsForWordsList = [];
    var i, k;
    //window.colorsArraySetIndex
    //window.colorsArray[window.colorsArraySetIndex]
    for (i = 0; i < wordsListArr.length; i++) {
        var colorIndex = hexToDecColor(wordsListArr[i]);
        //console.log(wordsListArr[i], colorIndex);
        //var wordStr = wordsListArr[i];
        //var itemWordColor = {};
        //itemWordColor[wordsListArr[i]] = getColor(colorIndex, undefined, undefined, undefined);
        //console.log(itemWordColor);
        autoUnicColorsForWordsList[wordsListArr[i]] = getColor(colorIndex, undefined, undefined, undefined);
    }
    //console.log(autoUnicColorsForWordsList, autoUnicColorsForWordsList.length);
    window.autoUnicColorsForWordsList = autoUnicColorsForWordsList;
    var autoUnicColorsForWordsListKeys = Object.keys(autoUnicColorsForWordsList);
    for (k = 0; k < autoUnicColorsForWordsListKeys.length; k++) {
    //$.each(autoUnicColorsForWordsList, function(index, item) {
        //console.log('index = ', autoUnicColorsForWordsListKeys[k], ' item = ', autoUnicColorsForWordsList[autoUnicColorsForWordsListKeys[k]]);
        if (usedColorsArr.indexOf(autoUnicColorsForWordsList[autoUnicColorsForWordsListKeys[k]]) == -1) {
            usedColorsArr.push(autoUnicColorsForWordsList[autoUnicColorsForWordsListKeys[k]]);
        } else {
            var setNewColor = 0;
            var j = 0;
            for (j = 0; j < window.colorsArray[window.colorsArraySetIndex].length; j++) {
                if (setNewColor == 0) {
                    if (usedColorsArr.indexOf(window.colorsArray[window.colorsArraySetIndex][j]) == -1) {
                        setNewColor = 1;
                        autoUnicColorsForWordsList[autoUnicColorsForWordsListKeys[k]]= window.colorsArray[window.colorsArraySetIndex][j];
                        usedColorsArr.push(window.colorsArray[window.colorsArraySetIndex][j]);
                    }
                }
            }
        }
    }//);
    //console.log(autoUnicColorsForWordsList, usedColorsArr);

    return autoUnicColorsForWordsList;
}


async function getColorByStr(text, minLightness = 40, maxLightness = 80, minSaturation = 30, maxSaturation = 100) {
    let hash = await CryptoJS.subtle.digest("SHA-1", new TextEncoder().encode(text));
    hash = new Uint8Array(hash).join("").slice(16);

    var resColor = "hsl(" + (hash % 360) + ", " + (hash % (maxSaturation - minSaturation) 
        + minSaturation) + "%, " + (hash % (maxLightness - minLightness) + minLightness) + "%)";
    //console.log(resColor);
    return resColor;
}

function stringToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    //console.log(colour);
    return colour;
}

function stringToColour2(name) {
    var n = 'abcdefghijklmnopqrstuvwxyz'.split('');
    var r = name.split('').map(function(e) {return n.indexOf(e);}).join('');
    var l = parseFloat( '0.'+ (r*r*1000).toString().replace(/^0/, ''));
    //console.log('#' + Math.floor(l*16777215).toString(16));
    return '#' + Math.floor(l*16777215).toString(16);
}

function stringToColour3(string) {
    return  '#' + CryptoJS.MD5(string).toString().slice(9, 15);
}
 

function getDashStyle(n, cIndex, isCluster, wordsListLength) {
    var dashStyles = [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
    ];
    if (typeof(cIndex) === 'undefined') {
        cIndex = 0;
    }
    if (typeof(isCluster) === 'undefined') {
        isCluster = 0;
    }
    if (typeof(window.colorsArrayUsedMinIndex[cIndex]) === 'undefined') {
        window.colorsArrayUsedMinIndex[cIndex] = 0;
    }
    //var setLength = dashStyles.length * window.colorsArray[window.colorsArraySetIndex].length; 
    //var colorNumber = (n) % (window.colorsArray[window.colorsArraySetIndex].length * dashStyles.length);
    var retDashStyle = 'Solid';
    //retDashStyle = window.colorsArray[window.colorsArraySetIndex][colorNumber];
    if (typeof(window.colorsArrayUsed[cIndex]) === 'undefined') {
        //window.colorsArrayUsed[cIndex] = [];
    }
    if (isCluster  == 1) {
        retDashStyle = 'Solid';
    } else {
        //retDashStyle = dashStyles[(Math.floor(n / window.colorsArray[window.colorsArraySetIndex].length ) % dashStyles.length)];
        retDashStyle = dashStyles[(Math.floor(n / wordsListLength ) % dashStyles.length)];
    }
    
    return retDashStyle;
}

/**
 * 
 * @param val - md5 hex hash string
 * @returns
 */
function hexToDecColor1(val) {
    // Reversed the order because the added values need to 16^i for each value since 'F' is position 1 and 'E' is position 0
    var hex = val.toUpperCase().split('');
    // Set the Decimal variable as a integer
    var dec = 0;
    // Loop through the length of the hex to iterate through each character
    for (i = 0; i < hex.length; i++) {
        // Obtain the numeric value of the character A=10 B=11 and so on..
        // you could also change this to var conv = parseInt(hex[i], 16) instead
        var conv = '0123456789ABCDEF'.indexOf(hex[i]);
        // Calculation performed is the converted value * (16^i) basedObject.keys( on the position of the character
        // This is then added to the original dec variable.  'FE' for example
        // in Reverse order [E] = (14 * (16 ^ 0)) + [F] = (15 * (16 ^ 1)) 
        //dec += conv * Math.pow(16, i);
        dec += conv * i;
    }
    // Returns the added decimal value
    return dec;
}

function hexToDecColor2(val) {
    parseInt("6cc56362a1ca955a0f79f25f3304f24f", 16)/Math.pow(16, 31);
    val = val.replace(' ','');
    var hex = val.split('');
    // Set the Decimal variable as a integer
    var dec = 0;
    //var ii = Math.ceil(hex.length/2);
    //dec = ((val).charCodeAt(0) + (val).charCodeAt(2) + (val).charCodeAt(ii)*1 + (val).charCodeAt(hex.length - 1) * 1) -32*4;
    
    for (i = 0; i < hex.length; i++) {
        var conv = ((val).charCodeAt(i) - 32) * (i + 1);
        dec += conv;
    }
    dec = Math.ceil(dec);// % (hex.length * hex.length);
    // Returns the added decimal value
    //console.log('val = ', val, 'dec = ', dec);
    return dec;
}

function hexToDecColor(val) {
    if (val.length != 32) {
        var md5Str = CryptoJS.MD5(val).toString();
    } else {
        var md5Str = val;
    }
    var dec = Math.ceil(parseInt(md5Str, 16)/Math.pow(16, 31) * 100);
    //console.log(dec);
    return dec;
}


function splitHexColor(hex) {
    var c;
    if (hex.length === 4) {
        c = (hex.replace('#','')).split('');
        return {
            r: parseInt((c[0] + c[0]), 16),
            g: parseInt((c[1] + c[1]), 16),
            b: parseInt((c[2] + c[2]), 16)
        };
    } else {
        return {
            r: parseInt(hex.slice(1,3), 16),
            g: parseInt(hex.slice(3,5), 16),
            b: parseInt(hex.slice(5), 16)
        };
    }
}

/**
* Helper callback method for the showChart() function.
* @param object node 
* @return string font size value
*/
function adaptLabelFontSize(d) {
    if (isDebugg) {console.log('20');}
    var xPadding, diameter, labelAvailableWidth, labelWidth;
    xPadding = 0;
    var fontSizeMult = 1;
    var fontSize = '';
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    var chartType = $('#chartType').val();
    if (chartType == CHART_TYPE_RADIAL) { //Radial
        diameter = window.radialData[chartIndex].radiusItem(d.size) * 2;
        multiplier = window.radialData[chartIndex].circleSizeMult * window.radialData[chartIndex].radiusMult * 1.7;
        if (typeof window.radialData[chartIndex].fontSizeMult !== "undefined") {
            fontSizeMult = window.radialData[chartIndex].fontSizeMult;
        }
        if (typeof window.radialData[chartIndex].fontSize !== "undefined") {
            fontSize = window.radialData[chartIndex].fontSize;
        }
    }
    if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) { //
        diameter = window.dendrogramHorizontalData[chartIndex].radiusItem(d.size) * 2;
        multiplier = window.dendrogramHorizontalData[chartIndex].circleSizeMult * window.dendrogramHorizontalData[chartIndex].radiusMult * 1.7;
        if (typeof window.dendrogramHorizontalData[chartIndex].fontSizeMult !== "undefined") {
            fontSizeMult = window.dendrogramHorizontalData[chartIndex].fontSizeMult;
        }
        if (typeof window.dendrogramHorizontalData[chartIndex].fontSize !== "undefined") {
            fontSize = window.dendrogramHorizontalData[chartIndex].fontSize;
        }
    }
    if (chartType == CHART_TYPE_MOLECULAR) { //Molecular
        diameter = window.molecularData[chartIndex].radius(d.size) * 2;
        multiplier = window.molecularData[chartIndex].circleSizeMult;
        if (typeof window.molecularData[chartIndex].fontSizeMult !== "undefined") {
            fontSizeMult = window.molecularData[chartIndex].fontSizeMult;
        }
        if (typeof window.molecularData[chartIndex].fontSize !== "undefined") {
            fontSize = window.molecularData[chartIndex].fontSize;
        }
    }
    
    //window.radialData[chartIndex].radius
    //window.radialData[chartIndex].circleSizeMult
    
    if (fontSize/*.length*/ === '') {
        labelAvailableWidth = diameter - xPadding;
        labelWidth = this.getComputedTextLength();
        val = multiplier * diameter/100.0;
        if (val >= 1.3) {
            val = 1.3;
        } else if (val <= 1.3 && val >= 1.1) {
            val = 1.2;
        } else if (val <= 1.1 && val >= 1) {
            val = 1.1;
        } else if (val <= 1 && val >= 0.7) {
            val = 1;
        } else if (val <= 0.7 && val >= 0.5) {
            val = 0.9;
        } else if (val <= 0.5 && val >= 0.2) {
            val = 0.9;
        } else if (val <= 0.2 && val >= 0.1) {
            val = 0.9;
        } else {
            val = 0.9;
        }
        return val * fontSizeMult + 'em';
    } else {
        val = fontSize;
        return val * fontSizeMult + 'px';
    }
}


function setAutoBackgroundColor() {
    var BGColor = 'rgba(0, 0, 0, 0)';
    switch(theme) {
        case 'dark':
            BGColor = '#252830';
            break;
        case 'metrodark':
            BGColor = '#252526';
            break;
        case 'black':
            BGColor = '#252830';
            break;
        case 'ui-lightness':
            BGColor = '#F7F7F7';
            break;
        case 'fresh':
            BGColor = '#F7F7F7';
            break;
        case 'ui-darkness':
            BGColor = '#000000';
            break;
        case 'ui-start':
            BGColor = '#FCFDFD';
            break;
        case 'ui-redmond':
            BGColor = '#FCFDFD';
            break;
        case 'ui-sunny':
            BGColor = '#FEEEBD';
            break;
        case 'ui-overcast':
            BGColor = '#C9C9C9';
            break;
        case 'ui-le-frog':
            BGColor = '#285C00';
            break;
      default:
          BGColor = 'rgba(0, 0, 0, 0)';
    }
    $('#dataContaner').css('background-color', BGColor);
    return BGColor;
}

function showHideTitle () {
    var chartType = $('#chartType').val();
    if (chartType == CHART_TYPE_TIMESERIES){//timeseries
        if (typeof curDataChartTS != "undefined") {
            initPrepareFormData();
            //showChart(curDataChartTS, window.newValuesFormTab1);
            if (window.newValuesFormTab1.checkboxShowHideTitleValue == true) {
                var data = curDataChartTS.result;
                var addData = curDataChartTS.addData;
                if (typeof chartIndex === "undefined") {
                    var chartIndex = 0;
                }
                if (typeof addData !== "undefined") {
                    addData = JSON.parse(addData);
                    window.timeSeriesData[chartIndex].addData = addData;
                } else {
                    addData = window.timeSeriesData[chartIndex].addData;
                    if (typeof addData == "undefined") {
                        addData = {};
                    }
                }
                if (typeof addData.sourceTitle !== "undefined") {
                    window.timeSeriesData[chartIndex].sourceTitle = addData.sourceTitle;
                }
                if (typeof addData.dateFrom !== "undefined") {
                    window.timeSeriesData[chartIndex].dateFrom = addData.dateFrom;
                }
                if (typeof addData.dateTo !== "undefined") {
                    window.timeSeriesData[chartIndex].dateTo = addData.dateTo;
                }
                if (data.countDates <= 31) {
                    var intervalInDays = 24 * 3600 * 1000;
                } else {
                    var intervalInDays = undefined;
                }
                if (typeof window.newValuesFormTab1.dropdownValueVIndicator !== "undefined") {
                    var valueVIndicator = getValueIndicatorTextById(window.newValuesFormTab1.dropdownValueVIndicator);
                } else {
                    var valueVIndicator = getValueIndicatorTextById(valueDefault);
                }
                if (typeof window.newValuesFormTab1.textBoxTitleTimeCharts !== "undefined") {
                    var textBoxTitleTimeCharts = window.newValuesFormTab1.textBoxTitleTimeCharts;
                } else {
                    var textBoxTitleTimeCharts = '';
                }
                var chartViewLabel = '';
                if (typeof window.newValuesFormTab1.dropdownChartView !== "undefined") {
                    $.each(sourceChartView, function(index, item) {
                        if (item.value == window.newValuesFormTab1.dropdownChartView) {
                            chartViewLabel = item.label;
                        }
                    });
                }
                var dataSourceLabel = '';
                if (typeof window.newValuesFormTab1.dropdownValueDSource !== "undefined") {
                    $.each(DataSelectOptions, function(index, item) {
                        if (item.value == window.newValuesFormTab1.dropdownValueDSource) {
                            dataSourceLabel = item.label;
                        }
                    });
                }
                textBoxTitleTimeCharts = textBoxTitleTimeCharts
                .replaceAll('%VALUE_INDICATOR%', valueVIndicator)
                .replaceAll('%DATE_FROM%', window.timeSeriesData[chartIndex].dateFrom)
                .replaceAll('%DATE_TO%', window.timeSeriesData[chartIndex].dateTo)
                .replaceAll('%DATA_SOURCE%', dataSourceLabel)
                .replaceAll('%CHART_VIEW%', chartViewLabel);
                if (typeof window.newValuesFormTab1.textBoxSubtitleTimeCharts !== "undefined") {
                    var textBoxSubtitleTimeCharts = window.newValuesFormTab1.textBoxSubtitleTimeCharts;
                } else {
                    var textBoxSubtitleTimeCharts = 'Data source: %DATA_SOURCE% (%CHART_VIEW%)';
                }
                textBoxSubtitleTimeCharts = textBoxSubtitleTimeCharts
                    .replaceAll('%VALUE_INDICATOR%', valueVIndicator)
                    .replaceAll('%DATE_FROM%', window.timeSeriesData[chartIndex].dateFrom)
                    .replaceAll('%DATE_TO%', window.timeSeriesData[chartIndex].dateTo)
                    .replaceAll('%DATA_SOURCE%', dataSourceLabel)
                    .replaceAll('%CHART_VIEW%', chartViewLabel);
                if (typeof window.newValuesFormTab1.textBoxDescriptionTimeCharts !== "undefined") {
                    var textBoxDescriptionTimeCharts = window.newValuesFormTab1.textBoxDescriptionTimeCharts;
                } else {
                    var textBoxDescriptionTimeCharts = 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%';
                }
                textBoxDescriptionTimeCharts = textBoxDescriptionTimeCharts
                    .replaceAll('%VALUE_INDICATOR%', valueVIndicator)
                    .replaceAll('%DATE_FROM%', window.timeSeriesData[chartIndex].dateFrom)
                    .replaceAll('%DATE_TO%', window.timeSeriesData[chartIndex].dateTo)
                    .replaceAll('%DATA_SOURCE%', dataSourceLabel)
                    .replaceAll('%CHART_VIEW%', chartViewLabel);
                
                chartHC.update({title: {
                    text: textBoxTitleTimeCharts
                }});
                chartHC.update({subtitle: {
                    text:  '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    +'<center>' + textBoxDescriptionTimeCharts + '</cener>',
                    useHTML: true
                }});
            } else {
                chartHC.update({title: {
                    text: ' '
                }});
                chartHC.update({subtitle: {
                    text: ' ',
                    useHTML: false
                }});
            }
        }
    } else if (chartType == CHART_TYPE_TIMELINE){//timeline
        if (typeof curDataChartTL != "undefined") {
            initPrepareFormData();
            //showChart(curDataChartTL, window.newValuesFormTab1);
            if (window.newValuesFormTab1.checkboxShowHideTitleValue == true) {
                var data = curDataChartTL.result;
                var addData = curDataChartTL.addData;
                if (typeof chartIndex === "undefined") {
                    var chartIndex = 0;
                }
                if (typeof addData !== "undefined") {
                    addData = JSON.parse(addData);
                    window.timelineData[chartIndex].addData = addData;
                } else {
                    addData = window.timelineData[chartIndex].addData;
                    if (typeof addData == "undefined") {
                        addData = {};
                    }
                }
                if (typeof addData.sourceTitle !== "undefined") {
                    window.timelineData[chartIndex].sourceTitle = addData.sourceTitle;
                }
                if (typeof addData.dateFrom !== "undefined") {
                    window.timelineData[chartIndex].dateFrom = addData.dateFrom;
                }
                if (typeof addData.dateTo !== "undefined") {
                    window.timelineData[chartIndex].dateTo = addData.dateTo;
                }
                if (data.countDates <= 31) {
                    var intervalInDays = 24 * 3600 * 1000;
                } else {
                    var intervalInDays = undefined;
                }
                if (typeof window.newValuesFormTab1.dropdownValueVIndicator !== "undefined") {
                    var valueVIndicator = getValueIndicatorTextById(window.newValuesFormTab1.dropdownValueVIndicator);
                } else {
                    var valueVIndicator = getValueIndicatorTextById(valueDefault);
                }
                if (typeof window.newValuesFormTab1.textBoxTitleTimeCharts !== "undefined") {
                    var textBoxTitleTimeCharts = window.newValuesFormTab1.textBoxTitleTimeCharts;
                } else {
                    var textBoxTitleTimeCharts = '';
                }
                var chartViewLabel = '';
                var dataSourceLabel = '';
                if (typeof window.newValuesFormTab1.dropdownValueDSource !== "undefined") {
                    $.each(DataSelectOptions, function(index, item) {
                        if (item.value == window.newValuesFormTab1.dropdownValueDSource) {
                            dataSourceLabel = item.label;
                        }
                    });
                }
                textBoxTitleTimeCharts = textBoxTitleTimeCharts
                .replaceAll('%VALUE_INDICATOR%', valueVIndicator)
                .replaceAll('%DATE_FROM%', window.timelineData[chartIndex].dateFrom)
                .replaceAll('%DATE_TO%', window.timelineData[chartIndex].dateTo)
                .replaceAll('%DATA_SOURCE%', dataSourceLabel)
                .replaceAll('%CHART_VIEW%', chartViewLabel);
                if (typeof window.newValuesFormTab1.textBoxSubtitleTimeCharts !== "undefined") {
                    var textBoxSubtitleTimeCharts = window.newValuesFormTab1.textBoxSubtitleTimeCharts;
                } else {
                    var textBoxSubtitleTimeCharts = 'Data source: %DATA_SOURCE%';
                }
                textBoxSubtitleTimeCharts = textBoxSubtitleTimeCharts
                    .replaceAll('%VALUE_INDICATOR%', valueVIndicator)
                    .replaceAll('%DATE_FROM%', window.timelineData[chartIndex].dateFrom)
                    .replaceAll('%DATE_TO%', window.timelineData[chartIndex].dateTo)
                    .replaceAll('%DATA_SOURCE%', dataSourceLabel)
                    .replaceAll('%CHART_VIEW%', chartViewLabel);
                if (typeof window.newValuesFormTab1.textBoxDescriptionTimeCharts !== "undefined") {
                    var textBoxDescriptionTimeCharts = window.newValuesFormTab1.textBoxDescriptionTimeCharts;
                } else {
                    var textBoxDescriptionTimeCharts = 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%';
                }
                textBoxDescriptionTimeCharts = textBoxDescriptionTimeCharts
                    .replaceAll('%VALUE_INDICATOR%', valueVIndicator)
                    .replaceAll('%DATE_FROM%', window.timelineData[chartIndex].dateFrom)
                    .replaceAll('%DATE_TO%', window.timelineData[chartIndex].dateTo)
                    .replaceAll('%DATA_SOURCE%', dataSourceLabel)
                    .replaceAll('%CHART_VIEW%', chartViewLabel);
                
                chartTimelineHC.update({title: {
                    text: textBoxTitleTimeCharts
                }});
                chartTimelineHC.update({subtitle: {
                    text:  '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    +'<center>' + textBoxDescriptionTimeCharts + '</cener>',
                    useHTML: true
                }});
            } else {
                chartTimelineHC.update({title: {
                    text: ' '
                }});
                chartTimelineHC.update({subtitle: {
                    text: ' ',
                    useHTML: false
                }});
            }
        }
    } else if (chartType == CHART_TYPE_STREAMGRAPH){//streamgraph
        if (typeof curDataChartSG != "undefined") {
            initPrepareFormData();
            //showChart(curDataChartSG, window.newValuesFormTab1);
            if (window.newValuesFormTab1.checkboxShowHideTitleValue == true) {
                //var data = curDataChartSG.result;
                initPrepareFormData();
                showChart(curDataChartSG, window.newValuesFormTab1);
            } else {
                initPrepareFormData();
                showChart(curDataChartSG, window.newValuesFormTab1);
            }
        }
    } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1){//sankey
        if (typeof curDataChartSK != "undefined") {
            console.log('showHideTitle SANKEY');
            initPrepareFormData();
            //showChart(curDataChartSG, window.newValuesFormTab1);
            if (window.newValuesFormTab1.checkboxShowHideTitleValue == true) {
                //var data = curDataChartSG.result;
                initPrepareFormData();
                showChart(curDataChartSK, window.newValuesFormTab1);
            } else {
                initPrepareFormData();
                showChart(curDataChartSK, window.newValuesFormTab1);
            }
        }
    } else  if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL){
        if (typeof curDataChartDH != "undefined") {
            initPrepareFormData();
            showChart(JSON.parse(curDataChartDH), window.newValuesFormTab1);
        }
    } else {
        if (typeof curDataChart != "undefined") {
            initPrepareFormData();
            showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
        }
    }
}


/**
 * get max size from data item for the radial and dendrogram charts data format
 * @param object data
 * @param int maxSize
 *
 * @return int maxSize
 */
function getMaxRadius(data, maxSize, maxValue, maxLevel) {
    //if ((typeof getMaxValue !== "undefined") && (getMaxValue == true)) {
        if (typeof data.value !== "undefined") {
            if (maxValue < data.value) {
                maxValue = data.value;
            }
            if (typeof data.sizeChild !== "undefined") {
                if (maxValue < data.sizeChild * 1) {
                    maxValue = data.sizeChild * 1;
                }
            }
        }
        if (typeof data.size !== "undefined") {
            if (maxSize < data.size) {
                maxSize = data.size;
            }
        }
        if (typeof data.level !== "undefined") {
            if (maxLevel < data.level) {
                maxLevel = data.level;
            }
        }

        if ((typeof data.children !== "undefined") && (data.children.length > 0)) {
            $.each(data.children, function(index, item) {
                if (typeof item.value !== "undefined") {
                    if (maxValue < item.value) {
                        maxValue = item.value;
                    }
                    if (typeof item.sizeChild !== "undefined") {
                        if (maxValue < item.sizeChild * 1) {
                            maxValue = item.sizeChild * 1;
                        }
                    }
                }
                if (typeof item.size !== "undefined") {
                    if (maxSize < item.size) {
                        maxSize = item.size;
                    }
                }
                if (typeof item.level !== "undefined") {
                    if (maxLevel < item.level) {
                        maxLevel = item.level;
                    }
                }
                if ((typeof item.children !== "undefined") && (item.children.length > 0)) {
                    var ret = getMaxRadius(item, maxSize, maxValue, maxLevel);
                    if (maxValue < ret.value) {
                        maxValue = ret.value;
                    }
                    if (maxSize < ret.size) {
                        maxSize = ret.size;
                    }
                    if (maxLevel < ret.level) {
                        maxLevel = ret.level;
                    }
                }
           });
        }
    
    return {'size': maxSize, 'value': maxValue, 'level': maxLevel};
}


/**
 * get max size from data item for the molecular charts data format
 * @param object data
 * @param int maxSize
 *
 * @return int maxSize
 */
 function getMaxRadiusMol(data, maxSize, getMaxValue) {
    if ((typeof getMaxValue !== "undefined") && (getMaxValue == true)) {
        $.each(data, function(index, item) {
            if (maxSize < item.value * 1) {
                maxSize = item.value * 1;
            }
        });
    } else {
        $.each(data, function(index, item) {
            if (typeof item.size !== "undefined") {
                if (maxSize < item.size * 1) {
                    maxSize = item.size * 1;
                }
            }
        });
    }
    
    return maxSize;
}

/**
 * get max frequency from data item for the radial and dendrogram charts data format
 * @param object data
 * @param int maxSize
 *
 * @return int maxSize
 */
 function getMaxFrequency(data, maxFrequency, chartType) {
    if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL || chartType == CHART_TYPE_RADIAL) {
        if (typeof data.frequency !== "undefined" && data.frequency != null) {
            if (maxFrequency < data.frequency) {
                maxFrequency = data.frequency;
            }
        }
        if ((typeof data.children !== "undefined") && (data.children.length > 0)) {
            $.each(data.children, function(index, item) {
                if (typeof item.frequency !== "undefined" && data.frequency != null) {
                    if (maxFrequency < item.frequency) {
                        maxFrequency = item.frequency;
                    }
                }
                if ((typeof item.children !== "undefined") && (item.children.length > 0)) {
                    var retFrequency = getMaxFrequency(item, maxFrequency, chartType);
                    if (maxFrequency < retFrequency) {
                        maxFrequency = retFrequency;
                    }
                }
           });
        }
    } else if (chartType == CHART_TYPE_MOLECULAR) {
        $.each(data, function(index, item) {
            if (typeof item.frequency !== "undefined" && item.frequency != null) {
                if (maxFrequency < item.frequency) {
                    maxFrequency = item.frequency;
                }
            }
       });
    }
    
    return maxFrequency;
}


/**
 * get words list array from data item for the radial and dendrogram charts data format
 * @param object data
 * @param array wordsList
 *
 * @return array wordsList
 */
function getWordsList(data, wordsList) {
    if (typeof data.name !== "undefined") {
        if ((data.name != '') && (data.name != ' ')) {
            if (wordsList.indexOf(data.name) === -1) {
                wordsList.push(data.name);
            }
        }
    }
    if ((typeof data.children !== "undefined") && (data.children.length > 0)) {
        $.each(data.children, function(index, item) {
            if (typeof item.name !== "undefined") {
                if ((item.name != '') && (item.name != ' ')) {
                    if (wordsList.indexOf(item.name) === -1) {
                        wordsList.push(item.name);
                    }
                }
            }
            if ((typeof item.children != "undefined") && (item.children.length > 0)) {
                wordsList = getWordsList(item, wordsList);
            }
       });
    }
    
    return wordsList;
}

/**
 * get words list array from data item for the molecular charts data format
 * @param object data
 *
 * @return array wordsList
 */
function getWordsListMol(data) {
    var wordsList = [];
    var wordsListMd5 = [];
    var i = 0;
    if ((typeof data.nodes !== "undefined") && (data.nodes.length > 0)) {
        $.each(data.nodes, function(index, item) {
            if (typeof item.atom !== "undefined") {
                if ((item.atom != '') && (item.atom != ' ')) {
                    data.nodes[index].md5 = CryptoJS.MD5(item.atom).toString();
                    if (wordsList.indexOf(item.atom) === -1) {
                        //wordsList.push(item.atom);
                        //wordsListMd5.push(item.md5);
                        wordsList[i] = item.atom;
                        wordsListMd5[i] = item.md5;
                        i++;
                    }
                }
            }
       });
    }
    
    return {'wordsList': wordsList, 'wordsListMd5': wordsListMd5, 'data': data};
}

/**
 * get words list array from data item for the timeline charts data format
 * @param object data
 *
 * @return array wordsList
 */
function getWordsListTL(data) {
    var wordsList = [];
    if ((typeof data !== "undefined") && (data.length > 0)) {
        $.each(data, function(index, item) {
            if (typeof item.name !== "undefined") {
                if ((item.name != '') && (item.name != ' ')) {
                    if (wordsList.indexOf(item.name) === -1) {
                        wordsList.push(item.name);
                    }
                }
            }
       });
    }
    
    return wordsList;
}

/**
 * get words list array from data item for the timeline charts data format
 * @param object data
 *
 * @return array wordsList
 */
 function getWordsListSK(data) {
    var wordsList = [];
    //console.log(data.length);
    //if ((typeof data !== "undefined") && (data.length > 0)) {
        //console.log(data.words.length);
        if ((typeof data.words !== "undefined") && (data.words.length > 0)) {

            //$.each(data.words, function(index, item) {
            for (var i = 0; i < data.words.length; i++) {
                    //console.log(data.words[i]);
                if (wordsList.indexOf(data.words[i]) === -1) {
                    wordsList.push(data.words[i]);
                }
            }
        }
    //}
    //console.log(data.words, wordsList);
    return wordsList;
}

/**
 get text length in px, by font size
 * @param string textStr
 * @param int fontSize
 *
 * @return int width
 */
function getTextLengthPx (textStr, fontSize, fontSizeMult) {
    /*
    var textContanerId = 'tmpTextContaner';
    $("body #dataContaner").append('<span id="' + textContanerId + '"></span>');
    tempText = $('#' + textContanerId)
    .css("font-size", (fontSize * fontSizeMult) + "px")
    .css("font-family", '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;')
    .text(textStr);

    var width = document.getElementById(textContanerId).scrollWidth; //tempText.getComputedTextLength();
    if (!width) {
        width = $('#' + textContanerId).width();
    }
    */
    //console.log('max words width = ', width)

    //var fontSize = 10;
    $('#tmpTextContaner').text(textStr);
    var test = document.getElementById("tmpTextContaner");
    test.style.fontSize = (fontSize * fontSizeMult) + "px";
    test.style.fontFamily = '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif';
    var height = (test.clientHeight + 1) + "px";
    var width = (test.clientWidth + 1) + "px"
    

    return width;
}

/**
 get max] text length in px, by font size from wordsList array
 * @param array wordsList
 * @param int fontSize
 *
 * @return int width
 */
function getMaxTextLengthPx (wordsList, fontSize, fontSizeMult) {
    width = 0;
    for (var i = 0; i < wordsList.length; i++) {
        var word = wordsList[i];
        var wordWidth = getTextLengthPx (word, fontSize, fontSizeMult);
        wordWidth = wordWidth.replace('px', '')*1;
        if (wordWidth > width) {
            width = wordWidth;
        }
    }

    return width;
}


function getLineWidthMult(d, connectorLineWidthIndicator, chartType, valueMaxDepth, maxDistance, maxFrequency, maxValue) {
    connectorLineWidthIndicator = connectorLineWidthIndicator * 1; 
    //console.log('maxValue = ', maxValue);
    //console.log(d, connectorLineWidthIndicator, chartType, valueMaxDepth, maxDistance);
    var lineWidth = 1;
    if (chartType == CHART_TYPE_RADIAL || chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
        switch(connectorLineWidthIndicator) {
            case 1: //Frequency
                //lineWidth = 9 - d.source.level;
                if (d.target.frequency != null) {
                    lineWidth = 100 / maxFrequency * d.target.frequency / 10;
                }
                break;
            case 2: //Level
                lineWidth = valueMaxDepth * 1 + maxDistance * 1 + 1 - d.source.level;
                //console.log('lineWidth = ', lineWidth);
                break;
            case 3: //Distance
                if (d.target.distance == null) {
                    var distance = 0;
                } else {
                    var distance = d.target.distance * 1;
                }
                lineWidth = maxDistance * 1 + 1 - distance;
                break;
            case 4: //Absolute  value
                //var tSize = Math.round(d.target.size / 10);
                if (d.target.size == 0.1) {
                    if (typeof d.target.sizeChild !== "undefined") {
                        lineWidth = 100 / maxValue * d.target.sizeChild / 10 ;
                    }
                } else {
                    lineWidth = Math.round(d.target.size / 10);
                }
                break;
            default: //Absolute value
                lineWidth = 1;
        }
    } else if (chartType == CHART_TYPE_MOLECULAR) {
        if (((typeof(d.source) === 'undefined') || (d.source.atom.charAt(0) == '_')) 
        || ((typeof(d.target) === 'undefined') || d.target.atom.charAt(0) == '_')) {
            lineWidth = 1;
        } else {
            switch(connectorLineWidthIndicator) {
                case 1: //Frequency
                    //lineWidth = 9 - d.source.level;
                    if (d.frequency != null) {
                        lineWidth = 100 / maxFrequency * d.frequency / 10;
                    }
                    break;
                case 2: //Level
                    lineWidth = valueMaxDepth * 1 + maxDistance * 1 + 1 - d.level;
                    //console.log('lineWidth = ', lineWidth);
                    break;
                case 3: //Distance
                    if (d.distance == null) {
                        var distance = 0;
                    } else {
                        var distance = d.distance * 1;
                    }
                    lineWidth = maxDistance * 1 + 1 - distance;
                    break;
                case 4: //Absolute  value
                    lineWidth = Math.round(d.size / 10);
                    break;
                default: //Absolute value
                    lineWidth = 1;
            }
        }
    }

    return lineWidth;
}            

function getIndexByFieldValue(arrObj, fieldName, value) {
    var index = -1;
    for (var i = 0; i < arrObj.length; i++) {
        if (typeof arrObj[i][fieldName] !== "undefined") {
            if (arrObj[i][fieldName] == value) {
                index = i;
            }
        }
    }

    return index;
}

function getFieldValueByIndex(arrObj, fieldName, index) {
    var value;
    if (typeof arrObj[index] !== "undefined") {
        if (typeof arrObj[index][fieldName] !== "undefined") {
            value = arrObj[index][fieldName];
        }
    }

    return value;
}
function copyToClipboard(elementSelector) {
    //console.log(elementSelector);

    // Create a "hidden" input
    var textArea = $(elementSelector);
  
    textArea.focus();
    textArea.select();

    //navigator.clipboard.write(textArea.text);

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }
}

function getTooltipLinkStr(searchStr) {
    var linkStr = '<a class="%OPEN_IDE_CSS%" style="%INLINE_STYLE%" title="%TOOLTIP_TOOLTIP%" %ONCLICK_STR%>%TOOLTIP_TEXT%</a>';
    var onclickStrTrue = 'onclick="window.open(\'%BASE_URL%\');"';
    var onclickStrFalse = 'href="%BASE_URL%"';


    /*
    //Textual chart - macro names for a column options
    %WORD_SINGLE% - replaced with a clicked word as is, for example - "Donald Trump"
    %WORD_MULTI% - replaced with a clicked word's words in CSV with "%" template, for example - "%Donald%,%Trump%"
    example: #search=%WORD_SINGLE%
    
    dataSource=%DATA_SOURCE% - a data source name
    valMin=%VAL_MIN% - value indicator min
    valMax=%VAL_MAX% - value indicator max
    datesInterval=%DATES_INTERVAL%
    maxDepth=%MAX_DEPTH%
    valueIndicator=%VAL_INDICATOR%
    wordsDailyMax=%WORDS_DAILY_MAX%
    appearanceMin=%APPEARANCE_MIN%
    appearanceMax=%APPEARANCE_MAX%
    datesrange=%DATESRANGE%
    */
   /*
    if (typeof iframeOptionsConfig !== "undefined") {
        $.each(widgetUrlParamsMapping, function(indexMM, itemMM) {
            //console.log('indexMM=', indexMM, 'itemMM=',itemMM);
            if (typeof iframeOptionsConfig[indexMM] !== "undefined") {
                if (indexMM == 'datesInterval') {
                    //console.log(formdata[itemMM.formField]);
                }
                if (typeof itemMM.macro !== "undefined") {
                    baseUrl = baseUrl.replaceAll(itemMM.macro, iframeOptionsConfig[indexMM]);
                }
            } else {
                if (indexMM == 'datesrange') {
                    if (typeof itemMM.macro !== "undefined") {
                        baseUrl = baseUrl.replaceAll(itemMM.macro, '');
                    }
                } else if (indexMM == 'datesInterval') {
                    if (typeof itemMM.macro !== "undefined") {
                        baseUrl = baseUrl.replaceAll(itemMM.macro, '');
                    }
                } else {
                    if ((typeof itemMM.formField !== "undefined") && (typeof itemMM.macro !== "undefined")) {
                        if (typeof formdata[itemMM.formField] !== "undefined") {
                            baseUrl = baseUrl.replaceAll(itemMM.macro, formdata[itemMM.formField]);
                        }
                    }
                }
            }
        });
    }*/


    if (typeof iframeOptionsConfig !== "undefined") {
        if (typeof iframeOptionsConfig.tooltipOptions !== "undefined") {
            if (typeof iframeOptionsConfig.tooltipOptions.link !== "undefined") {
                if (typeof iframeOptionsConfig.tooltipOptions.link.baseUrl !== "undefined") {

                    var baseUrl = iframeOptionsConfig.tooltipOptions.link.baseUrl;
                    baseUrl = baseUrl.replaceAll('%WORD_SINGLE%', searchStr);
                    //var itemWordsArr = window.sourceData[index][0].split(" ");
                    //var wordMultiStr = '';
                    //$.each(itemWordsArr, function(indexW, itemW) {
                    //    wordMultiStr = wordMultiStr + (wordMultiStr == '' ? '' : ',') + '%' + itemW + '%';
                    //});
                    baseUrl = baseUrl.replaceAll('%WORD_MULTI%', searchStr);

                    onclickStrTrue = onclickStrTrue.replace("%BASE_URL%", baseUrl);
                    onclickStrFalse = onclickStrFalse.replace("%BASE_URL%", baseUrl );
                }
                if (typeof iframeOptionsConfig.tooltipOptions.link.icon !== "undefined") {
                }
                if (typeof iframeOptionsConfig.tooltipOptions.link.newTab !== "undefined") {
                    if (iframeOptionsConfig.tooltipOptions.link.newTab == true) {
                        linkStr = linkStr.replace('%ONCLICK_STR%', onclickStrTrue);
                    } else {
                        linkStr = linkStr.replace('%ONCLICK_STR%', onclickStrFalse);
                    }
                }
            }
            if (typeof iframeOptionsConfig.tooltipOptions.text !== "undefined") {
                linkStr = linkStr.replace('%TOOLTIP_TEXT%', iframeOptionsConfig.tooltipOptions.text);
            }
            if (typeof iframeOptionsConfig.tooltipOptions.tooltip !== "undefined") {
                linkStr = linkStr.replace('%TOOLTIP_TOOLTIP%', iframeOptionsConfig.tooltipOptions.tooltip);
            }
            if (typeof iframeOptionsConfig.tooltipOptions.stylesOverride !== "undefined") {
                var styleStr = '';

                $.each(iframeOptionsConfig.tooltipOptions.stylesOverride, function(index, item) {
                    styleStr += index + ': ' + item + '; ';
                });
                linkStr = linkStr.replace('%INLINE_STYLE%', styleStr);
            }
        } else {
            linkStr = '';
        }
    } else {
        linkStr = '';
    }

    return linkStr;
}
