window.dendrogramHorizontalDataInit = {
    radius: undefined,
    pradius: undefined,
    data: undefined,
    width: undefined,
    height: undefined,
    json: undefined,
    cluster: undefined,
    diagonal: undefined,
    svg: undefined,
    nodes: undefined,
    link: undefined,
    node: undefined,
    isConnectorsLines: undefined,
    cicleSizeDiv: undefined,
    radiusMult: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    stopFlag: false,
    wordsListReset: true,
    addData: undefined,
    changedColorsByLevel: {},
    lineWidthMult: 0,
    transparentLevel: 1,
    colorGray: '#606060',
    colorGrayTransparentLevel: 0.6
};
window.dendrogramHorizontalData = {};
window.dendrogramHorizontalData[0] = window.dendrogramHorizontalDataInit;
var isInverted = false;


/**
 * The function builds and displays Dendrogram Horizontal chart by data and form`s parameters.
 * @param object data
 * @param object form  data 
 * @param int chartIndex
 * @return void
 */
function showDendrogramHorizontalChart(data, formdata, chartIndex) {
    if (isDebugg) {console.log('14', 'formdata.wordsList=', formdata.wordsList);}
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    
    initGetColors(CHART_TYPE_DENDROGRAMHORIZONTAL, chartIndex);
    
    cleanup(CHART_TYPE_DENDROGRAMHORIZONTAL, chartIndex);
    
    window.dendrogramHorizontalData[chartIndex].wordsListSelected = formdata.wordsList;
    if (typeof data.result === "string") {
        data = JSON.parse(data.result);
    } else if (typeof data.result !== "undefined") {
        data = data.result;
    }
    if (typeof data === "string") {
        data = JSON.parse(data);
    }
    window.dendrogramHorizontalData[chartIndex].endWordsCount = getEndWordsCount(data, 0);

    isInverted = formdata.checkboxOrientationHorizontalVertical;

    curDataChartDH = JSON.stringify(data);
    if (isWidget == 1) {
        $("body #dataContaner").attr('style', 'width: 100%; height: 100%;');
    }
    var circleSizeMult = 1;
    if (typeof formdata.circleSizeMult !== "undefined") {
        if (formdata.circleSizeMult == 0) {
            formdata.circleSizeMult = 0.1;
        }
        circleSizeMult = formdata.circleSizeMult * 1;
    }
    window.dendrogramHorizontalData[chartIndex].circleSizeMult = circleSizeMult;

    var fontSizeMult = 1;
    if (typeof formdata.fontSizeMult !== "undefined") {
        if (formdata.fontSizeMult == 0) {
            formdata.fontSizeMult = 0.1;
        }
        fontSizeMult = formdata.fontSizeMult * 1;
    }
    window.dendrogramHorizontalData[chartIndex].fontSizeMult = fontSizeMult;

    var fontSize = '';
    if (typeof formdata.nubmberBoxValueFontSize !== "undefined") {
        fontSize = formdata.nubmberBoxValueFontSize;
    }
    window.dendrogramHorizontalData[chartIndex].fontSize = fontSize;
    
    $("body #dataContaner").attr('class', 'radialDataContaner');
    
    var isTitle = formdata.checkboxShowHideTitleValue;
    if (isTitle) {
        var titleHeight = getChartTitleRawsCnt(formdata) * 15 + 10;
    } else {
        var titleHeight = 0;
    }
    var valueMaxDepth = 1;
    if (typeof formdata.dropdownValueMaxDepth !== "undefined") {
        valueMaxDepth = formdata.dropdownValueMaxDepth;
        window.dendrogramHorizontalData[chartIndex].valueMaxDepth = formdata.dropdownValueMaxDepth;
    }
    
    var maxDistance = 1;
    if (typeof formdata.dropdownMaxDistance !== "undefined") {
        maxDistance = formdata.dropdownMaxDistance;
        window.dendrogramHorizontalData[chartIndex].maxDistance = formdata.dropdownMaxDistance;
    }

    var connectorLineWidthIndicator = 1;
    if (typeof formdata.dropdownConnectorLineWidthIndicator !== "undefined") {
        connectorLineWidthIndicator = formdata.dropdownConnectorLineWidthIndicator;
        window.dendrogramHorizontalData[chartIndex].connectorLineWidthIndicator = formdata.dropdownConnectorLineWidthIndicator;
    }
    
    var lineWidthMult = 0;
    if (typeof formdata.lineWidthMult !== "undefined") {
        if (formdata.lineWidthMult == 0) {
            formdata.lineWidthMult = 0;
        }
        lineWidthMult = formdata.lineWidthMult;
        window.dendrogramHorizontalData[chartIndex].lineWidthMult = formdata.lineWidthMult;
    }

    if (typeof formdata.radiusValue !== "undefined") {
        window.dendrogramHorizontalData[chartIndex].radiusMult = formdata.radiusValue;
    } else {
        window.dendrogramHorizontalData[chartIndex].radiusMult = 1;
    }

    if ((formdata.dropdownValueMaxDepth == '') || (formdata.dropdownValueMaxDepth == '')) {
        var addRadiusMult = 1;
    } else if (formdata.dropdownValueMaxDepth == 2) {
        var addRadiusMult = 0.85;
    } else if (formdata.dropdownValueMaxDepth == 3) {
        var addRadiusMult = 0.75;
    } else {
        var addRadiusMult = 0.7;
    }
    //window.dendrogramHorizontalData[chartIndex].radiusMult *= addRadiusMult;

    var svgHeightPadding = 30;
    var svgWidthPadding = 20;
    window.dendrogramHorizontalData[chartIndex].width = getWidth('layoutDataContaner', 'dataContaner') - svgWidthPadding;
    window.dendrogramHorizontalData[chartIndex].height = getHeight('layoutDataContaner', 'dataContaner') - titleHeight - svgHeightPadding;
    if ((window.dendrogramHorizontalData[chartIndex].height) < window.dendrogramHorizontalData[chartIndex].endWordsCount * 12 * window.dendrogramHorizontalData[chartIndex].radiusMult) {
        if (!isInverted) {
            window.dendrogramHorizontalData[chartIndex].height = window.dendrogramHorizontalData[chartIndex].endWordsCount * 12 * window.dendrogramHorizontalData[chartIndex].radiusMult;
            window.dendrogramHorizontalData[chartIndex].width -= 20;
        }
    }

    if (isInverted) {
        if (window.dendrogramHorizontalData[chartIndex].width >= window.dendrogramHorizontalData[chartIndex].height) {
            window.dendrogramHorizontalData[chartIndex].radius = window.radialData[chartIndex].width/2;
        } else {
            window.dendrogramHorizontalData[chartIndex].radius = window.dendrogramHorizontalData[chartIndex].height/2;
        }
    } else {
        if (window.dendrogramHorizontalData[chartIndex].width >= window.dendrogramHorizontalData[chartIndex].height) {
            window.dendrogramHorizontalData[chartIndex].radius = window.dendrogramHorizontalData[chartIndex].height/2;
        } else {
            window.dendrogramHorizontalData[chartIndex].radius = window.radialData[chartIndex].width/2;
        }
    }
    //window.radialData[chartIndex].radius = d3.scale.sqrt().range([0, 6]);
    
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection = 1;
    }
    if (typeof formdata.dropdownConnectorLinesColor !== "undefined") {
        window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor = formdata.dropdownConnectorLinesColor * 1;
    } else {
        window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor = 2;
    }
    var wordsListArr = getWordsList(data, []);
     wordsListArr = wordsListArr.sort();
    window.wordsListArr = wordsListArr;
     if (typeof formdata.dropdownColorsSet === "undefined") {
        formdata.dropdownColorsSet = 0;
    }
    window.dendrogramHorizontalData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
    
    if (formdata.dropdownColorsSet == 0) {
        if (wordsListArr.length <= 8) {
            window.colorsArraySetIndex = 6;
        } else if (wordsListArr.length <= 16) {
            window.colorsArraySetIndex = 7;
        } else if (wordsListArr.length <= 24) {
            window.colorsArraySetIndex = 8;
        } else if (wordsListArr.length <= 32) {
            window.colorsArraySetIndex = 9;
        } else {
            window.colorsArraySetIndex = 1;
        }
        if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 1) {
            var wordsListColorsAuto = getAutoUnicColorsForWordsList(wordsListArr, CHART_TYPE_DENDROGRAMHORIZONTAL, chartIndex);
        }
    } else {
        window.dendrogramHorizontalData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    }
    
    if (typeof formdata.dropdownBallsText !== "undefined") {
        window.dendrogramHorizontalData[chartIndex].dropdownBallsText = formdata.dropdownBallsText * 1;
    } else {
        window.dendrogramHorizontalData[chartIndex].dropdownBallsText = 4;
    }
    if (typeof formdata.changedRadLevelColors !== "undefined") {
        window.dendrogramHorizontalData[chartIndex].changedRadLevelColors = formdata.changedRadLevelColors;
    } else {
        window.dendrogramHorizontalData[chartIndex].changedRadLevelColors = {};
    }
    
    window.dendrogramHorizontalData[chartIndex].radiusItem = d3.scale.sqrt().range([0, 0.9]);
    
    window.dendrogramHorizontalData[chartIndex].data = data;
    
    //var maxNodeSize = getMaxRadius(window.dendrogramHorizontalData[chartIndex].data, 0);
    //var maxValue = getMaxRadius(window.dendrogramHorizontalData[chartIndex].data, 0, true);

    var retMaxItems = getMaxRadius(window.dendrogramHorizontalData[chartIndex].data, 0, 0, 0);
    var maxNodeSize = retMaxItems.size;
    var maxValue = retMaxItems.value;
    var maxLevel = retMaxItems.level;

    addColorpickersSelection(CHART_TYPE_DENDROGRAMHORIZONTAL, maxLevel);
    setChangedRadLevelColors(window.dendrogramHorizontalData[chartIndex].changedRadLevelColors);

    var maxRadius = window.dendrogramHorizontalData[chartIndex].radiusItem(maxNodeSize)
        * circleSizeMult * window.dendrogramHorizontalData[chartIndex].radiusMult * 2;
    var maxFrequency = getMaxFrequency(data, 0, CHART_TYPE_RADIAL);

    window.dendrogramHorizontalData[chartIndex].json = window.dendrogramHorizontalData[chartIndex].data;
    
    var circleSizeMultPadding = circleSizeMult;
    if (circleSizeMult < 0.5) {
        circleSizeMultPadding = 0.5;
    }
    
    window.dendrogramHorizontalData[chartIndex].diameter = window.dendrogramHorizontalData[chartIndex].radius * 2;
    window.dendrogramHorizontalData[chartIndex].duration = 2000;
    
    window.dendrogramHorizontalData[chartIndex].root; // store data in a variable accessible by all functions

    if (isInverted) {
        window.dendrogramHorizontalData[chartIndex].tree = d3.layout.tree().size(
            [window.dendrogramHorizontalData[chartIndex].width * window.dendrogramHorizontalData[chartIndex].radiusMult - 80,
            window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult - 50]);
    
        window.dendrogramHorizontalData[chartIndex].cluster = d3.layout.cluster().size([window.dendrogramHorizontalData[chartIndex].width - 80,
            window.dendrogramHorizontalData[chartIndex].height - 50]);
    } else {
        window.dendrogramHorizontalData[chartIndex].tree = d3.layout.tree().size(
            [window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult - 100,
            window.dendrogramHorizontalData[chartIndex].width * window.dendrogramHorizontalData[chartIndex].radiusMult - 0]);
    
        window.dendrogramHorizontalData[chartIndex].cluster = d3.layout.cluster().size([window.dendrogramHorizontalData[chartIndex].height - 100,
            window.dendrogramHorizontalData[chartIndex].width - 0]);
    }

    window.dendrogramHorizontalData[chartIndex].diagonal = d3.svg.diagonal()
        .projection(function (d) {
            if (isInverted) {
                return [d.x*1, d.y*1];
            } else {
                return [d.y*1, d.x*1];
            }
        });
        //var diagonal = d3.svg.diagonal().projection(function(d) { });
        
    window.dendrogramHorizontalData[chartIndex].radialTree = d3.layout.tree()
        .size([360, window.dendrogramHorizontalData[chartIndex].radius ])
        .separation(function(a, b) {
            return (a.parent == b.parent ? 1 : 2) / a.depth;
        });

    

    window.dendrogramHorizontalData[chartIndex].radialCluster = d3.layout.cluster()
        .size([360, window.dendrogramHorizontalData[chartIndex].radius ])
        .separation(function(a, b) {
            return (a.parent == b.parent ? 1 : 2) / a.depth;
        });

    window.dendrogramHorizontalData[chartIndex].radialDiagonal = d3.svg.diagonal.radial()
        .projection(function(d) {
            return [d.y + 100, d.x / 180 * Math.PI];
        });

    window.dendrogramHorizontalData[chartIndex].svg = d3.select("body #dataContaner").html('').append("svg")
        .attr("width", window.dendrogramHorizontalData[chartIndex].width
            * ( window.dendrogramHorizontalData[chartIndex].radiusMult > 1 ? window.dendrogramHorizontalData[chartIndex].radiusMult : 1))
        .attr("height", window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult)
        .append("g")
        .attr("transform", "translate(40,0)");
        

    var maxTextLengthPx = getMaxTextLengthPx(wordsListArr, 10, fontSizeMult);
    
    var leftPadding = 0;
    if (typeof window.dendrogramHorizontalData[chartIndex].json.name !== "undefined") {
        if (window.dendrogramHorizontalData[chartIndex].json.name == '') {
            leftPadding = 0;
        } else {
            leftPadding = getTextLengthPx(window.dendrogramHorizontalData[chartIndex].json.name , 10, fontSizeMult);
            leftPadding = leftPadding.replace('px', '') * 1;
        }
    }

    var retMaxItems = getMaxRadius(window.dendrogramHorizontalData[chartIndex].json, 0, 0, 0);
    var maxNodeRadius = retMaxItems.size;

    var maxCircleRadius = maxNodeRadius * circleSizeMult * window.dendrogramHorizontalData[chartIndex].radiusMult / 2;

    if (isInverted) {
        window.dendrogramHorizontalData[chartIndex].tree = d3.layout.tree().size(
            [window.dendrogramHorizontalData[chartIndex].width * window.dendrogramHorizontalData[chartIndex].radiusMult - leftPadding - 90,
            window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult - maxCircleRadius - 60]);
    
        window.dendrogramHorizontalData[chartIndex].cluster = d3.layout.cluster().size(
            window.dendrogramHorizontalData[chartIndex].width * window.dendrogramHorizontalData[chartIndex].radiusMult - leftPadding - 90,
            window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult - 60);
    } else {
        window.dendrogramHorizontalData[chartIndex].tree = d3.layout.tree().size(
            [window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult - maxCircleRadius,
            window.dendrogramHorizontalData[chartIndex].width * window.dendrogramHorizontalData[chartIndex].radiusMult - maxTextLengthPx - 120 - leftPadding]);
    
        window.dendrogramHorizontalData[chartIndex].cluster = d3.layout.cluster().size(
            window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult,
            window.dendrogramHorizontalData[chartIndex].width * window.dendrogramHorizontalData[chartIndex].radiusMult - maxTextLengthPx - 120 - leftPadding);
    
    }
        
    var svgWidth =  window.dendrogramHorizontalData[chartIndex].width
            * ((window.dendrogramHorizontalData[chartIndex].radiusMult > 1) ? window.dendrogramHorizontalData[chartIndex].radiusMult : 1);
    var contanerHeight = getHeight('layoutDataContaner', 'dataContaner') - titleHeight - svgHeightPadding;
    var svgHeight = window.dendrogramHorizontalData[chartIndex].height * window.dendrogramHorizontalData[chartIndex].radiusMult;
    window.dendrogramHorizontalData[chartIndex].svg = d3.select("body #dataContaner").html('').append("svg")
        .attr("width", svgWidth)
        .attr("height", ((svgHeight < contanerHeight) ? contanerHeight : svgHeight))
        .append("g")
        .attr("transform", "translate(40," + maxCircleRadius / 2 + ")");
        
    if (isTitle) {
        $("body #dataContaner").prepend(getChartTitle(formdata, svgWidth + 'px'));
    }
    
    var tooltip = d3.select("body")
    .append("div")
    .style("position", "absolute")
    .style("z-index", "1000000")
    .style("visibility", "hidden")
    .style("font-family", "Verdana, Arial, sans-serif")
    .style("border", "1px solid gray")
    .style("background-color", "white")
    .style("font-size", "12px")
    .style("padding", "2px")
    .text("");

    //window.dendrogramHorizontalData[chartIndex].root = d3.layout.hierarchy(window.dendrogramHorizontalData[chartIndex].json);//getData(),
    window.dendrogramHorizontalData[chartIndex].root = window.dendrogramHorizontalData[chartIndex].json;//getData(),

    
    window.dendrogramHorizontalData[chartIndex].nodes
        = window.dendrogramHorizontalData[chartIndex].cluster.nodes(window.dendrogramHorizontalData[chartIndex].root);//.reverse();
    
    window.dendrogramHorizontalData[chartIndex].links
        = window.dendrogramHorizontalData[chartIndex].cluster.links(window.dendrogramHorizontalData[chartIndex].nodes);
    
        
    window.dendrogramHorizontalData[chartIndex].node = window.dendrogramHorizontalData[chartIndex].svg.selectAll(".node")
        .data(window.dendrogramHorizontalData[chartIndex].nodes)
        .enter()
        .append("g")
        .filter(
            function(d) {
                if (isNaN(d.y) || isNaN(d.x)) {
                    d.x = 0;
                    d.y = 0;
                } else if ((typeof d.y == "undefined") || (typeof d.x == "undefined")){
                    d.x = 0;
                    d.y = 0;
                } else {
                    if (isInverted) {
                        console.log('d.x=', d.x, 'd.y=', d.y)
                        return d;
                    } else {
                        console.log('d.x=', d.x, 'd.y=', d.y)
                        return d;
                    }
                }
                return d;
            }
        )
         /*JSON.stringify(d),*/
        .attr("class", "node")
        .attr("transform", function (d) {
            if (isNaN(d.y)) {
                return "translate(" + 0 + "," + 0 + ")";
            }
            if (isNaN(d.x)) {
                return "translate(" + 0 + "," + 0 + ")";
            }
            if (isInverted) {
                //console.log('d.x=', d.x, 'd.y=', d.y);
                return "translate(" + d.x*1 + "," + d.y*1 + ")";
            } else {
                //console.log('d.x=', d.x, 'd.y=', d.y);
                return "translate(" + d.y*1 + "," + d.x*1 + ")";
            }
    });


    window.dendrogramHorizontalData[chartIndex].link = window.dendrogramHorizontalData[chartIndex].svg.selectAll("path.link")
        .data(window.dendrogramHorizontalData[chartIndex].links)
        .enter()
        .append("path")
        .attr("class", "link")
        .style("stroke-width", function(d) {
            var lineWidth = 1;
            if (lineWidthMult == 0) {
                //lineWidth = 6 - d.source.level;
                lineWidth = getLineWidthMult(d, connectorLineWidthIndicator, CHART_TYPE_DENDROGRAMHORIZONTAL, valueMaxDepth, maxDistance, maxFrequency, maxValue);            
                if (lineWidth < 1) {
                    lineWidth = 1;
                }
            } else {
                lineWidth = lineWidth * lineWidthMult;
            }
               
            //return ((d.bond * 2 - 1) * 2 + "px"; 
            return lineWidth + "px"; 
        })
        .attr("d", window.dendrogramHorizontalData[chartIndex].diagonal); 


    var ifEmptyWordsList = false;
    if ((wordsListArr).length == 0) {
        window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    if ((wordsListArr).length != wordsListArr.length) {
        window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    window.dendrogramHorizontalData[chartIndex].wordsList = {};
    window.dendrogramHorizontalData[chartIndex].node.filter(
        function(d) {
            d.name = replaceNamesIndicators(d.name);
            if (((d.name != '') && (d.name != ' ')) && window.dendrogramHorizontalData[chartIndex].wordsListReset == true) {
                if ((firstLoadChart == 1 && isShare && hash != '') && !ifEmptyWordsList) {
                } else {
                    window.dendrogramHorizontalData[chartIndex].wordsList[d.name] = {'name' : d.name, 'size' : d.size};
                }
            }
            if ((d.name != '') && (d.name != ' ')) {
                if (typeof window.dendrogramHorizontalData[chartIndex].wordsList[d.name] == "undefined") {
                    window.dendrogramHorizontalData[chartIndex].wordsList[d.name] = {'name' : d.name, 'size' : d.size};
                }
            }
            return d;
        }
    )
    .append("circle")
    .attr("title", function(d) {
        return d.name;
    })
    .attr("r", function(d) {
        var retRadius;
        if (typeof d.parentItem !== "undefined") {
            retRadius = 1;
        } else if (d.name == '') {
            retRadius = 1;
        } else {
            retRadius = (window.dendrogramHorizontalData[chartIndex].radiusItem(d.size)) * circleSizeMult
                * window.dendrogramHorizontalData[chartIndex].radiusMult;
        }
        d.retRadius = retRadius;
        return retRadius;
    })
    .style("fill", function(d, i) {
        //##########################
        //CryptoJS.MD5(d.name).toString();
        if (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors !== "undefined") {
            if (d.name == ' ') {
                var curLevel = d.levelParent;
            } else {
                var curLevel = d.level;
            }
            if ((d.name == ' ')
                && (typeof d.levelParent !== "undefined")
                && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1 ].color !== "undefined")) {
                var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color,
                    window.dendrogramHorizontalData[chartIndex].transparentLevel);
            } else if ((typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color !== "undefined")) {
                if (d.name == '') {
                    var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                } else {
                    var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color,
                        window.dendrogramHorizontalData[chartIndex].transparentLevel);
                }
            } else {
                var itemVal = undefined;
                var curName;
                if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 1) {
                    if (d.name == ' ') {
                        //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                        d.colorIndex = hexToDecColor(d.nameParent);
                        curName = d.nameParent;
                    } else {
                        //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                        d.colorIndex = hexToDecColor(d.name);
                        curName = d.name;
                    }
                    itemVal = CryptoJS.MD5(curName).toString();
                } else if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.value;
                    if (itemVal < 0) {
                        d.colorIndex = 0;
                    } else if (itemVal == 1) {
                        d.colorIndex = 1;
                    } else {
                        d.colorIndex = 2;
                    }
                } else {
                    if (d.name == ' ') {
                        curName = d.nameParent;
                    } else {
                        curName = d.name;
                    }
                    d.colorIndex = (wordsListArr).indexOf(curName);
                }
                if (d.name == '') {
                    var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                } else {
                    if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
                        && (typeof window.customPopWordsColors[curName] !== "undefined")) {
                        var resColor = hexToRGB(window.customPopWordsColors[curName],
                            window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    } else {
                        var hexColor = 
                            getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                                (wordsListArr).indexOf(curName), formdata);
                        var resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    }
                }
            }
        } else {
            var itemVal = undefined;
            var curName;
            if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 1) {
                if (d.name == ' ') {
                    //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                    d.colorIndex = hexToDecColor(d.nameParent);
                    curName = d.nameParent;
                } else {
                    d.colorIndex = hexToDecColor(d.name);
                    //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                    curName = d.name;
                }
                itemVal = CryptoJS.MD5(curName).toString();
            } else if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 10) {
                itemVal = d.value;
                if (itemVal < 0) {
                    d.colorIndex = 0;
                } else if (itemVal == 1) {
                    d.colorIndex = 1;
                } else {
                    d.colorIndex = 2;
                }
                if (d.name == ' ') {
                    var curName = d.nameParent;
                } else {
                    curName = d.name;
                }
            } else {
                if (d.name == ' ') {
                    var curName = d.nameParent;
                    curName = d.nameParent;
                } else {
                    var curName = d.name;
                    curName = d.name;
                }
                d.colorIndex = (wordsListArr).indexOf(curName);
            }
            if (d.name == '') {
                var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
            } else {
                if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[curName] !== "undefined")) {
                    var resColor = hexToRGB(window.customPopWordsColors[curName], window.dendrogramHorizontalData[chartIndex].transparentLevel);
                } else {
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    var hexColor =
                        getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                            (wordsListArr).indexOf(curName), formdata);
                    var resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);
                }
            }
        }
        return resColor;
    })
    .style("stroke", function(d, i) {
        if (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors !== "undefined") {
            if (d.name == ' ') {
                var curLevel = d.levelParent;
            } else {
                var curLevel = d.level;
            }
            if ((d.name == ' ')
                && (typeof d.levelParent !== "undefined")
                && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1 ].color !== "undefined")) {
                var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color,
                    window.dendrogramHorizontalData[chartIndex].transparentLevel);
            } else if ((typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color !== "undefined")) {
                if (d.name == '') {
                    var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                } else {
                    var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color,
                        window.dendrogramHorizontalData[chartIndex].transparentLevel);
                }
            } else {
                var itemVal = undefined;
                var curName;
                if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 1) {
                    if (d.name == ' ') {
                        //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                        d.colorIndex = hexToDecColor(d.nameParent);
                        curName = d.nameParent;
                    } else {
                        //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                        d.colorIndex = hexToDecColor(d.name);
                        curName = d.name;
                    }
                    itemVal = CryptoJS.MD5(curName).toString();
                } else if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.value;
                    if (itemVal < 0) {
                        d.colorIndex = 0;
                    } else if (itemVal == 1) {
                        d.colorIndex = 1;
                    } else {
                        d.colorIndex = 2;
                    }
                } else {
                    if (d.name == ' ') {
                        curName = d.nameParent;
                    } else {
                        curName = d.name;
                    }
                    d.colorIndex = (wordsListArr).indexOf(curName);
                }
                if (d.name == '') {
                    var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                } else {
                    if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
                        && (typeof window.customPopWordsColors[curName] !== "undefined")) {
                        var resColor = hexToRGB(window.customPopWordsColors[curName],
                            window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    } else {
                        var hexColor = 
                            getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                                (wordsListArr).indexOf(curName), formdata);
                        var resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    }
                }
            }
        } else {
            var itemVal = undefined;
            var curName;
            if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 1) {
                if (d.name == ' ') {
                    //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                    d.colorIndex = hexToDecColor(d.nameParent);
                    curName = d.nameParent;
                } else {
                    //d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                    d.colorIndex = hexToDecColor(d.name);
                    curName = d.name;
                }
                itemVal = CryptoJS.MD5(curName).toString();
            } else if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 10) {
                itemVal = d.value;
                if (itemVal < 0) {
                    d.colorIndex = 0;
                } else if (itemVal == 1) {
                    d.colorIndex = 1;
                } else {
                    d.colorIndex = 2;
                }
                if (d.name == ' ') {
                    var curName = d.nameParent;
                } else {
                    curName = d.name;
                }
            } else {
                if (d.name == ' ') {
                    var curName = d.nameParent;
                    curName = d.nameParent;
                } else {
                    var curName = d.name;
                    curName = d.name;
                }
                d.colorIndex = (wordsListArr).indexOf(curName);
            }
            if (d.name == '') {
                var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
            } else {
                if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[curName] !== "undefined")) {
                    var resColor = hexToRGB(window.customPopWordsColors[curName], window.dendrogramHorizontalData[chartIndex].transparentLevel);
                } else {
                    var hexColor = 
                        getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                            (wordsListArr).indexOf(curName), formdata);
                    var resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);
                }
            }
        }
        return resColor;
    })
    .attr("title", function(d) {
        var retRadius;
        if (typeof d.parentItem !== "undefined") {
            retRadius = 1;
        } else if (d.name == '') {
            retRadius = 1;
        } else {
            retRadius = (window.dendrogramHorizontalData[chartIndex].radiusItem(d.size)) * circleSizeMult
                * window.dendrogramHorizontalData[chartIndex].radiusMult;
        }
        d.retRadius = retRadius;
        return retRadius;
    });

    window.dendrogramHorizontalData[chartIndex].node.append("text")
        .attr("dx", function (d) { return d.children ? -8 : 8; })
        .attr("dy", 3)
        .style("text-anchor", function (d) { return d.children ? "end" : "start"; })
        .style("font-size", adaptLabelFontSize)
        .attr("transform", function(d) {
            if (isInverted) {
                var angle = 35;
            } else {
                var angle = 0;
            }
            return "rotate(" + angle + ")translate(" + d.y + ")";
        })
        .text(function (d) { return d.name; });

    window.dendrogramHorizontalData[chartIndex].link.filter(function(d) {
        return d;
    })
    .style("stroke", function(d, i) {
        
        if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
            var curName = d.source.name;
        } else if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
            var curName = d.source.name;
        } else { // Colors from a target node
            var curName = d.target.name;
        }
        if (curName == '') {
            var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
        } else {
            if (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors !== "undefined") {
                if (curName == ' ') {
                    if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        var curLevel = d.source.levelParent;
                    } else if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                        var curLevel = d.source.levelParent;
                    } else { // Colors from a target node
                        var curLevel = d.target.levelParent;
                    }
                } else {
                    if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        var curLevel = d.source.level;
                    } else if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                        var curLevel = d.source.level;
                    } else { // Colors from a target node
                        var curLevel = d.target.level;
                    }
                }
                if ((curName == ' ')
                    && (typeof d.source.levelParent !== "undefined")
                    && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                    && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1 ].color !== "undefined")) {
                    if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                    } else {
                        var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    }
                } else if ((typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                    && (typeof window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color !== "undefined")) {
                    if (curName == '') {
                        var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                    } else {
                        if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                            var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                        } else {
                            var resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.dendrogramHorizontalData[chartIndex].transparentLevel);
                        }
                    }
                } else {
                    var itemVal = undefined;
                    var colorIndex;
                    var resColor = hexToRGB('#ffffff', 0.0);
                    if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 1) {
                        if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                            resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                        } else if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                            resColor = getColorLinkFromSource(d, wordsListColorsAuto, formdata);
                        } else { // Colors from a target node
                            resColor = getColorLinkFromTarget(d, wordsListColorsAuto, formdata);
                        }
                    } else if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 10) {
                        //For a "Colors set" add several predefined colors groups for values <0, == 0 and >0
                        itemVal = d.source.value;
                        if (itemVal < 0) {
                            colorIndex = 0;
                        } else if (itemVal == 1) {
                            colorIndex = 1;
                        } else {
                            colorIndex = 2;
                        }
                        if (d.source.name == ' ') {
                            var curName = d.source.nameParent;
                        } else {
                            var curName = d.source.name;
                        }
                    resColor = hexToRGB(
                        getColor(colorIndex, undefined, undefined, itemVal, curName,
                            (wordsListArr).indexOf(curName), formdata), 
                        window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    } else {
                        if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                            resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                        } else if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                            if (d.source.name == ' ') {
                                var curName = d.source.nameParent;
                            } else {
                                var curName = d.source.name;
                            }
                            if (curName !== '') {
                                colorIndex = (wordsListArr).indexOf(curName);
                                resColor = hexToRGB(
                                    getColor(colorIndex, undefined, undefined, itemVal, curName,
                                        (wordsListArr).indexOf(curName), formdata), 
                                    window.dendrogramHorizontalData[chartIndex].transparentLevel);
                                if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
                                    && (typeof window.customPopWordsColors[curName] !== "undefined")) {
                                    resColor = hexToRGB(window.customPopWordsColors[curName],
                                        window.dendrogramHorizontalData[chartIndex].transparentLevel);
                                }
                            }
                        } else { // Colors from a target node
                            if (d.target.name == ' ') {
                                var curName = d.target.nameChild;
                            } else {
                                var curName = d.target.name;
                            }
                            if (curName !== '') {
                                colorIndex = (wordsListArr).indexOf(curName);
                                resColor = 
                                    hexToRGB(getColor(colorIndex, undefined, undefined, itemVal, curName,
                                        (wordsListArr).indexOf(curName), formdata), 
                                        window.dendrogramHorizontalData[chartIndex].transparentLevel);
                                if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
                                    && (typeof window.customPopWordsColors[curName] !== "undefined")) {
                                    resColor = hexToRGB(window.customPopWordsColors[curName],
                                        window.dendrogramHorizontalData[chartIndex].transparentLevel);
                                }
                            }
                        }
                    }
                }
            } else {
                var itemVal = undefined;
                if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 1) {
                    if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                    } else if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                        resColor = getColorLinkFromSource(d, wordsListColorsAuto, formdata);
                    } else { // Colors from a target node
                        resColor = getColorLinkFromTarget(d, wordsListColorsAuto, formdata);
                    }
                } else if (window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.source.value;
                    if (itemVal < 0) {
                        d.source.colorIndex = 0;
                    } else if (itemVal == 1) {
                        d.source.colorIndex = 1;
                    } else {
                        d.source.colorIndex = 2;
                    }
                    if (d.source.name == ' ') {
                        var curName = d.source.nameParent;
                    } else {
                        var curName = d.source.name;
                    }
                resColor = hexToRGB(
                    getColor(colorIndex, undefined, undefined, itemVal, curName,
                        (wordsListArr).indexOf(curName), formdata),
                    window.dendrogramHorizontalData[chartIndex].transparentLevel);
                } else {
                    if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                    } else if (window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                       if (d.source.name == ' ') {
                            var curName = d.source.nameParent;
                        } else {
                            var curName = d.source.name;
                        }
                        if (curName !== '') {
                            colorIndex = (wordsListArr).indexOf(curName);
                            resColor = hexToRGB(
                                getColor(colorIndex, undefined, undefined, itemVal, curName,
                                    (wordsListArr).indexOf(curName), formdata),
                                window.dendrogramHorizontalData[chartIndex].transparentLevel);
                        }
                    } else { // Colors from a target node
                        if (d.target.name == ' ') {
                            var curName = d.target.nameChild;
                        } else {
                            var curName = d.target.name;
                        }
                        if (curName !== '') {
                            colorIndex = (wordsListArr).indexOf(curName);
                            resColor = hexToRGB(
                                getColor(colorIndex, undefined, undefined, itemVal, curName,
                                    (wordsListArr).indexOf(curName), formdata), 
                                window.dendrogramHorizontalData[chartIndex].transparentLevel);
                        }
                    }
                    if (curName == '') {
                        resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
                    }
                }
            }
        }
        return resColor;
    })
    .style("stroke-width", function(d) {
        var lineWidth = 1;
        if (lineWidthMult == 0) {
            //lineWidth = (formdata.dropdownValueMaxDepth * 1 + 0.5) - d.source.level;
            lineWidth = getLineWidthMult(d, connectorLineWidthIndicator, CHART_TYPE_DENDROGRAMHORIZONTAL, valueMaxDepth, maxDistance, maxFrequency, maxValue);            
            if (lineWidth < 1) {
                lineWidth = 1;
            }
        } else {
            lineWidth = lineWidth * lineWidthMult;
        }
        
        //return ((d.bond * 2 - 1) * 2 + "px"; 
        return lineWidth + "px"; 
    });

    transitionToTree(leftPadding, maxCircleRadius);
    //transitionToCluster()
    
    window.dendrogramHorizontalData[chartIndex].node.on("mouseover", function(p) {
        //show tooltip
        if ((p.name != '') && (p.name != ' ')) {
            if (p.distance == null) {
                var distanceSrt = '';
            } else {
                var distanceSrt = ', Distance: ' + p.distance;
            }
            tooltip.html( '<b>' + p.name + "</b><br>" + 'Value: ' + p.size + '%/' + p.value + distanceSrt).style("visibility", "visible");
        }
    });
    
    window.dendrogramHorizontalData[chartIndex].node.on("mousemove", function(){
        return tooltip.style("top", (event.pageY-10)+"px").style("left",(event.pageX+10)+"px");
    });
    
    window.dendrogramHorizontalData[chartIndex].node.on("mouseout", function(p) {
        //hide tooltip
        tooltip.style("visibility", "hidden");
    });
    //d3.select(self.frameElement).style("height", window.dendrogramHorizontalData[chartIndex].height + "px");
    

    if (firstLoadChart == 1 && isShare && hash != '') {
        firstLoadChart = 0;
    }
    window.dendrogramHorizontalData[chartIndex].wordsListFirst = window.dendrogramHorizontalData[chartIndex].wordsList;
    if (Object.keys(window.dendrogramHorizontalData[chartIndex].wordsListFirst).length == 0) {
        window.dendrogramHorizontalData[chartIndex].wordsListFirst = window.dendrogramHorizontalData[chartIndex].wordsList;
    }
    addWordsCheckboxesAndInfo(
        window.dendrogramHorizontalData[chartIndex].wordsListFirst,
        window.dendrogramHorizontalData[chartIndex].wordsListSelected,
        CHART_TYPE_DENDROGRAMHORIZONTAL
    );
    window.dendrogramHorizontalData[chartIndex].wordsListReset = false;

    firstLoadChart = 0;
}

function getColorLinkFromSource(d, wordsListColorsAuto, formdata) {
    var curName = d.source.name;
    var resColor;
    if (d.source.name == ' ') {
        curName = d.source.nameParent;
        //colorIndex = hexToDecColor(CryptoJS.MD5(d.source.nameParent).toString());
        itemVal = CryptoJS.MD5(curName).toString();
        colorIndex = hexToDecColor(CryptoJS.MD5(curName).toString());
        var hexColor = 
            getColor(colorIndex, undefined, undefined, itemVal, curName,
                (wordsListArr).indexOf(curName), formdata);
        resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);

        //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, undefined), window.dendrogramHorizontalData[chartIndex].transparentLevel);
    } else if (d.source.name == '') {
        resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
    } else {
        //colorIndex = hexToDecColor(CryptoJS.MD5(d.source.name).toString());
        itemVal = CryptoJS.MD5(curName).toString();
        colorIndex = hexToDecColor(CryptoJS.MD5(curName).toString());
        var hexColor = 
            getColor(colorIndex, undefined, undefined, itemVal, d.source.name,
                (wordsListArr).indexOf(d.source.name), formdata);
        resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);
        //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, undefined), window.dendrogramHorizontalData[chartIndex].transparentLevel);
    }
    if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
        && (typeof window.customPopWordsColors[curName] !== "undefined")) {
        resColor = hexToRGB(window.customPopWordsColors[curName],
            window.dendrogramHorizontalData[chartIndex].transparentLevel);
    }
    return resColor;
}

function getColorLinkFromTarget(d, wordsListColorsAuto, formdata) {
    var curName = d.target.name;
    var resColor;
    if (d.target.name == ' ') {
        curName = d.target.nameChild;
        //colorIndex = hexToDecColor(CryptoJS.MD5(d.target.nameChild).toString());
        colorIndex = hexToDecColor(curName);
        itemVal = CryptoJS.MD5(curName).toString();
        colorIndex = hexToDecColor(CryptoJS.MD5(curName).toString());
        var hexColor = 
            getColor(colorIndex, undefined, undefined, itemVal, curName,
                (wordsListArr).indexOf(curName), formdata);
        resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);
        //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, undefined), window.dendrogramHorizontalData[chartIndex].transparentLevel);
    } else if (d.target.name == '') { // Grayed
        resColor = hexToRGB(window.dendrogramHorizontalData[chartIndex].colorGray, window.dendrogramHorizontalData[chartIndex].colorGrayTransparentLevel);
    } else {
        itemVal = CryptoJS.MD5(curName).toString();
        colorIndex = hexToDecColor(CryptoJS.MD5(curName).toString());
        var hexColor = 
            getColor(colorIndex, undefined, undefined, itemVal, curName,
                (wordsListArr).indexOf(curName), formdata);
        resColor = hexToRGB(hexColor, window.dendrogramHorizontalData[chartIndex].transparentLevel);
    }
    if ((window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection != 10) 
        && (typeof window.customPopWordsColors[curName] !== "undefined")) {
        resColor = hexToRGB(window.customPopWordsColors[curName],
            window.dendrogramHorizontalData[chartIndex].transparentLevel);
    }
    return resColor;
}


function change() {
    if (this.value === "radialtree")
        transitionToRadialTree();
    else if (this.value === "radialcluster")
        transitionToRadialCluster();
    else if (this.value === "tree")
        transitionToTree();
    else
        transitionToCluster();
}

function transitionToRadialTree() {
    window.dendrogramHorizontalData[chartIndex].nodes = radialTree.nodes(window.dendrogramHorizontalData[chartIndex].root), // recalculate layout
    window.dendrogramHorizontalData[chartIndex].links = radialTree.links(window.dendrogramHorizontalData[chartIndex].nodes);

    window.dendrogramHorizontalData[chartIndex].svg.transition().duration(duration)
        .attr("transform", "translate(" + (window.dendrogramHorizontalData[chartIndex].width/2) + ","
            + (window.dendrogramHorizontalData[chartIndex].height/2) + ")"
        );
    // set appropriate translation (origin in middle of svg)

    window.dendrogramHorizontalData[chartIndex].link.data(window.dendrogramHorizontalData[chartIndex].links)
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        .style("stroke", "#fc8d62")
        .attr("d", window.dendrogramHorizontalData[chartIndex].radialDiagonal); //get the new radial path

    window.dendrogramHorizontalData[chartIndex].node.data(window.dendrogramHorizontalData[chartIndex].nodes)
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        .attr("transform", function(d) {
            return "rotate(" + (d.x - 90) + ")translate(" + d.y + ")";
        });

    window.dendrogramHorizontalData[chartIndex].node.select("circle")
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        .style("stroke", "#984ea3");
}

function transitionToRadialCluster() {
    window.dendrogramHorizontalData[chartIndex].nodes = radialCluster.nodes(root); // recalculate layout
    window.dendrogramHorizontalData[chartIndex].links = radialCluster.links(nodes);

    window.dendrogramHorizontalData[chartIndex].svg.transition().duration(duration)
        .attr("transform", "translate(" + (window.dendrogramHorizontalData[chartIndex].width/2 - 100) + "," +
            (window.dendrogramHorizontalData[chartIndex].height/2) + ")"
        );
    // set appropriate translation (origin in middle of svg)

    window.dendrogramHorizontalData[chartIndex].link.data(window.dendrogramHorizontalData[chartIndex].links)
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        .style("stroke", "#66c2a5")
        .attr("d", window.dendrogramHorizontalData[chartIndex].radialDiagonal); //get the new radial path

    window.dendrogramHorizontalData[chartIndex].node.data(window.dendrogramHorizontalData[chartIndex].nodes)
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        .attr("transform", function(d) {
            return "rotate(" + (d.x - 90) + ")translate(" + d.y + ")";
        });

    window.dendrogramHorizontalData[chartIndex].node.select("circle")
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        .style("stroke", "#4daf4a");

}

function transitionToTree(leftPadding, maxCircleRadius) {
    window.dendrogramHorizontalData[chartIndex].nodes
        = window.dendrogramHorizontalData[chartIndex].tree.nodes(window.dendrogramHorizontalData[chartIndex].root); //recalculate layout
    window.dendrogramHorizontalData[chartIndex].links
        = window.dendrogramHorizontalData[chartIndex].tree.links(window.dendrogramHorizontalData[chartIndex].nodes);
    
    window.dendrogramHorizontalData[chartIndex].svg.transition().duration(window.dendrogramHorizontalData[chartIndex].duration)
       .attr("transform", "translate(" + (40 + leftPadding) + "," + maxCircleRadius/2 + ")");
    
    window.dendrogramHorizontalData[chartIndex].link.data(window.dendrogramHorizontalData[chartIndex].links)
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        //.style("stroke", "#e78ac3")
        .filter(
            function(d) {
                if ((d.y == NaN) || (d.x == NaN)) {
                    d.x = 0;
                    d.y = 0;
                } else if ((typeof d.y == "undefined") || (typeof d.x == "undefined")){
                    d.x = 0;
                    d.y = 0;
                }
                return d;
            }
        )
        .attr("d", window.dendrogramHorizontalData[chartIndex].diagonal); // get the new tree path

    window.dendrogramHorizontalData[chartIndex].node.data(window.dendrogramHorizontalData[chartIndex].nodes)
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration)
        .attr("transform", function (d) {
            if (isInverted) {
                return "translate(" + d.x + "," + d.y + ")";
            } else {
                return "translate(" + d.y + "," + d.x + ")";
            }

        });

    window.dendrogramHorizontalData[chartIndex].node.select("circle")
        .transition()
        .duration(window.dendrogramHorizontalData[chartIndex].duration);
        //.style("stroke", "#377eb8");
}

function transitionToCluster() {
    window.dendrogramHorizontalData[chartIndex].nodes = cluster.nodes(window.dendrogramHorizontalData[chartIndex].root), //recalculate layout
    window.dendrogramHorizontalData[chartIndex].links = cluster.links(window.dendrogramHorizontalData[chartIndex].nodes);

    window.dendrogramHorizontalData[chartIndex].svg.transition().duration(window.dendrogramHorizontalData[chartIndex].duration)
        .attr("transform", "translate(40,0)");

    window.dendrogramHorizontalData[chartIndex].link.data(links)
	    .transition()
	    .duration(window.dendrogramHorizontalData[chartIndex].duration)
	    .style("stroke", "#8da0cb")
	    .attr("d", window.dendrogramHorizontalData[chartIndex].diagonal); //get the new cluster path

    window.dendrogramHorizontalData[chartIndex].node.data(window.dendrogramHorizontalData[chartIndex].nodes)
	    .transition()
	    .duration(window.dendrogramHorizontalData[chartIndex].duration)
	    .attr("transform", function (d) {
	        return "translate(" + d.y + "," + d.x + ")";
	    });

    window.dendrogramHorizontalData[chartIndex].node.select("circle")
	    .transition()
	    .duration(window.dendrogramHorizontalData[chartIndex].duration)
	    .style("stroke", "#e41a1c");
}



function getData() {
    return {
        "name": "flare",
            "children": [{
            "name": "analytics",
                "children": [{
                "name": "cluster",
                    "children": []
            }, {
                "name": "graph",
                    "children": [{
                    "name": "BetweennessCentrality",
                    "size": 3534
                }, {
                    "name": "LinkDistance",
                    "size": 5731
                }, {
                    "name": "MaxFlowMinCut",
                    "size": 7840
                }, {
                    "name": "ShortestPaths",
                    "size": 5914
                }, {
                    "name": "SpanningTree",
                    "size": 3416
                }]
            }, {
                "name": "optimization",
                    "children": [{
                    "name": "AspectRatioBanker",
                    "size": 7074
                }]
            }]
        }, {
            "name": "animate",
                "children": [{
                "name": "interpolate",
                    "children": [{
                    "name": "ArrayInterpolator",
                    "size": 1983
                }, {
                    "name": "ColorInterpolator",
                    "size": 2047
                }, {
                    "name": "DateInterpolator",
                    "size": 1375
                }, {
                    "name": "Interpolator",
                    "size": 8746
                }, {
                    "name": "MatrixInterpolator",
                    "size": 2202
                }, {
                    "name": "NumberInterpolator",
                    "size": 1382
                }, {
                    "name": "ObjectInterpolator",
                    "size": 1629
                }, {
                    "name": "PointInterpolator",
                    "size": 1675
                }, {
                    "name": "RectangleInterpolator",
                    "size": 2042
                }]
            }, {
                "name": "ISchedulable",
                "size": 1041
            }, {
                "name": "Parallel",
                "size": 5176
            }, {
                "name": "Pause",
                "size": 449
            }, {
                "name": "Scheduler",
                "size": 5593
            }, {
                "name": "Sequence",
                "size": 5534
            }, {
                "name": "Transition",
                "size": 9201
            }, {
                "name": "Transitioner",
                "size": 19975
            }, {
                "name": "TransitionEvent",
                "size": 1116
            }, {
                "name": "Tween",
                "size": 6006
            }]
        }, {
            "name": "data",
                "children": [{
                "name": "converters",
                    "children": [{
                    "name": "Converters",
                    "size": 721
                }, {
                    "name": "DelimitedTextConverter",
                    "size": 4294
                }, {
                    "name": "GraphMLConverter",
                    "size": 9800
                }, {
                    "name": "IDataConverter",
                    "size": 1314
                }, {
                    "name": "JSONConverter",
                    "size": 2220
                }]
            }, {
                "name": "DataField",
                "size": 1759
            }, {
                "name": "DataSchema",
                "size": 2165
            }, {
                "name": "DataSet",
                "size": 586
            }, {
                "name": "DataSource",
                "size": 3331
            }, {
                "name": "DataTable",
                "size": 772
            }, {
                "name": "DataUtil",
                "size": 3322
            }]
        }]
    };
}

/** Calculation of the maximum word length,
 *  to determine the right indent of the chart
 * 
 * @param {*} data 
 * @param int maxLength 
 * @param str resWord 
 * @returns {'len': maxSize, 'word': resWord}
 */

function getMaxWordsLen(data, maxLength, resWord) {
    if (typeof data.name !== "undefined") {
        if (maxLength < data.name.length) {
            maxLength = data.name.length;
            resWord = data.name;
        }
    }
    if ((typeof data.children !== "undefined") && (data.children.length > 0)) {
        $.each(data.children, function(index, item) {
            if (typeof item.name !== "undefined") {
                if (maxLength < item.name.length) {
                    maxLength = item.name.length;
                    resWord = data.name;
                }
            } 
            if ((typeof item.children !== "undefined") && (item.children.length > 0)) {
                var res = getMaxWordsLen(item, maxLength, resWord);
                var retLength = res.len;
                if (maxLength < res.len) {
                    maxLength = res.len;
                    resWord = res.word;
                }
            }
       });
    }
    
    return {'len': maxSize, 'word': resWord};
}

/** Calculation of the number of words at the end nodes,
 *  to determine the minimum allowable height of the chart
 * 
 * @param {*} data 
 * @param int endWordsCount 
 * @returns int endWordsCount
 */

function getEndWordsCount(data, endWordsCount) {
    if ((typeof data.children !== "undefined") && (data.children.length > 0)) {
        $.each(data.children, function(index, item) {
            if ((typeof item.children !== "undefined") && (item.children.length > 0)) {
                endWordsCount = getEndWordsCount(item, endWordsCount);
            } else {
                endWordsCount++;
            }
       });
    } else {
        endWordsCount++;
    }
    
    return endWordsCount;
}

