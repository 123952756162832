window.radialDataInit = {
    radius: undefined,
    pradius: undefined,
    data: undefined,
    width: undefined,
    height: undefined,
    json: undefined,
    cluster: undefined,
    diagonal: undefined,
    svg: undefined,
    nodes: undefined,
    link: undefined,
    node: undefined,
    isConnectorsLines: undefined,
    cicleSizeDiv: undefined,
    radiusMult: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    stopFlag: false,
    wordsListReset: true,
    addData: undefined,
    changedColorsByLevel: {},
    lineWidthMult: 0,
    transparentLevel: 0.8,
    colorGray: '#606060',
    colorGrayTransparentLevel: 0.7
};
window.radialData = {};
window.radialData[0] = window.radialDataInit;


/**
 * The function builds and displays Radial chart by data and form`s parameters.
 * @param object data
 * @param object form  data 
 * @param int chartIndex
 * @return void
 */
function showRadialChart(data, formdata, chartIndex) {
    if (isDebugg) {console.log('14', 'formdata.wordsList=', formdata.wordsList);}
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    
    initGetColors(CHART_TYPE_RADIAL, chartIndex);
    
    cleanup(CHART_TYPE_RADIAL, chartIndex);
    window.radialData[chartIndex].wordsListSelected = formdata.wordsList;
    if (typeof data === "string") {
        data = JSON.parse(data);
    }

    curDataChart = JSON.stringify(data);
    if (isWidget == 1) {
        $("body #dataContaner").attr('style', 'width: 100%; height: 100%;');
    }
    var circleSizeMult = 1;
    if (typeof formdata.circleSizeMult !== "undefined") {
        if (formdata.circleSizeMult == 0) {
            formdata.circleSizeMult = 0.001;
        }
        circleSizeMult = formdata.circleSizeMult;
    }
    window.radialData[chartIndex].circleSizeMult = circleSizeMult;

    var fontSizeMult = 1;
    if (typeof formdata.fontSizeMult !== "undefined") {
        if (formdata.fontSizeMult == 0) {
            formdata.fontSizeMult = 0.1;
        }
        fontSizeMult = formdata.fontSizeMult;
    }
    window.radialData[chartIndex].fontSizeMult = fontSizeMult;

    var fontSize = '';
    if (typeof formdata.nubmberBoxValueFontSize !== "undefined") {
        fontSize = formdata.nubmberBoxValueFontSize;
    }
    window.radialData[chartIndex].fontSize = fontSize;
    
    $("body #dataContaner").attr('class', 'radialDataContaner');
    
    var isTitle = formdata.checkboxShowHideTitleValue;
    if (isTitle) {
        var titleHeight = getChartTitleRawsCnt(formdata) * 15 + 10;
    } else {
        var titleHeight = 0;
    }
    
    var lineWidthMult = 0;
    if (typeof formdata.lineWidthMult !== "undefined") {
        if (formdata.lineWidthMult == 0) {
            formdata.lineWidthMult = 0;
        }
        lineWidthMult = formdata.lineWidthMult;
        window.radialData[chartIndex].lineWidthMult = formdata.lineWidthMult;
    }

    var valueMaxDepth = 1;
    if (typeof formdata.dropdownValueMaxDepth !== "undefined") {
        valueMaxDepth = formdata.dropdownValueMaxDepth;
        window.radialData[chartIndex].valueMaxDepth = formdata.dropdownValueMaxDepth;
    }
    
    var maxDistance = 1;
    if (typeof formdata.dropdownMaxDistance !== "undefined") {
        maxDistance = formdata.dropdownMaxDistance;
        window.radialData[chartIndex].maxDistance = formdata.dropdownMaxDistance;
    }

    var connectorLineWidthIndicator = 1;
    if (typeof formdata.dropdownConnectorLineWidthIndicator !== "undefined") {
        connectorLineWidthIndicator = formdata.dropdownConnectorLineWidthIndicator;
        window.radialData[chartIndex].connectorLineWidthIndicator = formdata.dropdownConnectorLineWidthIndicator;
    }
    
    var svgHeightPadding = 20;
    var svgWidthPadding = 20;
    window.radialData[chartIndex].width = getWidth('layoutDataContaner', 'dataContaner')/* - 20*/;
    window.radialData[chartIndex].height = getHeight('layoutDataContaner', 'dataContaner') - titleHeight;/* - 20 - titleHeight*/

    if (window.radialData[chartIndex].width >= window.radialData[chartIndex].height) {
        window.radialData[chartIndex].radius = window.radialData[chartIndex].height/2;
    } else {
        window.radialData[chartIndex].radius = window.radialData[chartIndex].width/2;
    }
    //window.radialData[chartIndex].radius = d3.scale.sqrt().range([0, 6]);
    if (typeof formdata.radiusValue !== "undefined") {
        window.radialData[chartIndex].radiusMult = formdata.radiusValue;
    } else {
        window.radialData[chartIndex].radiusMult = 1;
    }
    
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.radialData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.radialData[chartIndex].dropdownColorsSelection = 1;
    }
    if (typeof formdata.dropdownConnectorLinesColor !== "undefined") {
        window.radialData[chartIndex].dropdownConnectorLinesColor = formdata.dropdownConnectorLinesColor * 1;
    } else {
        window.radialData[chartIndex].dropdownConnectorLinesColor = 2;
    }
    /*if (typeof formdata.dropdownColorsSet !== "undefined") {
        window.radialData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    } else {
        window.radialData[chartIndex].dropdownColorsSet = 0;
        window.colorsArraySetIndex = 0;
    }*/
    
    var wordsListArr = getWordsList(data, []);
    //console.log(wordsListArr);
    wordsListArr = wordsListArr.sort();
    //console.log(wordsListArr);

    if (typeof formdata.dropdownColorsSet === "undefined") {
        formdata.dropdownColorsSet = 0;
    }
    
    window.radialData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
    
    //console.log('wordsListArr.length = ', wordsListArr.length, 'formdata.dropdownColorsSet = ', formdata.dropdownColorsSet, 'window.radialData[chartIndex].dropdownColorsSelection = ', window.radialData[chartIndex].dropdownColorsSelection);
    if (formdata.dropdownColorsSet == 0) {
        //console.log('formdata.dropdownColorsSet = ', formdata.dropdownColorsSet);
        if (wordsListArr.length <= 8) {
            window.colorsArraySetIndex = 6;
        } else if (wordsListArr.length <= 16) {
            window.colorsArraySetIndex = 7;
        } else if (wordsListArr.length <= 24) {
            window.colorsArraySetIndex = 8;
        } else if (wordsListArr.length <= 32) {
            window.colorsArraySetIndex = 9;
        } else {
            window.colorsArraySetIndex = 1;
        }
    } else {
        window.radialData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    }
    //console.log(wordsListColorsAuto);
    var wordsListObj = [];
    for (var i = 0; i < wordsListArr.length; i++) {
        var md5 = CryptoJS.MD5(wordsListArr[i]).toString();
        var curColor = getColor(hexToDecColor(md5), undefined, undefined, md5, wordsListArr[i],
        i, formdata);
        wordsListObj.push({'name': wordsListArr[i], 'md5': md5, 'color': curColor});
    }
    if ((formdata.dropdownColorsSet == 0) && (window.radialData[chartIndex].dropdownColorsSelection == 1)) {
        var wordsListColorsAuto = getAutoUnicColorsForWordsList(wordsListArr, CHART_TYPE_RADIAL, chartIndex);
    }

    if (typeof formdata.dropdownBallsText !== "undefined") {
        window.radialData[chartIndex].dropdownBallsText = formdata.dropdownBallsText * 1;
    } else {
        window.radialData[chartIndex].dropdownBallsText = 4;
    }
    if (typeof formdata.dropdownBallsTextLayout !== "undefined") {
        window.radialData[chartIndex].dropdownBallsTextLayout = formdata.dropdownBallsTextLayout * 1;
    } else {
        window.radialData[chartIndex].dropdownBallsTextLayout = 1;
    }
    if (typeof formdata.changedRadLevelColors !== "undefined") {
        window.radialData[chartIndex].changedRadLevelColors = formdata.changedRadLevelColors;
    } else {
        window.radialData[chartIndex].changedRadLevelColors = {};
    }
    
    window.radialData[chartIndex].radius =  window.radialData[chartIndex].radius * window.radialData[chartIndex].radiusMult * 1.05 - titleHeight - svgHeightPadding;
    window.radialData[chartIndex].radiusItem = d3.scale.sqrt().range([0, 2]);
    
    window.radialData[chartIndex].cicleSizeDiv = ($('#RadialMaxSize').val() / window.radialData[chartIndex].radius) * 12;
    window.radialData[chartIndex].pradius = 1;

    window.radialData[chartIndex].data = data;
    window.radialData[chartIndex].data.parentItem = 1;
    var retMaxItems = getMaxRadius(data, 0, 0, 0);
    var maxNodeSize = retMaxItems.size;
    var maxValue = retMaxItems.value;
    var maxLevel = retMaxItems.level;

    addColorpickersSelection(CHART_TYPE_RADIAL, maxLevel);
    setChangedRadLevelColors(window.radialData[chartIndex].changedRadLevelColors);

    var maxRadius = window.radialData[chartIndex].radiusItem(maxNodeSize) * circleSizeMult * window.radialData[chartIndex].radiusMult * 2;
    var maxFrequency = getMaxFrequency(data, 0, CHART_TYPE_RADIAL);
    
    window.radialData[chartIndex].json = data;
    
    var circleSizeMultPadding = circleSizeMult;
    if (circleSizeMult < 0.5) {
        circleSizeMultPadding = 0.5;
    }
    
    if (window.radialData[chartIndex].dropdownBallsTextLayout == 3) {
        var outerCirclePadding = 0;
    } else {
        var outerCirclePadding = -50;
    }
    window.radialData[chartIndex].cluster = d3.layout.cluster()
        .size([360, window.radialData[chartIndex].radius - maxRadius/2 + outerCirclePadding / circleSizeMultPadding / 1.5]);
    
    window.radialData[chartIndex].diagonal = d3.svg.diagonal.radial()
    .projection(function(d) {return [d.y, d.x / 180 * Math.PI];});

    //titleHeight svgHeightPadding svgWidthPadding
    var svgWidth = window.radialData[chartIndex].width
    * ((window.radialData[chartIndex].radiusMult > 1) ? window.radialData[chartIndex].radiusMult : 1)
    - svgWidthPadding;
    window.radialData[chartIndex].svg = d3.select("body #dataContaner").html('').append("svg")
    .attr("width", svgWidth)
    .attr("height", window.radialData[chartIndex].height 
        * ((window.radialData[chartIndex].radiusMult > 1) ? window.radialData[chartIndex].radiusMult : 1)
        + ((window.radialData[chartIndex].radiusMult > 1) ? maxRadius : 0) /*- titleHeight*/ - svgHeightPadding
    )
    .append("g")
    .attr("transform", "translate(" 
        + (window.radialData[chartIndex].width 
        * ((window.radialData[chartIndex].radiusMult > 1) ? window.radialData[chartIndex].radiusMult : 1) - svgWidthPadding)/2 
        + "," + (window.radialData[chartIndex].height 
        * ((window.radialData[chartIndex].radiusMult > 1) ? window.radialData[chartIndex].radiusMult : 1) - titleHeight - svgHeightPadding)/2 + ")");

    $("body #dataContaner svg").attr('id', 'radialSVG');
    
    if (isTitle) {
        $("body #dataContaner").prepend(getChartTitle(formdata, svgWidth + 'px'));
    }
    
    var tooltip = d3.select("body")
    .append("div")
    .style("position", "absolute")
    .style("z-index", "1000000")
    .style("visibility", "hidden")
    .style("font-family", "Verdana, Arial, sans-serif")
    .style("border", "1px solid gray")
    .style("background-color", "white")
    .style("font-size", "12px")
    .style("padding", "2px")
    .text("");
    
    window.radialData[chartIndex].nodes = window.radialData[chartIndex].cluster.nodes(window.radialData[chartIndex].json);
    
    window.radialData[chartIndex].link = window.radialData[chartIndex].svg.selectAll("path.link")
    .data(window.radialData[chartIndex].cluster.links(window.radialData[chartIndex].nodes))
    .enter()
    .append("path")
    .attr("class", "link")
    .attr("d", window.radialData[chartIndex].diagonal)
    .style("stroke", function(d) {
        if (typeof d.children !== "undefined") {
            isEmptyChildren = 1;
        }
        if (d.target.name == '') {
            var retColor = 'transparent';
        } else {
            /*
            var curName = d.target.name;
            var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
            var curMd5 = wordsListObj[curIndex].md5;
            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                var retColor = window.customPopWordsColors[wordsListObj[curIndex].name];
            } else {
                var retColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
            }*/

            var retColor = getRadialLinkColor(d, formdata, wordsListObj);

        }
        return retColor; 
    })
    .style("stroke-width", function(d) {
        var lineWidth = 1;
        if (lineWidthMult == 0) {
            //lineWidth = 6 - d.source.level;
            lineWidth = getLineWidthMult(d, connectorLineWidthIndicator, CHART_TYPE_RADIAL, valueMaxDepth, maxDistance, maxFrequency, maxValue);            
            if (lineWidth < 1) {
                lineWidth = 1;
            }
        } else {
            lineWidth = lineWidth * lineWidthMult;
        }
        
        //return ((d.bond * 2 - 1) * 2 + "px"; 
        return lineWidth + "px"; 
    });

    window.radialData[chartIndex].node = window.radialData[chartIndex].svg.selectAll("g.node")
    .data(window.radialData[chartIndex].nodes)
    .enter()
    .append("g")
    .attr("class", "node")
    .attr("transform", function(d) {
        retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult;
        if (d.level == 0) {
            retRadius = 0;
        } else if (typeof d.children !== "undefined") {
            retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult;
        } else {
            retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult;
        }
        return "rotate(" + (d.x - 90) + ")translate(" + (d.y + retRadius) + ")";
    });
    
    var ifEmptyWordsList = false;
    if (Object.keys(window.radialData[chartIndex].wordsList).length == 0) {
        window.radialData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    if (Object.keys(window.radialData[chartIndex].wordsList).length != wordsListArr.length) {
        window.radialData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    
    window.radialData[chartIndex].wordsList = {};
    window.radialData[chartIndex].node.filter(
        function(d) {
            d.name = replaceNamesIndicators(d.name);
            if (((d.name != '') && (d.name != ' ')) && window.radialData[chartIndex].wordsListReset == true) {
                if ((firstLoadChart == 1 && isShare && hash != '') && !ifEmptyWordsList) {
                } else {
                    window.radialData[chartIndex].wordsList[d.name] = {'name' : d.name, 'size' : d.size};
                }
            }
            if ((d.name != '') && (d.name != ' ')) {
                if (typeof window.radialData[chartIndex].wordsList[d.name] == "undefined") {
                    window.radialData[chartIndex].wordsList[d.name] = {'name' : d.name, 'size' : d.size};
                }
            }
            return d;
        }
    )
    .append("circle")
    .attr("title", function(d) {
        return d.name;
    })
    .attr("r", function(d) {
        var retRadius;
        if (typeof d.parentItem !== "undefined") {
            retRadius = 1;
        } else if (d.name == '') {
            retRadius = 1;
        } else if (d.name == ' ') {
            retRadius = 1;
        } else {
            retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult;
        }
        d.retRadius = retRadius;
        return retRadius;
    })
    .style("fill", function(d, i) {
        //##########################
        if (typeof window.radialData[chartIndex].changedRadLevelColors !== "undefined") {
            if (d.name == ' ') {
                var curLevel = d.levelParent;
            } else {
                var curLevel = d.level;
            }
            if ((d.name == ' ')
                && (typeof d.levelParent !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1 ].color !== "undefined")) {
                var resColor = hexToRGB(window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.radialData[chartIndex].transparentLevel);
            } else if ((typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color !== "undefined")) {
                if (d.name == '') {
                    var resColor = hexToRGB('#ffffff', 0.0);
                } else {
                    var resColor = hexToRGB(window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.radialData[chartIndex].transparentLevel);
                }
            } else {
                var itemVal = undefined;
                if (window.radialData[chartIndex].dropdownColorsSelection == 1) {
                    if (d.name == ' ') {
                        var curName = d.nameParent;
                        d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                    } else {
                        var curName = d.name;
                        d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                    }
                    itemVal = CryptoJS.MD5(curName).toString();
                } else if (window.radialData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.value;
                    if (itemVal < 0) {
                        d.colorIndex = 0;
                    } else if (itemVal == 1) {
                        d.colorIndex = 1;
                    } else {
                        d.colorIndex = 2;
                    }
                } else {
                    if (d.name == ' ') {
                        var curName = d.nameParent;
                    } else {
                        var curName = d.name;
                    }
                    d.colorIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                }
                if (d.name == '') {
                    var resColor = hexToRGB('#ffffff', 0.0);
                } else {
                    //var hexColor = getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                    //    getIndexByFieldValue(wordsListObj, 'name', curName), formdata);
                    var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                    var curMd5 = wordsListObj[curIndex].md5;
                    //var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                    if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                        var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                    } else {
                        var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                    }
                    var resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                }
            }
        } else {
            var itemVal = undefined;
            if (window.radialData[chartIndex].dropdownColorsSelection == 1) {
                if (d.name == ' ') {
                    var curName = d.nameParent;
                    d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                } else {
                    var curName = d.name;
                    d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                }
                itemVal = CryptoJS.MD5(curName).toString();
            } else if (window.radialData[chartIndex].dropdownColorsSelection == 10) {
                itemVal = d.value;
                if (itemVal < 0) {
                    d.colorIndex = 0;
                } else if (itemVal == 1) {
                    d.colorIndex = 1;
                } else {
                    d.colorIndex = 2;
                }
            } else {
                if (d.name == ' ') {
                    var curName = d.nameParent;
                } else {
                    var curName = d.name;
                }
                d.colorIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
            }
            if (d.name == '') {
                var resColor = hexToRGB('#ffffff', 0.0);
            } else {
                //var hexColor = getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                //    getIndexByFieldValue(wordsListObj, 'name', curName), formdata);
                var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                var curMd5 = wordsListObj[curIndex].md5;
                if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                    var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                } else {
                    var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                }
            var resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.radialData[chartIndex].transparentLevel);
            }
        }
        //console.log('fill ', curName, resColor);
        return resColor;
    })
    .style("stroke", function(d, i) {
        //CryptoJS.MD5(d.name).toString();
        if (typeof window.radialData[chartIndex].changedRadLevelColors !== "undefined") {
            if (d.name == ' ') {
                var curLevel = d.levelParent;
            } else {
                var curLevel = d.level;
            }

            if ((d.name == ' ')
                && (typeof d.levelParent !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1 ].color !== "undefined")) {
                var resColor = hexToRGB(window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.radialData[chartIndex].transparentLevel);
            } else if ((typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color !== "undefined")) {
                if (d.name == '') {
                    var resColor = hexToRGB('#ffffff', 0.0);
                } else {
                    var resColor = hexToRGB(window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.radialData[chartIndex].transparentLevel);
                }
            } else {
                var itemVal = undefined;
                if (window.radialData[chartIndex].dropdownColorsSelection == 1) {
                    if (d.name == ' ') {
                        var curName = d.nameParent;
                        d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                    } else {
                        var curName = d.name;
                        d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                    }
                    itemVal = CryptoJS.MD5(curName).toString();
                } else if (window.radialData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.value;
                    if (itemVal < 0) {
                        d.colorIndex = 0;
                    } else if (itemVal == 1) {
                        d.colorIndex = 1;
                    } else {
                        d.colorIndex = 2;
                    }
                } else {
                    if (d.name == ' ') {
                        var curName = d.nameParent;
                    } else {
                        var curName = d.name;
                    }
                    d.colorIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                }
                if (d.name == '') {
                    var resColor = hexToRGB('#ffffff', 0.0);
                } else {
                    //var hexColor = getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                    //    getIndexByFieldValue(wordsListObj, 'name', curName), formdata);
                    var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                    var curMd5 = wordsListObj[curIndex].md5;
                    if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                        var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                    } else {
                        var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                    }
                    var resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.radialData[chartIndex].transparentLevel);
                }
            }
        } else {
            var itemVal = undefined;
            if (window.radialData[chartIndex].dropdownColorsSelection == 1) {
                if (d.name == ' ') {
                    var curName = d.nameParent;
                    d.colorIndex = hexToDecColor(CryptoJS.MD5(d.nameParent).toString());
                } else {
                    var curName = d.name;
                    d.colorIndex = hexToDecColor(CryptoJS.MD5(d.name).toString());
                }
                itemVal = CryptoJS.MD5(curName).toString();
            } else if (window.radialData[chartIndex].dropdownColorsSelection == 10) {
                itemVal = d.value;
                if (itemVal < 0) {
                    d.colorIndex = 0;
                } else if (itemVal == 1) {
                    d.colorIndex = 1;
                } else {
                    d.colorIndex = 2;
                }
            } else {
                if (d.name == ' ') {
                    var curName = d.nameParent;
                } else {
                    var curName = d.name;
                }
                d.colorIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
            }
            if (d.name == '') {
                var resColor = hexToRGB('#ffffff', 0.0);
            } else {
                //var hexColor = getColor(d.colorIndex, undefined, undefined, itemVal, curName,
                //    getIndexByFieldValue(wordsListObj, 'name', curName), formdata);
                var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                var curMd5 = wordsListObj[curIndex].md5;
                if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                    var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                } else {
                    var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                }
            var resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.radialData[chartIndex].transparentLevel);
            }
        }
        //console.log('stroke ', curName, resColor);
        return resColor;
    })
    .attr("title", function(d) {
        var retRadius;
        if (typeof d.parentItem !== "undefined") {
            retRadius = 1;
        } else if (d.name == '') {
            retRadius = 1;
        } else {
            retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult;
        }
        d.retRadius = retRadius;
        return retRadius;
    });
    
    window.radialData[chartIndex].node.append("text")
    .attr("dy", ".31em")
    .attr("text-anchor", function(d) {return d.x < 180 ? "start" : "end";})
    .text(function(d) {return d.name;})
    .style("font-size", adaptLabelFontSize)
    .each(function(d,i) {
        var thisWidth = this.getComputedTextLength();
        var bbox = this.getBBox();

        var textWidth = bbox.width;
        var textHeight = bbox.height;
        var radiusMult = circleSizeMult * window.radialData[chartIndex].radiusMult;

        d.textWidth = thisWidth;
        d.textHeight = textHeight;
        if (thisWidth > (window.radialData[chartIndex].radiusItem(d.size) * radiusMult)) {
            var lineMult =  window.radialData[chartIndex].radiusItem(d.size) * radiusMult / 100;
            if (lineMult < 1){
                lineMult = lineMult * 1.3;
            }
            if (lineMult < 0.8){
                lineMult = lineMult * 1.5;
            }
            if (lineMult > 2)  {
                lineMult = lineMult * 0.8;
            }
            if (lineMult < 0.6) {
                lineMult = 0.6;
            }
            if (lineMult > 1) {
                lineMult = 1;
            }
            var xText2 = window.radialData[chartIndex].radiusItem(d.size) * radiusMult / 2 
                + 20 * lineMult
                - (window.radialData[chartIndex].radiusItem(d.size) * radiusMult);
            var xText3 = window.radialData[chartIndex].radiusItem(d.size) * radiusMult / 2 
                + 20 * lineMult
                - (window.radialData[chartIndex].radiusItem(d.size) * radiusMult);
            //full single-line
            if (window.radialData[chartIndex].dropdownBallsText == 0) {
            //full multi-line
            } else if (window.radialData[chartIndex].dropdownBallsText == 1) {
                if (d.name.search(' ') >= 0){
                    var wordsArr = d.name.split(" ");
                    if (wordsArr.length == 1) {
                    } else if (wordsArr.length == 2) {
                        d3.select(this).html('');
                        
                        d3.select(this).append("tspan")
                        .attr("dy", -18 * lineMult)
                        .attr("x", 0)
                        .text('........................................')
                        .attr("style", "fill: transparent;text-shadow: none;");
                        
                        d3.select(this).append("tspan")
                        .attr("dy", 15 * lineMult)
                        .attr("x", 0)
                        .text(wordsArr[0]);
                        d3.select(this).append("tspan")
                        .attr("dy", 15 * lineMult)
                        .attr("x", 0)
                        .text(wordsArr[1]);
                    } else if (wordsArr.length == 3){
                        d3.select(this).html('');
                        
                        d3.select(this).append("tspan")
                        .attr("dy", -25 * lineMult)
                        .attr("x", 0)
                        .text('........................................')
                        .attr("style", "fill: transparent;text-shadow: none;");
                        
                        d3.select(this).append("tspan")
                        .attr("dy", 15 * lineMult)
                        .attr("x", 0)
                        .text(wordsArr[0]);
                        d3.select(this).append("tspan")
                        .attr("dy", 15 * lineMult)
                        .attr("x", 0)
                        .text(wordsArr[1]);
                        d3.select(this).append("tspan")
                        .attr("dy", 15 * lineMult)
                        .attr("x", 0)
                        .text(wordsArr[2]);
                    } else if (wordsArr.length > 3) {
                        var charWidth = Math.floor(thisWidth/d.name.length);
                        nextLine = 0;
                        var fistLine = '';
                        var secondLine = '';
                        for (var i = 0; i < wordsArr.length; i++) {
                            if (nextLine == 0) {
                                if ((fistLine + wordsArr[i]).length * charWidth <= thisWidth / 2){
                                    fistLine = fistLine == '' ? wordsArr[i] : fistLine + ' ' + wordsArr[i];
                                } else {
                                    secondLine = wordsArr[i];
                                    nextLine = 1;
                                }
                            } else {
                                secondLine = secondLine + ' ' + wordsArr[i];
                            }
                        }
                        d3.select(this).html('');
                        
                        d3.select(this).append("tspan")
                        .attr("dy", -18 * lineMult)
                        .attr("x", 0)
                        .text('........................................')
                        .attr("style", "fill: transparent;text-shadow: none;");
                        
                        d3.select(this).append("tspan")
                        .attr("dy", 15 * lineMult)
                        .attr("x", 0)
                        .text(fistLine);
                        d3.select(this).append("tspan")
                        .attr("dy", 15 * lineMult)
                        .attr("x", 0)
                        .text(secondLine);
                    }
                }
            //cut to the size of a circle
            } if (window.radialData[chartIndex].dropdownBallsText == 2) {
                var charWidth = Math.floor(thisWidth/d.name.length);
                var maxWidth = window.radialData[chartIndex].radiusItem(d.size) * radiusMult * 2;
                var cutStr = '';
                for (var i = 0; i < d.name.length; i++) {
                    if ((charWidth * (i+1) + charWidth * 3) <= maxWidth) {
                        cutStr = cutStr + d.name.charAt(i);
                    }
                }
                cutStr = cutStr + '...';
                d3.select(this).html('');
                d3.select(this).append("tspan")
                .attr("dy",  2 * lineMult)
                .attr("x", 0)
                .text(cutStr);
            //hide if is not fit in the circle
            } if (window.radialData[chartIndex].dropdownBallsText == 3) {
                d3.select(this).html('');
            }
        }
    })
    .attr("transform", function(d) {
        var ret;
        itemRadius = ((window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult);
        
        retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult;
        if (d.level == 0) {
            retRadius = 0;
        } else if (typeof d.children !== "undefined") {
            if (d.children.length > 0) {
                retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult * (-1);
            }
        } else {
            retRadius = (window.radialData[chartIndex].radiusItem(d.size)) * circleSizeMult * window.radialData[chartIndex].radiusMult;
        }
        var retStr = "rotate(" + (d.x - 90) + ")translate(" + (d.y + retRadius) + ")";
        
        if (window.radialData[chartIndex].dropdownBallsTextLayout == 1) {
            //Outer radius 
            if (d.x < 180) {
                ret = "translate(" + (itemRadius * 1 + 5) + ")";
            } else {
                ret = "rotate(180)translate(-" + (itemRadius * 1 + 5) + ")";
            }
        } else if (window.radialData[chartIndex].dropdownBallsTextLayout == 2) {
            //Inside
            if (d.x < 180) {
                ret = "translate(-" + (d.retRadius * 1 + 1 ) + ")";
            } else {
                ret = "rotate(180)translate(" + (d.retRadius * 1 + 1 ) + ")";
            }
        } else if (window.radialData[chartIndex].dropdownBallsTextLayout == 3) {
            //Inner radius
            if (d.x < 180) {
                ret = "translate(-" + ((d.retRadius * 1 + d.textWidth * 1 ) + 2) + ")";
            } else {
                ret = "rotate(180)translate(" + ((d.retRadius * 1 + d.textWidth * 1 ) + 2) + ")";
            }
        }
        
        return ret;
    });
    
    window.radialData[chartIndex].link.filter(function(d) {
        return d;
    })
    .style("stroke", function(d, i) {
        var resColor = getRadialLinkColor(d, formdata, wordsListObj);
        //console.log('resColor = ', resColor);
        return resColor;
    });

    
    window.radialData[chartIndex].node.on("mouseover", function(p) {
        //show tooltip
        if ((p.name != '') && (p.name != ' ')) {
            if (p.distance == null) {
                var distanceSrt = '';
            } else {
                var distanceSrt = ', Distance: ' + p.distance;
            }
            if (p.frequency == null) {
                var frequencySrt = '';
            } else {
                var frequencySrt = ', Frequency: ' + p.frequency;
            }
            tooltip.html( '<b>' + p.name + "</b><br>" + 'Value: ' + p.size + '%/' + p.value + distanceSrt + frequencySrt).style("visibility", "visible");
        }
    });
    
    window.radialData[chartIndex].node.on("mousemove", function(){
        return tooltip.style("top", (event.pageY-10)+"px").style("left",(event.pageX+10)+"px");
    });
    
    window.radialData[chartIndex].node.on("mouseout", function(p) {
        //hide tooltip
        tooltip.style("visibility", "hidden");
    });
    d3.select(self.frameElement).style("height", window.radialData[chartIndex].height + "px");

    //if (window.radialData[chartIndex].wordsListReset == true) {
        if (isDebugg) {console.log('141 firstLoadChart='+firstLoadChart, isShare, hash);}
        if (firstLoadChart == 1 && isShare && hash != '') {
            firstLoadChart = 0;
        } //else {
            window.radialData[chartIndex].wordsListFirst = window.radialData[chartIndex].wordsList;
            window.molecularData[chartIndex].wordsListFirst = window.radialData[chartIndex].wordsList;
        //}
    //}
    if (Object.keys(window.radialData[chartIndex].wordsListFirst).length == 0) {
        window.radialData[chartIndex].wordsListFirst = window.radialData[chartIndex].wordsList;
    }
    if (Object.keys(window.molecularData[chartIndex].wordsListFirst).length == 0) {
        window.molecularData[chartIndex].wordsListFirst = window.radialData[chartIndex].wordsList;
    }
    //if (window.radialData[chartIndex].wordsListReset == true) {
        addWordsCheckboxesAndInfo(
            window.radialData[chartIndex].wordsListFirst,
            window.radialData[chartIndex].wordsListSelected,
            CHART_TYPE_RADIAL
        );
        window.radialData[chartIndex].wordsListReset = false;
        window.molecularData[chartIndex].wordsListReset = false;
    //}
    firstLoadChart = 0;

    setTimeout(function () {
        scrollTo('dataContaner');
    }, 500);
}

function getRadialLinkColor(d, formdata, wordsListObj) {
    if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
        var curName = ((d.target.name == '') || (d.source.name == '')) ? '' : d.target.name;
    } else if (window.radialData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
        var curName = ((d.target.name == '') || (d.source.name == '')) ? '' : d.source.name;
    } else { // Colors from a target node
        var curName = ((d.target.name == '') || (d.source.name == '')) ? '' : d.target.name;
    }
    if (curName == '') {
        var resColor = hexToRGB('#ffffff', 0.0);
    } else {
        if (typeof window.radialData[chartIndex].changedRadLevelColors !== "undefined") {
            if (curName == ' ') {
                if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                    var curLevel = d.source.levelParent;
                } else if (window.radialData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                    var curLevel = d.source.levelParent;
                } else { // Colors from a target node
                    var curLevel = d.target.levelParent;
                }
            } else {
                if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                    var curLevel = d.source.level;
                } else if (window.radialData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                    var curLevel = d.source.level;
                } else { // Colors from a target node
                    var curLevel = d.target.level;
                }
            }
            if ((curName == ' ')
                && (typeof d.source.levelParent !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1 ].color !== "undefined")) {
                var resColor = hexToRGB(window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.radialData[chartIndex].transparentLevel);
                if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                    var resColor = hexToRGB('#606060', 0.6);
                } else {
                    var resColor = hexToRGB(window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.radialData[chartIndex].transparentLevel);
                }
            } else if ((typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1] !== "undefined")
                && (typeof window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color !== "undefined")) {
                if (curName == '') {
                    var resColor = hexToRGB('#ffffff', 0.0);
                } else {
                    if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        if (d.target.name == '') {
                            var resColor = hexToRGB('#ffffff', 0.0);
                        } else {
                            var resColor = hexToRGB('#606060', 0.6);
                        }
                    } else {
                        var resColor = hexToRGB(window.radialData[chartIndex].changedRadLevelColors[curLevel * 1].color, window.radialData[chartIndex].transparentLevel);
                    }
                }
            } else {
                var itemVal = undefined;
                var colorIndex;
                var resColor = hexToRGB('#ffffff', 0.0);
                if (window.radialData[chartIndex].dropdownColorsSelection == 1) {
                    if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        resColor = hexToRGB('#606060', 0.6);
                    } else if (window.radialData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                        if (d.source.name == ' ') {
                            var curMd5 = CryptoJS.MD5(d.source.nameParent).toString(); 
                            colorIndex = hexToDecColor(curMd5);
                            var curName = d.source.nameParent;
                            var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                            var hexColor = getColor(colorIndex, undefined, undefined, curMd5, curName,
                                getIndexByFieldValue(wordsListObj, 'name', curName), formdata);
    
                            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                                var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                            } else {
                                var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                            }
                            resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                            //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, undefined), window.radialData[chartIndex].transparentLevel);
                        } else if (d.source.name == '') {
                            resColor = hexToRGB('#ffffff', 0.0);
                        } else {
                            if (d.target.name == '') {
                                //console.log('013211 changedRadLevelColors');
                                resColor = hexToRGB('#ffffff', 0.0);
                            } else {
                                //console.log('013212 changedRadLevelColors');
                                //var curMd5 = CryptoJS.MD5(d.source.name).toString(); 
                                var curName = d.source.name;
                                //var hexColor = getColor(colorIndex, undefined, undefined, curMd5, curName,
                                //    (wordsListArr).indexOf(curName), formdata);
                                //console.log(wordsListObj, curName);
    
                                var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                                var curMd5 = wordsListObj[curIndex].md5;
                                //console.log(curIndex, curMd5);
                                colorIndex = hexToDecColor(curMd5);
                                if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                                    var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                                } else {
                                    var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                                }
                                resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                                //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, undefined), window.radialData[chartIndex].transparentLevel);
                            }
                        }
                    } else { // Colors from a target node
                        if (d.target.name == ' ') {
                            //var curMd5 = CryptoJS.MD5(d.target.nameChild).toString(); 
                            var curName = d.target.nameChild;
    
                            var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                            var curMd5 = wordsListObj[curIndex].md5;
                            colorIndex = hexToDecColor(curMd5);
                            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                                var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                            } else {
                                var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                            }
                            resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                            //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, undefined), window.radialData[chartIndex].transparentLevel);
                        } else if (d.source.name == '') {
                            resColor = hexToRGB('#ffffff', 0.0);
                        } else {
                            var curMd5 = CryptoJS.MD5(d.target.name).toString(); 
                            colorIndex = hexToDecColor(curMd5);
                            var curName = d.target.name;
                            var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                            var curMd5 = wordsListObj[curIndex].md5;
                            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                                var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                            } else {
                                var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                            }
                            //var hexColor = getColor(colorIndex, undefined, undefined, curMd5, curName,
                            //    (wordsListArr).indexOf(curName), formdata);
                            resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                            //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, undefined), window.radialData[chartIndex].transparentLevel);
                        }
                    }
                } else if (window.radialData[chartIndex].dropdownColorsSelection == 10) {
                    //console.log('0132 changedRadLevelColors');
                    //For a "Colors set" add several predefined colors groups for values <0, == 0 and >0
                    itemVal = d.source.value;
                    if (itemVal < 0) {
                        colorIndex = 0;
                    } else if (itemVal == 1) {
                        colorIndex = 1;
                    } else {
                        colorIndex = 2;
                    }
                    var curName = d.source.name;
                    var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                    var curMd5 = wordsListObj[curIndex].md5;
                    if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                        var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                    } else {
                        var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                    }
                    resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                    //window.colorsArray[window.colorsArraySetIndex][colorNumber]
                } else {
                    //console.log('0133 changedRadLevelColors');
                    if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                        resColor = hexToRGB('#606060', 0.6);
                    } else if (window.radialData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                        if (d.source.name == ' ') {
                            var curName = d.source.nameParent;
                        } else {
                            var curName = d.source.name;
                        }
                        if (curName !== '') {
                            if (d.target.name == '') {
                                //console.log('013311 changedRadLevelColors');
                                resColor = hexToRGB('#ffffff', 0.0);
                            } else {
                                //console.log('013312 changedRadLevelColors');
                                if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                                    var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                                } else {
                                    var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                                    var curMd5 = wordsListObj[curIndex].md5;
                                    var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                                }
                                resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                                }
                        }
                    } else { // Colors from a target node
                        if (d.target.name == ' ') {
                            var curName = d.target.nameChild;
                        } else {
                            var curName = d.target.name;
                        }
                        if (curName !== '') {
                            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                                var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                            } else {
                                var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                                var curMd5 = wordsListObj[curIndex].md5;
                                var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                            }
                            resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                        }
                    }
                    if (curName == '') {
                        resColor = hexToRGB('#ffffff', 0.0);
                    }
                }
            }
        } else {
            //console.log('02 not changedRadLevelColors', d.source.name);
            var itemVal = undefined;
            if (window.radialData[chartIndex].dropdownColorsSelection == 1) {
                if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                    resColor = hexToRGB('#606060', 0.6);
                } else if (window.radialData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                    if (d.source.name == ' ') {
                        var curMd5 = CryptoJS.MD5(d.source.nameParent).toString(); 
                        colorIndex = hexToDecColor(curMd5);
                        var curName = d.source.nameParent;
                        if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                            var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                        } else {
                            var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                            var curMd5 = wordsListObj[curIndex].md5;
                            var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                        }
                        resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                        //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, itemVal), window.radialData[chartIndex].transparentLevel);
                    } else if (d.source.name == '') {
                        resColor = hexToRGB('#ffffff', 0.0);
                    } else {
                        var curMd5 = CryptoJS.MD5(d.source.name).toString(); 
                        colorIndex = hexToDecColor(curMd5);
                        var curName = d.source.name;
                        var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                        var curMd5 = wordsListObj[curIndex].md5;
                            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                            var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                        } else {
                            var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                        }
                        resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                        //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, itemVal), window.radialData[chartIndex].transparentLevel);
                    }
                } else { // Colors from a target node
                    if (d.target.name == ' ') {
                        var curMd5 = CryptoJS.MD5(d.target.nameChild).toString(); 
                        colorIndex = hexToDecColor(curMd5);
                        var curName = d.target.nameChild;
                        var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                        var curMd5 = wordsListObj[curIndex].md5;
                            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                            var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                        } else {
                            var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                        }
                        resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                        //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, itemVal), window.radialData[chartIndex].transparentLevel);
                    } else if (d.source.name == '') { // Grayed
                        resColor = hexToRGB('#ffffff', 0.0);
                    } else {
                        var curMd5 = CryptoJS.MD5(d.target.name).toString(); 
                        colorIndex = hexToDecColor(curMd5);
                        var curName = d.target.name;
                        var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                        var curMd5 = wordsListObj[curIndex].md5;
                            if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                            var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                        } else {
                            var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                        }
                        resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                        //resColor = hexToRGB(getColor(colorIndex, undefined, undefined, itemVal), window.radialData[chartIndex].transparentLevel);
                    }
                }
            } else if (window.radialData[chartIndex].dropdownColorsSelection == 10) {
                itemVal = d.source.value;
                if (itemVal < 0) {
                    d.source.colorIndex = 0;
                } else if (itemVal == 1) {
                    d.source.colorIndex = 1;
                } else {
                    d.source.colorIndex = 2;
                }
                var curName = d.source.name;
                var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                var curMd5 = wordsListObj[curIndex].md5;
                if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                    var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                } else {
                    var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                }
                resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
            } else {
                if (window.radialData[chartIndex].dropdownConnectorLinesColor == 1) { // Grayed
                    resColor = hexToRGB('#606060', 0.6);
                } else if (window.radialData[chartIndex].dropdownConnectorLinesColor == 2) { // Colors from a source node
                    if (d.source.name == ' ') {
                        var curName = d.source.nameParent;
                    } else {
                        var curName = d.source.name;
                    }
                    if (curName !== '') {
                        colorIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                        var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                        var curMd5 = wordsListObj[curIndex].md5;
                        if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                            var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                        } else {
                            var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                        }
                        resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                    }
                } else { // Colors from a target node
                    if (d.target.name == ' ') {
                        var curName = d.target.nameChild;
                    } else {
                        var curName = d.target.name;
                    }
                    if (curName !== '') {
                        colorIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                        var curIndex = getIndexByFieldValue(wordsListObj, 'name', curName);
                        var curMd5 = wordsListObj[curIndex].md5;
                        if ((formdata.dropdownColorsSelection != 10) && (typeof window.customPopWordsColors[wordsListObj[curIndex].name] !== "undefined")) {
                            var hexColor = window.customPopWordsColors[wordsListObj[curIndex].name];
                        } else {
                            var hexColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, curName, curIndex, formdata);
                        }
                        resColor = hexToRGB(hexColor, window.radialData[chartIndex].transparentLevel);
                    }
                }
                if (curName == '') {
                    resColor = hexToRGB('#ffffff', 0.0);
                }
            }
        }
    }

    return resColor;
}




