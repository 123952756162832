window.timelineDataInit = {
    data: undefined,
    width: undefined,
    height: undefined,
    json: undefined,
    cluster: undefined,
    diagonal: undefined,
    svg: undefined,
    nodes: undefined,
    link: undefined,
    node: undefined,
    isConnectorsLines: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    stopFlag: false,
    wordsListReset: true,
    wordsListColorChange: false,
    unicItems: {},
    unicDates: {},
    allDataKeys: {},
    timeFormatter: undefined,
    margin: { top: 20, right: 20, bottom: 10, left: 50 },
    x: undefined,
    y: undefined,
    chartAreaWidth: undefined,
    chartAreaHeight: undefined,
    zoom: undefined,
    colorsArr: undefined,
    colorsDrawArr: undefined,
    dashStyles: undefined,
    sourceTitle: undefined,
    dateFrom: undefined,
    dateTo:undefined,
    addData: undefined,
    seriesData: [],
    seriesDates: [],
    plotBands: [],
    customWordsColors: {},
    changedColors: {}
};
window.timelineData = {};
window.timelineData[0] = window.timelineDataInit;
var isLegend = true;
var isTitle = true;
var isChartMenu = false;
var dataArrLength;
var htmlContent = '';

if (typeof chartIndex === "undefined") {
    var chartIndex = 0;
}

var chartTimelineHC;

/**
 * Show Timeline Chart
 * @param data
 * @param formdata
 * @param chartIndex
 * @returns
 */      
function showTimelineChart(dataChart, formdata, chartIndex) {
    $('#dataContaner').html($('#chartTimelineDiv').html());
    
    isLegend = formdata.checkboxShowHideLegendValue;
    isTitle = formdata.checkboxShowHideTitleValue;
    isChartMenu = formdata.checkboxShowHideMenuValue;
    
    if (typeof dataChart.result === "string") {
        var resData  = JSON.parse(dataChart.result);
    } else {
        var resData  = dataChart.result;
    }
    var md5Arr = dataChart.md5Arr;
    var wordsList = dataChart.wordsList;
    if (typeof chartIndex === "undefined") {
        var chartIndex = 0;
    }
    
    initGetColors(CHART_TYPE_TIMELINE, chartIndex);

    var wordsListArr = getWordsListTL(resData);
    //console.log(wordsListArr, data);
    wordsListArr = wordsListArr.sort();
    //console.log(wordsListArr);
    
    if (typeof formdata.dropdownColorsSet === "undefined") {
        formdata.dropdownColorsSet = 0;
    }
    window.timelineData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
    
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.timelineData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.timelineData[chartIndex].dropdownColorsSelection = 1;
    }
    
    //console.log('wordsListArr.length = ', wordsListArr.length, 'formdata.dropdownColorsSet = ', formdata.dropdownColorsSet);
    if (formdata.dropdownColorsSet == 0) {
        //console.log('formdata.dropdownColorsSet = ', formdata.dropdownColorsSet);
        if (wordsListArr.length <= 8) {
            window.colorsArraySetIndex = 6;
        } else if (wordsListArr.length <= 16) {
            window.colorsArraySetIndex = 7;
        } else if (wordsListArr.length <= 24) {
            window.colorsArraySetIndex = 8;
        } else if (wordsListArr.length <= 32) {
            window.colorsArraySetIndex = 9;
        } else {
            window.colorsArraySetIndex = 1;
        }
    } else {
        window.timelineData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    }

    var wordsListObj = [];
    for (var i = 0; i < wordsListArr.length; i++) {
        var md5 = CryptoJS.MD5(wordsListArr[i]).toString();
        var curColor = getColor(hexToDecColor(md5), undefined, undefined, md5, wordsListArr[i],
        i, formdata);
        wordsListObj.push({'name': wordsListArr[i], 'md5': md5, 'color': curColor});
    }
    if ((formdata.dropdownColorsSet == 0) && (window.timelineData[chartIndex].dropdownColorsSelection == 1)) {
        var wordsListColorsAuto = getAutoUnicColorsForWordsList(wordsListArr, CHART_TYPE_TIMELINE, chartIndex, undefined, undefined, undefined);
    }


    if (Object.keys(window.timelineData[chartIndex].wordsList).length == 0) {
        window.timelineData[chartIndex].wordsListReset = true;
    }
    if (window.timelineData[chartIndex].wordsListReset == true
        || typeof window.timelineData[chartIndex].colorsArr === "undefined"
        || window.timelineData[chartIndex].colorsArr.length == 0) {
        window.timelineData[chartIndex].colorsArr = [];
        window.timelineData[chartIndex].colorsDrawArr = [];
    }
    if (Object.keys(window.timelineData[chartIndex].wordsList).length == 0) {
    	window.timelineData[chartIndex].wordsListReset = true;
    }

    if (Object.keys(window.timelineData[chartIndex].wordsList).length != wordsListArr.length) {
        window.timelineData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    if (window.timelineData[chartIndex].wordsListReset == true) {
        window.timelineData[chartIndex].wordsListFirst = {};
        window.timelineData[chartIndex].wordsList = {};
    }
    if (typeof formdata.wordsList !== "undefined") {
        window.timelineData[chartIndex].wordsListSelected = formdata.wordsList;
    }
    
    $.each(resData, function(index, item) {
        //md5Arr.push(CryptoJS.MD5(item.name));
    });
    
    curDataChartTL = dataChart;
    var addData = dataChart.addData;
    if (typeof addData !== "undefined") {
        addData = JSON.parse(addData);
        window.timelineData[chartIndex].addData = addData;
    } else {
        addData = window.timelineData[chartIndex].addData;
        if (typeof addData == "undefined") {
            addData = {};
        }
    }
    
    if (Object.keys(md5Arr).length == 0) {
        var md5ArrIsEmpty = true;
    } else {
        var md5ArrIsEmpty = false;
    }

    var newData = [];
    var colorsArr = [];
    var curDataChartCsv = '';
    var i = 0;
    //window.timelineData[chartIndex].curDataChartCsv
    $.each(resData, function(index1, item1) {
        var newItemData = [];
        curDataChartCsv = curDataChartCsv + item1.name + ',';
        $.each(item1.data, function(index2, item2) {
            if ((index2 == (item1.data.length -1))) {
                var endStr = "\n";
            } else {
                var endStr = ",";
            }
            curDataChartCsv = curDataChartCsv + item2.x1 + ' - ' + item2.x2 + ' v:' + item2.v + endStr;
            if (index2 == 0) {
                var dataLabelsItem = {
                    enabled: false,
                    formatter: function() {
                        return this.series.name;
                    },
                    verticalAlign: 'middle',
                    y: 0,
                    style: {
                        color: 'black',//'contrast',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        textOutline: '1px #ffffff',//'0px contrast'
                    },
                    align: 'left'
                };
            } else {
                var dataLabelsItem = {};
            }
            var newItemDataItem = {
                x: prepareDate(item2.x1), 
                x2: prepareDate(item2.x2) + 24*60*60*1000,
                y: index1,
                dataLabels: dataLabelsItem,
                v: item2.v,
                name: item1.name
            };
            newItemData.push(newItemDataItem);
        });
        var curMd5 = CryptoJS.MD5(item1.name).toString();
        if (formdata.dropdownColorsSelection == '1') {
            var curColor = getColor(hexToDecColor(curMd5), undefined, undefined, curMd5, item1.name,
                getIndexByFieldValue(wordsListObj, 'name', item1.name), formdata);
        } else {
            var curColor = getColor(getIndexByFieldValue(wordsListObj, 'name', item1.name), undefined, undefined, undefined, item1.name,
                getIndexByFieldValue(wordsListObj, 'name', item1.name), formdata);
        }
        if ((formdata.dropdownColorsSelection != 10) 
            && (typeof window.customPopWordsColors[item1.name] !== "undefined")) {
            var curColor = window.customPopWordsColors[item1.name];
        } else {
            //var curColor = getColor(j);
        }

        var newItem = {
            name: item1.name,
            pointPadding: 0,
            groupPadding: 0.3,
            borderColor: curColor,
            pointWidth: 7,
            borderRadius: 0,
            data: newItemData,
            color: curColor,
            legendColor: curColor,
            showInLegend: true
        };
        newData.push(newItem);
        colorsArr.push(curColor);
        if (md5ArrIsEmpty) {
            md5Arr.push(curMd5);
        }
        i++;
    });
    
    window.timelineData[chartIndex].curDataChartCsv = curDataChartCsv;
    
    if (typeof wordsListObj !== "undefined") {
        if ((window.timelineData[chartIndex].wordsListReset == true) || (window.timelineData[chartIndex].colorsArr.length == 0)) {
            window.timelineData[chartIndex].colorsArr = [];
            window.timelineData[chartIndex].colorsDrawArr = [];
            window.timelineData[chartIndex].dashStyles = [];
            var curColor = 'ffffff';//getColor(hexToDecColor(md5Arr[0]));
            for (var i = 0 ; i < wordsListObj.length; i++) {
                if (firstLoadChart == 1 && formDataStr != '') {
                    curColor = formdata.curWordColor;
                    formDataStr = '';
                } else {
                    if (formdata.dropdownColorsSelection == '1') {
                        //curColor = getColor(hexToDecColor(md5Arr[i]), undefined, undefined, md5Arr[i]);
                        curColor = getColor(hexToDecColor(wordsListObj[i].md5), undefined, undefined, wordsListObj[i].md5, wordsListObj[i].name,
                            i, formdata);
                        //curDashStyle = getDashStyle(hexToDecColor(md5Arr[i]));
                    } else {
                        curColor = getColor(i, undefined, undefined, undefined, wordsListObj[i].name, i, formdata);
                        //curDashStyle = getDashStyle(i);
                    }
                }

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[i].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[i].name];
                } else {
                     //var curColor = getColor(j);
                }

                window.timelineData[chartIndex].colorsArr.push(curColor);
                window.timelineData[chartIndex].colorsDrawArr.push(curColor);
                wordsListObj[i].color = curColor;
            }
            //console.log(md5Arr, window.timelineData[chartIndex].colorsArr);
            for (var j = 0; j < wordsListObj.length; j++) {
                var curName = wordsListObj[j].name;
                //formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j].md5, chartIndex);
                var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j].md5)
                    ? formdata.changedColors[wordsListObj[j].md5].color
                    : getFieldValueByIndex(wordsListObj, 'color', j)
                );
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colorsArr[j] = curColor;
                }
                var colornew = window.timelineData[chartIndex].colorsArr[j];
                var colornewIsChange = 0;
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colornew = formdata.changedColors[wordsListObj[j].md5].color;
                    window.timelineData[chartIndex].colorsDrawArr[j] = colornew;
                    colornewIsChange = 1;
                }
                formdata.changedColors = findPopWordNewColor(wordsListObj[j].name,formdata.changedColors, wordsListObj[j].md5, chartIndex);
                if (window.timelineData[chartIndex].wordsListReset == true) {
                    window.timelineData[chartIndex].wordsList[wordsListObj[j].name] = {
                        'name': wordsListObj[j].name,
                        'size': 1, //item.sizeReal,
                        'color': window.timelineData[chartIndex].colorsArr[j],
                        'colornew': curColor,
                        'id': j,
                        'fore': 0,
                        'md5': wordsListObj[j].md5,
                        'colorIndex': hexToDecColor(wordsListObj[j].md5)
                    };
                }
                //if (window.timelineData[chartIndex].wordsListColorChange == true) {
                    if (window.timelineData[chartIndex].wordsList[wordsListObj[j].name] !== "undefined") {
                        window.timelineData[chartIndex].wordsList[wordsListObj[j].name].colornew = curColor;
                    }
                //}
                wordsListObj[j].color = curColor;
            }
            window.timelineData[chartIndex].wordsListFirst = window.timelineData[chartIndex].wordsList;
        } else if (typeof formdata.changedColors !== "undefined") {
            for (var j = 0; j < wordsListObj.length; j++) {
                var curName = wordsListObj[j].name;
                formdata.changedColors = findPopWordNewColor(wordsListObj[j].name, formdata.changedColors, wordsListObj[j].md5, chartIndex);
                var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j].md5)
                    ? formdata.changedColors[wordsListObj[j].md5].color
                    : getFieldValueByIndex(wordsListObj, 'color', j)
                );
                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[j].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[j].name];
                    window.timelineData[chartIndex].colorsArr[j] = curColor;
                } else {
                    //var curColor = getColor(j);
                }

                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colorsArr[j] = curColor;
                }
                var colornew = window.timelineData[chartIndex].colorsArr[j];
                var colornewIsChange = 0;
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colornew = formdata.changedColors[wordsListObj[j].md5].color;
                    window.timelineData[chartIndex].colorsDrawArr[j] = colornew;
                    colornewIsChange = 1;
                }
                formdata.changedColors = findPopWordNewColor(wordsListObj[j].name,formdata.changedColors, wordsListObj[j].md5, chartIndex);
                if (window.timelineData[chartIndex].wordsListReset == true) {
                    window.timelineData[chartIndex].wordsList[wordsListObj[j].name] = {
                        'name': wordsListObj[j].name,
                        'size': 1, //item.sizeReal,
                        'color': window.timelineData[chartIndex].colorsArr[j],
                        'colornew': curColor,
                        'id': j,
                        'fore': 0,
                        'md5': wordsListObj[j].md5,
                        'colorIndex': hexToDecColor(wordsListObj[j].md5)
                    };
                }
                //if (window.timelineData[chartIndex].wordsListColorChange == true) {
                    if (window.timelineData[chartIndex].wordsList[wordsListObj[j].name] !== "undefined") {
                        window.timelineData[chartIndex].wordsList[wordsListObj[j].name].colornew = curColor;
                    }
                //}
                wordsListObj[j].color = curColor;
            }
            window.timelineData[chartIndex].wordsListFirst = window.timelineData[chartIndex].wordsList;
        } else {
            for (var i = 0 ; i < wordsListObj.length; i++) {
                if (firstLoadChart == 1 && formDataStr != '') {
                    curColor = formdata.curWordColor;
                    formDataStr = '';
                } else {
                    if (formdata.dropdownColorsSelection == '1') {
                        curColor = getColor(hexToDecColor(wordsListObj[i].md5), undefined, undefined, wordsListObj[i].md5, wordsListObj[i].name,
                            i, formdata);
                    } else {
                        curColor = getColor(i, undefined, undefined, undefined, wordsListObj[i].name, i, formdata);
                    }
                }

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[i].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[i].name];
                }

                wordsListObj[i].color = curColor;
            }
        }
    }
    window.wordsListObj = wordsListObj;

    if (typeof formdata.dropdownValueVIndicator !== "undefined") {
        var valueVIndicator = getValueIndicatorTextById(formdata.dropdownValueVIndicator);
    } else {
        var valueVIndicator = getValueIndicatorTextById(valueDefault);
    }
    
    //Start title block
    if (typeof addData.sourceTitle !== "undefined") {
        window.timelineData[chartIndex].sourceTitle = addData.sourceTitle;
    }
    if (typeof addData.dateFrom !== "undefined") {
        window.timelineData[chartIndex].dateFrom = addData.dateFrom;
    }
    if (typeof addData.dateTo !== "undefined") {
        window.timelineData[chartIndex].dateTo = addData.dateTo;
    }
    
    if (data.countDates <= 31) {
        var intervalInDays = 24 * 3600 * 1000;
    } else {
        var intervalInDays = undefined;
    }

    if (typeof formdata.textBoxTitleTimeCharts !== "undefined") {
        var textBoxTitleTimeCharts = formdata.textBoxTitleTimeCharts;
    } else {
        var textBoxTitleTimeCharts = '';
    }
    var dataSourceLabel = '';
    if (typeof formdata.dropdownValueDSource !== "undefined") {
        $.each(DataSelectOptions, function(index, item) {
            if (item.value == formdata.dropdownValueDSource) {
                dataSourceLabel = item.label;
            }
        });
    }
    var chartViewLabel = '';
    
    textBoxTitleTimeCharts = textBoxTitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.timelineData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.timelineData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxSubtitleTimeCharts !== "undefined") {
        var textBoxSubtitleTimeCharts = formdata.textBoxSubtitleTimeCharts;
    } else {
        var textBoxSubtitleTimeCharts = 'Data source: %DATA_SOURCE% (%CHART_VIEW%)';
    }
    textBoxSubtitleTimeCharts = textBoxSubtitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.timelineData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.timelineData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxDescriptionTimeCharts !== "undefined") {
        var textBoxDescriptionTimeCharts = formdata.textBoxDescriptionTimeCharts;
    } else {
        var textBoxDescriptionTimeCharts = 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%';
    }
    textBoxDescriptionTimeCharts = textBoxDescriptionTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.timelineData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.timelineData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    //end ttle block

    
    $('#chartTimelineContaner .chart svg').remove();
    $('#chartTimelineContaner .legend').html('');
    
    window.timelineData[chartIndex].margin = { top: 20, right: 20, bottom: 10, left: 50 };
    window.timelineData[chartIndex].width = 
        $('#dataContaner').parent().width() - window.timelineData[chartIndex].margin.left 
        - window.timelineData[chartIndex].margin.right - 220;
    if (isWidget == 0) {
        window.timelineData[chartIndex].height =
            $('#dataContaner').parent().height() - window.timelineData[chartIndex].margin.top 
            - window.timelineData[chartIndex].margin.bottom;
    } else {
        window.timelineData[chartIndex].height = $('#dataContaner').parent().height() - 20;
    }
   
    if (firstLoadChart == 1 && isShare && hash != '') {
        //firstLoadChart = 0;
        isShare = false;
        addWordsCheckboxesAndInfo(
            window.timelineData[chartIndex].wordsListFirst,
            window.timelineData[chartIndex].wordsListSelected,
            CHART_TYPE_TIMELINE
        );
    } else {
        //firstLoadChart = 0;
        addWordsCheckboxesAndInfo(
            window.timelineData[chartIndex].wordsListFirst,
            window.timelineData[chartIndex].wordsListSelected,
            CHART_TYPE_TIMELINE
        );
    }
    window.timelineData[chartIndex].wordsListReset = false;
    /*
    if (firstLoadChart == 1) {
            (function(H) {
                //setTimeout(function() {
                H.Legend.prototype.getAllItems = function () {
                    var allItems = [];
                    console.log(this.chart);
                    H.each(this.chart.series, function (series) {
                        var seriesOptions = series && series.options;
                        //if (series.type === 'xrange') {
                            series.color = series.userOptions.color
                        //}
                        // Handle showInLegend. If the series is linked to another series,
                        // defaults to false.
                        if (series && H.pick(
                            seriesOptions.showInLegend,
                            !H.defined(seriesOptions.linkedTo) ? undefined : false, true
                        )) {

                            // Use points or series for the legend item depending on
                            // legendType
                            allItems = allItems.concat(
                                series.legendItems ||
                                (
                                     seriesOptions.legendType === 'point' ?
                                        series.data :
                                     series
                                )
                            );
                        }
                    });

                    H.fireEvent(this, 'afterGetAllItems', { allItems: allItems });

                    return allItems;
                }
                //}, 1000);
    })(Highcharts);
    firstLoadChart = 0;
    }*/
    
    
    Highcharts.setOptions({
        global: {
            useUTC:  true
        },
        time: {
            timezoneOffset: new Date().getTimezoneOffset()
        }
    });

    chartTimelineHC = Highcharts.chart('chartTimelineContaner', {
    //Highcharts.chart('container', {
        chart: {
            type: 'xrange',
            //data: resData,
            dataLabels: {
                enabled: true
            },
            events: {
                load: function () {
                    //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                    //}
                    setTimeout(function () {
                        if (isWidget == 1) {
                            $('#jqxLoaderWidget').jqxLoader('close');
                        } else {
                            $('#jqxLoader').jqxLoader('close');
                        }
                        
                        //$.each(window.timelineData[chartIndex].colorsDrawArr, function(index, item) {
                        $.each($('.highcharts-legend .highcharts-point'), function(index, item) {
                            var curItemName = $(item).prev().text();
                            if (window.timelineData[chartIndex].wordsList[curItemName] !== "undefined") {
                                $(item).attr('fill', window.timelineData[chartIndex].wordsList[curItemName].colornew);
                            }
        
                        });
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                            $.each($('.highcharts-legend .highcharts-point'), function(index, item) {
                                var curItemName = $(item).prev().text();
                                if (window.timelineData[chartIndex].wordsList[curItemName] !== "undefined") {
                                    $(item).attr('fill', window.timelineData[chartIndex].wordsList[curItemName].colornew);
                                }
                            });
                        } else {
                            //$.each(window.timelineData[chartIndex].colorsDrawArr, function(index, item) {
                                //$('.highcharts-legend-item.highcharts-series-' + index + ' .highcharts-point').attr('fill', item);
                            //});
                            $.each($('.highcharts-legend .highcharts-point'), function(index, item) {
                                var curItemName = $(item).prev().text();
                                if (window.timelineData[chartIndex].wordsList[curItemName] !== "undefined") {
                                    $(item).attr('fill', window.timelineData[chartIndex].wordsList[curItemName].colornew);
                                }
                            });
                        }
                        $('.highcharts-xrange-series').click(function() {
                            $.each($('.highcharts-legend .highcharts-point'), function(index, item) {
                                var curItemName = $(item).prev().text();
                                if (window.timelineData[chartIndex].wordsList[curItemName] !== "undefined") {
                                    $(item).attr('fill', window.timelineData[chartIndex].wordsList[curItemName].colornew);
                                }
                            });
                            window.newValuesFormTab1.hiddenLegendItems = getHiddenLegendItems(7);
                        });
                    }, 1000);
                }
            },
            //backgroundColor: chartBackgroundColor
            height: window.timelineData[chartIndex].height
        },
        data: {
            //csv: newData,
            boostThreshold: 0,
            turboThreshold: 0,

            complete: function (options) {
                //Highcharts.each(window.timeSeriesDataInit.seriesData, function(item, index) {
                //    options.series.push(item);
                //});
                if (isDebugg) {console.log('1611 firstLoadChart='+firstLoadChart, isShare, hash);}
                if (firstLoadChart == 1 && isShare && hash != '') {
                    firstLoadChart = 0;
                    isShare = false;
                    //console.log('addWordsCheckboxesAndInfo 1');
                    addWordsCheckboxesAndInfo(
                            window.timelineData[chartIndex].wordsListFirst,
                            window.timelineData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMELINE
                        );
                } else {
                    //console.log('addWordsCheckboxesAndInfo 2');
                    addWordsCheckboxesAndInfo(
                        window.timelineData[chartIndex].wordsListFirst,
                        window.timelineData[chartIndex].wordsListSelected,
                        CHART_TYPE_TIMELINE
                    );
                }
                window.timelineData[chartIndex].wordsListReset = false;
            }
        },
        
        credits:{
            enabled: false
        },
        navigation: {
            buttonOptions: {
                enabled: isChartMenu
            }
        },
        title: {
            text: isTitle == true ? textBoxTitleTimeCharts : ' '
        },
        subtitle: isTitle == true ? {
            text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                +'<center>' + textBoxDescriptionTimeCharts + '</cener>',
            useHTML: true
        } : {
            text: ' ',
            useHTML: false
        },
        accessibility: {
            point: {
                descriptionFormatter: function (point) {
                    var ix = point.index + 1,
                        category = point.name,
                        from = new Date(point.x),
                        to = new Date(point.x2);
                    return category + ', ' + from.toDateString() +
                        ' to ' + to.toDateString() + '. value:' + point.v;
                }
            }
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: ''
            },
            //categories: ['Prototyping', 'Development', 'Testing', 'Testing2'],
            reversed: true,
            labels: {
                formatter: function() {
                   return '';
                }
            }
        },
        tooltip: {
            formatter: function() {
                     var ix = this.point.index + 1,
                        category = this.point.name,
                        from = new Date(this.point.x),
                        to = new Date(this.point.x2);
                    return  category + ', ' + from.toDateString() +
                        ' to ' + to.toDateString() + '. value:' + this.point.v;
            }
        },
        legend: {
            align: 'left',
            verticalAlign: 'bottom',
            //borderWidth: 0,
            enabled: true,
            maxHeight: (isLegend) ? 80 : 1,
            navigation: {
                arrowSize:12,
                enabled:true,
                inactiveColor:'#cccccc',
                style: (isLegend) ? undefined : 'display:none'
            }            //symbolWidth: 50
        },
        series: newData, /*resData*/
        plotOptions: {
            xrange: {
                colors: colorsArr /*['red', 'green', 'blue']*/
            },
            series: {
                point: {
                    events: {
                        click: function (e) {
                            /*hs.htmlExpand(null, {
                                pageOrigin: {
                                    x: e.pageX || e.clientX,
                                    y: e.pageY || e.clientY
                                },
                                headingText: this.series.name,
                                //maincontentText: Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ':<br/> ' + this.y,
                                maincontentText: Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ': ' + this.y + '<br/>' + this.series.name
                                //+ '<a href="' + twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name)
                                //+ '" target="_blank"><img src="/images/icon_Twitter.png"></a>'
                                ,
                                width: 240,
                                height: 110
                            });*/
                            
                            if (dbDataType == 1) {
                                var addTwitterIcon = '<a href="'
                                    + twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name)
                                    + '" target="_blank"><img src="/images/icon_Twitter.png"></a>';
                                window.open(twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name));
                            } else {
                                if (isWidget == 1) {
                                    $('#jqxLoaderWidget').jqxLoader('open');
                                } else {
                                    $('#jqxLoader').jqxLoader('open');
                                }
                                if (!isLegend) {
                                    $('.highcharts-legend').css('display', 'none');
                                }
                                var formData = window.newValuesFormTab1;
                                formData.chartType = CHART_TYPE_TIMELINE;
                                formData.metadataWord = this.series.name;
                                formData.metadataValue = this.y;
                                formData.metadataDate = {};
                                formData.metadataDate.year = Highcharts.dateFormat('%Y', this.x);
                                formData.metadataDate.month = Highcharts.dateFormat('%m', this.x);
                                formData.metadataDate.day = Highcharts.dateFormat('%e', this.x);

                                getDataAndShowMeasurePointDialog('current');
                            }
                        }
                    }
                },
            },
        },
});
}
