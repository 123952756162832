window.molecularDataInit  = {
    graph: {'links': {}, 'nodes': {}},
    graphData: {'links': {}, 'nodes': {}},
    width: undefined,
    height: undefined,
    color: undefined,
    colorB: undefined,
    colorC: undefined,
    radius: undefined,
    svg: undefined,
    force: undefined,
    link: undefined,
    node: undefined,
    div: undefined,
    drag: undefined,
    isConnectorsLines: undefined,
    isIndentValuesRelated: undefined,
    maxSize: undefined,
    padding: undefined,
    clusterPadding: undefined,
    maxRadius: undefined,
    isReduceOverlap: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    wordsListReset: true,
    dropdownMaxNodes: 100,
    dropdownAutoAdjust: 1,
    dropdownChartView: 1,
    dropdownAutoAdjustCnt: 250,
    autoAdjustFirstStart: true,
    AA: {},
    circleSizeMult: 1,
    fontSizeMult: 1,
    sourcePointsSizeMult: 1,
    maxSizeNodeIndex: undefined,
    fixedNodesArr: {},
    svgHeight: 0,
    svgWidth: 0,
    widthMult: 1,
    heightMult: 1,
    changedColors: {},
    colorsListReset: true,
    addData: undefined,
    lineWidthMult: 0
};
window.addData = {};
window.molecularData =  {};
window.molecularData[0] = window.molecularDataInit;
var startTickfixedNodesFlag = true;
var graphLinksNodes = [];

/**
 * The function builds and displays Molecular chart by data and form`s parameters.
 * @param data
 * @param formdata
 * @returns
 */
function showMolecularChart(data, formdata, chartIndex) {
    if (isDebugg) {console.log('16');}
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    if (typeof window.molecularData[chartIndex].force !== "undefined") {
        if (window.molecularData[chartIndex].force) {
            window.molecularData[chartIndex].force.stop();
        }
    }
    
    initGetColors(CHART_TYPE_MOLECULAR, chartIndex);
    
    cleanup(CHART_TYPE_MOLECULAR, chartIndex);
    window.molecularData[chartIndex].wordsListSelected = formdata.wordsList;
    window.molecularData[chartIndex].wordsList = {};
    
    if (typeof data === "string") {
        data = JSON.parse(data);
    }
    
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.molecularData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.molecularData[chartIndex].dropdownColorsSelection = 1;
    }
    
    var wordsListArrRet = getWordsListMol(data);
    var wordsListArr = wordsListArrRet.wordsList;
    data  = wordsListArrRet.data;
    wordsListArr = wordsListArr.sort();
   if (typeof formdata.dropdownColorsSet === "undefined") {
        formdata.dropdownColorsSet = 0;
    }
    window.molecularData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
    
    if (formdata.dropdownColorsSet == 0) {
        if (wordsListArr.length <= 8) {
            window.colorsArraySetIndex = 6;
        } else if (wordsListArr.length <= 16) {
            window.colorsArraySetIndex = 7;
        } else if (wordsListArr.length <= 24) {
            window.colorsArraySetIndex = 8;
        } else if (wordsListArr.length <= 32) {
            window.colorsArraySetIndex = 9;
        } else {
            window.colorsArraySetIndex = 1;
        }
        if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
            var wordsListColorsAuto = getAutoUnicColorsForWordsList(wordsListArr, CHART_TYPE_MOLECULAR, chartIndex, undefined, undefined, undefined);
        }
    } else {
        window.molecularData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    }
    
    if (Object.keys(window.molecularData[chartIndex].wordsList).length != wordsListArr.length) {
        window.molecularData[chartIndex].wordsListReset = true;
    }
    
    curDataChart = JSON.stringify(data);
    window.molecularData[chartIndex].graphData = data;
    window.molecularData[chartIndex].maxSize = $('#MolecularMaxSize').val();

    var maxNodeSize = getMaxRadiusMol(data.links, 0);
    var maxValue = getMaxRadiusMol(data.links, 0, true);
    var maxFrequency = getMaxFrequency(data.links, 0, CHART_TYPE_MOLECULAR);

    $("body #dataContaner").attr('class', 'molecularDataContaner');
    if (typeof formdata.checkboxConnectorsLinesValue !== "undefined" 
        &&  (formdata.checkboxConnectorsLinesValue === 'true' 
            || formdata.checkboxConnectorsLinesValue === true)) {
        window.molecularData[chartIndex].isConnectorsLines = true;
    } else {
        window.molecularData[chartIndex].isConnectorsLines = false;
    }

    var valueMaxDepth = 1;
    if (typeof formdata.dropdownValueMaxDepth !== "undefined") {
        valueMaxDepth = formdata.dropdownValueMaxDepth;
        window.dendrogramHorizontalData[chartIndex].valueMaxDepth = formdata.dropdownValueMaxDepth;
    }
    
    var maxDistance = 1;
    if (typeof formdata.dropdownMaxDistance !== "undefined") {
        maxDistance = formdata.dropdownMaxDistance;
        window.dendrogramHorizontalData[chartIndex].maxDistance = formdata.dropdownMaxDistance;
    }

    var connectorLineWidthIndicator = 1;
    if (typeof formdata.dropdownConnectorLineWidthIndicator !== "undefined") {
        connectorLineWidthIndicator = formdata.dropdownConnectorLineWidthIndicator;
        window.dendrogramHorizontalData[chartIndex].connectorLineWidthIndicator = formdata.dropdownConnectorLineWidthIndicator;
    }
    
    var lineWidthMult = 0;
    if (typeof formdata.lineWidthMult !== "undefined") {
        if (formdata.lineWidthMult == 0) {
            formdata.lineWidthMult = 0;
        }
        lineWidthMult = formdata.lineWidthMult;
        window.molecularData[chartIndex].lineWidthMult = formdata.lineWidthMult;
    }
    
    if (typeof formdata.checkboxIndentValuesRelatedValue !== "undefined" 
        &&  (formdata.checkboxIndentValuesRelatedValue === 'true' 
            || formdata.checkboxIndentValuesRelatedValue === true)) {
        window.molecularData[chartIndex].isIndentValuesRelated = true;
    } else {
        window.molecularData[chartIndex].isIndentValuesRelated = false;
    }
    if (typeof formdata.checkboxReduceOverlapValue !== "undefined" 
        &&  (formdata.checkboxReduceOverlapValue === 'true' 
            || formdata.checkboxReduceOverlapValue === true)) {
        window.molecularData[chartIndex].isReduceOverlap = true;
    } else {
        window.molecularData[chartIndex].isReduceOverlap = false;
    }
    if (typeof formdata.checkboxShowHideLegendValue !== "undefined" 
        &&  (formdata.checkboxShowHideLegendValue === 'true' 
            || formdata.checkboxShowHideLegendValue === true)) {
        window.molecularData[chartIndex].checkboxShowHideLegendValue = true;
    } else {
        window.molecularData[chartIndex].checkboxShowHideLegendValue = false;
    }
    if (typeof formdata.checkboxShowHideSeriesLabelValue !== "undefined" 
        &&  (formdata.checkboxShowHideSeriesLabelValue === 'true' 
            || formdata.checkboxShowHideSeriesLabelValue === true)) {
        window.molecularData[chartIndex].checkboxShowHideSeriesLabelValue = true;
    } else {
        window.molecularData[chartIndex].checkboxShowHideSeriesLabelValue = false;
    }
    if (typeof formdata.checkboxShowHideTitleValue !== "undefined" 
        &&  (formdata.checkboxShowHideTitleValue === 'true' 
            || formdata.checkboxShowHideTitleValue === true)) {
        window.molecularData[chartIndex].checkboxShowHideTitleValue = true;
    } else {
        window.molecularData[chartIndex].checkboxShowHideTitleValue = false;
    }
    if (typeof formdata.checkboxShowHideMenuValue !== "undefined" 
        &&  (formdata.checkboxShowHideMenuValue === 'true' 
            || formdata.checkboxShowHideMenuValue === true)) {
        window.molecularData[chartIndex].checkboxShowHideMenuValue = true;
    } else {
        window.molecularData[chartIndex].checkboxShowHideMenuValue = false;
    }
    if (typeof formdata.checkboxShowHideTableHeaderValue !== "undefined" 
        &&  (formdata.checkboxShowHideTableHeaderValue === 'true' 
            || formdata.checkboxShowHideTableHeaderValue === true)) {
        window.molecularData[chartIndex].checkboxShowHideTableHeaderValue = true;
    } else {
        window.molecularData[chartIndex].checkboxShowHideTableHeaderValue = false;
    }
    if (typeof formdata.checkboxShowHidePaginationValue !== "undefined" 
        &&  (formdata.checkboxShowHidePaginationValue === 'true' 
            || formdata.checkboxShowHidePaginationValue === true)) {
        window.molecularData[chartIndex].checkboxShowHidePaginationValue = true;
    } else {
        window.molecularData[chartIndex].checkboxShowHidePaginationValue = false;
    }
    if (typeof formdata.checkboxConnectNullsValue !== "undefined") {
        window.molecularData[chartIndex].checkboxConnectNullsValue = formdata.checkboxConnectNullsValue * 1;
    } else {
        window.molecularData[chartIndex].checkboxConnectNullsValue = window.formInitData.checkboxConnectNulls;
    }
    if (typeof formdata.dropdownConnectorLinesSourceValue !== "undefined") {
        window.molecularData[chartIndex].dropdownConnectorLinesSourceValue = formdata.dropdownConnectorLinesSourceValue * 1;
    } else {
        window.molecularData[chartIndex].dropdownConnectorLinesSourceValue = window.formInitData.dropdownConnectorLinesSource;
    }
    if (typeof formdata.checkboxSourceDataPointsValue !== "undefined" 
        &&  (formdata.checkboxSourceDataPointsValue === 'true' 
            || formdata.checkboxSourceDataPointsValue === true)) {
        window.molecularData[chartIndex].checkboxSourceDataPointsValue = true;
    } else {
        window.molecularData[chartIndex].checkboxSourceDataPointsValue = false;
    }
    if (typeof formdata.checkboxAxisTurnOnOffValue !== "undefined" 
        &&  (formdata.checkboxAxisTurnOnOffValue === 'true' 
            || formdata.checkboxAxisTurnOnOffValue === true)) {
        window.molecularData[chartIndex].checkboxAxisTurnOnOffValue = true;
    } else {
        window.molecularData[chartIndex].checkboxAxisTurnOnOffValue = false;
    }
    if (typeof formdata.dropdownMaxNodes !== "undefined") {
        var  nodesLength = 0;
        if ((formdata.dropdownMaxNodes * 1) == 0) {
            nodesLength = data.nodes.length;
            window.molecularData[chartIndex].dropdownMaxNodes = nodesLength;
        } else if ((formdata.dropdownMaxNodes * 1) > 100) {
            nodesLength = data.nodes.length;
            window.molecularData[chartIndex].dropdownMaxNodes = Math.ceil(
                nodesLength * (formdata.dropdownMaxNodes * 1 - 100) / 100
            );
        } else {
            window.molecularData[chartIndex].dropdownMaxNodes = formdata.dropdownMaxNodes * 1;
        }
    } else {
        window.molecularData[chartIndex].dropdownMaxNodes = 100;
    }
    
    
    if (typeof formdata.dropdownBallsText !== "undefined") {
        window.molecularData[chartIndex].dropdownBallsText = formdata.dropdownBallsText * 1;
    } else {
        window.molecularData[chartIndex].dropdownBallsText = 1;
    }
    
    if (typeof formdata.dropdownChartBackgroundColor !== "undefined") {
        window.molecularData[chartIndex].dropdownChartBackgroundColor = formdata.dropdownChartBackgroundColor;
        chartBackgroundColor = formdata.dropdownChartBackgroundColor;
        if ((chartBackgroundColor == 0)) {
            window.molecularData[chartIndex].dropdownChartBackgroundColor = chartBackgroundColor = setAutoBackgroundColor();
        }
        $('#dataContaner').css('background-color', chartBackgroundColor);
    } else {
        window.molecularData[chartIndex].dropdownChartBackgroundColor = 0;
        window.molecularData[chartIndex].dropdownChartBackgroundColor = chartBackgroundColor = setAutoBackgroundColor();
        $('#dataContaner').css('background-color', chartBackgroundColor);
    }
    if (typeof formdata.dropdownAutoAdjust !== "undefined") {
        window.molecularData[chartIndex].dropdownAutoAdjust = formdata.dropdownAutoAdjust * 1;
    } else {
        window.molecularData[chartIndex].dropdownAutoAdjust = 1;
    }
    if (typeof formdata.dropdownAutoAdjustCnt !== "undefined") {
        window.molecularData[chartIndex].dropdownAutoAdjustCnt = formdata.dropdownAutoAdjustCnt * 1;
    } else {
        window.molecularData[chartIndex].dropdownAutoAdjustCnt = 250;
    }
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.molecularData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.molecularData[chartIndex].dropdownColorsSelection = 1;
    }

    if (typeof formdata.dropdownChartTextColor !== "undefined") {
        window.molecularData[chartIndex].dropdownChartTextColor = formdata.dropdownColorsSelection * 1;
    } else {
        window.molecularData[chartIndex].dropdownChartTextColor = 0;
    }
    /*
    options: [
        {label: "Auto", value: '0'}, 
        {label: "Dark text with light decoration", value: '1'},
        {label: "Light text with dark decoration", value: '2'},
        {label: "From theme", value: '3'}, 
        {label: "Custom", value: '10'}, 
    ]
    */

    if (typeof formdata.dropdownChartView !== "undefined") {
        window.molecularData[chartIndex].dropdownChartView = formdata.dropdownChartView * 1;
    } else {
        window.molecularData[chartIndex].dropdownChartView = 1;
    }
    if (typeof formdata.indentValue !== "undefined") {
        var indentValue = formdata.indentValue;
    } else {
        var indentValue = -300;
    }
    if (typeof formdata.fixedNodesArr !== "undefined") {
        if (typeof formdata.fixedNodesArr === "string") {
            window.molecularData[chartIndex].fixedNodesArr = JSON.parse(formdata.fixedNodesArr);
        } else {
            window.molecularData[chartIndex].fixedNodesArr = formdata.fixedNodesArr;
        }
    }
    if ((typeof window.newValuesFormTab1.fixedNodesArr !== "undefined") 
        && (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length == 0)) {
        if (firstLoadChart == 1 && isShare && hash != '') {
            if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
                window.molecularData[chartIndex].fixedNodesArr = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
            } else {
                window.molecularData[chartIndex].fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
            }
        } else {
            if (typeof window.molecularData[chartIndex].fixedNodesArr === "string") {
                window.molecularData[chartIndex].fixedNodesArr = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
            } else {
                window.molecularData[chartIndex].fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
            }
        }
    }

    var circleSizeMult = 0.5;
    if (typeof formdata.circleSizeMult !== "undefined") {
        if (formdata.circleSizeMult == 0) {
            formdata.circleSizeMult = 0.0001;
        }
        circleSizeMult = formdata.circleSizeMult / 2;
        window.molecularData[chartIndex].circleSizeMult = formdata.circleSizeMult / 2;
    }
    
    var fontSizeMult = 1;
    if (typeof formdata.fontSizeMult !== "undefined") {
        if (formdata.fontSizeMult == 0) {
            formdata.fontSizeMult = 0.1;
        }
        fontSizeMult = formdata.fontSizeMult;
    }
    window.molecularData[chartIndex].fontSizeMult = fontSizeMult;

    var fontSize = '';
    if (typeof formdata.nubmberBoxValueFontSize !== "undefined") {
        fontSize = formdata.nubmberBoxValueFontSize;
    }
    window.molecularData[chartIndex].fontSize = fontSize;

    var gravityValue = 0.13;
    if (typeof formdata.gravityValue !== "undefined") {
        gravityValue = formdata.gravityValue;
    }
    var dataNodes = [];
    var dataIndexes = [];
    var dataIndexesCnt = [];
    dataIndexes.push('_');
    var ii = 0;
    var excludeIndex = [];
    var mainNodesCnt = 0;
    if ((window.molecularData[chartIndex].dropdownMaxNodes > 0) /*&&
        ((typeof formdata.dropdownMaxNodes !== "undefined") && formdata.dropdownMaxNodes > 0)*/
        ) {
        $.each(data.nodes, function(index, item) {
            var isInsert = 0;
            data.nodes[index].atom = replaceNamesIndicators(item.atom);
            //if ((item.atom.charAt(0) != '_') && (item.itemIndex < window.molecularData[chartIndex].dropdownMaxNodes)) {
            if ((item.atom.charAt(0) != '_') && (mainNodesCnt < window.molecularData[chartIndex].dropdownMaxNodes * 1)) {
                if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                    item.colorIndex = hexToDecColor(item.md5);
                } else {
                    item.colorIndex = ii;
                }
                dataNodes.push(item);
                dataIndexes.push(item.itemIndex);
                dataIndexes.push(item.atom)
                
                dataIndexesCnt.push({'id':  item.itemIndex, 'str': item.atom, 'cnt': 0, 'md5': item.md5, 'colorIndex': item.colorIndex});
                isInsert = 1;
                mainNodesCnt++;
            } else {
                var cmpIndex = item.itemIndex; //item.atom.replace("_", "") * 1;
                //if ((cmpIndex < window.molecularData[chartIndex].dropdownMaxNodes)
                //if ((cmpIndex < window.molecularData[chartIndex].dropdownMaxNodes)
                //    /*&& dataIndexes.includes(cmpIndex)*/) {
                if (item.atom.charAt(0) == '_') {
                        dataNodes.push(item);
                    dataIndexes.push(item.itemIndex);
                    isInsert = 1;
                    dataIndexesCnt.push({'id': item.itemIndex, 'str': item.atom, 'cnt': 0, 'md5': item.md5});
                } else {
                }
            }
            if (isInsert == 0) {
                excludeIndex.push(item.itemIndex, dataIndexesCnt);
            } 
            ii++;
        });
        var j  = 0;
        var dataLinks = [];
        $.each(data.links, function(index, item) {
            if ((typeof item.source !== "undefined") && (typeof item.target !== "undefined")) { //NEW!
                if (!excludeIndex.includes(item.source) && !excludeIndex.includes(item.target)) {
                    if (dataIndexes.includes(item.source) && dataIndexes.includes(item.target)) {
                        if ((dataIndexes.indexOf(item.source) >= 0) && (dataIndexes.indexOf(item.target) >= 0)) {
                            //if ((item.source < window.molecularData[chartIndex].dropdownMaxNodes)
                            //    && (item.target < window.molecularData[chartIndex].dropdownMaxNodes)) {
                                dataLinks.push(item);
                                var indexSource = dataIndexes.indexOf(item.source);
                                var indexTarget = dataIndexes.indexOf(item.target);
                                for (var i = 0; i < dataIndexesCnt.length; i++) {
                                    if (dataIndexesCnt[i].id  == dataIndexes[indexSource] || dataIndexesCnt[i].str  == dataIndexes[indexSource]) {
                                        dataIndexesCnt[i].cnt++;
                                    }
                                    if (dataIndexesCnt[i].id  == dataIndexes[indexTarget] || dataIndexesCnt[i].str  == dataIndexes[indexTarget]) {
                                       dataIndexesCnt[i].cnt++;
                                    }
                                }
                            //}
                        }
                    }
                }
            }
        });
        var dataRes = removeEndNodesAndLinks(dataIndexes, dataIndexesCnt, {'nodes': dataNodes, 'links': dataLinks});
        dataNodes = dataRes.nodes;
        dataLinks = dataRes.links;
    } else {
        dataNodes = data.nodes;
        dataLinks = data.links;
    }
    
    window.molecularData[chartIndex].graph = {'nodes': dataNodes, 'links': dataLinks};
    window.molecularData[chartIndex].graphData = window.molecularData[chartIndex].graph;
    
    var isTitle = formdata.checkboxShowHideTitleValue;
    if (isTitle) {
        var titleHeight = getChartTitleRawsCnt(formdata) * 15 + 10;
    } else {
        var titleHeight = 0;
    }
    
    window.molecularData[chartIndex].width = getWidth('layoutDataContaner', 'dataContaner') - 20;
    window.molecularData[chartIndex].height = getHeight('layoutDataContaner', 'dataContaner') - titleHeight ;//27;
    if (window.molecularData[chartIndex].svgHeight > 0) {
    } else if (typeof formdata.svgHeight === "undefined") {
        if (window.molecularData[chartIndex].svgHeight == 0) {
            window.molecularData[chartIndex].svgHeight = window.molecularData[chartIndex].height;
        }
    } else {
        window.molecularData[chartIndex].svgHeight = formdata.svgHeight * 1;
    }
    if (window.molecularData[chartIndex].svgWidth > 0) {
    } else if (typeof formdata.svgWidth === "undefined") {
        if (window.molecularData[chartIndex].svgWidth == 0) {
            window.molecularData[chartIndex].svgWidth = window.molecularData[chartIndex].width;
        }
    } else {
        window.molecularData[chartIndex].svgWidth = formdata.svgWidth * 1;
    }
    window.molecularData[chartIndex].widthMult
        = window.molecularData[chartIndex].width / window.molecularData[chartIndex].svgWidth;
    window.molecularData[chartIndex].heightMult
        = window.molecularData[chartIndex].height / window.molecularData[chartIndex].svgHeight;

    window.molecularData[chartIndex].ellipsePointStep = Math.ceil((window.molecularData[chartIndex].width + window.molecularData[chartIndex].height)/ 400);

    window.molecularData[chartIndex].color = d3.scale.category20();
    window.molecularData[chartIndex].colorB = d3.scale.category20b();
    window.molecularData[chartIndex].colorC = d3.scale.category20c();
    window.molecularData[chartIndex].radius = d3.scale.sqrt().range([0, 6]);

    window.molecularData[chartIndex].maxRadius = window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxSize);

    window.molecularData[chartIndex].nodesSumSize = 0;
    window.molecularData[chartIndex].nodesItemCount = 0;
    window.molecularData[chartIndex].nodesSumSquare = 0;
    $.each(window.molecularData[chartIndex].graphData.nodes, function(index, item) {
        if ((item.atom.charAt(0) != '_') && item.size > 0) {
            window.molecularData[chartIndex].nodesSumSize  += item.size;
            window.molecularData[chartIndex].nodesItemCount++;
            var itemDiameter = window.molecularData[chartIndex].radius(item.size)
            * window.molecularData[chartIndex].circleSizeMult * 2;
            window.molecularData[chartIndex].nodesSumSquare += (itemDiameter * itemDiameter);
        }
        if (item.size > 0) {
            window.molecularData[chartIndex].graphData.nodes[index].x = Math.ceil(Math.random()
                    * (window.molecularData[chartIndex].width - window.molecularData[chartIndex].maxRadius * 2))
                    + window.molecularData[chartIndex].maxRadius;
            window.molecularData[chartIndex].graphData.nodes[index].y = Math.ceil(Math.random()
                    * (window.molecularData[chartIndex].height - window.molecularData[chartIndex].maxRadius * 2))
                    + window.molecularData[chartIndex].maxRadius;
        }
    });
    //factor correction for the radius of the circle node
    window.molecularData[chartIndex].circleSizeMult = window.molecularData[chartIndex].circleSizeMult
        * (100 / window.molecularData[chartIndex].maxSize);
    var factorCorrection = Math.sqrt(window.molecularData[chartIndex].width * window.molecularData[chartIndex].height * 0.25) / 
        Math.sqrt(window.molecularData[chartIndex].nodesSumSquare);
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 8) {
        factorCorrection *= 0.7;
    }
    if (window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxSize)
        * window.molecularData[chartIndex].circleSizeMult * factorCorrection 
        > (window.molecularData[chartIndex].width + window.molecularData[chartIndex].height) / 15) {
        factorCorrection *= ((window.molecularData[chartIndex].width + window.molecularData[chartIndex].height) / 15) /
            (window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxSize)
                * window.molecularData[chartIndex].circleSizeMult * factorCorrection);
    }

    window.molecularData[chartIndex].circleSizeMult = window.molecularData[chartIndex].circleSizeMult * factorCorrection;// * formdata.circleSizeMult;
    window.molecularData[chartIndex].circleSizeMult *= circleSizeMult;

    window.molecularData[chartIndex].padding = 50; // separation between same-color nodes
    window.molecularData[chartIndex].clusterPadding = 60; // separation between different-color nodes
    
    
    //window.molecularData[chartIndex].height = window.molecularData[chartIndex].height - titleHeight;
    window.molecularData[chartIndex].svg = d3.select("body #dataContaner").html('').append("svg")
        .attr("width", window.molecularData[chartIndex].width)
        .attr("height", window.molecularData[chartIndex].height - 30);

    if (isTitle) {
        $("body #dataContaner").prepend(getChartTitle(formdata));
    }

    window.molecularData[chartIndex].force = d3.layout.force()
        .size([window.molecularData[chartIndex].width, window.molecularData[chartIndex].height]);
    
    if (window.molecularData[chartIndex].isIndentValuesRelated === true) {
        window.molecularData[chartIndex].force.gravity(function(node) {
            return gravityValue * (node.size/window.molecularData[chartIndex].maxSize);
        });
        window.molecularData[chartIndex].force.charge(function(node) {
            return indentValue * (node.size/window.molecularData[chartIndex].maxSize);
        });
    } else {
        window.molecularData[chartIndex].force.gravity(gravityValue);
        window.molecularData[chartIndex].force.charge(
            function(d) { 
                return indentValue;
            }
        );
    }
    
    window.molecularData[chartIndex].force.linkDistance(function(d) { 
        return d.source.atom.charAt(0) == '_' || d.target.atom.charAt(0) == '_'
            ? (
                window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxSize
                * window.molecularData[chartIndex].circleSizeMult) / 20
            )
            : (
                window.molecularData[chartIndex].radius(d.source.size) 
                + window.molecularData[chartIndex].radius(d.target.size)
                + window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxSize
                * window.molecularData[chartIndex].circleSizeMult)
            );
        }
    )
        .linkStrength(function(d) {
            return d.source.atom.charAt(0) == '_' || d.target.atom.charAt(0) == '_' ? 0.35 : 0.15;
        })
        .friction(0.95)
        .theta(0.8)
        .alpha(0.1)
        .start();
    if (window.molecularData[chartIndex].force) {
        window.molecularData[chartIndex].force.stop();
    }
    window.molecularData[chartIndex].force
        .nodes(window.molecularData[chartIndex].graphData.nodes)
        .links(window.molecularData[chartIndex].graphData.links);
    
    window.molecularData[chartIndex].force
        .on("tick", tick);
    window.molecularData[chartIndex].force.start();
    if (window.molecularData[chartIndex].force) {
        setInterval(function () {
            if (typeof window.molecularData[chartIndex].tickCnt !== "undefined" && window.molecularData[chartIndex].tickCnt > 50) {
                window.molecularData[chartIndex].force.stop();
            } else {
                setInterval(function () {
                    if (typeof window.molecularData[chartIndex].tickCnt !== "undefined" && window.molecularData[chartIndex].tickCnt > 50) {
                        window.molecularData[chartIndex].force.stop();
                    }
                }, 5000);
            }
            try {
                window.molecularData[chartIndex].link.selectAll("line")
                    .filter(function(d,i){
                        if ((d.target.atom === '') || (d.source.atom === '')) {
                            return false;
                        } else {
                            return d;
                        }
                     })
                    .attr("x1", function(d) {if (typeof d.source !== 'undefined') {return d.source.x;}})
                    .attr("y1", function(d) {if (typeof d.source !== 'undefined') {return d.source.y;}})
                    .attr("x2", function(d) {if (typeof d.target !== 'undefined') {return d.target.x;}})
                    .attr("y2", function(d) {if (typeof d.target !== 'undefined') {return d.target.y;}});
            } catch (error) {
                var er = error;
            }
        }, 5000);
    }
    $.each(window.molecularData[chartIndex].graphData.nodes, function(index, item) {
        window.molecularData[chartIndex].graphData.nodes[index].sizeReal = item.size;
    });
    window.molecularData[chartIndex].link = window.molecularData[chartIndex].svg.selectAll(".link")
        .data(window.molecularData[chartIndex].graphData.links)
        .enter().append("g")
        .attr("lll", function(d) { return JSON.stringify(d); })
        .attr("class", "");
    
    window.molecularData[chartIndex].link.filter(function(d, i) {
        if (
            (typeof(d.target) === 'undefined')
            || (typeof(d.source) === 'undefined')
            || (typeof(d.target.atom) === 'undefined')
            || (typeof(d.source.atom) === 'undefined')
        ) {
            return false;
        } else {
            d.source.selected = false;
            d.target.selected = false;
            if (window.molecularData[chartIndex].isConnectorsLines) {
                d.source.connectorLines = 1;
                d.target.connectorLines = 1;
            } else {
                d.source.connectorLines = 0;
                d.target.connectorLines = 0;
            }
            if (typeof(d.target.atom) !== 'undefined') {
                if (d.target.atom.charAt(0) != '_') {
                    formdata.changedColors = findPopWordNewColorMolecular(d.target.atom, formdata.changedColors, d.target.md5);
                }
            }
            if (typeof(d.source.atom) !== 'undefined') {
                if (d.source.atom.charAt(0) != '_') {
                    formdata.changedColors = findPopWordNewColorMolecular(d.source.atom, formdata.changedColors, d.source.md5);
                }
            }
            if (typeof(d.source.color) === 'undefined') {
                if (typeof window.molecularData[chartIndex].wordsListPrev === 'undefined') {
                    window.molecularData[chartIndex].wordsListPrev = {};
                }
                
                var itemVal;
                if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                    itemVal = d.source.md5;
                } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.source.value * 1;
                } else {
                    itemVal = undefined;
                }

                d.source.color = (((typeof(d.source.atom) === 'undefined')) || (d.source.atom.charAt(0) == '_')) ? window.colorGray
                    : (
                        //formdata.changedColors = findPopWordNewColorMolecular(d.source.atom, formdata.changedColors, d.source.md5, chartIndex);
                        formdata.changedColors.hasOwnProperty(d.source.md5)
                            ? formdata.changedColors[d.source.md5].color
                            : (typeof window.molecularData[chartIndex].wordsListPrev[d.source.atom] === 'undefined'
                                ? (getColor(d.source.colorIndex, undefined, undefined, itemVal, d.source.atom,
                                    (wordsListArr).indexOf(d.source.atom), formdata))
                                : window.molecularData[chartIndex].wordsListPrev[d.source.atom].newcolor
                            )
                    );
                if (typeof window.molecularData[chartIndex].wordsList[d.source.atom] !== 'undefined') {
                    if (window.molecularData[chartIndex].wordsList[d.source.atom].newcolor != 
                        getColor(d.source.colorIndex, undefined, undefined, itemVal, d.source.atom,
                            (wordsListArr).indexOf(d.source.atom), formdata)) {
                        window.molecularData[chartIndex].wordsList[d.source.atom].color = 
                            getColor(d.source.colorIndex, undefined, undefined, itemVal, d.source.atom,
                                (wordsListArr).indexOf(d.source.atom), formdata);
                    }
                }
            }
            if (typeof(d.target.color) === 'undefined') {
                var itemVal;
                if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                    itemVal = d.target.md5;
                } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.target.value * 1;
                } else {
                    itemVal = undefined;
                }
                d.target.color = ((typeof(d.target.atom) === 'undefined') || (d.target.atom.charAt(0) == '_')) ? window.colorGray 
                    : (
                        formdata.changedColors.hasOwnProperty(d.target.md5)
                        ? formdata.changedColors[d.target.md5].color
                        : (typeof window.molecularData[chartIndex].wordsList[d.target.atom] === 'undefined'
                            ? getColor(d.target.colorIndex, undefined, undefined, itemVal, d.target.atom,
                                (wordsListArr).indexOf(d.target.atom), formdata)
                            : window.molecularData[chartIndex].wordsList[d.target.atom].newcolor
                        )
                    );
                if (typeof window.molecularData[chartIndex].wordsList[d.target.atom] !== 'undefined') {
                    if (window.molecularData[chartIndex].wordsList[d.target.atom].newcolor != 
                        getColor(d.target.colorIndex, undefined, undefined, itemVal, d.target.atom,
                            (wordsListArr).indexOf(d.target.atom))) {
                        window.molecularData[chartIndex].wordsList[d.target.atom].color = 
                            getColor(d.target.colorIndex, undefined, undefined, itemVal, d.target.atom,
                                (wordsListArr).indexOf(d.target.atom), formdata);
                    }
                }
            }
        }
        return false;
    });

    //Draw line
    window.molecularData[chartIndex].link
    .filter(function(d) {
        if (
            (typeof(d.target) === 'undefined') 
            || (typeof(d.target.atom) === 'undefined')
            || (typeof(d.source) === 'undefined') 
            || (typeof(d.source.atom) === 'undefined')
            || ((!isSelected(d.source) && (d.source.connectorLines == 0 || d.target.connectorLines == 0)))
        ) {
            return false;
        } else {
            if ((d.target.atom != '') && (d.source.atom != '')) {
                graphLinksNodes.push(d.target.atom);
                graphLinksNodes.push(d.source.atom);
            }
             return true;
        }
    })
    .append("line")
    .style("stroke-width", function(d) {
        var lineWidth = 1;
        if (lineWidthMult == 0) {
            lineWidth = getLineWidthMult(d, connectorLineWidthIndicator, CHART_TYPE_MOLECULAR, valueMaxDepth, maxDistance, maxFrequency, maxValue);            
            if (lineWidth < 1) {
                lineWidth = 1;
            }
        } else {
            lineWidth = lineWidth * lineWidthMult;
        }
        
        //return ((d.bond * 2 - 1) * 2 + "px"; 
        return lineWidth + "px"; 
    })
    .style("stroke", function(d) {
        return (
            ((typeof(d.source.atom) === 'undefined') || (d.source.atom.charAt(0) == '_')) 
            || ((typeof(d.target) === 'undefined') || d.target.atom.charAt(0) == '_')
        ) ? window.colorGray : d.source.color; 
     })
    .style("stroke-dasharray", function(d) {
        return (
                ((typeof(d.source) === 'undefined') || (d.source.atom.charAt(0) == '_')) 
                || ((typeof(d.target) === 'undefined') || d.target.atom.charAt(0) == '_')
            ) ? '0,2 1' : '';
     })
    .attr("x1", function(d) {if (typeof d.source !== 'undefined') {return d.source.x;}})
    .attr("y1", function(d) {if (typeof d.source !== 'undefined') {return d.source.y;}})
    .attr("x2", function(d) {if (typeof d.target !== 'undefined') {return d.target.x;}})
    .attr("y2", function(d) {if (typeof d.target !== 'undefined') {return d.target.y;}});

    window.molecularData[chartIndex].node = window.molecularData[chartIndex].svg.selectAll(".node")
        .data(window.molecularData[chartIndex].graphData.nodes
            .filter(function(d) {
                if (d.size == 0) {
                    return false;
                } else {
                    if ((d.size == 1) && (!graphLinksNodes.includes(d.atom))){
                        //return false;
                    }
                    return d;
                }
            })
        )
        .enter()
        .append("g")
        .attr("class", function(d) { 
            var nodeClass = 'node';
            var resAutoAdjust = autoAdjustByType(d);
            nodeClass += resAutoAdjust.classStr;
            d = resAutoAdjust.node;
            return nodeClass; 
        })
        .attr("id", function(d) {return d.itemIndex;})
        .attr("name", function(d) {return d.md5;})
        .attr("ddd", function(d) {return JSON.stringify(d); })
        .call(window.molecularData[chartIndex].force.drag);
    window.molecularData[chartIndex].node.filter(function(d, i) {
        var itemVal;
        if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
            itemVal = d.md5;
        } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
            itemVal = d.value * 1;
        } else {
            itemVal = undefined;
        }
        if (window.molecularData[chartIndex].isConnectorsLines) {
            d.connectorLines = 1;
        } else {
            d.connectorLines = 0;
        }
        if (typeof(d.color) === 'undefined') {
            d.color = d.atom.charAt(0) == '_' ? window.colorGray : getColor(d.colorIndex, undefined, undefined, itemVal, d.atom,
                (wordsListArr).indexOf(d.atom), formdata);
        }
        return false;
    });

    //Draw circles
    window.molecularData[chartIndex].node.filter(
        function(d) {
            if (d.size == 0) {
                return false;
            }
            if (typeof(d.color) === 'undefined') {
                var itemVal;
                if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                    itemVal = d.md5;
                } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.value * 1;
                } else {
                    itemVal = undefined;
                }
                d.color = d.atom.charAt(0) == '_' ? window.colorGray : getColor(d.colorIndex*1, undefined, undefined, itemVal, d.atom,
                    (wordsListArr).indexOf(d.atom), formdata);
            }
            var color = splitHexColor(d.color);
            var bgDelta = (color.r * 0.299) + (color.g * 0.587) + (color.b * 0.114);
            var foreColor = (255 - bgDelta < 195) ? '0' : '1';
            d.fore = foreColor;
            return d;
        }
    )
    .append("circle")
        .attr("r", function (d) {
            if (d.atom.charAt(0) != '_' && window.molecularData[chartIndex].wordsListReset == true) {
                var itemVal;
                if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                    itemVal = d.md5;
                } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.value * 1;
                } else {
                    itemVal = undefined;
                }
                var itemColor = getColor(d.colorIndex, undefined, undefined, itemVal, d.atom,
                    (wordsListArr).indexOf(d.atom), formdata);
                if (firstLoadChart == 1 && isShare && hash != '') {
                    window.molecularData[chartIndex].wordsList[d.atom] = {
                        'name': d.atom,
                        'size': d.sizeReal,
                        'color': (d.color == itemColor ? d.color : itemColor),
                        'colornew' : (
                            formdata.changedColors.hasOwnProperty(d.md5) 
                            ? formdata.changedColors[d.md5].color 
                            : d.color) + '111',
                        'id': d.itemIndex,
                        'fore': d.fore,
                        'md5': d.md5,
                        'colorIndex': d.colorIndex
                    };
                }
                if (typeof window.molecularData[chartIndex].wordsListSelected[d.atom] !== 'undefined') {
                    window.molecularData[chartIndex].wordsListSelected[d.atom] = {
                        'name': d.atom,
                        'size': d.sizeReal,
                        'color': (d.color == itemColor ? d.color : itemColor),
                        'colornew' : (
                            formdata.changedColors.hasOwnProperty(d.md5) 
                            ? formdata.changedColors[d.md5].color 
                            : d.color),
                        'id': d.itemIndex,
                        'fore': d.fore,
                        'md5': d.md5,
                        'colorIndex': d.colorIndex
                    };
                }
            } else if (d.atom.charAt(0) != '_') {
                var itemVal;
                if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                    itemVal = d.md5;
                } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
                    itemVal = d.value * 1;
                } else {
                    itemVal = undefined;
                }
                var itemColor = getColor(d.colorIndex, undefined, undefined, itemVal, d.atom, (wordsListArr).indexOf(d.atom), formdata);
                if (typeof window.molecularData[chartIndex].wordsListSelected[d.atom] !== 'undefined') {
                    window.molecularData[chartIndex].wordsListSelected[d.atom] = {
                        'name': d.atom,
                        'size': d.sizeReal,
                        'color': (d.color == itemColor ? d.color : itemColor),
                        'colornew' : (
                            formdata.changedColors.hasOwnProperty(d.md5) 
                            ? formdata.changedColors[d.md5].color 
                            : d.color),
                        'id': d.itemIndex,
                        'fore': d.fore,
                        'md5': d.md5,
                        'colorIndex': d.colorIndex
                    };
                }
                if (typeof window.molecularData[chartIndex].wordsList[d.atom] === 'undefined') {
                    window.molecularData[chartIndex].wordsList[d.atom] = {
                        'name': d.atom,
                        'size': d.sizeReal,
                        'color': (d.color == itemColor ? d.color : itemColor),
                        'colornew' : (
                            formdata.changedColors.hasOwnProperty(d.md5) 
                            ? formdata.changedColors[d.md5].color 
                            : d.color),
                        'id': d.itemIndex,
                        'fore': d.fore,
                        'md5': d.md5,
                        'colorIndex': d.colorIndex
                    };
                }
            }
            if (d.atom.charAt(0) == '_' && d.connectorLines == 0) return 0;
            return d.atom.charAt(0) == '_' ? 1 : window.molecularData[chartIndex].radius(d.size)
                * window.molecularData[chartIndex].circleSizeMult;
        })
        .style("fill", function(d, i) {
            var itemVal;
            if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                itemVal = d.md5;
            } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
                itemVal = d.value * 1;
            } else {
                itemVal = undefined;
            }
            if (typeof(d.color) === 'undefined')
                d.color = getColor(d.colorIndex, undefined, undefined, itemVal, d.atom, (wordsListArr).indexOf(d.atom), formdata);
            return d.atom.charAt(0) == '_'
                ? 'gray' 
                : (typeof(d.color) === 'undefined' ? getColor(d.colorIndex, undefined, undefined, itemVal, d.atom,
                    (wordsListArr).indexOf(d.atom), formdata) : d.color);
        }
    );
    var textWidth = [];
    window.molecularData[chartIndex].circle = window.molecularData[chartIndex].svg.selectAll("circle")
        .data(window.molecularData[chartIndex].graphData.nodes);
    window.molecularData[chartIndex].node.append("text")
        .attr("dy", ".35em")
        .attr("class", function (d) {return getTextColorMol(d, formdata, 1);})
        .attr("text-anchor", "middle")
        .text(function (d) {
            return d.atom.charAt(0) == '_' ? '' : d.atom;
        }).
        attr("text-str", function (d) {
            return d.atom.charAt(0) == '_' ? '' : d.atom;
        })
        .style({"font-size": adaptLabelFontSize, "fill": function (d) {return getTextColorMol(d, formdata, 0);}})
        .each(function(d,i) {
            var thisWidth = this.getComputedTextLength();
            var bbox = this.getBBox();

            var textWidth = bbox.width;
            var textHeight = bbox.height;

            d.textWidth = thisWidth;
            d.textHeight = textHeight;
            if (thisWidth > (window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult * 2)) {
                
                if (d.atom.charAt(0) == '_') {
                    return '';
                } else {
                    var lineMult = window.molecularData[chartIndex].circleSizeMult * window.molecularData[chartIndex].radius(d.size) * 2 / 100;
                    if (lineMult < 1){
                        lineMult = lineMult * 1.3;
                    }
                    if (lineMult < 0.8){
                        lineMult = lineMult * 1.5;
                    }
                    if (lineMult > 2)  {
                        lineMult = lineMult * 0.8;
                    }
                    if (lineMult < 0.6) {
                        lineMult = 0.6;
                    }
                    if (lineMult > 1) {
                        lineMult = 1;
                    }
                    var xText2 = window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult / 2 
                        + 20 * lineMult
                        - (window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult);
                    var xText3 = window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult / 2 
                        + 20 * lineMult
                        - (window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult);
                    //full single-line
                    if (window.molecularData[chartIndex].dropdownBallsText == 0) {
                    //full multi-line
                    } else if (window.molecularData[chartIndex].dropdownBallsText == 1) {
                        if (d.atom.search(' ') >= 0){
                            var wordsArr = d.atom.split(" ");
                            if (wordsArr.length == 1) {
                            } else if (wordsArr.length == 2) {
                                d3.select(this).html('');
                                
                                d3.select(this).append("tspan")
                                .attr("dy", -18 * lineMult)
                                .attr("x", 0)
                                .text('........................................')
                                .attr("style", "fill: transparent;text-shadow: none;");
                                
                                d3.select(this).append("tspan")
                                .attr("dy", 15 * lineMult)
                                .attr("x", 0)
                                .text(wordsArr[0]);
                                d3.select(this).append("tspan")
                                .attr("dy", 15 * lineMult)
                                .attr("x", 0)
                                .text(wordsArr[1]);
                            } else if (wordsArr.length == 3){
                                d3.select(this).html('');
                                
                                d3.select(this).append("tspan")
                                .attr("dy", -25 * lineMult)
                                .attr("x", 0)
                                .text('........................................')
                                .attr("style", "fill: transparent;text-shadow: none;");
                                
                                d3.select(this).append("tspan")
                                .attr("dy", 15 * lineMult)
                                .attr("x", 0)
                                .text(wordsArr[0]);
                                d3.select(this).append("tspan")
                                .attr("dy", 15 * lineMult)
                                .attr("x", 0)
                                .text(wordsArr[1]);
                                d3.select(this).append("tspan")
                                .attr("dy", 15 * lineMult)
                                .attr("x", 0)
                                .text(wordsArr[2]);
                            } else if (wordsArr.length > 3) {
                                var charWidth = Math.floor(thisWidth/d.atom.length);
                                nextLine = 0;
                                var fistLine = '';
                                var secondLine = '';
                                for (var i = 0; i < wordsArr.length; i++) {
                                    if (nextLine == 0) {
                                        if ((fistLine + wordsArr[i]).length * charWidth <= thisWidth / 2){
                                            fistLine = fistLine == '' ? wordsArr[i] : fistLine + ' ' + wordsArr[i];
                                        } else {
                                            secondLine = wordsArr[i];
                                            nextLine = 1;
                                        }
                                    } else {
                                        secondLine = secondLine + ' ' + wordsArr[i];
                                    }
                                }
                                d3.select(this).html('');
                                
                                d3.select(this).append("tspan")
                                .attr("dy", -18 * lineMult)
                                .attr("x", 0)
                                .text('........................................')
                                .attr("style", "fill: transparent;text-shadow: none;");
                                
                                d3.select(this).append("tspan")
                                .attr("dy", 15 * lineMult)
                                .attr("x", 0)
                                .text(fistLine);
                                d3.select(this).append("tspan")
                                .attr("dy", 15 * lineMult)
                                .attr("x", 0)
                                .text(secondLine);
                            }
                        }
                    //cut to the size of a circle
                    } if (window.molecularData[chartIndex].dropdownBallsText == 2) {
                        var charWidth = Math.floor(thisWidth/d.atom.length);
                        var maxWidth = window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult * 2;
                        var cutStr = '';
                        for (var i = 0; i < d.atom.length; i++) {
                            if ((charWidth * (i+1) + charWidth * 3) <= maxWidth) {
                                cutStr = cutStr + d.atom.charAt(i);
                            }
                        }
                        cutStr = cutStr + '...';
                        d3.select(this).html('');
                        d3.select(this).append("tspan")
                        .attr("dy",  2 * lineMult)
                        .attr("x", 0)
                        .text(cutStr);
                    //hide if is not fit in the circle
                    } if (window.molecularData[chartIndex].dropdownBallsText == 3) {
                        d3.select(this).html('');
                    }
                }
            }
        });
    
    window.molecularData[chartIndex].div = d3.select("body #dataContaner").append("div")
        .attr("class", "tooltip")
        .style("opacity", 1e-6);

    window.molecularData[chartIndex].node.on("dblclick", function (p) {
        if (!isSelected(p)) {
            p.selected = true;
            className = '';//"link1";
            colorName = p.color;
        } else {
            p.selected = false;
            className = '';//"link";
            colorName = window.colorGray;
        }
        intermediate = [];
        intermediateUnique = [];
        window.molecularData[chartIndex].link.filter(function (d) {
            if ((d.target.x == p.x && d.target.y == p.y) || d.source.x == p.x && d.source.y == p.y) {
                if (d.source.atom.charAt(0) == '_' || d.target.atom.charAt(0) == '_') {
                    intermediate.push(d);
                }
                return true;
            }
        })
        .style("stroke-width", function(d) { return (d.bond * 2 - 1) * 2 + "px"; })
        .style("stroke", function(d) {
            if (p.selected) {
                return (d.source.atom.charAt(0) == '_' || d.target.atom.charAt(0) == '_')
                    ? window.molecularData[chartIndex].colorGray
                    : d.source.color;
            } else {
                 return null;
            }
        })
        .style("stroke-dasharray", function(d) {
            return d.source.atom.charAt(0) == '_' || d.target.atom.charAt(0) == '_' ? '0,2 1' : '';
        })
        .attr("class", className);

        for (i = 0; i < 4; i++) {
            var added = false;
            window.molecularData[chartIndex].link.filter(function(d) {
                intermediate.forEach(function(i) {
                    if ((d.target.x == i.source.x && d.target.y == i.source.y)
                        || (d.target.x == i.target.x && d.target.y == i.target.y)
                        || (d.source.x == i.source.y && d.source.y == i.source.y)
                        || (d.source.x == i.target.x && d.source.y == i.target.y)) {
                        d.selected = p.selected;
                        if (d.source.atom.charAt(0) == '_' && d.target.atom.charAt(0) == '_') {
                            nodeKey = d.source.atom + d.target.atom;
                            if (!intermediateUnique.includes(nodeKey)) {
                                intermediate.push(d);
                                intermediateUnique.push(nodeKey);
                                added = true;
                            }
                        }
                        return false;
                    }
                });
                return false;
            });
            if (!added) break;
        }
        window.molecularData[chartIndex].link.filter(function (d) {
            found = false;
            intermediate.forEach(function(i) {
                if ((d.target.x == i.source.x && d.target.y == i.source.y)
                    || (d.target.x == i.target.x && d.target.y == i.target.y)
                    || (d.source.x == i.source.y && d.source.y == i.source.y)
                    || (d.source.x == i.target.x && d.source.y == i.target.y)) {
                    found = true;
                    d.selected = p.selected;
                    return found;
                }
            });
            return found;
        })
        .style("stroke-width", function(d) { return (d.bond * 2 - 1) * 2 + "px"; })
        .style("stroke", function(d) {
            if (p.selected) {
                return (d.source.atom.charAt(0) == '_' || d.target.atom.charAt(0) == '_') ? window.colorGray : d.source.color;
            } else {
                return null;
            }
        })
        .style("stroke-dasharray", function(d) {
            return d.source.atom.charAt(0) == '_' || d.target.atom.charAt(0) == '_' ? '0,2 1' : '';
        })
        .attr("class", className);
    });

    window.molecularData[chartIndex].node.on("mouseover", function (d) {
        /*window.molecularData[chartIndex].div
            .transition()
            .duration(300)
            .style("opacity", 1);*/
        window.molecularData[chartIndex].div
            .html( /*"<button id='but1'>Button 1</button><button  id='but2'>Button 2</button>" + */'<b>' + d.atom + "</b><br>" + 'Value: ' + d.size + '%/' + d.value )
            .style("left", (d3.event.pageX - 40) + "px")
            .style("top", (d3.event.pageY - 30) + "px")
            .transition()
            //.duration(1300)
            .style("opacity", 1);

        //window.molecularData[chartIndex].div.style("left", (d3.event.pageX-4)+"px")
        //    .style("top", (d3.event.pageY+8)+"px")
        //window.molecularData[chartIndex].div.transition()/*.duration(1000)*/.style("opacity", 0.9)
        d3.event.stopPropagation();

        d3.select("#but1").on("click", function(){ alert("button 1 clicked")});

    });
    var savePageX = 0, savePageY = 0;
    
    window.molecularData[chartIndex].node.on("mousemove", function (d) {
        if ((savePageX < (d3.event.pageX - 20)) || (savePageX > (d3.event.pageX + 20))
            || (savePageY < (d3.event.pageY - 20)) || (savePageY > (d3.event.pageY + 20))
        ) {
            window.molecularData[chartIndex].d3 = d3;
            //setTimeout(function() {
                window.molecularData[chartIndex].div
                    .html( /*"<button id='but1'>Button 1</button><button  id='but2'>Button 2</button>" +*/ '<b>' + d.atom + "</b><br>" + 'Value: ' + d.size + '%/' + d.value )
                    .style("left", (window.molecularData[chartIndex].d3.event.pageX - 40) + "px")
                    .style("top", (window.molecularData[chartIndex].d3.event.pageY - 30) + "px")
                    .transition()
                    //.duration(1300)
                    .style("opacity", 1);

                window.molecularData[chartIndex].d3.event.stopPropagation();

                window.molecularData[chartIndex].d3.select("#but1").on("click", function(){ alert("button 1 clicked")});

                savePageX = window.molecularData[chartIndex].d3.event.pageX;
                savePageY = window.molecularData[chartIndex].d3.event.pageY;
            //}, 1000);

        }

        /*if (d.distance == null) {
            var distanceSrt = '';
        } else {
            var distanceSrt = ', Distance: ' + d.distance;
        }
        window.molecularData[chartIndex].div
            .html( '<b>' + d.atom + "</b><br>" + 'Value: ' + d.size + '%/' + d.value + distanceSrt)
            .style("left", (d3.event.pageX - 40) + "px")
            .style("top", (d3.event.pageY - 30) + "px");*/
    });
    window.molecularData[chartIndex].node.on("mouseout", function (d) {
        window.molecularData[chartIndex].div
            .transition()
            .duration(300)
            .style("opacity", 1e-6);
    });
    window.molecularData[chartIndex].drag = window.molecularData[chartIndex].force.drag()
        .on("dragstart", function (d) {
            if (typeof window.molecularData[chartIndex].fixedNodesArr[d.atom] !== "undefined") {
                delete window.molecularData[chartIndex].fixedNodesArr[d.atom];
                if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
                    var fixedNodesArrObj = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
                } else {
                    var fixedNodesArrObj = window.newValuesFormTab1.fixedNodesArr;
                }
                if (typeof fixedNodesArrObj[d.atom] !== "undefined") {
                    delete fixedNodesArrObj[d.atom];
                }
                window.newValuesFormTab1.fixedNodesArr = fixedNodesArrObj;
            }

            d3.select(this).classed("fixed", d.fixed = true).classed("user-fixed", d.userFixed = 1);
    });
    //if (window.molecularData[chartIndex].wordsListReset == true) {
        if (isDebugg) {console.log('1611 firstLoadChart='+firstLoadChart, isShare, hash);}
        if (firstLoadChart == 1 && isShare && hash != '') {
            firstLoadChart = 0;
        }
        $.each(window.molecularData[chartIndex].graphData.nodes, function(index, item) {
            //if (isDebugg) {console.log('161', item);}
            var itemVal;
            if (window.molecularData[chartIndex].dropdownColorsSelection == 1) {
                itemVal = item.md5;
            } else if (window.molecularData[chartIndex].dropdownColorsSelection == 10) {
                itemVal = item.value * 1;
            } else {
                itemVal = undefined;
            }
            var itemColor = getColor(item.colorIndex, undefined, undefined, itemVal, item.atom, (wordsListArr).indexOf(item.atom), formdata);
            if ((item.atom.charAt(0) != '_') && window.molecularData[chartIndex].wordsListReset == true) {
                window.molecularData[chartIndex].wordsList[item.atom] = {
                    'name': item.atom,
                    'size': item.sizeReal,
                    'color': (item.color == itemColor ? item.color : itemColor),
                    'colornew': 
                        (formdata.changedColors.hasOwnProperty(item.md5)
                            ? formdata.changedColors[item.md5].color
                            : (
                                item.color
                            )
                        ),
                    'id': item.itemIndex,
                    'fore': item.fore,
                    'md5': item.md5,
                    'colorIndex': item.colorIndex
                };
            }
            if ((item.atom.charAt(0) != '_')) {
                if (typeof window.molecularData[chartIndex].wordsList[item.atom] == "undefined") {
                    window.molecularData[chartIndex].wordsList[item.atom] = {
                        'name': item.atom,
                        'size': item.sizeReal,
                        'color': (item.color == itemColor ? item.color : itemColor),
                        'colornew': 
                            (formdata.changedColors.hasOwnProperty(item.md5)
                                ? formdata.changedColors[item.md5].color
                                : (
                                    item.color
                                )
                            ),
                        'id': item.itemIndex,
                        'fore': item.fore,
                        'md5': item.md5,
                        'colorIndex': item.colorIndex
                    };
                }
            }
        });
        window.radialData[chartIndex].wordsListFirst = window.molecularData[chartIndex].wordsList;
        window.molecularData[chartIndex].wordsListFirst = window.molecularData[chartIndex].wordsList;
        addWordsCheckboxesAndInfo(
            window.molecularData[chartIndex].wordsListFirst,
            window.molecularData[chartIndex].wordsListSelected,
            CHART_TYPE_MOLECULAR
        );
        window.radialData[chartIndex].wordsListReset = false;
        window.molecularData[chartIndex].wordsListReset = false;
    //}
    if (Object.keys(window.radialData[chartIndex].wordsListFirst).length == 0) {
        window.radialData[chartIndex].wordsListFirst = window.molecularData[chartIndex].wordsList;
    }
    if (Object.keys(window.molecularData[chartIndex].wordsListFirst).length == 0) {
        window.molecularData[chartIndex].wordsListFirst = window.molecularData[chartIndex].wordsList;
    }
    firstLoadChart = 0;
    window.molecularData[chartIndex].svgHeight = window.molecularData[chartIndex].height;
    window.molecularData[chartIndex].svgWidth = window.molecularData[chartIndex].width;
    window.molecularData[chartIndex].widthMult = 1;
    window.molecularData[chartIndex].heightMult = 1;
    //window.molecularData[chartIndex].fixedNodesArr = {};
}


function getTextColorMol(d, formdata, getCss) {
    switch(formdata.dropdownChartTextColor) {
        case '0': //Auto
            var chartTextColorCss = d.atom.charAt(0) == '_' ? '' : (d.fore == 1 ? 'white' : 'black');
            var chartTextColor = d.atom.charAt(0) == '_' ? '' : (d.fore == 1 ? '#FFFFFF' : '#000000');
            break;
        case '1': //Dark text with light decoration
            var chartTextColorCss = 'black';
            var chartTextColor = '#000000';
            break;
        case '2': //Light text with dark decoration
            var chartTextColorCss = 'white';
            var chartTextColor = '#FFFFFF';
            break;
        //case 3: //From theme
            //window.molecularData[chartIndex].chartTextColor = '';
            //break;
        case '10': //Custom
            var chartTextColorCss = '';
            if ((typeof formdata.changedCTColor !== "undefined") && (formdata.changedCTColor != '')) {
                var chartTextColor = formdata.changedCTColor;
            } else {
                var chartTextColorCss = 'black';
                var chartTextColor = '#000000';
            }
            break;
        default:
            var chartTextColorCss = d.atom.charAt(0) == '_' ? '' : (d.fore == 1 ? 'white' : 'black');
            var chartTextColor = d.atom.charAt(0) == '_' ? '' : (d.fore == 1 ? '#FFFFFF' : '#000000');
    }
    return (getCss == 1) ? chartTextColorCss : chartTextColor;
}


function removeEndNodesAndLinks(dataIndexes, dataIndexesCnt, data) {
    var dataIndexesForRemove = [];
    dataIndexesForRemove[0] = 0;
    for (var i = 0; i < dataIndexesCnt.length; i++) {
        if (dataIndexesCnt[i].cnt < 2) {
            if (dataIndexesCnt[i].str.charAt(0) != '_' && dataIndexesCnt[i].cnt == 1) {
            } else {
                dataIndexesForRemove.push(dataIndexesCnt[i].id);
            }
        }
    }
    var dataNodes = [];
    var isLinksRemoved = 0;
    var dataIndexes = [];
    //remove second level ended nodes
    for ( var j = 0; j < data.nodes.length; j++) { 
        if ( dataIndexesForRemove.indexOf(data.nodes[j].itemIndex) > 0 && data.nodes[j].atom.charAt(0) == '_') {
            data.nodes[j] = {"size": 0, "itemIndex": data.nodes[j].itemIndex, "atom": '', "md5": data.nodes[j].md5};
            isLinksRemoved = 1;
        } else {
            dataIndexes.push(data.nodes[j].itemIndex);
            dataNodes.push(data.nodes[j]);
        }
    }
    
    //remove ended links from only one node
    for (var i = 0; i < data.links.length; i++) {
        if (((dataIndexesForRemove.indexOf(data.links[i].source) > 0) 
            || (dataIndexesForRemove.indexOf(data.links[i].target) > 0))
            ) {
            data.links.splice(i, 1);
            isLinksRemoved = 1;
            i--;
        }
        if (typeof data.links[i] != "undefined") {
            if ((dataIndexes.indexOf(data.links[i].source) < 0) || (dataIndexes.indexOf(data.links[i].target) < 0)) {
                data.links.splice(i, 1);
                isLinksRemoved = 1;
                i--;
            }
        }
    }
    var dataRes = data;
    dataIndexesCnt = [];
    if (isLinksRemoved) {
        $.each(data.nodes, function(index, item) {
            if (item.size > 0) {
               dataIndexesCnt.push({'id':  item.itemIndex, 'str': item.atom, 'cnt': 0, 'md5': item.md5});
            }
        });
        $.each(data.links, function(index, item) {
            if ((dataIndexes.indexOf(item.source) >= 0) && (dataIndexes.indexOf(item.target) >= 0)) {
                var indexSource = dataIndexes.indexOf(item.source);
                var indexTarget = dataIndexes.indexOf(item.target);
                for (var i = 0; i < dataIndexesCnt.length; i++) {
                    if (dataIndexesCnt[i].id  == dataIndexes[indexSource] || dataIndexesCnt[i].str  == dataIndexes[indexSource]) {
                        dataIndexesCnt[i].cnt++;
                    }
                    if (dataIndexesCnt[i].id  == dataIndexes[indexTarget] || dataIndexesCnt[i].str  == dataIndexes[indexTarget]) {
                        dataIndexesCnt[i].cnt++;
                    }
                }
            }
        });
        
        dataRes = removeEndNodesAndLinks(dataIndexes, dataIndexesCnt, data);
    }
    var dataRes = data;
    return dataRes;
}


/**
 * Automatically positions top-level nodes by the selected algorithm.
 * Called during the formation and assignment of attributes of each node.
 * @param node
 * @returns
 */
function autoAdjustByType(node) {
    var retObj = {};
    retObj.node = node;
    node.fixed = false;
    var nodeClass = '';
    if (typeof node.userFixed === "undefined" || !node.userFixed) {
        nodeClass = '';
        node.fixed = false;
    } else {
        node.fixed = true;
        nodeClass = ' fixed user-fixed';
        node.x = node.px = node.x * window.molecularData[chartIndex].widthMult;
        node.y = node.py = node.y * window.molecularData[chartIndex].heightMult;
    }

    var maxRadius = window.molecularData[chartIndex].maxRadius * window.molecularData[chartIndex].circleSizeMult;
    
    if (typeof window.molecularData[chartIndex].maxSizeNodeIndex === "undefined") {
        if (node.size == window.molecularData[chartIndex].maxSize) {
            window.molecularData[chartIndex].maxSizeNodeIndex = node.itemIndex;
            //window.molecularData[chartIndex].rootNode = node;
        }
    }
    if (window.molecularData[chartIndex].autoAdjustFirstStart) {
        window.molecularData[chartIndex].AA.nodesLength = 0;
        $.each(window.molecularData[chartIndex].graphData.nodes, function(index, item) {
            if ((item.atom.charAt(0) != '_') && item.size  > 0) {
                window.molecularData[chartIndex].AA.nodesLength++;
            }
        });
    }

    
    /**
     * Predefined nodes position configuration by rectangle perimeter
     */
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 3) {
        if (window.molecularData[chartIndex].autoAdjustFirstStart) {
            if (window.molecularData[chartIndex].dropdownAutoAdjustCnt == 0) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
            } else if (window.molecularData[chartIndex].dropdownAutoAdjustCnt < 200) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].dropdownAutoAdjustCnt;
                if (window.molecularData[chartIndex].AA.nodesCnt > window.molecularData[chartIndex].AA.nodesLength) {
                    window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
                }
            } else {
                window.molecularData[chartIndex].AA.nodesCnt = Math.ceil(
                    window.molecularData[chartIndex].AA.nodesLength
                        * (window.molecularData[chartIndex].dropdownAutoAdjustCnt  - 200) / 100
                );
            }
            window.molecularData[chartIndex].AA.widthLength = 
                window.molecularData[chartIndex].width - maxRadius * 2 - 2;
            window.molecularData[chartIndex].AA.heightLength = 
                window.molecularData[chartIndex].height - maxRadius * 2 - 2;
            window.molecularData[chartIndex].AA.perimeterLength =
                (window.molecularData[chartIndex].AA.widthLength + window.molecularData[chartIndex].AA.heightLength) * 2;
            window.molecularData[chartIndex].AA.nodesInterval =
                Math.round(window.molecularData[chartIndex].AA.perimeterLength / window.molecularData[chartIndex].AA.nodesCnt);
            window.molecularData[chartIndex].AA.startPadding = maxRadius + 1;
            window.molecularData[chartIndex].AA.startSide2 = false;
            window.molecularData[chartIndex].AA.startSide3 = false;
            window.molecularData[chartIndex].AA.startSide4 = false;
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
            window.molecularData[chartIndex].AA.nodesAddCnt = 0;
        }
        if ((node.atom.charAt(0) != '_')) {
            if (window.molecularData[chartIndex].AA.nodesCnt == 4) {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 0) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 1) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.widthLength
                            + window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 2) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.widthLength
                            + window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding
                            + window.molecularData[chartIndex].AA.heightLength;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 3) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding
                        + window.molecularData[chartIndex].AA.heightLength;
                    }
                }
                if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                    if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                        nodeClass = ' fixed user-fixed';
                        node.fixed = true;
                        node.userFixed = 1;
                        node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                            * window.molecularData[chartIndex].widthMult);
                        node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                            * window.molecularData[chartIndex].heightMult);
                        retObj.classStr = nodeClass;
                        retObj.node = node;
                    }
                }
                window.molecularData[chartIndex].AA.nodesAddCnt++;
            } else if (window.molecularData[chartIndex].AA.nodesCnt == 8) {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 0) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 1) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].width / 2;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 2) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.widthLength
                            + window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 3) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.widthLength
                            + window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].height / 2;
                    }
                    
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 4) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.widthLength
                            + window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding
                            + window.molecularData[chartIndex].AA.heightLength;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 5) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].width / 2;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding
                            + window.molecularData[chartIndex].AA.heightLength;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 6) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding
                        + window.molecularData[chartIndex].AA.heightLength;
                    }
                    if (window.molecularData[chartIndex].AA.nodesAddCnt == 7) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].height / 2;
                    }
                }
                if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                    if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                        nodeClass = ' fixed user-fixed';
                        node.fixed = true;
                        node.userFixed = 1;
                        
                        node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                            * window.molecularData[chartIndex].widthMult);
                        node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                            * window.molecularData[chartIndex].heightMult);
                        retObj.classStr = nodeClass;
                        retObj.node = node;
                    }
                }
                window.molecularData[chartIndex].AA.nodesAddCnt++;
            } else {
                if (window.molecularData[chartIndex].AA.nodesAddCnt * window.molecularData[chartIndex].AA.nodesInterval
                        <=
                        window.molecularData[chartIndex].AA.widthLength) {
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding
                            + window.molecularData[chartIndex].AA.nodesAddCnt * window.molecularData[chartIndex].AA.nodesInterval;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding;
                    }
                    if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                        if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            node.fixed = true;
                            node.userFixed = 1;
                            
                            node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                                * window.molecularData[chartIndex].heightMult);
                            retObj.classStr = nodeClass;
                            retObj.node = node;
                            //return retObj;
                        }
                    }
                    window.molecularData[chartIndex].AA.nodesAddCnt++;
                } else if (window.molecularData[chartIndex].AA.nodesAddCnt * window.molecularData[chartIndex].AA.nodesInterval 
                        <=
                        (window.molecularData[chartIndex].AA.widthLength + window.molecularData[chartIndex].AA.heightLength)) {
                    if (!window.molecularData[chartIndex].AA.startSide2) {
                        window.molecularData[chartIndex].AA.startSide2 = true;
                        window.molecularData[chartIndex].AA.paddingSide2 = (window.molecularData[chartIndex].AA.nodesAddCnt)
                            * window.molecularData[chartIndex].AA.nodesInterval
                            - window.molecularData[chartIndex].AA.widthLength;
                        window.molecularData[chartIndex].AA.startIndex2 = window.molecularData[chartIndex].AA.nodesAddCnt;
                    }
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding + window.molecularData[chartIndex].AA.widthLength;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding + window.molecularData[chartIndex].AA.paddingSide2
                            + (window.molecularData[chartIndex].AA.nodesAddCnt - window.molecularData[chartIndex].AA.startIndex2)
                            * window.molecularData[chartIndex].AA.nodesInterval;
                    }
                    if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                        if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            node.fixed = true;
                            node.userFixed = 1;
                            
                            node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                                * window.molecularData[chartIndex].heightMult);
                            retObj.classStr = nodeClass;
                            retObj.node = node;
                            //return retObj;
                        }
                    }
                    window.molecularData[chartIndex].AA.nodesAddCnt++;
                } else if (window.molecularData[chartIndex].AA.nodesAddCnt * window.molecularData[chartIndex].AA.nodesInterval 
                        <=
                        (window.molecularData[chartIndex].AA.widthLength * 2 + window.molecularData[chartIndex].AA.heightLength)) {
                    if (!window.molecularData[chartIndex].AA.startSide3) {
                        window.molecularData[chartIndex].AA.startSide3 = true;
                        window.molecularData[chartIndex].AA.paddingSide3 = (window.molecularData[chartIndex].AA.nodesAddCnt)
                            * window.molecularData[chartIndex].AA.nodesInterval
                            - (window.molecularData[chartIndex].AA.widthLength + window.molecularData[chartIndex].AA.heightLength);
                        window.molecularData[chartIndex].AA.startIndex3 = window.molecularData[chartIndex].AA.nodesAddCnt;
                    }
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.widthLength
                            + window.molecularData[chartIndex].AA.startPadding
                            - window.molecularData[chartIndex].AA.paddingSide3
                            - (window.molecularData[chartIndex].AA.nodesAddCnt - window.molecularData[chartIndex].AA.startIndex3)
                            * window.molecularData[chartIndex].AA.nodesInterval;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding
                            + window.molecularData[chartIndex].AA.heightLength;
                    }
                    if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                        if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            node.fixed = true;
                            node.userFixed = 1;
                            
                            node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                                * window.molecularData[chartIndex].heightMult);
                            retObj.classStr = nodeClass;
                            retObj.node = node;
                        }
                    }
                    window.molecularData[chartIndex].AA.nodesAddCnt++;
                } else if (window.molecularData[chartIndex].AA.nodesAddCnt < window.molecularData[chartIndex].AA.nodesCnt) {
                    if (!window.molecularData[chartIndex].AA.startSide4) {
                        window.molecularData[chartIndex].AA.startSide4 = true;
                        window.molecularData[chartIndex].AA.paddingSide4 = (window.molecularData[chartIndex].AA.nodesAddCnt)
                            * window.molecularData[chartIndex].AA.nodesInterval
                            - (window.molecularData[chartIndex].AA.widthLength * 2 + window.molecularData[chartIndex].AA.heightLength);
                        window.molecularData[chartIndex].AA.startIndex4 = window.molecularData[chartIndex].AA.nodesAddCnt;
                    }
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = window.molecularData[chartIndex].AA.startPadding;
                        node.y = node.py = window.molecularData[chartIndex].AA.startPadding
                            + window.molecularData[chartIndex].AA.heightLength
                            - window.molecularData[chartIndex].AA.paddingSide4 - (window.molecularData[chartIndex].AA.nodesAddCnt
                            - window.molecularData[chartIndex].AA.startIndex4)
                            * window.molecularData[chartIndex].AA.nodesInterval;
                    }
                    window.molecularData[chartIndex].AA.nodesAddCnt++;
                    if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                        if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            node.fixed = true;
                            node.userFixed = 1;
                            
                            node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                                * window.molecularData[chartIndex].heightMult);
                            retObj.classStr = nodeClass;
                            retObj.node = node;
                            //return retObj;
                        }
                    }
                } else {
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = '';
                        node.fixed = false;
                    } else {
                        node.fixed = true;
                        nodeClass = ' fixed user-fixed';
                    }
                }
            }
        }
        
        retObj.classStr = nodeClass;
        retObj.node = node;
    }
    /**
     * Predefined nodes position configuration by circle perimeter
     */
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 4) {
        if (window.molecularData[chartIndex].autoAdjustFirstStart) {
            if (window.molecularData[chartIndex].dropdownAutoAdjustCnt == 0) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
            } else if (window.molecularData[chartIndex].dropdownAutoAdjustCnt < 200) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].dropdownAutoAdjustCnt;
                if (window.molecularData[chartIndex].AA.nodesCnt > window.molecularData[chartIndex].AA.nodesLength) {
                    window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
                }
            } else {
                window.molecularData[chartIndex].AA.nodesCnt = Math.ceil(
                    window.molecularData[chartIndex].AA.nodesLength
                        * (window.molecularData[chartIndex].dropdownAutoAdjustCnt  - 200) / 100
                );
            }
            window.molecularData[chartIndex].AA.nodesInterval = (2 * Math.PI) / window.molecularData[chartIndex].AA.nodesCnt;
            window.molecularData[chartIndex].AA.startPadding = maxRadius / 2 + 10;
            
            if (window.molecularData[chartIndex].width <= window.molecularData[chartIndex].height) {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].width / 2 - 
                    window.molecularData[chartIndex].AA.startPadding;
            } else {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].height / 2 - 
                    window.molecularData[chartIndex].AA.startPadding;
            }
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
            window.molecularData[chartIndex].AA.nodesAddCnt= 0;
        }
        if ((node.atom.charAt(0) != '_')) {
            if (window.molecularData[chartIndex].AA.nodesAddCnt < window.molecularData[chartIndex].AA.nodesCnt) {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    nodeClass = ' fixed';
                    node.fixed = true;
                    node.userFixed = 0;
                    node.x = node.px = Math.cos(window.molecularData[chartIndex].AA.nodesAddCnt
                        * window.molecularData[chartIndex].AA.nodesInterval)
                        * window.molecularData[chartIndex].AA.radius + window.molecularData[chartIndex].width / 2;
                    node.y = node.py = Math.sin(window.molecularData[chartIndex].AA.nodesAddCnt
                        * window.molecularData[chartIndex].AA.nodesInterval)
                        * window.molecularData[chartIndex].AA.radius + window.molecularData[chartIndex].height / 2;
                }
                window.molecularData[chartIndex].AA.nodesAddCnt++;
                if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                    if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                        nodeClass = ' fixed user-fixed';
                        node.fixed = true;
                        node.userFixed = 1;
                        
                        node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                            * window.molecularData[chartIndex].widthMult);
                        node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                            * window.molecularData[chartIndex].heightMult);
                        retObj.classStr = nodeClass;
                        retObj.node = node;
                    }
                }
            } else {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    nodeClass = '';
                    node.fixed = false;
                } else {
                    node.fixed = true;
                    nodeClass = ' fixed user-fixed';
                }
            }
        }
        retObj.classStr = nodeClass;
        retObj.node = node;
    }
    /**
     * Predefined nodes position configuration by various combination:
     * circle at center and one first (biggest node) inside at the center of a circle
     */
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 5) {
        if (window.molecularData[chartIndex].autoAdjustFirstStart) {
            if (window.molecularData[chartIndex].dropdownAutoAdjustCnt == 0) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
            } else if (window.molecularData[chartIndex].dropdownAutoAdjustCnt < 200) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].dropdownAutoAdjustCnt;
                if (window.molecularData[chartIndex].AA.nodesCnt > window.molecularData[chartIndex].AA.nodesLength) {
                    window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
                }
            } else {
                window.molecularData[chartIndex].AA.nodesCnt = Math.ceil(
                    window.molecularData[chartIndex].AA.nodesLength
                        * (window.molecularData[chartIndex].dropdownAutoAdjustCnt  - 200) / 100
                );
            }
            window.molecularData[chartIndex].AA.nodesCnt--;
            
            window.molecularData[chartIndex].AA.nodesInterval = (2 * Math.PI) / window.molecularData[chartIndex].AA.nodesCnt;
            window.molecularData[chartIndex].AA.startPadding = maxRadius / 2 + 10;
            
            if (window.molecularData[chartIndex].width <= window.molecularData[chartIndex].height) {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].width / 2
                    - window.molecularData[chartIndex].AA.startPadding;
            } else {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].height / 2
                    - window.molecularData[chartIndex].AA.startPadding;
            }
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
            window.molecularData[chartIndex].AA.nodesAddCnt = 0;
        }
        
        if ((node.atom.charAt(0) != '_')) {
            if (window.molecularData[chartIndex].AA.nodesAddCnt < (window.molecularData[chartIndex].AA.nodesCnt)) {
                if (typeof window.molecularData[chartIndex].maxSizeNodeIndex === "undefined"
                    ||
                    (node.itemIndex != window.molecularData[chartIndex].maxSizeNodeIndex)) {
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.userFixed = 0;
                        node.x = node.px = Math.cos(window.molecularData[chartIndex].AA.nodesAddCnt
                            * window.molecularData[chartIndex].AA.nodesInterval)
                            * window.molecularData[chartIndex].AA.radius + window.molecularData[chartIndex].width / 2;
                        node.y = node.py = Math.sin(window.molecularData[chartIndex].AA.nodesAddCnt
                            * window.molecularData[chartIndex].AA.nodesInterval)
                            * window.molecularData[chartIndex].AA.radius + window.molecularData[chartIndex].height / 2;
                    }
                    window.molecularData[chartIndex].AA.nodesAddCnt++;
                    if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                        if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            node.fixed = true;
                            node.userFixed = 1;
                            
                            node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                                * window.molecularData[chartIndex].heightMult);
                            retObj.classStr = nodeClass;
                            retObj.node = node;
                        }
                    }
                }
            }
        } else {
            if (typeof node.userFixed === "undefined" || !node.userFixed) {
                nodeClass = '';
                node.fixed = false;
            } else {
                node.fixed = true;
                nodeClass = ' fixed user-fixed';
            }
        }
        if (typeof window.molecularData[chartIndex].maxSizeNodeIndex !== "undefined") {
            if (node.itemIndex == window.molecularData[chartIndex].maxSizeNodeIndex) {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    nodeClass = ' fixed';
                    node.fixed = true;
                    node.userFixed = 0;
                    node.x = node.px = window.molecularData[chartIndex].width / 2;
                    node.y = node.py = window.molecularData[chartIndex].height / 2;
                }
            }
        }
        retObj.classStr = nodeClass;
        retObj.node = node;
    }
    /**
     * Predefined nodes position configuration by various combination:
     * circle at center and one first (biggest node) at the corner (one of four)
     */
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 6) {
        if (window.molecularData[chartIndex].autoAdjustFirstStart) {
            if (window.molecularData[chartIndex].dropdownAutoAdjustCnt == 0) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
            } else if (window.molecularData[chartIndex].dropdownAutoAdjustCnt < 200) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].dropdownAutoAdjustCnt;
                if (window.molecularData[chartIndex].AA.nodesCnt > window.molecularData[chartIndex].AA.nodesLength) {
                    window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
                }
            } else {
                window.molecularData[chartIndex].AA.nodesCnt = Math.ceil(
                    window.molecularData[chartIndex].AA.nodesLength
                        * (window.molecularData[chartIndex].dropdownAutoAdjustCnt  - 200) / 100
                );
            }
            window.molecularData[chartIndex].AA.nodesCnt--;
            
            window.molecularData[chartIndex].AA.nodesInterval = (2 * Math.PI) / window.molecularData[chartIndex].AA.nodesCnt;
            window.molecularData[chartIndex].AA.startPadding = maxRadius / 2 + 10;
            
            if (window.molecularData[chartIndex].width <= window.molecularData[chartIndex].height) {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].width / 2
                    - window.molecularData[chartIndex].AA.startPadding;
            } else {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].height / 2
                    - window.molecularData[chartIndex].AA.startPadding;
            }
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
            window.molecularData[chartIndex].AA.nodesAddCnt = 0;
        }
        
        if ((node.atom.charAt(0) != '_')) {
            if (window.molecularData[chartIndex].AA.nodesAddCnt < (window.molecularData[chartIndex].AA.nodesCnt)) {
                if (typeof window.molecularData[chartIndex].maxSizeNodeIndex === "undefined"
                    ||
                    (node.itemIndex != window.molecularData[chartIndex].maxSizeNodeIndex)) {
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.x = node.px = Math.cos(window.molecularData[chartIndex].AA.nodesAddCnt
                            * window.molecularData[chartIndex].AA.nodesInterval)
                            * window.molecularData[chartIndex].AA.radius + window.molecularData[chartIndex].width / 2;
                        node.y = node.py = Math.sin(window.molecularData[chartIndex].AA.nodesAddCnt
                            * window.molecularData[chartIndex].AA.nodesInterval)
                            * window.molecularData[chartIndex].AA.radius + window.molecularData[chartIndex].height / 2;
                    }
                    window.molecularData[chartIndex].AA.nodesAddCnt++;
                    if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                        if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            node.fixed = true;
                            node.userFixed = 1;
                            
                            node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                                * window.molecularData[chartIndex].heightMult);
                            retObj.classStr = nodeClass;
                            retObj.node = node;
                        }
                    }
                }
            } else {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    nodeClass = '';
                    node.fixed = false;
                } else {
                    node.fixed = true;
                    nodeClass = ' fixed user-fixed';
                }
            }
            if (typeof window.molecularData[chartIndex].maxSizeNodeIndex !== "undefined") {
                if (node.itemIndex == window.molecularData[chartIndex].maxSizeNodeIndex) {
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        nodeClass = ' fixed';
                        node.fixed = true;
                        node.x = node.px = window.molecularData[chartIndex].width - maxRadius - 20;
                        node.y = node.py = maxRadius + 2;
                    }
                }
            }
        }
        retObj.classStr = nodeClass;
        retObj.node = node;
    }
    /**
     * Predefined nodes position configuration by various combination:
     * Сircle in a circle
     */
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 7) {
        if (window.molecularData[chartIndex].autoAdjustFirstStart) {
            if (window.molecularData[chartIndex].dropdownAutoAdjustCnt == 0) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
            } else if (window.molecularData[chartIndex].dropdownAutoAdjustCnt < 200) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].dropdownAutoAdjustCnt;
                if (window.molecularData[chartIndex].AA.nodesCnt > window.molecularData[chartIndex].AA.nodesLength) {
                    window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
                }
            } else {
                window.molecularData[chartIndex].AA.nodesCnt = Math.ceil(
                    window.molecularData[chartIndex].AA.nodesLength
                        * (window.molecularData[chartIndex].dropdownAutoAdjustCnt  - 200) / 100
                );
            }
            window.molecularData[chartIndex].AA.nodesCnt1 = Math.ceil(window.molecularData[chartIndex].AA.nodesCnt / 5 * 3);
            window.molecularData[chartIndex].AA.nodesCnt2 = Math.ceil(window.molecularData[chartIndex].AA.nodesCnt / 5 * 2);
            if ((window.molecularData[chartIndex].AA.nodesCnt1 + window.molecularData[chartIndex].AA.nodesCnt2)
                > window.molecularData[chartIndex].AA.nodesCnt) {
                window.molecularData[chartIndex].AA.nodesCnt2--;
            }
            if ((window.molecularData[chartIndex].AA.nodesCnt1 + window.molecularData[chartIndex].AA.nodesCnt2)
                > window.molecularData[chartIndex].AA.nodesCnt) {
                window.molecularData[chartIndex].AA.nodesCnt1--;
            }
            
            window.molecularData[chartIndex].AA.nodesInterval1 = (2 * Math.PI) / window.molecularData[chartIndex].AA.nodesCnt1;
            if (window.molecularData[chartIndex].AA.nodesCnt2 > 0) {
                window.molecularData[chartIndex].AA.nodesInterval2 = (2 * Math.PI) / window.molecularData[chartIndex].AA.nodesCnt2;
            } else {
                window.molecularData[chartIndex].AA.nodesInterval2 = 0;
            }
            window.molecularData[chartIndex].AA.startPadding = maxRadius / 2 + 10;
            
            if (window.molecularData[chartIndex].width <= window.molecularData[chartIndex].height) {
                window.molecularData[chartIndex].AA.radius1 = window.molecularData[chartIndex].width / 2 - 
                    window.molecularData[chartIndex].AA.startPadding;
            } else {
                window.molecularData[chartIndex].AA.radius1 = window.molecularData[chartIndex].height / 2 - 
                    window.molecularData[chartIndex].AA.startPadding;
            }
            window.molecularData[chartIndex].AA.radius2 = window.molecularData[chartIndex].AA.radius1 / 3 * 2;
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
            window.molecularData[chartIndex].AA.nodesAddCnt1 = 0;
            window.molecularData[chartIndex].AA.nodesAddCnt2 = 0;
        }
        if ((node.atom.charAt(0) != '_')) {
            if (window.molecularData[chartIndex].AA.nodesAddCnt1 < window.molecularData[chartIndex].AA.nodesCnt1) {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    nodeClass = ' fixed';
                    node.fixed = true;
                    node.userFixed = 0;
                    node.x = node.px = Math.cos(window.molecularData[chartIndex].AA.nodesAddCnt1
                        * window.molecularData[chartIndex].AA.nodesInterval1)
                        * window.molecularData[chartIndex].AA.radius1 + window.molecularData[chartIndex].width / 2;
                    node.y = node.py = Math.sin(window.molecularData[chartIndex].AA.nodesAddCnt1
                        * window.molecularData[chartIndex].AA.nodesInterval1)
                        * window.molecularData[chartIndex].AA.radius1 + window.molecularData[chartIndex].height / 2;
                }
                window.molecularData[chartIndex].AA.nodesAddCnt1++;
                if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                    if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                        nodeClass = ' fixed user-fixed';
                        node.fixed = true;
                        node.userFixed = 1;
                        
                        node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                            * window.molecularData[chartIndex].widthMult);
                        node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                            * window.molecularData[chartIndex].heightMult);
                        retObj.classStr = nodeClass;
                        retObj.node = node;
                    }
                }
            } else if (window.molecularData[chartIndex].AA.nodesAddCnt2 < window.molecularData[chartIndex].AA.nodesCnt2) {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    nodeClass = ' fixed';
                    node.fixed = true;
                    node.userFixed = 0;
                    node.x = node.px = Math.cos(window.molecularData[chartIndex].AA.nodesAddCnt2
                        * window.molecularData[chartIndex].AA.nodesInterval2)
                        * window.molecularData[chartIndex].AA.radius2 + window.molecularData[chartIndex].width / 2;
                    node.y = node.py = Math.sin(window.molecularData[chartIndex].AA.nodesAddCnt2
                        * window.molecularData[chartIndex].AA.nodesInterval2)
                        * window.molecularData[chartIndex].AA.radius2 + window.molecularData[chartIndex].height / 2;
                }
                window.molecularData[chartIndex].AA.nodesAddCnt2++;
                if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                    if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                        nodeClass = ' fixed user-fixed';
                        node.fixed = true;
                        node.userFixed = 1;
                        
                        node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                            * window.molecularData[chartIndex].widthMult);
                        node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                            * window.molecularData[chartIndex].heightMult);
                        retObj.classStr = nodeClass;
                        retObj.node = node;
                    }
                }
            } else {
                if (typeof node.userFixed === "undefined" || !node.userFixed) {
                    nodeClass = '';
                    node.fixed = false;
                } else {
                    node.fixed = true;
                    nodeClass = ' fixed user-fixed';
                }
            }
        }
        retObj.classStr = nodeClass;
        retObj.node = node;
    }
    /**
     * Predefined nodes position configuration by various combination:
     * circle at center and one first (biggest node) inside at the center of a circle
     */
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 8) {
        if (window.molecularData[chartIndex].autoAdjustFirstStart) {
            if (window.molecularData[chartIndex].dropdownAutoAdjustCnt == 0) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
            } else if (window.molecularData[chartIndex].dropdownAutoAdjustCnt < 200) {
                window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].dropdownAutoAdjustCnt;
                if (window.molecularData[chartIndex].AA.nodesCnt > window.molecularData[chartIndex].AA.nodesLength) {
                    window.molecularData[chartIndex].AA.nodesCnt = window.molecularData[chartIndex].AA.nodesLength;
                }
            } else {
                window.molecularData[chartIndex].AA.nodesCnt = Math.ceil(
                    window.molecularData[chartIndex].AA.nodesLength
                        * (window.molecularData[chartIndex].dropdownAutoAdjustCnt  - 200) / 100
                );
            }
            window.molecularData[chartIndex].AA.nodesCnt--;
            
            window.molecularData[chartIndex].AA.nodesInterval = (2 * Math.PI) / window.molecularData[chartIndex].AA.nodesCnt;
            window.molecularData[chartIndex].AA.startPadding = maxRadius / 2 + 10;
            
            if (window.molecularData[chartIndex].width <= window.molecularData[chartIndex].height) {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].width / 2
                    - window.molecularData[chartIndex].AA.startPadding;
            } else {
                window.molecularData[chartIndex].AA.radius = window.molecularData[chartIndex].height / 2
                    - window.molecularData[chartIndex].AA.startPadding;
            }
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
            window.molecularData[chartIndex].AA.nodesAddCnt = 0;
        }
        
        if ((node.atom.charAt(0) != '_')) {
            if (window.molecularData[chartIndex].AA.nodesAddCnt < (window.molecularData[chartIndex].AA.nodesCnt)) {
                if (typeof window.molecularData[chartIndex].maxSizeNodeIndex === "undefined"
                    ||
                    (node.itemIndex != window.molecularData[chartIndex].maxSizeNodeIndex)) {
                    if (typeof node.userFixed === "undefined" || !node.userFixed) {
                        node.userFixed = 0;
                    }
                    window.molecularData[chartIndex].AA.nodesAddCnt++;
                    if (Object.keys(window.molecularData[chartIndex].fixedNodesArr).length > 0) {
                        if (typeof window.molecularData[chartIndex].fixedNodesArr[node.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            node.fixed = true;
                            node.userFixed = 1;
                            
                            node.x = node.px = (window.molecularData[chartIndex].fixedNodesArr[node.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            node.y = node.py = (window.molecularData[chartIndex].fixedNodesArr[node.atom].y
                                * window.molecularData[chartIndex].heightMult);
                            retObj.classStr = nodeClass;
                            retObj.node = node;
                        }
                    }
               }
            }
        } else {
            if (typeof node.userFixed === "undefined" || !node.userFixed) {
                nodeClass = '';
                node.fixed = false;
            } else {
                node.fixed = true;
                nodeClass = ' fixed user-fixed';
            }
        }
        if (typeof window.molecularData[chartIndex].maxSizeNodeIndex !== "undefined") {
            if (node.itemIndex == window.molecularData[chartIndex].maxSizeNodeIndex) {
                nodeClass = ' fixed';
                node.fixed = true;
                node.userFixed = 0;
                node.x = node.px = window.molecularData[chartIndex].width / 2;
                node.y = node.py = window.molecularData[chartIndex].height / 2;
            }
        }
        retObj.classStr = nodeClass;
        retObj.node = node;
    }
    
    retObj.classStr = nodeClass;
    retObj.node = node;
    return retObj;
}


/**
* Helper callback method for the showChart() function.
* @return void
*/
function tick(e) {
    if (isDebugg) {console.log('18');}
    if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
        var fixedNodesArrObj = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
    } else {
        var fixedNodesArrObj = window.newValuesFormTab1.fixedNodesArr;
    }
    if (window.molecularData[chartIndex].autoAdjustFirstStart) {
        window.molecularData[chartIndex].tickLimit = Math.ceil(10000 / window.molecularData[chartIndex].node[0].length);
        if (window.molecularData[chartIndex].tickLimit > 200) {
            window.molecularData[chartIndex].tickLimit = 200;
        }
        if (window.molecularData[chartIndex].tickLimit < 20) {
            window.molecularData[chartIndex].tickLimit = 20;
        }
        if (typeof window.molecularData[chartIndex].nodesCoordIsNaN === 'undefined'
            || window.molecularData[chartIndex].nodesCoordIsNaN == false) {
            window.molecularData[chartIndex].nodesCoordIsNaN = (!window.molecularData[chartIndex].node.each(function( index, value) {
                if (isNaN(value.x)) {
                    return false;
                } 
            })) ? false : true;
            if (window.molecularData[chartIndex].nodesCoordIsNaN == false) {
                setTimeout(function() {
                    window.molecularData[chartIndex].nodesCoordIsNaN = (!window.molecularData[chartIndex].node.each(
                        function( index, value) {
                            if (isNaN(value.x)) {
                                return false;
                            } 
                        }
                    )) ? false : true;
                    if (window.molecularData[chartIndex].nodesCoordIsNaN == false) {
                        setTimeout(function() {
                            window.molecularData[chartIndex].nodesCoordIsNaN = (!window.molecularData[chartIndex].node.each(
                                function( index, value) {
                                    if (isNaN(value.x)) {
                                         return false;
                                    } 
                                }
                            )) ? false : true;
                        }, 1000);
                    }
                }, 1000);
            }
        }
    } else {
        if (typeof window.molecularData[chartIndex].tickCnt === "undefined") {
            window.molecularData[chartIndex].tickCnt = 0;
        } else {
            window.molecularData[chartIndex].tickCnt++;
        }
        if (typeof window.molecularData[chartIndex].tickLimit === "undefined") {
            window.molecularData[chartIndex].tickLimit = Math.ceil(5000 / window.molecularData[chartIndex].node[0].length);
            if (window.molecularData[chartIndex].tickLimit > 200) {
                window.molecularData[chartIndex].tickLimit = 200;
            }
            if (window.molecularData[chartIndex].tickLimit < 20) {
                window.molecularData[chartIndex].tickLimit = 20;
            }
        }
    }
    if (startTickfixedNodesFlag == true) {
        setTimeout(function () {
            if (typeof window.molecularData[chartIndex].node !== "undefined") {
            window.molecularData[chartIndex].node.each(collide(0.01))
            .attr("transform", function (d) {
                if (!isNaN(d.x)) {
                    if (Object.keys(fixedNodesArrObj).length > 0) {
                        if (typeof fixedNodesArrObj[d.atom] !== "undefined") {
                            nodeClass = 'node fixed user-fixed';
                            $('g[name=' + d.md5 + ']').attr('class', nodeClass);
                            d.fixed = true;
                            d.userFixed = 1;
                            d.x = d.px = (fixedNodesArrObj[d.atom].x
                                    * window.molecularData[chartIndex].widthMult);
                            d.y = d.py = (fixedNodesArrObj[d.atom].y
                                    * window.molecularData[chartIndex].heightMult);
                        }
                    }
                    var k = "translate(" + d.x + "," + d.y + ")";
                    return k;
                } else {
                    return false;
                }
            });
            }
            startTickfixedNodesFlag = true;
        }, 1000);
        startTickfixedNodesFlag = false;
    }

    if ((window.molecularData[chartIndex].dropdownAutoAdjust > 2) && (window.molecularData[chartIndex].dropdownAutoAdjust != 8))  {
        if (typeof window.molecularData[chartIndex].tickCnt !== "undefined" && window.molecularData[chartIndex].tickCnt 
            > window.molecularData[chartIndex].tickLimit) {
            window.molecularData[chartIndex].force.stop();
            if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
                var fixedNodesArrObj = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
            } else {
                var fixedNodesArrObj = window.newValuesFormTab1.fixedNodesArr;
            }
            if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
                var fixedNodesArrObj = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
            } else {
                var fixedNodesArrObj = window.newValuesFormTab1.fixedNodesArr;
            }
            window.molecularData[chartIndex].node.each(collide(0.01))
            .attr("transform", function (d) {
                if (!isNaN(d.x)) {
                    if (Object.keys(fixedNodesArrObj).length > 0) {
                        if (typeof fixedNodesArrObj[d.atom] !== "undefined") {
                            nodeClass = ' fixed user-fixed';
                            $('g[name=' + d.md5 + ']').attr('class', nodeClass);
                            d.fixed = true;
                            d.userFixed = 1;
                            d.x = d.px = (fixedNodesArrObj[d.atom].x
                                * window.molecularData[chartIndex].widthMult);
                            d.y = d.py = (fixedNodesArrObj[d.atom].y
                                * window.molecularData[chartIndex].heightMult);
                        }
                    }
                    var k = "translate(" + d.x + "," + d.y + ")";
                    return k;
                } else {
                    return false;
                }
            });
        }
    }
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 2) {
        if (typeof window.molecularData[chartIndex].tickCnt === "undefined") {
            window.molecularData[chartIndex].tickCnt = 0;
        } else {
            window.molecularData[chartIndex].tickCnt++;
        }
        if (typeof window.molecularData[chartIndex].tickCnt !== "undefined") {
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
        }
        if (typeof window.molecularData[chartIndex].node === 'undefined') {
        } else if (window.molecularData[chartIndex].nodesCoordIsNaN == true) {
            if (typeof window.molecularData[chartIndex].tickCnt !== "undefined" && window.molecularData[chartIndex].tickCnt 
                > window.molecularData[chartIndex].tickLimit) {
                window.molecularData[chartIndex].force.stop();
                if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
                    var fixedNodesArrObj = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
                } else {
                    var fixedNodesArrObj = window.newValuesFormTab1.fixedNodesArr;
                }
                window.molecularData[chartIndex].node.each(collide(0.01))
                .attr("transform", function (d) {
                    if (!isNaN(d.x)) {
                        if (Object.keys(fixedNodesArrObj).length > 0) {
                            if (typeof fixedNodesArrObj[d.atom] !== "undefined") {
                                nodeClass = ' fixed user-fixed';
                                $('g[name=' + d.md5 + ']').attr('class', nodeClass);
                                d.fixed = true;
                                d.userFixed = 1;
                                d.x = d.px = (fixedNodesArrObj[d.atom].x
                                    * window.molecularData[chartIndex].widthMult);
                                d.y = d.py = (fixedNodesArrObj[d.atom].y
                                    * window.molecularData[chartIndex].heightMult);
                            }
                        }
                        var k = "translate(" + d.x + "," + d.y + ")";
                        return k;
                    } else {
                        return false;
                    }
                });
            }
            window.molecularData[chartIndex].node.each(collide(0.01))
            .attr("transform", function (d) {
                if (!isNaN(d.x)) {
                    if (typeof window.molecularData[chartIndex].maxSizeNodeIndex !== "undefined") {
                        if (d.itemIndex == window.molecularData[chartIndex].maxSizeNodeIndex) {
                            if (typeof d.userFixed === "undefined" || !d.userFixed) {
                                if (window.molecularData[chartIndex].tickCnt < (window.molecularData[chartIndex].tickLimit / 2)) {
                                    nodeClass = ' fixed';
                                    d.fixed = true;
                                    d.userFixed = 0;
                                    d.x = d.px = window.molecularData[chartIndex].width / 2;
                                    d.y = d.py = window.molecularData[chartIndex].height / 2;
                                }
                            }
                        }
                    }
                    if (window.molecularData[chartIndex].autoAdjustFirstStart) {
                        d.x = d.px = d.px = window.molecularData[chartIndex].width / 2;
                        d.y = d.py = window.molecularData[chartIndex].height / 2;
                    }
                    var k = "translate(" + d.x + "," + d.y + ")";
                    return k;
                } else {
                    return false;
                }
            });
        }
    }
    
    if (window.molecularData[chartIndex].dropdownAutoAdjust == 8) {
        if (typeof window.molecularData[chartIndex].tickCnt !== "undefined") {
            window.molecularData[chartIndex].autoAdjustFirstStart = false;
        }
        if (typeof window.molecularData[chartIndex].node === 'undefined') {
        } else {
            window.molecularData[chartIndex].node.each(collideCloud(0.01))
            .attr("transform", function (d) {
                if (!isNaN(d.x)) {
                    if (typeof window.molecularData[chartIndex].maxSizeNodeIndex !== "undefined") {
                        if (d.itemIndex == window.molecularData[chartIndex].maxSizeNodeIndex) {
                            if (typeof d.userFixed === "undefined" || !d.userFixed) {
                                nodeClass = ' fixed';
                                d.fixed = true;
                                d.userFixed = 0;
                                d.x = d.px = window.molecularData[chartIndex].width / 2;
                                d.y = d.py = window.molecularData[chartIndex].height / 2;
                            }
                        }
                    }
                    var k = "translate(" + d.x + "," + d.y + ")";
                    return k;
                } else {
                    return false;
                }
            });
        }
    }

    window.molecularData[chartIndex].node.attr("cx", function(d) {
        if (!isNaN(d.x)) {
            window.molecularData[chartIndex].node.attr(
                "cx",
                function(d) {
                    if (window.molecularData[chartIndex].dropdownAutoAdjust == 8) {
                        newCoords = getInEllipsePoint(d);
                    }
                    return d.x = window.molecularData[chartIndex].dropdownAutoAdjust == 8 ? newCoords.x : Math.max(
                        window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult + 2, 
                        Math.min(window.molecularData[chartIndex].width - window.molecularData[chartIndex].radius(d.size)
                            * window.molecularData[chartIndex].circleSizeMult - 2, d.x)
                    );
                }
            )
            .attr(
                "cy",
                function(d) {
                    if (window.molecularData[chartIndex].dropdownAutoAdjust == 8) {
                        newCoords = getInEllipsePoint(d);
                    }
                    return d.y = window.molecularData[chartIndex].dropdownAutoAdjust == 8 ? newCoords.y : Math.max(
                        window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult + 2,
                        Math.min(window.molecularData[chartIndex].height - window.molecularData[chartIndex].radius(d.size)
                            * window.molecularData[chartIndex].circleSizeMult - 2, d.y)
                    );
                }
            );
        }
    });
    try {
        window.molecularData[chartIndex].link.selectAll("line")
            .filter(function(d,i){
                if ((d.target.atom === '') || (d.source.atom === '')) {
                    return false;
                } else {
                    return d;
                }
            })
            .attr("x1", function(d) {if (typeof d.source !== 'undefined') {return d.source.x;}})
            .attr("y1", function(d) {if (typeof d.source !== 'undefined') {return d.source.y;}})
            .attr("x2", function(d) {if (typeof d.target !== 'undefined') {return d.target.x;}})
            .attr("y2", function(d) {if (typeof d.target !== 'undefined') {return d.target.y;}});
    } catch (error) {
        var er = error;
    }
    
    if (typeof window.molecularData[chartIndex].node === 'undefined') {
    } else if (window.molecularData[chartIndex].nodesCoordIsNaN == true) {
        window.molecularData[chartIndex].node.attr("transform", function(d) {
            if (!isNaN(d.x)) {
                var k = "translate(" + d.x + "," + d.y + ")";
                return k;
            } else {
                return false;
            }
        });
    } else {
        window.molecularData[chartIndex].node.attr("transform", function(d) {
            if (!isNaN(d.y)) {
                var k = "translate(" + d.x + "," + d.y + ")";
                return k;
            } else {
                return false;
            }
        });
    }
}


/**
 * Resolves collisions between d and all other circles.
 * @param alpha
 * @returns
 */
function collide(alpha) {
    if (isDebugg) {console.log('22');}
    var q = d3.geom.quadtree(window.molecularData[chartIndex].graphData.nodes);
    return function (d) {
        if ((d.atom.charAt(0) != '_')) {
            var r = window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult
                + window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxRadius) 
                * window.molecularData[chartIndex].circleSizeMult,
            nx1 = d.x - r,
            nx2 = d.x + r,
            ny1 = d.y - r,
            ny2 = d.y + r;
            q.visit(function (quad, x1, y1, x2, y2) {
                if (quad.point && (quad.point !== d) && d.other !== quad.point && d !== quad.point.other) {
                    var x = d.x - quad.point.x,
                        y = d.y - quad.point.y,
                        l = Math.sqrt(x * x + y * y),
                        r = window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult
                        + window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxRadius) 
                        * window.molecularData[chartIndex].circleSizeMult;
                    if (l < r) {
                        l = (l - r) / l * alpha;
                        var ttt = '';
                        if (isNaN(d.x) || isNaN(d.y) 
                            ||
                            (typeof window.molecularData[chartIndex].tickCnt
                            !== "undefined" && window.molecularData[chartIndex].tickCnt < 10)
                            ) {
                            if (d.itemIndex % 4 == 0) {
                                //+
                                l = (l < 0) ? l * -1 : l * 1;
                                x = (x < 0) ? x * -1 : x * 1;
                                y = (y < 0) ? y * -1 : y * 1;
                                d.x += x *= l;
                                d.y += y *= l;
                                quad.point.x -= x;
                                quad.point.y -= y;
                                if (d.x < 0 || d.x > window.molecularData[chartIndex].width || isNaN(d.x)) {
                                    d.x = window.molecularData[chartIndex].width / 2 + window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.x = d.x;
                                }
                                if (d.y < 0 || d.y > window.molecularData[chartIndex].height || isNaN(d.y)) {
                                    d.y = window.molecularData[chartIndex].height / 2 + window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.y = d.y;
                                }
                                ttt =' 4 (x+,y+) ';
                            } else if (d.itemIndex % 3 == 0) {
                                //+-
                                l = (l < 0) ? l * -1 : l * 1;
                                x = (x < 0) ? x * -1 : x * 1;
                                y = (y < 0) ? y * -1 : y * 1;
                                d.x += x *= l;
                                d.y -= y *= l;
                                quad.point.x -= x;
                                quad.point.y += y;
                                if (d.x < 0 || d.x > window.molecularData[chartIndex].width || isNaN(d.x)) {
                                    d.x = window.molecularData[chartIndex].width / 2 + window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.x = d.x;
                                }
                                if (d.y < 0 || d.y > window.molecularData[chartIndex].height || isNaN(d.y)) {
                                    d.y = window.molecularData[chartIndex].height / 2 - window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.y = d.y;
                                }
                                ttt =' 3 (x+,y-) ';
                            } else if (d.itemIndex % 2 == 0) {
                                //-+
                                l = (l < 0) ? l * -1 : l * 1;
                                x = (x < 0) ? x * -1 : x * 1;
                                y = (y < 0) ? y * -1 : y * 1;
                                d.x -= x *= l;
                                d.y += y *= l;
                                quad.point.x += x;
                                quad.point.y -= y;
                                if (d.x < 0 || d.x > window.molecularData[chartIndex].width || isNaN(d.x)) {
                                    d.x = window.molecularData[chartIndex].width / 2 - window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.x = d.x;
                                }
                                if (d.y < 0 || d.y > window.molecularData[chartIndex].height || isNaN(d.y)) {
                                    d.y = window.molecularData[chartIndex].height / 2 + window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.y = d.y;
                                }
                                ttt =' 2 (x-,y+) ';
                            } else {
                                //-
                                l = (l < 0) ? l * -1 : l * 1;
                                x = (x < 0) ? x * -1 : x * 1;
                                y = (y < 0) ? y * -1 : y * 1;
                                d.x -= x *= l;
                                d.y -= y *= l;
                                quad.point.x -= x;
                                quad.point.y -= y;
                                if (d.x < 0 || d.x > window.molecularData[chartIndex].width || isNaN(d.x)) {
                                    d.x = window.molecularData[chartIndex].width / 2 - window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.x = d.x;
                                }
                                if (d.y < 0 || d.y > window.molecularData[chartIndex].height || isNaN(d.y)) {
                                    d.y = window.molecularData[chartIndex].height / 2 - window.molecularData[chartIndex].maxRadius
                                        * window.molecularData[chartIndex].circleSizeMult;
                                    quad.point.y = d.y;
                                }
                                ttt =' 1 (x-,y-) ';
                            }
                        }  else {
                            d.x -= x *= l;
                            d.y -= y *= l;
                            quad.point.x += x;
                            quad.point.y += y;
                        }
                    }
                }
                return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
            });
        } else {
            return true;
        }
    };
}


/**
 * 
 * @param node
 * @returns
 */
function getInEllipsePoint(node) {   // render an ellipse
    var padding = window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxRadius)
        * window.molecularData[chartIndex].circleSizeMult;
    var pointInEllipse = checkpointInEllipse(
            window.molecularData[chartIndex].width / 2,
            window.molecularData[chartIndex].height / 2,
            node.x,
            node.y,
            window.molecularData[chartIndex].width / 2 - padding,
            window.molecularData[chartIndex].height / 2 - padding
    );
    if (1.06 > pointInEllipse) {
        return {'x': node.x, 'y': node.y};
    } else {
        var newX = node.x, 
            newY = node.y,
            rx = window.molecularData[chartIndex].width / 2 - padding,
            ry = window.molecularData[chartIndex].height / 2 - padding,
            mult = (rx/ry) * (rx/ry),
            cx = window.molecularData[chartIndex].width / 2,
            cy = window.molecularData[chartIndex].height / 2;
        newX = ((node.x-cx) * rx) / Math.sqrt((node.x-cx)*(node.x-cx) + mult*(node.y-cy)*(node.y-cy))+cx;
        newX *= newX < 0 ? -1 : 1;
        newY = ((node.y-cy) * rx) / Math.sqrt((node.x-cx)*(node.x-cx) + mult*(node.y-cy)*(node.y-cy))+cy;
        newY *= newY < 0 ? -1 : 1;
        return {'x': newX, 'y': newY};
    }
}

/**
 * 
 * @param alpha
 * @returns
 */
function collideCloud(alpha) {
    if (isDebugg) {console.log('22');}
    var q = d3.geom.quadtree(window.molecularData[chartIndex].graphData.nodes);
    return function (d) {
        if (isNaN(d.x) || isNaN(d.y)) {
            return false;
        }
        var r = window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult
            + window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxRadius) 
            * window.molecularData[chartIndex].circleSizeMult,
        nx1 = d.x - r,
        nx2 = d.x + r,
        ny1 = d.y - r,
        ny2 = d.y + r;
        q.visit(function (quad, x1, y1, x2, y2) {
            if (quad.point && (quad.point !== d) && d.other !== quad.point && d !== quad.point.other) {
                if ((typeof window.molecularData[chartIndex].maxSizeNodeIndex !== "undefined") 
                        && (d.itemIndex == window.molecularData[chartIndex].maxSizeNodeIndex)) {
                    var rAdd = 20;
                } else {
                    var rAdd = 0;
                }

                var x = d.x - quad.point.x,
                    y = d.y - quad.point.y,
                    l = Math.sqrt(x * x + y * y),
                    r = window.molecularData[chartIndex].radius(d.size) * window.molecularData[chartIndex].circleSizeMult
                        + window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxRadius) 
                        * window.molecularData[chartIndex].circleSizeMult + rAdd;
                if (l < r) {
                    l = (l - r) / l * 0.5;
                    if ((typeof window.molecularData[chartIndex].maxSizeNodeIndex !== "undefined") 
                        && (d.itemIndex == window.molecularData[chartIndex].maxSizeNodeIndex)) {
                        x *= l;
                        y *= l;
                        quad.point.x += x;
                        quad.point.y += y;
                        return true;
                    } else {
                        d.x -= x *= l;
                        d.y -= y *= l;
                        quad.point.x += x;
                        quad.point.y += y;
                        var isInCircle = pointInCircle(
                            d.x,
                            d.y,
                            window.molecularData[chartIndex].width / 2,
                            window.molecularData[chartIndex].height / 2,
                            window.molecularData[chartIndex].radius(window.molecularData[chartIndex].maxRadius)
                                * window.molecularData[chartIndex].circleSizeMult + window.molecularData[chartIndex].radius(d.size)
                                * window.molecularData[chartIndex].circleSizeMult + 100,
                            d.itemIndex
                        );
                        var isInEllipse = 1 > checkpointInEllipse(
                            window.molecularData[chartIndex].width / 2,
                            window.molecularData[chartIndex].height / 2,
                            d.x,
                            d.y,
                            window.molecularData[chartIndex].width / 2,
                            window.molecularData[chartIndex].height / 2
                        );
                        return (isInCircle == true) && (isInEllipse == true);
                    }
                }
            }
            return false;
            //return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
        });
    };
}

// x,y is the point to test
// cx, cy is circle center, and radius is circle radius
function pointInCircle(x, y, cx, cy, radius, itemIndex) {
    var distancesquared = (x - cx) * (x - cx) + (y - cy) * (y - cy);

    return distancesquared >= (radius * radius);
}

/**
 * 
 * @param cx - x coordinate of ellipse center
 * @param cy - y coordinate of ellipse center
 * @param x - x coordinate of the point to test
 * @param y - y coordinate of the point to test
 * @param rx - x semi axis
 * @param ry - y semi axis
 * @returns
 */
function checkpointInEllipse(cx, cy, x, y, rx, ry) { 
    // checking the equation of 
    // ellipse with the given point 
    var p = ((x - cx) * (x - cx)) / (rx * rx) + ((y - cy) * (y - cy)) / (ry * ry); 
  
    return p; 
} 

/**
 * A left-right bias causing topics to orient by party preference.
 * @param alpha
 * @returns
 */
function bias(alpha) {
    if (isDebugg) {console.log('19');}
     return function(d) {
        d.x += d.bias * alpha * 2;
    };
}
