var startDate;
window.timeSeriesDataInit = {
    data: undefined,
    width: undefined,
    height: undefined,
    json: undefined,
    cluster: undefined,
    diagonal: undefined,
    svg: undefined,
    nodes: undefined,
    link: undefined,
    node: undefined,
    isConnectorsLines: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    stopFlag: false,
    wordsListReset: true,
    wordsListColorChange: false,
    unicItems: {},
    unicDates: {},
    allDataKeys: {},
    timeFormatter: undefined,
    margin: undefined,
    x: undefined,
    y: undefined,
    chartAreaWidth: undefined,
    chartAreaHeight: undefined,
    zoom: undefined,
    colorsArr: undefined,
    colorsDrawArr: undefined,
    dashStyles: undefined,
    sourceTitle: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    dashStyles: [
        'Solid',
        'ShortDash',
        'ShortDot',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'
    ],
    markerSymbols: [
        'circle',
        'square',
        'diamond',
        'triangle',
        'triangle-down'
    ],
    unicLine: [],
    addData: undefined,
    seriesData: [],
    seriesDates: [],
    plotBands: [],
    circleSizeMult: 1,
    lineWidthMult: 1,
    customWordsColors: {},
    changedColors: {}
};
window.timeSeriesData = {};
window.timeSeriesData[0] = window.timeSeriesDataInit;
var isLegend = true;
var isTitle = true;
var isChartMenu = false;
var connectNulls = false;
var sourceDataPoints = false;
var dataArrLength;
var htmlContent = '';

if (typeof chartIndex === "undefined") {
    var chartIndex = 0;
}
var chartHC;
var valueDateDif = new Date('1/1/1970 00:00:00');

function drawTimeSeries(dataChart, formdata) {
    console.time('line');

    initGetColors(CHART_TYPE_TIMESERIES, chartIndex);
    var data = dataChart.result;
    var md5Arr = dataChart.md5Arr;
    var wordsList = dataChart.wordsList;
    var addData = dataChart.addData;
    var wordsListArr = dataChart.wordsList;

    var xLabelsDateFormat = '{value:%Y-%m-%d}';
    window.colorsArrayUsed = [];
    window.colorsArrayUsedMinIndex = [];
    isLegend = formdata.checkboxShowHideLegendValue;
    isTitle = formdata.checkboxShowHideTitleValue;
    isChartMenu = formdata.checkboxShowHideMenuValue;

    //prepare data for the metadata dialog
    /*window.newValuesFormTab1.chartType = 22;
    window.newValuesFormTab1.metadataWord = '';
    window.newValuesFormTab1.metadataValue = '';
    window.newValuesFormTab1.metadataDate = {};
    if (typeof window.newValuesFormTab1.textBoxValueDatesRange !== "undefined") {
        var datesArr = window.newValuesFormTab1.textBoxValueDatesRange.split(' - ');
        var dateStartArr = datesArr[0].split('/');
        //var setDayStart = new Date(dateStartArr[2], dateStartArr[1]-1, dateStartArr[0]);
        window.newValuesFormTab1.metadataDate.year = dateStartArr[2];
        window.newValuesFormTab1.metadataDate.month = dateStartArr[1];
        window.newValuesFormTab1.metadataDate.day = dateStartArr[0];
    }
    */
    if (typeof formdata.checkboxShowHideSeriesLabelValue !== "undefined") {
        var isSeriesLabel = formdata.checkboxShowHideSeriesLabelValue;
    } else {
        var isSeriesLabel = true;
    }
    if (typeof formdata.checkboxAxisTurnOnOffValue !== "undefined") {
        var axisTurnOnOffValue = formdata.checkboxAxisTurnOnOffValue;
    } else {
        var axisTurnOnOffValue = true;
    }
    if (typeof formdata.dropdownValueVIndicator !== "undefined") {
        var valueVIndicator = getValueIndicatorTextById(formdata.dropdownValueVIndicator);
    } else {
        var valueVIndicator = getValueIndicatorTextById(valueDefault);
    }

    if (typeof formdata.checkboxConnectNullsValue !== "undefined") {
        var connectorLinesAnalisys = formdata.checkboxConnectNullsValue;
    } else {
        var connectorLinesAnalisys = 1;
    }
    if (connectorLinesAnalisys == 2) {
        connectNulls = true;
    } else {
        connectNulls = false;
    }
    var connectorLinesSource = formdata.dropdownConnectorLinesSourceValue;
    if (typeof formdata.dropdownConnectorLinesSourceValue !== "undefined") {
        var connectorLinesSource = formdata.dropdownConnectorLinesSourceValue;
    } else {
        var connectorLinesSource = 0;
    }
    if (connectorLinesSource == 2) {
        connectNullsSource = true;
    } else {
        connectNullsSource = false;
    }
    sourceDataPoints = formdata.checkboxSourceDataPointsValue;
    if (typeof chartIndex === "undefined") {
        var chartIndex = 0;
    }

    if (Object.keys(window.timeSeriesData[chartIndex].wordsList).length == 0) {
        window.timeSeriesData[chartIndex].wordsListReset = true;
    }
    if (Object.keys(window.timeSeriesData[chartIndex].wordsList).length != wordsListArr.length) {
        window.timeSeriesData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    if (window.timeSeriesData[chartIndex].wordsListReset == true
        || typeof window.timeSeriesData[chartIndex].colorsArr === "undefined"
        || window.timeSeriesData[chartIndex].colorsArr.length == 0) {
        window.timeSeriesData[chartIndex].colorsArr = [];
        window.timeSeriesData[chartIndex].colorsDrawArr = [];
    }
    if (window.timeSeriesData[chartIndex].wordsListReset == true) {
        window.timeSeriesData[chartIndex].wordsListFirst = {};
        window.timeSeriesData[chartIndex].wordsList = {};
    }
    if (typeof formdata.wordsList !== "undefined") {
        window.timeSeriesData[chartIndex].wordsListSelected = formdata.wordsList;
    }
    //console.log('wordsList', window.timeSeriesData[chartIndex].wordsList);
    curDataChartTS = dataChart;
    if (typeof addData !== "undefined") {
        addData = JSON.parse(addData);
        window.timeSeriesData[chartIndex].addData = addData;
    } else {
        addData = window.timeSeriesData[chartIndex].addData;
        if (typeof addData == "undefined") {
            addData = {};
        }
    }

    $('#chartTimeSeriesContaner .chart svg').remove();
    $('#chartTimeSeriesContaner .legend').html('');

    window.timeSeriesData[chartIndex].margin = { top: 20, right: 20, bottom: 10, left: 50 };
    window.timeSeriesData[chartIndex].width =
        $('#dataContaner').parent().width() - window.timeSeriesData[chartIndex].margin.left
        - window.timeSeriesData[chartIndex].margin.right - 220;
    if (isWidget == 0) {
        window.timeSeriesData[chartIndex].height =
            $('#dataContaner').parent().height() - window.timeSeriesData[chartIndex].margin.top
            - window.timeSeriesData[chartIndex].margin.bottom;
    } else {
        window.timeSeriesData[chartIndex].height = $('#dataContaner').parent().height();
    }
    var circleSizeMult = 1;
    var enableMarker = true;
    if (typeof formdata.circleSizeMult !== "undefined") {
        if (formdata.circleSizeMult == 0) {
            formdata.circleSizeMult = 0;
            enableMarker = false;
        }
        circleSizeMult = formdata.circleSizeMult;
        window.timeSeriesData[chartIndex].circleSizeMult = formdata.circleSizeMult;
    }
    var sourcePointsSizeMult = 1;
    var enableMarker = true;
    if (typeof formdata.sourcePointsSizeMult !== "undefined") {
        if (formdata.sourcePointsSizeMult == 0) {
            formdata.sourcePointsSizeMult = 0;
            //enableMarker = false;
        }
        sourcePointsSizeMult = formdata.sourcePointsSizeMult;
        window.timeSeriesData[chartIndex].sourcePointsSizeMult = formdata.sourcePointsSizeMult;
    }
    var lineWidthMult = 1;
    var enableMarker = true;
    if (typeof formdata.lineWidthMult !== "undefined") {
        if (formdata.lineWidthMult == 0) {
            formdata.lineWidthMult = 0;
        }
        lineWidthMult = formdata.lineWidthMult;
        window.timeSeriesData[chartIndex].lineWidthMult = formdata.lineWidthMult;
    }
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.timeSeriesData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.timeSeriesData[chartIndex].dropdownColorsSelection = 1;
    }

    if (firstLoadChart == 1) {
        wordsListArr.shift();
    }
    //console.log(wordsListArr, data);
    wordsListArr = wordsListArr.sort();
    //console.log(wordsListArr);
    var wordsListObj = [];
    for (var i = 0; i < wordsListArr.length; i++) {
        wordsListObj.push({ 'name': wordsListArr[i], 'md5': CryptoJS.MD5(wordsListArr[i]).toString(),  })
    }

    if (typeof formdata.dropdownColorsSet === "undefined") {
        formdata.dropdownColorsSet = 0;
    }
    window.timeSeriesData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;

    //console.log('wordsListArr.length = ', wordsListArr.length, 'formdata.dropdownColorsSet = ', formdata.dropdownColorsSet);
    if (formdata.dropdownColorsSet == 0) {
        //console.log('formdata.dropdownColorsSet = ', formdata.dropdownColorsSet);
        if (wordsListArr.length <= 8) {
            window.colorsArraySetIndex = 6;
        } else if (wordsListArr.length <= 16) {
            window.colorsArraySetIndex = 7;
        } else if (wordsListArr.length <= 24) {
            window.colorsArraySetIndex = 8;
        } else if (wordsListArr.length <= 32) {
            window.colorsArraySetIndex = 9;
        } else {
            window.colorsArraySetIndex = 1;
        }
        if (window.timeSeriesData[chartIndex].dropdownColorsSelection == 1) {
            var wordsListColorsAuto = getAutoUnicColorsForWordsList(wordsListArr, CHART_TYPE_TIMESERIES, chartIndex, undefined, undefined, undefined);
        }
    } else {
        window.timeSeriesData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    }

    var chartTimeSeriesType = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartView').val();
    //console.log('wordsListObj', wordsListObj, window.timeSeriesData[chartIndex].wordsListReset, window.timeSeriesData[chartIndex].colorsArr);

    if (typeof wordsListObj !== "undefined") {
        if ((window.timeSeriesData[chartIndex].wordsListReset == true) || (window.timeSeriesData[chartIndex].colorsArr.length == 0)) {
            window.timeSeriesData[chartIndex].colorsArr = [];
            window.timeSeriesData[chartIndex].colorsDrawArr = [];
            window.timeSeriesData[chartIndex].dashStyles = [];
            var curColor = 'ffffff';//getColor(hexToDecColor(md5Arr[0]));
            //window.timeSeriesData[chartIndex].colorsArr.push(curColor);
            //window.timeSeriesData[chartIndex].colorsDrawArr.push(curColor);
            var curDashStyle = 'Solid';

            var wordsListLength;
            if (window.colorsArraySetIndex == 13) {
                wordsListLength = wordsListArr.length;
            } else if (window.colorsArraySetIndex == 14) {
                wordsListLength = wordsListArr.length;
            } else {
                wordsListLength = window.colorsArray[window.colorsArraySetIndex].length;
            }

            for (var i = 0; i < wordsListObj.length; i++) {
                if (firstLoadChart == 1 && formDataStr != '') {
                    curColor = formdata.curWordColor;
                    formDataStr = '';
                } else  if (formdata.dropdownColorsSelection != 10) {
                    if ((typeof addData.clustersColors !== "undefined") && (typeof addData.clustersColors[i] !== "undefined")) {
                        //curColor = getColor(addData.clustersColors[i-1]*1+1, 0, 1, );
                        curColor = getColor(addData.clustersColors[i] * 1 + 1, 0, 1, undefined, wordsListObj[i].name,
                            i, formdata);
                        curDashStyle = (addData.clustersColors[i] * 1 + 1, 0, 1, wordsListLength);
                    } else {
                        if (formdata.dropdownColorsSelection == '1') {
                            //curColor = getColor(hexToDecColor(md5Arr[i]), undefined, undefined, md5Arr[i]);
                            curColor = getColor(i, undefined, undefined, undefined, wordsListObj[i].name,
                                i, formdata);
                            curDashStyle = getDashStyle(hexToDecColor(wordsListObj[i].md5), undefined, undefined, wordsListLength);
                        } else {
                            //curColor = getColor(i);
                            curColor = getColor(i, undefined, undefined, undefined, wordsListObj[i].name,
                                i, formdata);
                            curDashStyle = getDashStyle(i, undefined, undefined, wordsListLength);
                        }
                    }
                } else {
                    curColor = getColor(i, undefined, undefined, wordsListObj[i].md5, wordsListObj[i].name, i, formdata);
                    
                    /*$.each(wordsListObj, function(indexW, itemW) {
                        if (wordsListObj[indexW].name == resData.nodes[index].name) {
                            //console.log(resData.nodes[index].value, index);
                            if (typeof wordsListObj[indexW].value == "undefined") {
                                wordsListObj[indexW].value = resData.nodes[index].value;
                            } else {
                                wordsListObj[indexW].value += resData.nodes[index].value;
                            }
                            wordsListObj[indexW].color = getColor(index, undefined, undefined, wordsListObj[indexW].value, undefined, undefined, formdata);
                        }
                    });*/
                }

                
                
                
                
                //console.log(curColor);
                window.timeSeriesData[chartIndex].colorsArr[i] = curColor;
                window.timeSeriesData[chartIndex].colorsDrawArr[i] = curColor;
                wordsListObj[i].color = curColor;
                if (chartTimeSeriesType == 1 ||
                    chartTimeSeriesType == 2 ||
                    chartTimeSeriesType == 4 ||
                    chartTimeSeriesType == 5 ||
                    chartTimeSeriesType == 6 ||
                    chartTimeSeriesType == 7 ||
                    chartTimeSeriesType == 8) {
                    window.timeSeriesData[chartIndex].dashStyles.push({ dashStyle: curDashStyle, fillColor: "undefined", type: "undefined" });
                } else if (chartTimeSeriesType == 10 ||
                    chartTimeSeriesType == 11 ||
                    chartTimeSeriesType == 12) {
                    var markerSymbol = window.timeSeriesData[chartIndex].markerSymbols[i % 4];
                    window.timeSeriesData[chartIndex].dashStyles.push({ dashStyle: curDashStyle });
                }
            }
        } else {
            for (var i = 0; i < wordsListObj.length; i++) {
                if (firstLoadChart == 1 && formDataStr != '') {
                    curColor = formdata.curWordColor;
                    formDataStr = '';
                } else if ((typeof addData.clustersColors !== "undefined") && (typeof addData.clustersColors[i] !== "undefined")) {
                    //curColor = getColor(addData.clustersColors[i-1]*1+1, 0, 1, );
                    curColor = getColor(addData.clustersColors[i] * 1 + 1, 0, 1, undefined, wordsListObj[i].name,
                        i, formdata);
                    curDashStyle = (addData.clustersColors[i] * 1 + 1, 0, 1, wordsListLength);
                } else {
                    if (formdata.dropdownColorsSelection == '1') {
                        //curColor = getColor(hexToDecColor(md5Arr[i]), undefined, undefined, md5Arr[i]);
                        curColor = getColor(i, undefined, undefined, undefined, wordsListObj[i].name,
                            i, formdata);
                        curDashStyle = getDashStyle(hexToDecColor(wordsListObj[i].md5), undefined, undefined, wordsListLength);
                    } else {
                        //curColor = getColor(i);
                        curColor = getColor(i, undefined, undefined, undefined, wordsListObj[i].name,
                            i, formdata);
                        curDashStyle = getDashStyle(i, undefined, undefined, wordsListLength);
                    }
                }
                //console.log(curColor);
                window.timeSeriesData[chartIndex].colorsArr[i] = curColor;
                window.timeSeriesData[chartIndex].colorsDrawArr[i] = curColor;
                wordsListObj[i].color = curColor;
            }
        }
    }
    //console.log('wordsListObj', wordsListObj);
    if (typeof addData.sourceTitle !== "undefined") {
        window.timeSeriesData[chartIndex].sourceTitle = addData.sourceTitle;
    }
    if (typeof addData.dateFrom !== "undefined") {
        window.timeSeriesData[chartIndex].dateFrom = addData.dateFrom;
    }
    if (typeof addData.dateTo !== "undefined") {
        window.timeSeriesData[chartIndex].dateTo = addData.dateTo;
    }

    if (data.countDates <= 31) {
        var intervalInDays = 24 * 3600 * 1000;
    } else {
        var intervalInDays = undefined;
    }

    if (typeof formdata.textBoxTitleTimeCharts !== "undefined") {
        var textBoxTitleTimeCharts = formdata.textBoxTitleTimeCharts;
    } else {
        var textBoxTitleTimeCharts = '';
    }
    var dataSourceLabel = '';
    if (typeof formdata.dropdownValueDSource !== "undefined") {
        $.each(DataSelectOptions, function (index, item) {
            if (item.value == formdata.dropdownValueDSource) {
                dataSourceLabel = item.label;
            }
        });
    }
    var chartViewLabel = '';
    if (typeof formdata.dropdownChartView !== "undefined") {
        $.each(sourceChartView, function (index, item) {
            if (item.value == formdata.dropdownChartView) {
                chartViewLabel = item.label;
            }
        });
    }

    textBoxTitleTimeCharts = textBoxTitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.timeSeriesData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.timeSeriesData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel);
    if (typeof formdata.textBoxSubtitleTimeCharts !== "undefined") {
        var textBoxSubtitleTimeCharts = formdata.textBoxSubtitleTimeCharts;
    } else {
        var textBoxSubtitleTimeCharts = 'Data source: %DATA_SOURCE% (%CHART_VIEW%)';
    }
    textBoxSubtitleTimeCharts = textBoxSubtitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.timeSeriesData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.timeSeriesData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel);
    if (typeof formdata.textBoxDescriptionTimeCharts !== "undefined") {
        var textBoxDescriptionTimeCharts = formdata.textBoxDescriptionTimeCharts;
    } else {
        var textBoxDescriptionTimeCharts = 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%';
    }
    textBoxDescriptionTimeCharts = textBoxDescriptionTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.timeSeriesData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.timeSeriesData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel);

    if ((chartBackgroundColor == 0)) {
        chartBackgroundColor = setAutoBackgroundColor();
    }
    $('#dataContaner').css('background-color', chartBackgroundColor);

    var debugArr = data.split('#debug ');
    var debugData = "";
    dataArrLength = debugArr.length;
    for (var i = 1; i < debugArr.length; i++) {
        var debugLine = debugArr[i].split("\n");
        debugData = debugData + debugLine[0] + '<br>';
    }
    $('#DebugContaner').html(debugData);

    Highcharts.seriesTypes.area.prototype.drawLegendSymbol =
        Highcharts.seriesTypes.line.prototype.drawLegendSymbol;

    Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode = {
            circle: '●',
            diamond: '♦',
            square: '■',
            triangle: '▲',
            'triangle-down': '▼'
        }[this.symbol] || '●';
    });
    console.time('line2');


    //console.log(data);
    var dataArr = data.split('#src_data, ');
    var newData = data + "\n";
    var labelsIndexesArr = [];
    var labelsIndexesArrCnt = [];
    var dataValues = [];
    var averageValues = [];
    var maxValues = [];
    var minValues = [];
    var sumValues = [];
    var averageCnt = [];
    var dataArr1 =  dataArr[0].split("\n");
    dataArrLength = dataArr.length;

    for (var i1 = 2; i1 < dataArr1.length; i1++) {
        //newData = newData + dataArr[i];
        dataValues[i1-2] = dataArr1[i1].split(',');
        var valCnt = 0;
        for (var j1 = 1; j1 < dataValues[i1-2].length; j1++) {
            if (typeof averageCnt[j1-1] == "undefined") {
                averageCnt[j1-1] = 0;
            }
            if ((dataValues[i1-2][j1] != null) && (dataValues[i1-2][j1] != 'null')) {
                //console.log('not null', dataValues[i1-2][j1]);
                averageCnt[j1-1]++;
                if (typeof maxValues[j1-1] == "undefined") {
                    maxValues[j1-1] = dataValues[i1-2][j1]*1;
                }
                if (typeof minValues[j1-1] == "undefined") {
                    minValues[j1-1] = dataValues[i1-2][j1]*1;
                }
                if (typeof sumValues[j1-1] == "undefined") {
                    sumValues[j1-1] = dataValues[i1-2][j1]*1;
                    maxValues[j1-1] = dataValues[i1-2][j1]*1;
                    minValues[j1-1] = dataValues[i1-2][j1]*1;
                } else {
                    sumValues[j1-1] += dataValues[i1-2][j1]*1
                    if (dataValues[i1-2][j1]*1 > maxValues[j1-1]) {
                        maxValues[j1-1] = dataValues[i1-2][j1]*1;
                    }
                    if (dataValues[i1-2][j1]*1 < minValues[j1-1]) {
                        minValues[j1-1] = dataValues[i1-2][j1]*1;
                    }
                }
            } else {
                //console.log('null ', dataValues[i1-2][j1]);
                if (typeof sumValues[j1-1] == "undefined") {
                    sumValues[j1-1] = 0;
                }
            }

        }
    }

    for (var i = 0; i < sumValues.length; i++) {
        if (averageCnt[i] > 0) {
            averageValues[i] = Math.round(sumValues[i]/averageCnt[i]);
        } else {
            averageValues[i] = 0;
        }
        if (typeof maxValues[i] == "undefined") {
            maxValues[i] = 0;
        }
        if (typeof minValues[i] == "undefined") {
            minValues[i] = 0;
        }
    }

    //console.log(averageValues, sumValues, averageCnt, maxValues, minValues);




    /**
     * Time series view: 1 -Line - Basic
     */
    if (chartTimeSeriesType == 1) { //Line - Basic
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        var labelsIndexesArr = [];
        var labelsIndexesArrCnt = [];

        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }

        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });

        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            chart: {
                zoomType: 'x',
                height: window.timeSeriesData[chartIndex].height,
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor,
                animation: false
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            data: {
                csv: newData,
                boostThreshold: 0,
                //turboThreshold: 0,

                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var columnsLength = Object.keys(columns).length;
                    Highcharts.each(columns, function (c, j) {
                        //console.log(c, j, wordsListObj.length);
                        if (j <= wordsListObj.length) {
                            var curName = '';
                            var seriesData = [];
                            var seriesData2 = [];
                            var lastP = '';
                            var cLength = Object.keys(c).length;
                            Highcharts.each(c, function (p, i) {
                                //console.log(p, i, seriesData);
                                if (j == 0 && i > 0) {
                                    window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                                } else if (i == 0) {
                                    curName = replaceNamesIndicators(p);
                                } else {
                                    if (!isNaN(parseFloat(p))) {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        }
                                    } else {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        }

                                    }
                                }
                            });
                        }
                        if (j <= wordsListObj.length) {
                            if (j > 0) {
                                formdata.changedColors = findPopWordNewColor(curName, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                                if (formdata.dropdownColorsSelection != 10) {
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                    );
                                } else {
                                    var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : curColorsObj.curColor
                                    );
                                }    
                                //console.log(seriesData, seriesData.length);
                                if ((typeof seriesData !== "undefined") && (seriesData.length > 0)) {
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: curName,
                                        data: seriesData,
                                        boostThreshold: 0,
                                        turboThreshold: 0,
                                        dataLabels: {
                                            style: {
                                                //fontSize: '7px'
                                            }
                                        },
                                        type: "line",
                                        //turboThreshold: 100000,
                                        color: curColor,
                                        marker: {
                                            enabled: true,
                                            lineWidth: 1,
                                            radius: 4 * circleSizeMult
                                        },
                                        connectNulls: connectNulls,
                                        lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                                        dashStyle: (typeof window.timeSeriesData[chartIndex].dashStyles[j - 1] !== "undefined") ? window.timeSeriesData[chartIndex].dashStyles[j - 1].dashStyle : 'Solid'
                                    });
                                }
                                if ((sourceDataPoints === true) || (sourceDataPoints == 'true')) {
                                    if (seriesData2.length > 0) {
                                        window.timeSeriesDataInit.seriesData.push({
                                            name: curName,
                                            data: seriesData2,
                                            boostThreshold: 0,
                                            turboThreshold: 0,
                                            type: "scatter",
                                            color: curColor,
                                            marker: {
                                                enabled: enableMarker,
                                                lineWidth: 0,
                                                radius: 4 * sourcePointsSizeMult
                                            },
                                            connectNulls: connectNullsSource,
                                            lineWidth: 1 * (connectorLinesSource == 0 ? 0 : lineWidthMult),
                                            legend: {
                                                enabled: false
                                            },
                                            showInLegend: false,
                                        });
                                    }
                                }
                                var colornew = getFieldValueByIndex(wordsListObj, 'color', j-1);
                                var colornewIsChange = 0;
                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    colornew = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    window.timeSeriesData[chartIndex].colorsDrawArr[j-1] = colornew;
                                    colornewIsChange = 1;
                                }
                                formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j - 1].md5, chartIndex);
                                if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                        'name': wordsListObj[j-1].name,
                                        'size': 1, //item.sizeReal,
                                        'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                        'colornew': curColor,
                                        'id': j,
                                        'fore': 0,
                                        'md5': wordsListObj[j-1].md5,
                                        'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                    };
                                }
                                if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                    if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                        window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                    }
                                }
                                //console.log(j, 'window.timeSeriesData[chartIndex].wordsList', window.timeSeriesData[chartIndex].wordsList, wordsListObj);
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            /*title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    +'<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },*/
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    millisecond: '%Y-%m-%d, %H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%Y-%m-%d, %H:%M',
                    hour: '%Y-%m-%d, %H:%M',
                    day: '%Y-%m-%d',//'%e. %b',
                    month: '%Y-%m-%d',//'%e. %b',
                    year: '%Y-%m-%d',//'%b'
                    week: '%Y-%m-%d'

                    /*millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e. %b',
                    week: '%e. %b',
                    month: '%b \'%y',
                    year: '%Y'*/
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,//'{value:%Y-%m-%d}'
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: [
                { // left y axis
                    title: {
                        text: null,
                        style: {
                            color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                        }
                    },
                    labels: {
                        align: 'left',
                        x: 3,
                        y: 16,
                        format: '{value:.,0f}',
                        style: {
                            color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                        }
                    },
                    showFirstLabel: false,
                    visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                    gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    },
                    lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                    tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
                },
                { // right y axis
                    linkedTo: 0,
                    gridLineWidth: 0,
                    opposite: true,
                    title: {
                        text: null,
                        style: {
                            color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                        }
                    },
                    labels: {
                        align: 'right',
                        x: -3,
                        y: 16,
                        format: '{value:.,0f}',
                        style: {
                            color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                        }
                    },
                    showFirstLabel: false,
                    showLastLabel: false,
                    visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                    gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    },
                    lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                    tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
                },
            ],
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                borderWidth: 0,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1,
                symbolWidth: 50
            },
            tooltip: {
                //shared: true,
                split: false,

                //crosshairs: false,
                formatter: function () {
                    //console.log(this);
                    var s = '<b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '</b>';
                    //s += '<br/><span style="color:' + this.color + '">' + this.name + '</span>: ' + this.y;
                    s += '<br/><span style="color:' + this.point.series.color + '">' + this.point.series.name + '</span>: ' + this.point.y;
                    if (isWidget == 1) {
                        //console.log('isWidget = 1');
                        //console.log(iframeOptionsConfig);
                        //$('#jqxLoaderWidget').jqxLoader('close');
                        var linkStr = getTooltipLinkStr(this.point.series.name);
                        s += "<br/>" + linkStr;
                    
                        //s += "<br/>" + JSON.stringify(iframeOptionsConfig);
                    } else {
                        //console.log('not isWidget');
                        s += "<br/><span onclick=\"formDataToNewTab('" + this.point.series.name +  "', '" + this.point.series.color + "', '" + this.point.series.color + "');\">Open report</span> ";
                        
                        /*widgetStr = widgetStr + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'extraOptions: {' + "\n"
                        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'valueShare: "' + data.result.hash + '",' + "\n"
                        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + "linkHTML: \'&lt;a class=\"%OPEN_IDE_CSS%\" style=\"%INLINE_STYLE%\" title=\"Open report\" "
                        + "onclick=\"window.open(\\\'" + window.location.origin + '/drawing?' + theme + '&share=' + data.result.hash + "\\\');\"&gt;Open report&lt;/a&gt;\'," + "\n"
                        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '},' + "\n";*/
                    }

                    if((typeof $('#checkboxPopWordReportTooltip') != "undefined") 
                        && (typeof $('#checkboxPopWordReportTooltip')[0] != "undefined") 
                        && ($('#checkboxPopWordReportTooltip')[0].checked)) {
                        
                    }

                    //$.each(this.points, function (i, point) {
                    //    s += '<br/><span style="color:' + point.series.color + '">' + point.series.name + '</span>: ' + point.y;
                    //});
                    return s;
                },
                useHTML: true,
                style: {
                  pointerEvents: 'auto'
                },
                animation: false,
                hideDelay: 1000
            },

            plotOptions: {
                series: {
                    boostThreshold: 0,
                    turboThreshold: 10000000,
                    cursor: 'pointer',
                    animation: false,
                    point: {
                        events: {
                            click: function (e) {
                                /*hs.htmlExpand(null, {
                                    pageOrigin: {
                                        x: e.pageX || e.clientX,
                                        y: e.pageY || e.clientY
                                    },
                                    headingText: this.series.name,
                                    //maincontentText: Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ':<br/> ' + this.y,
                                    maincontentText: Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ': ' + this.y + '<br/>' + this.series.name
                                    //+ '<a href="' + twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name)
                                    //+ '" target="_blank"><img src="/images/icon_Twitter.png"></a>'
                                    ,
                                    width: 240,
                                    height: 110
                                });*/

                                if (dbDataType == 1) {
                                    var addTwitterIcon = '<a href="'
                                        + twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name)
                                        + '" target="_blank"><img src="/images/icon_Twitter.png"></a>';
                                    window.open(twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name));
                                } else {
                                    if (isWidget == 1) {
                                        $('#jqxLoaderWidget').jqxLoader('open');
                                    } else {
                                        $('#jqxLoader').jqxLoader('open');
                                    }
                                    if (!isLegend) {
                                        $('.highcharts-legend').css('display', 'none');
                                    }
                                    var formData = window.newValuesFormTab1;
                                    formData.chartType = CHART_TYPE_TIMESERIES;
                                    formData.metadataWord = this.series.name;
                                    formData.metadataValue = this.y;
                                    formData.metadataDate = {};
                                    formData.metadataDate.year = Highcharts.dateFormat('%Y', this.x);
                                    formData.metadataDate.month = Highcharts.dateFormat('%m', this.x);
                                    formData.metadataDate.day = Highcharts.dateFormat('%e', this.x);

                                    getDataAndShowMeasurePointDialog('current');
                                }
                            }
                        }
                    },
                    items:
                        [{
                            style: { "position": "absolute", left: '100px', top: '200px' }
                        }],
                    label: {
                        boxesToAvoid: {
                            left: '500px'
                        },
                        enabled: isSeriesLabel,
                        //maxFontSize: '8px',
                        //style: { "position": "absolute", left: '100px', top: '200px' },
                        connectorAllowed: true,
                        connectorNeighbourDistance: 30,
                        //onArea: false, 
                        //minFontSize: 5
                    },
                    /*marker: {
                        enabled: enableMarker,
                        lineWidth: 1 * lineWidthMult,
                        radius: 4 * circleSizeMult
                    },*/
                    connectNulls: connectNulls,
                    dataLabels: {
                        enabled: true,
                        align: 'left',
                        formatter: function() {
//console.log(Object.keys(this.point).length, this.series);
                            if (Object.keys(this.series).length > 49) {
                                //console.log(labelsIndexesArr.indexOf(this.series.name));
                                if (labelsIndexesArr.indexOf(this.series.name) == -1) {
                                    labelsIndexesArr[labelsIndexesArr.length] = this.series.name;
                                    labelsIndexesArrCnt[labelsIndexesArr.length] = 0;
                                    //return `<span style="color: ${this.series.color}">${this.series.name}</span>`
                                } else {
                                    //console.log(this.series.name, this.point.index);
                                    if (typeof labelsIndexesArrCnt[labelsIndexesArr.indexOf(this.series.name)] !== "undefined") {
                                        labelsIndexesArrCnt[labelsIndexesArr.indexOf(this.series.name)]++;
                                    } else {
                                        labelsIndexesArrCnt[labelsIndexesArr.indexOf(this.series.name)] = 1;
                                    }
                                    if (labelsIndexesArrCnt[labelsIndexesArr.indexOf(this.series.name)] == 1) {
                                        //console.log(this.point.index);
                                        return `<span style="color: ${this.series.color}">${this.series.name}</span>`
                                    }
                                    //console.log(labelsIndexesArrCnt);
                                }
                                //if ((this.point.index === this.series.points.length - 2) || (this.point.index === 1)) {
                                //    return `<span style="color: ${this.series.color}">${this.series.name}</span>`
                                //}
                                //return `<span style="color: ${this.series.color}">${this.series.name}</span>`
                            }
                        }
                    }
                
                },
                connectNulls: connectNulls,
            },
        });
    }

    /**
     * Time series view: 2 - Line - Accessible
     */
    if (chartTimeSeriesType == 2) { //Line - Accessible
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            chart: {
                zoomType: 'x',
                height: window.timeSeriesData[chartIndex].height,
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            data: {
                csv: newData,
                boostThreshold: 1,
                turboThreshold: 1000000,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var columnsLength = Object.keys(columns).length;
                    Highcharts.each(columns, function (c, j) {
                        if (j <= wordsListObj.length) {
                            var curName = '';
                            var seriesData = [];
                            var seriesData2 = [];
                            var lastP = '';
                            var cLength = Object.keys(c).length;
                            Highcharts.each(c, function (p, i) {
                                if (j == 0 && i > 0) {
                                    window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                                } else if (i == 0) {
                                    curName = replaceNamesIndicators(p);
                                } else {
                                    if (!isNaN(parseFloat(p))) {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        }
                                    } else {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        }
                                    }
                                }
                            });
                        }
                        if (j <= wordsListObj.length) {
                            if (j > 0) {
                                formdata.changedColors = findPopWordNewColor(curName, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
 
                                if (formdata.dropdownColorsSelection != 10) {
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                    );
                                } else {
                                    var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : curColorsObj.curColor
                                    );
                                }    

                                if ((typeof seriesData !== "undefined") && (seriesData.length > 0)) {
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: curName,
                                        data: seriesData,
                                        dataLabels: {
                                            style: {
                                                fontSize: '7px'
                                            }
                                        },
                                        type: "line",
                                        turboThreshold: 100000,
                                        color: curColor,
                                        marker: {
                                            enabled: true,
                                            lineWidth: 1,
                                            radius: 4 * circleSizeMult
                                        },
                                        connectNulls: connectNulls,
                                        lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                                        //dashStyle: window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle
                                        dashStyle: (typeof window.timeSeriesData[chartIndex].dashStyles[j-1] !== "undefined") ? window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle : 'Solid'
                                    });
                                }
                                if ((sourceDataPoints === true) || (sourceDataPoints == 'true')) {
                                    if (seriesData2.length > 0) {
                                        window.timeSeriesDataInit.seriesData.push({
                                            name: curName,
                                            data: seriesData2,
                                            type: "line",
                                            color: curColor,
                                            marker: {
                                                enabled: enableMarker,
                                                lineWidth: 0,
                                                radius: 4 * sourcePointsSizeMult
                                            },
                                            connectNulls: connectNullsSource,
                                            lineWidth: 1 * (connectorLinesSource == 0 ? 0 : lineWidthMult),
                                            legend: {
                                                enabled: false
                                            },
                                            showInLegend: false,
                                        });
                                    }
                                }
                                var colornew = window.timeSeriesData[chartIndex].colorsArr[j-1];
                                var colornewIsChange = 0;
                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    colornew = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    window.timeSeriesData[chartIndex].colorsDrawArr[j-1] = colornew;
                                    colornewIsChange = 1;
                                }
                                formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j - 1].md5, chartIndex);
                                if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                        'name': wordsListObj[j - 1].name,
                                        'size': 1, //item.sizeReal,
                                        'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                        'colornew': curColor,
                                        'id': j,
                                        'fore': 0,
                                        'md5': wordsListObj[j-1].md5,
                                        'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                    };
                                }
                                if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                    if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                        window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                    }
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    day: '%Y-%m-%d',//'%e. %b',
                    month: '%Y-%m-%d',//'%e. %b',
                    year: '%Y-%m-%d',//'%b'
                    week: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                borderWidth: 0,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1,
                symbolWidth: 50
            },
            boost: {
                useGPUTranslations: true
            },
            tooltip: {
                split: true,
                formatter: function () {
                    var s = '<b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '</b>';
                    $.each(this.points, function (i, point) {
                        s += '<br/><span style="color:' + point.series.color + '">' + point.series.name + '</span>: ' + point.y;
                    });
                    return s;
                }
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function (e) {
                                hs.htmlExpand(null, {
                                    pageOrigin: {
                                        x: e.pageX || e.clientX,
                                        y: e.pageY || e.clientY
                                    },
                                    headingText: this.series.name,
                                    maincontentText: Highcharts.dateFormat('%Y-%m-%d', this.x) + ':<br/> ' + this.y,
                                    width: 200
                                });
                            }
                        }
                    },
                    connectNulls: connectNulls,
                    label: {
                        enabled: isSeriesLabel,
                        maxFontSize: '8px',
                        style: { "position": "absolute", left: '100px', top: '100px' }
                    },

                    wordcloud: {
                        maxFontSize: '8px'
                    }
                },
                connectNulls: connectNulls,
            },
        });
    }


    /**
     * Time series view: 4 - Line - Data labels
     */
    if (chartTimeSeriesType == 4) { //Line - Data labels
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            chart: {
                height: window.timeSeriesData[chartIndex].height,
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            data: {
                csv: newData,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var columnsLength = Object.keys(columns).length;
                    Highcharts.each(columns, function (c, j) {
                        if (j <= wordsListObj.length) {
                            var curName = '';
                            var seriesData = [];
                            var seriesData2 = [];
                            var lastP = '';
                            var cLength = Object.keys(c).length;
                            Highcharts.each(c, function (p, i) {
                                if (j == 0 && i > 0) {
                                    window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                                } else if (i == 0) {
                                    curName = replaceNamesIndicators(p);
                                } else {
                                    if (!isNaN(parseFloat(p))) {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        }
                                    } else {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        }
                                    }
                                }
                            });
                        }
                        if (j <= wordsListObj.length) {
                            if (j > 0) {
                                formdata.changedColors = findPopWordNewColor(curName, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                                if (formdata.dropdownColorsSelection != 10) {
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                    );
                                } else {
                                    var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : curColorsObj.curColor
                                    );
                                }    

                                if ((typeof seriesData !== "undefined") && (seriesData.length > 0)) {
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: curName,
                                        data: seriesData,
                                        dataLabels: {
                                            style: {
                                                fontSize: '7px'
                                            }
                                        },
                                        type: "line",
                                        turboThreshold: 100000,
                                        color: curColor,
                                        marker: {
                                            enabled: true,
                                            lineWidth: 1,
                                            radius: 4 * circleSizeMult
                                        },
                                        connectNulls: connectNulls,
                                        lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                                        //dashStyle: window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle
                                        dashStyle: (typeof window.timeSeriesData[chartIndex].dashStyles[j-1] !== "undefined") ? window.timeSeriesData[chartIndex].dashStyles[j - 1].dashStyle : 'Solid'
                                    });
                                }
                                if ((sourceDataPoints === true) || (sourceDataPoints == 'true')) {
                                    if (seriesData2.length > 0) {
                                        window.timeSeriesDataInit.seriesData.push({
                                            name: curName,
                                            data: seriesData2,
                                            type: "line",
                                            color: curColor,
                                            marker: {
                                                enabled: enableMarker,
                                                lineWidth: 0,
                                                radius: 4 * sourcePointsSizeMult
                                            },
                                            connectNulls: connectNullsSource,
                                            lineWidth: 1 * (connectorLinesSource == 0 ? 0 : lineWidthMult),
                                            legend: {
                                                enabled: false
                                            },
                                            showInLegend: false,
                                        });
                                    }
                                }
                                var colornew = window.timeSeriesData[chartIndex].colorsArr[j-1];
                                var colornewIsChange = 0;
                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    colornew = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    window.timeSeriesData[chartIndex].colorsDrawArr[j-1] = colornew;
                                    colornewIsChange = 1;
                                }
                                formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                                if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                        'name': wordsListObj[j-1].name,
                                        'size': 1, //item.sizeReal,
                                        'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                        'colornew': curColor,
                                        'id': j,
                                        'fore': 0,
                                        'md5': wordsListObj[j-1].md5,
                                        'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                    };
                                }
                                if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                    if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                        window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                    }
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    day: '%Y-%m-%d',//'%e. %b',
                    month: '%Y-%m-%d',//'%e. %b',
                    year: '%Y-%m-%d',//'%b'
                    week: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                borderWidth: 0,
                symbolWidth: 50,
                enabled: true,//isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false,
                    turboThreshold: 1000000
                },
                series: {
                    marker: {
                        enabled: enableMarker,
                        lineWidth: 1,
                        radius: 4 * circleSizeMult
                    },
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    connectNulls: connectNulls
                },
                label: {
                    enabled: isSeriesLabel
                },
                connectNulls: connectNulls,
            },
        });
    }

    /**
     * Time series view: 5 - Line - Zoomable
     */
    if (chartTimeSeriesType == 5) { //Line - Zoomable
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            chart: {
                zoomType: 'x',
                height: window.timeSeriesData[chartIndex].height,
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            credits: {
                enabled: false
            },
            tooltip: {
                //split: true,
                formatter: function () {
                    var s = '<b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '</b>';
                    //console.log(this);
                    s += '<br/><span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + this.y;

                    //$.each(this.points, function(i, point) {
                    //    s += '<br/><span style="color:'+point.series.color+'">'+ point.series.name +'</span>: '+point.y;
                    //});
                    return s;
                }
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            data: {
                csv: newData,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var columnsLength = Object.keys(columns).length;
                    Highcharts.each(columns, function (c, j) {
                        if (j <= wordsListObj.length) {
                            var curName = '';
                            var seriesData = [];
                            var seriesData2 = [];
                            var lastP = '';
                            var cLength = Object.keys(c).length;
                            Highcharts.each(c, function (p, i) {
                                if (j == 0 && i > 0) {
                                    window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                                } else if (i == 0) {
                                    curName = replaceNamesIndicators(p);
                                } else {
                                    if (!isNaN(parseFloat(p))) {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        }
                                    } else {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        }
                                    }
                                }
                            });
                        }
                        if (j <= wordsListObj.length) {
                            if (j > 0) {
                                formdata.changedColors = findPopWordNewColor(curName, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                                if (formdata.dropdownColorsSelection != 10) {
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                    );
                                } else {
                                    var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : curColorsObj.curColor
                                    );
                                }    

                                if ((typeof seriesData !== "undefined") && (seriesData.length > 0)) {
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: curName,
                                        data: seriesData,
                                        dataLabels: {
                                            style: {
                                                fontSize: '7px'
                                            }
                                        },
                                        type: "line",
                                        turboThreshold: 1000000,
                                        color: curColor,
                                        marker: {
                                            enabled: true,
                                            lineWidth: 1,
                                            radius: 4 * circleSizeMult
                                        },
                                        connectNulls: connectNulls,
                                        lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                                        //dashStyle: window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle
                                        dashStyle: (typeof window.timeSeriesData[chartIndex].dashStyles[j - 1] !== "undefined") ? window.timeSeriesData[chartIndex].dashStyles[j - 1].dashStyle : 'Solid'
                                    });
                                }
                                if ((sourceDataPoints === true) || (sourceDataPoints == 'true')) {
                                    if (seriesData2.length > 0) {
                                        window.timeSeriesDataInit.seriesData.push({
                                            name: curName,
                                            data: seriesData2,
                                            type: "scatter",
                                            color: curColor,
                                            marker: {
                                                enabled: enableMarker,
                                                lineWidth: 0,
                                                radius: 4 * sourcePointsSizeMult
                                            },
                                            connectNulls: connectNullsSource,
                                            lineWidth: 1 * (connectorLinesSource == 0 ? 0 : lineWidthMult),
                                            legend: {
                                                enabled: false
                                            },
                                            showInLegend: false,
                                        });
                                    }
                                }
                                var colornew = window.timeSeriesData[chartIndex].colorsArr[j-1];
                                var colornewIsChange = 0;
                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    colornew = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    window.timeSeriesData[chartIndex].colorsDrawArr[j-1] = colornew;
                                    colornewIsChange = 1;
                                }
                                formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                                if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                        'name': wordsListObj[j-1].name,
                                        'size': 1, //item.sizeReal,
                                        'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                        'colornew': curColor,
                                        'id': j,
                                        'fore': 0,
                                        'md5': wordsListObj[j-1].md5,
                                        'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                    };
                                }
                                if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                    if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                        window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                    }
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    day: '%Y-%m-%d',//'%e. %b',
                    month: '%Y-%m-%d',//'%e. %b',
                    year: '%Y-%m-%d',//'%b'
                    week: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                borderWidth: 0,
                symbolWidth: 50,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        enabled: enableMarker,
                        lineWidth: 1,
                        radius: 4 * circleSizeMult
                    },
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    states: {
                        hover: {
                            lineWidth: 2 * lineWidthMult,
                        }
                    },
                    threshold: null
                },
                series: {
                    connectNulls: connectNulls,
                    label: {
                        enabled: isSeriesLabel
                    },
                },
                connectNulls: connectNulls,
                tturboThreshold: 1000000
            },
        });
    }

    /**
     * Time series view: 6 - Line - Spline
     */
    if (chartTimeSeriesType == 6) { //Line - Spline
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "spline";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            chart: {
                zoomType: 'x',
                height: window.timeSeriesData[chartIndex].height,
                /*scrollablePlotArea: {
                    minWidth: 600,
                    scrollPositionX: 1
                }*/
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            tooltip: {
                //split: true,
                formatter: function () {
                    var s = '<b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '</b>';
                    s += '<br/><span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + this.y;
                    //$.each(this.points, function(i, point) {
                    //    s += '<br/><span style="color:'+point.series.color+'">'+ point.series.name +'</span>: '+point.y;
                    //});
                    return s;
                }
            },
            data: {
                csv: newData,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var columnsLength = Object.keys(columns).length;
                    Highcharts.each(columns, function (c, j) {
                        if (j <= wordsListObj.length) {
                            var curName = '';
                            var seriesData = [];
                            var seriesData2 = [];
                            var lastP = '';
                            var cLength = Object.keys(c).length;
                            Highcharts.each(c, function (p, i) {
                                if (j == 0 && i > 0) {
                                    window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                                } else if (i == 0) {
                                    curName = replaceNamesIndicators(p);
                                } else {
                                    if (!isNaN(parseFloat(p))) {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                        }
                                    } else {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                        }
                                    }
                                }
                            });
                        }
                        if (j <= wordsListObj.length) {
                            if (j > 0) {
                                formdata.changedColors = findPopWordNewColor(curName, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);

                                if (formdata.dropdownColorsSelection != 10) {
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                    );
                                } else {
                                    var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : curColorsObj.curColor
                                    );
                                }    

                                if ((typeof seriesData !== "undefined") && (seriesData.length > 0)) {
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: curName,
                                        data: seriesData,
                                        dataLabels: {
                                            style: {
                                                fontSize: '7px'
                                            }
                                        },
                                        type: "spline",
                                        turboThreshold: 100000,
                                        color: curColor,
                                        marker: {
                                            enabled: false,
                                            lineWidth: 1,
                                            radius: 4 * circleSizeMult
                                        },
                                        connectNulls: connectNulls,
                                        lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                                        //dashStyle: window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle
                                        dashStyle: (typeof window.timeSeriesData[chartIndex].dashStyles[j-1] !== "undefined")
                                            ? window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle : 'Solid'
                                    });
                                }
                                if ((sourceDataPoints === true) || (sourceDataPoints == 'true')) {
                                    if (seriesData2.length > 0) {
                                        window.timeSeriesDataInit.seriesData.push({
                                            name: curName,
                                            data: seriesData2,
                                            type: "scatter",
                                            color: curColor,
                                            marker: {
                                                enabled: enableMarker,
                                                lineWidth: 0,
                                                radius: 4 * sourcePointsSizeMult
                                            },
                                            connectNulls: connectNullsSource,
                                            lineWidth: 1 * (connectorLinesSource == 0 ? 0 : lineWidthMult),
                                            legend: {
                                                enabled: false
                                            },
                                            showInLegend: false,
                                        });
                                    }
                                }
                                var colornew = window.timeSeriesData[chartIndex].colorsArr[j-1];
                                var colornewIsChange = 0;
                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    colornew = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    window.timeSeriesData[chartIndex].colorsDrawArr[j-1] = colornew;
                                    colornewIsChange = 1;
                                }
                                formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j - 1].md5, chartIndex);
                                if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                        'name': wordsListObj[j-1].name,
                                        'size': 1, //item.sizeReal,
                                        'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                        'colornew': curColor,
                                        'id': j,
                                        'fore': 0,
                                        'md5': wordsListObj[j-1].md5,
                                        'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                    };
                                }
                                if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                    if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                        window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                    }
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%Y-%m-%d',
                    year: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                borderWidth: 0,
                symbolWidth: 50,
                enabled: true,//isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            plotOptions: {
                spline: {
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    states: {
                        hover: {
                            lineWidth: 2 * lineWidthMult,
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    pointInterval: 3600000, // one hour
                    pointStart: Date.UTC(2018, 1, 13, 0, 0, 0),
                    turboThreshold: 100000
                },
                series: {
                    label: {
                        enabled: isSeriesLabel
                    },
                    connectNulls: connectNulls
                },
                connectNulls: connectNulls,
            },
        });
    }

    /**
     * Time series view: 7 - Line - Irregular intervals
     */
    if (chartTimeSeriesType == 7) { //Line - Irregular intervals
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "spline";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            //colors: window.timeSeriesData[chartIndex].colorsArr,
            chart: {
                zoomType: 'x',
                height: window.timeSeriesData[chartIndex].height,
                type: 'spline',
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            tooltip: {
                //split: true,
                formatter: function () {
                    var s = '<b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '</b>';
                    s += '<br/><span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + this.y;
                    //$.each(this.points, function(i, point) {
                    //    s += '<br/><span style="color:'+point.series.color+'">'+ point.series.name +'</span>: '+point.y;
                    //});
                    return s;
                }
            },
            data: {
                csv: newData,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var columnsLength = Object.keys(columns).length;
                    Highcharts.each(columns, function (c, j) {
                        if (j <= wordsListObj.length) {
                            var curName = '';
                            var seriesData = [];
                            var seriesData2 = [];
                            var lastP = '';
                            var cLength = Object.keys(c).length;
                            Highcharts.each(c, function (p, i) {
                                if (j == 0 && i > 0) {
                                    window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                                } else if (i == 0) {
                                    curName = replaceNamesIndicators(p);
                                } else {
                                    if (!isNaN(parseFloat(p))) {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i-1], y: parseFloat(p) });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i-1], y: parseFloat(p) });
                                        }
                                    } else {
                                        if (i <= (window.timeSeriesDataInit.seriesDates.length - dataArrLength + 1)) {
                                            seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i-1], y: null });
                                        } else {
                                            seriesData2.push({ x: window.timeSeriesDataInit.seriesDates[i-1], y: null });
                                        }
                                    }
                                }
                            });
                        }
                        if (j <= wordsListObj.length) {
                            if (j > 0) {
                                formdata.changedColors = findPopWordNewColor(curName, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);

                                if (formdata.dropdownColorsSelection != 10) {
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                    );
                                } else {
                                    var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : curColorsObj.curColor
                                    );
                                } 

                                if ((typeof seriesData !== "undefined") && (seriesData.length > 0)) {
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: curName,
                                        data: seriesData,
                                        dataLabels: {
                                            style: {
                                                fontSize: '7px'
                                            }
                                        },
                                        type: "spline",
                                        turboThreshold: 100000,
                                        color: curColor,
                                        marker: {
                                            enabled: true,
                                            lineWidth: 1,
                                            radius: 4 * circleSizeMult
                                        },
                                        connectNulls: connectNulls,
                                        lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                                        //dashStyle: window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle
                                        dashStyle: (typeof window.timeSeriesData[chartIndex].dashStyles[j-1] !== "undefined") 
                                            ? window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle : 'Solid'
                                    });
                                }
                                if ((sourceDataPoints === true) || (sourceDataPoints == 'true')) {
                                    if (seriesData2.length > 0) {
                                        window.timeSeriesDataInit.seriesData.push({
                                            name: curName,
                                            data: seriesData2,
                                            type: "scatter",
                                            color: curColor,
                                            marker: {
                                                enabled: enableMarker,
                                                lineWidth: 0,
                                                radius: 4 * sourcePointsSizeMult
                                            },
                                            connectNulls: connectNullsSource,
                                            lineWidth: 1 * (connectorLinesSource == 0 ? 0 : lineWidthMult),
                                            legend: {
                                                enabled: false
                                            },
                                            showInLegend: false,
                                        });
                                    }
                                }
                                var colornew = window.timeSeriesData[chartIndex].colorsArr[j-1];
                                var colornewIsChange = 0;
                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    colornew = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    window.timeSeriesData[chartIndex].colorsDrawArr[j-1] = colornew;
                                    colornewIsChange = 1;
                                }
                                formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j - 1].md5, chartIndex);
                                if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                        'name': wordsListObj[j-1].name,
                                        'size': 1, //item.sizeReal,
                                        'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                        'colornew': curColor,
                                        'id': j,
                                        'fore': 0,
                                        'md5': wordsListObj[j-1].md5,
                                        'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                    };
                                }
                                if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                    if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                        window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                    }
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            //series: window.timeSeriesData[chartIndex].dashStyles,
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%Y-%m-%d',
                    year: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    overflow: 'justify',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                borderWidth: 0,
                symbolWidth: 50,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            plotOptions: {
                spline: {
                    marker: {
                        enabled: enableMarker,
                        lineWidth: 1,
                        radius: 4 * circleSizeMult
                    },
                    turboThreshold: 100000
                },
                lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                series: {
                    label: {
                        enabled: isSeriesLabel
                    },
                    connectNulls: connectNulls
                },
                connectNulls: connectNulls,
            },
        });
    }

    /**
     * Time series view:8 - Line - Logarithmic axis
     */
    if (chartTimeSeriesType == 8) { //Line - Logarithmic axis
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            colors: window.timeSeriesData[chartIndex].colorsArr,
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            chart: {
                zoomType: 'x',
                /*scrollablePlotArea: {
                    minWidth: 600,
                    scrollPositionX: 1
                }*/
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            series: window.timeSeriesData[chartIndex].dashStyles,
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            tooltip: {
                //split: true,
                formatter: function () {
                    var s = '<b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '</b>';
                    s += '<br/><span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + this.y;
                    //$.each(this.points, function(i, point) {
                    //    s += '<br/><span style="color:'+point.series.color+'">'+ point.series.name +'</span>: '+point.y;
                    //});
                    return s;
                }
            },
            xAxis: {
                tickInterval: 1,
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%%Y-%m-%d',
                    year: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    overflow: 'justify',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                type: 'logarithmic',
                minorTickInterval: 0.1,
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            data: {
                csv: data,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var newColumns = [];
                    Highcharts.each(columns, function (c, j) {
                        var curName = '';
                        var seriesData = [];
                        Highcharts.each(c, function (p, i) {
                            if (j == 0 && i > 0) {
                                window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                            } else if (i == 0) {
                                curName = replaceNamesIndicators(p);
                            } else {
                                if (parseFloat(p) && (parseFloat(p) > 0)) {
                                    seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                }
                            }
                        });
                        if (j > 0) {
                            formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                            if (formdata.dropdownColorsSelection != 10) {
                                var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                ? formdata.changedColors[wordsListObj[j-1].md5].color
                                : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                );
                            } else {
                                var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
                                var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : curColorsObj.curColor
                                );
                            }    

                            window.timeSeriesDataInit.seriesData.push({
                                name: wordsListObj[j-1].name,
                                data: seriesData,
                                color: curColor
                            });
                            if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                    'name': wordsListObj[j-1].name,
                                    'size': 1, //item.sizeReal,
                                    'color': window.timeSeriesData[chartIndex].colorsArr[j - 1],
                                    'colornew': curColor,
                                    'id': j,
                                    'fore': 0,
                                    'md5': wordsListObj[j-1].md5,
                                    'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                };
                            }
                            if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    //if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                    //}
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                //borderWidth: 0,
                symbolWidth: 50,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            plotOptions: {
                spline: {
                    states: {
                        hover: {
                            lineWidth: 2 * lineWidthMult,
                        }
                    },
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    marker: {
                        enabled: false
                    },
                    pointInterval: 3600000, // one hour
                    pointStart: Date.UTC(2018, 1, 13, 0, 0, 0),
                    turboThreshold: 100000
                },
                series: {
                    label: {
                        enabled: isSeriesLabel
                    },
                    connectNulls: connectNulls,
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                },
                connectNulls: connectNulls,
            },
        });
    }

    /**
     * Time series view: 10 - Area - Basic
     */
    if (chartTimeSeriesType == 10) { //Area - Basic
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            colors: window.timeSeriesData[chartIndex].colorsArr,
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            chart: {
                type: 'area',
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            series: window.timeSeriesData[chartIndex].dashStyles,
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            tooltip: {
                //split: true,
                formatter: function () {
                    var s = '<b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '</b>';
                    s += '<br/><span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + this.y;
                    //$.each(this.points, function(i, point) {
                    //    s += '<br/><span style="color:'+point.series.color+'">'+ point.series.name +'</span>: '+point.y;
                    //});
                    return s;
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%Y-%m-%d',
                    year: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    overflow: 'justify',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {

                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            data: {
                csv: data,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var newColumns = [];
                    //console.log(wordsListObj);
                    Highcharts.each(columns, function (c, j) {
                        var curName = '';
                        var seriesData = [];
                        var seriesData2 = [];
                        var maxVal;
                        var mimVal;
                        var curVal;
                        var flagFirst = 1;

                        Highcharts.each(c, function (p, i) {
                            if (j == 0 && i > 0) {
                                window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                            } else if (i == 0) {
                                curName = replaceNamesIndicators(p);
                            } else {
                                curVal = parseFloat(p);
                                if (flagFirst == 1) {
                                    flagFirst = 0;
                                    maxVal = curVal;
                                    mimVal = curVal;
                                }
                                if (!isNaN(curVal)) {
                                //if (parseFloat(p) && (parseFloat(p) > 0)) {
                                    seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: curVal });
                                    if (curVal > maxVal) {
                                        maxVal = curVal;
                                    }
                                    if (curVal < mimVal) {
                                        mimVal = curVal;
                                    }
                                } else {
                                    seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                }

                            }


                            /*if ((typeof seriesData !== "undefined") && (seriesData.length > 0)) {
                                window.timeSeriesDataInit.seriesData.push({
                                    name: curName,
                                    data: seriesData,
                                    dataLabels:{
                                        style:{
                                            fontSize:'7px'
                                        }
                                    },
                                    type : "line",
                                    turboThreshold: 10000,
                                    color: curColor,
                                    marker: {
                                        enabled: true,
                                        lineWidth: 1,
                                        radius: 4 * circleSizeMult
                                    },
                                    connectNulls: connectNulls,
                                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                                    dashStyle: window.timeSeriesData[chartIndex].dashStyles[j-1].dashStyle
                               });
                            }*/

                        });
                        //console.log(seriesData);
                        if (j > 0) {
                            formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                            
                            if (formdata.dropdownColorsSelection != 10) {
                                var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                );

                                window.timeSeriesDataInit.seriesData.push({
                                    name: wordsListObj[j-1].name,
                                    data: seriesData,
                                    type: "area",
                                    fillColor: {
                                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                        stops: [
                                            [0, Highcharts.Color(curColor).setOpacity(0.7).get('rgba')],
                                            [1, Highcharts.Color(curColor).setOpacity(0).get('rgba')]
                                         ]
                                    },
                                    color: curColor,
                                });
                            } else {
                                var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);

                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    var curColor = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    var maxColor = curColor;
                                    var minColor = curColor;
                                    var opacityMax = 0.7;
                                    var opacityMin = 0;
                                } else {
                                    var curColor = curColorsObj.curColor;
                                    var maxColor = curColorsObj.maxColor;
                                    var minColor = curColorsObj.minColor;
                                    var opacityMax = curColorsObj.opacityMax;
                                    var opacityMin = curColorsObj.opacityMin;
                                }

                                window.timeSeriesDataInit.seriesData.push({
                                    name: wordsListObj[j-1].name,
                                    data: seriesData,
                                    type: "area",
                                    fillColor: {
                                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                        stops: [
                                            [0, Highcharts.Color(maxColor).setOpacity(opacityMax).get('rgba')],
                                            [1, Highcharts.Color(minColor).setOpacity(opacityMin).get('rgba')]
                                            
                                        ]
                                    },
                                    color: curColor,
                                });
                            }
                            if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                    'name': wordsListObj[j-1].name,
                                    'size': 1, //item.sizeReal,
                                    'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                    'colornew': curColor,
                                    'id': j,
                                    'fore': 0,
                                    'md5': wordsListObj[j-1].md5,
                                    'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                };
                            }
                            if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;

                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                //borderWidth: 0,
                symbolWidth: 50,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            plotOptions: {
                area: {
                    marker: {
                        enabled: enableMarker,
                        lineWidth: 1,
                        radius: 4 * circleSizeMult
                    },
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    states: {
                        hover: {
                            lineWidth: 2 * lineWidthMult
                        }
                    },
                    turboThreshold: 1000000,
                    //yAxis:-10
                },
                series: {
                    label: {
                        enabled: isSeriesLabel
                    },
                    connectNulls: connectNulls
                },
                connectNulls: connectNulls,
            },
        });
    }

    /**
     * Time series view: 11 - Area - Stacked
     */
    if (chartTimeSeriesType == 11) { //Area - Stacked
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            colors: window.timeSeriesData[chartIndex].colorsArr,
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            chart: {
                type: 'area',
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            series: window.timeSeriesData[chartIndex].dashStyles,
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%Y-%m-%d',
                    year: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    overflow: 'justify',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            data: {
                csv: data,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var newColumns = [];
                    Highcharts.each(columns, function (c, j) {
                        var curName = '';
                        var seriesData = [];
                        Highcharts.each(c, function (p, i) {
                            if (j == 0 && i > 0) {
                                window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                                //window.timeSeriesDataInit.seriesDates.push(p);
                            } else if (i == 0) {
                                curName = replaceNamesIndicators(p);
                            } else {
                                if (!isNaN(parseFloat(p))) {
                                    seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                } else {
                                    seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                }
                            }
                        });
                        if (j > 0) {
                            formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                             if (formdata.dropdownColorsSelection != 10) {
                                /*var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                );

                                window.timeSeriesDataInit.seriesData.push({
                                    name: wordsListObj[j-1].name,
                                    data: seriesData,
                                    type: "area",
                                    fillColor: {
                                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                        stops: [
                                            [0, Highcharts.Color(curColor).setOpacity(0.7).get('rgba')],
                                            [1, Highcharts.Color(curColor).setOpacity(0).get('rgba')]
                                         ]
                                    },
                                    color: curColor,
                                });*/

                                if (formdata.dropdownColorsSelection != 10) {
                                    var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                        ? formdata.changedColors[wordsListObj[j-1].md5].color
                                        : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                    );
    
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: wordsListObj[j-1].name,
                                        data: seriesData,
                                        type: "area",
                                        fillColor: {
                                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                            stops: [
                                                [0, Highcharts.Color(curColor).setOpacity(0.7).get('rgba')],
                                                [1, Highcharts.Color(curColor).setOpacity(0).get('rgba')]
                                             ]
                                        },
                                        color: curColor,
                                    });
                                } else {
                                    var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);
    
                                    if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                        var curColor = formdata.changedColors[wordsListObj[j-1].md5].color;
                                        var maxColor = curColor;
                                        var minColor = curColor;
                                        var opacityMax = 0.7;
                                        var opacityMin = 0;
                                    } else {
                                        var curColor = curColorsObj.curColor;
                                        var maxColor = curColorsObj.maxColor;
                                        var minColor = curColorsObj.minColor;
                                        var opacityMax = curColorsObj.opacityMax;
                                        var opacityMin = curColorsObj.opacityMin;
                                    }
    
                                    window.timeSeriesDataInit.seriesData.push({
                                        name: wordsListObj[j-1].name,
                                        data: seriesData,
                                        type: "area",
                                        fillColor: {
                                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                            stops: [
                                                [0, Highcharts.Color(maxColor).setOpacity(opacityMax).get('rgba')],
                                                [1, Highcharts.Color(minColor).setOpacity(opacityMin).get('rgba')]
                                                
                                            ]
                                        },
                                        color: curColor,
                                    });
                                }
                            } else {
                                var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);

                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    var curColor = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    var maxColor = curColor;
                                    var minColor = curColor;
                                    var opacityMax = 0.7;
                                    var opacityMin = 0;
                                } else {
                                    var curColor = curColorsObj.curColor;
                                    var maxColor = curColorsObj.maxColor;
                                    var minColor = curColorsObj.minColor;
                                    var opacityMax = curColorsObj.opacityMax;
                                    var opacityMin = curColorsObj.opacityMin;
                                }

                                window.timeSeriesDataInit.seriesData.push({
                                    name: wordsListObj[j-1].name,
                                    data: seriesData,
                                    type: "area",
                                    fillColor: {
                                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                        stops: [
                                            [0, Highcharts.Color(maxColor).setOpacity(opacityMax).get('rgba')],
                                            [1, Highcharts.Color(minColor).setOpacity(opacityMin).get('rgba')]
                                            
                                        ]
                                    },
                                    color: curColor,
                                });
                            }
                            if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                    'name': wordsListObj[j-1].name,
                                    'size': 1, //item.sizeReal,
                                    'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                    'colornew': curColor,
                                    'id': j,
                                    'fore': 0,
                                    'md5': wordsListObj[j-1].md5,
                                    'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                };
                            }
                            if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                //borderWidth: 0,
                symbolWidth: 50,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            tooltip: {
                split: true
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    marker: {
                        enabled: enableMarker,
                        lineWidth: 1,
                        radius: 4 * circleSizeMult
                    },
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    states: {
                        hover: {
                            lineWidth: 2 * lineWidthMult
                        }
                    },
                    turboThreshold: 100000
                },
                series: {
                    connectNulls: true,//connectNulls
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    label: {
                        enabled: isSeriesLabel
                    },
                },
            },
        });
    }

    /**
     * Time series view: 12 - Area - Spline
     */
    if (chartTimeSeriesType == 12) { //Area - Spline
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
        Highcharts.setOptions({
            global: {
                useUTC: true
            },
            time: {
                timezoneOffset: new Date().getTimezoneOffset()
            }
        });
        chartHC = Highcharts.chart('chartTimeSeriesContaner', {
            colors: window.timeSeriesData[chartIndex].colorsArr,
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: isChartMenu
                }
            },
            chart: {
                type: 'areaspline',
                events: {
                    load: function () {
                        //if (isLegend) {
                        setEventLegendItems();
                        setHiddenLegendItems();
                        //}
                        setTimeout(function () {
                            if (isWidget == 1) {
                                $('#jqxLoaderWidget').jqxLoader('close');
                            } else {
                                $('#jqxLoader').jqxLoader('close');
                            }
                            if (!isLegend) {
                                $('.highcharts-legend').css('display', 'none');
                            }
                        }, 500);
                        if (!isLegend) {
                            $('.highcharts-legend').css('display', 'none');
                        }
                    }
                },
                backgroundColor: chartBackgroundColor
            },
            series: window.timeSeriesData[chartIndex].dashStyles,
            title: {
                text: isTitle == true ? textBoxTitleTimeCharts : ' '
            },
            subtitle: isTitle == true ? {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            } : {
                text: ' ',
                useHTML: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%Y-%m-%d',
                    year: '%Y-%m-%d'
                },
                title: {
                    text: null,//'Date',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    format: xLabelsDateFormat,
                    overflow: 'justify',
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                tickInterval: intervalInDays,
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                labels: {
                    style: {
                        color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                    }
                },
                visible: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? true : axisTurnOnOffValue,
                gridLineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#e6e6e6',
                style: {
                    color: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#666666'
                },
                lineColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb',
                tickColor: ((isWidget == 1) && (axisTurnOnOffValue === false)) ? 'transparent' : '#ccd6eb'
            },
            data: {
                csv: data,
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                },
                parsed: function (columns) {
                    window.timeSeriesDataInit.seriesData = [];
                    window.timeSeriesDataInit.seriesDates = [];
                    var newColumns = [];
                    Highcharts.each(columns, function (c, j) {
                        var curName = '';
                        var seriesData = [];
                        Highcharts.each(c, function (p, i) {
                            if (j == 0 && i > 0) {
                                window.timeSeriesDataInit.seriesDates.push(prepareDate(p));
                            } else if (i == 0) {
                                curName = replaceNamesIndicators(p);
                            } else {
                                if (!isNaN(parseFloat(p))) {
                                    seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: parseFloat(p) });
                                } else {
                                    seriesData.push({ x: window.timeSeriesDataInit.seriesDates[i - 1], y: null });
                                }
                            }
                        });
                        if (j > 0) {
                            formdata.changedColors = findPopWordNewColor(wordsListObj[j-1].name, formdata.changedColors, wordsListObj[j-1].md5, chartIndex);
                            /*var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                ? formdata.changedColors[wordsListObj[j-1].md5].color
                                : getFieldValueByIndex(wordsListObj, 'color', j-1)
                            );
                            window.timeSeriesDataInit.seriesData.push({
                                name: wordsListObj[j-1].name,
                                data: seriesData,
                                type: "areaspline",
                                fillColor: {
                                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                    stops: [
                                        [0, Highcharts.Color(curColor).setOpacity(0.7).get('rgba')],
                                        [1, Highcharts.Color(curColor).setOpacity(0).get('rgba')]
                                    ]
                                },
                            });*/

                            if (formdata.dropdownColorsSelection != 10) {
                                var curColor = (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)
                                    ? formdata.changedColors[wordsListObj[j-1].md5].color
                                    : getFieldValueByIndex(wordsListObj, 'color', j-1)
                                );

                                window.timeSeriesDataInit.seriesData.push({
                                    name: wordsListObj[j-1].name,
                                    data: seriesData,
                                    type: "areaspline",
                                    fillColor: {
                                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                        stops: [
                                            [0, Highcharts.Color(curColor).setOpacity(0.7).get('rgba')],
                                            [1, Highcharts.Color(curColor).setOpacity(0).get('rgba')]
                                         ]
                                    },
                                    color: curColor,
                                });
                            } else {
                                var curColorsObj = getSentimentColor(j-1, averageValues, maxValues, minValues, formdata);

                                if (formdata.changedColors.hasOwnProperty(wordsListObj[j-1].md5)) {
                                    var curColor = formdata.changedColors[wordsListObj[j-1].md5].color;
                                    var maxColor = curColor;
                                    var minColor = curColor;
                                    var opacityMax = 0.7;
                                    var opacityMin = 0;
                                } else {
                                    var curColor = curColorsObj.curColor;
                                    var maxColor = curColorsObj.maxColor;
                                    var minColor = curColorsObj.minColor;
                                    var opacityMax = curColorsObj.opacityMax;
                                    var opacityMin = curColorsObj.opacityMin;
                                }

                                window.timeSeriesDataInit.seriesData.push({
                                    name: wordsListObj[j-1].name,
                                    data: seriesData,
                                    type: "areaspline",
                                    fillColor: {
                                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                                        stops: [
                                            [0, Highcharts.Color(maxColor).setOpacity(opacityMax).get('rgba')],
                                            [1, Highcharts.Color(minColor).setOpacity(opacityMin).get('rgba')]
                                            
                                        ]
                                    },
                                    color: curColor,
                                });
                            }


                            if (window.timeSeriesData[chartIndex].wordsListReset == true) {
                                window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] = {
                                    'name': wordsListObj[j-1].name,
                                    'size': 1, //item.sizeReal,
                                    'color': window.timeSeriesData[chartIndex].colorsArr[j-1],
                                    'colornew': curColor,
                                    'id': j,
                                    'fore': 0,
                                    'md5': wordsListObj[j-1].md5,
                                    'colorIndex': hexToDecColor(wordsListObj[j-1].md5)
                                };
                            }
                            if (window.timeSeriesData[chartIndex].wordsListColorChange == true) {
                                if (window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name] !== "undefined") {
                                    window.timeSeriesData[chartIndex].wordsList[wordsListObj[j-1].name].colornew = curColor;
                                }
                            }
                        }
                    });
                    window.timeSeriesData[chartIndex].wordsListFirst = window.timeSeriesData[chartIndex].wordsList;
                    this.columns = [];
                    window.timeSeriesData[chartIndex].plotBands = [];
                    Highcharts.each(window.timeSeriesDataInit.seriesDates, function (val, index) {
                        var dayWeek = new Date(val).getDay();
                        if (dayWeek == 0 || dayWeek == 6) {
                            var xFrom = val;
                            var xTo = val;
                            if (index == 0) {
                                xFrom = val;
                                xTo += 60 * 60 * 12 * 1000;
                            } else if (index == (window.timeSeriesDataInit.seriesDates.length - 1)) {
                                xFrom = val - 60 * 60 * 12 * 1000;
                                xTo = val;
                            } else {
                                xFrom = val - 60 * 60 * 12 * 1000;
                                xTo = val + 60 * 60 * 12 * 1000;
                            }
                            window.timeSeriesData[chartIndex].plotBands.push({ // visualize the weekend
                                from: xFrom,
                                to: xTo,
                                color: 'rgba(255, 215, 215, .3)'
                            });
                        }
                    });
                },
                complete: function (options) {
                    Highcharts.each(window.timeSeriesDataInit.seriesData, function (item, index) {
                        options.series.push(item);
                    });
                    if (isDebugg) { console.log('1611 firstLoadChart=' + firstLoadChart, isShare, hash); }
                    if (firstLoadChart == 1 && isShare && hash != '') {
                        firstLoadChart = 0;
                        isShare = false;
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    } else {
                        addWordsCheckboxesAndInfo(
                            window.timeSeriesData[chartIndex].wordsListFirst,
                            window.timeSeriesData[chartIndex].wordsListSelected,
                            CHART_TYPE_TIMESERIES
                        );
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = false;
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                //borderWidth: 0,
                symbolWidth: 50,
                enabled: true, //isLegend,
                maxHeight: (isLegend) ? 115 : 1
            },
            tooltip: {
                split: true
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    marker: {
                        enabled: enableMarker,
                        lineWidth: 1,
                        radius: 4 * circleSizeMult
                    },
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    states: {
                        hover: {
                            lineWidth: 2 * lineWidthMult
                        }
                    },
                    turboThreshold: 100000
                },
                series: {
                    connectNulls: connectNulls,
                    lineWidth: 1 * (connectorLinesAnalisys == 0 ? 0 : lineWidthMult),
                    label: {
                        enabled: isSeriesLabel
                    },
                },
                connectNulls: connectNulls,
            },
        });
        var chart = $('#chartTimeSeriesContaner').highcharts();
        Highcharts.each(window.timeSeriesData[chartIndex].plotBands, function (item, index) {
            chart.xAxis[0].addPlotBand(item);
        });
    }

    /**
     * Time series view: 13 - Area - Sparkline
     */
    if (chartTimeSeriesType == 13) { //Area - Sparkline
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
    }

    /**
     * Time series view: 14 - Area - Streamgraph
     */
    if (chartTimeSeriesType == 14) { //Area - Streamgraph
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
    }

    /**
     * Time series view: 15 - Area - Basic
     */
    if (chartTimeSeriesType == 15) { //Sankey - Basic
        var dataArr = data.split('#src_data, ');
        var newData = data + "\n";
        dataArrLength = dataArr.length;
        for (var i = 1; i < dataArr.length; i++) {
            newData = newData + dataArr[i];
        }
        $.each(window.timeSeriesData[chartIndex].dashStyles, function (dashIndex, dashItem) {
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].fillColor = undefined;
            window.timeSeriesData[chartIndex].dashStyles[dashIndex].type = "line";
        });
    }

    /*if (isLegend) {
        chartHC.update({legend: {
            enabled: true
        }});
    } else {
        chartHC.update({legend: {
            enabled: false
        }});
    }*/

    if (isTitle == true) {
        chartHC.update({
            title: {
                text: textBoxTitleTimeCharts
            }
        });
        chartHC.update({
            subtitle: {
                text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                    + '<center>' + textBoxDescriptionTimeCharts + '</cener>',
                useHTML: true
            }
        });
    } else {
        chartHC.update({
            title: {
                text: ' '
            }
        });
        chartHC.update({
            subtitle: {
                text: ' ',
                useHTML: false
            }
        });
    }
    console.timeEnd('line');
    console.timeEnd('line2');

    /* Prepare csv string for the scv file saving */
    window.timeSeriesData[chartIndex].curDataChartCsv = dataArr[0].replace(/[\w\W]+?\n+?/, "");


}

function prepareDate(value) {
    var nowDate = new Date();
    if (isNaN(value * 1)) {
        var valueDate = new Date(value /*.replace(/-/gi, "\/") + ' 00:00:00'*/);
        //console.log('value = ', value, 'valueDate = ', valueDate, 'getTimezoneOffset = ', nowDate.getTimezoneOffset()*60*1000, 'date = ', valueDate.getTime())
        var retValue = valueDate.getTime() + nowDate.getTimezoneOffset() * 60 * 1000;// - valueDateDif.getTime();//getUTCMilliseconds();
        //console.log(value, new Date(retValue));
    } else {
        var curDate = new Date(value);
        //var timeChangeDif = (curDate.getHours() - curDate.getTimezoneOffset() / 30 * (-1)) * 60; 
        //var retValue = value + (curDate.getTimezoneOffset() - timeChangeDif)*60*1000; // + valueDateDif.getTime();
        var retValue = value + nowDate.getTimezoneOffset() * 60 * 1000;
        //console.log(curDate, new Date(retValue));
    }
    return retValue;
}

function findPopWordNewColor(curName, changedColors, md5Val, chartIndex) {
    var popWordNewColor = '';
    $.each(window.customPopWordsColors, function (popWord, colorPopWord) {
        if (curName == popWord) {
            popWordNewColor = colorPopWord;
            if (typeof changedColors[md5Val] !== "undefined") {
                //formdata.changedColors[wordsListObj[j-1].md5].color = popWordNewColor;
            } else {
                changedColors[md5Val] = {};
                changedColors[md5Val].color = popWordNewColor;
                $('td.wordsColorColumn .node-color-old-value[name="' + curName + '"]').parent().find('.node-color').css('background-color', popWordNewColor);
                $('td.wordsColorColumn .node-color-old-value[name="' + curName + '"]').parent().find('.node-color-new-value').attr('value', popWordNewColor);
            }
            //window.timeSeriesData[chartIndex].wordsListReset = true;
            //firstLoadChart = 0;
        }
    });
    return changedColors;
}

function prepareMeasurePointText(dataResult, headStr) {
    var resContent = '';
    if (typeof dataResult.frequency !== "undefined") {
        headStr = headStr + ', <b>frequency:</b> ' + dataResult.frequency;
    }
    if (typeof dataResult['articles-total'] !== "undefined") {
        headStr = headStr + ', <b>articles-total:</b> ' + dataResult['articles-total'];
    }
    if (typeof dataResult['sentiment-rate'] !== "undefined") {
        headStr = headStr + ', <b>sentiment-rate:</b> ' + dataResult['sentiment-rate'].toFixed(2);
    }
    if (typeof dataResult['source-forms'] !== "undefined") {
        headStr = headStr + ', <b>source-forms:</b> ' + Object.keys(dataResult['source-forms']).length;
    }
    if (typeof dataResult.chains !== "undefined") {
        headStr = headStr + ', <b>chains:</b> ' + Object.keys(dataResult.chains).length;
    }
    headStr = '<div class="topDivMetaData">' + headStr;
    resContent = headStr;
    resContent = resContent + '<br><b>source-forms:</b> ' + Object.keys(dataResult['source-forms']).length;
    resContent = resContent + '<table class="tableMetaData" width="100%">';
    var firstRaw4 = true;
    var borderTopClass4 = '';
    resContent = resContent + '<tr>';
    resContent = resContent + '<td class="borderTopBold">';
    var sourceFormsContent = '';
    $.each(dataResult['source-forms'], function (indexSF, itemSF) {
        const itemSave = itemSF;
        sourceFormsContent = sourceFormsContent + (sourceFormsContent == '' ? '' : ', ') + '<span class="source-form-item" id="' + itemSF.replace(/ /ig, "-").replace(/'/ig, "-") + '">' + itemSave + '</span>';
    });

    resContent = resContent + sourceFormsContent;
    resContent = resContent + '</td>';
    resContent = resContent + '</tr>';
    resContent = resContent + '</table>' + '</div>';

    resContent = resContent + '<br><b>chains:</b> ' + Object.keys(dataResult.chains).length;
    resContent = resContent + '<table class="tableMetaData" width="100%">';
    var firstRaw4 = true;
    var borderTopClass4 = '';
    $.each(dataResult.chains, function (resIndex4, resItem4) {
        if (firstRaw4 == true) {
            borderTopClass4 = 'borderTopBold';
            firstRaw4 = false;
        } else {
            borderTopClass4 = 'borderTop';
        }
        resContent = resContent + '<tr>';
        if (typeof resIndex4 == "number") {
        } else {
            resContent = resContent + '<td class="' + borderTopClass4 + ' borderRight"><b>';
            resContent = resContent + '<nobr>' + resIndex4 + '</nobr>';
            resContent = resContent + '</b></td>';
        }
        resContent = resContent + '<td class="' + borderTopClass4 + '">';
        if (typeof resItem4 == "object") {
            resContent = resContent + '<table>';
            //$.each(resItem3, function(resIndex4, resItem4) {
            //});
            resContent = resContent + '</table>';
        } else {
            resContent = resContent + resItem4;
        }
        resContent = resContent + '</td>';
        resContent = resContent + '</tr>';
    });
    resContent = resContent + '</table>';

    $.each(dataResult.articles, function (resIndex2, resItem2) {
        if (typeof resItem2 != "object") {
            resContent = resContent + resItem2;
        } else {
            const resIndex2Save = resIndex2;
            resContent = resContent + '<br><b>source-form: <font color="red" id="source-form-' + resIndex2.replace(/ /ig, "-").replace(/'/ig, "-") + '">' + resIndex2Save
                + '</font>, articles: </b>' + Object.keys(resItem2).length;
            resContent = resContent + '<table class="tableMetaData" width="100%">';
            $.each(resItem2, function (resIndex3, resItem3) {
                if (typeof resItem3 == "object") {
                    var firstRaw4 = true;
                    var borderTopClass4 = '';
                    $.each(resItem3, function (resIndex4, resItem4) {
                        if (firstRaw4 == true) {
                            borderTopClass4 = 'borderTopBold';
                            firstRaw4 = false;
                        } else {
                            borderTopClass4 = 'borderTop';
                        }
                        resContent = resContent + '<tr>';
                        if (typeof resIndex4 == "number") {
                        } else {
                            resContent = resContent + '<td class="' + borderTopClass4 + ' borderRight"><b>';
                            resContent = resContent + '<nobr>' + resIndex4 + '</nobr>';
                            resContent = resContent + '</b></td>';
                        }
                        resContent = resContent + '<td class="' + borderTopClass4 + '">';
                        if (typeof resItem4 == "object") {
                            resContent = resContent + '<table>';
                            resContent = resContent + '</table>';
                        } else {
                            if (resIndex4 == 'url') {
                                var resItem4LinkText = (resItem4.length > 50) ? (resItem4.substr(0, 50) + '...') : resItem4;
                                resContent = resContent + '<a href="' + resItem4 + '" target="_blank">' + resItem4LinkText + '</a>';
                            } else {
                                resContent = resContent + resItem4;
                            }
                        }
                        resContent = resContent + '</td>';
                        resContent = resContent + '</tr>';
                    });
                } else {
                    resContent = resContent + resItem3;
                }
            });
            resContent = resContent + '</table>';
        }
    });

    return resContent;
}

function showMeasurePointDialog(htmlContent, dialogTitle, newTab) {
    try {
        $("#measurePointText").jqxWindow('destroy');
    } catch (err) { }
    try {
        $('#measurePointText').remove();
    } catch (err) { }
    $('body').append('<div id="measurePointText"></div>');

    $('#measurePointText').html('<div id="measurePointHeader"></div><div id="measurePointContent"></div>');
    $('#measurePointHeader').html(dialogTitle);
    $('#measurePointContent').html(htmlContent);
    if ((typeof newTab !== "undefined") && (newTab == true)) {
        //window.newValuesFormTab1.metadataWord = wordStr;
        $("#measurePointText").jqxWindow({
            position: { x: '0.2%', y: '0.5%' },
            maxHeight: '97%',
            maxWidth: '99.5%',
            minHeight: '97%',
            minWidth: '98%',
            height: '97%',
            width: '99.5%',
            resizable: false,
            draggable: false,
            isModal: true,
            initContent: function () {
                $('#measurePointText').jqxWindow('focus');
                $('.jqx-window-close-button-background').addClass('unvisible');
            }
        });
    
    } else {
        $("#measurePointText").jqxWindow({
            position: { x: '5%', y: '18%' },
            maxHeight: '90%',
            maxWidth: '99%',
            minHeight: '50%',
            minWidth: '50%',
            height: '70%',
            width: '90%',
            resizable: true,
            draggable: true,
            isModal: true,
            initContent: function () {
                $('#measurePointText').jqxWindow('focus');
            }
        });
    }
    $("#measurePointText").on('open', function (e) {
        var $dlg = $(e.target);
        if ($dlg.is(':visible')) {
            $dlg.focus();
        }
    });
    $('.source-form-item').on('click', function (e) {
        var container = document.getElementById('measurePointContent');
        var scrollTo = document.getElementById('source-form-' + $(this).attr('id'));
        container.scrollTop = scrollTo.offsetTop - 120;
    });
    //$('#measurePointText').jqxWindow('open');
    //initGlobalOptionsForm();
    /*$('#measurePointText').dialog({
        title: dialogTitle,
        height: 400, maxHeight: 2000, minHeight: 200,
        width: 600, maxWidth: 3000, minWidth: 300,
        modal: true,
        open: function(e, ui) {
            var $dlg = $(e.target);
            if ($dlg.is(':visible')) {
                $dlg.focus();
                console.log('set focus on', $dlg);
            }
        }
    });*/
    /*$('#measurePointText').prev().on('dblclick', function (e) {
        $('#measurePointText').dialog( "option", "width", $(window).width());
        $('#measurePointText').dialog( "option", "height", $(window).height());
    });
    $('#measurePointText').on( "dialogclose", function(event, ui) {
        $('#measurePointText').dialog("destroy");
    });*/
}

function getDataAndShowMeasurePointDialog(period, wordStr, newTab) {
    startDate = Date.now();
    var waitingFlag = 0;

    //prepare data for the metadata dialog
    if (typeof window.newValuesFormTab1.chartType == "undefined") {
        window.newValuesFormTab1.chartType = 22;
    }
    if (typeof wordStr !== "undefined") {
        window.newValuesFormTab1.metadataWord = wordStr;
    } else {
        if (typeof window.newValuesFormTab1.metadataWord == "undefined") {
            window.newValuesFormTab1.metadataWord = '';
        }
    }
    if (typeof window.newValuesFormTab1.metadataValue == "undefined") {
        window.newValuesFormTab1.metadataValue = '';
    }
    if (typeof window.newValuesFormTab1.metadataDate == "undefined") {
        window.newValuesFormTab1.metadataDate = {};
        if (typeof window.newValuesFormTab1.textBoxValueDatesRange !== "undefined") {
        } else {
            window.newValuesFormTab1.textBoxValueDatesRange = $('#inputjqxWidgetDateRange').val();
            if (typeof window.newValuesFormTab1.textBoxValueDatesRange == "undefined") {
                waitingFlag = 1;
                setTimeout(function () {
                    window.newValuesFormTab1.textBoxValueDatesRange = $('#inputjqxWidgetDateRange').val();
                }, 1000);
            }
        }
        if (waitingFlag == 1) {
            setTimeout(function () {
                if (typeof window.newValuesFormTab1.textBoxValueDatesRange == "undefined") {
                    waitingFlag = 1;
                    setTimeout(function () {
                        window.newValuesFormTab1.textBoxValueDatesRange = $('#inputjqxWidgetDateRange').val();
                        var datesArr = window.newValuesFormTab1.textBoxValueDatesRange.split(' - ');
                        var dateStartArr = datesArr[0].split('-');
                        //var setDayStart = new Date(dateStartArr[2], dateStartArr[1]-1, dateStartArr[0]);
                        window.newValuesFormTab1.metadataDate.year = dateStartArr[0];
                        window.newValuesFormTab1.metadataDate.month = dateStartArr[1];
                        window.newValuesFormTab1.metadataDate.day = dateStartArr[2];
                            }, 500);
                } else {
                    var datesArr = window.newValuesFormTab1.textBoxValueDatesRange.split(' - ');
                    var dateStartArr = datesArr[0].split('-');
                    //var setDayStart = new Date(dateStartArr[2], dateStartArr[1]-1, dateStartArr[0]);
                    window.newValuesFormTab1.metadataDate.year = dateStartArr[0];
                    window.newValuesFormTab1.metadataDate.month = dateStartArr[1];
                    window.newValuesFormTab1.metadataDate.day = dateStartArr[2];
    
                }
            }, 1000);
        } else {
            var datesArr = window.newValuesFormTab1.textBoxValueDatesRange.split(' - ');
            var dateStartArr = datesArr[0].split('-');
            //var setDayStart = new Date(dateStartArr[2], dateStartArr[1]-1, dateStartArr[0]);
            window.newValuesFormTab1.metadataDate.year = dateStartArr[2];
            window.newValuesFormTab1.metadataDate.month = dateStartArr[1];
            window.newValuesFormTab1.metadataDate.day = dateStartArr[0];
        }
    }
    if (waitingFlag == 1) {
        setTimeout(function () {
            var formData = window.newValuesFormTab1;
            getDataAndShowMeasurePointDialogSub(period, window.newValuesFormTab1.metadataWord, newTab, formData);
            waitingFlag = 0;
        }, 1500);
    } else {
        var formData = window.newValuesFormTab1;
        getDataAndShowMeasurePointDialogSub(period, window.newValuesFormTab1.metadataWord, newTab, formData);
    }

    return false;
}

function getDataAndShowMeasurePointDialogSub(period, wordStr, newTab, formData) {
    var periodDayShift = 0;
    if (period == 'current') {
        periodDayShift = 0
    } else if (period == 'next') {
        periodDayShift = 1;
    } else if (period == 'prev') {
        periodDayShift = -1;
    }
    var dateNew = new Date(formData.metadataDate.year, (formData.metadataDate.month * 1 - 1), (formData.metadataDate.day * 1 + periodDayShift));
    
    formData.metadataDate.year = dateNew.getFullYear();
    formData.metadataDate.month = dateNew.getMonth() + 1;
    formData.metadataDate.day = dateNew.getDate();

    if ((formData.metadataDate.month + '').length < 2) {
        var metadataDateMonth = '0' + formData.metadataDate.month;
    } else {
        var metadataDateMonth = formData.metadataDate.month;
    }
    if ((formData.metadataDate.day + '').length < 2) {
        var metadataDateDay = '0' + formData.metadataDate.day;
    } else {
        var metadataDateDay = formData.metadataDate.day;
    }
    window.newValuesFormTab1.metadataDate.year = formData.metadataDate.year;
    window.newValuesFormTab1.metadataDate.month = formData.metadataDate.month;
    window.newValuesFormTab1.metadataDate.day = formData.metadataDate.day;
    formData.chartType = CHART_TYPE_TEXTUALWORDSMETADATA;

    htmlContent = '<div id="jqxLoaderWindow"></div>'
        + '<b>word:</b> "<font color="red">' + formData.metadataWord + '</font>", '
        + '<b>date:</b> '
        + " <a href=\"#\" class=\"prevNextDate\" onclick=\"return getDataAndShowMeasurePointDialog('prev', '" + wordStr + "'" + ', ' + newTab + ");\"><</a> "
        + formData.metadataDate.year + '-' + metadataDateMonth + '-' + metadataDateDay
        + " <a href=\"#\" class=\"prevNextDate\" onclick=\"return getDataAndShowMeasurePointDialog('next', '" + wordStr + "'" + ', ' + newTab + ");\">></a> ";
    if ((typeof formData.metadataValue !== "undefined") && (formData.metadataValue != '')) {
        htmlContent = htmlContent + ', <b>value:</b> ' + formData.metadataValue;
    }
    var dialogTitle = 'Meta-data for word: ' + formData.metadataWord
        + ', date: ' + formData.metadataDate.year + '-' + metadataDateMonth + '-' + metadataDateDay;
    showMeasurePointDialog(htmlContent, dialogTitle, newTab);
    $('#jqxLoaderWindow').jqxLoader({ width: '98%', height: '50%', imagePosition: 'center' });

    $('#jqxLoaderWindow').jqxLoader('open');

    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        // the route pointing to the post function 
        url: '/charts-form',
        type: 'POST',
        // send the csrf-token and the input to the controller 
        data: {
            _token: CSRF_TOKEN,
            filename: 'dataChartMolecular.txt',
            formdata: formData
        },
        dataType: 'JSON',
        // remind that 'data' is the response of the AjaxController
        success: function (data) {
            $('#DebugContaner').html('');
            if ((typeof data.result == "undefined")
                || (data.result == null)
                || (data.result == "\n#debug ")
                || (data.result.length == 20)
            ) {
                $('#ErrorListContaner').text(data.errMsg);
                if (isWidget == 1) {
                    $('#jqxLoaderWindow').jqxLoader('close');
                    $('#jqxLoaderWidget').jqxLoader('close');
                } else {
                    $('#jqxLoaderWindow').jqxLoader('close');
                    $('#jqxLoader').jqxLoader('close');
                }
                showMeasurePointDialog(htmlContent, dialogTitle, newTab);
            } else {
                htmlContent = prepareMeasurePointText(data.result, htmlContent);
                showMeasurePointDialog(htmlContent, dialogTitle, newTab);
            }
            if (isWidget == 1) {
                $('#jqxLoaderWindow').jqxLoader('close');
                $('#jqxLoaderWidget').jqxLoader('close');
            } else {
                $('#jqxLoaderWindow').jqxLoader('close');
                $('#jqxLoader').jqxLoader('close');
            }
        },
        error: function (data) {
            $('#DebugContaner').html('');
            $('#ErrorListContaner').text(data.errMsg);
            if (isWidget == 1) {
                $('#jqxLoaderWindow').jqxLoader('close');
                $('#jqxLoaderWidget').jqxLoader('close');
            } else {
                $('#jqxLoaderWindow').jqxLoader('close');
                $('#jqxLoader').jqxLoader('close');
            }
            showMeasurePointDialog(htmlContent, dialogTitle, newTab);
        }
    });
    return false;
}

