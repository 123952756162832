window.streamgraphDataInit = {
    data: undefined,
    width: undefined,
    height: undefined,
    json: undefined,
    cluster: undefined,
    diagonal: undefined,
    svg: undefined,
    nodes: undefined,
    link: undefined,
    node: undefined,
    isConnectorsLines: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    stopFlag: false,
    wordsListReset: true,
    wordsListColorChange: false,
    unicItems: {},
    unicDates: {},
    allDataKeys: {},
    timeFormatter: undefined,
    margin: { top: 20, right: 20, bottom: 10, left: 50 },
    x: undefined,
    y: undefined,
    chartAreaWidth: undefined,
    chartAreaHeight: undefined,
    zoom: undefined,
    colorsArr: undefined,
    colorsDrawArr: undefined,
    dashStyles: undefined,
    sourceTitle: undefined,
    dateFrom: undefined,
    dateTo:undefined,
    addData: undefined,
    seriesData: [],
    seriesDates: [],
    plotBands: [],
    customWordsColors: {},
    changedColors: {}
};
window.streamgraphData = {};
window.streamgraphData[0] = window.streamgraphDataInit;
var isTitle = true;
var isChartMenu = false;
var dataArrLength;
var htmlContent = '';

if (typeof chartIndex === "undefined") {
    var chartIndex = 0;
}

var chartStreamgraphHC;

//CHART_TYPE_STREAMGRAPH
//curDataChartSG
/**
 * Show Streamgraph Chart
 * @param data
 * @param formdata
 * @param chartIndex
 * @returns
 */      
function showStreamgraphChart(dataChart, formdata, chartIndex) {
    console.log('line start showStreamgraphChart');
    console.time('line');
    //console.log('call showStreamgraphChart', formdata.changedColors);
    $('#dataContaner').html($('#chartStreamgraphDiv').html());
    
    isTitle = formdata.checkboxShowHideTitleValue;
    isChartMenu = formdata.checkboxShowHideMenuValue;
    
    if (typeof dataChart.result === "string") {
        var resDataAll  = JSON.parse(dataChart.result);
        var resData = resDataAll.series;
    } else {
        var resDataAll  = dataChart.result;
        var resData = resDataAll.series;
    }
    var categoriesDates = resDataAll.categories;
    
    var colors = Highcharts.getOptions().colors;
    
    var md5Arr = [];
    var wordsList = dataChart.wordsList;
    if (typeof chartIndex === "undefined") {
        var chartIndex = 0;
    }
    if (typeof dataChart.dates !== "undefined") {
        window.streamgraphData[chartIndex].dates = dataChart.dates;
    } else {
        window.streamgraphData[chartIndex].dates = [];
    }
    
    initGetColors(CHART_TYPE_STREAMGRAPH, chartIndex);

    var wordsListArr = getWordsListTL(resData);
    //console.log(wordsListArr, data);
    wordsListArr = wordsListArr.sort();
    //console.log(wordsListArr);
    
    if (typeof formdata.dropdownColorsSet === "undefined") {
        formdata.dropdownColorsSet = 0;
    }
    window.streamgraphData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
    
    if (typeof formdata.dropdownColorsSelection !== "undefined") {
        window.streamgraphData[chartIndex].dropdownColorsSelection = formdata.dropdownColorsSelection * 1;
    } else {
        window.streamgraphData[chartIndex].dropdownColorsSelection = 1;
    }
    
    //console.log('wordsListArr.length = ', wordsListArr.length, 'formdata.dropdownColorsSet = ', formdata.dropdownColorsSet);
    if (formdata.dropdownColorsSet == 0) {
        //console.log('formdata.dropdownColorsSet = ', formdata.dropdownColorsSet);
        if (wordsListArr.length <= 8) {
            window.colorsArraySetIndex = 6;
        } else if (wordsListArr.length <= 16) {
            window.colorsArraySetIndex = 7;
        } else if (wordsListArr.length <= 24) {
            window.colorsArraySetIndex = 8;
        } else if (wordsListArr.length <= 32) {
            window.colorsArraySetIndex = 9;
        } else {
            window.colorsArraySetIndex = 1;
        }
    } else {
        window.streamgraphData[chartIndex].dropdownColorsSet = formdata.dropdownColorsSet * 1;
        window.colorsArraySetIndex = formdata.dropdownColorsSet * 1;
    }

    var wordsListObj = [];
    for (var i = 0; i < wordsListArr.length; i++) {
        var md5 = CryptoJS.MD5(wordsListArr[i]).toString();
        var curColor = getColor(hexToDecColor(md5), undefined, undefined, md5, wordsListArr[i],
        i, formdata);
        wordsListObj.push({'name': wordsListArr[i], 'md5': md5, 'color': curColor});
    }

    if ((formdata.dropdownColorsSet == 0) && (window.streamgraphData[chartIndex].dropdownColorsSelection == 1)) {
        var wordsListColorsAuto = getAutoUnicColorsForWordsList(wordsListArr, CHART_TYPE_STREAMGRAPH, chartIndex, undefined, undefined, undefined);
    }

    if (Object.keys(window.streamgraphData[chartIndex].wordsList).length == 0) {
        window.streamgraphData[chartIndex].wordsListReset = true;
    }
    if (window.streamgraphData[chartIndex].wordsListReset == true
        || typeof window.streamgraphData[chartIndex].colorsArr === "undefined"
        || window.streamgraphData[chartIndex].colorsArr.length == 0) {
        window.streamgraphData[chartIndex].colorsArr = [];
        window.streamgraphData[chartIndex].colorsDrawArr = [];
    }
    if (Object.keys(window.streamgraphData[chartIndex].wordsList).length == 0) {
        window.streamgraphData[chartIndex].wordsListReset = true;
    }

    if (Object.keys(window.streamgraphData[chartIndex].wordsList).length != wordsListArr.length) {
        window.streamgraphData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    if (window.streamgraphData[chartIndex].wordsListReset == true) {
        window.streamgraphData[chartIndex].wordsListFirst = {};
        window.streamgraphData[chartIndex].wordsList = {};
    }
    if (typeof formdata.wordsList !== "undefined") {
        window.streamgraphData[chartIndex].wordsListSelected = formdata.wordsList;
    }
    
    $.each(resData, function(index, item) {
        md5Arr.push({'md5': CryptoJS.MD5(item.name).toString(), 'name': item.name});
    });
    //console.log('md5Arr ', md5Arr);
    
    curDataChartSG = dataChart;
    var addData = dataChart.addData;
    if (typeof addData !== "undefined") {
        addData = JSON.parse(addData);
        window.streamgraphData[chartIndex].addData = addData;
    } else {
        addData = window.streamgraphData[chartIndex].addData;
        if (typeof addData == "undefined") {
            addData = {};
        }
    }
    
    if (Object.keys(md5Arr).length == 0) {
        var md5ArrIsEmpty = true;
    } else {
        var md5ArrIsEmpty = false;
    }

    var newData = [];
    var colorsArr = [];
    var curDataChartCsv = '';
    var i = 0;
    //window.timelineData[chartIndex].curDataChartCsv
    $.each(resData, function(index1, item1) {
        var newItemData = [];
        curDataChartCsv = curDataChartCsv + item1.name + ',';
        $.each(item1.data, function(index2, item2) {
            //resData[index1].data[index2] = item2 * 1;
            if ((index2 == (item1.data.length -1))) {
                var endStr = "\n";
            } else {
                var endStr = ",";
            }
            curDataChartCsv = curDataChartCsv + item2 + endStr;
        });
        i++;
    });
    
    window.streamgraphData[chartIndex].curDataChartCsv = curDataChartCsv;
    
    if (typeof wordsListObj !== "undefined") {
        if ((window.streamgraphData[chartIndex].wordsListReset == true) || (window.streamgraphData[chartIndex].colorsArr.length == 0)) {
            window.streamgraphData[chartIndex].colorsArr = [];
            window.streamgraphData[chartIndex].colorsDrawArr = [];
            var curColor = 'ffffff';
            for (var i = 0 ; i < wordsListObj.length; i++) {
                if (firstLoadChart == 1 && formDataStr != '') {
                    curColor = formdata.curWordColor;
                    formDataStr = '';
                } else {
                    //console.log(wordsListObj[i]);
                    if (typeof formdata.changedColors[wordsListObj[i].md5] !== "undefined") {
                        curColor = formdata.changedColors[wordsListObj[i].md5].color;
                    } else {
                        if (formdata.dropdownColorsSelection == '1') {
                            curColor = getColor(hexToDecColor(wordsListObj[i].md5), undefined, undefined, wordsListObj[i].md5,
                                wordsListObj[i].name, i, formdata);
                        } else {
                            //curColor = getColor(i);
                            curColor = getColor(i, undefined, undefined, wordsListObj[i].md5,
                                wordsListObj[i].name, i, formdata);
                        }
                    }
                }

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[i].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[i].name];
                } else {
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    //var curColor = getColor(i);
                }

                window.streamgraphData[chartIndex].colorsArr[i] = curColor;
                window.streamgraphData[chartIndex].colorsDrawArr[i] = curColor;
            }
            //console.log(wordsListObj, window.timelineData[chartIndex].colorsArr);
            for (var j = 0; j < wordsListObj.length; j++) {
                var curName = wordsListObj[j].name;
                //formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j], chartIndex);
                var curColor = ((typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined")
                    ? (formdata.changedColors[wordsListObj[j].md5].color)
                    : (
                        window.streamgraphData[chartIndex].colorsArr[j]
                    )
                );
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colorsArr[j] = curColor;
                }
                var colornew = window.streamgraphData[chartIndex].colorsArr[j];
                var colornewIsChange = 0;
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colornew = formdata.changedColors[wordsListObj[j].md5].color;
                    window.streamgraphData[chartIndex].colorsDrawArr[j] = colornew;
                    colornewIsChange = 1;
                }
                formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j].md5, chartIndex);

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[j].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[j].name];
                } else {
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    //var curColor = getColor(j);
                }

                if (window.streamgraphData[chartIndex].wordsListReset == true) {
                    window.streamgraphData[chartIndex].wordsList[curName] = {
                        'name': curName,
                        'size': 1, //item.sizeReal,
                        'color': window.streamgraphData[chartIndex].colorsArr[j],
                        'colornew': curColor,
                        'id': j,
                        'fore': 0,
                        'md5': wordsListObj[j].md5,
                        'colorIndex': hexToDecColor(wordsListObj[j].md5)
                    };
                }
                //if (window.timelineData[chartIndex].wordsListColorChange == true) {
                    if (window.streamgraphData[chartIndex].wordsList[curName] !== "undefined") {
                        window.streamgraphData[chartIndex].wordsList[curName].colornew = curColor;
                    }
                //}
                window.streamgraphData[chartIndex].colorsArr[j] = curColor;
                window.streamgraphData[chartIndex].colorsDrawArr[j] = curColor;
            }
            window.streamgraphData[chartIndex].wordsListFirst = window.streamgraphData[chartIndex].wordsList;
        } else if (typeof formdata.changedColors !== "undefined") {
            for (var j = 0; j < wordsListObj.length; j++) {
                var curName = wordsListObj[j].name;
                formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j].md5, chartIndex);
                var curColor = ((typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined")
                    ? formdata.changedColors[wordsListObj[j].md5].color
                    : (
                        window.streamgraphData[chartIndex].colorsArr[j]
                    )
                );
                
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    curColor = formdata.changedColors[wordsListObj[j].md5].color;
                } else {
                    if (formdata.dropdownColorsSelection == '1') {
                        //curColor = getColor(hexToDecColor(wordsListObj[j].md5), undefined, undefined, wordsListObj[j].md5);
                        curColor = getColor(hexToDecColor(wordsListObj[j].md5), undefined, undefined, wordsListObj[j].md5,
                            wordsListObj[j].name, j, formdata);
                    } else {
                        //curColor = getColor(j);
                        curColor = getColor(j, undefined, undefined, wordsListObj[j].md5,
                            wordsListObj[j].name, j, formdata);
                    }
                }
                
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colorsArr[j] = curColor;
                }
                var colornew = window.streamgraphData[chartIndex].colorsArr[j];
                var colornewIsChange = 0;
                if (typeof formdata.changedColors[wordsListObj[j].md5] !== "undefined") {
                    colornew = formdata.changedColors[wordsListObj[j].md5].color;
                    window.streamgraphData[chartIndex].colorsDrawArr[j] = colornew;
                    colornewIsChange = 1;
                }
                formdata.changedColors = findPopWordNewColor(curName,formdata.changedColors, wordsListObj[j].md5, chartIndex);

                if ((formdata.dropdownColorsSelection != 10) 
                    && (typeof window.customPopWordsColors[wordsListObj[j].name] !== "undefined")) {
                    var curColor = window.customPopWordsColors[wordsListObj[j].name];
                } else {
                    //var resColor = hexToRGB(getColor(d.colorIndex, undefined, undefined, itemVal), window.dendrogramHorizontalData[chartIndex].transparentLevel);
                    //var curColor = getColor(j);
                }

                if (window.streamgraphData[chartIndex].wordsListReset == true) {
                    window.streamgraphData[chartIndex].wordsList[curName] = {
                        'name': curName,
                        'size': 1, //item.sizeReal,
                        'color': window.streamgraphData[chartIndex].colorsArr[j],
                        'colornew': curColor,
                        'id': j,
                        'fore': 0,
                        'md5': wordsListObj[j].md5,
                        'colorIndex': hexToDecColor(wordsListObj[j].md5)
                    };
                }
                //if (window.timelineData[chartIndex].wordsListColorChange == true) {
                    if (window.streamgraphData[chartIndex].wordsList[curName] !== "undefined") {
                        window.streamgraphData[chartIndex].wordsList[curName].colornew = curColor;
                    }
                //}
                window.streamgraphData[chartIndex].colorsArr[j] = curColor;
                window.streamgraphData[chartIndex].colorsDrawArr[j] = curColor;
            }
            window.streamgraphData[chartIndex].wordsListFirst = window.streamgraphData[chartIndex].wordsList;
        }
    }

    if (typeof formdata.dropdownValueVIndicator !== "undefined") {
        var valueVIndicator = getValueIndicatorTextById(formdata.dropdownValueVIndicator);
    } else {
        var valueVIndicator = getValueIndicatorTextById(valueDefault);
    }
    
    //Start title block
    if (typeof addData.sourceTitle !== "undefined") {
        window.streamgraphData[chartIndex].sourceTitle = addData.sourceTitle;
    }
    if (typeof addData.dateFrom !== "undefined") {
        window.streamgraphData[chartIndex].dateFrom = addData.dateFrom;
    }
    if (typeof addData.dateTo !== "undefined") {
        window.streamgraphData[chartIndex].dateTo = addData.dateTo;
    }
    
    if (data.countDates <= 31) {
        var intervalInDays = 24 * 3600 * 1000;
    } else {
        var intervalInDays = undefined;
    }

    if (typeof formdata.textBoxTitleTimeCharts !== "undefined") {
        var textBoxTitleTimeCharts = formdata.textBoxTitleTimeCharts;
    } else {
        var textBoxTitleTimeCharts = '';
    }
    var dataSourceLabel = '';
    if (typeof formdata.dropdownValueDSource !== "undefined") {
        $.each(DataSelectOptions, function(index, item) {
            if (item.value == formdata.dropdownValueDSource) {
                dataSourceLabel = item.label;
            }
        });
    }
    var chartViewLabel = '';
    
    textBoxTitleTimeCharts = textBoxTitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.streamgraphData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.streamgraphData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxSubtitleTimeCharts !== "undefined") {
        var textBoxSubtitleTimeCharts = formdata.textBoxSubtitleTimeCharts;
    } else {
        var textBoxSubtitleTimeCharts = 'Data source: %DATA_SOURCE% (%CHART_VIEW%)';
    }
    textBoxSubtitleTimeCharts = textBoxSubtitleTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.streamgraphData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.streamgraphData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    if (typeof formdata.textBoxDescriptionTimeCharts !== "undefined") {
        var textBoxDescriptionTimeCharts = formdata.textBoxDescriptionTimeCharts;
    } else {
        var textBoxDescriptionTimeCharts = 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%';
    }
    textBoxDescriptionTimeCharts = textBoxDescriptionTimeCharts
        .replace('%VALUE_INDICATOR%', valueVIndicator)
        .replace('%DATE_FROM%', window.streamgraphData[chartIndex].dateFrom)
        .replace('%DATE_TO%', window.streamgraphData[chartIndex].dateTo)
        .replace('%DATA_SOURCE%', dataSourceLabel)
        .replace('%CHART_VIEW%', chartViewLabel)
        .replace('()', '');
    //end ttle block

    
    $('#chartStreamgraphContaner .chart svg').remove();
    $('#chartStreamgraphContaner .legend').html('');
    
    window.streamgraphData[chartIndex].margin = { top: 20, right: 10, bottom: 10, left: 10 };
    window.streamgraphData[chartIndex].width = 
        $('#dataContaner').parent().width() - window.streamgraphData[chartIndex].margin.left 
        - window.streamgraphData[chartIndex].margin.right ;
    if (isWidget == 0) {
        window.streamgraphData[chartIndex].height =
            $('#dataContaner').parent().height() - window.streamgraphData[chartIndex].margin.top 
            - window.streamgraphData[chartIndex].margin.bottom;
    } else {
        window.streamgraphData[chartIndex].height = $('#dataContaner').parent().height() - 20;
    }
   
    if (firstLoadChart == 1 && isShare && hash != '') {
        //firstLoadChart = 0;
        isShare = false;
        addWordsCheckboxesAndInfo(
            window.streamgraphData[chartIndex].wordsListFirst,
            window.streamgraphData[chartIndex].wordsListSelected,
            CHART_TYPE_STREAMGRAPH
        );
    } else {
        //firstLoadChart = 0;
        addWordsCheckboxesAndInfo(
            window.streamgraphData[chartIndex].wordsListFirst,
            window.streamgraphData[chartIndex].wordsListSelected,
            CHART_TYPE_STREAMGRAPH
        );
    }
    window.streamgraphData[chartIndex].wordsListReset = false;
    //console.log(resData);
    $.each(resData, function(index1, item1) {
        //for (var i = 0 ; i < wordsListObj.length; i++) {
            resData[index1].color = window.streamgraphData[chartIndex].wordsList[item1.name].color;
        //}
    });

    Highcharts.setOptions({
        global: {
            useUTC:  true
        },
        time: {
            timezoneOffset: new Date().getTimezoneOffset()
        }
    });
    
    chartStreamgraphHC = Highcharts.chart('chartStreamgraphContaner', {
        chart: {
            type: 'streamgraph',
            marginBottom: 70,
            zoomType: 'x',
            events: {
                load: function () {
                    setTimeout(function () {
                        if (isWidget == 1) {
                            $('#jqxLoaderWidget').jqxLoader('close');
                        } else {
                            $('#jqxLoader').jqxLoader('close');
                        }
                    }, 1000);
                }
            },
            //backgroundColor: chartBackgroundColor
            height: window.streamgraphData[chartIndex].height,
            width: window.streamgraphData[chartIndex].width
        },
    
        // Make sure connected countries have similar colors
        colors: window.streamgraphData[chartIndex].colorsArr,
        /*[
            colors[0],
            colors[1],
            colors[2],
            colors[3],
            colors[4],
            // East Germany, West Germany and Germany
            Highcharts.color(colors[5]).brighten(0.2).get(),
            Highcharts.color(colors[5]).brighten(0.1).get(),
    
            colors[5],
            colors[6],
            colors[7],
            colors[8],
            colors[9],
            colors[0],
            colors[1],
            colors[3],
            // Soviet Union, Russia
            Highcharts.color(colors[2]).brighten(-0.1).get(),
            Highcharts.color(colors[2]).brighten(-0.2).get(),
            Highcharts.color(colors[2]).brighten(-0.3).get()
        ],*/

        data: {
            //csv: newData,
            boostThreshold: 0,
            turboThreshold: 0,

            complete: function (options) {
                //Highcharts.each(window.timeSeriesDataInit.seriesData, function(item, index) {
                //    options.series.push(item);
                //});
                if (isDebugg) {console.log('1611 firstLoadChart='+firstLoadChart, isShare, hash);}
                if (firstLoadChart == 1 && isShare && hash != '') {
                    firstLoadChart = 0;
                    isShare = false;
                    //console.log('addWordsCheckboxesAndInfo 1');
                    addWordsCheckboxesAndInfo(
                            window.streamgraphData[chartIndex].wordsListFirst,
                            window.streamgraphData[chartIndex].wordsListSelected,
                            CHART_TYPE_STREAMGRAPH
                        );
                } else {
                    //console.log('addWordsCheckboxesAndInfo 2');
                    addWordsCheckboxesAndInfo(
                        window.streamgraphData[chartIndex].wordsListFirst,
                        window.streamgraphData[chartIndex].wordsListSelected,
                        CHART_TYPE_STREAMGRAPH
                    );
                }
                window.streamgraphData[chartIndex].wordsListReset = false;
            }
        },
        credits:{
            enabled: false
        },
        navigation: {
            buttonOptions: {
                enabled: isChartMenu
            }
        },
        title: {
            text: isTitle == true ? textBoxTitleTimeCharts : ' '
        },
        subtitle: isTitle == true ? {
            text: '<center>' + textBoxSubtitleTimeCharts + '</cener><br>'
                +'<center>' + textBoxDescriptionTimeCharts + '</cener>',
            useHTML: true
        } : {
            text: ' ',
            useHTML: false
        },

        xAxis: {
            maxPadding: 100,
            type: 'category',
            crosshair: true,
            categories: categoriesDates,
            /*labels: {
                align: 'right',
                reserveSpace: false,
                rotation: 270
            },*/
            labels: {
                useHTML:true,//set to true
                style:{
                    width:'60px',
                    //whiteSpace:'nowrap'//set to normal
                },
                  //padding: '90px',
                align: 'right',
                //reserveSpace: false,
                rotation: 270,
                //step: 1,
                formatter: function () {//use formatter
                    return '<div align="left">' + this.value + '</div>';
                }
            },
            
            lineWidth: 0,
            //margin: 20,
            tickWidth: 0
        },
    
        yAxis: {
            visible: false,
            startOnTick: false,
            endOnTick: false
        },
    
        legend: {
            enabled: false
        },
        /*
        annotations: [{
            labels: [{
                point: {
                    x: 5.5,
                    xAxis: 0,
                    y: 30,
                    yAxis: 0
                },
                text: 'Cancelled<br>during<br>World War II'
            }, {
                point: {
                    x: 18,
                    xAxis: 0,
                    y: 90,
                    yAxis: 0
                },
                text: 'Soviet Union fell,<br>Germany united'
            }],
            labelOptions: {
                backgroundColor: 'rgba(255,255,255,0.5)',
                borderColor: 'silver'
            }
        }],
        */
        plotOptions: {
            series: {
                label: {
                    minFontSize: 6,
                    maxFontSize: 10,
                    style: {
                        //color: 'rgba(255,255,255,0.75)'
                    },
                    //onArea: false,
                    boxesToAvoid: [{
                        left: 20,
                        top: 0,
                        right: 20,
                        bottom: 100,

                    }],
                  },

                boostThreshold: 0,
                turboThreshold: 0,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e) {
                            if (dbDataType == 1) {
                                var addTwitterIcon = '<a href="'
                                    + twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name)
                                    + '" target="_blank"><img src="/images/icon_Twitter.png"></a>';
                                window.open(twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", this.series.name));
                            } else {
                                if (isWidget == 1) {
                                    $('#jqxLoaderWidget').jqxLoader('open');
                                } else {
                                    $('#jqxLoader').jqxLoader('open');
                                }
                                if (!isLegend) {
                                    $('.highcharts-legend').css('display', 'none');
                                }
                                var pointDate = this.category.split('-');
                                var formData = window.newValuesFormTab1;
                                formData.chartType = 25;
                                formData.metadataWord = this.series.name;
                                formData.metadataValue = this.y;
                                formData.metadataDate = {};
                                formData.metadataDate.year = pointDate[0];
                                formData.metadataDate.month = pointDate[1];
                                formData.metadataDate.day = pointDate[2];
                                getDataAndShowMeasurePointDialog('current');
                            }
                        }
                    }
                }
            }
        },
    
        // Data parsed with olympic-medals.node.js
        series: resData,
        responsive: {
            rules: [{
                chartOptions:undefined,
                condition: {
                    callback:undefined,
                    maxHeight:undefined,
                    maxWidth:undefined,
                    minHeight:0,
                    minWidth: window.streamgraphData[chartIndex].width
                }
            }]
        },
        /*dataLabels:
        [{
            allowOverlap: true,
            crop: false,
            overflow: "allow"
        }],*/
        exporting: {
            sourceWidth: window.streamgraphData[chartIndex].width,
            sourceHeight: window.streamgraphData[chartIndex].height
        }
    
    });

    console.timeEnd('line');
    
}
