window.colorsArraySet1 = [
    "#FF1493",// 0. 
    
    "#FF0000", //Red
    "#0000FF", //Blue
    "#ff7700", //Orange
    "#fff100", //Yellow
    "#FF00FF", //Magenta
    "#800080", //Purple
    "#18A018", //Green
    "#660000", //Maroon
    
    "#ADFF2F", //GreenYellow
    "#FF69B4", //HotPink
    "#CC9900", //Salmon
    "#9933FF", //Violet
    "#003400", //DarkGreen
    "#990000", //Navy
    "#00FFFF", //Cyan
    "#663311", //Choolate

    "#1E90FF", //DodgerBlue
    "#CF4A83", //Melon
    "#008080", //Teal
    "#FF4500", //OrangeRed
    "#440044", //DarkViolet
    "#808000", //Olive
    "#000055", //DarkBlue
    "#00FA9A", //MediumSpringGreen
    
    "#AA2C50", //Red
    "#333399", //Blue
    "#C65605", //Orange
    "#B8860B", //Yellow
    "#A71585", //Magenta
    "#7500B5", //Purple
    "#2B7048", //Green
    "#FF0066", //Pink
    
    "#53AFED", //1. Blue
    "#FC6A6A", //2. Red
    "#FFF572", //3. Yellow
    "#5CD65C", //4. Green
    "#FF0099", //5. Magenta m
    "#3333FF", //6. Blue m
    "#DFFF7F", //7. GreenYellow
    "#FFAA66", //8. #Orange
    
    //1
    "#9400D3",// 1. Violet m
    "#0000FF",// 2. Blue m
    "#00FFFF",// 3. Cyan l
    "#18A018",// 4. Green m
    "#FFFF00",// 5. Yellow m
    "#FF0000",// 6. Red m
    "#FF00FF",// 7. Magenta m
    "#FF6600",// 8. Orange m
    "#008B8B",// 9. SeaGreen
    "#808000",//10. Olive m
    //11
    "#8B0000",//11. Red d
    "#EE82EE",//12. Magenta l
    "#7FFF00",//13. GreenYellow l
    "#6495ED",//14. Blue l
    "#006400",//15. Green d 
    "#1E1159",//16. Blue  dd
    "#FFCC00",//17. Orange l
    "#D2D817",//18. Lime
    "#F73302",//19. Orange
    "#FF9B9B",//20. Red l
    //21
    "#11B5DD",//21. Blue l1
    "#FF0033",//22. Red l
    "#CC3333",//23. Orange d
    "#0174B2",//24. Blue l2
    "#1c5300",//25. Green m 
    "#CC00CC",// Magenta d
    "#CC0000",// Red d
    "#09C6A4",// BlueGreen
    "#2c3500",// Olive d
    "#FF99CC",// Pink l
    //31
    "#1d71c6",// Blue m
    "#9933CC",// Violet m
    "#1c5300",// Green d
    "#FF00CC",// Pink d
    "#FC8046",// Orange l
    "#04CC04",// GreenYellow l
    "#990033",// Brick d
    "#2045FC",// Blue m
    "#CC0099",// Magenta m
    "#541700",// Brown
    //41
    "#FF66FF",// Pink m
    "#CC3366",// Brick d
    "#FF33FF",// Magenta l
    "#fd7854",// Orange m
    "#CC3399",// Pink d
    "#CC66CC",// Pink m
    "#FF0099",// Magenta m
    "#9ACD32",// GreenYellow m
    "#51af23",//26. Green m
    "#3333FF",
    //51
    "#990099",// Magenta d2
    "#33FF66",// Green l
    "#993399",// Purple d
    "#CC66FF",// Purple m
    "#444cde",// BlueViolet
    "#CC33FF",// Pink d2
    "#FF6633",// Orange m
    "#CC00FF",// Magenta d3
    "#9900CC",// Violet m
    "#FF6699",// Red l
    //61
    "#660066",// Violet d
    "#9933CC",// Violet m
    "#66FF33",// GreenYellow l
    "#CC33CC",// Pink d
    "#990066",// Purple d

    "#9900FF",// Violet l
    "#FF0066",// Pink d
    "#660099",// Violet m
    "#9966FF",// Violet 
    "#993366",// Purple d
    "#6600CC",// Violet m
    "#663399",// Violet m
    "#330033",// Violet d
    "#9999FF",// BlueViolet
    "#CC0066",// Red m
    "#CC3300",// Brick m
    "#6633FF",// Violet m
    "#72da72",// GreenYellow m
    "#330099",// Violet d
    "#6666FF",// BlueViolet m
    "#3333FF",// Blue m
    "#CC6699",// Purple l
    "#3300FF",// Blue d
    "#3300CC",// Blue dd
    "#3333CC",// Blue m
    "#000099",// Blue dd
    "#FF66CC",// Pink m
    "#33FF99",// GreenYellow m
    "#6699FF",// Blue ll
    "#3366FF",// Blue l
    "#0033CC",// Blue m
    "#000033",// Black
    "#9370DB",// Purple m
    "#FF3399",// Pink d
    "#0066FF",
    "#0066CC",
    "#3366CC",
    "#0033FF",
    "#003399",
    "#99CCFF",// blue l
    "#3399FF",
    "#0099FF",
    "#6699CC",
    "#336699",
    "#006699",
    "#66CCFF",
    "#33CCFF",
    "#3399CC",
    "#FF8C00",
    "#0099CC",
    "#003333",
    "#66CCCC",
    "#339999",
    "#006666",
    "#336666",
    "#00CCCC",
    "#66FFCC",
    "#FF9933",
    "#00FFCC",
    "#33CCCC",
    "#009999",
    "#66CC99",
    "#00CC99",
    "#339966",
    "#009966",
    "#006633",
    "#66FF99",
    "#CC0033",
    "#00FF99",
    "#00CC66",
    "#009933",
    "#0000CC",
    "#66FF66",
    "#00FF66",
    "#339933",
    "#006600",
    "#00CC33",
    "#66FF00",
    "#33FF00",
    "#33CC00",
    "#339900",
    "#009900",
    "#99FF66",
    "#66CC00",
    "#66CC33",
    "#669933",
    "#336600",
    "#99FF33",
    "#99CC66",
    "#99CC00",
    "#99CC33",
    "#669900",
    "#CCFF00",
    "#CCCC33",
    "#999900",
    "#5753c5",
    "#0099bf",
    "#188018",
    "#2e8c00",
    "#ab511f",
    "#d583ff",
    "#a7a4ff",
    "#82d3ff",
    "#76eaff",
    "#85ed85",
    "#8deb5f",
    "#c2d653",
    "#fcb75c",
    "#ffc66d",
    "#ffb39e",
    "#ff9de5",
    "#2BCBBA",
    "#9fb2ff",
    "#e36920",
    "#d34e2a",
    "#ec3b24",
    "#ba3d99",
    "#9d45c9",
    "#4f5aec",
    "#615dcf",
    "#3286cf",
    "#00abca",
    "#279227",
    "#3a980c",
    "#6c7f00",
    "#542800",
    "#33CC99",
    "#721f00",
    "#66004b",
    "#5c0488",
    "#0626a5",
    "#201c8e",
    "#1d3876",
    "#005d6e",
    "#005400",
    "#451904",
    "#5d1f0c",
    "#490036",
    "#48036c",
    "#051e81",
    "#3531a3",
    "#1d4892",
    "#006f84",
    "#036b03",
    "#c8210a",
    "#950f74",
    "#7b23a7",
    "#263dd4",
    "#0b0779",
    "#004b59",
    "#004800",
    "#164000",
    "#401a02",
    "#763700",
    "#9f241e",
    "#982c0e",
    "#a81300",
    "#650d90",
    "#082fca",
    "#4642b4",
    "#1d5cac",
    "#00849c",
    "#0e760e",
    "#686868",
    "#c36806",
    "#c85120",
    "#bf3624",
    "#df2512",
    "#aa2288",
    "#933bbf",
    "#ff911a",
    "#fc8120",
    "#fa5236",
    "#ca4da9",
    "#a74fd3",
    "#5a68ff",
    "#6d69db",
    "#489bd9",
    "#00bcde",
    "#36a436",
    "#47a519",
    "#798d0a",
    "#c1a120",
    "#fd8c25",
    "#f36e4a",
    "#fc6148",
    "#d75ab6",
    "#b25ade",
    "#6575ff",
    "#7b77e9",
    "#4ea8ec",
    "#00d0f5",
    "#4eb94e",
    "#8b9f1c",
    "#d0853a",
    "#ffc51f",
    "#f86b5a",
    "#e467c3",
    "#bd65e9",
    "#7183ff",
    "#55b6ff",
    "#10dcff",
    "#51cd51",
    "#5cba2e",
    "#9eb22f",
    "#ffd03b",
    "#ff8a6a",
    "#ff7e7e",
    "#ef72ce",
    "#c56df1",
    "#8091ff",
    "#918dff",
    "#69caff",
    "#3ee1ff",
    "#71cf43",
    "#abbf3c",
    "#e6c645",
    "#eda04e",
    "#ff987c",
    "#ff8f8f",
    "#fb7eda",
    "#ce76fa",
    "#9c98ff",
    "#64e7ff",
    "#f9ad58",
    "#ffe651",
    "#ffbf51",
    "#ff9d9e",
    "#ff8de1",
    "#b2afff",
    "#8ddaff",
    "#97f569",
    "#cde153",
    "#e2a9ff",
    "#76eaff",
];
window.colorsArraySet2 = [
    "#FF1493",// 0. 
    //91
    "#0033CC",// Blue m
    "#000033",// Black
    "#9370DB",// Purple m
    "#FF3399",// Pink d
    "#0066FF",
    "#0066CC",
    "#3366CC",
    "#0033FF",
    "#003399",
    "#99CCFF",// blue l
    //81
    "#3333FF",// Blue m
    "#CC6699",// Purple l
    "#3300FF",// Blue d
    "#3300CC",// Blue dd
    "#3333CC",// Blue m
    "#000099",// Blue dd
    "#FF66CC",// Pink m
    "#33FF99",// GreenYellow m
    "#6699FF",// Blue ll
    "#3366FF",// Blue l
    //71
    "#6600CC",// Violet m
    "#663399",// Violet m
    "#330033",// Violet d
    "#9999FF",// BlueViolet
    "#CC0066",// Red m
    "#CC3300",// Brick m
    "#6633FF",// Violet m
    "#72da72",// GreenYellow m
    "#330099",// Violet d
    "#6666FF",// BlueViolet m
    //61
    "#660066",// Violet d
    "#9933CC",// Violet m
    "#66FF33",// GreenYellow l
    "#CC33CC",// Pink d
    "#990066",// Purple d
    "#9900FF",// Violet l
    "#FF0066",// Pink d
    "#660099",// Violet m
    "#9966FF",// Violet 
    "#993366",// Purple d
    //51
    "#990099",// Magenta d2
    "#33FF66",// Green l
    "#993399",// Purple d
    "#CC66FF",// Purple m
    "#444cde",// BlueViolet
    "#CC33FF",// Pink d2
    "#FF6633",// Orange m
    "#CC00FF",// Magenta d3
    "#9900CC",// Violet m
    "#FF6699",// Red l
    //41
    "#FF66FF",// Pink m
    "#CC3366",// Brick d
    "#FF33FF",// Magenta l
    "#fd7854",// Orange m
    "#CC3399",// Pink d
    "#CC66CC",// Pink m
    "#FF0099",// Magenta m
    "#9ACD32",// GreenYellow m
    "#51af23",//26. Green m
    "#3333FF",
    //31
    "#1d71c6",// Blue m
    "#9933CC",// Violet m
    "#1c5300",// Green d
    "#FF00CC",// Pink d
    "#FC8046",// Orange l
    "#04CC04",// GreenYellow l
    "#990033",// Brick d
    "#2045FC",// Blue m
    "#CC0099",// Magenta m
    "#541700",// Brown
    //21
    "#11B5DD",//21. Blue l1
    "#FF0033",//22. Red l
    "#CC3333",//23. Orange d
    "#0174B2",//24. Blue l2
    "#1c5300",//25. Green m 
    "#CC00CC",// Magenta d
    "#CC0000",// Red d
    "#09C6A4",// BlueGreen
    "#2c3500",// Olive d
    "#FF99CC",// Pink l
    //11
    "#8B0000",//11. Red d
    "#EE82EE",//12. Magenta l
    "#7FFF00",//13. GreenYellow l
    "#6495ED",//14. Blue l
    "#006400",//15. Green d 
    "#1E1159",//16. Blue  dd
    "#FFCC00",//17. Orange l
    "#D2D817",//18. Lime
    "#F73302",//19. Orange
    "#FF9B9B",//20. Red l
    //1
    "#9400D3",// 1. Violet m
    "#0000FF",// 2. Blue m
    "#00FFFF",// 3. Cyan l
    "#18A018",// 4. Green m
    "#FFFF00",// 5. Yellow m
    "#FF0000",// 6. Red m
    "#FF00FF",// 7. Magenta m
    "#FF6600",// 8. Orange m
    "#008B8B",// 9. SeaGreen
    "#808000",//10. Olive m
    //101
    "#3399FF",
    "#0099FF",
    "#6699CC",
    "#336699",
    "#006699",
    "#66CCFF",
    "#33CCFF",
    "#3399CC",
    "#FF8C00",
    "#0099CC",
    "#003333",
    "#66CCCC",
    "#339999",
    "#006666",
    "#336666",
    "#00CCCC",
    "#66FFCC",
    "#FF9933",
    "#00FFCC",
    "#33CCCC",
    "#009999",
    "#66CC99",
    "#00CC99",
    "#339966",
    "#009966",
    "#006633",
    "#66FF99",
    "#CC0033",
    "#00FF99",
    "#00CC66",
    "#009933",
    "#0000CC",
    "#66FF66",
    "#00FF66",
    "#339933",
    "#006600",
    "#00CC33",
    "#66FF00",
    "#33FF00",
    "#33CC00",
    "#339900",
    "#009900",
    "#99FF66",
    "#66CC00",
    "#66CC33",
    "#669933",
    "#336600",
    "#99FF33",
    "#99CC66",
    "#99CC00",
    "#99CC33",
    "#669900",
    "#CCFF00",
    "#CCCC33",
    "#999900",
    "#5753c5",
    "#0099bf",
    "#188018",
    "#2e8c00",
    "#ab511f",
    "#d583ff",
    "#a7a4ff",
    "#82d3ff",
    "#76eaff",
    "#85ed85",
    "#8deb5f",
    "#c2d653",
    "#fcb75c",
    "#ffc66d",
    "#ffb39e",
    "#ff9de5",
    "#2BCBBA",
    "#9fb2ff",
    "#e36920",
    "#d34e2a",
    "#ec3b24",
    "#ba3d99",
    "#9d45c9",
    "#4f5aec",
    "#615dcf",
    "#3286cf",
    "#00abca",
    "#279227",
    "#3a980c",
    "#6c7f00",
    "#542800",
    "#33CC99",
    "#721f00",
    "#66004b",
    "#5c0488",
    "#0626a5",
    "#201c8e",
    "#1d3876",
    "#005d6e",
    "#005400",
    "#451904",
    "#5d1f0c",
    "#490036",
    "#48036c",
    "#051e81",
    "#3531a3",
    "#1d4892",
    "#006f84",
    "#036b03",
    "#c8210a",
    "#950f74",
    "#7b23a7",
    "#263dd4",
    "#0b0779",
    "#004b59",
    "#004800",
    "#164000",
    "#401a02",
    "#763700",
    "#9f241e",
    "#982c0e",
    "#a81300",
    "#650d90",
    "#082fca",
    "#4642b4",
    "#1d5cac",
    "#00849c",
    "#0e760e",
    "#686868",
    "#c36806",
    "#c85120",
    "#bf3624",
    "#df2512",
    "#aa2288",
    "#933bbf",
    "#ff911a",
    "#fc8120",
    "#fa5236",
    "#ca4da9",
    "#a74fd3",
    "#5a68ff",
    "#6d69db",
    "#489bd9",
    "#00bcde",
    "#36a436",
    "#47a519",
    "#798d0a",
    "#c1a120",
    "#fd8c25",
    "#f36e4a",
    "#fc6148",
    "#d75ab6",
    "#b25ade",
    "#6575ff",
    "#7b77e9",
    "#4ea8ec",
    "#00d0f5",
    "#4eb94e",
    "#8b9f1c",
    "#d0853a",
    "#ffc51f",
    "#f86b5a",
    "#e467c3",
    "#bd65e9",
    "#7183ff",
    "#55b6ff",
    "#10dcff",
    "#51cd51",
    "#5cba2e",
    "#9eb22f",
    "#ffd03b",
    "#ff8a6a",
    "#ff7e7e",
    "#ef72ce",
    "#c56df1",
    "#8091ff",
    "#918dff",
    "#69caff",
    "#3ee1ff",
    "#71cf43",
    "#abbf3c",
    "#e6c645",
    "#eda04e",
    "#ff987c",
    "#ff8f8f",
    "#fb7eda",
    "#ce76fa",
    "#9c98ff",
    "#64e7ff",
    "#f9ad58",
    "#ffe651",
    "#ffbf51",
    "#ff9d9e",
    "#ff8de1",
    "#b2afff",
    "#8ddaff",
    "#97f569",
    "#cde153",
    "#e2a9ff",
    "#76eaff",
];

window.colorsArraySet3 = [
    "#9400D3", // 1. Violet m 
    "#0000FF", // 2. Blue m       
    "#00FFFF", // 3. Cyan l       
    "#18A018", // 4. Green m      
    "#FFFF00", // 5. Yellow m     
    "#FF0000", // 6. Red m        
    "#FF00FF", // 7. Magenta m    
    "#FF6600", // 8. Orange m     
    "#008B8B", // 9. SeaGreen     
    "#8B0000", //10. Red d        
    "#7FFF00", //11. GreenYellow l
    "#6495ED", //12. Blue l       
    "#006400", //13. Green d      
    "#F73302", //14. Orange       
    "#11B5DD", //15. Blue l1      
    "#CC00CC", //16. Magenta d    
    "#CC0000", //17. Red d        
    "#09C6A4", //18. BlueGreen    
    "#1d71c6", //19. Blue m       
    "#9933CC", //20. Violet m     
    "#FC8046", //21. Orange l     
    "#04CC04", //22. GreenYellow l
    "#990033", //23. Brick d      
    "#2045FC", //24. Blue m       
    "#CC0099", //25. Magenta m    
    "#541700", //26. Brown        
    "#CC3366", //27. Brick d      
    "#FF33FF", //28. Magenta l    
    "#9ACD32", //29. GreenYellow m
    "#51af23", //30. Green m      
    "#3333FF", //31. #3333FF      
    "#FF0099", //32. Magenta m    
    "#990099", //33. Magenta d2   
    "#33FF66", //34. Green l      
    "#444cde", //35. BlueViolet   
    "#CC33FF", //36. Pink d2      
    "#FF6633", //37. Orange m     
    "#9900CC", //38. Violet m     
    "#FF6699", //39. Red l        
    "#660066", //40. Violet d     
    "#66FF33", //41. GreenYellow l
    "#CC33CC", //42. Pink d       
    "#FF0066", //43. Pink d       
    "#9966FF", //44. Violet       
    "#6600CC", //45. Violet m     
    "#CC0066", //46. Red m        
    "#6633FF", //47. Violet m     
    "#72da72", //48. GreenYellow m
    "#330099", //49. Violet d     
    "#3333FF", //50. Blue m       
    "#FF66CC", //51. Pink m       
    "#33FF99", //52. GreenYellow m
    "#FF9933", //53. #FF9933
    "#FF3399", //54. Pink d 
    "#0066FF", //55. #0066FF
    "#0033FF", //56. #0033FF
    "#FF8C00", //57. #FF8C00
    "#006600", //58. #006600
    "#003399", //59. #003399
    "#0099FF", //60. #0099FF
    "#33CCFF", //61. #33CCFF
    "#CC0033", //62. #CC0033
    "#00FF99", //63. #00FF99
    "#009933", //64. #009933
];
window.colorsArraySet4 = [
    "#FF0000", // 1. Red #FF0000 rgb(255, 0, 0)                 
    "#3366FF", // 2. Blue #3366FF rgb(135, 206, 250)            
    "#00FF00", // 3. Lime #00FF00 rgb(0, 255, 0)                
    "#FFD700", // 4. Gold #FFD700 rgb(255, 215, 0)              
    "#FF00FF", // 5. Magenta #FF00FF rgb(255, 0, 255)           
    "#00FFFF", // 6. Cyan #00FFFF rgb(0, 255, 255)              
    "#F4A460", // 7. SandyBrown #F4A460 rgb(244, 164, 96)       
    "#008000", // 8. Green #008000 rgb(0, 128, 0)               
    "#FF7F50", // 9. Coral #FF7F50 rgb(255, 127, 80)            
    "#09C6A4", //10. MediumAquamarine #09C6A4 rgb(102, 205, 170)
    "#FF1493", //11. DeepPink #FF1493 rgb(255, 20, 147)         
    "#D2691E", //12. Chocolate #D2691E rgb(210, 105, 30)        
    "#00BFFF", //13. DeepSkyBlue #00BFFF rgb(0, 191, 255)       
    "#DC143C", //14. Сrimson #DC143C rgb(220, 20, 60)           
    "#00FF7F", //15. SpringGreen #00FF7F rgb(0, 255, 127)       
    "#EE82EE", //16. Violet #EE82EE rgb(238, 130, 238)          
    "#00008B", //17. DarkBlue #00008B rgb(0, 0, 139)            
    "#800000", //18. Maroon #800000 rgb(128, 0, 0)              
    "#0000FF", //19. Blue #0000FF rgb(0, 0, 255)                
    "#FF8C00", //20. DarkOrange #FF8C00 rgb(255, 140, 0)        
    "#008080", //21. Teal #008080 rgb(0, 128, 128)              
    "#32CD32", //22. LimeGreen #32CD32 rgb(50, 205, 50)         
    "#7B68EE", //23. MediumSlateBlue #7B68EE rgb(123, 104, 238) 
    "#A52A2A", //24. Brown #A52A2A rgb(165, 42, 42)             
    "#6B8E23", //25. OliveDrab #6B8E23 rgb(107, 142, 35)        
    "#C71585", //26. MediumVioletRed #C71585 rgb(199, 21, 133)  
    "#8A2BE2", //27. BlueViolet #8A2BE2 rgb(138, 43, 226)       
    "#8B0000", //28. DarkRed #8B0000 rgb(139, 0, 0)             
    "#00CED1", //29. DarkTurquoise #00CED1 rgb(0, 206, 209)     
    "#FF4500", //30. OrangeRed #FF4500 rgb(255, 69, 0)          
    "#ADFF2F", //31. GreenYellow #ADFF2F rgb(173, 255, 47)      
    "#4B0082", //32. Indigo #4B0082 rgb(75, 0, 130)             
    "#FFA500", //33. Orange #FFA500 rgb(255, 165, 0)            
    "#B22222", //34. FireBrick #B22222 rgb(178, 34, 34)         
    "#9400D3", //35. DarkViolet #9400D3 rgb(148, 0, 211)        
    "#9ACD32", //36. YellowGreen #9ACD32 rgb(154, 205, 50)      
    "#FF0099", //37. Magenta m #FF0099                          
    "#FF69B4", //38. HotPink #FF69B4 rgb(255, 105, 180)         
    "#1E90FF", //39. DodgerBlue #1E90FF rgb(30, 144, 255)       
    "#8B4513", //40. SaddleBrown #8B4513 rgb(139, 69, 19)       
    "#20B2AA", //41. LightSeaGreen #20B2AA rgb(32, 178, 170)    
    "#FFFF00", //42. Yellow #FFFF00 rgb(255, 255, 0)            
    "#800080", //43. Purple #800080 rgb(128, 0, 128)            
    "#006400", //44. DarkGreen #006400 rgb(0, 100, 0)           
    "#FF6347", //45. Tomato #FF6347 rgb(255, 99, 71)            
    "#808000", //46. Olive #808000 rgb(128, 128, 0)             
    "#4169E1", //47. RoyalBlue #4169E1 rgb(65, 105, 225)        
    "#3CB371", //48. MediumSeaGreen #3CB371 rgb(60, 179, 113)   
    "#D2D817", //49. Lime #D2D817                               
];
window.colorsArraySet5 = [
    "#FF00FF", // 1. Magenta #FF00FF rgb(255, 0, 255)           
    "#008000", // 2. Green #008000 rgb(0, 128, 0)               
    "#3366FF", // 3. Blue #3366FF rgb(135, 206, 250)            
    "#FFD700", // 4. Gold #FFD700 rgb(255, 215, 0)              
    "#FF7F50", // 5. Coral #FF7F50 rgb(255, 127, 80)            
    "#09C6A4", // 6. MediumAquamarine #09C6A4 rgb(102, 205, 170)
    "#FF0000", // 7. Red #FF0000 rgb(255, 0, 0)                 
    "#F4A460", // 8. SandyBrown #F4A460 rgb(244, 164, 96)       
    "#00FF00", // 9. Lime #00FF00 rgb(0, 255, 0)                
    "#FF1493", //10. DeepPink #FF1493 rgb(255, 20, 147)         
    "#00FFFF", //11. Cyan #00FFFF rgb(0, 255, 255)              
    "#FF0099", //12. Magenta m #FF0099                          
    "#9400D3", //13. DarkViolet #9400D3 rgb(148, 0, 211)        
    "#800080", //14. Purple #800080 rgb(128, 0, 128)            
    "#20B2AA", //15. LightSeaGreen #20B2AA rgb(32, 178, 170)    
    "#7B68EE", //16. MediumSlateBlue #7B68EE rgb(123, 104, 238) 
    "#6B8E23", //17. OliveDrab #6B8E23 rgb(107, 142, 35)        
    "#FFFF00", //18. Yellow #FFFF00 rgb(255, 255, 0)            
    "#006400", //19. DarkGreen #006400 rgb(0, 100, 0)           
    "#EE82EE", //20. Violet #EE82EE rgb(238, 130, 238)          
    "#FF8C00", //21. DarkOrange #FF8C00 rgb(255, 140, 0)        
    "#32CD32", //22. LimeGreen #32CD32 rgb(50, 205, 50)         
    "#8B4513", //23. SaddleBrown #8B4513 rgb(139, 69, 19)       
    "#00BFFF", //24. DeepSkyBlue #00BFFF rgb(0, 191, 255)       
    "#8A2BE2", //25. BlueViolet #8A2BE2 rgb(138, 43, 226)       
    "#00008B", //26. DarkBlue #00008B rgb(0, 0, 139)            
    "#FFA500", //27. Orange #FFA500 rgb(255, 165, 0)            
    "#00FF7F", //28. SpringGreen #00FF7F rgb(0, 255, 127)       
    "#FF69B4", //29. HotPink #FF69B4 rgb(255, 105, 180)         
    "#4169E1", //30. RoyalBlue #4169E1 rgb(65, 105, 225)        
    "#ADFF2F", //31. GreenYellow #ADFF2F rgb(173, 255, 47)      
    "#8B0000", //32. DarkRed #8B0000 rgb(139, 0, 0)             
    "#800000", //33. Maroon #800000 rgb(128, 0, 0)              
    "#9ACD32", //34. YellowGreen #9ACD32 rgb(154, 205, 50)      
    "#D2691E", //35. Chocolate #D2691E rgb(210, 105, 30)        
    "#00CED1", //36. DarkTurquoise #00CED1 rgb(0, 206, 209)     
    "#808000", //37. Olive #808000 rgb(128, 128, 0)             
    "#FF4500", //38. OrangeRed #FF4500 rgb(255, 69, 0)          
    "#008080", //39. Teal #008080 rgb(0, 128, 128)              
    "#1E90FF", //40. DodgerBlue #1E90FF rgb(30, 144, 255)       
    "#C71585", //41. MediumVioletRed #C71585 rgb(199, 21, 133)  
    "#FF6347", //42. Tomato #FF6347 rgb(255, 99, 71)            
    "#0000FF", //43. Blue #0000FF rgb(0, 0, 255)                
    "#B22222", //44. FireBrick #B22222 rgb(178, 34, 34)         
    "#D2D817", //45. Lime #D2D817                               
    "#4B0082", //46. Indigo #4B0082 rgb(75, 0, 130)             
    "#DC143C", //47. Сrimson #DC143C rgb(220, 20, 60)           
    "#3CB371", //48. MediumSeaGreen #3CB371 rgb(60, 179, 113)   
    "#A52A2A", //49. Brown #A52A2A rgb(165, 42, 42)             
];
window.colorsArraySet6 = [
	"#008000", // 1. Green #008000 rgb(0, 128, 0)              
	"#DC143C", // 2. Сrimson #DC143C rgb(220, 20, 60)          
	"#0000FF", // 3. Blue #0000FF rgb(0, 0, 255)               
	"#800080", // 4. Purple #800080 rgb(128, 0, 128)           
	"#FF6600", // 5. Orange #FF6600                            
	"#7B68EE", // 6. MediumSlateBlue #7B68EE rgb(123, 104, 238)
	"#FF0000", // 7. Red #FF0000 rgb(255, 0, 0)                
	"#51af23", // 8. MediumSeaGreen #51af23                    
	"#8B0000", // 9. DarkRed #8B0000 rgb(139, 0, 0)            
	"#1E90FF", //10. DodgerBlue #1E90FF rgb(30, 144, 255)      
	"#FF1493", //11. DeepPink #FF1493 rgb(255, 20, 147)        
	"#808000", //12. Olive #808000 rgb(128, 128, 0)            
	"#C71585", //13. MediumVioletRed #C71585 rgb(199, 21, 133) 
	"#00008B", //14. DarkBlue #00008B rgb(0, 0, 139)           
	"#FF6347", //15. Tomato #FF6347 rgb(255, 99, 71)           
	/*"#00FF00", //16. Lime #00FF00 rgb(0, 255, 0)               
	"#00BFFF", //17. DeepSkyBlue #00BFFF rgb(0, 191, 255)      
	"#9400D3", //18. DarkViolet #9400D3 rgb(148, 0, 211)       
	"#FF4500", //19. OrangeRed #FF4500 rgb(255, 69, 0)         
	"#0000FF", //20. Blue #0000FF rgb(0, 0, 255)               
	"#FFD700", //21. Gold #FFD700 rgb(255, 215, 0)             
	"#4B0082", //22. Indigo #4B0082 rgb(75, 0, 130)            
	"#A52A2A", //23. Brown #A52A2A rgb(165, 42, 42)            
	"#ADFF2F", //24. GreenYellow #ADFF2F rgb(173, 255, 47)     
	"#8A2BE2", //25. BlueViolet #8A2BE2 rgb(138, 43, 226)      
	"#006400", //26. DarkGreen #006400 rgb(0, 100, 0)          
	"#4169E1", //26. RoyalBlue #4169E1 rgb(65, 105, 225)       
	"#990012", //28. Red Wine #990012                          
	"#F433FF", //29. Bright Neon Pink #F433FF
	"#1F45FC", //30. Blue Orchid #1F45FC     */
];
/*
window.colorsArraySentiment1 = [
    "#008000", //Green (positive)
    "#FF0000", //Red (negative)
    "#0000FF"  //Blue (neutral)
];
window.colorsArraySentiment2 = [
    "#008000", //Green (positive)
    "#FF0000", //Red (negative)
    "#FFFF00"  //Yellow (neutral)
];
window.colorsArraySentiment3 = [
    "#18A018", //Green (positive)
    "#CC0066", //Red (negative)
    "#FFD700", //Yellow Gold (neutral)
];
*/

window.colorsArraySentiment1 = [
    "#008000", //Green (positive)
    "#FF0000", //Red (negative)
    "#0000FF"  //Blue (neutral)
];
window.colorsArraySentiment2 = [
    "#008000", //Green (positive)
    "#0000FF", //Blue (negative)
    "#FFFF00"  //Yellow (neutral)
];
window.colorsArraySentiment3 = [
    "#18A018", //Green (positive)
    "#CC0066", //Red (negative)
    "#FFFFFF" //White (neutral)
];
window.colorsArraySentiment4 = [
    "#008000", //Green (positive)
    "#0000FF", //Blue (negative)
    "#FFFFFF" //White (neutral)
];
window.colorsArraySentiment5 = [
    "#CC0066", //Red (positive)
    "#0000FF", //Blue (negative)
    "#FFFFFF" //White (neutral)
];

window.colorsArraySet_8colors = [
    "#FF0000", //Red
    "#0000FF", //Blue
    "#ff7700", //Orange
    "#fff100", //Yellow
    "#FF00FF", //Magenta
    "#800080", //Purple
    "#18A018", //Green
    "#660000", //Maroon
];
window.colorsArraySet_16colors = [
    "#FF0000", //Red
    "#0000FF", //Blue
    "#ff7700", //Orange
    "#fff100", //Yellow
    "#FF00FF", //Magenta
    "#800080", //Purple
    "#18A018", //Green
    "#660000", //Maroon
    
    "#ADFF2F", //GreenYellow
    "#FF69B4", //HotPink
    "#CC9900", //Salmon
    "#9933FF", //Violet
    "#003400", //DarkGreen
    "#990000", //Navy
    "#00FFFF", //Cyan
    "#663311", //Choolate
];
window.colorsArraySet_24colors = [
    "#FF0000", //Red
    "#0000FF", //Blue
    "#ff7700", //Orange
    "#fff100", //Yellow
    "#FF00FF", //Magenta
    "#800080", //Purple
    "#18A018", //Green
    "#660000", //Maroon
    
    "#ADFF2F", //GreenYellow
    "#FF69B4", //HotPink
    "#CC9900", //Salmon
    "#9933FF", //Violet
    "#003400", //DarkGreen
    "#990000", //Navy
    "#00FFFF", //Cyan
    "#663311", //Choolate

    "#1E90FF", //DodgerBlue
    "#CF4A83", //Melon
    "#008080", //Teal
    "#FF4500", //OrangeRed
    "#440044", //DarkViolet
    "#808000", //Olive
    "#000055", //DarkBlue
    "#00FA9A", //MediumSpringGreen
];

window.colorsArraySet_32colors = [
    "#FF0000", //Red
    "#0000FF", //Blue
    "#ff7700", //Orange
    "#fff100", //Yellow
    "#FF00FF", //Magenta
    "#800080", //Purple
    "#18A018", //Green
    "#660000", //Maroon
    
    "#ADFF2F", //GreenYellow
    "#FF69B4", //HotPink
    "#CC9900", //Salmon
    "#9933FF", //Violet
    "#003400", //DarkGreen
    "#990000", //Navy
    "#00FFFF", //Cyan
    "#663311", //Choolate

    "#1E90FF", //DodgerBlue
    "#CF4A83", //Melon
    "#008080", //Teal
    "#FF4500", //OrangeRed
    "#440044", //DarkViolet
    "#808000", //Olive
    "#000055", //DarkBlue
    "#00FA9A", //MediumSpringGreen
    
    "#AA2C50", //Red
    "#333399", //Blue
    "#C65605", //Orange
    "#B8860B", //Yellow
    "#A71585", //Magenta
    "#7500B5", //Purple
    "#2B7048", //Green
    "#FF0066", //Pink
];

window.colorsArray = {
    "1" : window.colorsArraySet4,
    "2" : window.colorsArraySet5,
    "3" : window.colorsArraySet3,
    "4" : window.colorsArraySet1,
    "5" : window.colorsArraySet2,
    //"5" : window.colorsArraySet6,
    "6" : window.colorsArraySet_8colors,
    "7" : window.colorsArraySet_16colors,
    "8" : window.colorsArraySet_24colors,
    "9" : window.colorsArraySet_32colors,
    "10" : window.colorsArraySentiment1,
    "11" : window.colorsArraySentiment2,
    "12" : window.colorsArraySentiment3,
    "13" : window.colorsArraySentiment4,
    "14" : window.colorsArraySentiment5
    
};

    
    
    

