var isDebugg = false;

window.savedSize = {
    contentWidth: undefined,
    leftWidht: undefined,
    rightWidht: undefined,
    centerWidth: undefined,
    minLeftWidht: 150,
    minRightWidht: 320,
    minCenterWidth: 600,
    iniLeftWidht: 150,
    iniRightWidht: 320,
    iniCenterWidth: 650
};
var mouseupF = 0;
var curDataChart,
    curDataChartTS,
    curDataChartTL,
    curDataChartTW,
    curDataChartSG,
    curDataChartSK,
    dataContanerWidth,
    dataContanerHeight,
    contentWidth,
    contentHeight,
    curDataContanerHeight,
    isResize,
    isCalendarClick;
window.cloneFormWrap = undefined;
window.sampleForm = undefined;
window.sampleFormDrawing = undefined;
window.btnMain = undefined;
window.btnDrawing = undefined;
window.colorGray = '#BFBEBE';
window.wordsListHead = {
    'radial': '',
    'molecular': '',
    'timeSeries': '',
    'textualWords': '',
    'timeLine': '',
    'streamgraph': '',
    'sankey': ''
};
window.colorsArrayUsed = [];
window.colorsArrayUsedMinIndex = [];
window.colorsArraySetIndex = 0;

window.isAutoClickLegend = false;

window.getColor.color = d3.scale.category20().domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]);
window.getColor.colorB = d3.scale.category20b().domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]);
window.getColor.colorC = d3.scale.category20c().domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]);

window.prevChartType = undefined;

var formDataMetadata;
var newCoords;
var sourceChartView;
var isChangeDropdownDatesInterval = 1;
var dataWidgetResult;


/**
 * function return form options json for the dataSelect tab form
 * 
 * @param json DataSelectOptions
 * @returns
 */
function getFormDataSelectTemplate() {
    if (isDebugg) { console.log('01'); }
    return [
        //DataSelect
        /*{
            type: 'label',
            label: '<div class="form-block-title">Data select</div>',
            labelPosition: 'left',
            align: 'left',
            width: '100px',
            labelWidth: '100%',
            backgroundColor: '#fafafa'
        },*/
        /*{
            bind: 'dropdownValueDSource',
            name: 'dropdownValueDSource',
            type: 'option',
            label: '<div class="data-source-label">Data source</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '220px',
            dropDownHeight: '300px',
            minWidth: '100px',
            required: true,
            component: 'jqxDropDownList',
            enableBrowserBoundsDetection: true,
            options: DataSelectOptions,
            autoItemsHeight: true
        },*/
        {
            bind: 'dropdownValueDSource2',
            name: 'dropdownValueDSource2',
            type: 'option',
            label: '<div class="data-source-label2">Data source 2</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            dropDownHeight: 250,
            component: 'jqxDropDownList',
            enableBrowserBoundsDetection: true,
            options: DataSelectOptions,
            disabled: true
        },
        {
            columns: [
                {
                    type: 'label',
                    label: '<div class="data-source-label">Data source</div>',
                    width: '100%',
                    labelWidth: '100%',
                    rowHeight: '40px',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                },
                {
                    type: 'label',
                    label: '<div id="dropdownValueDSource"></div>',
                    width: '100%',
                    labelWidth: '100%',
                    rowHeight: '40px',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                }
            ]
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-date-template-label">Date</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'textBoxValueDatesRange',
            name: 'textBoxValueDatesRange',
            type: 'text',
            label: '<div class="form-dates-range-input">Dates range</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        /*{
            bind: 'dropdownDatesInterval',
            name: 'dropdownDatesInterval',
            type: 'option',
            label: '<div class="dates-interval-label">Dates interval</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            //height: '250px',
            dropDownHeight: 250,
            autoItemsHeight: true,
            //enableBrowserBoundsDetection: true,
            options: dropdownDatesIntervalOptions
        },*/
        {
            columns: [
                {
                    type: 'label',
                    label: '<div class="dates-interval-label">Dates interval</div>',
                    width: '100%',
                    labelWidth: '100%',
                    rowHeight: '40px',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                },
                {
                    type: 'label',
                    label: '<div id="dropdownDatesInterval"></div>',
                    width: '100%',
                    labelWidth: '100%',
                    rowHeight: '40px',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                }
            ]
        },

        {
            type: 'label',
            label: '<div class="form-exclude-periods-label">Exclude periods</div>',
            width: '100%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 0, bottom: 5 },
        },
        {
            bind: 'textBoxValueExcludePeriods',
            name: 'textBoxValueExcludePeriods',
            type: 'text',
            label: '<div class="form-exclude-periods-input"></div>',
            labelPosition: 'left',
            labelWidth: '0%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false,
            labelPadding: { left: 0, top: 5, right: 0, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-pop-words-template-label">Pop-words</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'dropdownValueVIndicator',
            name: 'dropdownValueVIndicator',
            type: 'option',
            label: '<div class="value-indicator-label">Value indicator</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: dropdownValueVIndicatorOptions
        },
        {
            columns: [
                {
                    columnWidth: '35%',
                    type: 'label',
                    label: '<div class="frequency-min-max-label">'
                        + '<span class="frequency-min-max-label1">Frequency min-max</span>'
                        + '<span class="frequency-min-max-label2">Value indicator min-max</span></div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxValue1"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '30%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                },
                {
                    columnWidth: '1%',
                    type: 'label',
                    label: '-',
                    labelPosition: 'right',
                    align: 'left',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 4, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxValue2"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '30%',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 0, bottom: 5 }
                }
            ]
        },
        {
            bind: 'checkboxRepeatSelectionMinMaxUnlim',
            name: 'checkboxRepeatSelectionMinMaxUnlim',
            type: 'boolean',
            label: '<div class="checkbox-repeat-selection-unlim-label" id="checkboxRepeatSelectionMinMaxUnlim">Repeat selection with min-max unlimited</div>',//value-indicator-label
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxValueIndicatorLimitsStrict',//checkboxWordsGroupSubentries
            name: 'checkboxValueIndicatorLimitsStrict',//checkboxWordsGroupSubentries
            type: 'boolean',
            label: '<div id="checkboxValueIndicatorLimitsStrict">Value indicator limits min/max strict</div>',//checkboxWordsGroupSubentries
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },

        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'dropdownValueIWords',
            name: 'dropdownValueIWords',
            type: 'option',
            label: '<div class="intermediate-words-label">Intermediate words</div>',
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '50px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: 'No', value: '0' },
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
                { label: '7', value: '7' },
                { label: '8', value: '8' }
            ]
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },

        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            columns: [
                {
                    columnWidth: '20%',
                    type: 'label',
                    label: '<div class="form-max-words-total-label">Max per day</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxValueMaxWordsTotal"></div>',
                    labelPosition: 'left',
                    labelWidth: '60px',
                    align: 'left',
                    width: '25%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                }
            ]
        },
        {
            columns: [
                {
                    columnWidth: '35%',
                    type: 'label',
                    label: '<div class="appearance-min-max-label">Appearance min-max</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxAppearanceMin"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '32%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                },
                {
                    columnWidth: '1%',
                    type: 'label',
                    label: '-',
                    labelPosition: 'right',
                    align: 'left',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 4, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxAppearanceMax"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '32%',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 0, bottom: 5 }
                }
            ]
        },
        /*{
            bind: 'checkboxAppearanceFullTimeValue',
            type: 'boolean',
            name: 'checkboxAppearanceFullTime',
            label: '<div id="checkboxAppearanceFullTime">Appearance full time</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },*/
        {
            bind: 'checkboxAppearanceFullTimeValueTMP',
            type: 'boolean',
            name: 'checkboxAppearanceFullTimeTMP',
            label: '<div id="checkboxAppearanceFullTimeTMP">Appearance full time</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'checkboxAppearanceFullTimeValue',
            name: 'checkboxAppearanceFullTimeValue',
            type: 'option',
            label: '<div class="appearance-time-label">Appearance interval</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Any", value: '0' },
                { label: "All days only", value: '1' },
                { label: "Remove all days", value: '2' }
            ]
        },
        {
            bind: 'dropdownsSortField',//dropdownValueVIndicator
            name: 'dropdownsSortField',//dropdownValueVIndicator
            type: 'option',
            label: '<div class="sort-field-label">Sort field</div>',//value-indicator-label
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: dropdownsSortFieldOptions//dropdownValueVIndicatorOptions
        },


        {
            bind: 'isChange1',
            name: 'isChangeTab1',
            type: 'boolean',
            label: '<div id="checkboxIsChangeTab1"></div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },

        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-eighbors-template-label">Neighbors</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            columns: [
                {
                    columnWidth: '50%',
                    bind: 'dropdownValueMaxDepth',
                    name: 'dropdownValueMaxDepth',
                    type: 'option',
                    label: '<div class="max-depth-label">Max depth</div>',
                    minWidth: '100px',
                    labelPosition: 'left',
                    labelWidth: '50%',
                    align: 'left',
                    width: '60px',
                    required: false,
                    component: 'jqxDropDownList',
                    options: [
                        { label: '', value: '' },
                        { label: '1', value: '1' },
                        { label: '2', value: '2' },
                        { label: '3', value: '3' },
                        { label: '4', value: '4' },
                        { label: '5', value: '5' },
                        { label: '6', value: '6' },
                        { label: '7', value: '7' },
                        { label: '8', value: '8' }
                    ]
                },
                {
                    columnWidth: '50%',
                    bind: 'dropdownMaxDistance',
                    name: 'dropdownMaxDistance',
                    type: 'option',
                    label: '<div class="distance-label">Max distance</div>',
                    minWidth: '100px',
                    labelPosition: 'left',
                    labelWidth: '35%',
                    align: 'left',
                    width: '60px',
                    required: false,
                    component: 'jqxDropDownList',
                    options: [
                        { label: '0', value: '0' },
                        { label: '1', value: '1' },
                        { label: '2', value: '2' },
                        { label: '3', value: '3' },
                        { label: '4', value: '4' },
                        { label: '5', value: '5' },
                        { label: '6', value: '6' },
                        { label: '7', value: '7' },
                        { label: '8', value: '8' }
                    ]
                }
            ]
        },
        {
            columns: [
                {
                    columnWidth: '25%',
                    type: 'label',
                    label: '<div class="form-max-neighbors-words-label">Max words</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxNeighborsMaxItems"></div>',
                    labelPosition: 'left',
                    labelWidth: '60px',
                    align: 'left',
                    width: '25%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                }
            ]
        },
        {
            columns: [
                {
                    columnWidth: '35%',
                    type: 'label',
                    label: '<div class="neighbors-freq-min-max-label">Frequency min max</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxNeighborsFreqMin"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '30%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                },
                {
                    columnWidth: '1%',
                    type: 'label',
                    label: '-',
                    labelPosition: 'right',
                    align: 'left',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 4, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxNeighborsFreqMax"></div>',//nubmberBoxSatellitesFreqMax
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '30%',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 0, bottom: 5 }
                }
            ]
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-satellites-template-label">Satellites</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            columns: [
                {
                    columnWidth: '35%',
                    type: 'label',
                    label: '<div class="satellites-freq-min-max-label">'
                        + '<span class="satellites-freq-min-max-label1">Frequency min-max</span>'
                        + '<span class="satellites-freq-min-max-label2">Value indicator min-max</span></div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxSatellitesFreqMin"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '30%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                },
                {
                    columnWidth: '1%',
                    type: 'label',
                    label: '-',
                    labelPosition: 'right',
                    align: 'left',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 4, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxSatellitesFreqMax"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '30%',
                    padding: { left: 0, top: 5, right: 0, bottom: 5 },
                    labelPadding: { left: 0, top: 5, right: 0, bottom: 5 }
                }
            ]
        },
        {
            bind: 'satellitesOrderCondition',
            name: 'satellitesOrderCondition',
            type: 'option',
            label: '<div class="words-selection-order-label">Words selection order</div>',
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '160px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: 'Closest, significant, popular', value: '0' },
                { label: 'Significant, closest, popular', value: '1' },
                { label: 'Popular, significant, closest', value: '2' }
            ]
        },

/////////////////////////
{
            type: 'label',
            label: '<div class="form-title-hr-template-label form-data-relation-template-label">Data relation</div><hr></hr>',//form-satellites-template-label
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'dropdownDaysInterval',//dropdownValueIWords
            name: 'dropdownDaysInterval',//dropdownValueIWords
            type: 'text',
            label: '<div class="days-interval-label">Days interval</div>',//intermediate-words-label
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '99%',
            align: 'left',
            width: '135px',
            minWidth: '100px',
            valign: 'top',
            required: false
            //component: 'jqxDropDownList'
        },
        {
            bind: 'dropdownMinIntersectedNeighbors',//dropdownDaysInterval
            name: 'dropdownMinIntersectedNeighbors',//dropdownDaysInterval
            type: 'text',
            label: '<div class="min-intersected-neighbors-label">Min intersected</div>',//days-interval-label
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '99%',
            align: 'left',
            width: '50px',
            required: false
            //component: 'jqxDropDownList'
        },
        {
            bind: 'dropdownSortDailyWordsBy',//dropdownValueIWords
            name: 'dropdownSortDailyWordsBy',//dropdownValueIWords
            type: 'option',
            label: '<div class="sort-daily-words-by-label">Sort daily words by</div>',//intermediate-words-label
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '55%',
            align: 'left',
            width: '150px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: 'frequency', value: '0' },
                { label: 'alphabetically', value: '1' }
            ]
        },
        {
            name: 'relationsLabel',//filterPOSLabel
            type: 'label',
            label: '<div class="form-title-hr-template-label form-relations-template-label" id="relationsLabel">Relations</div><hr></hr>',//filterPOSLabel
            //form-filter-pos-template-label
            width: '100%',
            labelWidth: '100%',
            rowHeight: '20px',
            padding: { left: 0, top: 5, right: 0, bottom: 5 },
        },
        {
            bind: 'checkboxPWIntersectedNeighbors',
            type: 'boolean',
            name: 'checkboxPWIntersectedNeighbors',
            label: '<div class="checkbox-pop-words-intersected-neighbors-label" id="checkboxPWIntersectedNeighbors">pop-words of intersected neighbors</div>',//checkbox-repeat-selection-unlim-label
            labelPosition: 'left',
            labelWidth: '70%',
            align: 'left',
        },
        {
            bind: 'checkboxPW1inNeighbors2',
            type: 'boolean',
            name: 'checkboxPW1inNeighbors2',
            label: '<div class="checkbox-pop-words-1-in-neighbors-2-label" id="checkboxPW1inNeighbors2">pop-word 1 in neighbors 2</div>',
            labelPosition: 'left',
            labelWidth: '70%',
            align: 'left',
        },
        {
            bind: 'checkboxPW2inNeighbors1',
            type: 'boolean',
            name: 'checkboxPW2inNeighbors1',
            label: '<div class="checkbox-pop-words-2-in-neighbors-1-label" id="checkboxPW2inNeighbors1">pop-word 2 in neighbors 1</div>',
            labelPosition: 'left',
            labelWidth: '70%',
            align: 'left',
        },
        {
            bind: 'checkboxIntersectedNeighbors',
            type: 'boolean',
            name: 'checkboxIntersectedNeighbors',
            label: '<div class="checkbox-intersected-neighbors-label id="checkboxIntersectedNeighbors">articles titles</div>',
            labelPosition: 'left',
            labelWidth: '70%',
            align: 'left',
        },
        
        {
            bind: 'checkboxArticlesList',//checkboxIntersectedNeighbors
            type: 'boolean',
            name: 'checkboxArticlesList',//checkboxIntersectedNeighbors
            label: '<div class="checkbox-articles-list-label id="checkboxArticlesList">articles titles filtered</div>',//checkbox-intersected-neighbors-label checkboxIntersectedNeighbors
            labelPosition: 'left',
            labelWidth: '70%',
            align: 'left',
        },

//////////////////////////////////////////

        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-data-seectother-template-label"></div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'dropdownDataSelectCache',//satellitesOrderCondition
            name: 'dropdownDataSelectCache',//satellitesOrderCondition
            type: 'option',
            label: '<div class="dropdown-data-select-cache-label">Cache</div>', //words-selection-order-label
            rowHeight: '40px',
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '200px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: 'Config', value: '0' },
                { label: 'Data download results', value: '1' },
                { label: 'Data download and DB results', value: '2' },
                { label: 'No data download results', value: '3' },
                { label: 'No DB results', value: '4' },
                { label: 'No regression results', value: '5' }
            ]
        },

        {
            columns: [
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'submitButton',
                    text: 'Draw',
                    align: 'left',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'shareButton',
                    text: 'Share',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'widgetButton',
                    text: 'Widget',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'saveButton',
                    text: 'Save',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '22%',
                    type: 'button',
                    name: 'helpButton',
                    text: 'Help',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                }
            ]
        }
    ];
}
/**
 * function return form options json for the Drawing tab form
 * 
 * @param json DrawingOptions
 * @returns
 */
function getFormTemplateDrawing() {
    if (isDebugg) { console.log('02'); }
    return [
        {
            type: 'label',
            label: '<div id="valueIndicatorContaner">Value indicator:</div>',
            rowHeight: '40px'
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-table-settings-label">Table settings</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'checkboxShowHideTableHeaderValue',
            name: 'checkboxShowHideTableHeader',
            type: 'boolean',
            label: '<div id="checkboxShowHideTableHeader">Header on/off</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxShowHidePaginationValue',
            name: 'checkboxShowHidePagination',
            type: 'boolean',
            label: '<div id="checkboxShowHidePagination">Pagination on/off</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxShowHideMetadataColumnValue',
            name: 'checkboxShowHideMetadataColumn',
            type: 'boolean',
            label: '<div id="checkboxShowHideMetadataColumn">Metadata</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },

        //max words total
        {
            columns: [
                {
                    columnWidth: '35%',
                    type: 'label',
                    label: '<div class="form-textual-max-words-total-label">Max words total</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxValueMaxWordsTotalTextual"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '32%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                }
            ]
        },

        {
            type: 'label',
            label: '<div class="form-title-template-label">Title template</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'textBoxTitleTimeCharts',
            name: 'textBoxTitleTimeCharts',
            type: 'text',
            label: '<div class="form-title-time-charts-input">Title</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'textBoxSubtitleTimeCharts',
            name: 'textBoxSubtitleTimeCharts',
            type: 'text',
            label: '<div class="form-subtitle-time-charts-input">Subtitle</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'textBoxDescriptionTimeCharts',
            name: 'textBoxDescriptionTimeCharts',
            type: 'text',
            label: '<div class="form-description-time-charts-input">Description</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'checkboxShowHideTitleValue',
            name: 'checkboxShowHideTitle',
            type: 'boolean',
            label: '<div id="checkboxShowHideTitle">Show title</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-chart-settings-label">Chart settings</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'checkboxOrientationHorizontalVertical',
            name: 'checkboxOrientationHorizontalVertical',
            type: 'boolean',
            label: '<div id="checkboxOrientationHorizontalVertical">Orientation</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },

        {
            bind: 'dropdownMaxNodes',
            name: 'dropdownMaxNodes',
            type: 'text',
            label: '<div class="form-dropdown-max-nodes-input max-nodes-label">Max nodes</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '135px',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'dropdownAutoAdjust',
            name: 'dropdownAutoAdjust',
            type: 'text',
            label: '<div class="form-dropdown-auto-adjust-input auto-adjust-label">Nodes layout</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '135px',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'dropdownAutoAdjustCnt',
            name: 'dropdownAutoAdjustCnt',
            type: 'text',
            label: '<div class="form-dropdown-auto-adjust-cnt-input auto-adjust-label">Number of nodes for auto-adjust</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '70px',
            valign: 'top',
            required: false
        },
        {
            bind: 'checkboxConnectorsLinesValue',
            type: 'boolean',
            name: 'checkboxConnectorsLines',
            label: '<div id="checkboxConnectorsLines">Connectors lines</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            type: 'label',
            label: '<div class="sizeSliderContaner"><div class="sizeSliderLabel">Size </div><div id="sizeSliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        {
            type: 'label',
            label: '<div class="sizeSourcePointsSliderContaner"><div class="sizeSourcePointsSliderLabel">Source<br> points<br> size </div>'
                + '<div id="sizeSourcePointsSliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        {
            type: 'label',
            label: '<div class="lineWidthSliderContaner">'
                + '<div class="lineWidthSliderLabel">Line width </div>'
                + '<div id="lineWidthSliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        {
            bind: 'dropdownConnectorLineWidthIndicator',
            name: 'dropdownConnectorLineWidthIndicator',
            type: 'option',
            label: '<div class="form-dropdown-connector-line-width-indicator-label">Connector line width indicator</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Frequency", value: '1' },
                { label: "Level", value: '2' },
                { label: "Distance", value: '3' },
                { label: "Absolute value", value: '4' }
            ]
        },
        /*{
            type: 'label',
            label: '<div class="lineWidthSliderContaner"><div class="lineWidthSliderLabel">Size </div><div id="lineWidthSliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },*/
        {
            type: 'label',
            label: '<div class="gravitySliderContaner"><div class="gravitySliderLabel">Gravity </div>'
                + '<div id="gravitySliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        {
            type: 'label',
            label: '<div class="indentSliderContaner"><div class="indentSliderLabel">Сharge </div>'
                + '<div id="indentSliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        {
            type: 'label',
            label: '<div class="radiusSliderContaner"><div class="radiusSliderLabel">Radius </div>'
                + '<div id="radiusSliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        {
            bind: 'checkboxIndentValuesRelatedValue',
            name: 'checkboxIndentValuesRelated',
            type: 'boolean',
            label: '<div id="checkboxIndentValuesRelated">Values related</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxNotIncludeSingleNodesValue',
            name: 'checkboxNotIncludeSingleNodes',
            type: 'boolean',
            label: '<div id="checkboxNotIncludeSingleNodes">Not include single nodes</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxReduceOverlapValue',
            name: 'checkboxReduceOverlap',
            type: 'boolean',
            label: '<div id="checkboxReduceOverlap">Reduce overlap</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxShowHideMenuValue',
            name: 'checkboxShowHideMenu',
            type: 'boolean',
            label: '<div id="checkboxShowHideMenu">Show menu button</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxShowHideSeriesLabelValue',
            name: 'checkboxShowHideSeriesLabel',
            type: 'boolean',
            label: '<div id="checkboxShowHideSeriesLabel">Series labels</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'checkboxShowHideLegendValue',
            name: 'checkboxShowHideLegend',
            type: 'boolean',
            label: '<div id="checkboxShowHideLegend">Show legend</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'checkboxConnectNullsValue',
            name: 'checkboxConnectNulls',
            type: 'option',
            label: '<div id="checkboxConnectNulls">Connector lines analysis</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            selectedIndex: 3,
            options: [
                { label: "No", value: '0' },
                { label: "Actual points", value: '1' },
                { label: "Actual & missed points", value: '2' },
            ]
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'checkboxSourceDataPointsValue',
            name: 'checkboxSourceDataPoints',
            type: 'boolean',
            label: '<div id="checkboxSourceDataPoints">Source data points</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
        },
        {
            bind: 'checkboxAxisTurnOnOffValue',
            name: 'checkboxAxisTurnOnOff',
            type: 'boolean',
            label: '<div id="checkboxAxisTurnOnOff">Axis turn on/off</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'dropdownConnectorLinesSourceValue',
            name: 'dropdownConnectorLinesSource',
            type: 'option',
            label: '<div id="dropdownConnectorLinesSource">Connector lines source</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "No", value: '0' },
                { label: "Actual points", value: '1' },
                { label: "Actual & missed points", value: '2' },
            ]
        },
        //!!!
        {
            bind: 'dropdownChartView',
            name: 'dropdownChartView',
            type: 'text',
            label: '<div class="form-dropdown-chart-view-input chart-view-label">Chart view</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false
            //enableBrowserBoundsDetection: true
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },

        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-colors-template-label">Color</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'dropdownColorsSelection',
            name: 'dropdownColorsSelection',
            type: 'option',
            label: '<div class="form-dropdown-colors-selection-label">Colors selection</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Word string based", value: '1' },
                { label: "Word order index based", value: '2' },
                { label: "Sentiment", value: '10' }
            ]
        },
        {
            bind: 'dropdownConnectorLinesColor',
            name: 'dropdownConnectorLinesColor',
            type: 'option',
            label: '<div class="form-connector-lines-color-label">Connector lines color</div>',//form-dropdown-colors-selection-label
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Grayed", value: '1' },
                { label: "Colors from a source node", value: '2' },
                { label: "Colors from a target node", value: '3' }
            ]
        },


        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-invisible-item-label"></div>',//form unvisible item for the dropdowns working
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'dropdownColorsSet',
            name: 'dropdownColorsSet',
            type: 'option',
            label: '<div class="form-dropdown-colors-set-label">Colors set</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Auto", value: '0' },
                { label: "___________________________", value: '1' },
                //{ label: "By string or index algorithm 1", value: '13' },
                //{ label: "By string or index algorithm 2", value: '14' },
                { label: "By string or index", value: '14' },
                { label: "Set #1", value: '1' },
                { label: "Set #2", value: '2' },
                { label: "Set #3", value: '3' },
                { label: "Set #4", value: '4' },
                { label: "Set #5", value: '5' },
                { label: "Set 8 colors", value: '6' },
                { label: "Set 16 colors", value: '7' },
                { label: "Set 24 colors", value: '8' },
                { label: "Set 32 colors", value: '9' },
                { label: "___________________________", value: '9' },
                { label: "Sentiment 1", value: '10' },
                { label: "Sentiment 2", value: '11' },
                { label: "Sentiment 3", value: '12' },
                { label: "Sentiment 4", value: '13' },
                { label: "Sentiment 5", value: '14' },
            ]
        },
        {
            //colors selection for a levels
            type: 'label',
            label: '<div id="colorsSelectionForLevelsContaner"></div>',
            rowHeight: '40px'
        },
        {
            bind: 'dropdownChartBackgroundColor',
            name: 'dropdownChartBackgroundColor',
            type: 'option',
            label: '<div class="form-dropdown-chart-background-color-label">Background color</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                /*Arctic, Light, Material, Web, Bootstrap, Metro, Office, Orange, Fresh, Energy Blue,
                  Dark Blue, Shiny Black, Classic, Summer, High Contrast, Smoothness*/
                { label: "auto", value: '0' },
                { label: "transparent", value: 'rgba(0,0,0,0)' },
                { label: "white", value: '#ffffff' },
                { label: "Dark, Metro Dark, Black", value: '#252830' },//dark
                { label: "Fresh, Lightness", value: '#F7F7F7' },//lite gray
                { label: "Darkness", value: '#000000' },//black
                { label: "Start, Redmond", value: '#FCFDFD' },//lite gray 2
                { label: "Sunny", value: '#FEEEBD' },//yellow
                { label: "Overcast", value: '#C9C9C9' }, //gray
                { label: "Le Frog", value: '#285C00' }//green
            ]
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-text-template-label">Text</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        //Font size input spin
        {
            columns: [
                {
                    columnWidth: '35%',
                    type: 'label',
                    label: '<div class="form-font-size-label">Font size (px)</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 5, right: 0, bottom: 5 }
                },
                {
                    type: 'label',
                    label: '<div id="nubmberBoxValueFontSize"></div>',
                    labelPosition: 'left',
                    labelWidth: '75px',
                    align: 'left',
                    width: '32%',
                    labelPadding: { left: 4, top: 5, right: 0, bottom: 5 },
                    padding: { left: 4, top: 5, right: 0, bottom: 5 },
                }
            ]
        },
        {
            type: 'label',
            label: '<div class="fontSizeSliderContaner">'
                + '<div class="fontSizeSliderLabel">Font size </div>'
                + '<div id="fontSizeSliderElement"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        {
            bind: 'dropdownBallsText',
            name: 'dropdownBallsText',
            type: 'option',
            label: '<div class="form-dropdown-balls-text-label">Balls text</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            selectedIndex: 2,
            component: 'jqxDropDownList',
            options: [
                { label: "auto", value: '4' },
                { label: "full single-line", value: '0' },
                { label: "full multi-line", value: '1' },
                { label: "cut to the size of a circle", value: '2' },
                { label: "hide if is not fit in the circle", value: '3' }
            ]
        },
        {
            bind: 'dropdownBallsTextLayout',
            name: 'dropdownBallsTextLayout',
            type: 'option',
            label: '<div class="form-dropdown-balls-text-layout-label">Balls text layout</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            selectedIndex: 2,
            component: 'jqxDropDownList',
            options: [
                //{label: "full single-line", value: '0'}, 
                { label: "Outer radius", value: '1' },
                { label: "Inside", value: '2' },
                { label: "Inner radius", value: '3' }
            ]
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-text-colors-template-label">Text colors</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'dropdownChartTextColor',//dropdownChartBackgroundColor
            name: 'dropdownChartTextColor',//dropdownChartBackgroundColor
            type: 'option',
            label: '<div class="form-dropdown-chart-text-color-label">Colors set</div>',//form-dropdown-chart-background-color-label
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Auto", value: '0' },
                { label: "Dark text with light decoration", value: '1' },
                { label: "Light text with dark decoration", value: '2' },
                //{label: "From theme", value: '3'}, 
                { label: "Custom", value: '10' },
            ]
        },
        {
            //color selection for a chart text color
            type: 'label',
            label: '<div id="colorsSelectionForChartTextColorContaner"></div>',//colorsSelectionForLevelsContaner
            rowHeight: '40px'
        },
        {
            bind: 'checkboxAddNodesFixLevel',//checkboxShowHideTitleValue
            name: 'checkboxAddNodesFixLevel',//checkboxShowHideTitle
            type: 'boolean',
            label: '<div id="checkboxAddNodesFixLevel">Fix level</div>',//checkboxShowHideTitle
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },

        {
            bind: 'isChange2',
            name: 'isChangeTab2',
            type: 'boolean',
            label: '<div id="checkboxIsChangeTab2"></div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            columns: [
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'submitButton',
                    text: 'Draw',
                    align: 'left',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'shareButton',
                    text: 'Share',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'widgetButton',
                    text: 'Widget',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'saveButton',
                    text: 'Save',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '22%',
                    type: 'button',
                    name: 'helpButton',
                    text: 'Help',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                }
            ]
        },
    ];
}

/**
 * function return form options json for the Analysis tab form
 * 
 * @param json WordsOptions
 * @returns
 */
function getFormTemplateWords() {
    if (isDebugg) { console.log('03'); }
    return [
        {
            type: 'label',
            label: '<div id="wordsListContaner"></div>',
            rowHeight: '40px'
        },
        {
            bind: 'isChange4',
            name: 'isChangeTab4',
            type: 'boolean',
            label: '<div id="checkboxIsChangeTab4"></div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            columns: [
                {
                    columnWidth: '15%',
                    type: 'label',
                    label: '<div id="CopyWordsListButton">'
                        + '<input type="image" id="imageCSV" alt="Copy words list in clipboard" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAADXTAAA10wEMIUN3AAAAB3RJTUUH4wkUDSAsIeu6JwAAASJJREFUWIXt1L8uREEUx/HPbiQKFP6EaGgUEiEehhfxBl5CwRN4AxIdUSuVEkRBFNhqJau4Q6649s6s2ZVlf8lJZjJnzvnOnDPDSCPFqxNp55iKDdpMhGjUWBMXOEqBiFUncr2BPZxhMkfSssUAZIXofDOu8y1DnP4WwDtE131jqUSJEI26DTkBapNVKfUZZlcugNyl/FBVE+7iGS2s4RIPYb4e1hZwjXn1zZsM8IQtzGApJJrFBFbwiuXgt1EH0EsJxnGMR8XJD3CLVbSDTzsknu4h/idV3cAdNjEX5ovYxr3iVk7wgsOKGFkA9hUnbCtKcIUb7JR8W4qy9AWgSt1ewUAAYmN80Z/5iIYXIPUL/VFD9aK+NF5ZQ1GCgV/7SP9Lb4NxVA1+JGm2AAAAAElFTkSuQmCC">'
                        + '</div>',
                },
                {
                    columnWidth: '15%',
                    type: 'label',
                    label: '<div id="CopyIncWordsListButton">'
                        + '<input type="image" id="imageInc" alt="Set as search expression for selected words" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAFxGAABcRgEUlENBAAAAB3RJTUUH4wwLEC8qm3T7RwAAAPZJREFUWIXtlDFuwkAQRZ8JZRCF+19zBqS0UCLR5wo5DEUuEGpEGVEhEaWmRJTT5wxOE9CKBHbHMViAnzTSyt6dfbL3LzQ0pFMk1ifQSW3ackpkkWoBa+DdI5FKkfg+A16BD+Cxik3DShGoVKI4Mo7NDSVWdQnsJE6ua3uNnBJZbEGVAtHN/sIbw8q5PQFJuaS3WgQk5ThjV1pA0oukh4PHc2BjZs9nFwBGwFJS90doBmBmY0+T/8RwAEyBhaQt0AOevE1i2S2COeF4j6QJ0AeGZvYV6eHGcxWn9PjF7d0DVyfgTUHZc1Casxy8kKv4BRf/7A33xTes1z+oVY9+zwAAAABJRU5ErkJggg==">'
                        + '</div>',
                },
                {
                    columnWidth: '15%',
                    type: 'label',
                    label: '<div id="CopyExcWordsListButton">'
                        + '<input type="image" id="imageExc" alt="Set as exclude expression for selected words" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAFxGAABcRgEUlENBAAAAB3RJTUUH4wwLEC8v6x4PyAAAARxJREFUWIXtlTFqwzAUhj+Hju0l/qNk69TSwUsg0CFToTfo2hukdAsUuhYyJVOWlMw9gS6QLRdwh7og4sbSi1UHE38gkG3pfz/vPUvQ0xNPETk2wFWs6MBoIguMAfAFLC0mYikiv2fAC/AJXKYI6o8YA0lNFAfmobW+ifWpDPyaqN13YXVkNJGFNqQ0EAz2F9bfMDndNSBpIel6710uadaKAeADeJM0LIOPgCkwt4iEGqfw1vhzyqAT4BmYAffA2Dm3qNEwEzwHJE0l7SQ9RWhUaNSEZQZy4BV43O+JFBzMgKSJpK3fA+XzbY1GhSYZuOOn5isA59w78ADcNNCsYLkLYjQqdPcgSoX1Mjq2DEfzL3X36UQJWk97z3nxDar3UML2R6EaAAAAAElFTkSuQmCC">'
                        + '</div>',
                },
            ]
        },
        {
            name: 'filterPOSLabel',
            type: 'label',
            label: '<div class="form-title-hr-template-label form-filter-pos-template-label" id="filterPOSLabel">POS filter include</div><hr></hr>',
            width: '100%',
            labelWidth: '100%',
            rowHeight: '20px',
            padding: { left: 0, top: 5, right: 0, bottom: 5 },
        },
        {
            rowHeight: '20px',
            name: 'filterPOSRow1',
            padding: { left: 0, top: 0, right: 0, bottom: 0 },
            columns: [
                {
                    columnWidth: '50%',
                    bind: 'checkboxPOSAcronymValue',
                    type: 'boolean',
                    name: 'checkboxPOSAcronym',
                    label: '<div id="checkboxPOSAcronym">Acronym</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                },
                {
                    columnWidth: '50%',
                    bind: 'checkboxPOSPersonNameValue',
                    type: 'boolean',
                    name: 'checkboxPOSPersonName',
                    label: '<div id="checkboxPOSPersonName">Person Name</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                }
            ]
        },
        {
            rowHeight: '20px',
            name: 'filterPOSRow2',
            padding: { left: 0, top: 0, right: 0, bottom: 0 },
            columns: [
                {
                    columnWidth: '50%',
                    bind: 'checkboxPOSGeoValue',
                    type: 'boolean',
                    name: 'checkboxPOSGeo',
                    label: '<div id="checkboxPOSGeo">Geo</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                },
                {
                    columnWidth: '50%',
                    bind: 'checkboxPOSWikiValue',
                    type: 'boolean',
                    name: 'checkboxPOSWiki',
                    label: '<div id="checkboxPOSWiki">Wiki</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                }
            ]
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-search-word-label">Search expression</div><hr></hr>',
            width: '100%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 0, bottom: 5 },
        },
        {
            bind: 'textBoxValueSearchWord',
            name: 'textBoxValueSearchWord',
            type: 'text',
            label: '<div class="form-search-word-input"></div>',
            labelPosition: 'left',
            labelWidth: '0%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false,
            labelPadding: { left: 0, top: 5, right: 0, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-exclude-word-label">Exclude expression</div><hr></hr>',
            width: '100%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 0, bottom: 5 },
        },
        {
            bind: 'textBoxValueExcludeWord',
            name: 'textBoxValueExcludeWord',
            type: 'text',
            label: '<div class="form-exclude-word-input"></div>',
            labelPosition: 'left',
            labelWidth: '0%',
            align: 'left',
            width: '100%',
            minWidth: '100px',
            valign: 'top',
            required: false,
            labelPadding: { left: 0, top: 5, right: 0, bottom: 5 },
            padding: { left: 5, top: 5, right: 5, bottom: 5 },
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label words-select-satellites-label">Satellites</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            type: 'label',
            label: '<div class="label-words-types">Words types:</div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },
        /*checkboxMultiWordSatellitesTypeMask
        checkboxProperSatellitesTypeMask
        checkboxNormalizedSatellitesTypeMask
        checkboxReadableSatellitesTypeMask
        checkboxCommonSatellitesTypeMask
        checkboxNumbersSatellitesTypeMask*/
        //1 - multi-word, 2 - proper, 4 – normalized, 8 - readable, 16 - common, 32 - number
        {
            rowHeight: '20px',
            name: 'satellitesTypeMaskRow1',
            padding: { left: 0, top: 0, right: 0, bottom: 0 },
            columns: [
                {
                    columnWidth: '50%',
                    bind: 'checkboxMultiWordSatellitesTypeMask',
                    type: 'boolean',
                    name: 'checkboxMultiWordSatellitesTypeMask',
                    label: '<div id="checkboxMultiWordSatellitesTypeMask">multi-word</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                },
                {
                    columnWidth: '50%',
                    bind: 'checkboxProperSatellitesTypeMask',
                    type: 'boolean',
                    name: 'checkboxProperSatellitesTypeMask',
                    label: '<div id="checkboxProperSatellitesTypeMask">proper</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                }
            ]
        },
        {
            rowHeight: '20px',
            name: 'satellitesTypeMaskRow2',
            padding: { left: 0, top: 0, right: 0, bottom: 0 },
            columns: [
                {
                    columnWidth: '50%',
                    bind: 'checkboxNormalizedSatellitesTypeMask',
                    type: 'boolean',
                    name: 'checkboxNormalizedSatellitesTypeMask',
                    label: '<div id="checkboxNormalizedSatellitesTypeMask">normalized</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                },
                {
                    columnWidth: '50%',
                    bind: 'checkboxReadableSatellitesTypeMask',
                    type: 'boolean',
                    name: 'checkboxReadableSatellitesTypeMask',
                    label: '<div id="checkboxReadableSatellitesTypeMask">readable</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                }
            ]
        },
        {
            rowHeight: '20px',
            name: 'satellitesTypeMaskRow3',
            padding: { left: 0, top: 0, right: 0, bottom: 0 },
            columns: [
                {
                    columnWidth: '50%',
                    bind: 'checkboxCommonSatellitesTypeMask',
                    type: 'boolean',
                    name: 'checkboxCommonSatellitesTypeMask',
                    label: '<div id="checkboxCommonSatellitesTypeMask">common</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                },
                {
                    columnWidth: '50%',
                    bind: 'checkboxNumbersSatellitesTypeMask',
                    type: 'boolean',
                    name: 'checkboxNumbersSatellitesTypeMask',
                    label: '<div id="checkboxNumbersSatellitesTypeMask">numbers</div>',
                    labelPosition: 'left',
                    labelWidth: '60%',
                    align: 'left',
                    height: '20px',
                    rowHeight: '20px'
                }
            ]
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label words-select-satellites-label"></div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '10px',
            padding: { left: 0, top: 0, right: 10, bottom: 0 },
        },
        {
            bind: 'dropdownUniqueWords',
            name: 'dropdownUniqueWords',
            type: 'option',
            label: '<div class="unique-words-label">Unique words</div>',
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '160px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: 'Show any', value: '0' },
                { label: 'Show not unique without neighbors', value: '1' },
                { label: 'Hide not unique', value: '2' }
            ]
        },
        {
            bind: 'checkboxWordsGroupSubentries',
            name: 'checkboxWordsGroupSubentries',
            type: 'boolean',
            label: '<div id="checkboxWordsGroupSubentries">Group sub-entries</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'dropdownSortOrderWords',//dropdownUniqueWords
            name: 'dropdownSortOrderWords',//dropdownUniqueWords
            type: 'option',
            label: '<div class="sort-order-words-label">Word sort order</div>',//unique-words-label
            minWidth: '100px',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '160px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: 'ASC', value: '0' },
                { label: 'DESC', value: '1' }
            ]
        },
        {
            type: 'label',
            label: '<div class="synonymsButtonContaner">'
                + '<div class="synonymsButtonLabel"></div>'
                + '<div id="synonymsButtonElement"><input type="button" value="Synonyms" onclick="showSynonymsDialog()"></div></div>',
            labelPosition: 'left',
            align: 'left',
            width: '170px',
            labelWidth: '100%'
        },

        {
            columns: [
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'submitButton',
                    text: 'Draw',
                    align: 'left',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'shareButton',
                    text: 'Share',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'widgetButton',
                    text: 'Widget',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'saveButton',
                    text: 'Save',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '22%',
                    type: 'button',
                    name: 'helpButton',
                    text: 'Help',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                }
            ]
        }
    ];
}

/**
 * function return form options json for the DataSelect tab form
 * 
 * @param json AnalysisOptions
 * @returns
 */
function getFormTemplateAnalysis() {
    if (isDebugg) { console.log('04'); }
    return [
        {
            type: 'label',
            label: '<div class="form-clusterization-title-label">Clusterization algorithm and library:</div>',
            rowHeight: '40px'
        },
        {
            bind: 'radiobuttonClusterization',
            name: 'radiobuttonClusterization',
            type: 'text',
            label: '<div class="form-radiobutton-clusterization-input">Radio buttons</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'dropdownClustersNumber',
            name: 'dropdownClustersNumber',
            type: 'text',
            label: '<div class="form-dropdown-clusters-number-input">Clusters number</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'dropdownDataSource',
            name: 'dropdownDataSource',
            type: 'text',
            label: '<div class="form-data-source-input">Data source</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'dropdownMaxIterations',
            name: 'dropdownMaxIterations',
            type: 'text',
            label: '<div class="form-max-iterations-input">Max iterations if random start point</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            valign: 'top',
            required: false
        },
        {
            bind: 'checkboxPreserveDateRange',
            name: 'checkboxPreserveDateRange',
            type: 'boolean',
            label: '<div id="checkboxPreserveDateRange">Preserve date range</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            bind: 'isChange3',
            name: 'isChangeTab3',
            type: 'boolean',
            label: '<div id="checkboxIsChangeTab3"></div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left'
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-values-transformations-label">Values transformations</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'dropdownDataOmission',
            name: 'dropdownDataOmission',
            type: 'option',
            label: '<div class="data-omission-label">Data omission</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Ignore", value: '0' },
                { label: "Zero value", value: '1' },
                { label: "Average value per series", value: '2' },
                { label: "Previous value", value: '3' },
                { label: "Average value of all series", value: '4' }
            ],
            labelPadding: { left: 5, top: 0, right: 5, bottom: 2 },
            padding: { left: 5, top: 0, right: 15, bottom: 2 },
            rowHeight: '30px'
        },
        {
            bind: 'dropdownValueVSmooth',
            name: 'dropdownValueVSmooth',
            type: 'option',
            label: '<div class="value-smooth-label">Smoothing</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "No", value: '0' },
                { label: "Average common weighted", value: '4' },//value 4 from Scale for the old shared
                { label: "Average individual weighted", value: '5' },//value 5 from Scale for the old shared
                { label: "Moving average 3 points", value: '7' },//value 7 from Scale for the old shared
                { label: "Moving average 5 points", value: '8' },//value 8 from Scale for the old shared
                { label: "Moving average 7 points", value: '9' },//value 9 from Scale for the old shared
                { label: "Moving average 9 points", value: '110' },
                { label: "Moving average 12 points", value: '111' },
            ],
            labelPadding: { left: 5, top: 2, right: 5, bottom: 2 },
            padding: { left: 5, top: 2, right: 15, bottom: 2 },
            rowHeight: '30px'
        },
        {
            bind: 'dropdownValueVScale',
            name: 'dropdownValueVScale',
            type: 'option',
            label: '<div class="value-scale-label">Scaling</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Absolute", value: '1' },
                { label: "Percentage", value: '2' },
                { label: "Log10", value: '3' },
                { label: "Cumulative", value: '6' },
                //{label: "Moving average 3 points", value: '7'},
                //{label: "Moving average 5 points", value: '8'},
                //{label: "Moving average 7 points", value: '9'},
                { label: "Presence bits daily", value: '10' },
                { label: "Presence bits stream", value: '11' },
                { label: "Average common weighted", value: '14' },
                { label: "Average individual weighted", value: '15' }
            ],
            labelPadding: { left: 5, top: 2, right: 5, bottom: 2 },
            padding: { left: 5, top: 2, right: 15, bottom: 2 },
            rowHeight: '30px'
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-regressions-label" style="padding-top: 5px;">Regressions</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            labelPadding: { left: 5, top: 5, right: 5, bottom: 0 },
            padding: { left: 0, top: 5, right: 10, bottom: 0 },
        },
        {
            columns: [
                {
                    columnWidth: '60%',
                    bind: 'dropdownModel',
                    name: 'dropdownModel',
                    type: 'option',
                    label: '<div class="model-label">Model</div>',
                    labelPosition: 'left',
                    labelWidth: '25%',
                    align: 'left',
                    width: '130px',
                    minWidth: '100px',
                    maxWidth: '130px',
                    required: false,
                    component: 'jqxDropDownList',
                    options: [
                        { label: "No", value: '0' },
                        { label: "Linear least square", value: '1' },
                        { label: "Linear SVM least square", value: '2' },
                        { label: "SVM Exponential", value: '3' },
                        { label: "Polynomial x^Y", value: '4' },
                    ],
                    labelPadding: { left: 5, top: 0, right: 5, bottom: 2 },
                    padding: { left: 1, top: 0, right: 5, bottom: 2 },
                },
                {
                    columnWidth: '20%',
                    type: 'label',
                    label: '<div class="form-degree-label">degree:</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 0, right: 0, bottom: 2 },
                    padding: { left: 0, top: 0, right: 0, bottom: 2 },
                },
                {
                    type: 'label',
                    label: '<div id="numberBoxDegree"></div>',
                    labelPosition: 'left',
                    labelWidth: '65px',
                    align: 'left',
                    width: '20%',
                    labelPadding: { left: 0, top: 0, right: 0, bottom: 2 },
                    padding: { left: 0, top: 0, right: 10, bottom: 2 },
                }
            ]
        },
        {
            bind: 'dropdownInterpolationPrediction',
            name: 'dropdownInterpolationPrediction',
            type: 'option',
            label: '<div class="interpolation-prediction-label">Interpolation<br> and<br> prediction</div>',
            labelPosition: 'left',
            labelWidth: '25%',
            align: 'right',
            width: '200px',
            minWidth: '100px',
            maxWidth: '200px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "No", value: '0' },
                { label: "Interpolation (inside of a dates range of training data)", value: '1' },
                { label: "Interpolation & Prediction (and outside of a dates range of training data)", value: '2' },
            ],
            labelPadding: { left: 5, top: 2, right: 5, bottom: 2 },
            padding: { left: 5, top: 2, right: 15, bottom: 2 },
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-filter-model-label">Correlation filter</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'dropdownFilterDirection',
            name: 'dropdownFilterDirection',
            type: 'option',
            label: '<div class="filter-direction-label">Direction</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "None", value: '0' },
                { label: "Maximize", value: '1' },
                { label: "Minimize", value: '2' },
            ],
            labelPadding: { left: 5, top: 0, right: 5, bottom: 2 },
            padding: { left: 5, top: 0, right: 15, bottom: 2 },
        },
        {
            bind: 'dropdownFilterModel',
            name: 'dropdownFilterModel',
            type: 'option',
            label: '<div class="data-filter-model-label">Model</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Pair correlation - Pearson", value: '1' },
                { label: "Pair correlation Kendall's Tau", value: '2' },
                { label: "Pair correlation Spearman's RHO", value: '3' },
            ],
            labelPadding: { left: 5, top: 2, right: 5, bottom: 2 },
            padding: { left: 5, top: 2, right: 15, bottom: 2 },
            rowHeight: '30px'
        },
        {
            columns: [
                {
                    columnWidth: '50%',
                    type: 'label',
                    label: '<div class="form-filter-threshold-label">Threshold</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 2, right: 0, bottom: 2 }
                },
                {
                    type: 'label',
                    label: '<div id="numberBoxFilterThreshold"></div>',
                    labelPosition: 'left',
                    labelWidth: '100%',
                    align: 'right',
                    width: '50%',
                    labelPadding: { left: 4, top: 2, right: 0, bottom: 2 },
                    padding: { left: 4, top: 2, right: 10, bottom: 2 },
                }
            ]
        },
        {
            type: 'label',
            label: '<div class="form-title-hr-template-label form-clusterization-label">Clusterization</div><hr></hr>',
            width: '80%',
            labelWidth: '100%',
            rowHeight: '40px',
            padding: { left: 0, top: 5, right: 10, bottom: 5 },
        },
        {
            bind: 'dropdownClusterizationModel',
            name: 'dropdownClusterizationModel',
            type: 'option',
            label: '<div class="clusterization-model-label">Model</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "No", value: '0' },
                { label: "K-means", value: '1' },
                { label: "DBSCAN", value: '2' }
            ],
            labelPadding: { left: 5, top: 0, right: 5, bottom: 2 },
            padding: { left: 5, top: 0, right: 15, bottom: 2 },
        },
        {
            bind: 'dropdownCentroidInitialization',
            name: 'dropdownCentroidInitialization',
            type: 'option',
            label: '<div class="centroid-initialization-label">Centroid Initialization</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Standard", value: '1' },
                { label: "Random", value: '2' },
            ],
            labelPadding: { left: 5, top: 2, right: 5, bottom: 2 },
            padding: { left: 5, top: 2, right: 15, bottom: 2 },
        },
        {
            bind: 'dropdownFactorsData',
            name: 'dropdownFactorsData',
            type: 'option',
            label: '<div class="factors-data-label">Factors data</div>',
            labelPosition: 'left',
            labelWidth: '35%',
            align: 'left',
            width: '80%',
            minWidth: '100px',
            required: false,
            component: 'jqxDropDownList',
            options: [
                { label: "Original", value: '1' },
                { label: "Average", value: '2' },
                { label: "Presence", value: '3' },
            ],
            labelPadding: { left: 5, top: 2, right: 5, bottom: 0 },
            padding: { left: 5, top: 2, right: 15, bottom: 0 },
        },
        {
            columns: [
                {
                    columnWidth: '50%',
                    type: 'label',
                    label: '<div class="form-epsilon-label">Epsilon:</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 2, right: 0, bottom: 2 }
                },
                {
                    type: 'label',
                    label: '<div id="numberBoxEpsilon"></div>',
                    labelPosition: 'left',
                    labelWidth: '100%',
                    align: 'left',
                    width: '50%',
                    labelPadding: { left: 4, top: 2, right: 0, bottom: 2 },
                    padding: { left: 4, top: 2, right: 10, bottom: 2 },
                }
            ]
        },
        {
            columns: [
                {
                    columnWidth: '50%',
                    type: 'label',
                    label: '<div class="form-min-items-per-cluster-label">Min items per cluster:</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 2, right: 0, bottom: 2 }
                },
                {
                    type: 'label',
                    label: '<div id="numberBoxMinItemsPerCluster"></div>',
                    labelPosition: 'left',
                    labelWidth: '100%',
                    align: 'left',
                    width: '50%',
                    labelPadding: { left: 4, top: 2, right: 0, bottom: 2 },
                    padding: { left: 4, top: 2, right: 10, bottom: 2 },
                }
            ]
        },
        {
            columns: [
                {
                    columnWidth: '50%',
                    type: 'label',
                    label: '<div class="form-clusters-number-label">Clusters number:</div>',
                    labelPosition: 'left',
                    align: 'left',
                    labelPadding: { left: 5, top: 2, right: 0, bottom: 2 }
                },
                {
                    columnWidth: '50%',
                    type: 'label',
                    label: '<div id="numberBoxClustersNumber"></div>',
                    labelPosition: 'left',
                    labelWidth: '100%',
                    align: 'right',
                    width: '50%',
                    labelPadding: { left: 4, top: 2, right: 0, bottom: 2 },
                    padding: { left: 4, top: 2, right: 10, bottom: 2 },
                }
            ]
        },
        {
            columns: [
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'submitButton',
                    text: 'Draw',
                    align: 'left',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'shareButton',
                    text: 'Share',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'widgetButton',
                    text: 'Widget',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '18%',
                    type: 'button',
                    name: 'saveButton',
                    text: 'Save',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                },
                {
                    columnWidth: '22%',
                    type: 'button',
                    name: 'helpButton',
                    text: 'Help',
                    width: '50px',
                    height: '30px',
                    rowHeight: '40px',
                    align: 'left'
                }
            ]
        }
    ];
}

/**
 * Create form fot the DataSelect tab,from temlate
 * set initial values and asign js events.
 * @param template
 * @returns
 */
function createFormDataSelect(template) {
    if (isDebugg) { console.log('05'); }
    var sampleValue = {
        'nubmberBoxValue1': window.formInitData.nubmberBoxValue1,
        'nubmberBoxValue2': window.formInitData.nubmberBoxValue2,
        'nubmberBoxNeighborsFreqMin': window.formInitData.nubmberBoxNeighborsFreqMin,
        'nubmberBoxNeighborsFreqMax': window.formInitData.nubmberBoxNeighborsFreqMax,
        'nubmberBoxSatellitesFreqMin': window.formInitData.nubmberBoxSatellitesFreqMin,
        'nubmberBoxSatellitesFreqMax': window.formInitData.nubmberBoxSatellitesFreqMax,
        'nubmberBoxAppearanceMin': window.formInitData.nubmberBoxAppearanceMin,
        'nubmberBoxAppearanceMax': window.formInitData.nubmberBoxAppearanceMax,
        'dropdownValueDSource': window.formInitData.dropdownValueDSource,
        'dropdownValueDSource2': window.formInitData.dropdownValueDSource2,
        'dropdownDatesInterval': window.formInitData.dropdownDatesInterval,
        'dropdownValueIWords': window.formInitData.dropdownValueIWords,
        'dropdownDaysInterval': window.formInitData.dropdownDaysInterval,
        'dropdownSortDailyWordsBy': window.formInitData.dropdownSortDailyWordsBy,
        'dropdownMinIntersectedNeighbors': window.formInitData.dropdownMinIntersectedNeighbors,
        'satellitesOrderCondition': window.formInitData.satellitesOrderCondition,
        'dropdownDataSelectCache': window.formInitData.dropdownDataSelectCache,
        'dropdownValueMaxDepth': window.formInitData.dropdownValueMaxDepth,
        'dropdownMaxDistance': window.formInitData.dropdownMaxDistance,
        'dropdownValueVIndicator': window.formInitData.dropdownValueVIndicator,
        'dropdownsSortField': window.formInitData.dropdownsSortField,
        'checkboxRepeatSelectionMinMaxUnlim': window.formInitData.checkboxRepeatSelectionMinMaxUnlim,
        'checkboxPWIntersectedNeighbors': window.formInitData.checkboxPWIntersectedNeighbors,
        'checkboxPW1inNeighbors2': window.formInitData.checkboxPW1inNeighbors2,
        'checkboxPW2inNeighbors1': window.formInitData.checkboxPW2inNeighbors1,
        'checkboxIntersectedNeighbors': window.formInitData.checkboxIntersectedNeighbors,
        'checkboxArticlesList': window.formInitData.checkboxArticlesList,
        'dropdownValueVScale': window.formInitData.dropdownValueVScale,
        'dropdownValueVSmooth': window.formInitData.dropdownValueVSmooth,
        'checkboxAppearanceFullTimeValue': window.formInitData.checkboxAppearanceFullTimeValue,
        'dropdownDataOmission': window.formInitData.dropdownDataOmission,
        'checkboxPreserveDateRange': window.formInitData.checkboxPreserveDateRange,
        'textBoxValueDatesRange': window.formInitData.textBoxValueDatesRange,
        'textBoxValueExcludePeriods': window.formInitData.textBoxValueExcludePeriods,
        'nubmberBoxValueMaxWordsTotal': window.formInitData.textBoxValueMaxWordsTotal,
        'nubmberBoxNeighborsMaxItems': window.formInitData.textBoxValueMaxWordsTotal,
        'dropdownFilterModel': window.formInitData.dropdownFilterModel,
        'filterPOS': 0,
        'isChange1': false,
        'fixedNodesArr': {},
        'svgHeight': 0,
        'svgWidth': 0
    };

    window.sampleForm = $('#sampleForm');

    window.sampleForm.jqxForm({
        template: template,
        value: sampleValue,
        padding: { left: 1, top: 5, right: 1, bottom: 10 }
    });
    window.btnMain = window.sampleForm.jqxForm('getComponentByName', 'submitButton');
    window.sampleForm.jqxForm('getComponentByName', 'dropdownValueDSource2').jqxDropDownList('disabled', true);
    $('#dropdownValueDSource').jqxDropDownList({ dropDownHeight: 250, autoItemsHeight: true, source: DataSelectOptions });
    setDataSourceTooltip();

    $('#dropdownDatesInterval').jqxDropDownList({ dropDownHeight: 250, autoItemsHeight: true, source: dropdownDatesIntervalOptions, selectedIndex: 0 });

    if (firstLoadChart == 1 && isShare && hash != '') {
    } else if (firstLoadPage == 1) {
        if ($('#chartType').val() == CHART_TYPE_MOLECULAR) {//molecular
            $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
        } else if ($('#chartType').val() == CHART_TYPE_RADIAL) {//radial
            $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
        } else if ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
            $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
        } else if ($('#chartType').val() == CHART_TYPE_TIMESERIES) {//time series
            $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(50);
        } else if ($('#chartType').val() == CHART_TYPE_TIMELINE) {//timeline
            $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
        } else if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {//streamgraph
            $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
        } else if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
            $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
        } else {
            $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
        }
        $('#dropdownValueDSource').val(window.formInitData.dropdownValueDSource);
    }

    $('#dropdownValueDSource').on('change', function (event) {
        var dropdownValueDSourceVal = $('#dropdownValueDSource').jqxDropDownList('val');
        window.newValuesFormTab1.dropdownValueDSource = dropdownValueDSourceVal;
        var dropdownValueVIndicator = window.sampleForm.jqxForm('getComponentByName', 'dropdownValueVIndicator');

        var valueIndicatorListCur = JSON.stringify(valueIndicatorList);

        var drawingValueIndicatorCheckboxesBySource = '';

        if (typeof valueIndicatorListBySources[dropdownValueDSourceVal] !== "undefined") {
            valueIndicatorList = valueIndicatorListBySources[dropdownValueDSourceVal].list;
            if (typeof valueIndicatorListBySources[dropdownValueDSourceVal].valueDefault !== "undefined") {
                valueDefault = valueIndicatorListBySources[dropdownValueDSourceVal].valueDefault;
            }
        } else if (typeof valueIndicatorListBySources.valueIndicatorListDef !== "undefined") {
            valueIndicatorList = valueIndicatorListBySources.valueIndicatorListDef.list;
            if (typeof valueIndicatorListBySources.valueIndicatorListDef.valueDefault !== "undefined") {
                valueDefault = valueIndicatorListBySources.valueIndicatorListDef.valueDefault;
            }
        } else {
            valueIndicatorList = {};
        }
        if (typeof valueDefault == "undefined") {
            valueDefault = 0;
        }

        var valueIndicatorListNew = JSON.stringify(valueIndicatorList);
        if (valueIndicatorListCur != valueIndicatorListNew) {
            dropdownValueVIndicator.jqxDropDownList('clear');

            drawingValueIndicatorCheckboxesDefaultChecked = [];
            $.each(valueIndicatorList, function (index, item) {
                dropdownValueVIndicator.jqxDropDownList('addItem', { label: index, value: item });
                drawingValueIndicatorCheckboxesDefaultChecked.push(item + '');

                drawingValueIndicatorCheckboxesBySource =
                    drawingValueIndicatorCheckboxesBySource
                    + '<tr><td width="10%"><input type="checkbox" name="valueIndicator-' + item + '" class="valueIndicatorCheckboxItem" value="' + item + '"></td><td>' + index + '</td></tr>';
            });

            $('.valueIndicatorCheckboxItem').parent().parent().remove();
            $('#valueIndicatorTableContaner').append(drawingValueIndicatorCheckboxesBySource);
            $('.valueIndicatorCheckboxItem').on('click', function () {
                window.newValuesFormTab1.drawingValueIndicators = getDrawingValueIndicators();
                if (typeof curDataChartTW != "undefined") {
                    initPrepareFormData();
                    if (typeof curDataChartTW === "string") {
                        showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                    } else {
                        showChart(curDataChartTW, window.newValuesFormTab1);
                    }
                }
            });
            setDrawingValueIndicatorsChecked(drawingValueIndicatorCheckboxesDefaultChecked);
            dropdownValueVIndicator.jqxDropDownList('val', valueDefault);
            dropdownValueVIndicator.jqxDropDownList('dropDownHeight', 150);
        } else {
        }




        var dropdownsSortField = window.sampleForm.jqxForm('getComponentByName', 'dropdownsSortField');

        var sortFieldListCur = JSON.stringify(sortFieldList);


        if (typeof sortFieldListBySources[dropdownValueDSourceVal] !== "undefined") {
            sortFieldList = sortFieldListBySources[dropdownValueDSourceVal].list;
            if (typeof sortFieldListBySources[dropdownValueDSourceVal].valueDefault !== "undefined") {
                valueDefault = sortFieldListBySources[dropdownValueDSourceVal].valueDefault;
            }
        } else if (typeof sortFieldListBySources.sortFieldListDef !== "undefined") {
            sortFieldList = sortFieldListBySources.sortFieldListDef.list;
            if (typeof sortFieldListBySources.sortFieldListDef.valueDefault !== "undefined") {
                valueDefault = sortFieldListBySources.sortFieldListDef.valueDefault;
            }
        } else {
            //sortFieldList = {};
        }
        if (typeof valueDefault == "undefined") {
            valueDefault = 0;
        }

        var sortFieldListNew = JSON.stringify(sortFieldList);
        if ((sortFieldListCur != sortFieldListNew) && (Object.keys(sortFieldList).length > 0)){
            dropdownsSortField.jqxDropDownList('clear');

            $.each(sortFieldList, function (index, item) {
                dropdownsSortField.jqxDropDownList('addItem', { label: index, value: item });
            });

            dropdownsSortField.jqxDropDownList('val', valueDefault);
            dropdownsSortField.jqxDropDownList('dropDownHeight', 150);
        } else {
        }






        if (typeof namesIndicatorsBySources[dropdownValueDSourceVal] !== "undefined") {
            namesIndicators = namesIndicatorsBySources[dropdownValueDSourceVal];
        } else if (typeof namesIndicatorsBySources.namesDef !== "undefined") {
            namesIndicators = namesIndicatorsBySources.namesDef;
        } else {
            namesIndicators = {};
        }
        //if (firstLoadChart == 1) {
        if (typeof chartTypesConfigurationBySources[dropdownValueDSourceVal] !== "undefined") {
            chartTypesConfiguration = chartTypesConfigurationBySources[dropdownValueDSourceVal];
        } else if (typeof chartTypesConfigurationBySources.chartTypesConfigurationDef !== "undefined") {
            chartTypesConfiguration = chartTypesConfigurationBySources.chartTypesConfigurationDef;
        } else {
            chartTypesConfiguration = {};
        }
        window.prevChartType = $('#chartType').val();
        var chartType = $('#chartType').val();
        var selectedIndex = getIdBychartType(chartType);
        setChartTypesConfiguration(selectedIndex, 1);
        //}
        setDataSourceTooltip();
    });

    dropdownValueMaxDepth = window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth');
    dropdownValueMaxDepth.on('change', function (event) {
        var chartType = $('#chartType').val();
        if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
            window.newValuesFormTab1.changedRadLevelColors = getChangedRadLevelColors();
        }
        addColorpickersSelection(chartType);
        if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
            setChangedRadLevelColors(window.newValuesFormTab1.changedRadLevelColors);
        }
    });

    dropdownMaxDistance = window.sampleForm.jqxForm('getComponentByName', 'dropdownMaxDistance');
    dropdownMaxDistance.on('change', function (event) {
        var chartType = $('#chartType').val();
        if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
            window.newValuesFormTab1.changedRadLevelColors = getChangedRadLevelColors();
        }
        addColorpickersSelection(chartType);
        if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
            setChangedRadLevelColors(window.newValuesFormTab1.changedRadLevelColors);
        }
    });

/////////////////////////////////////////
    //dropdownDaysInterval
    var sourceDaysInterval = [];
    for (var i = 0; i <= 100; i++) {
        sourceDaysInterval.push({ label: i + '', value: i + '' });
    }
    var dropdownDaysInterval = $('.days-interval-label')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
        dropdownDaysInterval.find('input').attr('hidden', 'hidden');
        dropdownDaysInterval.append('<div id="dropdownDaysInterval"></div>');
    $("#dropdownDaysInterval").prev().css("display", "none");
 
    $("#dropdownDaysInterval").jqxDropDownList({
        source: sourceDaysInterval,
        selectedIndex: 1,
        width: '70px',
        //height: '30',
        //autoDropDownHeight: true,
        displayMember: 'label',
        valueMember: 'value',
        dropDownHeight: 200
    });
    $('#dropdownDaysInterval').on('change', function (event) {
        var dropdownDaysIntervalVal = $('#dropdownDaysInterval').jqxDropDownList('val');
        window.newValuesFormTab1.dropdownDaysInterval = dropdownDaysIntervalVal;
    });


    //dropdownMinIntersectedNeighbors
    var sourceMinIntersectedNeighbors = [];
    for (var i = 0; i <= 100; i++) {
        sourceMinIntersectedNeighbors.push({ label: i + '', value: i + '' });
    }
    var dropdownMinIntersectedNeighbors = $('.min-intersected-neighbors-label')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
        dropdownMinIntersectedNeighbors.find('input').attr('hidden', 'hidden');
        dropdownMinIntersectedNeighbors.append('<div id="dropdownMinIntersectedNeighbors"></div>');
    $("#dropdownMinIntersectedNeighbors").prev().css("display", "none");
 
    $("#dropdownMinIntersectedNeighbors").jqxDropDownList({
        source: sourceMinIntersectedNeighbors,
        selectedIndex: 1,
        width: '70px',
        //height: '30',
        //autoDropDownHeight: true,
        displayMember: 'label',
        valueMember: 'value',
        dropDownHeight: 200
    });
    $('#dropdownMinIntersectedNeighbors').on('change', function (event) {
        var dropdownMinIntersectedNeighborsVal = $('#dropdownMinIntersectedNeighbors').jqxDropDownList('val');
        window.newValuesFormTab1.dropdownMinIntersectedNeighbors = dropdownMinIntersectedNeighborsVal;
    });

/////////////////////////////////////////
 /*   var sourceMaxNodes = [
        { label: "Unlimited", value: '0' },
    ];
    for (var i = 1; i < 30; i++) {
        sourceMaxNodes.push({ label: i + '', value: i + '' });
    }
    for (var i = 30; i <= 100; i += 10) {
        sourceMaxNodes.push({ label: i + '', value: i + '' });
    }
    sourceMaxNodes.push({ label: '5%', value: '105' });
    for (var i = 10; i <= 100; i += 10) {
        sourceMaxNodes.push({ label: i + '%', value: (i + 100) + '' });
    }
    var elMaxNodesCont = $('.days-interval-label')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elMaxNodesCont.find('input').attr('hidden', 'hidden');
    elMaxNodesCont.append('<div id="jqxWidgetMaxNodes"></div>');
    $("#jqxWidgetMaxNodes").prev().css("display", "none");
    $("#jqxWidgetMaxNodes").jqxDropDownList({
        source: sourceMaxNodes,
        selectedIndex: 37,
        width: '135px',
        //height: '30',
        //autoDropDownHeight: true,
        displayMember: 'label',
        valueMember: 'value',
        dropDownHeight: 250
    });

    $("#jqxWidgetMaxNodes").on('change', function (event) {
        var dropdownMaxNodes = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownMaxNodes');
        dropdownMaxNodes.jqxInput('val', event.args.item.value);
    });

    var sourceAutoAdjust = [
        { label: "No", value: '1' },
        { label: "Spread wide", value: '2' },
        { label: "Rectangle perimeter", value: '3' },
        { label: "Circle perimeter", value: '4' },
        { label: "Circle + center", value: '5' },
        { label: "Circle2 + corner", value: '6' },
        { label: "Circle in a circle", value: '7' },
        { label: "Cloud around center node", value: '8' }
    ];
    var elAutoAdjustCont = $('.form-dropdown-auto-adjust-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elAutoAdjustCont.find('input').attr('hidden', 'hidden');
    elAutoAdjustCont.append('<div id="jqxWidgetAutoAdjust"></div>');
    $("#jqxWidgetAutoAdjust").prev().css("display", "none");
    $("#jqxWidgetAutoAdjust").jqxDropDownList({
        source: sourceAutoAdjust,
        selectedIndex: (1 - 1),
        width: '135px',
        height: '30',
        autoDropDownHeight: true,
        displayMember: 'label',
        valueMember: 'value'
    });
*/
/////////////////////////////////////////////////







    window.sampleForm.on('formDataChange', function (event) {
        var args = event.args;
        if (isDebugg) { console.log('051 args.value.isChange1 === true'); }
        window.previousValueFormTab1 = args.previousValue;
        if (firstLoadChart == 1 && isShare && hash != '') {
            if (typeof window.newValuesFormTab1.fixedNodesArr !== "undefined") {
                args.value.fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
            }
            if (typeof window.newValuesFormTab1.svgHeight !== "undefined") {
                args.value.svgHeight = window.newValuesFormTab1.svgHeight;
            }
            if (typeof window.newValuesFormTab1.svgWidth !== "undefined") {
                args.value.svgWidth = window.newValuesFormTab1.svgWidth;
            }
            if (typeof window.newValuesFormTab1.hiddenLegendItems !== "undefined") {
                args.value.hiddenLegendItems = window.newValuesFormTab1.hiddenLegendItems;
            }
            if (typeof window.newValuesFormTab1.drawingValueIndicators !== "undefined") {
                args.value.drawingValueIndicators = window.newValuesFormTab1.drawingValueIndicators;
            }
            if (typeof window.newValuesFormTab1.textualWordsGridSorting !== "undefined") {
                args.value.textualWordsGridSorting = window.newValuesFormTab1.textualWordsGridSorting;
            }
            if (typeof window.newValuesFormTab1.textualWordsGridPagesize !== "undefined") {
                args.value.textualWordsGridPagesize = window.newValuesFormTab1.textualWordsGridPagesize;
            }
            if (typeof window.newValuesFormTab1.textualWordsGridPagenum !== "undefined") {
                args.value.textualWordsGridPagenum = window.newValuesFormTab1.textualWordsGridPagenum;
            }
            if (typeof window.newValuesFormTab1.metadataWord !== "undefined") {
                args.value.metadataWord = window.newValuesFormTab1.metadataWord;
            }
            if (typeof window.newValuesFormTab1.metadataDate !== "undefined") {
                args.value.metadataDate = window.newValuesFormTab1.metadataDate;
            }
        }
        args.value.dropdownValueDSource = $('#dropdownValueDSource').val();
        args.value.dropdownDatesInterval = $('#dropdownDatesInterval').val();
        args.value.dropdownDaysInterval = $('#dropdownDaysInterval').val();
        args.value.dropdownMinIntersectedNeighbors = $('#dropdownMinIntersectedNeighbors').val();
        //args.value.dropdownSortDailyWordsBy = $('#dropdownSortDailyWordsBy').val();

        //dropdownMinIntersectedNeighbors
        if (typeof window.newValuesFormTab1 != "undefined") {
            if (typeof window.newValuesFormTab1.textBoxValueDatesRange != "undefined") {
                window.previousValueFormTab1.textBoxValueDatesRange = window.newValuesFormTab1.textBoxValueDatesRange;
            }
            if (typeof window.newValuesFormTab1.textBoxValueExcludePeriods != "undefined") {
                window.previousValueFormTab1.textBoxValueExcludePeriods = window.newValuesFormTab1.textBoxValueExcludePeriods;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxValue1 != "undefined") {
                window.previousValueFormTab1.nubmberBoxValue1 = window.newValuesFormTab1.nubmberBoxValue1;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxValue2 != "undefined") {
                window.previousValueFormTab1.nubmberBoxValue2 = window.newValuesFormTab1.nubmberBoxValue2;
            }
            if (typeof window.newValuesFormTab1.checkboxRepeatSelectionMinMaxUnlim != "undefined") {
                window.previousValueFormTab1.checkboxRepeatSelectionMinMaxUnlim = window.newValuesFormTab1.checkboxRepeatSelectionMinMaxUnlim;
            }
            if (typeof window.newValuesFormTab1.checkboxValueIndicatorLimitsStrict == "undefined") {
                window.newValuesFormTab1.checkboxValueIndicatorLimitsStrict = window.formInitData.checkboxValueIndicatorLimitsStrict;
            }
            //window.newValuesFormTab1.checkboxValueIndicatorLimitsStrict = window.newValuesFormTab4.checkboxValueIndicatorLimitsStrict;
    
            if (typeof window.newValuesFormTab1.checkboxPWIntersectedNeighbors != "undefined") {
                window.previousValueFormTab1.checkboxPWIntersectedNeighbors = window.newValuesFormTab1.checkboxPWIntersectedNeighbors;
            }
            if (typeof window.newValuesFormTab1.checkboxPW1inNeighbors2 != "undefined") {
                window.previousValueFormTab1.checkboxPW1inNeighbors2 = window.newValuesFormTab1.checkboxPW1inNeighbors2;
            }
            if (typeof window.newValuesFormTab1.checkboxPW2inNeighbors1 != "undefined") {
                window.previousValueFormTab1.checkboxPW2inNeighbors1 = window.newValuesFormTab1.checkboxPW2inNeighbors1;
            }
            if (typeof window.newValuesFormTab1.checkboxIntersectedNeighbors != "undefined") {
                window.previousValueFormTab1.checkboxIntersectedNeighbors = window.newValuesFormTab1.checkboxIntersectedNeighbors;
            }
            if (typeof window.newValuesFormTab1.checkboxArticlesList != "undefined") {
                window.previousValueFormTab1.checkboxArticlesList = window.newValuesFormTab1.checkboxArticlesList;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxNeighborsFreqMin != "undefined") {
                window.previousValueFormTab1.nubmberBoxNeighborsFreqMin = window.newValuesFormTab1.nubmberBoxNeighborsFreqMin;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxNeighborsFreqMax != "undefined") {
                window.previousValueFormTab1.nubmberBoxNeighborsFreqMax = window.newValuesFormTab1.nubmberBoxNeighborsFreqMax;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxSatellitesFreqMin != "undefined") {
                window.previousValueFormTab1.nubmberBoxSatellitesFreqMin = window.newValuesFormTab1.nubmberBoxSatellitesFreqMin;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxSatellitesFreqMax != "undefined") {
                window.previousValueFormTab1.nubmberBoxSatellitesFreqMax = window.newValuesFormTab1.nubmberBoxSatellitesFreqMax;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxAppearanceMin != "undefined") {
                window.previousValueFormTab1.nubmberBoxAppearanceMin = window.newValuesFormTab1.nubmberBoxAppearanceMin;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxAppearanceMax != "undefined") {
                window.previousValueFormTab1.nubmberBoxAppearanceMax = window.newValuesFormTab1.nubmberBoxAppearanceMax;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxValueMaxWordsTotal != "undefined") {
                window.previousValueFormTab1.nubmberBoxValueMaxWordsTotal = window.newValuesFormTab1.nubmberBoxValueMaxWordsTotal;
            }
            if (typeof window.newValuesFormTab1.nubmberBoxNeighborsMaxItems != "undefined") {
                window.previousValueFormTab1.nubmberBoxNeighborsMaxItems = window.newValuesFormTab1.nubmberBoxNeighborsMaxItems;
            }
            if (typeof window.newValuesFormTab1.dropdownSortDailyWordsBy != "undefined") {
                window.previousValueFormTab1.dropdownSortDailyWordsBy = window.newValuesFormTab1.dropdownSortDailyWordsBy;
            }
            
            if (typeof window.newValuesFormTab1.chartType != "undefined") {
                window.previousValueFormTab1.chartType = window.newValuesFormTab1.chartType;
            }
            if (typeof window.newValuesFormTab1.textBoxValueExcludePeriods != "undefined") {
                window.previousValueFormTab1.textBoxValueExcludePeriods = window.newValuesFormTab1.textBoxValueExcludePeriods;
            }
            if (typeof window.newValuesFormTab1.checkboxConnectorsLinesValue != "undefined") {
                window.previousValueFormTab1.checkboxConnectorsLinesValue = window.newValuesFormTab1.checkboxConnectorsLinesValue;
            } else if (typeof window.previousValueFormTab1.checkboxConnectorsLinesValue == "undefined") {
                window.previousValueFormTab1.checkboxConnectorsLinesValue = true;
            }
            if (typeof window.newValuesFormTab1.checkboxIndentValuesRelatedValue != "undefined") {
                window.previousValueFormTab1.checkboxIndentValuesRelatedValue = window.newValuesFormTab1.checkboxIndentValuesRelatedValue;
            } else if (typeof window.previousValueFormTab1.checkboxIndentValuesRelatedValue == "undefined") {
                window.previousValueFormTab1.checkboxIndentValuesRelatedValue = false;
            }
            if (typeof window.newValuesFormTab1.checkboxNotIncludeSingleNodesValue != "undefined") {
                window.previousValueFormTab1.checkboxNotIncludeSingleNodesValue = window.newValuesFormTab1.checkboxNotIncludeSingleNodesValue;
            } else if (typeof window.previousValueFormTab1.checkboxNotIncludeSingleNodesValue == "undefined") {
                window.previousValueFormTab1.checkboxNotIncludeSingleNodesValue = false;
            }
            if (typeof window.newValuesFormTab1.checkboxReduceOverlapValue != "undefined") {
                window.previousValueFormTab1.checkboxReduceOverlapValue = window.newValuesFormTab1.checkboxReduceOverlapValue;
            } else if (typeof window.previousValueFormTab1.checkboxReduceOverlapValue == "undefined") {
                window.previousValueFormTab1.checkboxReduceOverlapValue = false;
            }

            if (typeof window.newValuesFormTab1.checkboxShowHideLegendValue != "undefined") {
                window.previousValueFormTab1.checkboxShowHideLegendValue = window.newValuesFormTab1.checkboxShowHideLegendValue;
            } else if (typeof window.previousValueFormTab1.checkboxShowHideLegendValue == "undefined") {
                window.previousValueFormTab1.checkboxShowHideLegendValue = true;
            }
            if (typeof window.newValuesFormTab1.checkboxShowHideSeriesLabelValue != "undefined") {
                window.previousValueFormTab1.checkboxShowHideSeriesLabelValue = window.newValuesFormTab1.checkboxShowHideSeriesLabelValue;
            } else if (typeof window.previousValueFormTab1.checkboxShowHideSeriesLabelValue == "undefined") {
                window.previousValueFormTab1.checkboxShowHideSeriesLabelValue = true;
            }
            if (typeof window.newValuesFormTab1.checkboxShowHideTitleValue != "undefined") {
                window.previousValueFormTab1.checkboxShowHideTitleValue = window.newValuesFormTab1.checkboxShowHideTitleValue;
            } else if (typeof window.previousValueFormTab1.checkboxShowHideTitleValue == "undefined") {
                window.previousValueFormTab1.checkboxShowHideTitleValue = true;
            }
            if (typeof window.newValuesFormTab1.checkboxAddNodesFixLevel != "undefined") {
                window.previousValueFormTab1.checkboxAddNodesFixLevel = window.newValuesFormTab1.checkboxAddNodesFixLevel;
            } else if (typeof window.previousValueFormTab1.checkboxAddNodesFixLevel == "undefined") {
                window.previousValueFormTab1.checkboxAddNodesFixLevel = true;
            }
            if (typeof window.newValuesFormTab1.checkboxShowHideTableHeaderValue != "undefined") {
                window.previousValueFormTab1.checkboxShowHideTableHeaderValue = window.newValuesFormTab1.checkboxShowHideTableHeaderValue;
            } else if (typeof window.previousValueFormTab1.checkboxShowHideTableHeaderValue == "undefined") {
                window.previousValueFormTab1.checkboxShowHideTableHeaderValue = true;
            }
            if (typeof window.newValuesFormTab1.checkboxOrientationHorizontalVertical != "undefined") {
                window.previousValueFormTab1.checkboxOrientationHorizontalVertical = window.newValuesFormTab1.checkboxOrientationHorizontalVertical;
            } else if (typeof window.previousValueFormTab1.checkboxOrientationHorizontalVertical == "undefined") {
                window.previousValueFormTab1.checkboxOrientationHorizontalVertical = true;
            }
            if (typeof window.newValuesFormTab1.checkboxShowHideMetadataColumnValue != "undefined") {
                window.previousValueFormTab1.checkboxShowHideMetadataColumnValue = window.newValuesFormTab1.checkboxShowHideMetadataColumnValue;
            } else if (typeof window.previousValueFormTab1.checkboxShowHideMetadataColumnValue == "undefined") {
                window.previousValueFormTab1.checkboxShowHideMetadataColumnValue = true;
            }
            if (typeof window.newValuesFormTab1.checkboxShowHidePaginationValue != "undefined") {
                window.previousValueFormTab1.checkboxShowHidePaginationValue = window.newValuesFormTab1.checkboxShowHidePaginationValue;
            } else if (typeof window.previousValueFormTab1.checkboxShowHidePaginationValue == "undefined") {
                window.previousValueFormTab1.checkboxShowHidePaginationValue = true;
            }
            if (typeof window.newValuesFormTab1.checkboxShowHideMenuValue != "undefined") {
                window.previousValueFormTab1.checkboxShowHideMenuValue = window.newValuesFormTab1.checkboxShowHideMenuValue;
            } else if (typeof window.previousValueFormTab1.checkboxShowHideMenuValue == "undefined") {
                window.previousValueFormTab1.checkboxShowHideMenuValue = false;
            }
            if (typeof window.newValuesFormTab1.checkboxConnectNullsValue != "undefined") {
                window.previousValueFormTab1.checkboxConnectNullsValue = window.newValuesFormTab1.checkboxConnectNullsValue;
            } else if (typeof window.previousValueFormTab1.checkboxConnectNullsValue == "undefined") {
                window.previousValueFormTab1.checkboxConnectNullsValue = window.formInitData.checkboxConnectNulls;
            }
            if (typeof window.newValuesFormTab1.dropdownConnectorLinesSourceValue != "undefined") {
                window.previousValueFormTab1.dropdownConnectorLinesSourceValue = window.newValuesFormTab1.dropdownConnectorLinesSourceValue;
            } else if (typeof window.previousValueFormTab1.dropdownConnectorLinesSourceValue == "undefined") {
                window.previousValueFormTab1.dropdownConnectorLinesSourceValue = window.formInitData.dropdownConnectorLinesSource;
            }
            if (typeof window.newValuesFormTab1.circleSizeMult != "undefined") {
                window.previousValueFormTab1.circleSizeMult = window.newValuesFormTab1.circleSizeMult * 1;
            }
            if (typeof window.newValuesFormTab1.fontSizeMult != "undefined") {
                window.previousValueFormTab1.fontSizeMult = window.newValuesFormTab1.fontSizeMult * 1;
            }
            if (typeof window.newValuesFormTab1.sourcePointsSizeMult != "undefined") {
                window.previousValueFormTab1.sourcePointsSizeMult = window.newValuesFormTab1.sourcePointsSizeMult * 1;
            }
            if (typeof window.newValuesFormTab1.lineWidthMult != "undefined") {
                window.previousValueFormTab1.lineWidthMult = window.newValuesFormTab1.lineWidthMult * 1;
            }
            if (typeof window.newValuesFormTab1.gravityValue != "undefined") {
                window.previousValueFormTab1.gravityValue = window.newValuesFormTab1.gravityValue * 1;
            }
            if (typeof window.newValuesFormTab1.indentValue != "undefined") {
                window.previousValueFormTab1.indentValue = window.newValuesFormTab1.indentValue * 1;
            }
            if (typeof window.newValuesFormTab1.radiusValue != "undefined") {
                window.previousValueFormTab1.radiusValue = window.newValuesFormTab1.radiusValue * 1;
            }
        } else {
            window.previousValueFormTab1.checkboxConnectorsLinesValue = true;
            window.previousValueFormTab1.checkboxIndentValuesRelatedValue = false;
            window.previousValueFormTab1.checkboxNotIncludeSingleNodesValue = false;
            window.previousValueFormTab1.checkboxReduceOverlapValue = false;
        }
        window.newValuesFormTab1 = args.value;
        window.newValuesFormTab1.textBoxValueDatesRange = $('#inputjqxWidgetDateRange').val();
        window.newValuesFormTab1.textBoxValueExcludePeriods = $('.form-exclude-periods-input').closest("tr").find('input').val();
        window.newValuesFormTab1.nubmberBoxValue1 = $.trim($('#nubmberBoxValue1 > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxValue2 = $.trim($('#nubmberBoxValue2 > input:nth-child(1)').val());
        window.newValuesFormTab1.checkboxRepeatSelectionMinMaxUnlim = $('.checkbox-repeat-selection-unlim-label').closest("tr").find('input').val();
        window.newValuesFormTab1.checkboxPWIntersectedNeighbors = $('.checkbox-pop-words-intersected-neighbors-label').closest("tr").find('input').val();
        window.newValuesFormTab1.checkboxPW1inNeighbors2 = $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr").find('input').val();
        window.newValuesFormTab1.checkboxPW2inNeighbors1 = $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr").find('input').val();
        window.newValuesFormTab1.checkboxIntersectedNeighbors = $('.checkbox-intersected-neighbors-label').closest("tr").find('input').val();
        window.newValuesFormTab1.checkboxArticlesList = $('.checkbox-articles-list-label').closest("tr").find('input').val();
        window.newValuesFormTab1.nubmberBoxNeighborsFreqMin = $.trim($('#nubmberBoxNeighborsFreqMin > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxNeighborsFreqMax = $.trim($('#nubmberBoxNeighborsFreqMax > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxSatellitesFreqMin = $.trim($('#nubmberBoxSatellitesFreqMin > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxSatellitesFreqMax = $.trim($('#nubmberBoxSatellitesFreqMax > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxValueMaxWordsTotal = $.trim($('#nubmberBoxValueMaxWordsTotal > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxNeighborsMaxItems = $.trim($('#nubmberBoxNeighborsMaxItems > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxAppearanceMin = $.trim($('#nubmberBoxAppearanceMin > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxAppearanceMax = $.trim($('#nubmberBoxAppearanceMax > input:nth-child(1)').val());

        window.newValuesFormTab1.chartType = $('#chartType').val();
        //Drawing
        if (typeof window.newValuesFormTab2 === "undefined") {
            window.newValuesFormTab2 = {};
        }
        if (typeof window.previousValueFormTab2 === "undefined") {
            window.previousValueFormTab2 = {};
        }
        if (typeof window.newValuesFormTab2.checkboxConnectorsLinesValue == "undefined") {
            window.newValuesFormTab2.checkboxConnectorsLinesValue = window.formInitData.checkboxConnectorsLinesValue;
        }
        window.newValuesFormTab1.checkboxConnectorsLinesValue = window.newValuesFormTab2.checkboxConnectorsLinesValue;
        if (typeof window.newValuesFormTab2.checkboxIndentValuesRelatedValue == "undefined") {
            window.newValuesFormTab2.checkboxIndentValuesRelatedValue = window.formInitData.checkboxIndentValuesRelatedValue;
        }
        window.newValuesFormTab1.checkboxIndentValuesRelatedValue = window.newValuesFormTab2.checkboxIndentValuesRelatedValue;
        if (typeof window.newValuesFormTab2.checkboxNotIncludeSingleNodesValue == "undefined") {
            window.newValuesFormTab2.checkboxNotIncludeSingleNodesValue = window.formInitData.checkboxNotIncludeSingleNodesValue;
        }
        window.newValuesFormTab1.checkboxNotIncludeSingleNodesValue = window.newValuesFormTab2.checkboxNotIncludeSingleNodesValue;

        if (typeof window.newValuesFormTab2.checkboxReduceOverlapValue == "undefined") {
            window.newValuesFormTab2.checkboxReduceOverlapValue = window.formInitData.checkboxReduceOverlapValue;
        }
        window.newValuesFormTab1.checkboxReduceOverlapValue = window.newValuesFormTab2.checkboxReduceOverlapValue;

        if (typeof window.newValuesFormTab2.checkboxShowHideLegend == "undefined") {
            window.newValuesFormTab2.checkboxShowHideLegend = window.formInitData.checkboxShowHideLegend;
        }
        window.newValuesFormTab1.checkboxShowHideLegendValue = window.newValuesFormTab2.checkboxShowHideLegendValue;
        if (typeof window.newValuesFormTab2.checkboxShowHideSeriesLabel == "undefined") {
            window.newValuesFormTab2.checkboxShowHideSeriesLabel = window.formInitData.checkboxShowHideSeriesLabel;
        }
        window.newValuesFormTab1.checkboxShowHideSeriesLabelValue = window.newValuesFormTab2.checkboxShowHideSeriesLabelValue;
        if (typeof window.newValuesFormTab2.checkboxShowHideTitle == "undefined") {
            window.newValuesFormTab2.checkboxShowHideTitle = window.formInitData.checkboxShowHideTitle;
        }
        window.newValuesFormTab1.checkboxShowHideTitleValue = window.newValuesFormTab2.checkboxShowHideTitleValue;
        if (typeof window.newValuesFormTab2.checkboxAddNodesFixLevel == "undefined") {
            window.newValuesFormTab2.checkboxAddNodesFixLevel = window.formInitData.checkboxAddNodesFixLevel;
        }
        window.newValuesFormTab1.checkboxAddNodesFixLevel = window.newValuesFormTab2.checkboxAddNodesFixLevel;
        if (typeof window.newValuesFormTab2.checkboxShowHideTableHeader == "undefined") {
            window.newValuesFormTab2.checkboxShowHideTableHeader = window.formInitData.checkboxShowHideTableHeader;
        }
        window.newValuesFormTab1.checkboxShowHideTableHeaderValue = window.newValuesFormTab2.checkboxShowHideTableHeaderValue;
        if (typeof window.newValuesFormTab2.checkboxOrientationHorizontalVertical == "undefined") {
            window.newValuesFormTab2.checkboxOrientationHorizontalVertical = window.formInitData.checkboxOrientationHorizontalVertical;
        }
        window.newValuesFormTab1.checkboxOrientationHorizontalVertical = window.newValuesFormTab2.checkboxOrientationHorizontalVertical;
        if (typeof window.newValuesFormTab2.checkboxShowHideMetadataColumn == "undefined") {
            window.newValuesFormTab2.checkboxShowHideMetadataColumn = window.formInitData.checkboxShowHideMetadataColumn;
        }
        window.newValuesFormTab1.checkboxShowHideMetadataColumnValue = window.newValuesFormTab2.checkboxShowHideMetadataColumnValue;
        if (typeof window.newValuesFormTab2.checkboxShowHidePagination == "undefined") {
            window.newValuesFormTab2.checkboxShowHidePagination = window.formInitData.checkboxShowHidePagination;
        }
        window.newValuesFormTab1.checkboxShowHidePaginationValue = window.newValuesFormTab2.checkboxShowHidePaginationValue;
        if (typeof window.newValuesFormTab2.checkboxShowHideMenu == "undefined") {
            window.newValuesFormTab2.checkboxShowHideMenu = window.formInitData.checkboxShowHideMenu;
        }
        window.newValuesFormTab1.checkboxShowHideMenuValue = window.newValuesFormTab2.checkboxShowHideMenuValue;
        if (typeof window.newValuesFormTab2.checkboxConnectNulls == "undefined") {
            window.newValuesFormTab2.checkboxConnectNulls = window.formInitData.checkboxConnectNulls;
        }
        window.newValuesFormTab1.checkboxConnectNullsValue = window.newValuesFormTab2.checkboxConnectNullsValue;
        if (typeof window.newValuesFormTab2.dropdownConnectorLinesSource == "undefined") {
            window.newValuesFormTab2.dropdownConnectorLinesSource = window.formInitData.dropdownConnectorLinesSource;
        }
        window.newValuesFormTab1.dropdownConnectorLinesSourceValue = window.newValuesFormTab2.dropdownConnectorLinesSourceValue;
        if (typeof window.newValuesFormTab2.circleSizeMult == "undefined") {
            window.newValuesFormTab2.circleSizeMult = window.formInitData.circleSizeMult * 1;
        }
        window.newValuesFormTab1.circleSizeMult = window.newValuesFormTab2.circleSizeMult;
        if (typeof window.newValuesFormTab2.fontSizeMult == "undefined") {
            window.newValuesFormTab2.fontSizeMult = window.formInitData.fontSizeMult * 1;
        }
        window.newValuesFormTab1.fontSizeMult = window.newValuesFormTab2.fontSizeMult;

        window.newValuesFormTab1.nubmberBoxValueMaxWordsTotalTextual =
            window.newValuesFormTab2.nubmberBoxValueMaxWordsTotalTextual =
            $.trim($('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val());
        window.newValuesFormTab1.nubmberBoxValueFontSize =
            window.newValuesFormTab2.nubmberBoxValueFontSize =
            $.trim($('#nubmberBoxValueFontSize > input:nth-child(1)').val());

        if (typeof window.newValuesFormTab2.sourcePointsSizeMult == "undefined") {
            window.newValuesFormTab2.sourcePointsSizeMult = window.formInitData.sourcePointsSizeMult * 1;
        }
        window.newValuesFormTab1.sourcePointsSizeMult = window.newValuesFormTab2.sourcePointsSizeMult;
        if (typeof window.newValuesFormTab2.lineWidthMult == "undefined") {
            window.newValuesFormTab2.lineWidthMult = window.formInitData.lineWidthMult * 1;
        }
        window.newValuesFormTab1.lineWidthMult = window.newValuesFormTab2.lineWidthMult;
        if (typeof window.newValuesFormTab2.gravityValue == "undefined") {
            window.newValuesFormTab2.gravityValue = window.formInitData.gravityValue * 1;
        }
        window.newValuesFormTab1.gravityValue = window.newValuesFormTab2.gravityValue;
        if (typeof window.newValuesFormTab2.indentValue == "undefined") {
            window.newValuesFormTab2.indentValue = window.formInitData.indentValue * 1;
        }
        window.newValuesFormTab1.indentValue = window.newValuesFormTab2.indentValue;
        if (typeof window.newValuesFormTab2.radiusValue == "undefined") {
            window.newValuesFormTab2.radiusValue = window.formInitData.radiusValue * 1;
        }
        window.newValuesFormTab1.radiusValue = window.newValuesFormTab2.radiusValue;
        window.newValuesFormTab1.isChange2 = window.newValuesFormTab2.isChange2;

        if (typeof window.newValuesFormTab2.dropdownMaxNodes == "undefined") {
            window.newValuesFormTab2.dropdownMaxNodes = window.formInitData.dropdownMaxNodes;
        }
        window.newValuesFormTab1.dropdownMaxNodes = window.newValuesFormTab2.dropdownMaxNodes;
        if (typeof window.newValuesFormTab2.dropdownColorsSelection == "undefined") {
            window.newValuesFormTab2.dropdownColorsSelection = window.formInitData.dropdownColorsSelection;
        }
        window.newValuesFormTab1.dropdownColorsSelection = window.newValuesFormTab2.dropdownColorsSelection;
        if (typeof window.newValuesFormTab2.dropdownConnectorLineWidthIndicator == "undefined") {
            window.newValuesFormTab2.dropdownConnectorLineWidthIndicator = window.formInitData.dropdownConnectorLineWidthIndicator;
        }
        window.newValuesFormTab1.dropdownConnectorLineWidthIndicator = window.newValuesFormTab2.dropdownConnectorLineWidthIndicator;
        if (typeof window.newValuesFormTab2.dropdownConnectorLinesColor == "undefined") {
            window.newValuesFormTab2.dropdownConnectorLinesColor = window.formInitData.dropdownConnectorLinesColor;
        }
        window.newValuesFormTab1.dropdownConnectorLinesColor = window.newValuesFormTab2.dropdownConnectorLinesColor;
        if (typeof window.newValuesFormTab2.dropdownColorsSet == "undefined") {
            window.newValuesFormTab2.dropdownColorsSet = window.formInitData.dropdownColorsSet;
        }
        window.newValuesFormTab1.dropdownColorsSet = window.newValuesFormTab2.dropdownColorsSet;
        if (typeof window.newValuesFormTab2.dropdownChartTextColor == "undefined") {
            window.newValuesFormTab2.dropdownChartTextColor = window.formInitData.dropdownChartTextColor;
        }
        window.newValuesFormTab1.dropdownChartTextColor = window.newValuesFormTab2.dropdownChartTextColor;
        if (typeof window.newValuesFormTab2.changedCTColor == "undefined") {
            window.newValuesFormTab2.changedCTColor = window.formInitData.changedCTColor;
        }
        window.newValuesFormTab1.changedCTColor = window.newValuesFormTab2.changedCTColor;
        if (typeof window.newValuesFormTab2.dropdownChartBackgroundColor == "undefined") {
            window.newValuesFormTab2.dropdownChartBackgroundColor = window.formInitData.dropdownChartBackgroundColor;
        }
        window.newValuesFormTab1.dropdownChartBackgroundColor = window.newValuesFormTab2.dropdownChartBackgroundColor;
        if (typeof window.newValuesFormTab2.dropdownBallsText == "undefined") {
            window.newValuesFormTab2.dropdownBallsText = window.formInitData.dropdownBallsText;
        }
        window.newValuesFormTab1.dropdownBallsText = window.newValuesFormTab2.dropdownBallsText;
        if (typeof window.newValuesFormTab2.dropdownBallsTextLayout == "undefined") {
            window.newValuesFormTab2.dropdownBallsTextLayout = window.formInitData.dropdownBallsTextLayout;
        }
        window.newValuesFormTab1.dropdownBallsTextLayout = window.newValuesFormTab2.dropdownBallsTextLayout;
        if (typeof window.newValuesFormTab2.dropdownAutoAdjust == "undefined") {
            window.newValuesFormTab2.dropdownAutoAdjust = window.formInitData.dropdownAutoAdjust;
        }
        window.newValuesFormTab1.dropdownAutoAdjust = window.newValuesFormTab2.dropdownAutoAdjust;

        if (typeof window.newValuesFormTab2.dropdownChartView == "undefined") {
            window.newValuesFormTab2.dropdownChartView = window.formInitData.dropdownChartView;
        }
        window.newValuesFormTab1.dropdownChartView = window.newValuesFormTab2.dropdownChartView;

        if (typeof window.newValuesFormTab2.dropdownAutoAdjustCnt == "undefined") {
            window.newValuesFormTab2.dropdownAutoAdjustCnt = window.formInitData.dropdownAutoAdjustCnt;
        }
        window.newValuesFormTab1.dropdownAutoAdjustCnt = window.newValuesFormTab2.dropdownAutoAdjustCnt;

        if (typeof window.newValuesFormTab2.checkboxSourceDataPointsValue != "undefined") {
            window.previousValueFormTab2.checkboxSourceDataPointsValue = window.newValuesFormTab2.checkboxSourceDataPointsValue;
        } else if (typeof window.previousValueFormTab2.checkboxSourceDataPointsValue == "undefined") {
            window.previousValueFormTab2.checkboxSourceDataPointsValue = true;
        }
        window.newValuesFormTab1.checkboxSourceDataPointsValue = window.newValuesFormTab2.checkboxSourceDataPointsValue;

        if (typeof window.newValuesFormTab2.checkboxAxisTurnOnOffValue != "undefined") {
            window.previousValueFormTab2.checkboxAxisTurnOnOffValue = window.newValuesFormTab2.checkboxAxisTurnOnOffValue;
        } else if (typeof window.previousValueFormTab2.checkboxAxisTurnOnOffValue == "undefined") {
            window.previousValueFormTab2.checkboxAxisTurnOnOffValue = true;
        }
        window.newValuesFormTab1.checkboxAxisTurnOnOffValue = window.newValuesFormTab2.checkboxAxisTurnOnOffValue;

        //Analysis
        if (typeof window.newValuesFormTab3 === "undefined") {
            window.newValuesFormTab3 = {};
        }
        if (typeof window.newValuesFormTab3.numberBoxDegree == "undefined") {
            window.newValuesFormTab3.numberBoxDegree = window.formInitData.numberBoxDegree;
        }
        window.newValuesFormTab1.numberBoxDegree = window.newValuesFormTab3.numberBoxDegree;
        if (typeof window.newValuesFormTab3.numberBoxFilterThreshold == "undefined") {
            window.newValuesFormTab3.numberBoxFilterThreshold = window.formInitData.numberBoxFilterThreshold;
        }
        window.newValuesFormTab1.numberBoxFilterThreshold = window.newValuesFormTab3.numberBoxFilterThreshold;
        if (typeof window.newValuesFormTab3.numberBoxClustersNumber == "undefined") {
            window.newValuesFormTab3.numberBoxClustersNumber = window.formInitData.numberBoxClustersNumber;
        }
        window.newValuesFormTab1.numberBoxClustersNumber = window.newValuesFormTab3.numberBoxClustersNumber;
        if (typeof window.newValuesFormTab3.numberBoxEpsilon == "undefined") {
            window.newValuesFormTab3.numberBoxEpsilon = window.formInitData.numberBoxEpsilon;
        }
        window.newValuesFormTab1.numberBoxEpsilon = window.newValuesFormTab3.numberBoxEpsilon;
        if (typeof window.newValuesFormTab3.numberBoxMinItemsPerCluster == "undefined") {
            window.newValuesFormTab3.numberBoxMinItemsPerCluster = window.formInitData.numberBoxMinItemsPerCluster;
        }
        window.newValuesFormTab1.numberBoxMinItemsPerCluster = window.newValuesFormTab3.numberBoxMinItemsPerCluster;
        if (typeof window.newValuesFormTab3.dropdownDataOmission == "undefined") {
            window.newValuesFormTab3.dropdownDataOmission = window.formInitData.dropdownDataOmission;
        }
        window.newValuesFormTab1.dropdownDataOmission = window.newValuesFormTab3.dropdownDataOmission;
        if (typeof window.newValuesFormTab3.checkboxPreserveDateRange == "undefined") {
            window.newValuesFormTab3.checkboxPreserveDateRange = window.formInitData.checkboxPreserveDateRange;
        }
        window.newValuesFormTab1.checkboxPreserveDateRange = window.newValuesFormTab3.checkboxPreserveDateRange;
        if (typeof window.newValuesFormTab3.dropdownFilterModel == "undefined") {
            window.newValuesFormTab3.dropdownFilterModel = window.formInitData.dropdownFilterModel;
        }
        window.newValuesFormTab1.dropdownFilterModel = window.newValuesFormTab3.dropdownFilterModel;
        if (typeof window.newValuesFormTab3.dropdownValueVScale == "undefined") {
            window.newValuesFormTab3.dropdownValueVScale = window.formInitData.dropdownValueVScale;
        }
        window.newValuesFormTab1.dropdownValueVScale = window.newValuesFormTab3.dropdownValueVScale;
        if (typeof window.newValuesFormTab3.dropdownValueVSmooth == "undefined") {
            window.newValuesFormTab3.dropdownValueVSmooth = window.formInitData.dropdownValueVSmooth;
        }
        window.newValuesFormTab1.dropdownValueVSmooth = window.newValuesFormTab3.dropdownValueVSmooth;
        if (typeof window.newValuesFormTab3.dropdownModel == "undefined") {
            window.newValuesFormTab3.dropdownModel = window.formInitData.dropdownModel;
        }
        window.newValuesFormTab1.dropdownModel = window.newValuesFormTab3.dropdownModel;
        if (typeof window.newValuesFormTab3.dropdownFilterDirection == "undefined") {
            window.newValuesFormTab3.dropdownFilterDirection = window.formInitData.dropdownFilterDirection;
        }
        window.newValuesFormTab1.dropdownFilterDirection = window.newValuesFormTab3.dropdownFilterDirection;
        if (typeof window.newValuesFormTab3.dropdownFactorsData == "undefined") {
            window.newValuesFormTab3.dropdownFactorsData = window.formInitData.dropdownFactorsData;
        }
        window.newValuesFormTab1.dropdownFactorsData = window.newValuesFormTab3.dropdownFactorsData;
        if (typeof window.newValuesFormTab3.dropdownCentroidInitialization == "undefined") {
            window.newValuesFormTab3.dropdownCentroidInitialization = window.formInitData.dropdownCentroidInitialization;
        }
        window.newValuesFormTab1.dropdownCentroidInitialization = window.newValuesFormTab3.dropdownCentroidInitialization;
        if (typeof window.newValuesFormTab3.dropdownClusterizationModel == "undefined") {
            window.newValuesFormTab3.dropdownClusterizationModel = window.formInitData.dropdownClusterizationModel;
        }
        window.newValuesFormTab1.dropdownClusterizationModel = window.newValuesFormTab3.dropdownClusterizationModel;
        if (typeof window.newValuesFormTab3.dropdownInterpolationPrediction == "undefined") {
            window.newValuesFormTab3.dropdownInterpolationPrediction = window.formInitData.dropdownInterpolationPrediction;
        }
        window.newValuesFormTab1.dropdownInterpolationPrediction = window.newValuesFormTab3.dropdownInterpolationPrediction;

        window.newValuesFormTab1.numberBoxDegree
            = window.newValuesFormTab3.numberBoxDegree
            = $.trim($('#numberBoxDegree > input:nth-child(1)').val());
        window.newValuesFormTab1.numberBoxFilterThreshold
            = window.newValuesFormTab3.numberBoxFilterThreshold
            = $.trim($('#numberBoxFilterThreshold > input:nth-child(1)').val());
        window.newValuesFormTab1.numberBoxClustersNumber
            = window.newValuesFormTab3.numberBoxClustersNumber
            = $.trim($('#numberBoxClustersNumber > input:nth-child(1)').val());
        window.newValuesFormTab1.numberBoxEpsilon
            = window.newValuesFormTab3.numberBoxEpsilon
            = $.trim($('#numberBoxEpsilon > input:nth-child(1)').val());
        window.newValuesFormTab1.numberBoxMinItemsPerCluster
            = window.newValuesFormTab3.numberBoxMinItemsPerCluster
            = $.trim($('#numberBoxMinItemsPerCluster > input:nth-child(1)').val());

        window.newValuesFormTab1.isChange3 = window.newValuesFormTab3.isChange3;
        //Words
        if (typeof window.newValuesFormTab4 === "undefined") {
            window.newValuesFormTab4 = {};
        }
        if (typeof window.previousValueFormTab4 === "undefined") {
            window.previousValueFormTab4 = {};
        }
        window.newValuesFormTab1.isChange4 = window.newValuesFormTab4.isChange4;

        if (typeof window.newValuesFormTab4.checkboxPOSAcronymValue == "undefined") {
            window.newValuesFormTab4.checkboxPOSAcronymValue = window.formInitData.checkboxPOSAcronymValue;
        }
        window.newValuesFormTab1.checkboxPOSAcronymValue = window.newValuesFormTab4.checkboxPOSAcronymValue;
        if (typeof window.newValuesFormTab4.checkboxPOSGeoValue == "undefined") {
            window.newValuesFormTab4.checkboxPOSGeoValue = window.formInitData.checkboxPOSGeoValue;
        }
        window.newValuesFormTab1.checkboxPOSGeoValue = window.newValuesFormTab4.checkboxPOSGeoValue;
        if (typeof window.newValuesFormTab4.dropdownUniqueWords == "undefined") {
            window.newValuesFormTab4.dropdownUniqueWords = window.formInitData.dropdownUniqueWords;
        }
        window.newValuesFormTab1.dropdownUniqueWords = window.newValuesFormTab4.dropdownUniqueWords;
        if (typeof window.newValuesFormTab4.dropdownSortOrderWords == "undefined") {
            window.newValuesFormTab4.dropdownSortOrderWords = window.formInitData.dropdownSortOrderWords;
        }
        window.newValuesFormTab1.dropdownSortOrderWords = window.newValuesFormTab4.dropdownSortOrderWords;

        //Satellites Type Mask
        if (typeof window.newValuesFormTab4.checkboxMultiWordSatellitesTypeMask == "undefined") {
            window.newValuesFormTab4.checkboxMultiWordSatellitesTypeMask = window.formInitData.checkboxMultiWordSatellitesTypeMask;
        }
        window.newValuesFormTab1.checkboxMultiWordSatellitesTypeMask = window.newValuesFormTab4.checkboxMultiWordSatellitesTypeMask;
        if (typeof window.newValuesFormTab4.checkboxProperSatellitesTypeMask == "undefined") {
            window.newValuesFormTab4.checkboxProperSatellitesTypeMask = window.formInitData.checkboxProperSatellitesTypeMask;
        }
        window.newValuesFormTab1.checkboxProperSatellitesTypeMask = window.newValuesFormTab4.checkboxProperSatellitesTypeMask;
        if (typeof window.newValuesFormTab4.checkboxNormalizedSatellitesTypeMask == "undefined") {
            window.newValuesFormTab4.checkboxNormalizedSatellitesTypeMask = window.formInitData.checkboxNormalizedSatellitesTypeMask;
        }
        window.newValuesFormTab1.checkboxNormalizedSatellitesTypeMask = window.newValuesFormTab4.checkboxNormalizedSatellitesTypeMask;
        if (typeof window.newValuesFormTab4.checkboxReadableSatellitesTypeMask == "undefined") {
            window.newValuesFormTab4.checkboxReadableSatellitesTypeMask = window.formInitData.checkboxReadableSatellitesTypeMask;
        }
        window.newValuesFormTab1.checkboxReadableSatellitesTypeMask = window.newValuesFormTab4.checkboxReadableSatellitesTypeMask;
        if (typeof window.newValuesFormTab4.checkboxCommonSatellitesTypeMask == "undefined") {
            window.newValuesFormTab4.checkboxCommonSatellitesTypeMask = window.formInitData.checkboxCommonSatellitesTypeMask;
        }
        window.newValuesFormTab1.checkboxCommonSatellitesTypeMask = window.newValuesFormTab4.checkboxCommonSatellitesTypeMask;
        if (typeof window.newValuesFormTab4.checkboxNumbersSatellitesTypeMask == "undefined") {
            window.newValuesFormTab4.checkboxNumbersSatellitesTypeMask = window.formInitData.checkboxNumbersSatellitesTypeMask;
        }
        window.newValuesFormTab1.checkboxNumbersSatellitesTypeMask = window.newValuesFormTab4.checkboxNumbersSatellitesTypeMask;

        if (typeof window.newValuesFormTab4.checkboxPOSPersonNameValue == "undefined") {
            window.newValuesFormTab4.checkboxPOSPersonNameValue = window.formInitData.checkboxPOSPersonNameValue;
        }
        window.newValuesFormTab1.checkboxPOSPersonNameValue = window.newValuesFormTab4.checkboxPOSPersonNameValue;
        if (typeof window.newValuesFormTab4.checkboxPOSWikiValue == "undefined") {
            window.newValuesFormTab4.checkboxPOSWikiValue = window.formInitData.checkboxPOSWikiValue;
        }
        window.newValuesFormTab1.checkboxPOSWikiValue = window.newValuesFormTab4.checkboxPOSWikiValue;

        if (typeof window.newValuesFormTab4.checkboxWordsGroupSubentries == "undefined") {
            window.newValuesFormTab4.checkboxWordsGroupSubentries = window.formInitData.checkboxWordsGroupSubentries;
        }
        window.newValuesFormTab1.checkboxWordsGroupSubentries = window.newValuesFormTab4.checkboxWordsGroupSubentries;


        if (typeof window.newValuesFormTab4.textBoxValueSearchWord != "undefined") {
            window.previousValueFormTab4.textBoxValueSearchWord = window.newValuesFormTab4.textBoxValueSearchWord;
        }
        window.newValuesFormTab1.textBoxValueSearchWord = window.newValuesFormTab4.textBoxValueSearchWord;
        if (typeof window.newValuesFormTab4.synonymsWordsCustom != "undefined") {
            window.previousValueFormTab4.synonymsWordsCustom = window.newValuesFormTab4.synonymsWordsCustom;
        } else {
            window.previousValueFormTab4.synonymsWordsCustom = {};
        }
        window.newValuesFormTab1.synonymsWordsCustom = window.newValuesFormTab4.synonymsWordsCustom;

        if (typeof window.newValuesFormTab4.textBoxValueExcludeWord != "undefined") {
            window.previousValueFormTab4.textBoxValueExcludeWord = window.newValuesFormTab4.textBoxValueExcludeWord;
        }

        if (typeof window.newValuesFormTab4.wordsList != "undefined") {
            window.previousValueFormTab4.wordsList = window.newValuesFormTab4.wordsList;
        }
        window.newValuesFormTab1.wordsList = window.newValuesFormTab4.wordsList;

        window.newValuesFormTab1.textBoxValueExcludeWord = window.newValuesFormTab4.textBoxValueExcludeWord;
        if (typeof window.newValuesFormTab2.textBoxTitleTimeCharts != "undefined") {
            window.previousValueFormTab2.textBoxTitleTimeCharts = window.newValuesFormTab4.textBoxTitleTimeCharts;
        }
        window.newValuesFormTab1.textBoxTitleTimeCharts = window.newValuesFormTab2.textBoxTitleTimeCharts;
        if (typeof window.newValuesFormTab2.textBoxTitleTimeCharts != "undefined") {
            window.previousValueFormTab2.textBoxTitleTimeCharts = window.newValuesFormTab2.textBoxTitleTimeCharts;
        }
        window.newValuesFormTab1.textBoxTitleTimeCharts = window.newValuesFormTab2.textBoxTitleTimeCharts;

        if (typeof window.newValuesFormTab2.textBoxSubtitleTimeCharts != "undefined") {
            window.previousValueFormTab2.textBoxSubtitleTimeCharts = window.newValuesFormTab4.textBoxSubtitleTimeCharts;
        }
        window.newValuesFormTab1.textBoxSubtitleTimeCharts = window.newValuesFormTab2.textBoxSubtitleTimeCharts;
        if (typeof window.newValuesFormTab2.textBoxSubtitleTimeCharts != "undefined") {
            window.previousValueFormTab2.textBoxSubtitleTimeCharts = window.newValuesFormTab2.textBoxSubtitleTimeCharts;
        }
        window.newValuesFormTab1.textBoxSubtitleTimeCharts = window.newValuesFormTab2.textBoxSubtitleTimeCharts;

        if (typeof window.newValuesFormTab2.textBoxDescriptionTimeCharts != "undefined") {
            window.previousValueFormTab2.textBoxDescriptionTimeCharts = window.newValuesFormTab4.textBoxDescriptionTimeCharts;
        }
        window.newValuesFormTab1.textBoxDescriptionTimeCharts = window.newValuesFormTab2.textBoxDescriptionTimeCharts;
        if (typeof window.newValuesFormTab2.textBoxDescriptionTimeCharts != "undefined") {
            window.previousValueFormTab2.textBoxDescriptionTimeCharts = window.newValuesFormTab2.textBoxDescriptionTimeCharts;
        }
        window.newValuesFormTab1.textBoxDescriptionTimeCharts = window.newValuesFormTab2.textBoxDescriptionTimeCharts;

        window.newValuesFormTab1.filterPOS =
            (window.newValuesFormTab4.checkboxPOSAcronymValue * $('#checkboxPOSAcronymVal').val())
            + (window.newValuesFormTab4.checkboxPOSGeoValue * $('#checkboxPOSGeoVal').val())
            + (window.newValuesFormTab4.checkboxPOSPersonNameValue * $('#checkboxPOSPersonNameVal').val())
            + (window.newValuesFormTab4.checkboxPOSWikiValue * $('#checkboxPOSWikiVal').val());
        //Satellites Type Mask
        window.newValuesFormTab1.checkboxesSatellitesTypeMask =
            (window.newValuesFormTab4.checkboxMultiWordSatellitesTypeMask * $('#inputMultiWordSatellitesTypeMask').val())
            + (window.newValuesFormTab4.checkboxProperSatellitesTypeMask * $('#inputProperSatellitesTypeMask').val())
            + (window.newValuesFormTab4.checkboxNormalizedSatellitesTypeMask * $('#inputNormalizedSatellitesTypeMask').val())
            + (window.newValuesFormTab4.checkboxReadableSatellitesTypeMask * $('#inputReadableSatellitesTypeMask').val())
            + (window.newValuesFormTab4.checkboxCommonSatellitesTypeMask * $('#inputCommonSatellitesTypeMask').val())
            + (window.newValuesFormTab4.checkboxNumbersSatellitesTypeMask * $('#inputNumbersSatellitesTypeMask').val());


        //Relations Type Mask
        if (typeof window.newValuesFormTab1.checkboxPWIntersectedNeighbors == 'string') {
            if (window.newValuesFormTab1.checkboxPWIntersectedNeighbors == 'true') {
                window.newValuesFormTab1.checkboxPWIntersectedNeighbors = true;
            } else {
                window.newValuesFormTab1.checkboxPWIntersectedNeighbors = false
            }
        }
        if (typeof window.newValuesFormTab1.checkboxPW1inNeighbors2 == 'string') {
            if (window.newValuesFormTab1.checkboxPW1inNeighbors2 == 'true') {
                window.newValuesFormTab1.checkboxPW1inNeighbors2 = true;
            } else {
                window.newValuesFormTab1.checkboxPW1inNeighbors2 = false
            }
        }
        if (typeof window.newValuesFormTab1.checkboxPW2inNeighbors1 == 'string') {
            if (window.newValuesFormTab1.checkboxPW2inNeighbors1 == 'true') {
                window.newValuesFormTab1.checkboxPW2inNeighbors1 = true;
            } else {
                window.newValuesFormTab1.checkboxPW2inNeighbors1 = false
            }
        }
        if (typeof window.newValuesFormTab1.checkboxIntersectedNeighbors == 'string') {
            if (window.newValuesFormTab1.checkboxIntersectedNeighbors == 'true') {
                window.newValuesFormTab1.checkboxIntersectedNeighbors = true;
            } else {
                window.newValuesFormTab1.checkboxIntersectedNeighbors = false
            }
        }
        if (typeof window.newValuesFormTab1.checkboxArticlesList == 'string') {
            if (window.newValuesFormTab1.checkboxArticlesList == 'true') {
                window.newValuesFormTab1.checkboxArticlesList = true;
            } else {
                window.newValuesFormTab1.checkboxArticlesList = false
            }
        }
        window.newValuesFormTab1.relationsModeMask = 
            (window.newValuesFormTab1.checkboxPWIntersectedNeighbors * $('#inputPWIntersectedNeighbors').val())
            + (window.newValuesFormTab1.checkboxPW1inNeighbors2 * $('#inputPW1inNeighbors2').val())
            + (window.newValuesFormTab1.checkboxPW2inNeighbors1 * $('#inputPW2inNeighbors1').val())
            + (window.newValuesFormTab1.checkboxIntersectedNeighbors * $('#inputIntersectedNeighbors').val())
            + (window.newValuesFormTab1.checkboxArticlesList * $('#inputArticlesList').val());
    
        if (firstLoadChart == 1 && isShare && hash != '') {
            if (typeof window.newValuesFormTab1.wordsList !== "undefined") {
                var checkedWords = window.newValuesFormTab1.wordsList;
            } else {
                var checkedWords = {};
            }
        } else {
            var checkboxList = $('.wordsCheckboxItem');
            var checkedWords = {};
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            $.each(checkboxList, function (index, item) {
                if ($(item).is(':checked')) {
                    var wordMD5 = $(item).parent().parent().find('.wordsColorColumn .node-color-new-value').attr('name');
                    if ($('#chartType').val() == CHART_TYPE_MOLECULAR) {//molecular
                        if (typeof window.molecularData[chartIndex].wordsList[$(item).attr('name')] !== "undefined") {
                            checkedWords[$(item).attr('name')] = window.molecularData[chartIndex].wordsList[$(item).attr('name')];
                        } else {
                            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                        }
                    } else if ($('#chartType').val() == CHART_TYPE_RADIAL) {//radial
                        if (typeof window.radialData[chartIndex].wordsList[$(item).attr('name')] !== "undefined") {
                            checkedWords[$(item).attr('name')] = window.radialData[chartIndex].wordsList[$(item).attr('name')];
                        } else {
                            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                        }
                    } else if ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                        if (typeof window.dendrogramHorizontalData[chartIndex].wordsList[$(item).attr('name')] !== "undefined") {
                            checkedWords[$(item).attr('name')] = window.dendrogramHorizontalData[chartIndex].wordsList[$(item).attr('name')];
                        } else {
                            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                        }
                    } else if ($('#chartType').val() == CHART_TYPE_TIMESERIES) {//timeseries
                        if (typeof window.timeSeriesData[chartIndex].wordsList[$(item).attr('name')] !== "undefined") {
                            checkedWords[$(item).attr('name')] = window.timeSeriesData[chartIndex].wordsList[$(item).attr('name')];
                        } else {
                            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                        }
                    } else if ($('#chartType').val() == CHART_TYPE_TIMELINE) {//timeline
                        if (typeof window.timelineData[chartIndex].wordsList[$(item).attr('name')] !== "undefined") {
                            checkedWords[$(item).attr('name')] = window.timelineData[chartIndex].wordsList[$(item).attr('name')];
                        } else {
                            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                        }
                    } else if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {//streamgraph
                        if (typeof window.streamgraphData[chartIndex].wordsList[$(item).attr('name')] !== "undefined") {
                            checkedWords[$(item).attr('name')] = window.streamgraphData[chartIndex].wordsList[$(item).attr('name')];
                        } else {
                            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                        }
                    } else if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                        if (typeof window.sankeyData[chartIndex].wordsList[$(item).attr('name')] !== "undefined") {
                            checkedWords[$(item).attr('name')] = window.sankeyData[chartIndex].wordsList[$(item).attr('name')];
                        } else {
                            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                        }
                    } else {
                        checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name'), 'md5': wordMD5 };
                    }
                }
            });
        }
        window.sampleFormWords.val({
            isChange4: window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(),
            wordsList: checkedWords
        });
        if (isDebugg) { '0511', console.log('wordsList'); }
        window.newValuesFormTab1.wordsList = window.newValuesFormTab4.wordsList = checkedWords;

        var colorInputs = $('td.wordsColorColumn .node-color-old-value');
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        if (firstLoadChart == 1 && isShare && hash != '') {
            var changedColors = {};
            var changedColorsByLevel = {};
            //molecular
            if ((typeof window.newValuesFormTab1.chartType !== "undefined")
                && window.newValuesFormTab1.chartType == CHART_TYPE_MOLECULAR) {
                if (typeof window.molecularData[chartIndex].changedColors !== "undefined") {
                    var changedColors = window.molecularData[chartIndex].changedColors;
                } else {
                    var changedColors = {};
                }
                //timeseries
            } else if ((typeof window.newValuesFormTab1.chartType !== "undefined")
                && window.newValuesFormTab1.chartType == CHART_TYPE_TIMESERIES) {
                if (typeof window.timeSeriesData[chartIndex].changedColors !== "undefined") {
                    var changedColors = window.timeSeriesData[chartIndex].changedColors;
                } else {
                    var changedColors = {};
                }
                //timeline
            } else if ((typeof window.newValuesFormTab1.chartType !== "undefined")
                && window.newValuesFormTab1.chartType == CHART_TYPE_TIMELINE) {
                if (typeof window.timelineData[chartIndex].changedColors !== "undefined") {
                    var changedColors = window.timelineData[chartIndex].changedColors;
                } else {
                    var changedColors = {};
                }
                //streamgraph
            } else if ((typeof window.newValuesFormTab1.chartType !== "undefined")
                && window.newValuesFormTab1.chartType == CHART_TYPE_STREAMGRAPH) {
                if (typeof window.streamgraphData[chartIndex].changedColors !== "undefined") {
                    var changedColors = window.streamgraphData[chartIndex].changedColors;
                } else {
                    var changedColors = {};
                }
                //sankey
            } else if ((typeof window.newValuesFormTab1.chartType !== "undefined")
                && window.newValuesFormTab1.chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
                if (typeof window.sankeyData[chartIndex].changedColors !== "undefined") {
                    var changedColors = window.sankeyData[chartIndex].changedColors;
                } else {
                    var changedColors = {};
                }
                //radial
            } else if ((typeof window.newValuesFormTab1.chartType !== "undefined")
                && window.newValuesFormTab1.chartType == CHART_TYPE_RADIAL) {
                if (typeof window.radialData[chartIndex].changedColorsByLevel !== "undefined") {
                    var changedColorsByLevel = window.radialData[chartIndex].changedColorsByLevel;
                } else {
                    var changedColorsByLevel = {};
                }
            } else if ((typeof window.newValuesFormTab1.chartType !== "undefined")
                && window.newValuesFormTab1.chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                if (typeof window.dendrogramHorizontalData[chartIndex].changedColorsByLevel !== "undefined") {
                    var changedColorsByLevel = window.dendrogramHorizontalData[chartIndex].changedColorsByLevel;
                    var changedColors = window.dendrogramHorizontalData[chartIndex].changedColors;
                } else {
                    var changedColorsByLevel = {};
                    var changedColors = {};
                }
            }
        } else {
            var changedColors = {};
            var changedColorsByLevel = {};
        }
        window.newValuesFormTab1.changedColors = window.newValuesFormTab4.changedColors = changedColors;
        window.newValuesFormTab1.changedColorsByLevel = window.newValuesFormTab4.changedColorsByLevel = changedColors;
        if (($('#chartType').val() == CHART_TYPE_MOLECULAR)
            || ($('#chartType').val() == CHART_TYPE_TIMESERIES)
            || ($('#chartType').val() == CHART_TYPE_TIMELINE)
            || ($('#chartType').val() == CHART_TYPE_STREAMGRAPH)
            || ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1)) {
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            $.each(colorInputs, function (index, item) {
                if ($(item).val() != $(item).next().val()) {
                    if (firstLoadChart == 1 && isShare && hash != '') {

                    } else {
                        changedColors[$(item).next().attr('name')] = {
                            'color': $(item).next().val()
                        };
                    }
                    if ($('#chartType').val() == CHART_TYPE_MOLECULAR) {
                        $.each(window.molecularData[chartIndex].wordsListFirst, function (indexW, itemW) {
                            if (window.molecularData[chartIndex].wordsListFirst[indexW].md5 == $(item).next().attr('name')) {
                                window.molecularData[chartIndex].wordsListFirst[indexW].colornew = $(item).next().val();
                            }
                        });
                    } else if ($('#chartType').val() == CHART_TYPE_TIMELINE) {
                        $.each(window.timelineData[chartIndex].wordsList, function (indexW, itemW) {
                            if (window.timelineData[chartIndex].wordsList[indexW].md5 == $(item).next().attr('name')) {
                                window.timelineData[chartIndex].wordsList[indexW].colornew = $(item).next().val();
                            }
                        });
                    } else if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {
                        $.each(window.streamgraphData[chartIndex].wordsList, function (indexW, itemW) {
                            if (window.streamgraphData[chartIndex].wordsList[indexW].md5 == $(item).next().attr('name')) {
                                window.streamgraphData[chartIndex].wordsList[indexW].colornew = $(item).next().val();
                            }
                        });
                    } else if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {
                        $.each(window.sankeyData[chartIndex].wordsList, function (indexW, itemW) {
                            if (window.sankeyData[chartIndex].wordsList[indexW].md5 == $(item).next().attr('name')) {
                                window.sankeyData[chartIndex].wordsList[indexW].colornew = $(item).next().val();
                            }
                        });
                    } else {
                        $.each(window.timeSeriesData[chartIndex].wordsList, function (indexW, itemW) {
                            if (window.timeSeriesData[chartIndex].wordsList[indexW].md5 == $(item).next().attr('name')) {
                                window.timeSeriesData[chartIndex].wordsList[indexW].colornew = $(item).next().val();
                            }
                        });
                    }
                }
            });
        }
        if (firstLoadChart == 1 && isShare && hash != '') {

        } else {
            window.newValuesFormTab1.hiddenLegendItems = getHiddenLegendItems($('#chartType').val());
            window.newValuesFormTab1.drawingValueIndicators = getDrawingValueIndicators();
            if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) {
                if ($('#dataTextualWords').length > 0) {
                    window.newValuesFormTab1.textualWordsGridSorting = JSON.stringify($('#dataTextualWords').jqxGrid('getsortinformation'));
                    var textualWordsGridState = $('#dataTextualWords').jqxGrid('getstate');
                }
                if (typeof textualWordsGridState != "undefined") {
                    if (typeof textualWordsGridState.pagenum != "undefined") {
                        window.newValuesFormTab1.textualWordsGridPagenum = textualWordsGridState.pagenum;
                    }
                    if (typeof textualWordsGridState.pagesize != "undefined") {
                        window.newValuesFormTab1.textualWordsGridPagesize = textualWordsGridState.pagesize;
                    }
                }
            }
        }

        window.sampleFormDrawing.jqxForm('getComponentByName', 'isChangeTab2').val(false);
        if (isDebugg) { '052', console.log(window.newValuesFormTab1); }
        if (typeof window.previousValueFormTab1 != "undefined") {
            if (window.previousValueFormTab1.checkboxConnectorsLinesValue != window.newValuesFormTab1.checkboxConnectorsLinesValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxAppearanceFullTimeValue != window.newValuesFormTab1.checkboxAppearanceFullTimeValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxIndentValuesRelatedValue
                != window.newValuesFormTab1.checkboxIndentValuesRelatedValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxNotIncludeSingleNodesValue
                != window.newValuesFormTab1.checkboxNotIncludeSingleNodesValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxReduceOverlapValue != window.newValuesFormTab1.checkboxReduceOverlapValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxShowHideLegendValue != window.newValuesFormTab1.checkboxShowHideLegendValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
                if (typeof curDataChartTL != "undefined") {
                    showChart(JSON.parse(curDataChartTL), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxShowHideSeriesLabelValue != window.newValuesFormTab1.checkboxShowHideSeriesLabelValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxShowHideTitleValue != window.newValuesFormTab1.checkboxShowHideTitleValue) {
                showHideTitle();
            }
            if (window.previousValueFormTab1.checkboxAddNodesFixLevel != window.newValuesFormTab1.checkboxAddNodesFixLevel) {
                //showHideTitle();
            }
            if (window.previousValueFormTab1.checkboxShowHideTableHeaderValue != window.newValuesFormTab1.checkboxShowHideTableHeaderValue) {
                if (typeof curDataChartTW != "undefined") {
                    if (typeof curDataChartTW === "string") {
                        showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                    } else {
                        showChart(curDataChartTW, window.newValuesFormTab1);
                    }
                }
            }
            if (window.previousValueFormTab1.checkboxOrientationHorizontalVertical != window.newValuesFormTab1.checkboxOrientationHorizontalVertical) {
                    window.newValuesFormTab1.checkboxOrientationHorizontalVertical = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical').val();

                if (($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) && (typeof curDataChartSK != "undefined")) {
                    if (typeof curDataChartSK === "string") {
                        showChart(JSON.parse(curDataChartSK), window.newValuesFormTab1);
                    } else {
                        showChart(curDataChartSK, window.newValuesFormTab1);
                    }
                } else if (($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) && typeof curDataChartDH != "undefined") {
                    if (typeof curDataChartDH === "string") {
                        showChart(JSON.parse(curDataChartDH), window.newValuesFormTab1);
                    } else {
                        showChart(curDataChartDH, window.newValuesFormTab1);
                    }
                }
            }
            if (window.previousValueFormTab1.checkboxShowHideMetadataColumnValue != window.newValuesFormTab1.checkboxShowHideMetadataColumnValue) {
                if (typeof curDataChartTW != "undefined") {
                    if (typeof curDataChartTW === "string") {
                        showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                    } else {
                        showChart(curDataChartTW, window.newValuesFormTab1);
                    }
                }
            }
            if (window.previousValueFormTab1.checkboxShowHidePaginationValue != window.newValuesFormTab1.checkboxShowHidePaginationValue) {
                if (typeof curDataChartTW != "undefined") {
                    if (typeof curDataChartTW === "string") {
                        showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                    } else {
                        showChart(curDataChartTW, window.newValuesFormTab1);
                    }
                }
            }
            if (window.previousValueFormTab1.checkboxShowHideMenuValue != window.newValuesFormTab1.checkboxShowHideMenuValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxConnectNullsValue != window.newValuesFormTab1.checkboxConnectNullsValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.dropdownConnectorLinesSourceValue != window.newValuesFormTab1.dropdownConnectorLinesSourceValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxSourceDataPointsValue != window.newValuesFormTab1.checkboxSourceDataPointsValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
            if (window.previousValueFormTab1.checkboxAxisTurnOnOffValue != window.newValuesFormTab1.checkboxAxisTurnOnOffValue) {
                if (typeof curDataChart != "undefined") {
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        }
    });

    $('#saveGlobalOptionsBtn').on('click', function () {
        window.radialData[chartIndex].wordsListReset = true;
        window.molecularData[chartIndex].wordsListReset = true;
        window.timeSeriesData[chartIndex].wordsListReset = true;
        window.timelineData[chartIndex].wordsListReset = true;
        window.streamgraphData[chartIndex].wordsListReset = true;
        window.sankeyData[chartIndex].wordsListReset = true;
        window.textualWordsData[chartIndex].wordsListReset = true;
        window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
    });

    window.btnMain.on('click', function () {
        //var retLimit = set15DaysLimit();
        //if (!retLimit) {
        //    return false;
        //}
        console.log('btnMain click');
        var chartType;

        /*Set data for the chart type*/
        if (firstLoadChart == 1 && formDataStr != '') {
            //console.log('firstLoadChart && formDataStr', $('#chartType').val());
            if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {
                window.currenFormDataSelectValues[chartIndex].chartType = CHART_TYPE_TIMESERIES;
                //data.result.chartType = CHART_TYPE_TIMESERIES;
                window.newValuesFormTab1.chartType = CHART_TYPE_TIMESERIES;
                $('#chartType').val(CHART_TYPE_TIMESERIES);
                chartType = CHART_TYPE_TIMESERIES;
            }
            if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) {
                window.currenFormDataSelectValues[chartIndex].chartType = CHART_TYPE_TIMESERIES;
                //data.result.chartType = CHART_TYPE_TIMESERIES;
                window.newValuesFormTab1.chartType = CHART_TYPE_TIMESERIES;
                $('#chartType').val(CHART_TYPE_TIMESERIES);
                chartType = CHART_TYPE_TIMESERIES;
            }
        }
        isChangeDropdownDatesInterval = 1;

        if (isDebugg) { console.log('051'); }
        //Set form tab values to the window object.
        initPrepareFormData();
        firstLoadPage = 0;
        if (isWidget == 1) {
            $('#jqxLoaderWidget').jqxLoader('open');
        } else {
            $('#jqxLoader').jqxLoader('open');
        }
        var checkboxValueIndicatorList = $('.valueIndicatorCheckboxItem:checked');
        var checkedValueIndicators = [];
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        $.each(checkboxValueIndicatorList, function (index, item) {
            if ($(item).is(':checked')) {
                checkedValueIndicators.push($(item).attr('value'));
            }
        });
        setDatesInterval($('#dropdownDatesInterval').jqxDropDownList('val'), $('#inputjqxWidgetDateRange').val());

        $("#jqxWidgetDateRange").prev('input[type=text]').attr('name', 'textBoxValueDatesRange');
        $('input[name=textBoxValueDatesRange]').val($('#inputjqxWidgetDateRange').val());

        // function: submit
        // arg1: url
        // arg2, optional: target, default is _blank
        // arg3, optional: submit method - GET or POST, default is POST
        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        chartType = $('#chartType').val();
        //changedRadLevelColors
        if (
            (window.currenFormDataSelectValues[chartIndex].dropdownValueDSource
                != $('#dropdownValueDSource').jqxDropDownList('val')) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownValueDSource2
                != $('.data-source-label2').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownDatesInterval
                != $('#dropdownDatesInterval').jqxDropDownList('val')) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownDatesInterval
                != $('#dropdownDatesInterval').jqxDropDownList('val')) ||
            (window.currenFormDataSelectValues[chartIndex].textBoxValueDatesRange
                != $('#inputjqxWidgetDateRange').val()) ||
            (window.currenFormDataSelectValues[chartIndex].textBoxValueExcludePeriods
                != $('.form-exclude-periods-input').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxValue1 != $("#nubmberBoxValue1 input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxValue2 != $("#nubmberBoxValue2 input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxRepeatSelectionMinMaxUnlim != $('.checkbox-repeat-selection-unlim-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPWIntersectedNeighbors != $('.checkbox-pop-words-intersected-neighbors-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPW1inNeighbors2 != $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPW2inNeighbors1 != $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxIntersectedNeighbors != $('.checkbox-intersected-neighbors-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxArticlesList != $('.checkbox-articles-list-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxNeighborsFreqMin != $("#nubmberBoxNeighborsFreqMin input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxNeighborsFreqMax != $("#nubmberBoxSatellitesFreqMax input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxSatellitesFreqMin != $("#nubmberBoxSatellitesFreqMin input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxSatellitesFreqMax != $("#nubmberBoxSatellitesFreqMax input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxAppearanceMin != $("#nubmberBoxAppearanceMin input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxAppearanceMax != $("#nubmberBoxAppearanceMax input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxValueMaxWordsTotal
                != $("#nubmberBoxValueMaxWordsTotal input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxNeighborsMaxItems
                != $("#nubmberBoxNeighborsMaxItems input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].nubmberBoxValueFontSize
                != $("#nubmberBoxValueFontSize input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].textBoxTitleTimeCharts
                != window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxTitleTimeCharts').val()) ||
            (window.currenFormDataSelectValues[chartIndex].textBoxSubtitleTimeCharts
                != window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxSubtitleTimeCharts').val()) ||
            (window.currenFormDataSelectValues[chartIndex].textBoxDescriptionTimeCharts
                != window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxDescriptionTimeCharts').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownValueIWords
                != $('.intermediate-words-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownDaysInterval
                != $('#dropdownDaysInterval').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownMinIntersectedNeighbors
                != $('#dropdownMinIntersectedNeighbors').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownSortDailyWordsBy
                != $('.sort-daily-words-by-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].satellitesOrderCondition
                != $('.words-selection-order-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownDataSelectCache
                != $('.dropdown-data-select-cache-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownValueMaxDepth
                != $('.max-depth-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownMaxDistance
                != $('.distance-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownValueVIndicator
                != $('.value-indicator-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownsSortField
                != $('.sort-field-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxRepeatSelectionMinMaxUnlim
                != $('.checkbox-repeat-selection-unlim-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPWIntersectedNeighbors
                != $('.checkbox-pop-words-intersected-neighbors-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPW1inNeighbors2
                != $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPW2inNeighbors1
                != $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxIntersectedNeighbors
                != $('.checkbox-intersected-neighbors-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxArticlesList
                != $('.checkbox-articles-list-label').closest("tr").find('input').val()) ||
                    
            //Analysis
            (window.currenFormDataSelectValues[chartIndex].numberBoxDegree
                != $("#numberBoxDegree input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].numberBoxFilterThreshold
                != $("#numberBoxFilterThreshold input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].numberBoxClustersNumber
                != $("#numberBoxClustersNumber input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].numberBoxEpsilon
                != $("#numberBoxEpsilon input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].numberBoxMinItemsPerCluster
                != $("#numberBoxMinItemsPerCluster input").val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownValueVScale
                != $('.value-scale-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownValueVSmooth
                != $('.value-smooth-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownDataOmission
                != $('.data-omission-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPreserveDateRange
                != window.sampleFormAnalysis.jqxForm('getComponentByName', 'checkboxPreserveDateRange').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownColorsSelection
                != $('.form-dropdown-colors-selection-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownConnectorLineWidthIndicator
                != $('.form-dropdown-connector-line-width-indicator-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownConnectorLinesColor
                != $('.form-connector-lines-color-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownColorsSet
                != $('.form-dropdown-colors-set-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownChartBackgroundColor
                != $('.form-dropdown-chart-background-color-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownChartTextColor
                != $('.form-dropdown-chart-text-color-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].changedCTColor
                != $('.chart-text-new-value').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownBallsText
                != $('.form-dropdown-balls-text-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownBallsTextLayout
                != $('.form-dropdown-balls-text-layout-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownFilterModel
                != $('.data-filter-model-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownFilterDirection
                != $('.filter-direction-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownFactorsData
                != $('.factors-data-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownCentroidInitialization
                != $('.centroid-initialization-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownClusterizationModel
                != $('.clusterization-model-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxAppearanceFullTimeValue
                != $('.appearance-time-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownInterpolationPrediction
                != $('.interpolation-prediction-label').closest("tr").find('input').val()) ||
            //Words
            (window.currenFormDataSelectValues[chartIndex].checkboxPOSAcronymValue
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSAcronym').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxPOSGeoValue
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSGeo').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownUniqueWords
                != $('.unique-words-label').closest("tr").find('input').val()) ||
            (window.currenFormDataSelectValues[chartIndex].dropdownSortOrderWords
                != $('.sort-order-words-label').closest("tr").find('input').val()) ||
    
            //Satellites Type Mask
            (window.currenFormDataSelectValues[chartIndex].checkboxMultiWordSatellitesTypeMask
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxMultiWordSatellitesTypeMask').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxProperSatellitesTypeMask
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxProperSatellitesTypeMask').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxNormalizedSatellitesTypeMask
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNormalizedSatellitesTypeMask').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxReadableSatellitesTypeMask
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxReadableSatellitesTypeMask').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxCommonSatellitesTypeMask
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxCommonSatellitesTypeMask').val()) ||
            (window.currenFormDataSelectValues[chartIndex].checkboxNumbersSatellitesTypeMask
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNumbersSatellitesTypeMask').val()) ||

            (window.currenFormDataSelectValues[chartIndex].checkboxPOSPersonNameValue
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSPersonName').val()) ||
            (window.currenFormDataSelectValues[chartIndex].textBoxValueSearchWord
                != window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val()) ||
            (window.currenFormDataSelectValues[chartIndex].synonymsWordsCustom
                != $('#synonymsWordsCustom').val()) ||
            (window.currenFormDataSelectValues[chartIndex].textBoxValueExcludeWord
                != window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueExcludeWord').val()) ||

            (window.currenFormDataSelectValues[chartIndex].textBoxValueExcludePeriods
                != $('.form-exclude-periods-input').closest("tr").find('input').val()) ||

            (window.currenFormDataSelectValues[chartIndex].checkboxPOSWikiValue
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSWiki').val()) ||

            (window.currenFormDataSelectValues[chartIndex].checkboxWordsGroupSubentries
                != window.sampleFormWords.jqxForm('getComponentByName', 'checkboxWordsGroupSubentries').val()) ||

            (window.currenFormDataSelectValues[chartIndex].checkboxValueIndicatorLimitsStrict
                != window.sampleForm.jqxForm('getComponentByName', 'checkboxValueIndicatorLimitsStrict').val())
        ) {
            if (isDebugg) { console.log('052', 'resetWordsList'); }
            if (firstLoadChart == 1 && isShare && hash != '') {
            } else {
                resetWordsList(chartType, chartIndex, 1);
                prepareFixedNodesData();
            }
        }
        saveCurrenFormDataSelectValues();
        /*filterPOS: 0,
        checkboxPOSAcronymValue: false,
        checkboxPOSGeoValue: false,
        checkboxPOSPersonNameValue: false
        'checkboxPOSAcronymValue': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSAcronym').val(),
        'checkboxPOSGeoValue': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSGeo').val(),
        'checkboxPOSPersonNameValue': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSPersonName').val()
        */
        if (chartType == CHART_TYPE_TIMESERIES) {
            $('#dataContaner').html($('#chartTimeSeriesDiv').html());
            var checkboxList = $('.wordsCheckboxItem');
            var checkedWords = {};
            $.each(checkboxList, function (index, item) {
                if ($(item).is(':checked')) {
                    checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name') };
                }
            });

            window.sampleFormWords.val({
                isChange4: window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(),
                wordsList: checkedWords
            });
            //d3.csv('https://raw.githubusercontent.com/levvsha/d3-in-all-its-glory-en/master/stats/data.csv').then(data => draw(data))
        }

        if (chartType == CHART_TYPE_TIMELINE) {
            $('#dataContaner').html($('#chartTimelineDiv').html());
            var checkboxList = $('.wordsCheckboxItem');
            var checkedWords = {};
            $.each(checkboxList, function (index, item) {
                if ($(item).is(':checked')) {
                    checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name') };
                }
            });

            window.sampleFormWords.val({
                isChange4: window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(),
                wordsList: checkedWords
            });
        }

        if (chartType == CHART_TYPE_STREAMGRAPH) {
            $('#dataContaner').html($('#chartStreamgraphDiv').html());
            var checkboxList = $('.wordsCheckboxItem');
            var checkedWords = {};
            $.each(checkboxList, function (index, item) {
                if ($(item).is(':checked')) {
                    checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name') };
                }
            });

            window.sampleFormWords.val({
                isChange4: window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(),
                wordsList: checkedWords
            });
        }
        if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
            $('#dataContaner').html($('#chartSankeyDiv').html());
            var checkboxList = $('.wordsCheckboxItem');
            var checkedWords = {};
            $.each(checkboxList, function (index, item) {
                if ($(item).is(':checked')) {
                    checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name') };
                }
            });

            window.sampleFormWords.val({
                isChange4: window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(),
                wordsList: checkedWords
            });
        }

        if (typeof data === "undefined") {
            var data = {};
        }
        if (firstLoadChart == 1 && formDataStr != '') {
            var resData = setWidgetUrlParams(data);
            data = resData['data'];
        }
        //retObj = resData['retObj']

        if (firstLoadChart == 1 && formDataStr != '') {
            if ((window.formDataStrSave.chartType == CHART_TYPE_SANKEY_NEIGHBORS1) || (window.formDataStrSave.chartType == CHART_TYPE_TEXTUALWORDS)) {
                window.currenFormDataSelectValues[chartIndex].chartType = CHART_TYPE_TIMESERIES;
                //data.result.chartType = CHART_TYPE_TIMESERIES;
                window.newValuesFormTab1.chartType = CHART_TYPE_TIMESERIES;
                $('#chartType').val(CHART_TYPE_TIMESERIES);
                chartType = CHART_TYPE_TIMESERIES;
                window.formDataStrSave.chartType = CHART_TYPE_TIMESERIES;
                window.formDataStrSave.dropdownValueMaxDepth = '';
                window.newValuesFormTab1.dropdownValueMaxDepth = '';
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            }
            window.newValuesFormTab1 = window.formDataStrSave;
            var searchWordDepthStr = '';
            window.newValuesFormTab1.textBoxValueSearchWord
            if (window.newValuesFormTab1.textBoxValueSearchWord.search('@') >= 0) {
                var searchWordArr = window.newValuesFormTab1.textBoxValueSearchWord.split("@");
                if (searchWordArr.length > 1) {
                    for (var i = 1; i < searchWordArr.length; i++) {
                        searchWordDepthArr = searchWordArr[i].split(":");
                        searchWordDepthStr = searchWordDepthStr + '@' + searchWordDepthArr[0] + ':';
                    }
                }
            }
            window.newValuesFormTab1.textBoxValueSearchWord = searchWordDepthStr + window.newValuesFormTab1.curWord;
            $('#chartType').val(window.newValuesFormTab1.chartType);
        }
        window.newValuesFormTab1.metadataDate = {};
        if (typeof window.newValuesFormTab1.textBoxValueDatesRange !== "undefined") {
            var datesArr = window.newValuesFormTab1.textBoxValueDatesRange.split(' - ');
            var dateStartArr = datesArr[0].split('-');
            window.newValuesFormTab1.metadataDate.year = dateStartArr[0];
            window.newValuesFormTab1.metadataDate.month = dateStartArr[1];
            window.newValuesFormTab1.metadataDate.day = dateStartArr[2];
        }
        if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
            window.newValuesFormTab1.changedRadLevelColors = getChangedRadLevelColors();
        }

        setDataSourceTooltip();
        if (chartType == CHART_TYPE_TIMELINE) {
            //successChart({});
        }

        $.ajax({
            // the route pointing to the post function 
            url: '/charts-form',
            type: 'POST',
            // send the csrf-token and the input to the controller 
            data: {
                _token: CSRF_TOKEN,
                filename: 'dataChartMolecular.txt',
                formdata: window.newValuesFormTab1
            },
            dataType: 'JSON',
            // remind that 'data' is the response of the AjaxController
            success: function (data) {
                $('#DebugContaner').html('');
                if ((data.result == null) || (data.result == "\n#debug ")
                    || ((typeof data.result === "string") && (data.result.length == 20))
                ) {
                    $('#ErrorListContaner').text(data.errMsg);
                    cleanup();
                    showHideTypeDependentItems();
                    $('#dataContaner')
                        .text('Empty result of a data selection, please change dates range or another options like a frequencies limits');
                    // hide preloader on ajax error
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }
                } else {
                    firstLoadChart = 1;
                    successChart(data);
                }
            },
            error: function (data) {
                $('#DebugContaner').html('');
                $('#ErrorListContaner').text(data.errMsg);
                cleanup();
                $('#dataContaner')
                    .text('Empty result of a data selection, please change dates range or another options like a frequencies limits');
                // hide preloader on ajax error
                if (isWidget == 1) {
                    $('#jqxLoaderWidget').jqxLoader('close');
                } else {
                    $('#jqxLoader').jqxLoader('close');
                }
            }
        });
    });

    window.saveButtonTab1 = window.sampleForm.jqxForm('getComponentByName', 'saveButton');
    window.saveButtonTab1.on('click', function () {
        if (hash != '') {
            window.newValuesFormTab1.saveButtonClik = true;
        }
        window.shareButtonTab1.click();
    });

    window.shareButtonTab1 = window.sampleForm.jqxForm('getComponentByName', 'shareButton');

    window.shareButtonTab1.on('click', function () {
        if (isDebugg) { console.log('0501'); }
        //Set form tab values to the window object.
        initPrepareFormData();
        prepareFixedNodesData();
        // Show preloader
        if (isWidget == 1) {
            $('#jqxLoaderWidget').jqxLoader('open');
        } else {
            $('#jqxLoader').jqxLoader('open');
        }
        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }

        saveCurrenFormDataSelectValues();
        if ((typeof window.newValuesFormTab1.saveButtonClik !== "undefined")
            && (window.newValuesFormTab1.saveButtonClik === true)) {
            if (hash != '') {
                window.newValuesFormTab1.shareHash = hash;
            }
        } else {
            window.newValuesFormTab1.shareHash = '';
        }

        var chartType = $('#chartType').val();
        if (chartType == CHART_TYPE_RADIAL) {
            var wordsList = window.radialData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
            var wordsList = window.dendrogramHorizontalData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if (chartType == CHART_TYPE_MOLECULAR) {
            var wordsList = window.molecularData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if (chartType == CHART_TYPE_TIMESERIES) {
            var wordsList = window.timeSeriesData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
            window.newValuesFormTab1.hiddenLegendItems = JSON.stringify(getHiddenLegendItems(chartType));
        }
        if (chartType == CHART_TYPE_TIMELINE) {
            var wordsList = window.timelineData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
            window.newValuesFormTab1.hiddenLegendItems = JSON.stringify(getHiddenLegendItems(chartType));
        }
        if (chartType == CHART_TYPE_STREAMGRAPH) {
            var wordsList = window.streamgraphData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
            var wordsList = window.sankeyData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if ((chartType == CHART_TYPE_TEXTUALWORDS) || (chartType == CHART_TYPE_TEXTUALWORDSMETADATA)) {
            window.newValuesFormTab1.drawingValueIndicators = JSON.stringify(getDrawingValueIndicators());
        }
        if (chartType == CHART_TYPE_TEXTUALWORDS) {
            window.newValuesFormTab1.textualWordsGridSorting = JSON.stringify($('#dataTextualWords').jqxGrid('getsortinformation'));
            var textualWordsGridState = $('#dataTextualWords').jqxGrid('getstate');
            if (typeof textualWordsGridState != "undefined") {
                if (typeof textualWordsGridState.pagenum != "undefined") {
                    window.newValuesFormTab1.textualWordsGridPagenum = textualWordsGridState.pagenum;
                }
                if (typeof textualWordsGridState.pagesize != "undefined") {
                    window.newValuesFormTab1.textualWordsGridPagesize = textualWordsGridState.pagesize;
                }
            }
        }

        window.newValuesFormTab1.selectedTab = getCurSelectedTab();
        window.newValuesFormTab1.synonymsWordsCustom = $('#synonymsWordsCustom').val();
        $.ajax({
            // the route pointing to the post function 
            url: '/share',
            type: 'POST',
            // send the csrf-token and the input to the controller 
            data: {
                _token: CSRF_TOKEN,
                formdata: window.newValuesFormTab1
            },
            dataType: 'JSON',
            // remind that 'data' is the response of the AjaxController
            success: function (data) {
                if (data.result) {
                    if (typeof theme === "undefined") {
                        theme = themeDefault;
                    }
                    if ((typeof window.newValuesFormTab1.saveButtonClik !== "undefined")
                        && (window.newValuesFormTab1.saveButtonClik === true)) {
                        window.newValuesFormTab1.saveButtonClik = false;
                    } else if ((typeof window.newValuesFormTab1.isOpenSharedIn !== "undefined")
                        && (window.newValuesFormTab1.isOpenSharedIn === true)) {

                        var urlStr = window.location.origin + '/drawing?' + theme + '&share=' + data.result;
                        openSharedIn(urlStr);
                    } else if ((typeof window.newValuesFormTab1.isMetadata !== "undefined")
                    && (window.newValuesFormTab1.isMetadata === true)) {
                        //test3.hce-project.com/drawing?arctic&share=1dedb2e9a46e32cf81016f03348aa5c7&metadata=1&word=United States of America&date=2021-11-07
                        
                        var urlStr = window.location.origin + '/drawing?' + theme + '&share=' + data.result + '&metadata=1';
                        //prepareSharedMetaData(urlStr);
                        openSharedIn(urlStr);
                    } else {
                        $('<div id="sharedText"><textarea class="selectable" cols="26" rows="5" type="text" readonly="readonly">'
                            + window.location.origin + '/drawing?' + theme + '&share=' + data.result + '</textarea></div>')
                            .dialog({ title: "Please, copy share URL", modal: true });
                        $(".selectable").click(function () {
                            $(this).select();
                        });
                    }
                } else {
                    cleanup();
                    $('#ErrorListContaner').text(data.errMsg);
                }
                // hide preloader on ajax succes
                if (isWidget == 1) {
                    $('#jqxLoaderWidget').jqxLoader('close');
                } else {
                    $('#jqxLoader').jqxLoader('close');
                }
            },
            error: function (data) {
                $('#ErrorListContaner').text(data.errMsg);
                cleanup();
                // hide preloader on ajax error
                if (isWidget == 1) {
                    $('#jqxLoaderWidget').jqxLoader('close');
                } else {
                    $('#jqxLoader').jqxLoader('close');
                }
            }
        });
    });

    window.widgetButtonTab1 = window.sampleForm.jqxForm('getComponentByName', 'widgetButton');

    window.widgetButtonTab1.on('click', function () {
        if (isDebugg) { console.log('0501'); }
        prepareAndShowWidgetHTML();
    });

    window.helpButtonTab1 = window.sampleForm.jqxForm('getComponentByName', 'helpButton');
    window.helpButtonTab1.on('click', function () {
        basicFormDrawingHelp.init();
        $('#window').jqxWindow('open');
        $('#tabFormDrawingHelp').jqxTabs({ selectedItem: 0 });
    });

    $('#nubmberBoxValue1').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 50,
        decimalDigits: 0,
        min: -1000,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: true,
        //inputMode: 'simple'
    });

    var nubmberBoxValue1Class = $('#nubmberBoxValue1 input').attr('class');
    $('#nubmberBoxValue1').html('<input type="number" step="1" min="-1000" max="1000" value="50" class="quantity ' +
        nubmberBoxValue1Class + '" />');
    $('#nubmberBoxValue1 .quantity').numeric({ precision: 4, decimal: ".", negative: true, scale: 0 });

    $('#nubmberBoxValue2').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: '',
        decimalDigits: 0,
        min: -1000,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var nubmberBoxValue2Class = $('#nubmberBoxValue2 input').attr('class');
    $('#nubmberBoxValue2').html('<input type="number" step="1" min="-1000" max="1000" value="" class="quantity ' +
        nubmberBoxValue1Class + '" />');
    $('#nubmberBoxValue2 .quantity').numeric({ precision: 4, decimal: ".", negative: true, scale: 0 });

    $('#nubmberBoxNeighborsFreqMin').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: '',
        decimalDigits: 0,
        min: 0,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false,
        inputMode: 'simple'
    });

    var nubmberBoxNeighborsFreqMinClass = $('#nubmberBoxNeighborsFreqMin input').attr('class');
    $('#nubmberBoxNeighborsFreqMin').html('<input type="number" step="1" min="0" max="1000" value="" class="quantity ' +
        nubmberBoxNeighborsFreqMinClass + '" />');
    $('#nubmberBoxNeighborsFreqMin .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('#nubmberBoxNeighborsFreqMax').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: '',
        decimalDigits: 0,
        min: 0,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false,
        inputMode: 'simple'
    });

    var nubmberBoxNeighborsFreqMaxClass = $('#nubmberBoxNeighborsFreqMax input').attr('class');
    $('#nubmberBoxNeighborsFreqMax').html('<input  type="number" step="1" min="0" max="1000" value="" class="quantity ' +
        nubmberBoxNeighborsFreqMaxClass + '" />');
    $('#nubmberBoxNeighborsFreqMax .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('#nubmberBoxSatellitesFreqMin').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: '',
        decimalDigits: 0,
        min: 0,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false,
        inputMode: 'simple'
    });

    var nubmberBoxSatellitesFreqMinClass = $('#nubmberBoxSatellitesFreqMin input').attr('class');
    $('#nubmberBoxSatellitesFreqMin').html('<input  type="number" step="1" min="0" max="1000" value="" class="quantity ' +
        nubmberBoxSatellitesFreqMinClass + '" />');
    $('#nubmberBoxSatellitesFreqMin .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('#nubmberBoxSatellitesFreqMax').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: '',
        decimalDigits: 0,
        min: 0,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var nubmberBoxSatellitesFreqMaxClass = $('#nubmberBoxSatellitesFreqMax input').attr('class');
    $('#nubmberBoxSatellitesFreqMax').html('<input  type="number" step="1" min="0" max="1000" value="" class="quantity ' +
        nubmberBoxValue1Class + '" />');
    $('#nubmberBoxSatellitesFreqMax .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('#nubmberBoxAppearanceMin').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 50,
        decimalDigits: 0,
        min: 1,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: true,
        inputMode: 'simple'
    });

    var nubmberBoxAppearanceMinClass = $('#nubmberBoxAppearanceMin input').attr('class');
    $('#nubmberBoxAppearanceMin').html('<input  type="number" step="1" min="1" max="1000" value="2" class="quantity ' +
        nubmberBoxAppearanceMinClass + '" />');
    $('#nubmberBoxAppearanceMin .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('#nubmberBoxAppearanceMax').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 50,
        decimalDigits: 0,
        min: 1,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: true,
        inputMode: 'simple'
    });

    var nubmberBoxAppearanceMaxClass = $('#nubmberBoxAppearanceMax input').attr('class');
    $('#nubmberBoxAppearanceMax').html('<input  type="number" step="1" min="0" max="1000" value="" class="quantity ' +
        nubmberBoxAppearanceMaxClass + '" />');
    $('#nubmberBoxAppearanceMax .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('#nubmberBoxValueMaxWordsTotal').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 20,
        decimalDigits: 0,
        min: 0,
        max: 10000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var nubmberBoxValueMaxWordsTotalClass = $('#nubmberBoxValueMaxWordsTotal input').attr('class');
    $('#nubmberBoxValueMaxWordsTotal').html('<input  type="number" step="1" min="0" max="1000" value="20" class="quantity ' +
        nubmberBoxValueMaxWordsTotalClass + '" />');
    $('#nubmberBoxValueMaxWordsTotal .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('#nubmberBoxNeighborsMaxItems').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 5,
        decimalDigits: 0,
        min: 0,
        max: 10000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var nubmberBoxNeighborsMaxItemsClass = $('#nubmberBoxNeighborsMaxItems input').attr('class');
    $('#nubmberBoxNeighborsMaxItems').html('<input  type="number" step="1" min="0" max="1000" value="5" class="quantity ' +
        nubmberBoxNeighborsMaxItemsClass + '" />');
    $('#nubmberBoxNeighborsMaxItems .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('.quantity').closest('td').width('98%');

    showHideTypeDependentItems();

    $('#formTab1').closest("div").addClass('full-height');
    setTimeout(function () {
        setDataSourceTooltip();
    }, 500);

}

/**
 * Calls up charting functions depending on type.
 * Generates output to the information block,
 * in accordance with the values in the response.
 * @param dataChart
 * @returns
 */
function successChart(dataChart) {
    if (dataChart.result) {
        if ($('#chartType').val() == CHART_TYPE_RADIAL) {
            $('#RadialMaxSize').val(dataChart.maxSize);
        }
        if ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) {
            $('#DendrogramHorizontalMaxSize').val(dataChart.maxSize);
        }
        if ($('#chartType').val() == CHART_TYPE_MOLECULAR) {
            $('#MolecularMaxSize').val(dataChart.maxSize);
        }
        if ($('#chartType').val() == CHART_TYPE_TIMESERIES) {
            window.colorsArrayUsed = [];
            window.colorsArrayUsedMinIndex = [];
            $('#TimeSeriesMaxSize').val(dataChart.maxSize);
        }
        if ($('#chartType').val() == CHART_TYPE_TIMELINE) {
            $('#TimeLineMaxSize').val(dataChart.maxSize);
        }
        if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {
            $('#StreamgraphMaxSize').val(dataChart.maxSize);
        }
        if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {
            $('#SankeyMaxSize').val(dataChart.maxSize);
        }
        if (dataChart.errMsg == '') {
            $('#ErrorListContaner').text('');
        } else {
            $('#ErrorListContaner').text(dataChart.errMsg);
        }
    } else {
        cleanup();
        $('#ErrorListContaner').text(dataChart.errMsg);
    }
    if (isWidget == 0) {
        if (typeof dataChart.info == "string") {
            $('#InfoPanelContaner').html(dataChart.info);
        } else if (typeof dataChart.info == "object") {
            var infoContent = '<table class="infoPanelTable"><tr><th class="key">Name</th><th class="val">Value</th></tr>';
            jQuery.each(dataChart.info, function (key, val) {
                if (($('#chartType').val() == CHART_TYPE_TIMESERIES) && (key == 'nodes')) {
                } else {
                    infoContent = infoContent + '<tr><td class="key">' + key + ': </td><td class="val">' + val + '</td></tr>';
                }
            });
            infoContent = infoContent + '</table>'
            $('#InfoPanelContaner').html(infoContent);
        }
    }
    if (dataChart.result) {
        setTimeout(function () {
            if (typeof dataChart.addData !== "undefined") {
                if (typeof dataChart.addData === "string") {
                    window.addData = JSON.parse(dataChart.addData);
                } else {
                    window.addData = dataChart.addData;
                }
            }
            if ($('#chartType').val() == CHART_TYPE_TIMESERIES) {//timeseries
                setTimeout(function () {
                    showChart(dataChart, window.newValuesFormTab1);
                }, 500);
            } else if ($('#chartType').val() == CHART_TYPE_TIMELINE) {//timeline
                setTimeout(function () {
                    showChart(dataChart, window.newValuesFormTab1);
                }, 500);
            } else if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {//streamgraph
                setTimeout(function () {
                    showChart(dataChart, window.newValuesFormTab1);
                }, 500);
            } else if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                setTimeout(function () {
                    showChart(dataChart, window.newValuesFormTab1);
                }, 50);
            } else if ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                setTimeout(function () {
                    showChart(dataChart, window.newValuesFormTab1);
                }, 500);
            } else {
                showChart(dataChart.result, window.newValuesFormTab1);
            }
        }, 500);
    }
    // hide preloader on ajax succes
}
/**
 * Set values to the window object from tab2 form for the create common parameters list
 * @returns
 */
function setValuesFromTab2() {
    if (isDebugg) { console.log('06'); }
    if (typeof window.newValuesFormTab1 == "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(true);
        window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(false);
    }
    window.newValuesFormTab2.changedCTColor = $('td.colorColumnChartText .chart-text-new-value').val();

    window.newValuesFormTab1.checkboxConnectorsLinesValue = window.newValuesFormTab2.checkboxConnectorsLinesValue;
    window.newValuesFormTab1.checkboxIndentValuesRelatedValue = window.newValuesFormTab2.checkboxIndentValuesRelatedValue;
    window.newValuesFormTab1.checkboxNotIncludeSingleNodesValue = window.newValuesFormTab2.checkboxNotIncludeSingleNodesValue;
    window.newValuesFormTab1.checkboxReduceOverlapValue = window.newValuesFormTab2.checkboxReduceOverlapValue;
    window.newValuesFormTab1.checkboxShowHideLegendValue = window.newValuesFormTab2.checkboxShowHideLegendValue;
    window.newValuesFormTab1.checkboxShowHideSeriesLabelValue = window.newValuesFormTab2.checkboxShowHideSeriesLabelValue;
    window.newValuesFormTab1.checkboxShowHideTitleValue = window.newValuesFormTab2.checkboxShowHideTitleValue;
    window.newValuesFormTab1.checkboxAddNodesFixLevel = window.newValuesFormTab2.checkboxAddNodesFixLevel;
    window.newValuesFormTab1.checkboxShowHideMenuValue = window.newValuesFormTab2.checkboxShowHideMenuValue;
    window.newValuesFormTab1.checkboxShowHideTableHeaderValue = window.newValuesFormTab2.checkboxShowHideTableHeaderValue;
    window.newValuesFormTab1.checkboxOrientationHorizontalVertical = window.newValuesFormTab2.checkboxOrientationHorizontalVertical;
    window.newValuesFormTab1.checkboxShowHideMetadataColumnValue = window.newValuesFormTab2.checkboxShowHideMetadataColumnValue;
    window.newValuesFormTab1.checkboxShowHidePaginationValue = window.newValuesFormTab2.checkboxShowHidePaginationValue;
    window.newValuesFormTab1.checkboxConnectNullsValue = window.newValuesFormTab2.checkboxConnectNullsValue;
    window.newValuesFormTab1.dropdownConnectorLinesSourceValue = window.newValuesFormTab2.dropdownConnectorLinesSourceValue;
    window.newValuesFormTab1.circleSizeMult = window.newValuesFormTab2.circleSizeMult;
    window.newValuesFormTab1.fontSizeMult = window.newValuesFormTab2.fontSizeMult;
    window.newValuesFormTab1.sourcePointsSizeMult = window.newValuesFormTab2.sourcePointsSizeMult;
    window.newValuesFormTab1.lineWidthMult = window.newValuesFormTab2.lineWidthMult;
    window.newValuesFormTab1.gravityValue = window.newValuesFormTab2.gravityValue;
    window.newValuesFormTab1.indentValue = window.newValuesFormTab2.indentValue;
    window.newValuesFormTab1.radiusValue = window.newValuesFormTab2.radiusValue;
    window.newValuesFormTab1.dropdownMaxNodes = window.newValuesFormTab2.dropdownMaxNodes;
    window.newValuesFormTab1.dropdownColorsSelection = window.newValuesFormTab2.dropdownColorsSelection;
    window.newValuesFormTab1.dropdownConnectorLineWidthIndicator = window.newValuesFormTab2.dropdownConnectorLineWidthIndicator;
    window.newValuesFormTab1.dropdownConnectorLinesColor = window.newValuesFormTab2.dropdownConnectorLinesColor;
    window.newValuesFormTab1.dropdownColorsSet = window.newValuesFormTab2.dropdownColorsSet;
    window.newValuesFormTab1.dropdownChartBackgroundColor = window.newValuesFormTab2.dropdownChartBackgroundColor;
    window.newValuesFormTab1.dropdownChartTextColor = window.newValuesFormTab2.dropdownChartTextColor;
    window.newValuesFormTab1.changedCTColor = window.newValuesFormTab2.changedCTColor;
    window.newValuesFormTab1.dropdownBallsText = window.newValuesFormTab2.dropdownBallsText;
    window.newValuesFormTab1.dropdownBallsTextLayout = window.newValuesFormTab2.dropdownBallsTextLayout;
    window.newValuesFormTab1.dropdownAutoAdjust = window.newValuesFormTab2.dropdownAutoAdjust;
    window.newValuesFormTab1.dropdownAutoAdjustCnt = window.newValuesFormTab2.dropdownAutoAdjustCnt;
    window.newValuesFormTab1.dropdownChartView = window.newValuesFormTab2.dropdownChartView;
    window.newValuesFormTab1.checkboxSourceDataPointsValue = window.newValuesFormTab2.checkboxSourceDataPointsValue;
    window.newValuesFormTab1.checkboxAxisTurnOnOffValue = window.newValuesFormTab2.checkboxAxisTurnOnOffValue;
}

/**
 * Set values to the window object from tab3 form for the create common parameters list
 * @returns
 */
function setValuesFromTab3() {
    if (isDebugg) { console.log('07'); }
    if (typeof window.newValuesFormTab1 == "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(true);
        window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(false);
    }
    if (typeof window.newValuesFormTab3 === "undefined") {
        window.newValuesFormTab3 = {};
    }
    if (typeof window.previousValueFormTab1 === "undefined") {
        window.previousValueFormTab1 = {};
    }

    window.newValuesFormTab1.dropdownChartView = window.newValuesFormTab3.dropdownChartView;
    if (typeof window.newValuesFormTab3.numberBoxDegree != "undefined") {
        window.previousValueFormTab1.numberBoxDegree = window.newValuesFormTab3.numberBoxDegree;
    }
    window.newValuesFormTab1.numberBoxDegree = window.newValuesFormTab3.numberBoxDegree;
    if (typeof window.newValuesFormTab3.numberBoxFilterThreshold != "undefined") {
        window.previousValueFormTab1.numberBoxFilterThreshold = window.newValuesFormTab3.numberBoxFilterThreshold;
    }
    window.newValuesFormTab1.numberBoxFilterThreshold = window.newValuesFormTab3.numberBoxFilterThreshold;
    if (typeof window.newValuesFormTab3.numberBoxClustersNumber != "undefined") {
        window.previousValueFormTab1.numberBoxClustersNumber = window.newValuesFormTab3.numberBoxClustersNumber;
    }
    window.newValuesFormTab1.numberBoxClustersNumber = window.newValuesFormTab3.numberBoxClustersNumber;
    if (typeof window.newValuesFormTab3.numberBoxEpsilon != "undefined") {
        window.previousValueFormTab1.numberBoxEpsilon = window.newValuesFormTab3.numberBoxEpsilon;
    }
    window.newValuesFormTab1.numberBoxEpsilon = window.newValuesFormTab3.numberBoxEpsilon;
    if (typeof window.newValuesFormTab3.numberBoxMinItemsPerCluster != "undefined") {
        window.previousValueFormTab1.numberBoxMinItemsPerCluster = window.newValuesFormTab3.numberBoxMinItemsPerCluster;
    }
    window.newValuesFormTab1.numberBoxMinItemsPerCluster = window.newValuesFormTab3.numberBoxMinItemsPerCluster;
    if (typeof window.newValuesFormTab3.dropdownDataOmission != "undefined") {
        window.previousValueFormTab1.dropdownDataOmission = window.newValuesFormTab3.dropdownDataOmission;
    }
    window.newValuesFormTab1.dropdownDataOmission = window.newValuesFormTab3.dropdownDataOmission;
    if (typeof window.newValuesFormTab3.checkboxPreserveDateRange != "undefined") {
        window.previousValueFormTab1.checkboxPreserveDateRange = window.newValuesFormTab3.checkboxPreserveDateRange;
    }
    window.newValuesFormTab1.checkboxPreserveDateRange = window.newValuesFormTab3.checkboxPreserveDateRange;
    if (typeof window.newValuesFormTab3.dropdownFilterModel != "undefined") {
        window.previousValueFormTab1.dropdownFilterModel = window.newValuesFormTab3.dropdownFilterModel;
    }
    window.newValuesFormTab1.dropdownFilterModel = window.newValuesFormTab3.dropdownFilterModel;
    var scaleSmoothValues = separationScaleSmoothValuesForBackwardCompatibility(window.newValuesFormTab3.dropdownValueVScale, window.newValuesFormTab3.dropdownValueVSmooth);

    //if (typeof window.newValuesFormTab3.dropdownValueVScale != "undefined") {
    //    window.previousValueFormTab1.dropdownValueVScale = window.newValuesFormTab3.dropdownValueVScale;
    //}
    window.newValuesFormTab1.dropdownValueVScale = window.newValuesFormTab3.dropdownValueVScale = scaleSmoothValues.valueVScale;
    //if (typeof window.newValuesFormTab3.dropdownValueVSmooth != "undefined") {
    //    window.previousValueFormTab1.dropdownValueVSmooth = window.newValuesFormTab3.dropdownValueVSmooth;
    //}
    window.newValuesFormTab1.dropdownValueVSmooth = window.newValuesFormTab3.dropdownValueVSmooth = scaleSmoothValues.valueVSmooth;
    if (typeof window.newValuesFormTab3.dropdownModel != "undefined") {
        window.previousValueFormTab1.dropdownModel = window.newValuesFormTab3.dropdownModel;
    }
    window.newValuesFormTab1.dropdownModel = window.newValuesFormTab3.dropdownModel;
    if (typeof window.newValuesFormTab3.dropdownFilterDirection != "undefined") {
        window.previousValueFormTab1.dropdownFilterDirection = window.newValuesFormTab3.dropdownFilterDirection;
    }
    window.newValuesFormTab1.dropdownFilterDirection = window.newValuesFormTab3.dropdownFilterDirection;
    if (typeof window.newValuesFormTab3.dropdownFactorsData != "undefined") {
        window.previousValueFormTab1.dropdownFactorsData = window.newValuesFormTab3.dropdownFactorsData;
    }
    window.newValuesFormTab1.dropdownFactorsData = window.newValuesFormTab3.dropdownFactorsData;
    if (typeof window.newValuesFormTab3.dropdownCentroidInitialization != "undefined") {
        window.previousValueFormTab1.dropdownCentroidInitialization = window.newValuesFormTab3.dropdownCentroidInitialization;
    }
    window.newValuesFormTab1.dropdownCentroidInitialization = window.newValuesFormTab3.dropdownCentroidInitialization;
    if (typeof window.newValuesFormTab3.dropdownClusterizationModel != "undefined") {
        window.previousValueFormTab1.dropdownClusterizationModel = window.newValuesFormTab3.dropdownClusterizationModel;
    }
    window.newValuesFormTab1.dropdownClusterizationModel = window.newValuesFormTab3.dropdownClusterizationModel;
    if (typeof window.newValuesFormTab3.dropdownInterpolationPrediction != "undefined") {
        window.previousValueFormTab1.dropdownInterpolationPrediction = window.newValuesFormTab3.dropdownInterpolationPrediction;
    }
    window.newValuesFormTab1.dropdownInterpolationPrediction = window.newValuesFormTab3.dropdownInterpolationPrediction;

    window.newValuesFormTab1.isChange3 = window.newValuesFormTab3.isChange3;
}

/**
 * Set values to the window object from tab3 form for the create common parameters list
 * @returns
 */
function setValuesFromTab4() {
    if (isDebugg) { console.log('08'); }
    if (typeof window.newValuesFormTab1 == "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(true);
        window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(false);
    }
    if (isDebugg) { '081', console.log('wordsList'); }
    if (firstLoadChart == 1 && isShare && hash != '') {
    } else {
        window.newValuesFormTab1.wordsList = window.newValuesFormTab4.wordsList;
    }
    var colorInputs = $('td.wordsColorColumn .node-color-old-value');
    var changedColors = {};
    $.each(colorInputs, function (index, item) {
        if ($(item).val() != $(item).next().val()) {
            changedColors[$(item).next().attr('name')] = {
                'color': $(item).next().val()
            };
        }
    });

    if (firstLoadChart == 1 && isShare && hash != '') {
    } else {
        window.newValuesFormTab1.changedColors = window.newValuesFormTab4.changedColors = changedColors;
    }
}

/** 
 * Create form fot the Drawing tab,from temlate
 * set initial values and asign js events.
 * @param template
 * @returns
 */
function createFormDrawing(template) {
    if (isDebugg) { console.log('09'); }
    var sampleValue = {
        'isChange2': false,
        'textBoxTitleTimeCharts': window.formInitData.textBoxTitleTimeCharts,
        'textBoxSubtitleTimeCharts': window.formInitData.textBoxSubtitleTimeCharts,
        'textBoxDescriptionTimeCharts': window.formInitData.textBoxDescriptionTimeCharts,
        'checkboxConnectorsLinesValue': window.formInitData.checkboxConnectorsLinesValue,
        'checkboxAppearanceFullTimeValue': window.formInitData.checkboxAppearanceFullTimeValue,
        'checkboxIndentValuesRelatedValue': window.formInitData.checkboxIndentValuesRelatedValue,
        'checkboxNotIncludeSingleNodesValue': window.formInitData.checkboxNotIncludeSingleNodesValue,
        'checkboxReduceOverlapValue': window.formInitData.checkboxReduceOverlapValue,
        'checkboxShowHideLegendValue': window.formInitData.checkboxShowHideLegendValue,
        'checkboxShowHideSeriesLabelValue': window.formInitData.checkboxShowHideSeriesLabelValue,
        'checkboxShowHideTitleValue': window.formInitData.checkboxShowHideTitleValue,
        'checkboxAddNodesFixLevel': window.formInitData.checkboxAddNodesFixLevel,
        'checkboxShowHideMenuValue': window.formInitData.checkboxShowHideMenuValue,
        'checkboxShowHideTableHeaderValue': window.formInitData.checkboxShowHideTableHeaderValue,
        'checkboxOrientationHorizontalVertical': window.formInitData.checkboxOrientationHorizontalVertical,
        'checkboxShowHideMetadataColumnValue': window.formInitData.checkboxShowHideMetadataColumnValue,
        'checkboxShowHidePaginationValue': window.formInitData.checkboxShowHidePaginationValue,
        'checkboxConnectNullsValue': window.formInitData.checkboxConnectNullsValue,
        'dropdownConnectorLinesSourceValue': window.formInitData.dropdownConnectorLinesSourceValue,
        'checkboxSourceDataPointsValue': window.formInitData.checkboxSourceDataPointsValue,
        'checkboxAxisTurnOnOffValue': window.formInitData.checkboxAxisTurnOnOffValue,
        'circleSizeMult': window.formInitData.circleSizeMult,
        'fontSizeMult': window.formInitData.fontSizeMult,
        'sourcePointsSizeMult': window.formInitData.sourcePointsSizeMult,
        'lineWidthMult': window.formInitData.lineWidthMult,
        'gravityValue': window.formInitData.gravityValue,
        'indentValue': window.formInitData.indentValue,
        'radiusValue': window.formInitData.radiusValue,
        'dropdownMaxNodes': window.formInitData.dropdownMaxNodes,
        'dropdownColorsSelection': window.formInitData.dropdownColorsSelection,
        'dropdownConnectorLineWidthIndicator': window.formInitData.dropdownConnectorLineWidthIndicator,
        'dropdownConnectorLinesColor': window.formInitData.dropdownConnectorLinesColor,
        'dropdownColorsSet': window.formInitData.dropdownColorsSet,
        'dropdownChartBackgroundColor': window.formInitData.dropdownChartBackgroundColor,
        'dropdownChartTextColor': window.formInitData.dropdownChartTextColor,
        'changedCTColor': window.formInitData.changedCTColor,
        'dropdownBallsText': window.formInitData.dropdownBallsText,
        'dropdownBallsTextLayout': window.formInitData.dropdownBallsTextLayout,
        'dropdownAutoAdjust': window.formInitData.dropdownAutoAdjust,
        'dropdownAutoAdjustCnt': window.formInitData.dropdownAutoAdjustCnt,
        'dropdownChartView': window.formInitData.dropdownChartView,
        'nubmberBoxValueMaxWordsTotalTextual': window.formInitData.nubmberBoxValueMaxWordsTotalTextual,
        'nubmberBoxValueFontSize': window.formInitData.nubmberBoxValueFontSize
    };
    window.sampleFormDrawing = $('#sampleFormDrawing');
    window.sampleFormDrawing.jqxForm({
        template: template,
        value: sampleValue,
        padding: { left: 1, top: 5, right: 1, bottom: 10 }
    });
    window.btnDrawing = window.sampleFormDrawing.jqxForm('getComponentByName', 'submitButton');

    $('#valueIndicatorContaner').closest("table").attr('id', 'valueIndicatorTableContaner');
    $('#valueIndicatorContaner').closest("td").attr('colspan', '2');
    $('#valueIndicatorTableContaner').append(drawingValueIndicatorCheckboxes);

    var sourceMaxNodes = [
        { label: "Unlimited", value: '0' },
    ];
    for (var i = 1; i < 30; i++) {
        sourceMaxNodes.push({ label: i + '', value: i + '' });
    }
    for (var i = 30; i <= 100; i += 10) {
        sourceMaxNodes.push({ label: i + '', value: i + '' });
    }
    sourceMaxNodes.push({ label: '5%', value: '105' });
    for (var i = 10; i <= 100; i += 10) {
        sourceMaxNodes.push({ label: i + '%', value: (i + 100) + '' });
    }
    var elMaxNodesCont = $('.form-dropdown-max-nodes-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elMaxNodesCont.find('input').attr('hidden', 'hidden');
    elMaxNodesCont.append('<div id="jqxWidgetMaxNodes"></div>');
    $("#jqxWidgetMaxNodes").prev().css("display", "none");
    $("#jqxWidgetMaxNodes").jqxDropDownList({
        source: sourceMaxNodes,
        selectedIndex: 37,
        width: '135px',
        //height: '30',
        //autoDropDownHeight: true,
        displayMember: 'label',
        valueMember: 'value',
        dropDownHeight: 250
    });

    $("#jqxWidgetMaxNodes").on('change', function (event) {
        var dropdownMaxNodes = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownMaxNodes');
        dropdownMaxNodes.jqxInput('val', event.args.item.value);
    });

    var sourceAutoAdjust = [
        { label: "No", value: '1' },
        { label: "Spread wide", value: '2' },
        { label: "Rectangle perimeter", value: '3' },
        { label: "Circle perimeter", value: '4' },
        { label: "Circle + center", value: '5' },
        { label: "Circle2 + corner", value: '6' },
        { label: "Circle in a circle", value: '7' },
        { label: "Cloud around center node", value: '8' }
    ];
    var elAutoAdjustCont = $('.form-dropdown-auto-adjust-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elAutoAdjustCont.find('input').attr('hidden', 'hidden');
    elAutoAdjustCont.append('<div id="jqxWidgetAutoAdjust"></div>');
    $("#jqxWidgetAutoAdjust").prev().css("display", "none");
    $("#jqxWidgetAutoAdjust").jqxDropDownList({
        source: sourceAutoAdjust,
        selectedIndex: (1 - 1),
        width: '135px',
        height: '30',
        autoDropDownHeight: true,
        displayMember: 'label',
        valueMember: 'value'
    });
    $("#jqxWidgetAutoAdjust").on('change', function (event) {
        var dropdownAutoAdjust = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownAutoAdjust');
        dropdownAutoAdjust.jqxInput('val', event.args.item.value);
        if (firstLoadChart == 1 && isShare && hash != '') {
            if (typeof window.newValuesFormTab1.fixedNodesArr !== "undefined") {
                /*if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
                    window.molecularData[chartIndex].fixedNodesArr = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
                    window.newValuesFormTab1.fixedNodesArr = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
                } else {
                    window.molecularData[chartIndex].fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
                }*/
                window.molecularData[chartIndex].fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
                window.newValuesFormTab1.fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
            }
        } else {
            $('g.node.user-fixed').removeClass('user-fixed');
            window.newValuesFormTab1.fixedNodesArr = {};
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            window.molecularData[chartIndex].fixedNodesArr = {};
        }
        if (typeof curDataChart != "undefined") {
            //prepareFixedNodesData();
            showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
        }
    });

    $('#nubmberBoxValueMaxWordsTotalTextual').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 1000,
        decimalDigits: 0,
        min: 0,
        max: 10000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var nubmberBoxValueMaxWordsTotalTextualClass = $('#nubmberBoxValueMaxWordsTotalTextual input').attr('class');
    $('#nubmberBoxValueMaxWordsTotalTextual').html('<input  type="number" step="1" min="0" max="10000" value="1000" class="quantity ' +
        nubmberBoxValueMaxWordsTotalTextualClass + '" />');
    $('#nubmberBoxValueMaxWordsTotalTextual .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $('.quantity').closest('td').width('98%');

    $('#nubmberBoxValueFontSize').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 10,
        decimalDigits: 0,
        min: 0,
        max: 50,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var nubmberBoxValueFontSizeClass = $('#nubmberBoxValueFontSize input').attr('class');
    $('#nubmberBoxValueFontSize').html('<input  type="number" step="1" min="0" max="50" value="" class="quantity ' +
        nubmberBoxValueFontSizeClass + '" />');
    $('#nubmberBoxValueFontSize .quantity').numeric({ precision: 2, decimal: ".", negative: false, scale: 0 });

    $('.quantity').closest('td').width('98%');

    var elAutoAdjustCntCont = $('.form-dropdown-auto-adjust-cnt-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elAutoAdjustCntCont.find('input').attr('hidden', 'hidden');
    elAutoAdjustCntCont.append('<div id="jqxWidgetAutoAdjustCnt"></div>');
    $("#jqxWidgetAutoAdjustCnt").prev().css("display", "none");
    $("#jqxWidgetAutoAdjustCnt").jqxDropDownList({
        source: dropdownAutoAdjustCntOptions,
        selectedIndex: 35,
        width: '70px',
        height: '30',
        autoDropDownHeight: true,
        dropDownHeight: 200,
        autoDropDownHeight: false,
        displayMember: 'label',
        valueMember: 'value',
        enableBrowserBoundsDetection: true
    });
    $("#jqxWidgetAutoAdjustCnt").on('change', function (event) {
        var dropdownAutoAdjustCnt = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownAutoAdjustCnt');
        dropdownAutoAdjustCnt.jqxInput('val', event.args.item.value);
    });

    sourceChartView = [
        { label: "Line - Basic", value: '1' },               //https://www.highcharts.com/demo/line-ajax (first implemented)
        { label: "Line - Accessible", value: '2' },          //https://www.highcharts.com/demo/accessible-line
        /*{label: "Line - Master detailed", value: '3'},*/ //https://www.highcharts.com/demo/dynamic-master-detail
        { label: "Line - Data labels", value: '4' },         //https://www.highcharts.com/demo/line-labels
        { label: "Line - Zoomable", value: '5' },            //https://www.highcharts.com/demo/line-time-series
        { label: "Line - Spline", value: '6' },              //https://www.highcharts.com/demo/spline-plot-bands (just spline without any additional subtitles)
        { label: "Line - Irregular intervals", value: '7' }, //https://www.highcharts.com/demo/spline-irregular-time
        { label: "Line - Logarithmic axis", value: '8' },    //https://www.highcharts.com/demo/line-log-axis
        /*{label: "Line - Huge values number", value: '9'},//https://www.highcharts.com/demo/line-boost*/
        { label: "Area - Basic", value: '10' },              //https://www.highcharts.com/demo/area-basic
        { label: "Area - Stacked", value: '11' },            //https://www.highcharts.com/demo/area-stacked
        { label: "Area - Spline", value: '12' },             //https://www.highcharts.com/demo/areaspline
        /*{label: "Area - Sparkline", value: '13'},        //https://www.highcharts.com/demo/sparkline
        {label: "Area - Streamgraph", value: '14'},        //https://www.highcharts.com/demo/streamgraph
        {label: "Sankey - Basic", value: '15'} */          //https://www.highcharts.com/demo/sankey-diagram
    ];
    var elChartViewCont = $('.form-dropdown-chart-view-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elChartViewCont.find('input').attr('hidden', 'hidden');
    elChartViewCont.prepend('<div id="jqxWidgetChartView"></div>');
    $("#jqxWidgetChartView").prev().css("display", "none");
    $("#jqxWidgetChartView").jqxDropDownList({
        source: sourceChartView,
        selectedIndex: (1 - 1),
        width: '100%',
        height: '30',
        autoDropDownHeight: true,
        displayMember: 'label',
        valueMember: 'value',
        enableBrowserBoundsDetection: true
    });
    $("#jqxWidgetChartView").on('change', function (event) {
        var dropdownChartView = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartView');
        dropdownChartView.jqxInput('val', event.args.item.value);
    });

    window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSet').jqxDropDownList('enableBrowserBoundsDetection', true);
    window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSet').jqxDropDownList('disableAt', 1);
    window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSet').jqxDropDownList('disableAt', 12)

    window.sampleFormDrawing.on('formDataChange', function (event) {
        var args = event.args;
        if (args.value.isChange2 === true) {
            window.newValuesFormTab2 = args.value;
            window.previousValueFormTab2 = args.previousValue;
            window.newValuesFormTab2.circleSizeMult = new Number($('#sizeSliderElement').val() * 1).toPrecision(3);
            window.newValuesFormTab2.fontSizeMult = new Number($('#fontSizeSliderElement').val() * 1).toPrecision(3);
            window.newValuesFormTab2.sourcePointsSizeMult = new Number($('#sizeSourcePointsSliderElement').val() * 1).toPrecision(3);
            window.newValuesFormTab2.lineWidthMult = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
            window.newValuesFormTab2.gravityValue = new Number($('#gravitySliderElement').val() * 1).toPrecision(3);
            window.newValuesFormTab2.indentValue = new Number($('#indentSliderElement').val() * 1).toPrecision(3);
            window.newValuesFormTab2.radiusValue = new Number($('#radiusSliderElement').val() * 1).toPrecision(3);

            setValuesFromTab2();

            if (isDebugg) { console.log('091', window.newValuesFormTab2); }
            window.sampleFormDrawing.jqxForm('getComponentByName', 'isChangeTab2').val(false);
        }
    });

    window.btnDrawing.on('click', function () {
        // Show preloader
        if (isWidget == 1) {
            $('#jqxLoaderWidget').jqxLoader('open');
        } else {
            $('#jqxLoader').jqxLoader('open');
        }
        window.btnMain.click();
    });
    window.saveButtonTab2 = window.sampleFormDrawing.jqxForm('getComponentByName', 'saveButton');
    window.saveButtonTab2.on('click', function () {
        window.saveButtonTab1.click();
    });
    window.shareButtonTab2 = window.sampleFormDrawing.jqxForm('getComponentByName', 'shareButton');
    window.shareButtonTab2.on('click', function () {
        window.shareButtonTab1.click();
    });
    window.widgetButtonTab2 = window.sampleFormDrawing.jqxForm('getComponentByName', 'widgetButton');
    window.widgetButtonTab2.on('click', function () {
        window.widgetButtonTab1.click();
    });
    window.helpButtonTab2 = window.sampleFormDrawing.jqxForm('getComponentByName', 'helpButton');
    window.helpButtonTab2.on('click', function () {
        basicFormDrawingHelp.init();
        $('#window').jqxWindow('open');
        $('#tabFormDrawingHelp').jqxTabs({ selectedItem: 2 });
    });

    if (isDebugg) { console.log('0901 sizeSliderElement'); }
    $('#sizeSliderElement').jqxSlider(
        {
            width: 'calc(100% - 70px)',
            height: 30,
            mode: "fixed",
            showMinorTicks: true,
            minorTicksFrequency: 0.25,
            showTickLabels: false,
            min: 0,
            max: 3,
            value: 1,
            step: 0.05,
            tooltip: true,
            showButtons: true,
            tooltipFormatFunction: function (value) { return new Number(value * 1).toPrecision(2); }
        }
    );
    if (isDebugg) { console.log('0901 fontSizeSliderElement'); }
    $('#fontSizeSliderElement').jqxSlider(
        {
            width: 'calc(100% - 70px)',
            height: 30,
            mode: "fixed",
            showMinorTicks: true,
            minorTicksFrequency: 0.25,
            showTickLabels: false,
            min: 0,
            max: 3,
            value: 1,
            step: 0.1,
            tooltip: true,
            showButtons: true,
            tooltipFormatFunction: function (value) { return new Number(value * 1).toPrecision(2); }
        }
    );

    if (isDebugg) { console.log('09011 sizeSourcePointsSliderElement'); }
    $('#sizeSourcePointsSliderElement').jqxSlider(
        {
            width: 'calc(100% - 70px)',
            height: 30,
            mode: "fixed",
            showMinorTicks: true,
            minorTicksFrequency: 0.25,
            showTickLabels: false,
            min: 0,
            max: 3,
            value: 1,
            step: 0.25,
            tooltip: true,
            showButtons: true,
            tooltipFormatFunction: function (value) { return new Number(value * 1).toPrecision(2); }
        }
    );
    $('#lineWidthSliderElement').jqxSlider(
        {
            width: 'calc(100% - 70px)',
            height: 30,
            mode: "fixed",
            showMinorTicks: true,
            minorTicksFrequency: 0.5,
            showTickLabels: false,
            min: 0,
            max: 6,
            value: 1,
            step: 0.25,
            tooltip: true,
            showButtons: true,
            tooltipFormatFunction: function (value) { return new Number(value * 1).toPrecision(2); }
        }
    );
    if (isDebugg) { console.log('0902 gravitySliderElement'); }
    $('#gravitySliderElement').jqxSlider(
        {
            width: 'calc(100% - 70px)',
            height: 30,
            mode: "fixed",
            ticksFrequency: 0.25,
            showMinorTicks: true,
            minorTicksFrequency: 0.125,
            showTickLabels: false,
            min: 0,
            max: 1,
            value: 0.13,
            step: 0.01,
            tooltip: true,
            showButtons: true,
            tooltipFormatFunction: function (value) { return new Number(value * 1).toPrecision(3); }
        }
    );
    if (isDebugg) { console.log('0903 indentSliderElement'); }
    $('#indentSliderElement').jqxSlider(
        {
            width: 'calc(100% - 70px)',
            height: 30,
            mode: "fixed",
            ticksFrequency: 500,
            showMinorTicks: true,
            minorTicksFrequency: 250,
            showTickLabels: false,
            min: -1000,
            max: 1000,
            value: -300,
            step: 10,
            tooltip: true,
            showButtons: true
        }
    );
    if (isDebugg) { console.log('0904 radiusSliderElement'); }
    $('#radiusSliderElement').jqxSlider(
        {
            width: 'calc(100% - 70px)',
            height: 30,
            mode: "fixed",
            showMinorTicks: true,
            minorTicksFrequency: 0.5,
            showTickLabels: false,
            min: 0.1,
            max: 5,
            value: 1,
            step: 0.1,
            tooltip: true,
            showButtons: true,
            tooltipFormatFunction: function (value) { return new Number(value * 1).toPrecision(2); }
        }
    );
    showHideTypeDependentItems();
}

/**
 * Create form fot the Analysis tab,from temlate
 * set initial values and asign js events.
 * @param template
 * @returns
 */
function createFormAnalysis(template) {
    if (isDebugg) { console.log('10'); }
    var sampleValue = {
        'radiobuttonClusterization': '0',
        'dropdownClustersNumber': '2',
        'dropdownDataSource': '1',
        'dropdownMaxIterations': '1',
        'numberBoxDegree': window.formInitData.numberBoxDegree,
        'numberBoxFilterThreshold': window.formInitData.numberBoxFilterThreshold,
        'numberBoxClustersNumber': window.formInitData.numberBoxClustersNumber,
        'numberBoxEpsilon': window.formInitData.numberBoxEpsilon,
        'numberBoxMinItemsPerCluster': window.formInitData.numberBoxMinItemsPerCluster,
        'dropdownDataOmission': window.formInitData.dropdownDataOmission,
        'checkboxPreserveDateRange': window.formInitData.checkboxPreserveDateRange,
        'dropdownFilterModel': window.formInitData.dropdownFilterModel,
        'dropdownValueVScale': window.formInitData.dropdownValueVScale,
        'dropdownValueVSmooth': window.formInitData.dropdownValueVSmooth,
        'dropdownModel': window.formInitData.dropdownModel,
        'dropdownFilterDirection': window.formInitData.dropdownFilterDirection,
        'dropdownFactorsData': window.formInitData.dropdownFactorsData,
        'dropdownCentroidInitialization': window.formInitData.dropdownCentroidInitialization,
        'dropdownClusterizationModel': window.formInitData.dropdownClusterizationModel,
        'dropdownInterpolationPrediction': window.formInitData.dropdownInterpolationPrediction,
        'isChange3': false
    };
    window.sampleFormAnalysis = $('#sampleFormAnalysis');
    window.sampleFormAnalysis.jqxForm({
        template: template,
        value: sampleValue,
        padding: { left: 1, top: 5, right: 1, bottom: 10 },
    });
    window.btnAnalysis = window.sampleFormAnalysis.jqxForm('getComponentByName', 'submitButton');

    var elClustersNumberCont = $('.form-radiobutton-clusterization-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elClustersNumberCont.find('input').attr('hidden', 'hidden');
    elClustersNumberCont.append('<div id="jqxWidgetRadiobuttonClusterization">'
        + '<div id="jqxradiobutton1">None</div>'
        + '<div id="jqxradiobutton2">nltk kmeans</div>'
        + '<div id="jqxradiobutton3">sklearn kmeans</div>'
        + '<div id="jqxradiobutton4">sklearn SpectralClustering</div>'
        + '</div>');
    $("#jqxWidgetRadiobuttonClusterization").prev().css("display", "none");
    $("#jqxradiobutton1").jqxRadioButton({ groupName: 'radiobuttonClusterization', width: 190, height: 25, checked: true, disabled: true });
    $("#jqxradiobutton2").jqxRadioButton({ groupName: 'radiobuttonClusterization', width: 190, height: 25, disabled: true });
    $("#jqxradiobutton3").jqxRadioButton({ groupName: 'radiobuttonClusterization', width: 190, height: 25, disabled: true });
    $("#jqxradiobutton4").jqxRadioButton({ groupName: 'radiobuttonClusterization', width: 190, height: 25, disabled: true });

    var sourceClustersNumber = [
        { label: "2", value: '2' },
        { label: "3", value: '3' },
        { label: "4", value: '4' },
        { label: "5", value: '5' },
        { label: "Auto up to 5", value: '6' }
    ];
    var elClustersNumberCont = $('.form-dropdown-clusters-number-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elClustersNumberCont.find('input').attr('hidden', 'hidden');
    elClustersNumberCont.append('<div id="jqxWidgetClustersNumber"></div>');
    $("#jqxWidgetClustersNumber").prev().css("display", "none");
    $("#jqxWidgetClustersNumber").jqxDropDownList({
        source: sourceClustersNumber,
        selectedIndex: 2,
        width: '105px',
        height: '30',
        disabled: true
    });
    $("#jqxWidgetClustersNumber").on('change', function (event) {
    });

    var sourceDataSource = [
        { label: "Mutual presence matrix", value: '1' },
        { label: "Distances matrix", value: '2' },
        { label: "Word`s fragments text converted with Word2Vec()", value: '3' }
    ];
    var elDataSourceCont = $('.form-data-source-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elDataSourceCont.find('input').attr('hidden', 'hidden');
    elDataSourceCont.append('<div id="jqxWidgetDataSource"></div>');
    $("#jqxWidgetDataSource").prev().css("display", "none");
    $("#jqxWidgetDataSource").jqxDropDownList({
        source: sourceDataSource,
        selectedIndex: 1,
        width: 'calc(100% - 5px)',
        height: '30',
        disabled: true
    });
    $("#jqxWidgetDataSource").on('change', function (event) { });

    var sourceMaxIterations = [
        { label: "1", value: '1' },
        { label: "2", value: '2' },
        { label: "3", value: '3' },
        { label: "4", value: '4' },
        { label: "5", value: '5' },
        { label: "6", value: '6' },
        { label: "7", value: '7' },
        { label: "8", value: '8' },
        { label: "9", value: '9' },
        { label: "10", value: '10' }
    ];
    var elMaxIterationsCont = $('.form-max-iterations-input')
        .parent().parent().parent().parent().next().find('div>div>input').parent();
    elMaxIterationsCont.find('input').attr('hidden', 'hidden');
    elMaxIterationsCont.append('<div id="jqxWidgetMaxIterations"></div>');
    $("#jqxWidgetMaxIterations").prev().css("display", "none");
    $("#jqxWidgetMaxIterations").jqxDropDownList({
        source: sourceMaxIterations,
        selectedIndex: 1,
        width: '55px',
        height: '30',
        disabled: true
    });
    $("#jqxWidgetMaxIterations").on('change', function (event) { });

    $('#numberBoxDegree').jqxNumberInput({
        width: '100%',
        height: '30px',
        decimal: 20,
        decimalDigits: 0,
        min: 0,
        max: 10000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var numberBoxDegreeClass = $('#numberBoxDegree input').attr('class');
    $('#numberBoxDegree').html('<input  type="number" step="1" min="3" max="32" value="4" class="quantity ' +
        numberBoxDegreeClass + '" />');
    $('#numberBoxDegree .quantity').numeric({ precision: 2, decimal: ",", negative: false, scale: 0 });

    $('#numberBoxFilterThreshold').jqxNumberInput({
        width: '70px',
        height: '30px',
        decimal: 0.65,
        decimalDigits: 2,
        min: 0,
        max: 1,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var numberBoxFilterThresholdClass = $('#numberBoxFilterThreshold input').attr('class');
    $('#numberBoxFilterThreshold').html(
        '<input  pattern="[0-9]+([\.,][0-9]+)?"  type="number" step="0.05" min="0.00" max="1.00" value="0.65" class="quantity '
        + numberBoxFilterThresholdClass + '" />');

    $("#numberBoxFilterThreshold input").on('keyup', function (event) {
        if ($("#numberBoxFilterThreshold input").val() > 1) {
            $("#numberBoxFilterThreshold input").val(1);
        }
        if ($("#numberBoxFilterThreshold input").val() < 0) {
            $("#numberBoxFilterThreshold input").val(0);
        }
    });

    $('#numberBoxClustersNumber').jqxNumberInput({
        width: '70px',
        height: '30px',
        decimal: 2,
        decimalDigits: 2,
        min: 2,
        max: 32,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var numberBoxClustersNumberClass = $('#numberBoxClustersNumber input').attr('class');
    $('#numberBoxClustersNumber').html('<input  type="number" step="1" min="2" max="32" value="2" class="quantity ' +
        numberBoxClustersNumberClass + '" />');
    $('#numberBoxClustersNumber .quantity').numeric({ precision: 2, decimal: ".", negative: false, scale: 0 });

    $('#numberBoxEpsilon').jqxNumberInput({
        width: '70px',
        height: '30px',
        decimal: 100,
        decimalDigits: 2,
        spinButtons: true,
        min: 0,
        max: 100,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false
    });
    var numberBoxEpsilonClass = $('#numberBoxEpsilon input').attr('class');
    $('#numberBoxEpsilon').html('<input  type="number" step="0.5" min="-0.5" max="100" value="100" class="quantity ' +
        numberBoxEpsilonClass + '" />');
    $('#numberBoxEpsilon .quantity').numeric({ spinButtons: true, precision: 3, decimal: ".", negative: false, scale: 0 });

    $("#numberBoxEpsilon input").on('keyup mouseup change', function (event) {
        if ($("#numberBoxEpsilon input").val() > 100) {
            $("#numberBoxEpsilon input").val(100);
        }
        if ($("#numberBoxEpsilon input").val() < 0.1) {
            $("#numberBoxEpsilon input").val(0.1);
        }
    });

    $('#numberBoxMinItemsPerCluster').jqxNumberInput({
        width: '70px',
        height: '30px',
        decimal: 1,
        decimalDigits: 4,
        min: 1,
        max: 1000,
        promptChar: '',
        groupSeparator: ' ',
        allowNull: false,
        digits: 4
    });
    var numberBoxMinItemsPerClusterClass = $('#numberBoxMinItemsPerCluster input').attr('class');
    $('#numberBoxMinItemsPerCluster').html('<input  type="number" step="1" min="1" max="1000" value="1" class="quantity ' +
        numberBoxMinItemsPerClusterClass + '" />');
    $('#numberBoxMinItemsPerCluster .quantity').numeric({ precision: 4, decimal: ".", negative: false, scale: 0 });

    $("#numberBoxMinItemsPerCluster input").on('keyup mouseup change', function (event) {
        if ($("#numberBoxMinItemsPerCluster input").val() > 1000) {
            $("#numberBoxMinItemsPerCluster input").val(1000);
        }
        if ($("#numberBoxMinItemsPerCluster input").val() < 1) {
            $("#numberBoxMinItemsPerCluster input").val(1);
        }
    });

    window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownFactorsData').jqxDropDownList('enableBrowserBoundsDetection', true)
    window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownClusterizationModel').jqxDropDownList('enableBrowserBoundsDetection', true)

    window.sampleFormAnalysis.on('formDataChange', function (event) {
        var args = event.args;
        if (args.value.isChange3 === true) {
            window.newValuesFormTab3 = args.value;

            window.previousValueFormTab3 = args.previousValue;
            window.sampleFormAnalysis.jqxForm('getComponentByName', 'isChangeTab3').val(false);
        }
    });

    window.btnAnalysis.on('click', function () {
        // Show preloader
        if (isWidget == 1) {
            $('#jqxLoaderWidget').jqxLoader('open');
        } else {
            $('#jqxLoader').jqxLoader('open');
        }
        window.btnMain.click();
    });

    window.shareButtonTab3 = window.sampleFormAnalysis.jqxForm('getComponentByName', 'shareButton');
    window.shareButtonTab3.on('click', function () {
        window.shareButtonTab1.click();
    });

    window.saveButtonTab3 = window.sampleFormAnalysis.jqxForm('getComponentByName', 'saveButton');
    window.saveButtonTab3.on('click', function () {
        window.saveButtonTab1.click();
    });

    window.widgetButtonTab3 = window.sampleFormAnalysis.jqxForm('getComponentByName', 'widgetButton');
    window.widgetButtonTab3.on('click', function () {
        window.widgetButtonTab1.click();
    });

    window.helpButtonTab3 = window.sampleFormAnalysis.jqxForm('getComponentByName', 'helpButton');
    window.helpButtonTab3.on('click', function () {
        basicFormDrawingHelp.init();
        $('#window').jqxWindow('open');
        $('#tabFormDrawingHelp').jqxTabs({ selectedItem: 3 });
    });

    showHideTypeDependentItems();
}

/**
 * Shows / hidden form elements, the display 
 * of which depends on the type of chart.
 * @returns
 */
function showHideTypeDependentItems() {
    if (isDebugg) { console.log('11'); }
    $('#checkboxIsChangeTab1').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
    $('#checkboxIsChangeTab2').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
    $('#checkboxIsChangeTab3').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
    $('#checkboxIsChangeTab4').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

    $('.form-invisible-item-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

    $('#checkboxAppearanceFullTimeTMP').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
    $('.data-source-label2').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
    $("#dataContaner").css({
        width: '99%',
        height: 'calc(100% - 12px)'
    });
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    if ($('#chartType').val() == CHART_TYPE_MOLECULAR) { // Molecular
        //Data select tab
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label1').removeClass('unvisible');
        $('.frequency-min-max-label2').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#wordsListTableContaner').removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        
        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        
        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        if ($('.form-dropdown-chart-text-color-label').closest("tr").find('input').val() == 10) {
            $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        } else {
            $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        }

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        if (new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3) == 0) {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator').jqxDropDownList('disabled', false);
        } else {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator').jqxDropDownList('disabled', false);
        }

        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');


        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        // Show/hide logics for the Analisys>Clusterization items 
        //if ($('.clusterization-model-label').closest("tr").find('input').val() == 1) { // "k-means" model
        $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        /*} else if ($('.clusterization-model-label').closest("tr").find('input').val() == 2) { // "DBSCAN" model
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        } else {
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        }*/

        if (window.molecularData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.molecular);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
        if ($('.form-dropdown-colors-selection-label').closest("tr").find('input').val() == 10) {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').jqxDropDownList('selectIndex', 0);
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').jqxDropDownList('disableAt', 2);//enableAt


    } else if ($('#chartType').val() == CHART_TYPE_RADIAL) {// Radial
        //Data select tab
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label1').removeClass('unvisible');
        $('.frequency-min-max-label2').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#wordsListTableContaner').removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        if (new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3) == 0) {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator').jqxDropDownList('disabled', false);
        } else {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator').jqxDropDownList('disabled', false);
        }
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        // Show/hide logics for the Analisys>Clusterization items 
        $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        if (window.radialData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.radial);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').jqxDropDownList('enableAt', 2);//
    } else if ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
        //Data select tab
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label1').removeClass('unvisible');
        $('.frequency-min-max-label2').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#wordsListTableContaner').removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        if (new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3) == 0) {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator').jqxDropDownList('disabled', false);
        } else {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator').jqxDropDownList('disabled', false);
        }
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        // Show/hide logics for the Analisys>Clusterization items 
        $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        if (window.dendrogramHorizontalData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.radial);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').jqxDropDownList('enableAt', 2);//
    } else if ($('#chartType').val() == CHART_TYPE_TIMESERIES) { //  TimeSeries
        //Data select tab
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label2').removeClass('unvisible');
        $('.frequency-min-max-label1').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#wordsListTableContaner').removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        // Show/hide logics for the Analisys>Clusterization items 
        if ($('.clusterization-model-label').closest("tr").find('input').val() == 1) { // "k-means" model
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        } else if ($('.clusterization-model-label').closest("tr").find('input').val() == 2) { // "DBSCAN" model
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        } else {
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        }

        if (window.timeSeriesData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.timeSeries);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
        if ($('.form-dropdown-colors-selection-label').closest("tr").find('input').val() == 10) {
            window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').jqxDropDownList('selectIndex', 2);
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').jqxDropDownList('enableAt', 2);//enableAt
    } else if ($('#chartType').val() == CHART_TYPE_TIMELINE) { // TimeLine
        //Data select tab
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label2').removeClass('unvisible');
        $('.frequency-min-max-label1').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#wordsListTableContaner').removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        // Show/hide logics for the Analisys>Clusterization items 
        $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        if (window.timelineData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.timeline);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
    } else if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) { // streamgraph
        //Data select tab
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label2').removeClass('unvisible');
        $('.frequency-min-max-label1').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#wordsListTableContaner').removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
       $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        // Show/hide logics for the Analisys>Clusterization items 
        if ($('.clusterization-model-label').closest("tr").find('input').val() == 1) { // "k-means" model
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        } else if ($('.clusterization-model-label').closest("tr").find('input').val() == 2) { // "DBSCAN" model
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        } else {
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        }

        if (window.streamgraphData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.streamgraph);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
    } else if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) { // sankey
        //Data select tab
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label2').removeClass('unvisible');
        $('.frequency-min-max-label1').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#wordsListTableContaner').removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        // Show/hide logics for the Analisys>Clusterization items 
        if ($('.clusterization-model-label').closest("tr").find('input').val() == 1) { // "k-means" model
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        } else if ($('.clusterization-model-label').closest("tr").find('input').val() == 2) { // "DBSCAN" model
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        } else {
            $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
            $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        }

        if (window.sankeyData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.sankey);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').jqxDropDownList('enableAt', 2);//
    } else if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) {
        //Data select tab
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        //$('.max-depth-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label2').removeClass('unvisible');
        $('.frequency-min-max-label1').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-text-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        if (window.textualWordsData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.textualWords);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
    } else if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDSMETADATA) {
        //Data select tab
        $('.appearance-time-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.intermediate-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.words-selection-order-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.dropdown-data-select-cache-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.max-depth-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.distance-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-max-neighbors-words-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.frequency-min-max-label2').removeClass('unvisible');
        $('.frequency-min-max-label1').addClass('unvisible');
        $('.neighbors-freq-min-max-label').removeClass('unvisible');
        $('.satellites-freq-min-max-label1').removeClass('unvisible');
        $('.satellites-freq-min-max-label2').addClass('unvisible');
        $('.appearance-min-max-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.value-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-field-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-repeat-selection-unlim-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-data-relation-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.days-interval-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.min-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-daily-words-by-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-relations-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-intersected-neighbors-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.checkbox-articles-list-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Words select
        $('.form-search-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-search-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-input').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-exclude-word-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.unique-words-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.sort-order-words-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        //Drawing tab
        $('#valueIndicatorTableContaner').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-table-settings-label').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHideTableHeader').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxOrientationHorizontalVertical').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMetadataColumn').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('#checkboxShowHidePagination').closest("tr[id^='rowWrap_el_']").removeClass('unvisible');

        $('.form-textual-max-words-total-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").removeClass('unvisible');
        $('.form-font-size-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectorsLines').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxIndentValuesRelated').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxNotIncludeSingleNodes').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxReduceOverlap').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideLegend').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideSeriesLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-title-time-charts-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-subtitle-time-charts-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-description-time-charts-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideTitle').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAddNodesFixLevel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-chart-settings-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxShowHideMenu').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxConnectNulls').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownConnectorLinesSource').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#dropdownColorsSet').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxSourceDataPoints').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxAxisTurnOnOff').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#lineWidthSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#sizeSourcePointsSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#gravitySliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#indentSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.auto-adjust-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.max-nodes-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#radiusSliderElement').closest("tr[id^='rowWrap_el_']").addClass('unvisibleSmall');
        $('.chart-view-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.sizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.fontSizeSliderLabel').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-selection-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-connector-lines-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-colors-set-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-chart-background-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-chart-text-color-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-balls-text-layout-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('#colorsSelectionForLevelsContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#colorsSelectionForChartTextColorContaner').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        $('.form-dropdown-connector-line-width-indicator-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-text-colors-template-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        //Analysis
        $('.form-clusterization-title-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-radiobutton-clusterization-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-dropdown-clusters-number-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-data-source-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-max-iterations-input').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-values-transformations-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-omission-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('#checkboxPreserveDateRange').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-scale-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.value-smooth-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-regressions-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.model-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.interpolation-prediction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-threshold-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.data-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.filter-direction-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.factors-data-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.clusterization-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-filter-model-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusterization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-clusters-number-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-epsilon-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.form-min-items-per-cluster-label').closest("tr[id^='rowWrap_el_']").parent().closest("tr[id^='rowWrap_el_']").addClass('unvisible');
        $('.centroid-initialization-label').closest("tr[id^='rowWrap_el_']").addClass('unvisible');

        if (window.textualWordsData[chartIndex].wordsListReset == true) {
            $('#wordsListTableContaner tbody').remove();
            $('#wordsListTableContaner thead').remove();
            $('#wordsListTableContaner').prepend(window.wordsListHead.textualWords);
            $('#wordsCheckboxAll').on('click', function (e) {
                if (isDebugg) { console.log('431'); }
                var elem = $(this);
                var checkboxList = $('.wordsCheckboxItem');
                if (elem.is(':checked')) {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", true);
                    });
                } else {
                    $.each(checkboxList, function (index, item) {
                        $(item).prop("checked", false);
                    });
                }
            });
        }
    }
}

/**
 * Create form fot the Words tab,from temlate
 * set initial values and asign js events.
 * @param template
 * @returns
 */
function createFormWords(template) {
    if (isDebugg) { console.log('12'); }
    var sampleValue = {
        'textBoxValueSearchWord': '',
        'synonymsWordsCustom': '',
        'textBoxValueExcludeWord': '',
        'checkboxPOSAcronym': false,
        'checkboxPOSPersonName': false,
        'checkboxPOSWiki': false,
        'checkboxWordsGroupSubentries': false,
        'checkboxValueIndicatorLimitsStrict': false,
        'checkboxPOSGeo': false,
        'checkboxPOSAcronymVal': 67108864,
        'checkboxPOSPersonNameVal': (16777216 + 33554432),
        'checkboxPOSGeoVal': 8388608,

        //Satellites Type Mask
        'checkboxMultiWordSatellitesTypeMask': false,
        'checkboxProperSatellitesTypeMask': false,
        'checkboxNormalizedSatellitesTypeMask': false,
        'checkboxReadableSatellitesTypeMask': false,
        'checkboxCommonSatellitesTypeMask': false,
        'checkboxNumbersSatellitesTypeMask': false,

        //Satellites Type Mask
        'inputMultiWordSatellitesTypeMask': 1,
        'inputProperSatellitesTypeMask': 2,
        'inputNormalizedSatellitesTypeMask': 4,
        'inputReadableSatellitesTypeMask': 8,
        'inputCommonSatellitesTypeMask': 16,
        'inputNumbersSatellitesTypeMask': 32,

        //Relations Type Mask
        'checkboxPWIntersectedNeighbors': true,
        'checkboxPW1inNeighbors2': false,
        'checkboxPW2inNeighbors1': false,
        'checkboxIntersectedNeighbors': false,
        'checkboxArticlesList': false,
        
        'inputPWIntersectedNeighbors': 1,
        'inputPW1inNeighbors2': 2,
        'inputPW2inNeighbors1': 4,
        'inputIntersectedNeighbors': 8,
        'inputArticlesList': 16,

        'checkboxPOSWikiVal': 268435456,
        'textBoxTitleTimeCharts': window.formInitData.textBoxTitleTimeCharts,
        'textBoxSubtitleTimeCharts': window.formInitData.textBoxSubtitleTimeCharts,
        'textBoxDescriptionTimeCharts': window.formInitData.textBoxDescriptionTimeCharts,
        'isChange4': false,
        'wordsList': {},
        'dropdownUniqueWords': window.formInitData.dropdownUniqueWords,
        'dropdownSortOrderWords': window.formInitData.dropdownSortOrderWords
    };

    window.sampleFormWords = $('#sampleFormWords');
    window.sampleFormWords.jqxForm({
        template: template,
        value: sampleValue,
        padding: { left: 1, top: 5, right: 1, bottom: 10 }
    });
    window.btnWords = window.sampleFormWords.jqxForm('getComponentByName', 'submitButton');
    window.sampleFormWords.append('<input id="checkboxPOSAcronymVal" type="hidden" value="' + sampleValue.checkboxPOSAcronymVal + '">');
    window.sampleFormWords.append(
        '<input id="checkboxPOSPersonNameVal" type="hidden" value="' + sampleValue.checkboxPOSPersonNameVal + '">'
    );
    window.sampleFormWords.append('<input id="checkboxPOSGeoVal" type="hidden" value="' + sampleValue.checkboxPOSGeoVal + '">');
    window.sampleFormWords.append('<input id="checkboxPOSWikiVal" type="hidden" value="' + sampleValue.checkboxPOSWikiVal + '">');

    //Satellites Type Mask
    window.sampleFormWords.append('<input id="inputMultiWordSatellitesTypeMask" type="hidden" value="' + sampleValue.inputMultiWordSatellitesTypeMask + '">');
    window.sampleFormWords.append('<input id="inputProperSatellitesTypeMask" type="hidden" value="' + sampleValue.inputProperSatellitesTypeMask + '">');
    window.sampleFormWords.append('<input id="inputNormalizedSatellitesTypeMask" type="hidden" value="' + sampleValue.inputNormalizedSatellitesTypeMask + '">');
    window.sampleFormWords.append('<input id="inputReadableSatellitesTypeMask" type="hidden" value="' + sampleValue.inputReadableSatellitesTypeMask + '">');
    window.sampleFormWords.append('<input id="inputCommonSatellitesTypeMask" type="hidden" value="' + sampleValue.inputCommonSatellitesTypeMask + '">');
    window.sampleFormWords.append('<input id="inputNumbersSatellitesTypeMask" type="hidden" value="' + sampleValue.inputNumbersSatellitesTypeMask + '">');

    //Relations Type Mask
    window.sampleFormWords.append('<input id="inputPWIntersectedNeighbors" type="hidden" value="' + sampleValue.inputPWIntersectedNeighbors + '">');
    window.sampleFormWords.append('<input id="inputPW1inNeighbors2" type="hidden" value="' + sampleValue.inputPW1inNeighbors2 + '">');
    window.sampleFormWords.append('<input id="inputPW2inNeighbors1" type="hidden" value="' + sampleValue.inputPW2inNeighbors1 + '">');
    window.sampleFormWords.append('<input id="inputIntersectedNeighbors" type="hidden" value="' + sampleValue.inputIntersectedNeighbors + '">');
    window.sampleFormWords.append('<input id="inputArticlesList" type="hidden" value="' + sampleValue.inputArticlesList + '">');


    $('#filterPOSLabel').closest("tr[id^='rowWrap_el_']").next().attr('class', 'filterPOSRow');
    window.sampleFormWords.on('formDataChange', function (event) {
        var args = event.args;
        if (args.value.isChange4 === true) {
            window.newValuesFormTab4 = args.value;
            window.previousValueFormTab4 = args.previousValue;
            window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(false);
        }
    });

    window.btnWords.on('click', function () {
        // Show preloader
        if (isWidget == 1) {
            $('#jqxLoaderWidget').jqxLoader('open');
        } else {
            $('#jqxLoader').jqxLoader('open');
        }
        window.btnMain.click();
    });

    $('#CopyWordsListButton input').on('click', function () {
        copyTextToClipboard(document.querySelector('#wordsListCSV').value);
    });
    $('#CopyIncWordsListButton input').on('click', function () {
        copyCheckedWordsToSearch();
    });
    $('#CopyExcWordsListButton input').on('click', function () {
        copyCheckedWordsToExclude();
    });
    //Search expression
    var textBoxValueSearchWord = window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord');
    textBoxValueSearchWord.on('keyup mouseup change', function (event) {
        if (textBoxValueSearchWord.val().search('@') >= 0) {
            return false;
        }
        window.newValuesFormTab1.textBoxValueSearchWord
            = window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val();
        window.newValuesFormTab1.textBoxValueExcludeWord
            = window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueExcludeWord').val();
        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    });
    textBoxValueSearchWord.on('open', function (event) {
        $('.jqx-popup.jqx-input-popup.jqx-menu.jqx-menu-vertical.jqx-menu-dropdown.jqx-widget.jqx-widget-content.jqx-rc-all').css({
          'max-height': '300px',
          'overflow-y': 'auto',
          'overflow-x': 'hidden',
        });
    });

    textBoxValueSearchWord.jqxInput({
        placeHolder: "Enter a search expression",
        source: function (query, response) {
            //console.log(query);
            if (query.search('%') >= 0) {
                var item, searchItem;
                var item = searchItem = query.split(/,\s*/).pop();
                item = item.replace('%', '');
                textBoxValueSearchWord.jqxInput({ query: item });
                window.newValuesFormTab1.textBoxValueSearchWord = searchItem;
            }
            //console.log(query);
            var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
            var dataAdapter = new $.jqx.dataAdapter(
                {
                    type: 'POST',
                    datatype: "json",
                    /*datafields: [
                            { name: 'name' },
                            { name: 'countryName' },
                            { name: 'population', type: 'float' },
                            { name: 'continentCode' },
                            { name: 'adminName1' }
                    ],*/
                    url: "/getautocomplete", 
                    //url: "http://api.geonames.org/searchJSON",
                    data: {
                        _token: CSRF_TOKEN,
                        formdata: window.newValuesFormTab1
                    }
                    /*data:
                    {
                        featureClass: "P",
                        style: "full",
                        maxRows: 12,
                        username: "jqwidgets"
                    }*/
                },
                {
                    autoBind: true,
                    formatData: function (data) {
                        //console.log(data);
                        data.name_startsWith = query;
                        return data;
                    },
                    loadComplete: function (data) {
                        //alert(data);
                        //console.log(data);
                        if (data.length > 0) {
                            response($.map(data, function (item) {
                                //console.log(item);
                                return {
                                    label: item,
                                    value: item
                                }
                            }));
                        }
                        /*if (data.geonames.length > 0) {
                            response($.map(data.geonames, function (item) {
                                console.log(item.name);
                                return {
                                    label: item.name,
                                    value: item.name
                                }
                            }));
                        }*/
                    }
                }
            );
        },
        items: 10000,
        renderer: function (itemValue, inputValue) {
            //var cursorPosition = textBoxValueSearchWord.caretPosition();
            //console.log('cursorPosition = ', cursorPosition);
            var terms = inputValue.split(/,\s*/);
            // remove the current input
            terms.pop();
            // add the selected item
            terms.push(itemValue);
            // add placeholder to get the comma-and-space at the end
            terms.push("");
            var value = terms.join(",");
            value = value.substring(0, value.length - 1);
            //console.log(value);
            return value;
        }
    });

    //synonyms
    var synonymsWordsCustom = $('#synonymsWordsCustom');
    synonymsWordsCustom.on('keyup mouseup change', function (event) {
        window.newValuesFormTab1.synonymsWordsCustom = window.newValuesFormTab4.synonymsWordsCustom = $('#synonymsWordsCustom').val();
    });

    window.shareButtonTab4 = window.sampleFormWords.jqxForm('getComponentByName', 'shareButton');
    window.shareButtonTab4.on('click', function () {
        window.shareButtonTab1.click();
    });

    window.saveButtonTab4 = window.sampleFormWords.jqxForm('getComponentByName', 'saveButton');
    window.saveButtonTab4.on('click', function () {
        window.saveButtonTab1.click();
    });

    window.widgetButtonTab4 = window.sampleFormWords.jqxForm('getComponentByName', 'widgetButton');
    window.widgetButtonTab4.on('click', function () {
        window.widgetButtonTab1.click();
    });

    window.helpButtonTab4 = window.sampleFormWords.jqxForm('getComponentByName', 'helpButton');
    window.helpButtonTab4.on('click', function () {
        basicFormDrawingHelp.init();
        $('#window').jqxWindow('open');
        $('#tabFormDrawingHelp').jqxTabs({ selectedItem: 1 });
    });

    showHideTypeDependentItems();
}

function caretPosition(input) {
    var start = input[0].selectionStart,
        end = input[0].selectionEnd,
        diff = end - start;

    if (start >= 0 && start == end) {
        // do cursor position actions, example:
        console.log('Cursor Position: ' + start);
    } else if (start >= 0) {
        // do ranged select actions, example:
        console.log('Cursor Position: ' + start + ' to ' + end + ' (' + diff + ' selected chars)');
    }
}

function copyCheckedWordsToSearch() {
    var checkboxList = $('.wordsCheckboxItem');
    var checkedWords = window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val();
    $.each(checkboxList, function (index, item) {
        if ($(item).is(':checked')) {
            checkedWords = (checkedWords == '' ? '' : checkedWords + ',') + $(item).attr('name');
        }
    });

    window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val(checkedWords);
}

function copyCheckedWordsToExclude() {
    var checkboxList = $('.wordsCheckboxItem');
    var checkedWords = window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueExcludeWord').val();
    $.each(checkboxList, function (index, item) {
        if ($(item).is(':checked')) {
            checkedWords = (checkedWords == '' ? '' : checkedWords + ',') + $(item).attr('name');
        }
    });

    window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueExcludeWord').val(checkedWords);
}

function prepareFixedNodesData() {
    if (firstLoadChart == 1 && isShare && hash != '') {
    } else {
        var fixedNodes = $('g.node.user-fixed');
        var fixedNodesArr = {};
        if (typeof window.newValuesFormTab1 === "undefined") {
            window.newValuesFormTab1 = {};
        }
        if (typeof window.newValuesFormTab1.fixedNodesArr !== "undefined") {
            if (typeof window.newValuesFormTab1.fixedNodesArr === "string") {
                var fixedNodesArr = JSON.parse(window.newValuesFormTab1.fixedNodesArr);
            } else {
                var fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
            }
        } else {
            window.newValuesFormTab1.fixedNodesArr = {};
        }
        $.each(fixedNodes, function (index, item) {
            try {
                var attrStr = $(item).attr('transform');
                attrStr = attrStr.replace("translate(", "");
                attrStr = attrStr.replace(")", "");
                var coords = attrStr.split(",");
                var itemId = $(item).find('text').attr("text-str");
                fixedNodesArr[itemId] = {};
                fixedNodesArr[itemId].x = coords[0];
                fixedNodesArr[itemId].y = coords[1];
            } catch (err) {
                //console.log('Oops, attr transform undefined');
            }
        });
        var svgWidth = $('#dataContaner svg').width();
        var svgHeight = $('#dataContaner svg').height();
        window.newValuesFormTab1.svgWidth = svgWidth;
        window.newValuesFormTab1.svgHeight = svgHeight;
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        window.molecularData[chartIndex].fixedNodesArr = fixedNodesArr;
        window.newValuesFormTab1.fixedNodesArr = JSON.stringify(fixedNodesArr);
    }
}

function formDataToNewTab(curWord, curWordColor, curChartTextColor) {
    //initPrepareFormData();
    //prepareFixedNodesData();
    initPrepareFormDataNewTab();
    // Show preloader
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    saveCurrenFormDataSelectValues();
    window.newValuesFormTab1.curWord = curWord;
    window.newValuesFormTab1.curWordColor = curWordColor;
    window.newValuesFormTab1.curChartTextColor = curChartTextColor;

    window.newValuesFormTab1.nubmberBoxValue1 = '';
    window.newValuesFormTab1.nubmberBoxValue2 = '';
    window.newValuesFormTab1.checkboxRepeatSelectionMinMaxUnlim = false;
    window.newValuesFormTab1.checkboxValueIndicatorLimitsStrict = false;
    window.newValuesFormTab1.nubmberBoxAppearanceMin = 1;
    window.newValuesFormTab1.nubmberBoxAppearanceMax = '';
    window.newValuesFormTab1.checkboxAppearanceFullTimeValue = 0;



    var saveCheckboxPreserveDateRange = window.newValuesFormTab1.checkboxPreserveDateRange;
    window.newValuesFormTab1.checkboxPreserveDateRange = true;
    var formData = JSON.stringify(window.newValuesFormTab1);
    var actionUrl = window.location.toString().split('&share=');
    $('#newTabForm textarea').val(formData);
    $('#newTabForm').attr('action', actionUrl[0]);
    $('#newTabForm input').attr('value', $('meta[name="csrf-token"]').attr('content'));

    $('#newTabForm').submit();
    window.newValuesFormTab1.checkboxPreserveDateRange = saveCheckboxPreserveDateRange;
    return curWord;
}

function initPrepareFormDataNewTab() {
    var retObj = {};
    /*Set data for the chart type*/
    retObj.chartType
        = window.newValuesFormTab1.chartType
        = $('#chartType').val();
    /*Set data for the tab1*/
    retObj.dropdownValueDSource
        = window.newValuesFormTab1.dropdownValueDSource
        = $('#dropdownValueDSource').jqxDropDownList('val');
    retObj.dropdownValueDSource2
        = window.newValuesFormTab1.dropdownValueDSource2
        = $('.data-source-label2').closest("tr").find('input').val();
    retObj.dropdownDatesInterval
        = window.newValuesFormTab1.dropdownDatesInterval
        = $('#dropdownDatesInterval').jqxDropDownList('val');
    retObj.textBoxValueDatesRange
        = window.newValuesFormTab1.textBoxValueDatesRange
        = $('#inputjqxWidgetDateRange').val();
    retObj.textBoxValueExcludePeriods
        = window.newValuesFormTab1.textBoxValueExcludePeriods
        = $('.form-exclude-periods-input').closest("tr").find('input').val();
    retObj.nubmberBoxValue1
        = window.newValuesFormTab1.nubmberBoxValue1
        = $("#nubmberBoxValue1 input").val();
    retObj.nubmberBoxValue2
        = window.newValuesFormTab1.nubmberBoxValue2
        = $("#nubmberBoxValue2 input").val();
    retObj.checkboxRepeatSelectionMinMaxUnlim
        = window.newValuesFormTab1.checkboxRepeatSelectionMinMaxUnlim
        = $('.checkbox-repeat-selection-unlim-label').closest("tr").find('input').val();
    retObj.checkboxPWIntersectedNeighbors
        = window.newValuesFormTab1.checkboxPWIntersectedNeighbors
        = $('.checkbox-pop-words-intersected-neighbors-label').closest("tr").find('input').val();
    retObj.checkboxPW2inNeighbors1
        = window.newValuesFormTab1.checkboxPW2inNeighbors1
        = $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr").find('input').val();
    retObj.checkboxPW1inNeighbors2
        = window.newValuesFormTab1.checkboxPW1inNeighbors2
        = $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr").find('input').val();
    retObj.checkboxIntersectedNeighbors
        = window.newValuesFormTab1.checkboxIntersectedNeighbors
        = $('.checkbox-intersected-neighbors-label').closest("tr").find('input').val();
    retObj.checkboxArticlesList
        = window.newValuesFormTab1.checkboxArticlesList
        = $('.checkbox-articles-list-label').closest("tr").find('input').val();
    retObj.nubmberBoxNeighborsFreqMin
        = window.newValuesFormTab1.nubmberBoxNeighborsFreqMin
        = $("#nubmberBoxNeighborsFreqMin input").val();
    retObj.nubmberBoxNeighborsFreqMax
        = window.newValuesFormTab1.nubmberBoxNeighborsFreqMax
        = $("#nubmberBoxNeighborsFreqMax input").val();
    retObj.nubmberBoxSatellitesFreqMin
        = window.newValuesFormTab1.nubmberBoxSatellitesFreqMin
        = $("#nubmberBoxSatellitesFreqMin input").val();
    retObj.nubmberBoxSatellitesFreqMax
        = window.newValuesFormTab1.nubmberBoxSatellitesFreqMax
        = $("#nubmberBoxSatellitesFreqMax input").val();
    retObj.nubmberBoxAppearanceMin
        = window.newValuesFormTab1.nubmberBoxAppearanceMin
        = $("#nubmberBoxAppearanceMin input").val();
    retObj.nubmberBoxAppearanceMax
        = window.newValuesFormTab1.nubmberBoxAppearanceMax
        = $("#nubmberBoxAppearanceMax input").val();
    retObj.nubmberBoxValueMaxWordsTotal
        = window.newValuesFormTab1.nubmberBoxValueMaxWordsTotal
        = $("#nubmberBoxValueMaxWordsTotal input").val();
    retObj.nubmberBoxNeighborsMaxItems
        = window.newValuesFormTab1.nubmberBoxNeighborsMaxItems
        = $("#nubmberBoxNeighborsMaxItems input").val();
    retObj.textBoxValueSearchWord
        = window.newValuesFormTab1.textBoxValueSearchWord
        = window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val();
    retObj.synonymsWordsCustom
        = window.newValuesFormTab1.synonymsWordsCustom
        = window.newValuesFormTab4.synonymsWordsCustom
        = $('synonymsWordsCustom').val();
    retObj.textBoxValueExcludeWord
        = window.newValuesFormTab1.textBoxValueExcludeWord
        = window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueExcludeWord').val();
    retObj.dropdownValueIWords
        = window.newValuesFormTab1.dropdownValueIWords
        = $('.intermediate-words-label').closest("tr").find('input').val();
    retObj.dropdownDaysInterval
        = window.newValuesFormTab1.dropdownDaysInterval
        = $('#dropdownDaysInterval').val();
    retObj.dropdownMinIntersectedNeighbors
        = window.newValuesFormTab1.dropdownMinIntersectedNeighbors
        = $('#dropdownMinIntersectedNeighbors').val();
    retObj.dropdownSortDailyWordsBy
        = window.newValuesFormTab1.dropdownSortDailyWordsBy
        = $('.sort-daily-words-by-label').closest("tr").find('input').val();
    retObj.satellitesOrderCondition
        = window.newValuesFormTab1.satellitesOrderCondition
        = $('.words-selection-order-label').closest("tr").find('input').val();
    retObj.dropdownDataSelectCache
        = window.newValuesFormTab1.dropdownDataSelectCache
        = $('.dropdown-data-select-cache-label').closest("tr").find('input').val();
    retObj.dropdownValueMaxDepth
        = window.newValuesFormTab1.dropdownValueMaxDepth
        = $('.max-depth-label').closest("tr").find('input').val();
    retObj.dropdownMaxDistance
        = window.newValuesFormTab1.dropdownMaxDistance
        = $('.distance-label').closest("tr").find('input').val();
    retObj.dropdownValueVIndicator
        = window.newValuesFormTab1.dropdownValueVIndicator
        = $('.value-indicator-label').closest("tr").find('input').val();
    retObj.dropdownsSortField
        = window.newValuesFormTab1.dropdownsSortField
        = $('.sort-field-label').closest("tr").find('input').val();
    retObj.dropdownValueVScale
        = window.newValuesFormTab1.dropdownValueVScale
        = $('.value-scale-label').closest("tr").find('input').val();
    retObj.dropdownValueVScale
        = window.newValuesFormTab1.dropdownValueVSmooth
        = $('.value-smooth-label').closest("tr").find('input').val();
    retObj.checkboxPreserveDateRange
        = window.newValuesFormTab1.checkboxPreserveDateRange
        = window.sampleFormAnalysis.jqxForm('getComponentByName', 'checkboxPreserveDateRange').val();
    retObj.dropdownDataOmission
        = window.newValuesFormTab1.dropdownDataOmission
        = $('.data-omission-label').closest("tr").find('input').val();
    retObj.checkboxAppearanceFullTimeValue
        = window.newValuesFormTab1.checkboxAppearanceFullTimeValue
        = $('.appearance-time-label').closest("tr").find('input').val();
    retObj.hiddenLegendItems
        = window.newValuesFormTab1.hiddenLegendItems
        = getHiddenLegendItems($('#chartType').val());
    retObj.drawingValueIndicators
        = window.newValuesFormTab1.drawingValueIndicators
        = getDrawingValueIndicators();
    if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) {
        retObj.textualWordsGridSorting
            = window.newValuesFormTab1.textualWordsGridSorting = JSON.stringify($('#dataTextualWords').jqxGrid('getsortinformation'));
        var textualWordsGridState = $('#dataTextualWords').jqxGrid('getstate');
        if (typeof textualWordsGridState != "undefined") {
            if (typeof textualWordsGridState.pagenum != "undefined") {
                retObj.textualWordsGridPagenum = window.newValuesFormTab1.textualWordsGridPagenum = textualWordsGridState.pagenum;
            }
            if (typeof textualWordsGridState.pagesize != "undefined") {
                retObj.textualWordsGridPagesize = window.newValuesFormTab1.textualWordsGridPagesize = textualWordsGridState.pagesize;
            }
        }
    }
    retObj.checkboxPOSAcronymValue
        = window.newValuesFormTab1.checkboxPOSAcronymValue
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSAcronym').val();
    retObj.checkboxPOSGeoValue
        = window.newValuesFormTab1.checkboxPOSGeoValue
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSGeo').val();
    retObj.dropdownUniqueWords
        = window.newValuesFormTab1.dropdownUniqueWords
        = window.sampleFormWords.jqxForm('getComponentByName', 'dropdownUniqueWords').val();
    retObj.dropdownSortOrderWords
        = window.newValuesFormTab1.dropdownSortOrderWords
        = window.sampleFormWords.jqxForm('getComponentByName', 'dropdownSortOrderWords').val();

    //Satellites Type Mask
    retObj.checkboxMultiWordSatellitesTypeMask
        = window.newValuesFormTab1.checkboxMultiWordSatellitesTypeMask
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxMultiWordSatellitesTypeMask').val();
    retObj.checkboxProperSatellitesTypeMask
        = window.newValuesFormTab1.checkboxProperSatellitesTypeMask
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxProperSatellitesTypeMask').val();
    retObj.checkboxNormalizedSatellitesTypeMask
        = window.newValuesFormTab1.checkboxNormalizedSatellitesTypeMask
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNormalizedSatellitesTypeMask').val();
    retObj.checkboxReadableSatellitesTypeMask
        = window.newValuesFormTab1.checkboxReadableSatellitesTypeMask
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxReadableSatellitesTypeMask').val();
    retObj.checkboxCommonSatellitesTypeMask
        = window.newValuesFormTab1.checkboxCommonSatellitesTypeMask
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxCommonSatellitesTypeMask').val();
    retObj.checkboxNumbersSatellitesTypeMask
        = window.newValuesFormTab1.checkboxNumbersSatellitesTypeMask
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNumbersSatellitesTypeMask').val();


    retObj.checkboxPOSPersonNameValue
        = window.newValuesFormTab1.checkboxPOSPersonNameValue
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSPersonName').val();
    retObj.checkboxPOSWikiValue
        = window.newValuesFormTab1.checkboxPOSWikiValue
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSWiki').val()
    retObj.checkboxWordsGroupSubentries
        = window.newValuesFormTab1.checkboxWordsGroupSubentries
        = window.sampleFormWords.jqxForm('getComponentByName', 'checkboxWordsGroupSubentries').val()
    retObj.checkboxValueIndicatorLimitsStrict
        = window.newValuesFormTab1.checkboxValueIndicatorLimitsStrict
        = window.sampleForm.jqxForm('getComponentByName', 'checkboxValueIndicatorLimitsStrict').val()
    /*Set data for the tab2*/
    retObj.textBoxTitleTimeCharts
        = window.newValuesFormTab1.textBoxTitleTimeCharts
        = window.newValuesFormTab2.textBoxTitleTimeCharts
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxTitleTimeCharts').val();
    retObj.textBoxSubtitleTimeCharts
        = window.newValuesFormTab1.textBoxSubtitleTimeCharts
        = window.newValuesFormTab2.textBoxSubtitleTimeCharts
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxSubtitleTimeCharts').val();
    retObj.textBoxDescriptionTimeCharts
        = window.newValuesFormTab1.textBoxDescriptionTimeCharts
        = window.newValuesFormTab2.textBoxDescriptionTimeCharts
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxDescriptionTimeCharts').val();
    retObj.dropdownMaxNodes
        = window.newValuesFormTab2.dropdownMaxNodes
        = window.newValuesFormTab1.dropdownMaxNodes
        = $('.form-dropdown-max-nodes-input').closest("tr").find('input').val();
    retObj.dropdownColorsSelection
        = window.newValuesFormTab2.dropdownColorsSelection
        = window.newValuesFormTab1.dropdownColorsSelection
        = $('.form-dropdown-colors-selection-label').closest("tr").find('input').val();
    retObj.dropdownConnectorLineWidthIndicator
        = window.newValuesFormTab2.dropdownConnectorLineWidthIndicator
        = window.newValuesFormTab1.dropdownConnectorLineWidthIndicator
        = $('.form-dropdown-connector-line-width-indicator-label').closest("tr").find('input').val();
    retObj.dropdownConnectorLinesColor
        = window.newValuesFormTab2.dropdownConnectorLinesColor
        = window.newValuesFormTab1.dropdownConnectorLinesColor
        = $('.form-connector-lines-color-label').closest("tr").find('input').val();
    retObj.dropdownColorsSet
        = window.newValuesFormTab2.dropdownColorsSet
        = window.newValuesFormTab1.dropdownColorsSet
        = $('.form-dropdown-colors-set-label').closest("tr").find('input').val();
    retObj.dropdownChartBackgroundColor
        = window.newValuesFormTab2.dropdownChartBackgroundColor
        = window.newValuesFormTab1.dropdownChartBackgroundColor
        = $('.form-dropdown-chart-background-color-label').closest("tr").find('input').val();
    retObj.dropdownChartTextColor
        = window.newValuesFormTab2.dropdownChartTextColor
        = window.newValuesFormTab1.dropdownChartTextColor
        = $('.form-dropdown-chart-text-color-label').closest("tr").find('input').val();
    retObj.changedCTColor
        = window.newValuesFormTab2.changedCTColor
        = window.newValuesFormTab1.changedCTColor
        = $('td.colorColumnChartText .chart-text-new-value').val();
    retObj.dropdownBallsText
        = window.newValuesFormTab2.dropdownBallsText
        = window.newValuesFormTab1.dropdownBallsText
        = $('.form-dropdown-balls-text-label').closest("tr").find('input').val();
    retObj.dropdownBallsTextLayout
        = window.newValuesFormTab2.dropdownBallsTextLayout
        = window.newValuesFormTab1.dropdownBallsTextLayout
        = $('.form-dropdown-balls-text-layout-label').closest("tr").find('input').val();
    retObj.dropdownAutoAdjust
        = window.newValuesFormTab2.dropdownAutoAdjust
        = window.newValuesFormTab1.dropdownAutoAdjust
        = $('.form-dropdown-auto-adjust-input').closest("tr").find('input').val();
    retObj.dropdownAutoAdjustCnt
        = window.newValuesFormTab2.dropdownAutoAdjustCnt
        = window.newValuesFormTab1.dropdownAutoAdjustCnt
        = $('.form-dropdown-auto-adjust-cnt-input').closest("tr").find('input').val();
    retObj.dropdownChartView
        = window.newValuesFormTab2.dropdownChartView
        = window.newValuesFormTab1.dropdownChartView
        = $('.form-dropdown-chart-view-input').closest("tr").find('input').val();
    retObj.checkboxConnectorsLinesValue
        = window.newValuesFormTab2.checkboxConnectorsLinesValue
        = window.newValuesFormTab1.checkboxConnectorsLinesValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxConnectorsLines').val();
    retObj.circleSizeMult
        = window.newValuesFormTab2.circleSizeMult
        = window.newValuesFormTab1.circleSizeMult
        = new Number($('#sizeSliderElement').val() * 1).toPrecision(3);
    retObj.fontSizeMult
        = window.newValuesFormTab2.fontSizeMult
        = window.newValuesFormTab1.fontSizeMult
        = new Number($('#fontSizeSliderElement').val() * 1).toPrecision(3);

    retObj.sourcePointsSizeMult
        = window.newValuesFormTab2.sourcePointsSizeMult
        = window.newValuesFormTab1.sourcePointsSizeMult
        = new Number($('#sizeSourcePointsSliderElement').val() * 1).toPrecision(3);
    retObj.lineWidthMult
        = window.newValuesFormTab2.lineWidthMult
        = window.newValuesFormTab1.lineWidthMult
        = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
    retObj.gravityValue
        = window.newValuesFormTab2.gravityValue
        = window.newValuesFormTab1.gravityValue
        = new Number($('#gravitySliderElement').val() * 1).toPrecision(3);
    retObj.indentValue
        = window.newValuesFormTab2.indentValue
        = window.newValuesFormTab1.indentValue
        = new Number($('#indentSliderElement').val() * 1).toPrecision(3);
    retObj.radiusValue
        = window.newValuesFormTab2.radiusValue
        = window.newValuesFormTab1.radiusValue
        = new Number($('#radiusSliderElement').val()).toPrecision(3);
    retObj.checkboxIndentValuesRelatedValue
        = window.newValuesFormTab2.checkboxIndentValuesRelatedValue
        = window.newValuesFormTab1.checkboxIndentValuesRelatedValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxIndentValuesRelated').val();
    retObj.checkboxNotIncludeSingleNodesValue
        = window.newValuesFormTab2.checkboxNotIncludeSingleNodesValue
        = window.newValuesFormTab1.checkboxNotIncludeSingleNodesValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxNotIncludeSingleNodes').val();
    retObj.checkboxReduceOverlapValue
        = window.newValuesFormTab2.checkboxReduceOverlapValue
        = window.newValuesFormTab1.checkboxReduceOverlapValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxReduceOverlap').val();
    retObj.checkboxShowHideLegendValue
        = window.newValuesFormTab2.checkboxShowHideLegendValue
        = window.newValuesFormTab1.checkboxShowHideLegendValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideLegend').val();
    retObj.checkboxShowHideSeriesLabelValue
        = window.newValuesFormTab2.checkboxShowHideSeriesLabelValue
        = window.newValuesFormTab1.checkboxShowHideSeriesLabelValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideSeriesLabel').val();
    retObj.checkboxShowHideTitleValue
        = window.newValuesFormTab2.checkboxShowHideTitleValue
        = window.newValuesFormTab1.checkboxShowHideTitleValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTitle').val();
    retObj.checkboxAddNodesFixLevel
        = window.newValuesFormTab2.checkboxAddNodesFixLevel
        = window.newValuesFormTab1.checkboxAddNodesFixLevel
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAddNodesFixLevel').val();
    retObj.checkboxShowHideMenuValue
        = window.newValuesFormTab2.checkboxShowHideMenuValue
        = window.newValuesFormTab1.checkboxShowHideMenuValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMenu').val();
    retObj.checkboxShowHideTableHeaderValue
        = window.newValuesFormTab2.checkboxShowHideTableHeaderValue
        = window.newValuesFormTab1.checkboxShowHideTableHeaderValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTableHeader').val();
    retObj.checkboxOrientationHorizontalVertical
        = window.newValuesFormTab2.checkboxOrientationHorizontalVertical
        = window.newValuesFormTab1.checkboxOrientationHorizontalVertical
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical').val();
    retObj.checkboxShowHideMetadataColumnValue
        = window.newValuesFormTab2.checkboxShowHideMetadataColumnValue
        = window.newValuesFormTab1.checkboxShowHideMetadataColumnValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMetadataColumn').val();
    retObj.checkboxShowHidePaginationValue
        = window.newValuesFormTab2.checkboxShowHidePaginationValue
        = window.newValuesFormTab1.checkboxShowHidePaginationValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHidePagination').val();
    retObj.checkboxConnectNullsValue
        = window.newValuesFormTab2.checkboxConnectNullsValue
        = window.newValuesFormTab1.checkboxConnectNullsValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxConnectNulls').val();
    retObj.dropdownConnectorLinesSourceValue
        = window.newValuesFormTab2.dropdownConnectorLinesSourceValue
        = window.newValuesFormTab1.dropdownConnectorLinesSourceValue
        = $('#dropdownConnectorLinesSource').closest("tr").find('input').val();
    retObj.checkboxSourceDataPointsValue
        = window.newValuesFormTab2.checkboxSourceDataPointsValue
        = window.newValuesFormTab1.checkboxSourceDataPointsValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxSourceDataPoints').val();
    retObj.checkboxAxisTurnOnOffValue
        = window.newValuesFormTab2.checkboxAxisTurnOnOffValue
        = window.newValuesFormTab1.checkboxAxisTurnOnOffValue
        = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAxisTurnOnOff').val();
    retObj.nubmberBoxValueMaxWordsTotalTextual
        = window.newValuesFormTab2.nubmberBoxValueMaxWordsTotalTextual
        = window.newValuesFormTab1.nubmberBoxValueMaxWordsTotalTextual
        = $("#nubmberBoxValueMaxWordsTotalTextual input").val();
    retObj.nubmberBoxValueFontSize
        = window.newValuesFormTab2.nubmberBoxValueFontSize
        = window.newValuesFormTab1.nubmberBoxValueFontSize
        = $("#nubmberBoxValueFontSize input").val();
    /*Set data for the tab3*/
    retObj.numberBoxDegree
        = window.newValuesFormTab1.numberBoxDegree
        = window.newValuesFormTab3.numberBoxDegree
        = $("#numberBoxDegree input").val();
    retObj.numberBoxFilterThreshold
        = window.newValuesFormTab1.numberBoxFilterThreshold
        = window.newValuesFormTab3.numberBoxFilterThreshold
        = $("#numberBoxFilterThreshold input").val();
    retObj.numberBoxClustersNumber
        = window.newValuesFormTab1.numberBoxClustersNumber
        = window.newValuesFormTab3.numberBoxClustersNumber
        = $("#numberBoxClustersNumber input").val();
    retObj.numberBoxEpsilon
        = window.newValuesFormTab1.numberBoxEpsilon
        = window.newValuesFormTab3.numberBoxEpsilon
        = $("#numberBoxEpsilon input").val();
    retObj.numberBoxMinItemsPerCluster
        = window.newValuesFormTab1.numberBoxMinItemsPerCluster
        = window.newValuesFormTab3.numberBoxMinItemsPerCluster
        = $("#numberBoxMinItemsPerCluster input").val();
    retObj.dropdownModel
        = window.newValuesFormTab1.dropdownModel
        = window.newValuesFormTab3.dropdownModel
        = window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownModel').val();
    retObj.dropdownFilterDirection
        = window.newValuesFormTab1.dropdownFilterDirection
        = window.newValuesFormTab3.dropdownFilterDirection
        = window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownFilterDirection').val();
    retObj.dropdownFactorsData
        = window.newValuesFormTab1.dropdownFactorsData
        = window.newValuesFormTab3.dropdownFactorsData
        = window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownFactorsData').val();
    retObj.dropdownCentroidInitialization
        = window.newValuesFormTab1.dropdownCentroidInitialization
        = window.newValuesFormTab3.dropdownCentroidInitialization
        = window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownCentroidInitialization').val();
    retObj.dropdownClusterizationModel
        = window.newValuesFormTab1.dropdownClusterizationModel
        = window.newValuesFormTab3.dropdownClusterizationModel
        = window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownClusterizationModel').val();
    retObj.dropdownInterpolationPrediction
        = window.newValuesFormTab1.dropdownInterpolationPrediction
        = window.newValuesFormTab3.dropdownInterpolationPrediction
        = window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownInterpolationPrediction').val();
    retObj.dropdownFilterModel
        = window.newValuesFormTab1.dropdownFilterModel
        = window.newValuesFormTab3.dropdownFilterModel
        = $('.data-filter-model-label').closest("tr").find('input').val();
    /*Set data for the tab4*/
    var checkboxList = $('.wordsCheckboxItem');
    var checkedWords = {};
    $.each(checkboxList, function (index, item) {
        if ($(item).is(':checked')) {
            checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name') };
        }
    });
    retObj.wordsList
        = window.newValuesFormTab4.wordsList
        = window.newValuesFormTab1.wordsList
        = checkedWords;
    var colorInputs = $('td.wordsColorColumn .node-color-old-value');
    var changedColors = {};
    $.each(colorInputs, function (index, item) {
        if ($(item).val() != $(item).next().val()) {
            changedColors[$(item).next().attr('name')] = {
                'color': $(item).next().val()
            };
        }
    });
    retObj.changedColors
        = window.newValuesFormTab4.changedColors
        = window.newValuesFormTab1.changedColors
        = changedColors;
    retObj.dropdownUniqueWords
        = window.newValuesFormTab4.dropdownUniqueWords
        = window.newValuesFormTab1.dropdownUniqueWords
        = $('.unique-words-label').closest("tr").find('input').val();
    retObj.dropdownSortOrderWords
        = window.newValuesFormTab4.dropdownSortOrderWords
        = window.newValuesFormTab1.dropdownSortOrderWords
        = $('.sort-order-words-label').closest("tr").find('input').val();
    prepareFixedNodesData();
    window.formInitData = retObj;
}


/**
* It clears the values of global variables that are used for plotting
* @return void
*/
function cleanup(chartType, chartIndex) {
    window.colorsArrayUsed = [];
    window.colorsArrayUsedMinIndex = [];

    if (isDebugg) { console.log('13', chartType, window.radialData[chartIndex].wordsListReset); }
    curDataChart = undefined;
    curDataChartTS = undefined;

    if (typeof chartType === "undefined") {
        chartType = 'clearAll';
    }
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    if (window.molecularData[chartIndex].force) {
        window.molecularData[chartIndex].force.stop();
    }
    var wordsListPrev = window.molecularData[chartIndex].wordsListPrev;
    var wordsListResetM = window.molecularData[chartIndex].wordsListReset;
    if (window.molecularData[chartIndex].wordsListReset == true) {
        var wordsListM = {};
        var wordsListFirstM = {};
        var wordsListSelectedM = {};
        var svgHeight = 0;
        var svgWidth = 0;
    } else {
        var wordsListM = window.molecularData[chartIndex].wordsList;
        var wordsListFirstM = window.molecularData[chartIndex].wordsListFirst;
        var wordsListSelectedM = window.molecularData[chartIndex].wordsListSelected;
        var svgHeight = window.molecularData[chartIndex].svgHeight;
        var svgWidth = window.molecularData[chartIndex].svgWidth;
    }

    var wordsListResetR = window.radialData[chartIndex].wordsListReset;
    if (window.radialData[chartIndex].wordsListReset == true) {
        var wordsListR = {};
        var wordsListFirstR = {};
        var wordsListSelectedR = {};
    } else {
        var wordsListR = window.radialData[chartIndex].wordsList;
        var wordsListFirstR = window.radialData[chartIndex].wordsListFirst;
        var wordsListSelectedR = window.radialData[chartIndex].wordsListSelected;
    }

    var wordsListResetDH = window.dendrogramHorizontalData[chartIndex].wordsListReset;
    if (window.dendrogramHorizontalData[chartIndex].wordsListReset == true) {
        var wordsListDH = {};
        var wordsListFirstDH = {};
        var wordsListSelectedDH = {};
    } else {
        var wordsListDH = window.dendrogramHorizontalData[chartIndex].wordsList;
        var wordsListFirstDH = window.dendrogramHorizontalData[chartIndex].wordsListFirst;
        var wordsListSelectedDH = window.dendrogramHorizontalData[chartIndex].wordsListSelected;
    }

    var wordsListResetTS = window.timeSeriesData[chartIndex].wordsListReset;
    if (window.timeSeriesData[chartIndex].wordsListReset == true) {
        var wordsListTS = {};
        var wordsListFirstTS = {};
        var wordsListSelectedTS = {};
    } else {
        var wordsListTS = window.timeSeriesData[chartIndex].wordsList;
        var wordsListFirstTS = window.timeSeriesData[chartIndex].wordsListFirst;
        var wordsListSelectedTS = window.timeSeriesData[chartIndex].wordsListSelected;
    }
    var wordsListResetTL = window.timelineData[chartIndex].wordsListReset;
    if (window.timelineData[chartIndex].wordsListReset == true) {
        var wordsListTL = {};
        var wordsListFirstTL = {};
        var wordsListSelectedTL = {};
    } else {
        var wordsListTL = window.timelineData[chartIndex].wordsList;
        var wordsListFirstTL = window.timelineData[chartIndex].wordsListFirst;
        var wordsListSelectedTL = window.timelineData[chartIndex].wordsListSelected;
    }
    //streamgraph
    var wordsListResetSG = window.streamgraphData[chartIndex].wordsListReset;
    if (window.streamgraphData[chartIndex].wordsListReset == true) {
        var wordsListSG = {};
        var wordsListFirstSG = {};
        var wordsListSelectedSG = {};
    } else {
        var wordsListSG = window.streamgraphData[chartIndex].wordsList;
        var wordsListFirstSG = window.streamgraphData[chartIndex].wordsListFirst;
        var wordsListSelectedSG = window.streamgraphData[chartIndex].wordsListSelected;
    }
    //sankey
    var wordsListResetSK = window.sankeyData[chartIndex].wordsListReset;
    if (window.sankeyData[chartIndex].wordsListReset == true) {
        var wordsListSK = {};
        var wordsListFirstSK = {};
        var wordsListSelectedSK = {};
    } else {
        var wordsListSK = window.sankeyData[chartIndex].wordsList;
        var wordsListFirstSK = window.sankeyData[chartIndex].wordsListFirst;
        var wordsListSelectedSK = window.sankeyData[chartIndex].wordsListSelected;
    }
    var wordsListResetTW = window.textualWordsData[chartIndex].wordsListReset;
    if (window.textualWordsData[chartIndex].wordsListReset == true) {
        var wordsListTW = {};
        var wordsListFirstTW = {};
        var wordsListSelectedTW = {};
    } else {
        var wordsListTW = window.textualWordsData[chartIndex].wordsList;
        var wordsListFirstTW = window.textualWordsData[chartIndex].wordsListFirst;
        var wordsListSelectedTW = window.textualWordsData[chartIndex].wordsListSelected;
    }

    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_MOLECULAR)) {
        window.molecularData[chartIndex] = {
            graph: { 'links': {}, 'nodes': {} },
            graphData: { 'links': {}, 'nodes': {} },
            width: undefined,
            height: undefined,
            color: undefined,
            colorB: undefined,
            colorC: undefined,
            radius: undefined,
            svg: undefined,
            force: undefined,
            link: undefined,
            node: undefined,
            div: undefined,
            drag: undefined,
            isConnectorsLines: undefined,
            isIndentValuesRelated: undefined,
            wordsListFirst: wordsListFirstM,
            wordsList: wordsListM,
            wordsListPrev: wordsListPrev,
            wordsListSelected: wordsListSelectedM,
            wordsListReset: wordsListResetM,
            dropdownAutoAdjust: 1,
            dropdownChartView: 1,
            dropdownMaxNodes: 100,
            dropdownMaxNodes: 1,
            dropdownAutoAdjustCnt: 250,
            dropdownColorsSelection: 1,
            dropdownConnectorLineWidthIndicator: 1,
            dropdownConnectorLinesColor: 2,
            dropdownColorsSet: 0,
            dropdownChartBackgroundColor: 0,
            dropdownChartTextColor: 0,
            changedCTColor: '',
            dropdownBallsText: 1,
            dropdownBallsTextLayout: 1,
            autoAdjustFirstStart: true,
            AA: {},
            circleSizeMult: 1,
            fontSizeMult: 1,
            sourcePointsSizeMult: 1,
            maxSizeNodeIndex: undefined,
            fixedNodesArr: {},
            svgHeight: svgHeight,
            svgWidth: svgWidth,
            widthMult: 1,
            heightMult: 1
        };
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_RADIAL)) {
        window.radialData[chartIndex] = {
            radius: undefined,
            pradius: undefined,
            data: undefined,
            width: undefined,
            height: undefined,
            json: undefined,
            cluster: undefined,
            diagonal: undefined,
            svg: undefined,
            nodes: undefined,
            link: undefined,
            node: undefined,
            isConnectorsLines: undefined,
            cicleSizeDiv: undefined,
            radiusMult: undefined,
            wordsList: wordsListR,
            wordsListFirst: wordsListFirstR,
            wordsListSelected: wordsListSelectedR,
            stopFlag: false,
            wordsListReset: wordsListResetR,
            dropdownColorsSelection: 1,
            dropdownConnectorLineWidthIndicator: 1,
            dropdownConnectorLinesColor: 2,
            dropdownColorsSet: 0,
            dropdownChartBackgroundColor: 0,
            dropdownChartTextColor: 0,
            changedCTColor: '',
            dropdownBallsText: 1,
            dropdownBallsTextLayout: 1,
            circleSizeMult: 1,
            fontSizeMult: 1,
            changedColorsByLevel: {},
            lineWidthMult: 0,
            transparentLevel: 1,
            colorGray: '#606060',
            colorGrayTransparentLevel: 0.6
        };
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
        window.dendrogramHorizontalData[chartIndex] = window.dendrogramHorizontalDataInit;
        window.dendrogramHorizontalData[chartIndex].wordsList = wordsListDH;
        window.dendrogramHorizontalData[chartIndex].wordsListFirst = wordsListFirstDH;
        window.dendrogramHorizontalData[chartIndex].wordsListSelected = wordsListSelectedDH;
        window.dendrogramHorizontalData[chartIndex].stopFlag = false;
        window.dendrogramHorizontalData[chartIndex].wordsListReset = wordsListResetDH;
        window.dendrogramHorizontalData[chartIndex].dropdownColorsSelection = 1;
        window.dendrogramHorizontalData[chartIndex].dropdownConnectorLineWidthIndicator = 1;
        window.dendrogramHorizontalData[chartIndex].dropdownConnectorLinesColor = 2;
        window.dendrogramHorizontalData[chartIndex].dropdownColorsSet = 0;
        window.dendrogramHorizontalData[chartIndex].dropdownChartBackgroundColor = 0;
        window.dendrogramHorizontalData[chartIndex].dropdownChartTextColor = 0;
        window.dendrogramHorizontalData[chartIndex].dropdownBallsText = 1;
        window.dendrogramHorizontalData[chartIndex].dropdownBallsTextLayout = 1;
        window.dendrogramHorizontalData[chartIndex].circleSizeMult = 1;
        window.dendrogramHorizontalData[chartIndex].fontSizeMult = 1;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TIMELINE)) {
        window.timelineData[chartIndex] = {
            data: undefined,
            width: undefined,
            height: undefined,
            json: undefined,
            cluster: undefined,
            diagonal: undefined,
            svg: undefined,
            nodes: undefined,
            link: undefined,
            node: undefined,
            isConnectorsLines: undefined,
            wordsListFirst: {},
            wordsList: {},
            wordsListSelected: {},
            stopFlag: false,
            wordsListReset: true,
            wordsListColorChange: false,
            unicItems: {},
            unicDates: {},
            allDataKeys: {},
            timeFormatter: undefined,
            margin: undefined,
            x: undefined,
            y: undefined,
            chartAreaWidth: undefined,
            chartAreaHeight: undefined,
            zoom: undefined,
            colorsArr: undefined,
            colorsDrawArr: undefined,
            dashStyles: undefined,
            sourceTitle: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            addData: undefined,
            seriesData: [],
            seriesDates: [],
            plotBands: [],
            customWordsColors: {},
            changedColors: {}
        };
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_STREAMGRAPH)) {
        window.streamgraphData[chartIndex] = window.streamgraphDataInit;
        window.streamgraphData[chartIndex].wordsList = wordsListSG;
        window.streamgraphData[chartIndex].wordsListFirst = wordsListFirstSG;
        window.streamgraphData[chartIndex].wordsListSelected = wordsListSelectedSG;
        window.streamgraphData[chartIndex].stopFlag = false;
        window.streamgraphData[chartIndex].wordsListReset = wordsListResetSG;
        window.streamgraphData[chartIndex].dropdownConnectorLineWidthIndicator = 1;
        window.streamgraphData[chartIndex].dropdownColorsSet = 0;
        window.streamgraphData[chartIndex].dropdownChartBackgroundColor = 0;
        window.streamgraphData[chartIndex].dropdownChartTextColor = 0;
        window.streamgraphData[chartIndex].fontSizeMult = 1;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_SANKEY_NEIGHBORS1)) {
        window.sankeyData[chartIndex] = window.sankeyDataInit;
        window.sankeyData[chartIndex].wordsList = wordsListSK;
        window.sankeyData[chartIndex].wordsListFirst = wordsListFirstSK;
        window.sankeyData[chartIndex].wordsListSelected = wordsListSelectedSK;
        window.sankeyData[chartIndex].stopFlag = false;
        window.sankeyData[chartIndex].wordsListReset = wordsListResetSK;
        window.sankeyData[chartIndex].dropdownConnectorLineWidthIndicator = 1;
        window.sankeyData[chartIndex].dropdownColorsSet = 0;
        window.sankeyData[chartIndex].dropdownChartBackgroundColor = 0;
        window.sankeyData[chartIndex].dropdownChartTextColor = 0;
        window.sankeyData[chartIndex].fontSizeMult = 1;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TEXTUALWORDS)) {
        window.textualWordsData[chartIndex] = {
            data: undefined,
            width: undefined,
            height: undefined,
            json: undefined,
            cluster: undefined,
            link: undefined,
            wordsListFirst: wordsListFirstTW,
            wordsList: wordsListTW,
            wordsListSelected: wordsListSelectedTW,
            stopFlag: false,
            wordsListReset: true
        };
    }
    $("body #dataContaner svg").remove();
}

/**
 * It clears the values of global variables that are used for the greate words list
 * @return void
 */
function resetWordsList(chartType, chartIndex, resetColors) {
    if (typeof chartType === "undefined") {
        chartType = 'clearAll';
    }
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    if (typeof resetColors === "undefined") {
        resetColors = 1;
    }
    if (resetColors) {
        window.newValuesFormTab1.changedColors = window.newValuesFormTab4.changedColors = {};

        window.molecularData[chartIndex].colorsListReset = true;

        window.timeSeriesData[chartIndex].colorsListReset = true;
        window.timeSeriesData[chartIndex].wordsListPrev = {};
    } else if (chartType == CHART_TYPE_MOLECULAR) {//molecular
        window.molecularData[chartIndex].colorsListReset = false;
        if (typeof window.newValuesFormTab1 !== "undefined") {
            if (typeof window.newValuesFormTab1.wordsList !== "undefined") {
                window.molecularData[chartIndex].wordsListPrev = window.newValuesFormTab1.wordsList;
            }
        }
    } else if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
        window.timeSeriesData[chartIndex].wordsListPrev = {};
        window.timeSeriesData[chartIndex].colorsListReset = false;
        if (typeof window.newValuesFormTab1 !== "undefined") {
            if (typeof window.newValuesFormTab1.wordsList !== "undefined") {
                window.timeSeriesData[chartIndex].wordsListPrev = window.newValuesFormTab1.wordsList;
            }
        }
    } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
        window.dendrogramHorizontalData[chartIndex].wordsListPrev = {};
        window.dendrogramHorizontalData[chartIndex].colorsListReset = false;
        if (typeof window.newValuesFormTab1 !== "undefined") {
            if (typeof window.newValuesFormTab1.wordsList !== "undefined") {
                window.dendrogramHorizontalData[chartIndex].wordsListPrev = window.newValuesFormTab1.wordsList;
            }
        }
    } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
        window.timelineData[chartIndex].wordsListPrev = {};
        window.timelineData[chartIndex].colorsListReset = false;
        if (typeof window.newValuesFormTab1 !== "undefined") {
            if (typeof window.newValuesFormTab1.wordsList !== "undefined") {
                window.timelineData[chartIndex].wordsListPrev = window.newValuesFormTab1.wordsList;
            }
        }
    } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
        window.streamgraphData[chartIndex].wordsListPrev = {};
        window.streamgraphData[chartIndex].colorsListReset = false;
        if (typeof window.newValuesFormTab1 !== "undefined") {
            if (typeof window.newValuesFormTab1.wordsList !== "undefined") {
                window.streamgraphData[chartIndex].wordsListPrev = window.newValuesFormTab1.wordsList;
            }
        }
    } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
        window.sankeyData[chartIndex].wordsListPrev = {};
        window.sankeyData[chartIndex].colorsListReset = false;
        if (typeof window.newValuesFormTab1 !== "undefined") {
            if (typeof window.newValuesFormTab1.wordsList !== "undefined") {
                window.sankeyData[chartIndex].wordsListPrev = window.newValuesFormTab1.wordsList;
            }
        }
    }

    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_MOLECULAR)) {//molecular
        window.molecularData[chartIndex].wordsListReset = true;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_RADIAL)) {//radial
        window.radialData[chartIndex].wordsListReset = true;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {//
        window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TIMESERIES)) {//timeseries
        window.timeSeriesData[chartIndex].wordsListReset = true;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TIMELINE)) {//timeline
        window.timelineData[chartIndex].wordsListReset = true;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_STREAMGRAPH)) {//streamgraph
        window.streamgraphData[chartIndex].wordsListReset = true;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_SANKEY_NEIGHBORS1)) {//sankey
        window.sankeyData[chartIndex].wordsListReset = true;
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TEXTUALWORDS)) {//textual
        window.textualWordsData[chartIndex].wordsListReset = true;
        window.textualWordsData[chartIndex].wordsList = {};
    }

    if (typeof window.newValuesFormTab1 !== "undefined") {
        //window.newValuesFormTab1.wordsList = {};
    }
    $('#wordsListTableContaner').find('.wordsList').remove();
}


function colorValues(color, transparent) {
    var retColor;
    if (!color)
        return;
    if (color.toLowerCase() === 'transparent')
        retColor = [0, 0, 0, 0];
    if (color[0] === '#') {
        if (color.length < 7) {
            // convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
            color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3] + (color.length > 4 ? color[4] + color[4] : '');
        }
        retColor = [parseInt(color.substr(1, 2), 16),
        parseInt(color.substr(3, 2), 16),
        parseInt(color.substr(5, 2), 16),
        color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1];
    }
    if (color.indexOf('rgb') === -1) {
        // convert named colors
        var temp_elem = document.body.appendChild(document.createElement('fictum')); // intentionally use unknown tag to lower chances of css rule override with !important
        var flag = 'rgb(1, 2, 3)'; // this flag tested on chrome 59, ff 53, ie9, ie10, ie11, edge 14
        temp_elem.style.color = flag;
        if (temp_elem.style.color !== flag)
            return; // color set failed - some monstrous css rule is probably taking over the color of our object
        temp_elem.style.color = color;
        if (temp_elem.style.color === flag || temp_elem.style.color === '')
            return; // color parse failed
        color = getComputedStyle(temp_elem).color;
        document.body.removeChild(temp_elem);
    }
    if (color.indexOf('rgb') === 0) {
        if (color.indexOf('rgba') === -1)
            color += ',1'; // convert 'rgb(R,G,B)' to 'rgb(R,G,B)A' which looks awful but will pass the regxep below
        retColor = color.match(/[\.\d]+/g).map(function (a) {
            retColor = +a
        });
    }
    retColor[3] = transparent;
    return retColor;
}

function scrollTo(id) {
    $("#" + id).scrollTop(($("#" + id + " > :first-child").next().height() - $("#" + id).height()) / 2);
    $("#" + id).scrollLeft(($("#" + id + " > :first-child").next().width() - $("#" + id).width()) / 2);
}

/**
 * Builds a list of words indicating the size of the node 
 * and with checkboxes for the tab Words select.
 * @param wordsList
 * @param wordsListSelected
 * @param chartType
 * @returns
 */
function addWordsCheckboxesAndInfo(wordsList, wordsListSelected, chartType) {
    if ((chartType == CHART_TYPE_RADIAL)
        || (chartType == CHART_TYPE_MOLECULAR)
        || (chartType == CHART_TYPE_TIMESERIES)
        || (chartType == CHART_TYPE_TIMELINE)
        || (chartType == CHART_TYPE_STREAMGRAPH)
        || (chartType == CHART_TYPE_SANKEY_NEIGHBORS1)
        || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
        if ($($('#wordsHeadValue')).length == 0) {
            //window.timeSeriesData[chartIndex].wordsListReset = true;
            showHideTypeDependentItems();
        }
        //$('#wordsHeadValue').jqxTooltip({ position: 'top', autoHideDelay: 10000, content: 'An indicator value.' });
    }

    $('#theadTrMolecular').remove();
    $('#tbodyTrMolecular').remove();
    $('#theadTrRadial').remove();
    $('#tbodyTrRadial').remove();
    $('#theadTrTimeSeries').remove();
    $('#tbodyTrTimeSeries').remove();
    $('#theadTrTimeline').remove();
    $('#tbodyTrTimeline').remove();
    $('#theadTrStreamgraph').remove();
    $('#tbodyTrStreamgraph').remove();
    $('#theadTrSankey').remove();
    $('#tbodyTrSankey').remove();
    $('#theadTrTextualWords').remove();
    $('#tbodyTrTextualWords').remove();
    $('#theadTrDendrogramHorizontal').remove();
    $('#tbodyTrDendrogramHorizontal').remove();


    $('.wordsListAll').remove();
    if (chartType == CHART_TYPE_MOLECULAR) {//molecular
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.molecularHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.molecularHead.row2);
    } else if (chartType == CHART_TYPE_RADIAL) {//radial
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.radialHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.radialHead.row2);
    } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//Dendrogram horizontal
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.dendrogramHorizontalHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.dendrogramHorizontalHead.row2);
    } else if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.timeSeriesHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.timeSeriesHead.row2);
    } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.timelineHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.timelineHead.row2);
    } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.streamgraphHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.streamgraphHead.row2);
    } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.sankeyHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.sankeyHead.row2);
    } else if (chartType == CHART_TYPE_TEXTUALWORDS) {//textual
        $('#wordsListTableContaner thead').prepend(window.wordsListHead.textualWordsHead.row1);
        $('#wordsListTableContaner tbody').prepend(window.wordsListHead.textualWordsHead.row2);
    }
    $('#wordsCheckboxAll').on('click', function (e) {
        if (isDebugg) { console.log('431'); }
        var elem = $(this);
        var checkboxList = $('.wordsCheckboxItem');
        if (elem.is(':checked')) {
            $.each(checkboxList, function (index, item) {
                $(item).prop("checked", true);
            });
        } else {
            $.each(checkboxList, function (index, item) {
                $(item).prop("checked", false);
            });
        }
    });

    if (isDebugg) { console.log('601 addWordsCheckboxesAndInfo, chartType=' + chartType, wordsList, wordsListSelected); }
    var wordsListHTML = '';
    var numIndex = 1;
    var wordsListCSV = '';
    var wordsListLength = Object.keys(wordsList).length;
    var wordsListInfo = '<table><thead><tr><th width="10%" class="wordsNumColumn">#</th>'
        + '<th width="70%" class="wordsNameColumn">Word'
        + '</th><th width="10%" class="wordsSizeColumn">Value'
        + '</th><th width="5%"></th/></tr></thead><tbody>';

    $.each(wordsListSelected, function (index, item) {
        if (typeof wordsList[index] === "undefined") {
            wordsList[index] = item;
        }
    });

    wordsList = sortObjByKey(wordsList);

    $.each(wordsList, function (index, item) {
        if (item.name != '') {
            wordsListCSV = wordsListCSV + item.name + (numIndex < wordsListLength ? ',' : '');
            var isChecked = isWordChecked(item.name, wordsListSelected);
            if (chartType == CHART_TYPE_MOLECULAR) {//molecular
                var colorColumn = '<td width="5%" class="wordsColorColumn">'
                    + '<div class="node-color" style="background-color:' + item.colornew + '">'
                    + '</div><input class="node-color-old-value" type="hidden" value="' + item.color + '" name="'
                    + item.name + '"><input type="hidden" value="' + item.colornew + '"'
                    + ' class="node-color-new-value" name="' + item.md5 + '">'
                    + '<input type="hidden" value="' + item.fore + '"'
                    + ' class="node-fore-color"></td>';
                var widthAdd = 0;
            } else if ((chartType == CHART_TYPE_RADIAL)
                || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)
                || (chartType == CHART_TYPE_TEXTUALWORDS)) {//radial or textual
                var colorColumn = '';
                var widthAdd = 5;
            } else if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                //var colorColumn = '';
                var colorColumn = '<td width="5%" class="wordsColorColumn">'
                    + '<div class="node-color" style="background-color:' + item.colornew + '">'
                    + '</div><input class="node-color-old-value" type="hidden" value="' + item.color + '" name="'
                    + item.name + '"><input type="hidden" value="' + item.colornew + '"'
                    + ' class="node-color-new-value" name="' + item.md5 + '">'
                    + '<input type="hidden" value="' + item.fore + '"'
                    + ' class="node-fore-color"></td>'
                var widthAdd = 0;
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                var colorColumn = '<td width="5%" class="wordsColorColumn">'
                    + '<div class="node-color" style="background-color:' + item.colornew + '">'
                    + '</div><input class="node-color-old-value" type="hidden" value="' + item.color + '" name="'
                    + item.name + '"><input type="hidden" value="' + item.colornew + '"'
                    + ' class="node-color-new-value" name="' + item.md5 + '">'
                    + '<input type="hidden" value="' + item.fore + '"'
                    + ' class="node-fore-color"></td>'
                var widthAdd = 0;
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                var colorColumn = '<td width="5%" class="wordsColorColumn">'
                    + '<div class="node-color" style="background-color:' + item.colornew + '">'
                    + '</div><input class="node-color-old-value" type="hidden" value="' + item.color + '" name="'
                    + item.name + '"><input type="hidden" value="' + item.colornew + '"'
                    + ' class="node-color-new-value" name="' + item.md5 + '">'
                    + '<input type="hidden" value="' + item.fore + '"'
                    + ' class="node-fore-color"></td>'
                var widthAdd = 0;
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                var colorColumn = '<td width="5%" class="wordsColorColumn">'
                    + '<div class="node-color" style="background-color:' + item.colornew + '">'
                    + '</div><input class="node-color-old-value" type="hidden" value="' + item.color + '" name="'
                    + item.name + '"><input type="hidden" value="' + item.colornew + '"'
                    + ' class="node-color-new-value" name="' + item.md5 + '">'
                    + '<input type="hidden" value="' + item.fore + '"'
                    + ' class="node-fore-color"></td>'
                var widthAdd = 0;
            }

            if (dbDataType == 1) {
                var addTwitterIcon = '<a href="'
                    + twitterProfileLinkBaseURL.replace("%PROFILE_NAME%", item.name)
                    + '" target="_blank"><img src="/images/icon_Twitter.png"></a>';
            } else {
                var addTwitterIcon = '';
            }

            wordsListHTML += '<tr class="wordsList">'
                + '<td width="10%" class="wordsNumColumn">' + numIndex + '.</td>'
                + colorColumn
                + '<td width="' + (65 + widthAdd) + '%" class="wordsNameColumn">'
                + '<span class="popWordsSpan">' + item.name + '</span>' + addTwitterIcon + '</td>';
            if (chartType == CHART_TYPE_TIMESERIES
                || chartType == CHART_TYPE_MOLECULAR
                || chartType == CHART_TYPE_RADIAL
                || chartType == CHART_TYPE_DENDROGRAMHORIZONTAL
                || chartType == CHART_TYPE_TIMELINE
                || chartType == CHART_TYPE_STREAMGRAPH
                || chartType == CHART_TYPE_SANKEY_NEIGHBORS1
                || chartType == CHART_TYPE_TEXTUALWORDS) {
                wordsListHTML += '<td width="10%" class="wordsSizeColumn">'
                    + /*item.size +*/ '</td><td width="10%"><input type="checkbox" name="'
                    + item.name + '" class="wordsCheckboxItem"'
                    + isChecked + '></td>'
                    + '<td><input type="image" class="open-word-in-new-tab" alt="Open single word chart with the same options in new tab"'
                    + ' src="/images/open_in_new1.png"></td><td width="5%" class="wordsMetadata">'
                    + " <a href=\"#\" class=\"wordsMetadataIcon\" onclick=\"return getDataAndShowMeasurePointDialog('current', '"
                    + item.name + "');\">...</a> "
                    + '</td></tr>';
            /*} else if (chartType == CHART_TYPE_TEXTUALWORDS) {
                wordsListHTML += '<td width="10%"><input type="checkbox" name="'
                    + item.name + '" class="wordsCheckboxItem"'
                    + isChecked + '></td><td width="5%" class="wordsMetadata">'
                    + " <a href=\"#\" class=\"wordsMetadataIcon\" onclick=\"return getDataAndShowMeasurePointDialog('current', '"
                    + item.name + "');\">...</a> "
                    + '</td></tr>';*/
            } else {
                wordsListHTML += '<td width="10%" class="wordsSizeColumn">'
                    + item.size + '</td><td width="10%"><input type="checkbox" name="'
                    + item.name + '" class="wordsCheckboxItem"'
                    + isChecked + '></td><td width="5%" class="wordsMetadata">'
                    + " <a href=\"#\" class=\"wordsMetadataIcon\" onclick=\"return getDataAndShowMeasurePointDialog('current', '"
                    + item.name + "');\">...</a> "
                    + '</td></tr>';
            }
            wordsListInfo += '<tr><td width="10%" class="wordsNumColumn">'
                + numIndex + '.</td><td width="80%" class="wordsNameColumn">'
                + item.name
                + '</td><td width="10%" class="wordsSizeColumn">'
                + item.size + '</td></tr>';

            numIndex++;
        }
    });
    wordsListInfo += '</tbody></table><input id="wordsListCSV" type="text" value="' + wordsListCSV + '" class="unvisible">';

    if (isDebugg) { console.log('162', wordsListHTML); }
    $('#wordsListTableContaner').find('.wordsList').remove();

    addTableHeadWords(wordsListInfo, chartType);
    addCheckboxEventsWords(wordsListHTML, chartType);
    if (typeof chartIndex === "undefined") {
        var chartIndex = 0;
    }
    if (typeof window.timeSeriesData === "undefined") {
        window.timeSeriesData = {};
        window.timeSeriesData[chartIndex] = window.timeSeriesDataInit;
    }

    window.timeSeriesData[chartIndex].wordsListReset = false;
    //Tooltips for the tab Words select
    $('#wordsHeadNum').attr('title', 'An order number.');
    $('#wordsHeadName').attr('title', 'A pop-word text.');
    if ((chartType == CHART_TYPE_RADIAL)
        || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)
        || (chartType == CHART_TYPE_MOLECULAR)
        || (chartType == CHART_TYPE_TIMESERIES)
        || (chartType == CHART_TYPE_TIMELINE)
        || (chartType == CHART_TYPE_STREAMGRAPH)
        || (chartType == CHART_TYPE_SANKEY_NEIGHBORS1)) {
        $('#wordsHeadValue').attr('title', 'An indicator value.');
    }
    $('#wordsHeadDisable').attr(
        'title',
        "Disable word for data selection \n and farther processing. \n Requires a `Draw` button \n press to apply."
    );

    if ($("td.wordsMetadata").length > 0) {
        $('td.wordsMetadata').attr('title', "Open meta-data dialog.");
    }
    if ($(".open-word-in-new-tab").length > 0) {
        $('.open-word-in-new-tab').attr('title', "Open single word chart with \n the same options in new tab");
    }
}


/**
 * Add colorpickers colors selection for a levels into contaner
 * @param wordsList
 * @param wordsListSelected
 * @param chartType
 * @returns
 */
//colorsSelectionForLevelsContaner
function addColorpickersSelection(chartType, maxLevel) {
    showHideTypeDependentItems();

    var wordsListStr = '';
    if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
        var dropdownValueMaxDepth = $('.max-depth-label').closest("tr").find('input').val() * 1;
        var dropdownValueIWords = $('.intermediate-words-label').closest("tr").find('input').val() * 1;

        if (typeof maxLevel == "undefined") {
            maxLevel = (dropdownValueMaxDepth*1 + dropdownValueIWords*1);
        }

        wordsListStr = '<table><tbody><tr><td width="130" class="wordsNumColumn">Colors selection</td>';
        for (var i = 1; i <= maxLevel; i++) {
            var colornew = '';
            var color = '';
            var fore = '';
            wordsListStr = wordsListStr + '<td width="5%" class="colorColumnLevel">'
                + '<div class="level-color" id="level-color-' + i + '" style="background-color:' + colornew + '">'
                + '</div><input class="level-color-old-value" type="hidden" value="' + color + '" name="'
                + 'level-color-old-value' + i + '"><input type="hidden" value="' + colornew + '"'
                + ' class="node-color-new-value" name="' + i + '">'
                + '<input type="hidden" value="' + fore + '"'
                + ' class="node-fore-color"></td>';
        }
        wordsListStr = wordsListStr + '</tr><tr><td width="120" class="wordsNumColumn">level:</td>';
        for (var i = 1; i <= (dropdownValueMaxDepth + dropdownValueIWords); i++) {
            wordsListStr = wordsListStr + '<td width="5%" class="levelColumn"> ' + i + '</td>';
        }
        wordsListStr = wordsListStr + '</tr></tbody></table>';
        $('#colorsSelectionForLevelsContaner').html(wordsListStr);
        $('.level-color').on('click', function () {
            var elem = $(this);
            $('#windowColorPickerRad').jqxWindow('open');
            $("#colorPickerRad").jqxColorPicker('setColor', elem.next().val());
            $('#curColorColorPickerRad').val(elem.next().val());
            $('#newColorColorPickerRad').val('');
            $('#curColorPickerElIdRad').val(elem.next().attr('name'));
            if (typeof chartIndex === "undefined") {
                var chartIndex = 0;
            }
        });
    }
}


/**
 * Add colorpicker colors selection for the chart text color
 * @param chartType
 * @returns
 */
function addColorpickerTextColor(chartType) {
    showHideTypeDependentItems();
    if ($('.colorColumnChartText').length == 0) {
        var wordsListStr = '';
        if ((chartType == CHART_TYPE_MOLECULAR)) {
            var textColorType = $('.form-dropdown-chart-text-color-label').closest("tr").find('input').val();

            wordsListStr = '<table><tbody><tr><td width="130" class="wordsNumColumn">Colors selection</td>';
            var colornew = '';
            var color = '';
            var fore = '';
            wordsListStr = wordsListStr + '<td width="5%" class="colorColumnChartText">'
                + '<div class="chart-text-color" id="chart-text-color" style="background-color:' + colornew + '">'
                + '</div><input class="chart-text-color-old-value" type="hidden" value="' + color + '" name="'
                + 'chart-text-color-old-value"><input type="hidden" value="' + colornew + '"'
                + ' class="chart-text-new-value" name="chart-text-new-value">'
                + '<input type="hidden" value="' + fore + '"'
                + ' class="node-fore-color"></td>';
            //        wordsListStr = wordsListStr + '</tr><tr><td width="120" class="wordsNumColumn">level:</td>';
            //        wordsListStr = wordsListStr + '<td width="5%" class="levelColumn"> ' + i + '</td>';
            wordsListStr = wordsListStr + '</tr></tbody></table>';
            $('#colorsSelectionForChartTextColorContaner').html(wordsListStr);
            $('.chart-text-color').on('click', function () {
                var elem = $(this);
                $('#windowColorPickerCT').jqxWindow('open');
                $("#colorPickerCT").jqxColorPicker('setColor', elem.next().val());
                $('#curColorColorPickerCT').val(elem.next().val());
                $('#newColorColorPickerCT').val('');
                $('#curColorPickerElIdCT').val(elem.next().attr('name'));
                if (typeof chartIndex === "undefined") {
                    var chartIndex = 0;
                }
            });
        }
    }
}

/**
 * Adds words info into Info panel.
 * @param wordsListInfo
 * @returns
 */
function addTableHeadWords(wordsListInfo, chartType) {
    if (chartType == CHART_TYPE_MOLECULAR) {//molecular
        var wordsListLength = Object.keys(window.molecularData[chartIndex].wordsList).length;
    } else if (chartType == CHART_TYPE_RADIAL) {//radial
        var wordsListLength = Object.keys(window.radialData[chartIndex].wordsList).length;
    } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//dendrogram horizontal
        var wordsListLength = Object.keys(window.dendrogramHorizontalData[chartIndex].wordsList).length;
    } else if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
        if (isWidget == 0) {
            $('.info-panel-words:first').prev().remove();
        }
        var wordsListLength = Object.keys(window.timeSeriesData[chartIndex].wordsList).length;
    } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
        if (isWidget == 0) {
            $('.info-panel-words:first').prev().remove();
        }
        var wordsListLength = Object.keys(window.timelineData[chartIndex].wordsList).length;
    } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
        if (isWidget == 0) {
            $('.info-panel-words:first').prev().remove();
        }
        var wordsListLength = Object.keys(window.streamgraphData[chartIndex].wordsList).length;
    } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
        if (isWidget == 0) {
            $('.info-panel-words:first').prev().remove();
        }
        var wordsListLength = Object.keys(window.sankeyData[chartIndex].wordsList).length;
    }
    if (isWidget == 0) {
        $('.info-panel-words').remove();
    }

    if (isWidget == 0) {
        $('.infoPanelTable tbody').append(
            '<tr class="info-panel-words" width="100%"><td class="key">words: </td><td class="val">'
            + wordsListLength + '</td></tr>'
        );
        $('.infoPanelTable tbody').append(
            '<tr class="info-panel-words" width="100%"><td class="key">words  list: </td><td class="val">'
            + wordsListInfo + '</td></tr>'
        );
    }
}

/**
 * Assigns events to items of the tab Words select.
 * @param wordsListHTML
 * @returns
 */
function addCheckboxEventsWords(wordsListHTML, chartType) {
    var curChartTextColor;
    $('#wordsListTableContaner').find('.wordsList').remove();
    $('#wordsListTableContaner>tbody').append(wordsListHTML);

    $('.wordsCheckboxItem').on('click', function () {
        var checkboxList = $('.wordsCheckboxItem');
        var checkedWords = {};
        $.each(checkboxList, function (index, item) {
            if ($(item).is(':checked')) {
                checkedWords[$(item).attr('name')] = { 'name': $(item).attr('name') };
            }
        });

        window.sampleFormWords.val({
            isChange4: window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(),
            wordsList: checkedWords
        });
        window.newValuesFormTab1.wordsList = window.newValuesFormTab4.wordsList = checkedWords;
    });
    $('.node-color').on('click', function () {
        var elem = $(this);
        $('#windowColorPicker').jqxWindow('open');
        $("#colorPicker").jqxColorPicker('setColor', elem.next().val());
        $('#curColorColorPicker').val(elem.next().val());
        $('#newColorColorPicker').val('');
        $('#wordNameColorPicker').val(elem.next().attr('name'));
        if (typeof chartIndex === "undefined") {
            var chartIndex = 0;
        }
        if (typeof window.timeSeriesData === "undefined") {
            window.timeSeriesData = {};
            window.timeSeriesData[chartIndex] = window.timeSeriesDataInit;
        }

        if (chartType == CHART_TYPE_TIMESERIES) {
            window.timeSeriesData[chartIndex].wordsListColorChange = true;
        }
        if (chartType == CHART_TYPE_TIMELINE) {
            window.timelineData[chartIndex].wordsListColorChange = true;
        }
        if (chartType == CHART_TYPE_STREAMGRAPH) {
            window.streamgraphData[chartIndex].wordsListColorChange = true;
        }
        if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
            window.sankeyData[chartIndex].wordsListColorChange = true;
        }
    });
    if (isWidget == 0) {
        $("#colorPicker").jqxColorPicker({ color: "ffaabb", colorMode: 'hue', width: 190, height: 190 });
    }
    setTimeout(function () {
        $('#wordsListTableContaner').attr('style', "max-height:" + ($('#dataContaner').height() - 230) + 'px !important');
    }, 1000);

    $('.open-word-in-new-tab').on('click', function () {
        if (isDebugg) { console.log('0501'); }
        //Set form tab values to the window object.
        initPrepareFormData();
        prepareFixedNodesData();
        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        saveCurrenFormDataSelectValues();
        var chartType = $('#chartType').val();
        if (chartType == CHART_TYPE_RADIAL) {
            var wordsList = window.radialData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
            var wordsList = window.dendrogramHorizontalData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if (chartType == CHART_TYPE_MOLECULAR) {
            var wordsList = window.molecularData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
            curChartTextColor = window.molecularData[chartIndex].chartTextColor;
        }
        if (chartType == CHART_TYPE_TIMESERIES) {
            var wordsList = window.timeSeriesData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
            window.newValuesFormTab1.hiddenLegendItems = JSON.stringify(getHiddenLegendItems(chartType));
        }
        if ((chartType == CHART_TYPE_TEXTUALWORDS) || (chartType == CHART_TYPE_TEXTUALWORDSMETADATA)) {
            window.newValuesFormTab1.drawingValueIndicators = JSON.stringify(getDrawingValueIndicators());
        }
        if (chartType == CHART_TYPE_TIMELINE) {
            var wordsList = window.timelineData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
            window.newValuesFormTab1.hiddenLegendItems = JSON.stringify(getHiddenLegendItems(chartType));
        }
        if (chartType == CHART_TYPE_STREAMGRAPH) {
            var wordsList = window.streamgraphData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
            var wordsList = window.sankeyData[chartIndex].wordsListFirst;
            window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        }
        window.newValuesFormTab1;
        var wordName = $(this).parent().parent().find('.wordsCheckboxItem').attr('name');
        var wordColor = $(this).parent().parent().find('.node-color-new-value').attr('value');
        var wordColor = $(this).parent().parent().find('.chart-text-color-new-value').attr('value');

        formDataToNewTab(wordName, wordColor, curChartTextColor);
    });
}

/**
 * removeCheckedWordsFromLink
 * @param deleteIndex
 * @returns
 */
function removeCheckedWordsFromLink(deleteIndex) {
    if (deleteIndex >= 0) {
        window.radialData[chartIndex].linkF[0].splice(deleteIndex, 1);
        if (!window.radialData[chartIndex].stopFlag) {
            //removeCheckedWordsFromLink(-1);
        }
    } else {
        for (var i = 0; i < window.radialData[chartIndex].linkF[0].length; i++) {
            itemLink = window.radialData[chartIndex].linkF[0][i];
            var isSource = isWordChecked(itemLink.__data__.source.name, window.radialData[chartIndex].wordsListSelected);
            var isTarget = isWordChecked(itemLink.__data__.target.name, window.radialData[chartIndex].wordsListSelected);
            if (isSource != '') {
                p = itemLink.__data__;
                window.radialData[chartIndex].link.filter(function (d) {
                    for (d = d.source; d; d = d.parent) {
                        if (d === p) return true;
                    }
                }).style("stroke", "#FFF");
            }
            if (typeof itemLink.__data__.target.parent !== "undefined") {
                var isTargetP = isWordChecked(itemLink.__data__.target.parent.name, window.radialData[chartIndex].wordsListSelected);
            } else {
                var isTargetP = '';
            }
            if (typeof itemLink.__data__.source.parent !== "undefined") {
                var isSourceP = isWordChecked(itemLink.__data__.source.parent.name, window.radialData[chartIndex].wordsListSelected);
            } else {
                var isSourceP = '';
            }
            if ((isSource != '' || isTarget != '' /*|| isTargetP != '' || isSourceP != ''*/)) {
                removeCheckedWordsFromLink(i);
                break;
            }
        }
        window.radialData[chartIndex].stopFlag = true;
    }
}

/**
 * Checks for the presence of a value in an array of selected words.
 * @param wordName
 * @returns string
 */
function isWordChecked(wordName, wordsListSelected) {
    var isChecked = '';
    //if (isDebugg) {console.log('141', wordName);}
    $.each(wordsListSelected, function (index, itemChecked) {
        if (itemChecked.name == wordName) {
            isChecked = ' checked="checked"';
        }
    });
    //if (isDebugg) {console.log('142', isChecked);}
    return isChecked;
}

/**
* The function calls one of the functions for builds and displays charts.
* Additionally: hides / shows form fields, depending on the selected chart type.
* @param object data 
* @return void
*/
function showChart(data, formdata, md5Arr) {
    if (isDebugg) { console.log('15'); }
    showHideTypeDependentItems();
    addPlotToolsPanel();
    if (typeof formdata.chartType !== "undefined") {
        switch (formdata.chartType) {
            case CHART_TYPE_RADIAL:
                showRadialChart(data, formdata);
                setTimeout(function () {
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }
                }, 500);
                break;
            case CHART_TYPE_DENDROGRAMHORIZONTAL:
                showDendrogramHorizontalChart(data, formdata);
                setTimeout(function () {
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }
                }, 500);
                break;
            case CHART_TYPE_MOLECULAR:
                showMolecularChart(data, formdata);
                setTimeout(function () {
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }
                }, 500);
                break;
            case CHART_TYPE_TIMESERIES:
                drawTimeSeries(data, formdata);
                break;
            case CHART_TYPE_TIMELINE:
                showTimelineChart(data, formdata);
                break;
            case CHART_TYPE_STREAMGRAPH:
                showStreamgraphChart(data, formdata);
                break;
            case CHART_TYPE_SANKEY_NEIGHBORS1:
                showSankeyChart(data, formdata);
                break;
            case CHART_TYPE_TEXTUALWORDS:
                showTextualWords(data, formdata);
                setTimeout(function () {
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }
                }, 500);
                break;
            case CHART_TYPE_TEXTUALWORDSMETADATA:
                //showTextualWords(data, formdata);
                setTimeout(function () {
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }
                }, 500);
                break;
            default:
                showMolecularChart(data, formdata);
                setTimeout(function () {
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }
                }, 500);
        }
    }
    setTimeout(function () {
        firstLoadChart = 0;
    }, 1500);
}


function setWidgetUrlParams(data, retObj) {
    if (typeof retObj === "undefined") {
        var retObj = {};
    }

    if (typeof iframeOptionsConfig !== "undefined") {
        if (typeof iframeOptionsConfig.datesInterval !== "undefined") {
            if (typeof iframeOptionsConfig.datesInterval.valueDatesRange !== "undefined") {
                window.newValuesFormTab1['textBoxValueDatesRange'] = iframeOptionsConfig.datesInterval.valueDatesRange;
            }
            if (typeof iframeOptionsConfig.datesInterval.valueDatesInterval !== "undefined") {
                if (typeof retObj !== "undefined") {
                    retObj['dropdownDatesInterval'] = iframeOptionsConfig.datesInterval.valueDatesInterval;
                }
            }
        }
        if (typeof iframeOptionsConfig.datesrange !== "undefined") {
            retObj['textBoxValueDatesRange'] = iframeOptionsConfig.datesrange;
        }
    }

    if (typeof iframeOptionsConfig !== "undefined") {
        if (typeof iframeOptionsConfig.parentLocationHash !== "undefined") {
            var parentWidgetUrlFragment = new URLSearchParams(iframeOptionsConfig.parentLocationHash.replace(/#/, ''));
        }
    }
    var locationStr = window.location.hash.replace(/#/, '');
    var widgetUrlFragmentLocation = new URLSearchParams(locationStr);
    if (locationStr == '') {
        locationStr = window.location;
        widgetUrlFragmentLocation = new URLSearchParams(locationStr.search);
    }
     //new URLSearchParams(("#search=%E6%84%9F%E6%9F%93").replace(/#/, '')).get('search');
    $.each(widgetUrlParamsMapping, function (index, item) {
        var curParam = null;
        if (typeof widgetUrlFragment !== "undefined") {
            curParam = widgetUrlFragment.get(index);
        }
        if (curParam == null) {
            if (typeof parentWidgetUrlFragment !== "undefined") {
                curParam = parentWidgetUrlFragment.get(index);
            }
        }
        if (curParam == null) {
            if (typeof widgetUrlFragmentLocation !== "undefined") {
                curParam = widgetUrlFragmentLocation.get(index);
            }
        }
        //console.log(index, curParam);
        if (curParam != null) {
            if ((index == 'datesrange') && (curParam == '')) {
                console.log(curParam);
            } else {
                if (typeof data.result !== "undefined") {
                    if (typeof data.result[item.formField] !== "undefined") {
                        data.result[item.formField] = curParam;
                    } else {
                        data.result[item.formField] = curParam;
                    }
                }
                if (typeof retObj !== "undefined") {
                    retObj[item.formField] = curParam;//data.result[item];
                }
                window.newValuesFormTab1[item.formField] = curParam;//data.result[item];
            }
        }
    });

    if (typeof iframeOptionsConfig !== "undefined") {
        if (typeof iframeOptionsConfig.search !== "undefined") { 
            window.newValuesFormTab1['textBoxValueSearchWord'] = iframeOptionsConfig.search;
            retObj['textBoxValueSearchWord'] = iframeOptionsConfig.search;
        }
    }

    return { 'data': data, 'retObj': retObj };
}


function findPopWordNewColorMolecular(curName, changedColors, md5Val) {
    var popWordNewColor = '';
    $.each(window.customPopWordsColors, function (popWord, colorPopWord) {
        if (curName == popWord) {
            popWordNewColor = colorPopWord;
            if (typeof changedColors[md5Val] !== "undefined") {
                //formdata.changedColors[md5Arr[j]].color = popWordNewColor;
            } else {
                changedColors[md5Val] = {};
                changedColors[md5Val].color = popWordNewColor;
                $('td.wordsColorColumn .node-color-old-value[name="' + curName + '"]').parent().find('.node-color').css('background-color', popWordNewColor);
                $('td.wordsColorColumn .node-color-old-value[name="' + curName + '"]').parent().find('.node-color-new-value').attr('value', popWordNewColor);
            }
            //window.timeSeriesData[chartIndex].wordsListReset = true;
            //firstLoadChart = 0;
        }
    });
    return changedColors;
}

/**
* Helper method for the showChart() function.
* @param object node 
* @return bool
*/
function isSelected(p) {
    if (isDebugg) { console.log('21'); }
    if (typeof p.selected === "undefined" || p.selected === false) {
        return false;
    } else {
        return true;
    }
}


/**
 * Set initial size for the layout`s blocks
 * @returns
 */
function initLayoutBlocks() {
    if (isDebugg) { console.log('23'); }
    window.savedSize.leftWidht = Math.round(((window.savedSize.iniLeftWidht / window.savedSize.contentWidth) * 100) * 100) / 100;
    window.savedSize.rightWidht = Math.round(((window.savedSize.iniRightWidht / window.savedSize.contentWidth) * 100) * 100) / 100;
    window.savedSize.centerWidth = Math.round(
        (
            (
                (window.savedSize.contentWidth - window.savedSize.iniLeftWidht - window.savedSize.iniRightWidht - 2) /
                window.savedSize.contentWidth
            ) * 100
        ) * 100
    ) / 100;
}

/**
 * Change Layout to table css view and add percent width for each part of Layout`s
 * @param firstLoad
 * @returns
 */
function changeLayoutToTable(firstLoad) {
    /*if (isDebugg) {console.log('24');}
    var isMin =  false;
    if (($('#content').width() - 29) < (
        window.savedSize.minLeftWidht +
        window.savedSize.minRightWidht +
        window.savedSize.minCenterWidth
    )) {
        window.savedSize.contentWidth = $('#content').width() - 29;
        $('#content').width((window.savedSize.minLeftWidht + window.savedSize.minRightWidht + window.savedSize.minCenterWidth));
        isMin =  true;
    }
    window.savedSize.contentWidth = $('#content').width() - 29;
    //if (typeof firstLoad !== "undefined" && firstLoad === true) {
        //console.log('241', 'changeLayoutToTable fist', 'isMin=', isMin);
        if (isMin) {
            var leftWidht = window.savedSize.minLeftWidht;
            var rightWidht = window.savedSize.minRightWidht - 59;
        } else {
            var leftWidht = window.savedSize.iniLeftWidht;
            var rightWidht = window.savedSize.iniRightWidht;
        }
        window.savedSize.leftWidht = Math.round(((leftWidht / window.savedSize.contentWidth) * 100) * 100) / 100;
        window.savedSize.rightWidht = Math.round(((rightWidht / window.savedSize.contentWidth) * 100) * 100) / 100;
        window.savedSize.centerWidth = Math.round(
            (
                (
                    (window.savedSize.contentWidth - leftWidht - rightWidht - 2 - 30) / 
                    window.savedSize.contentWidth
                ) * 100
            ) * 100
        ) / 100;
        $('#jqxTree').closest('.jqx-layout-group-tabbed').parent().parent().css('display', 'table');
        $('#jqxTree').closest('.jqx-layout-group-tabbed').parent().css({'display': 'flex'});
        $('#jqxTree').closest('.jqx-layout-group-tabbed').css(
            {
                'display': 'inline-block',
                'min-width': window.savedSize.minLeftWidht + 'px',
                'widht': window.savedSize.leftWidht + '%',
            }
        );
        if (window.savedSize.minCenterWidth > (window.savedSize.contentWidth - leftWidht - rightWidht - 2)) {
            var minCenterWidth = window.savedSize.contentWidth - leftWidht - rightWidht - 2;
        } else {
            var minCenterWidth = window.savedSize.minCenterWidth;
        }
        $('#dataContaner').closest('.jqx-layout-group-default').css(
            {
                'display': 'inline-block',
                'min-width': minCenterWidth + 'px',
                'widht': window.savedSize.centerWidth + '%',
                'max-height': '100%',
                'height': 'auto'
             }
        );
        $('.sampleFormContaner').closest('.jqx-layout-group-tabbed ').css(
            {
                'display': 'inline-block',
                'min-width': window.savedSize.minRightWidht + 'px',
                'widht': window.savedSize.rightWidht + '%',
            }
        );
    $('#formTab1').closest("div").addClass('full-height');
    */
}

/**
 * 
 * @param asFirstLoad
 * @returns
 */
function dataContanerResize(asFirstLoad) {
    if (isDebugg) { console.log('25'); }
    setTimeout(function () {
        if ((!isEqualSizeIndent(dataContanerWidth, Math.round($('#dataContaner').parent().width()), 2))
            || (!isEqualSizeIndent(dataContanerHeight, Math.round($('#dataContaner').parent().height()), 5))) {
            dataContanerWidth = Math.round($('#dataContaner').parent().width());
            dataContanerHeight = Math.round($('#dataContaner').parent().height());
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    window.newValuesFormTab1.chartType = CHART_TYPE_TIMESERIES;
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    window.newValuesFormTab1.chartType = CHART_TYPE_DENDROGRAMHORIZONTAL;
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    window.newValuesFormTab1.chartType = CHART_TYPE_TIMELINE;
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    window.newValuesFormTab1.chartType = CHART_TYPE_STREAMGRAPH;
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    window.newValuesFormTab1.chartType = CHART_TYPE_SANKEY_NEIGHBORS1;
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TEXTUALWORDS) {//textual
                if (typeof curDataChartTW != "undefined") {
                    window.newValuesFormTab1.chartType = CHART_TYPE_TEXTUALWORDS;
                    if (firstLoadChart == 0) {
                        if (typeof curDataChartTW != "undefined") {
                            initPrepareFormData();
                            if (typeof curDataChartTW === "string") {
                                showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                            } else {
                                showChart(curDataChartTW, window.newValuesFormTab1);
                            }
                        }
                    }
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    prepareFixedNodesData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        }
        changeLayoutToTable(asFirstLoad);
        isResize = false;
        setTimeout(function () {
            $('#wordsListTableContaner').attr('style', "max-height:" + ($('#dataContaner').height() - 230) + 'px !important');
        }, 1000);
    }, 500);
}

/**
 * 
 * @param value1
 * @param value2
 * @param indent
 * @returns
 */
function isEqualSizeIndent(value1, value2, indent) {
    if (isDebugg) { console.log('26'); }
    var isEqual = false;
    if (value1 == value2) {
        isEqual = true;
    }
    if ((value1 > (value2 - indent * 2)) && (value1 < (value2 + indent * 2))) {
        isEqual = true;
    }
    return isEqual;
}

/**
 * prepares data from several related forms (tabs) and sets their values to the window object
 * @returns
 */
function initPrepareFormData() {
    if (isDebugg) { console.log('27'); }
    if (window.sampleFormDrawing.jqxForm('getComponentByName', 'isChangeTab2').val() == true) {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'isChangeTab2').val(false);
    }
    window.sampleFormDrawing.jqxForm('getComponentByName', 'isChangeTab2').val(true);
    setValuesFromTab2();

    if (window.sampleFormAnalysis.jqxForm('getComponentByName', 'isChangeTab3').val() == true) {
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'isChangeTab3').val(false);
    }
    window.sampleFormAnalysis.jqxForm('getComponentByName', 'isChangeTab3').val(true);
    setValuesFromTab3();

    if (window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val() == true) {
        window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(false);
    }
    window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(true);
    setValuesFromTab4();

    if (window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val() == true) {
        window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(false);
    }
    window.sampleForm.jqxForm('getComponentByName', 'isChangeTab1').val(true);
}

/**
 * Save curren Form Data select tab values
 * @param chartType
 * @param chartIndex
 * @returns
 */
function saveCurrenFormDataSelectValues(chartType, chartIndex) {
    if (typeof chartType === "undefined") {
        chartType = chartTypeDefault;
    }
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    if (typeof window.currenFormDataSelectValues === "undefined") {
        window.currenFormDataSelectValues = {};
    }
    if (firstLoadChart == 1 && isShare && hash != '') {
        if (typeof window.newValuesFormTab1.hiddenLegendItems === "undefined") {
            var hiddenLegendItems = [];
        } else {
            var hiddenLegendItems = window.newValuesFormTab1.hiddenLegendItems;
        }
        if (typeof window.newValuesFormTab1.drawingValueIndicators === "undefined") {
            var drawingValueIndicators = [1];
        } else {
            var drawingValueIndicators = window.newValuesFormTab1.drawingValueIndicators;
        }
        if (typeof window.newValuesFormTab1.fixedNodesArr === "undefined") {
            var fixedNodesArr = {};
        } else {
            var fixedNodesArr = window.newValuesFormTab1.fixedNodesArr;
        }
    } else {
        var hiddenLegendItems = getHiddenLegendItems(chartType);
        var drawingValueIndicators = getDrawingValueIndicators();
        var fixedNodesArr = prepareFixedNodesData();
    }
    if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) {
        if ($('#dataTextualWords').length > 0) {
            var textualWordsGridSorting = JSON.stringify($('#dataTextualWords').jqxGrid('getsortinformation'));
            var textualWordsGridState = $('#dataTextualWords').jqxGrid('getstate');
            var textualWordsGridPagenum = 0;
            var textualWordsGridPagesize = 20;
            if (typeof textualWordsGridState != "undefined") {
                if (typeof textualWordsGridState.pagenum != "undefined") {
                    textualWordsGridPagenum = window.newValuesFormTab1.textualWordsGridPagenum = textualWordsGridState.pagenum;
                }
                if (typeof textualWordsGridState.pagesize != "undefined") {
                    textualWordsGridPagesize = window.newValuesFormTab1.textualWordsGridPagesize = textualWordsGridState.pagesize;
                }
            }
        } else {
            var textualWordsGridSorting = JSON.stringify({});
            var textualWordsGridPagenum = 0;
            var textualWordsGridPagesize = 20;
        }
    } else {
        var textualWordsGridSorting = JSON.stringify({});
        var textualWordsGridPagenum = 0;
        var textualWordsGridPagesize = 20;
    }

    if (($('#chartType').val() == CHART_TYPE_RADIAL) || ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
        var changedRadLevelColors = getChangedRadLevelColors();
    }

    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    window.molecularData[chartIndex].fixedNodesArr = fixedNodesArr;

    window.currenFormDataSelectValues[chartIndex] = {
        'dropdownValueDSource': $('#dropdownValueDSource').val(),
        'dropdownValueDSource2': $('.data-source-label2').closest("tr").find('input').val(),
        'dropdownDatesInterval': $('#dropdownDatesInterval').val(),
        'textBoxValueDatesRange': $('#inputjqxWidgetDateRange').val(),
        'textBoxValueExcludePeriods': $('.form-exclude-periods-input').closest("tr").find('input').val(),
        'nubmberBoxValue1': $("#nubmberBoxValue1 input").val(),
        'nubmberBoxValue2': $("#nubmberBoxValue2 input").val(),
        'checkboxRepeatSelectionMinMaxUnlim': $('.checkbox-repeat-selection-unlim-label').closest("tr").find('input').val(),
        'checkboxValueIndicatorLimitsStrict': window.sampleForm.jqxForm('getComponentByName', 'checkboxValueIndicatorLimitsStrict').val(),

        //Relations Type Mask
        'checkboxPWIntersectedNeighbors': $('.checkbox-pop-words-intersected-neighbors-label').closest("tr").find('input').val(),
        'checkboxPW1inNeighbors2': $('.checkbox-pop-words-1-in-neighbors-2-label').closest("tr").find('input').val(),
        'checkboxPW2inNeighbors1': $('.checkbox-pop-words-2-in-neighbors-1-label').closest("tr").find('input').val(),
        'checkboxIntersectedNeighbors': $('.checkbox-intersected-neighbors-label').closest("tr").find('input').val(),
        'checkboxArticlesList': $('.checkbox-articles-list-label').closest("tr").find('input').val(),

        'nubmberBoxNeighborsFreqMin': $("#nubmberBoxNeighborsFreqMin input").val(),
        'nubmberBoxNeighborsFreqMax': $("#nubmberBoxNeighborsFreqMax input").val(),
        'nubmberBoxSatellitesFreqMin': $("#nubmberBoxSatellitesFreqMin input").val(),
        'nubmberBoxSatellitesFreqMax': $("#nubmberBoxSatellitesFreqMax input").val(),
        'nubmberBoxAppearanceMin': $("#nubmberBoxAppearanceMin input").val(),
        'nubmberBoxAppearanceMax': $("#nubmberBoxAppearanceMax input").val(),
        'nubmberBoxValueMaxWordsTotal': $("#nubmberBoxValueMaxWordsTotal input").val(),
        'nubmberBoxNeighborsMaxItems': $("#nubmberBoxNeighborsMaxItems input").val(),
        'nubmberBoxValueMaxWordsTotalTextual': $("#nubmberBoxValueMaxWordsTotalTextual input").val(),
        'nubmberBoxValueFontSize': $("#nubmberBoxValueFontSize input").val(),
        'textBoxTitleTimeCharts': window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxTitleTimeCharts').val(),
        'textBoxSubtitleTimeCharts': window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxSubtitleTimeCharts').val(),
        'textBoxDescriptionTimeCharts': window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxDescriptionTimeCharts').val(),
        'dropdownValueIWords': $('.intermediate-words-label').closest("tr").find('input').val(),
        'dropdownDaysInterval': $('#dropdownDaysInterval').val(),
        'dropdownMinIntersectedNeighbors': $('#dropdownMinIntersectedNeighbors').val(),
        'dropdownSortDailyWordsBy': $('.sort-daily-words-by-label').closest("tr").find('input').val(),
        'satellitesOrderCondition': $('.words-selection-order-label').closest("tr").find('input').val(),
        'dropdownDataSelectCache': $('.dropdown-data-select-cache-label').closest("tr").find('input').val(),
        'dropdownValueMaxDepth': $('.max-depth-label').closest("tr").find('input').val(),
        'dropdownMaxDistance': $('.distance-label').closest("tr").find('input').val(),
        'dropdownValueVIndicator': $('.value-indicator-label').closest("tr").find('input').val(),
        'dropdownsSortField': $('.sort-field-label').closest("tr").find('input').val(),
        'dropdownValueVScale': $('.value-scale-label').closest("tr").find('input').val(),
        'dropdownValueVSmooth': $('.value-smooth-label').closest("tr").find('input').val(),
        'dropdownDataOmission': $('.data-omission-label').closest("tr").find('input').val(),
        'checkboxPreserveDateRange': window.sampleFormAnalysis.jqxForm('getComponentByName', 'checkboxPreserveDateRange').val(),
        'dropdownColorsSelection': $('.form-dropdown-colors-selection-label').closest("tr").find('input').val(),
        'dropdownConnectorLineWidthIndicator': $('.form-dropdown-connector-line-width-indicator-label').closest("tr").find('input').val(),
        'dropdownConnectorLinesColor': $('.form-connector-lines-color-label').closest("tr").find('input').val(),
        'dropdownColorsSet': $('.form-dropdown-colors-set-label').closest("tr").find('input').val(),
        'dropdownChartBackgroundColor': $('.form-dropdown-chart-background-color-label').closest("tr").find('input').val(),
        'dropdownChartTextColor': $('.form-dropdown-chart-text-color-label').closest("tr").find('input').val(),
        'changedCTColor': $('.chart-text-new-value').val(),
        'dropdownBallsText': $('.form-dropdown-balls-text-label').closest("tr").find('input').val(),
        'dropdownBallsTextLayout': $('.form-dropdown-balls-text-layout-label').closest("tr").find('input').val(),
        'dropdownFilterModel': $('.data-filter-model-label').closest("tr").find('input').val(),
        'checkboxAppearanceFullTimeValue': $('.appearance-time-label').closest("tr").find('input').val(),
        'checkboxPOSAcronymValue': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSAcronym').val(),
        'checkboxPOSGeoValue': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSGeo').val(),
        'dropdownUniqueWords': $('.unique-words-label').closest("tr").find('input').val(),
        'dropdownSortOrderWords': $('.sort-order-words-label').closest("tr").find('input').val(),

        //Satellites Type Mask
        'checkboxMultiWordSatellitesTypeMask': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxMultiWordSatellitesTypeMask').val(),
        'checkboxProperSatellitesTypeMask': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxProperSatellitesTypeMask').val(),
        'checkboxNormalizedSatellitesTypeMask': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNormalizedSatellitesTypeMask').val(),
        'checkboxReadableSatellitesTypeMask': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxReadableSatellitesTypeMask').val(),
        'checkboxCommonSatellitesTypeMask': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxCommonSatellitesTypeMask').val(),
        'checkboxNumbersSatellitesTypeMask': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNumbersSatellitesTypeMask').val(),

        'checkboxPOSPersonNameValue': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSPersonName').val(),
        'checkboxPOSWikiValue': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSWiki').val(),
        'checkboxWordsGroupSubentries': window.sampleFormWords.jqxForm('getComponentByName', 'checkboxWordsGroupSubentries').val(),
        'textBoxValueSearchWord': window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val(),
        'synonymsWordsCustom': $('#synonymsWordsCustom').val(),
        'textBoxValueExcludeWord': window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueExcludeWord').val(),
        'dropdownModel': window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownModel').val(),
        'dropdownFilterDirection': window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownFilterDirection').val(),
        'dropdownFactorsData': window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownFactorsData').val(),
        'dropdownCentroidInitialization': window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownCentroidInitialization').val(),
        'dropdownClusterizationModel': window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownClusterizationModel').val(),
        'dropdownInterpolationPrediction': window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownInterpolationPrediction').val(),
        'numberBoxDegree': $("#numberBoxDegree input").val(),
        'numberBoxFilterThreshold': $("#numberBoxFilterThreshold input").val(),
        'numberBoxClustersNumber': $("#numberBoxClustersNumber input").val(),
        'numberBoxEpsilon': $("#numberBoxEpsilon input").val(),
        'numberBoxMinItemsPerCluster': $("#numberBoxMinItemsPerCluster input").val(),
        'hiddenLegendItems': hiddenLegendItems,
        'drawingValueIndicators': drawingValueIndicators,
        'textualWordsGridSorting': textualWordsGridSorting,
        'textualWordsGridPagenum': textualWordsGridPagenum,
        'textualWordsGridPagesize': textualWordsGridPagesize,
        'fixedNodesArr': fixedNodesArr,
        'selectedTab': getCurSelectedTab(),
        'changedRadLevelColors': changedRadLevelColors
    }
}

function getCurSelectedTab() {
    var selectedTab = 'formTab1';
    if ($('#formTab1').parent().attr('class').search('item-selected') >= 0) {
        var selectedTab = 'formTab1';
    }
    if ($('#formTab2').parent().attr('class').search('item-selected') >= 0) {
        var selectedTab = 'formTab2';
    }
    if ($('#formTab3').parent().attr('class').search('item-selected') >= 0) {
        var selectedTab = 'formTab3';
    }
    if ($('#formTab4').parent().attr('class').search('item-selected') >= 0) {
        var selectedTab = 'formTab4';
    }
    return selectedTab;
}

function setSelectedTab(selectedTab) {
    $('#' + selectedTab).parent().click();
}

function setDataSourceTooltip() {
    var titleText = ($('.jqx-listitem-state-selected').text() != '') ? $('.jqx-listitem-state-selected').text() : $('#dropdownlistWrapperdropdownValueDSource').text();
    $('#dropdownlistWrapperdropdownValueDSource').attr('title', titleText);
}

/**
 * 
 * @param hash
 * @returns
 */
function setShareValuesForm(dataResult) {
    if (isDebugg) { console.log('111 setShareValuesForm', dataResult); }
    /*Set data for the chart type*/
    if (typeof dataResult.chartType !== "undefined") {
    } else {
        dataResult.chartType = chartTypeDefault;
    }
    /*Set data for the tab1*/
    if (typeof dataResult.dropdownValueDSource !== "undefined") {
        $('#dropdownValueDSource').val(dataResult.dropdownValueDSource);
        var dropdownValueDSourceVal = dataResult.dropdownValueDSource;
        var dropdownValueVIndicator = window.sampleForm.jqxForm('getComponentByName', 'dropdownValueVIndicator');
        dropdownValueVIndicator.jqxDropDownList('clear');

        if (typeof valueIndicatorListBySources[dropdownValueDSourceVal] !== "undefined") {
            valueIndicatorList = valueIndicatorListBySources[dropdownValueDSourceVal].list;
            if (typeof valueIndicatorListBySources[dropdownValueDSourceVal].legend !== "undefined") {
                valueDefault = valueIndicatorListBySources[dropdownValueDSourceVal].valueDefault;
            }
        } else if (typeof valueIndicatorListBySources.valueIndicatorListDef !== "undefined") {
            valueIndicatorList = valueIndicatorListBySources.valueIndicatorListDef.list;
            if (typeof valueIndicatorListBySources.valueIndicatorListDef.valueDefault !== "undefined") {
                valueDefault = valueIndicatorListBySources.valueIndicatorListDef.valueDefault;
            }
        } else {
            valueIndicatorList = {};
        }
        if (typeof valueDefault == "undefined") {
            valueDefault = 0;
        }
        if (typeof dataResult.dropdownValueVIndicator !== "undefined") {
            valueDefault = dataResult.dropdownValueVIndicator;
        }
        var selectedItem = {};
        var selectedIndex = 0;
        var i = 0;
        $.each(valueIndicatorList, function (index, item) {
            dropdownValueVIndicator.jqxDropDownList('addItem', { label: index, value: item });
            if (item == valueDefault) {
                selectedItem = { label: index, value: item };
                selectedIndex = i;
            }
            i++;
        });
        dropdownValueVIndicator.jqxDropDownList('val', valueDefault);
        dropdownValueVIndicator.jqxDropDownList('selectIndex', selectedIndex);
    //}



    var dropdownsSortField = window.sampleForm.jqxForm('getComponentByName', 'dropdownsSortField');
    dropdownsSortField.jqxDropDownList('clear');

    if (typeof sortFieldListBySources[dropdownValueDSourceVal] !== "undefined") {
        sortFieldList = sortFieldListBySources[dropdownValueDSourceVal].list;
        if (typeof sortFieldListBySources[dropdownValueDSourceVal].legend !== "undefined") {
            valueDefault = sortFieldListBySources[dropdownValueDSourceVal].valueDefault;
        }
    } else if (typeof sortFieldListBySources.sortFieldListDef !== "undefined") {
        sortFieldList = sortFieldListBySources.sortFieldListDef.list;
        if (typeof sortFieldListBySources.sortFieldListDef.valueDefault !== "undefined") {
            valueDefault = sortFieldListBySources.sortFieldListDef.valueDefault;
        }
    } else {
        //sortFieldList = {};
    }
    if (typeof valueDefault == "undefined") {
        valueDefault = 0;
    }
    if (typeof dataResult.dropdownsSortField !== "undefined") {
        valueDefault = dataResult.dropdownsSortField;
    }
    var selectedItem = {};
    var selectedIndex = 0;
    var i = 0;
    $.each(sortFieldList, function (index, item) {
        dropdownsSortField.jqxDropDownList('addItem', { label: index, value: item });
        if (item == valueDefault) {
            selectedItem = { label: index, value: item };
            selectedIndex = i;
        }
        i++;
    });
    dropdownsSortField.jqxDropDownList('val', valueDefault);
    dropdownsSortField.jqxDropDownList('selectIndex', selectedIndex);
}





    if (typeof dataResult.drawingValueIndicators !== "undefined") {
        setDrawingValueIndicatorsChecked(dataResult.drawingValueIndicators);
    } else {
        setDrawingValueIndicatorsChecked(dataResult.drawingValueIndicators);
    }

    if (typeof dataResult.dropdownValueDSource2 !== "undefined") {
    }
    if (typeof dataResult.dropdownDatesInterval == "undefined") {
        dataResult.dropdownDatesInterval = 0;
    }
    $('#dropdownDatesInterval').val(dataResult.dropdownDatesInterval);
    if ((typeof dataResult.textBoxValueDatesRange !== "undefined") && (dataResult.textBoxValueDatesRange !== '')) {
        setDatesInterval(dataResult.dropdownDatesInterval, dataResult.textBoxValueDatesRange);
    }
    if (typeof dataResult.textBoxValueExcludePeriods !== "undefined") {
        $('.form-exclude-periods-input').closest("tr").find('input').val(dataResult.textBoxValueExcludePeriods);
    }

    if (typeof dataResult.nubmberBoxValue1 !== "undefined") {
        $('#nubmberBoxValue1 > input:nth-child(1)').val(dataResult.nubmberBoxValue1);
    }
    if (typeof dataResult.nubmberBoxValue2 !== "undefined") {
        $('#nubmberBoxValue2 > input:nth-child(1)').val(dataResult.nubmberBoxValue2);
    }
    if (typeof dataResult.checkboxRepeatSelectionMinMaxUnlim !== "undefined") {
        $('#checkboxRepeatSelectionMinMaxUnlim').val(dataResult.checkboxRepeatSelectionMinMaxUnlim);
        window.sampleForm.jqxForm('getComponentByName', 'checkboxRepeatSelectionMinMaxUnlim')
        .val(dataResult.checkboxRepeatSelectionMinMaxUnlim);
    }
    
    if (typeof dataResult.checkboxPWIntersectedNeighbors !== "undefined") {
        $('#checkboxPWIntersectedNeighbors').val(dataResult.checkboxPWIntersectedNeighbors);
        window.sampleForm.jqxForm('getComponentByName', 'checkboxPWIntersectedNeighbors')
        .val(dataResult.checkboxPWIntersectedNeighbors);
    }
    if (typeof dataResult.checkboxPW1inNeighbors2 !== "undefined") {
        $('#checkboxPW1inNeighbors2').val(dataResult.checkboxPW1inNeighbors2);
        window.sampleForm.jqxForm('getComponentByName', 'checkboxPW1inNeighbors2')
        .val(dataResult.checkboxPW1inNeighbors2);
    }
    if (typeof dataResult.checkboxPW2inNeighbors1 !== "undefined") {
        $('#checkboxPW2inNeighbors1').val(dataResult.checkboxPW2inNeighbors1);
        window.sampleForm.jqxForm('getComponentByName', 'checkboxPW2inNeighbors1')
        .val(dataResult.checkboxPW2inNeighbors1);
    }
    if (typeof dataResult.checkboxIntersectedNeighbors !== "undefined") {
        $('#checkboxIntersectedNeighbors').val(dataResult.checkboxIntersectedNeighbors);
        window.sampleForm.jqxForm('getComponentByName', 'checkboxIntersectedNeighbors')
        .val(dataResult.checkboxIntersectedNeighbors);
    }
    if (typeof dataResult.checkboxArticlesList !== "undefined") {
        $('#checkboxArticlesList').val(dataResult.checkboxArticlesList);
        window.sampleForm.jqxForm('getComponentByName', 'checkboxArticlesList')
        .val(dataResult.checkboxArticlesList);
    }
    if (typeof dataResult.nubmberBoxNeighborsFreqMin !== "undefined") {
        $('#nubmberBoxNeighborsFreqMin > input:nth-child(1)').val(dataResult.nubmberBoxNeighborsFreqMin);
    }
    if (typeof dataResult.nubmberBoxNeighborsFreqMax !== "undefined") {
        $('#nubmberBoxNeighborsFreqMax > input:nth-child(1)').val(dataResult.nubmberBoxNeighborsFreqMax);
    }
    if (typeof dataResult.nubmberBoxSatellitesFreqMin !== "undefined") {
        $('#nubmberBoxSatellitesFreqMin > input:nth-child(1)').val(dataResult.nubmberBoxSatellitesFreqMin);
    }
    if (typeof dataResult.nubmberBoxSatellitesFreqMax !== "undefined") {
        $('#nubmberBoxSatellitesFreqMax > input:nth-child(1)').val(dataResult.nubmberBoxSatellitesFreqMax);
    }
    if (typeof dataResult.nubmberBoxAppearanceMin !== "undefined") {
        $('#nubmberBoxAppearanceMin > input:nth-child(1)').val(dataResult.nubmberBoxAppearanceMin);
    }
    if (typeof dataResult.nubmberBoxAppearanceMax !== "undefined") {
        $('#nubmberBoxAppearanceMax > input:nth-child(1)').val(dataResult.nubmberBoxAppearanceMax);
    }
    if (typeof dataResult.nubmberBoxValueMaxWordsTotal !== "undefined") {
        $('#nubmberBoxValueMaxWordsTotal > input:nth-child(1)').val(dataResult.nubmberBoxValueMaxWordsTotal);
    }
    if (typeof dataResult.nubmberBoxNeighborsMaxItems !== "undefined") {
        $('#nubmberBoxNeighborsMaxItems > input:nth-child(1)').val(dataResult.nubmberBoxNeighborsMaxItems);
    }
    if (typeof dataResult.nubmberBoxValueMaxWordsTotalTextual == "undefined") {
        if (dataResult.chartType == HART_TYPE_TIMESERIES) {
            dataResult.nubmberBoxValueMaxWordsTotalTextual = 50;
        } else {
            dataResult.nubmberBoxValueMaxWordsTotalTextual = 1000;
        }
    }
    if (typeof dataResult.nubmberBoxValueMaxWordsTotalTextual !== "undefined") {
        $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(dataResult.nubmberBoxValueMaxWordsTotalTextual);
    }
    if (typeof dataResult.nubmberBoxValueFontSize == "undefined") {
        dataResult.nubmberBoxValueFontSize = '';
    }
    if (typeof dataResult.nubmberBoxValueFontSize !== "undefined") {
        $('#nubmberBoxValueFontSize > input:nth-child(1)').val(dataResult.nubmberBoxValueFontSize);
    }
    if (typeof dataResult.dropdownValueIWords !== "undefined") {
    }
    if (typeof dataResult.dropdownDaysInterval !== "undefined") {
        $('#dropdownDaysInterval').val(dataResult.dropdownDaysInterval);
    }
    if (typeof dataResult.dropdownMinIntersectedNeighbors !== "undefined") {
        $('#dropdownMinIntersectedNeighbors').val(dataResult.dropdownMinIntersectedNeighbors);
    }

    if (typeof dataResult.dropdownSortDailyWordsBy !== "undefined") {
        var dropdownSortDailyWordsBy = window.sampleForm.jqxForm('getComponentByName', 'dropdownSortDailyWordsBy');
        dropdownSortDailyWordsBy.jqxInput('val', dataResult.dropdownSortDailyWordsBy);
        //$("#dropdownSortDailyWordsBy").jqxDropDownList('val', dataResult.dropdownSortDailyWordsBy);
    }

    if (typeof dataResult.satellitesOrderCondition !== "undefined") {
    }
    if (typeof dataResult.dropdownDataSelectCache !== "undefined") {
    }
    if (typeof dataResult.dropdownUniqueWords !== "undefined") {
    }
    if (typeof dataResult.dropdownSortOrderWords !== "undefined") {
    }
    if (typeof dataResult.dropdownValueMaxDepth !== "undefined") {
    }
    if (typeof dataResult.dropdownMaxDistance !== "undefined") {
    }
    //if (typeof dataResult.dropdownValueVIndicator !== "undefined") {
    //    dropdownValueVIndicator.jqxDropDownList('val', dataResult.dropdownValueVIndicator);
    //}
    if (typeof dataResult.dropdownFilterModel !== "undefined") {
    }
    if (typeof dataResult.checkboxAppearanceFullTimeValue !== "undefined") {
    }
    if (typeof dataResult.textualWordsGridSorting !== "undefined") {
        window.newValuesFormTab1.textualWordsGridSorting = dataResult.textualWordsGridSorting;
    }
    if (typeof dataResult.metadataWord != "undefined") {
        window.newValuesFormTab1.metadataWord = dataResult.metadataWord;
    }
    if (typeof dataResult.metadataDate != "undefined") {
        window.newValuesFormTab1.metadataDate = dataResult.metadataDate;
    }
    if (typeof dataResult.textualWordsGridPagenum != "undefined") {
        window.newValuesFormTab1.textualWordsGridPagenum = dataResult.textualWordsGridPagenum;
    }
    if (typeof dataResult.textualWordsGridPagesize != "undefined") {
        window.newValuesFormTab1.textualWordsGridPagesize = dataResult.textualWordsGridPagesize;
    }

    if (typeof dataResult.hiddenLegendItems !== "undefined") {
        window.newValuesFormTab1.hiddenLegendItems = dataResult.hiddenLegendItems;
    } else {
        window.newValuesFormTab1.hiddenLegendItems = {};
    }

    if (typeof dataResult.drawingValueIndicators !== "undefined") {
        window.newValuesFormTab1.drawingValueIndicators = dataResult.drawingValueIndicators;
    } else {
        window.newValuesFormTab1.drawingValueIndicators = [1];
    }
    if (typeof dataResult.fixedNodesArr !== "undefined") {
        window.newValuesFormTab1.fixedNodesArr = dataResult.fixedNodesArr;
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        window.molecularData[chartIndex].fixedNodesArr = dataResult.fixedNodesArr;
    }
    if (typeof dataResult.textualWordsGridSorting !== "undefined") {
        window.newValuesFormTab1.textualWordsGridSorting = dataResult.textualWordsGridSorting;
    }
    if (typeof dataResult.metadataWord != "undefined") {
        window.newValuesFormTab1.metadataWord = dataResult.metadataWord;
    }
    if (typeof dataResult.metadataDate != "undefined") {
        window.newValuesFormTab1.metadataDate = dataResult.metadataDate;
    }
    if (typeof dataResult.textualWordsGridPagenum != "undefined") {
        window.newValuesFormTab1.textualWordsGridPagenum = dataResult.textualWordsGridPagenum;
    }
    if (typeof dataResult.textualWordsGridPagesize != "undefined") {
        window.newValuesFormTab1.textualWordsGridPagesize = dataResult.textualWordsGridPagesize;
    }
    if (typeof dataResult.changedRadLevelColors != "undefined") {
        window.newValuesFormTab1.changedRadLevelColors = dataResult.changedRadLevelColors;
        setChangedRadLevelColors(dataResult.changedRadLevelColors);
    }

    /*Set data for the tab2*/
    if (typeof dataResult.dropdownMaxNodes !== "undefined") {
        var dropdownMaxNodes = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownMaxNodes');
        dropdownMaxNodes.jqxInput('val', dataResult.dropdownMaxNodes);
        $("#jqxWidgetMaxNodes").jqxDropDownList('val', dataResult.dropdownMaxNodes);
    }
    if (typeof dataResult.dropdownAutoAdjust !== "undefined") {
        var dropdownAutoAdjust = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownAutoAdjust');
        dropdownAutoAdjust.jqxInput('val', dataResult.dropdownAutoAdjust);
        $("#jqxWidgetAutoAdjust").jqxDropDownList('val', dataResult.dropdownAutoAdjust);
    }
    if (typeof dataResult.dropdownAutoAdjustCnt !== "undefined") {
        var dropdownAutoAdjustCnt = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownAutoAdjustCnt');
        dropdownAutoAdjustCnt.jqxInput('val', dataResult.dropdownAutoAdjustCnt);
        $("#jqxWidgetAutoAdjustCnt").jqxDropDownList('val', dataResult.dropdownAutoAdjustCnt);
    }
    if (typeof dataResult.dropdownChartView !== "undefined") {
        var dropdownChartView = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartView');
        dropdownChartView.jqxInput('val', dataResult.dropdownChartView);
        $("#jqxWidgetChartView").jqxDropDownList('val', dataResult.dropdownChartView);
    }
    if (typeof dataResult.textBoxTitleTimeCharts !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxTitleTimeCharts').val(dataResult.textBoxTitleTimeCharts);
    }
    if (typeof dataResult.textBoxSubtitleTimeCharts !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxSubtitleTimeCharts').val(dataResult.textBoxSubtitleTimeCharts);
    }
    if (typeof dataResult.textBoxDescriptionTimeCharts !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxDescriptionTimeCharts').val(dataResult.textBoxDescriptionTimeCharts);
    }
    if (typeof dataResult.dropdownColorsSelection !== "undefined") {
        window.newValuesFormTab2.dropdownColorsSelection =
            window.newValuesFormTab1.dropdownColorsSelection =
            dataResult.dropdownColorsSelection;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection').val(dataResult.dropdownColorsSelection);
    }
    if (typeof dataResult.dropdownConnectorLineWidthIndicator !== "undefined") {
        window.newValuesFormTab2.dropdownConnectorLineWidthIndicator =
            window.newValuesFormTab1.dropdownConnectorLineWidthIndicator =
            dataResult.dropdownConnectorLineWidthIndicator;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator').val(dataResult.dropdownConnectorLineWidthIndicator);
    }
    if (typeof dataResult.dropdownConnectorLinesColor !== "undefined") {
        window.newValuesFormTab2.dropdownConnectorLinesColor =
            window.newValuesFormTab1.dropdownConnectorLinesColor =
            dataResult.dropdownConnectorLinesColor;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLinesColor').val(dataResult.dropdownConnectorLinesColor);
    }
    if (typeof dataResult.dropdownColorsSet !== "undefined") {
        window.newValuesFormTab2.dropdownColorsSet =
            window.newValuesFormTab1.dropdownColorsSet =
            dataResult.dropdownColorsSet;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSet').val(dataResult.dropdownColorsSet);
    }
    if (typeof dataResult.dropdownChartBackgroundColor !== "undefined") {
        chartBackgroundColor = dataResult.dropdownChartBackgroundColor;
        if ((chartBackgroundColor == 0)) {
            chartBackgroundColor = setAutoBackgroundColor();
        }
        $('#dataContaner').css('background-color', chartBackgroundColor);
        window.newValuesFormTab2.dropdownChartBackgroundColor =
            window.newValuesFormTab1.dropdownChartBackgroundColor =
            dataResult.dropdownChartBackgroundColor;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartBackgroundColor').val(dataResult.dropdownChartBackgroundColor);
    }
    if (typeof dataResult.dropdownChartTextColor !== "undefined") {
        window.newValuesFormTab2.dropdownChartTextColor =
            window.newValuesFormTab1.dropdownChartTextColor =
            dataResult.dropdownChartTextColor;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartTextColor').val(dataResult.dropdownChartTextColor);
    }
    if (typeof dataResult.changedCTColor !== "undefined") {
        window.newValuesFormTab2.changedCTColor =
            window.newValuesFormTab1.changedCTColor =
            dataResult.changedCTColor;
        $('.chart-text-color').css('background-color', dataResult.changedCTColor);
        $('td.colorColumnChartText .chart-text-new-value').val(dataResult.changedCTColor);
    }

    if (typeof dataResult.dropdownBallsText !== "undefined") {
        window.newValuesFormTab2.dropdownBallsText =
            window.newValuesFormTab1.dropdownBallsText =
            dataResult.dropdownBallsText;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsText').val(dataResult.dropdownBallsText);
    }
    if (typeof dataResult.dropdownBallsTextLayout !== "undefined") {
        window.newValuesFormTab2.dropdownBallsTextLayout =
            window.newValuesFormTab1.dropdownBallsTextLayout =
            dataResult.dropdownBallsTextLayout;
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsTextLayout').val(dataResult.dropdownBallsTextLayout);
    }
    if (typeof dataResult.checkboxConnectorsLinesValue !== "undefined") {
    }
    if (typeof dataResult.circleSizeMult !== "undefined") {
        $('#sizeSliderElement').val(dataResult.circleSizeMult * 1);
    }
    if (typeof dataResult.fontSizeMult !== "undefined") {
        $('#fontSizeSliderElement').val(dataResult.fontSizeMult * 1);
    }

    if (typeof dataResult.sourcePointsSizeMult !== "undefined") {
        $('#sizeSourcePointsSliderElement').val(dataResult.sourcePointsSizeMult * 1);
    }
    if (typeof dataResult.lineWidthMult !== "undefined") {
        $('#lineWidthSliderElement').val(dataResult.lineWidthMult * 1);
    }
    if (typeof dataResult.gravityValue !== "undefined") {
        $('#gravitySliderElement').val(dataResult.gravityValue * 1);
    }
    if (typeof dataResult.indentValue !== "undefined") {
        $('#indentSliderElement').val(dataResult.indentValue * 1);
    }
    if (typeof dataResult.radiusValue !== "undefined") {
        $('#radiusSliderElement').val(dataResult.radiusValue * 1);
    }
    if (typeof dataResult.checkboxIndentValuesRelatedValue !== "undefined") {
    }
    if (typeof dataResult.checkboxNotIncludeSingleNodesValue !== "undefined") {
    }
    if (typeof dataResult.checkboxReduceOverlapValue !== "undefined") {
    }
    if (typeof dataResult.checkboxShowHideLegendValue !== "undefined") {
    }
    if (typeof dataResult.checkboxShowHideSeriesLabelValue !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideSeriesLabel')
            .val(dataResult.checkboxShowHideSeriesLabelValue);
    }
    if (typeof dataResult.checkboxShowHideTitleValue !== "undefined") {
    }
    if (typeof dataResult.checkboxAddNodesFixLevel !== "undefined") {
    }
    if (typeof dataResult.checkboxShowHideMenuValue !== "undefined") {
    }
    if (typeof dataResult.checkboxShowHideTableHeaderValue !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTableHeader')
            .val(dataResult.checkboxShowHideTableHeaderValue);
    }
    if (typeof dataResult.checkboxOrientationHorizontalVertical !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical')
            .val(dataResult.checkboxOrientationHorizontalVertical);
    }
    if (typeof dataResult.checkboxShowHideMetadataColumnValue !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMetadataColumn')
            .val(dataResult.checkboxShowHideMetadataColumnValue);
    }
    if (typeof dataResult.checkboxShowHidePaginationValue !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHidePagination')
            .val(dataResult.checkboxShowHidePaginationValue);
    }
    if (typeof dataResult.checkboxConnectNullsValue !== "undefined") {
    }
    if (typeof dataResult.dropdownConnectorLinesSourceValue !== "undefined") {
    }
    if (typeof dataResult.checkboxSourceDataPointsValue !== "undefined") {
    }
    if (typeof dataResult.checkboxAxisTurnOnOffValue !== "undefined") {
    }
    if (typeof dataResult.wordsListDataArr !== "undefined") {
        if (typeof dataResult.wordsList == "undefined") {
            dataResult.wordsList = {};
        }
        if (firstLoadChart == 1 && isShare && hash != '') {
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            if (typeof dataResult.wordsListDataArr == "string") {
                window.radialData[chartIndex].wordsListFirst = JSON.parse(dataResult.wordsListDataArr);
            } else {
                window.radialData[chartIndex].wordsListFirst = dataResult.wordsListDataArr;
            }
            window.radialData[chartIndex].wordsListReset = false;
            window.molecularData[chartIndex].wordsListReset = true;
        }
    }
    if (typeof dataResult.changedColors !== "undefined") {
        if (typeof dataResult.changedColors == "object") {
            changedColors = dataResult.changedColors;
        } else {
            if (dataResult.changedColors == '') {
                dataResult.changedColors = '{}';
            }
            changedColors = JSON.parse(dataResult.changedColors);
        }
        window.newValuesFormTab4.changedColors = window.newValuesFormTab1.changedColors = changedColors;
    }

    /*Set data for the tab3 (Analysis) */
    if (typeof dataResult.numberBoxDegree !== "undefined") {
        $('#numberBoxDegree > input:nth-child(1)').val(dataResult.numberBoxDegree);
    }
    var scaleSmoothValues = separationScaleSmoothValuesForBackwardCompatibility(dataResult.dropdownValueVScale, dataResult.dropdownValueVSmooth);
    window.newValuesFormTab3.dropdownValueVScale =
        window.newValuesFormTab1.dropdownValueVScale =
        scaleSmoothValues.valueVScale;
    window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownValueVScale').val(scaleSmoothValues.valueVScale);
    window.newValuesFormTab3.dropdownValueVSmooth =
        window.newValuesFormTab1.dropdownValueVSmooth =
        scaleSmoothValues.valueVSmooth;
    window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownValueVSmooth').val(scaleSmoothValues.valueVSmooth);

    if (typeof dataResult.dropdownDataOmission !== "undefined") {
        window.newValuesFormTab3.dropdownDataOmission =
            window.newValuesFormTab1.dropdownDataOmission =
            dataResult.dropdownDataOmission;
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownDataOmission').val(dataResult.dropdownDataOmission);
    }
    if (typeof dataResult.checkboxPreserveDateRange !== "undefined") {
        window.newValuesFormTab3.checkboxPreserveDateRange =
            window.newValuesFormTab1.checkboxPreserveDateRange =
            dataResult.checkboxPreserveDateRange;
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'checkboxPreserveDateRange').val(dataResult.checkboxPreserveDateRange);
    }
    if (typeof dataResult.dropdownModel !== "undefined") {
        window.newValuesFormTab3.dropdownModel =
            window.newValuesFormTab1.dropdownModel =
            dataResult.dropdownModel;
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownModel').val(dataResult.dropdownModel);
    }
    if (typeof dataResult.dropdownFilterDirection !== "undefined") {
        window.newValuesFormTab3.dropdownFilterDirection =
            window.newValuesFormTab1.dropdownFilterDirection =
            dataResult.dropdownFilterDirection;
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownFilterDirection').val(dataResult.dropdownFilterDirection);
    }
    if (typeof dataResult.dropdownFactorsData !== "undefined") {
        window.newValuesFormTab3.dropdownFactorsData =
            window.newValuesFormTab1.dropdownFactorsData =
            dataResult.dropdownFactorsData;
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownFactorsData').val(dataResult.dropdownFactorsData);
    }
    if (typeof dataResult.dropdownCentroidInitialization !== "undefined") {
        window.newValuesFormTab3.dropdownCentroidInitialization =
            window.newValuesFormTab1.dropdownCentroidInitialization =
            dataResult.dropdownCentroidInitialization;
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownCentroidInitialization').val(dataResult.dropdownCentroidInitialization);
    }
    if (typeof dataResult.dropdownClusterizationModel !== "undefined") {
        window.newValuesFormTab3.dropdownClusterizationModel =
            window.newValuesFormTab1.dropdownClusterizationModel =
            dataResult.dropdownClusterizationModel;
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownClusterizationModel').val(dataResult.dropdownClusterizationModel);
    }
    if (typeof dataResult.numberBoxFilterThreshold !== "undefined") {
        $('#numberBoxFilterThreshold > input:nth-child(1)').val(dataResult.numberBoxFilterThreshold);
    }
    if (typeof dataResult.numberBoxClustersNumber !== "undefined") {
        $('#numberBoxClustersNumber > input:nth-child(1)').val(dataResult.numberBoxClustersNumber);
    }
    if (typeof dataResult.numberBoxEpsilon !== "undefined") {
        $('#numberBoxEpsilon > input:nth-child(1)').val(dataResult.numberBoxEpsilon);
    }
    if (typeof dataResult.numberBoxMinItemsPerCluster !== "undefined") {
        $('#numberBoxMinItemsPerCluster > input:nth-child(1)').val(dataResult.numberBoxMinItemsPerCluster);
    }

    /*Set data for the tab4*/
    if (typeof dataResult.wordsList !== "undefined") {
        //wordsListSelected
        window.sampleFormWords.val({
            isChange4: window.sampleFormWords.jqxForm('getComponentByName', 'isChangeTab4').val(),
            wordsList: dataResult.wordsList
        });
    }
    if (typeof dataResult.checkboxPOSAcronymValue !== "undefined") {
        window.newValuesFormTab4.checkboxPOSAcronymValue =
            window.newValuesFormTab1.checkboxPOSAcronymValue =
            dataResult.checkboxPOSAcronymValue;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSAcronym').val(dataResult.checkboxPOSAcronymValue)
    }
    if (typeof dataResult.checkboxPOSGeoValue !== "undefined") {
        window.newValuesFormTab4.checkboxPOSGeoValue =
            window.newValuesFormTab1.checkboxPOSGeoValue =
            dataResult.checkboxPOSGeoValue;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSGeo').val(dataResult.checkboxPOSGeoValue)
    }
    if (typeof dataResult.dropdownUniqueWords !== "undefined") {
        window.newValuesFormTab4.dropdownUniqueWords =
            window.newValuesFormTab1.dropdownUniqueWords =
            dataResult.dropdownUniqueWords;
        window.sampleFormWords.jqxForm('getComponentByName', 'dropdownUniqueWords').val(dataResult.dropdownUniqueWords)
    }
    if (typeof dataResult.dropdownSortOrderWords !== "undefined") {
        window.newValuesFormTab4.dropdownSortOrderWords =
            window.newValuesFormTab1.dropdownSortOrderWords =
            dataResult.dropdownSortOrderWords;
        window.sampleFormWords.jqxForm('getComponentByName', 'dropdownSortOrderWords').val(dataResult.dropdownSortOrderWords)
    }

    //Satellites Type Mask
    if (typeof dataResult.checkboxMultiWordSatellitesTypeMask !== "undefined") {
        window.newValuesFormTab4.checkboxMultiWordSatellitesTypeMask =
            window.newValuesFormTab1.checkboxMultiWordSatellitesTypeMask =
            dataResult.checkboxMultiWordSatellitesTypeMask;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxMultiWordSatellitesTypeMask').val(dataResult.checkboxMultiWordSatellitesTypeMask)
    }
    if (typeof dataResult.checkboxProperSatellitesTypeMask !== "undefined") {
        window.newValuesFormTab4.checkboxProperSatellitesTypeMask =
            window.newValuesFormTab1.checkboxProperSatellitesTypeMask =
            dataResult.checkboxProperSatellitesTypeMask;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxProperSatellitesTypeMask').val(dataResult.checkboxProperSatellitesTypeMask)
    }
    if (typeof dataResult.checkboxNormalizedSatellitesTypeMask !== "undefined") {
        window.newValuesFormTab4.checkboxNormalizedSatellitesTypeMask =
            window.newValuesFormTab1.checkboxNormalizedSatellitesTypeMask =
            dataResult.checkboxNormalizedSatellitesTypeMask;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNormalizedSatellitesTypeMask').val(dataResult.checkboxNormalizedSatellitesTypeMask)
    }
    if (typeof dataResult.checkboxReadableSatellitesTypeMask !== "undefined") {
        window.newValuesFormTab4.checkboxReadableSatellitesTypeMask =
            window.newValuesFormTab1.checkboxReadableSatellitesTypeMask =
            dataResult.checkboxReadableSatellitesTypeMask;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxReadableSatellitesTypeMask').val(dataResult.checkboxReadableSatellitesTypeMask)
    }
    if (typeof dataResult.checkboxCommonSatellitesTypeMask !== "undefined") {
        window.newValuesFormTab4.checkboxCommonSatellitesTypeMask =
            window.newValuesFormTab1.checkboxCommonSatellitesTypeMask =
            dataResult.checkboxCommonSatellitesTypeMask;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxCommonSatellitesTypeMask').val(dataResult.checkboxCommonSatellitesTypeMask)
    }
    if (typeof dataResult.checkboxNumbersSatellitesTypeMask !== "undefined") {
        window.newValuesFormTab4.checkboxNumbersSatellitesTypeMask =
            window.newValuesFormTab1.checkboxNumbersSatellitesTypeMask =
            dataResult.checkboxNumbersSatellitesTypeMask;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxNumbersSatellitesTypeMask').val(dataResult.checkboxNumbersSatellitesTypeMask)
    }

    if (typeof dataResult.checkboxPOSPersonNameValue !== "undefined") {
        window.newValuesFormTab4.checkboxPOSPersonNameValue =
            window.newValuesFormTab1.checkboxPOSPersonNameValue =
            dataResult.checkboxPOSPersonNameValue;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSPersonName').val(dataResult.checkboxPOSPersonNameValue)
    }
    if (typeof dataResult.checkboxPOSWikiValue !== "undefined") {
        window.newValuesFormTab4.checkboxPOSWikiValue =
            window.newValuesFormTab1.checkboxPOSWikiValue =
            dataResult.checkboxPOSWikiValue;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxPOSWiki').val(dataResult.checkboxPOSWikiValue)
    }
    if (typeof dataResult.checkboxWordsGroupSubentries !== "undefined") {
        window.newValuesFormTab4.checkboxWordsGroupSubentries =
            window.newValuesFormTab1.checkboxWordsGroupSubentries =
            dataResult.checkboxWordsGroupSubentries;
        window.sampleFormWords.jqxForm('getComponentByName', 'checkboxWordsGroupSubentries').val(dataResult.checkboxWordsGroupSubentries)
    }
    if (typeof dataResult.checkboxValueIndicatorLimitsStrict !== "undefined") {
        //window.newValuesFormTab4.checkboxValueIndicatorLimitsStrict =
        window.newValuesFormTab1.checkboxValueIndicatorLimitsStrict =
            dataResult.checkboxValueIndicatorLimitsStrict;
        window.sampleForm.jqxForm('getComponentByName', 'checkboxValueIndicatorLimitsStrict').val(dataResult.checkboxValueIndicatorLimitsStrict)
    }
    if (typeof dataResult.textBoxValueSearchWord !== "undefined") {
        window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val(dataResult.textBoxValueSearchWord);
    }
    //console.log(dataResult.synonymsWordsCustom);
    if (typeof dataResult.synonymsWordsCustom !== "undefined") {
        window.newValuesFormTab1.synonymsWordsCustom =
        window.newValuesFormTab4.synonymsWordsCustom =
        dataResult.synonymsWordsCustom;
    $('#synonymsWordsCustom').val(dataResult.synonymsWordsCustom);
    }
    if (typeof dataResult.textBoxValueExcludeWord !== "undefined") {
        window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueExcludeWord').val(dataResult.textBoxValueExcludeWord);
    }
    if (typeof dataResult.selectedTab !== "undefined") {
        setSelectedTab(dataResult.selectedTab);
    }
}

function separationScaleSmoothValuesForBackwardCompatibility(valueVScale, valueVSmooth) {
    var retObj = {};
    if (typeof valueVSmooth !== "undefined") {
        retObj.valueVSmooth = valueVSmooth;
        if (typeof valueVScale !== "undefined") {
            retObj.valueVScale = valueVScale;
        } else {
            retObj.valueVScale = window.formInitData.dropdownValueVScale;
        }
    } else {
        if (typeof valueVScale !== "undefined") {
            if ((valueVScale == 0)
                || (valueVScale == 4)
                || (valueVScale == 5)
                || (valueVScale == 7)
                || (valueVScale == 8)
                || (valueVScale == 9)
            ) {
                retObj.valueVScale = 1;
                retObj.valueVSmooth = valueVScale;
            } else {
                retObj.valueVScale = valueVScale;
                retObj.valueVSmooth = window.formInitData.dropdownValueVSmooth;
            }
        } else {
            retObj.valueVScale = 1;
            retObj.valueVSmooth = window.formInitData.dropdownValueVSmooth;
        }
    }
    return retObj;
}

/**
 * Get initial shared values by hash from the saved file
 * @param hash
 * @returns
 */
function initShareValuesForm(hash, postFormData) {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    if (typeof window.newValuesFormTab1 === "undefined") {
        window.newValuesFormTab1 = {};
    }
    if (typeof window.newValuesFormTab2 === "undefined") {
        window.newValuesFormTab2 = {};
    }
    if (typeof window.newValuesFormTab3 === "undefined") {
        window.newValuesFormTab3 = {};
    }
    if (typeof window.newValuesFormTab4 === "undefined") {
        window.newValuesFormTab4 = {};
    }
    retObj = {
        chartType: chartTypeDefault,
        checkboxConnectorsLinesValue: true,
        checkboxIndentValuesRelatedValue: false,
        checkboxNotIncludeSingleNodesValue: false,
        checkboxReduceOverlapValue: false,
        checkboxShowHideLegendValue: true,
        checkboxShowHideSeriesLabelValue: true,
        checkboxShowHideTitleValue: true,
        checkboxAddNodesFixLevel: true,
        checkboxShowHideMenuValue: false,
        checkboxShowHideTableHeaderValue: true,
        checkboxOrientationHorizontalVertical: false,
        checkboxShowHideMetadataColumnValue: true,
        checkboxShowHidePaginationValue: true,
        checkboxConnectNullsValue: 2,
        dropdownConnectorLinesSourceValue: 0,
        checkboxSourceDataPointsValue: false,
        checkboxAxisTurnOnOffValue: true,
        circleSizeMult: 1,
        fontSizeMult: 1,
        sourcePointsSizeMult: 1,
        lineWidthMult: 1,
        dropdownMaxNodes: 100,
        dropdownAutoAdjust: 1,
        dropdownChartView: 1,
        dropdownAutoAdjustCnt: 250,
        dropdownValueDSource: sourceDefault,
        dropdownValueDSource2: sourceDefault,
        dropdownDatesInterval: 0,
        dropdownValueIWords: 0,
        dropdownDaysInterval: 0,
        dropdownMinIntersectedNeighbors: 0,
        dropdownSortDailyWordsBy: 0,
        satellitesOrderCondition: 0,
        dropdownDataSelectCache: 0,
        dropdownUniqueWords: 0,
        dropdownSortOrderWords: 0,
        dropdownValueMaxDepth: '',
        dropdownMaxDistance: 0,
        dropdownValueVIndicator: valueDefault,
        dropdownsSortField: sortFieldDefault,
        dropdownValueVScale: 1,
        dropdownValueVSmooth: 0,
        dropdownDataOmission: 0,
        checkboxPreserveDateRange: false,
        dropdownColorsSelection: 1,
        dropdownConnectorLineWidthIndicator: 1,
        dropdownConnectorLinesColor: 2,
        dropdownColorsSet: 0,
        dropdownChartBackgroundColor: 0,
        dropdownChartTextColor: 0,
        changedCTColor: '',
        dropdownBallsText: 1,
        dropdownBallsTextLayout: 1,
        dropdownFilterModel: 0,
        checkboxAppearanceFullTimeValue: 0,
        gravityValue: 0.13,
        indentValue: -300,
        nubmberBoxValue1: 50,
        nubmberBoxValue2: '',
        checkboxRepeatSelectionMinMaxUnlim: false,

        //Relations Type Mask
        checkboxPWIntersectedNeighbors: true,
        checkboxPW1inNeighbors2: false,
        checkboxPW2inNeighbors1: false,
        checkboxIntersectedNeighbors: false,
        checkboxArticlesList: false,

        relationsModeMask: 0,

        nubmberBoxNeighborsFreqMin: '',
        nubmberBoxNeighborsFreqMax: '',
        nubmberBoxSatellitesFreqMin: '',
        nubmberBoxSatellitesFreqMax: '',
        nubmberBoxAppearanceMin: 50,
        nubmberBoxAppearanceMax: '',
        nubmberBoxValueMaxWordsTotal: 20,
        nubmberBoxNeighborsMaxItems: 5,
        nubmberBoxValueMaxWordsTotalTextual: 1000,
        nubmberBoxValueFontSize: '',
        numberBoxDegree: 4,
        numberBoxFilterThreshold: 0.65,
        numberBoxClustersNumber: 2,
        numberBoxEpsilon: 100,
        numberBoxMinItemsPerCluster: 1,
        textBoxValueSearchWord: '',
        synonymsWordsCustom: '',
        textBoxValueExcludeWord: '',
        textBoxTitleTimeCharts: '',
        textBoxSubtitleTimeCharts: 'Data source: %DATA_SOURCE% (%CHART_VIEW%)',
        textBoxDescriptionTimeCharts: 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%',
        radiusValue: 1,
        textBoxValueDatesRange: '',
        textBoxValueExcludePeriods: '',
        textBoxValueMaxWordsTotal: 100,
        wordsList: {},
        wordsListDataArr: {},
        filterPOS: 0,
        checkboxesSatellitesTypeMask: 0,
        checkboxPOSAcronymValue: false,
        checkboxPOSGeoValue: false,

        //Satellites Type Mask
        checkboxMultiWordSatellitesTypeMask: false,
        checkboxProperSatellitesTypeMask: false,
        checkboxNormalizedSatellitesTypeMask: false,
        checkboxReadableSatellitesTypeMask: false,
        checkboxCommonSatellitesTypeMask: false,
        checkboxNumbersSatellitesTypeMask: false,

        checkboxPOSPersonNameValue: false,
        checkboxPOSWikiValue: false,
        checkboxWordsGroupSubentries: false,
        checkboxValueIndicatorLimitsStrict: false,
        fixedNodesArr: {},
        svgHeight: 0,
        svgWidth: 0,
        changedColors: {},
        hiddenLegendItems: [],
        drawingValueIndicators: [1]
    }
    window.formInitData = {
        chartType: chartTypeDefault,
        checkboxConnectorsLinesValue: true,
        checkboxIndentValuesRelatedValue: false,
        checkboxNotIncludeSingleNodesValue: false,
        checkboxReduceOverlapValue: false,
        checkboxShowHideLegendValue: true,
        checkboxShowHideSeriesLabelValue: true,
        checkboxShowHideTitleValue: true,
        checkboxAddNodesFixLevel: true,
        checkboxShowHideMenuValue: false,
        checkboxShowHideTableHeaderValue: true,
        checkboxOrientationHorizontalVertical: false,
        checkboxShowHideMetadataColumnValue: true,
        checkboxShowHidePaginationValue: true,
        checkboxConnectNullsValue: 2,
        dropdownConnectorLinesSourceValue: 0,
        checkboxSourceDataPointsValue: false,
        checkboxAxisTurnOnOffValue: true,
        circleSizeMult: 1,
        fontSizeMult: 1,
        sourcePointsSizeMult: 1,
        lineWidthMult: 1,
        dropdownMaxNodes: 100,
        dropdownAutoAdjust: 1,
        dropdownChartView: 1,
        dropdownAutoAdjustCnt: 250,
        dropdownValueDSource: sourceDefault,
        dropdownValueDSource2: sourceDefault,
        dropdownDatesInterval: 0,
        dropdownValueIWords: 0,
        dropdownDaysInterval: 0,
        dropdownMinIntersectedNeighbors: 0,
        dropdownSortDailyWordsBy: 0,
        satellitesOrderCondition: 0,
        dropdownDataSelectCache: 0,
        dropdownUniqueWords: 0,
        dropdownSortOrderWords: 0,
        dropdownValueMaxDepth: '',
        dropdownMaxDistance: 0,
        dropdownValueVIndicator: valueDefault,
        dropdownsSortField: sortFieldDefault,
        dropdownValueVScale: 1,
        dropdownValueVSmooth: 0,
        dropdownDataOmission: 0,
        checkboxPreserveDateRange: false,
        dropdownColorsSelection: 1,
        dropdownConnectorLineWidthIndicator: 1,
        dropdownConnectorLinesColor: 2,
        dropdownColorsSet: 0,
        dropdownChartBackgroundColor: 0,
        dropdownChartTextColor: 0,
        changedCTColor: '',
        dropdownBallsText: 1,
        dropdownBallsTextLayout: 1,
        dropdownFilterModel: 0,
        checkboxAppearanceFullTimeValue: 0,
        gravityValue: 0.13,
        indentValue: -300,
        nubmberBoxValue1: 50,
        nubmberBoxValue2: '',
        checkboxRepeatSelectionMinMaxUnlim: false,
        //Relations Type Mask
        checkboxPWIntersectedNeighbors: true,
        checkboxPW1inNeighbors2: false,
        checkboxPW2inNeighbors1: false,
        checkboxIntersectedNeighbors: false,
        checkboxArticlesList: false,

        relationsModeMask: 0,

        nubmberBoxNeighborsFreqMin: '',
        nubmberBoxNeighborsFreqMax: '',
        nubmberBoxSatellitesFreqMin: '',
        nubmberBoxSatellitesFreqMax: '',
        nubmberBoxAppearanceMin: 50,
        nubmberBoxAppearanceMax: '',
        nubmberBoxValueMaxWordsTotal: 20,
        nubmberBoxNeighborsMaxItems: 5,
        nubmberBoxValueMaxWordsTotalTextual: 1000,
        nubmberBoxValueFontSize: '',
        numberBoxDegree: 4,
        numberBoxFilterThreshold: 0.65,
        numberBoxClustersNumber: 2,
        numberBoxEpsilon: 100,
        numberBoxMinItemsPerCluster: 1,
        textBoxValueSearchWord: '',
        synonymsWordsCustom: '',
        textBoxValueExcludeWord: '',
        textBoxTitleTimeCharts: '',
        textBoxSubtitleTimeCharts: 'Data source: %DATA_SOURCE% (%CHART_VIEW%)',
        textBoxDescriptionTimeCharts: 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%',
        radiusValue: 1,
        textBoxValueDatesRange: '',
        textBoxValueExcludePeriods: '',
        textBoxValueMaxWordsTotal: 100,
        wordsList: {},
        filterPOS: 0,
        checkboxesSatellitesTypeMask: 0,
        checkboxPOSAcronymValue: false,
        checkboxPOSGeoValue: false,

        //Satellites Type Mask
        checkboxMultiWordSatellitesTypeMask: false,
        checkboxProperSatellitesTypeMask: false,
        checkboxNormalizedSatellitesTypeMask: false,
        checkboxReadableSatellitesTypeMask: false,
        checkboxCommonSatellitesTypeMask: false,
        checkboxNumbersSatellitesTypeMask: false,

        checkboxPOSPersonNameValue: false,
        checkboxPOSWikiValue: false,
        checkboxWordsGroupSubentries: false,
        checkboxValueIndicatorLimitsStrict: false,
        hiddenLegendItems: [],
        drawingValueIndicators: [1]
    };
    if ((hash == '') && (typeof postFormData !== "undefined")) {
        var data = {};
        data.result = postFormData;
        setTimeout(function () {
            setDataToFormValues(data);
        }, 300);
    } else {
        $.ajax({
            // the route pointing to the post function 
            url: '/getshare',
            type: 'POST',
            // send the csrf-token and the input to the controller 
            data: {
                _token: CSRF_TOKEN,
                share: hash
            },
            dataType: 'JSON',
            // remind that 'data' is the response of the AjaxController
            success: function (data) {
                if (data.result == null) {
                    cleanup();
                    // hide preloader on ajax error
                    if (isWidget == 1) {
                        $('#jqxLoaderWidget').jqxLoader('close');
                    } else {
                        $('#jqxLoader').jqxLoader('close');
                    }

                    if (typeof retObj.checkboxConnectNullsValue !== "undefined") {
                        if ((retObj.checkboxConnectNullsValue === true) || (retObj.checkboxConnectNullsValue == "true")) {
                            retObj.checkboxConnectNullsValue = 2;
                        }
                        if ((retObj.checkboxConnectNullsValue === false) || (retObj.checkboxConnectNullsValue == "false")) {
                            retObj.checkboxConnectNullsValue = 1;
                        }
                    }
                    window.formInitData = retObj;
                    isShare = false;
                    createLayout();
                    $('#ErrorListContaner').text(data.errMsg);
                    $('#dataContaner').text('The shared data configuration file does not exist. Chart on shared settings can not be displayed');
                    setShareValuesForm(retObj);
                    firstLoadChart = 1;
                    var yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    var setYesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
                    var weekAgo = new Date();
                    weekAgo.setDate(weekAgo.getDate() - 7);
                    var setWeekAgo = new Date(weekAgo.getFullYear(), weekAgo.getMonth(), weekAgo.getDate());
                    $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', setWeekAgo, setYesterday);
                } else {
                    setDataToFormValues(data);
                }
            },
            error: function (data) {
                $('#ErrorListContaner').text(data.errMsg);
                cleanup();
                // hide preloader on ajax error
                if (isWidget == 1) {
                    $('#jqxLoaderWidget').jqxLoader('close');
                } else {
                    $('#jqxLoader').jqxLoader('close');
                }
            }
        });
    }
}

function setDataToFormValues(data) {
    if (isDebugg) { console.log(data.result); }
    /*Set data for the chart type*/
    if (typeof data.result.chartType !== "undefined") {
        retObj.chartType = data.result.chartType;
        window.newValuesFormTab1.chartType = data.result.chartType;

    }
    /*Set data for the chart type*/
    if (firstLoadChart == 1 && formDataStr != '') {
        if (typeof data.result.chartType !== "undefined") {
            var chartType = data.result.chartType;
        }
        if (($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) || (window.formDataStrSave.chartType == CHART_TYPE_TEXTUALWORDS)) {
            retObj.chartType = CHART_TYPE_TIMESERIES;
            data.result.chartType = CHART_TYPE_TIMESERIES;
            window.newValuesFormTab1.chartType = data.result.chartType;
            $('#chartType').val(CHART_TYPE_TIMESERIES);
            chartType = CHART_TYPE_TIMESERIES;
        }
    }

    /*Set data for the tab1 (Data select)*/
    if (typeof data.result.dropdownValueDSource !== "undefined") {
        retObj.dropdownValueDSource = data.result.dropdownValueDSource;
        window.newValuesFormTab1.dropdownValueDSource = data.result.dropdownValueDSource;

        var dropdownValueDSourceVal = data.result.dropdownValueDSource;

        if (typeof namesIndicatorsBySources[dropdownValueDSourceVal] !== "undefined") {
            namesIndicators = namesIndicatorsBySources[dropdownValueDSourceVal];
        } else if (typeof namesIndicatorsBySources.namesDef !== "undefined") {
            namesIndicators = namesIndicatorsBySources.namesDef;
        } else {
            namesIndicators = {};
        }

        if (typeof chartTypesConfigurationBySources[dropdownValueDSourceVal] !== "undefined") {
            chartTypesConfiguration = chartTypesConfigurationBySources[dropdownValueDSourceVal];
        } else if (typeof chartTypesConfigurationBySources.chartTypesConfigurationDef !== "undefined") {
            chartTypesConfiguration = chartTypesConfigurationBySources.chartTypesConfigurationDef;
        } else {
            chartTypesConfiguration = {};
        }

        if (typeof data.result.chartType !== "undefined") {
            var chartType = data.result.chartType;
            var selectedIndex = getIdBychartType(chartType);
        }
        setChartTypesConfiguration(selectedIndex);
    }
    if (typeof data.result.metadataWord !== "undefined") {
        retObj.metadataWord = data.result.metadataWord;
        window.newValuesFormTab1.metadataWord = data.result.metadataWord;
    }
    if (typeof data.result.metadataDate !== "undefined") {
        retObj.metadataDate = data.result.metadataDate;
        window.newValuesFormTab1.metadataDate = data.result.metadataDate;
    }

    if (typeof data.result.textualWordsGridPagenum != "undefined") {
        retObj.textualWordsGridPagenum = data.result.textualWordsGridPagenum;
        window.newValuesFormTab1.textualWordsGridPagenum = data.result.textualWordsGridPagenum;
    }
    if (typeof data.result.textualWordsGridPagesize != "undefined") {
        retObj.textualWordsGridPagesize = data.result.textualWordsGridPagesize;
        window.newValuesFormTab1.textualWordsGridPagesize = data.result.textualWordsGridPagesize;
    }

    if (typeof data.result.dropdownValueDSource2 !== "undefined") {
        retObj.dropdownValueDSource2 = data.result.dropdownValueDSource2;
        window.newValuesFormTab1.dropdownValueDSource2 = data.result.dropdownValueDSource2;
    }
    if (typeof data.result.dropdownDatesInterval !== "undefined") {
        retObj.dropdownDatesInterval = data.result.dropdownDatesInterval;
        window.newValuesFormTab1.dropdownDatesInterval = data.result.dropdownDatesInterval;
    }
    if (typeof data.result.textBoxValueDatesRange !== "undefined") {
        retObj.textBoxValueDatesRange = data.result.textBoxValueDatesRange;
        window.newValuesFormTab1.textBoxValueDatesRange = data.result.textBoxValueDatesRange;
    }
    if (typeof data.result.textBoxValueExcludePeriods !== "undefined") {
        retObj.textBoxValueExcludePeriods = data.result.textBoxValueExcludePeriods;
        window.newValuesFormTab1.textBoxValueExcludePeriods = data.result.textBoxValueExcludePeriods;
    }
    if (typeof data.result.nubmberBoxValue1 !== "undefined") {
        retObj.nubmberBoxValue1 = data.result.nubmberBoxValue1;
        window.newValuesFormTab1.nubmberBoxValue1 = data.result.nubmberBoxValue1;
    }
    if (typeof data.result.nubmberBoxValue2 !== "undefined") {
        retObj.nubmberBoxValue2 = data.result.nubmberBoxValue2;
        window.newValuesFormTab1.nubmberBoxValue2 = data.result.nubmberBoxValue2;
    }
    if (typeof data.result.checkboxRepeatSelectionMinMaxUnlim !== "undefined") {
        retObj.checkboxRepeatSelectionMinMaxUnlim = data.result.checkboxRepeatSelectionMinMaxUnlim;
        window.newValuesFormTab1.checkboxRepeatSelectionMinMaxUnlim = data.result.checkboxRepeatSelectionMinMaxUnlim;
    }
    //Relations Type Mask
    if (typeof data.result.checkboxPWIntersectedNeighbors !== "undefined") {
        retObj.checkboxPWIntersectedNeighbors = data.result.checkboxPWIntersectedNeighbors;
        window.newValuesFormTab1.checkboxPWIntersectedNeighbors = data.result.checkboxPWIntersectedNeighbors;
    }
    if (typeof data.result.checkboxPW1inNeighbors2 !== "undefined") {
        retObj.checkboxPW1inNeighbors2 = data.result.checkboxPW1inNeighbors2;
        window.newValuesFormTab1.checkboxPW1inNeighbors2 = data.result.checkboxPW1inNeighbors2;
    }
    if (typeof data.result.checkboxPW2inNeighbors1 !== "undefined") {
        retObj.checkboxPW2inNeighbors1 = data.result.checkboxPW2inNeighbors1;
        window.newValuesFormTab1.checkboxPW2inNeighbors1 = data.result.checkboxPW2inNeighbors1;
    }
    if (typeof data.result.checkboxIntersectedNeighbors !== "undefined") {
        retObj.checkboxIntersectedNeighbors = data.result.checkboxIntersectedNeighbors;
        window.newValuesFormTab1.checkboxIntersectedNeighbors = data.result.checkboxIntersectedNeighbors;
    }
    if (typeof data.result.checkboxArticlesList !== "undefined") {
        retObj.checkboxArticlesList = data.result.checkboxArticlesList;
        window.newValuesFormTab1.checkboxArticlesList = data.result.checkboxArticlesList;
    }


    if (typeof data.result.nubmberBoxNeighborsFreqMin !== "undefined") {
        retObj.nubmberBoxNeighborsFreqMin = data.result.nubmberBoxNeighborsFreqMin;
        window.newValuesFormTab1.nubmberBoxNeighborsFreqMin = data.result.nubmberBoxNeighborsFreqMin;
    }
    if (typeof data.result.nubmberBoxNeighborsFreqMax !== "undefined") {
        retObj.nubmberBoxNeighborsFreqMax = data.result.nubmberBoxNeighborsFreqMax;
        window.newValuesFormTab1.nubmberBoxNeighborsFreqMax = data.result.nubmberBoxNeighborsFreqMax;
    }
    if (typeof data.result.nubmberBoxSatellitesFreqMin !== "undefined") {
        retObj.nubmberBoxSatellitesFreqMin = data.result.nubmberBoxSatellitesFreqMin;
        window.newValuesFormTab1.nubmberBoxSatellitesFreqMin = data.result.nubmberBoxSatellitesFreqMin;
    }
    if (typeof data.result.nubmberBoxSatellitesFreqMax !== "undefined") {
        retObj.nubmberBoxSatellitesFreqMax = data.result.nubmberBoxSatellitesFreqMax;
        window.newValuesFormTab1.nubmberBoxSatellitesFreqMax = data.result.nubmberBoxSatellitesFreqMax;
    }
    if (typeof data.result.nubmberBoxAppearanceMin !== "undefined") {
        retObj.nubmberBoxAppearanceMin = data.result.nubmberBoxAppearanceMin;
        window.newValuesFormTab1.nubmberBoxAppearanceMin = data.result.nubmberBoxAppearanceMin;
    }
    if (typeof data.result.nubmberBoxAppearanceMax !== "undefined") {
        retObj.nubmberBoxAppearanceMax = data.result.nubmberBoxAppearanceMax;
        window.newValuesFormTab1.nubmberBoxAppearanceMax = data.result.nubmberBoxAppearanceMax;
    }
    if (typeof data.result.nubmberBoxValueMaxWordsTotal !== "undefined") {
        retObj.nubmberBoxValueMaxWordsTotal = data.result.nubmberBoxValueMaxWordsTotal;
        window.newValuesFormTab1.nubmberBoxValueMaxWordsTotal = data.result.nubmberBoxValueMaxWordsTotal;
    }
    if (typeof data.result.nubmberBoxNeighborsMaxItems !== "undefined") {
        retObj.nubmberBoxNeighborsMaxItems = data.result.nubmberBoxNeighborsMaxItems;
        window.newValuesFormTab1.nubmberBoxNeighborsMaxItems = data.result.nubmberBoxNeighborsMaxItems;
    }
    if (typeof data.result.textBoxValueSearchWord !== "undefined") {
        retObj.textBoxValueSearchWord = data.result.textBoxValueSearchWord;
        window.newValuesFormTab1.textBoxValueSearchWord = data.result.textBoxValueSearchWord;
    }
    if (typeof data.result.synonymsWordsCustom !== "undefined") {
        retObj.synonymsWordsCustom = data.result.synonymsWordsCustom;
        window.newValuesFormTab1.synonymsWordsCustom = window.newValuesFormTab4.synonymsWordsCustom = data.result.synonymsWordsCustom;
    }
    if (typeof data.result.textBoxValueExcludeWord !== "undefined") {
        retObj.textBoxValueExcludeWord = data.result.textBoxValueExcludeWord;
        window.newValuesFormTab1.textBoxValueExcludeWord = data.result.textBoxValueExcludeWord;
    }
    if (typeof data.result.dropdownValueIWords !== "undefined") {
        retObj.dropdownValueIWords = data.result.dropdownValueIWords;
        window.newValuesFormTab1.dropdownValueIWords = data.result.dropdownValueIWords;
    }
    if (typeof data.result.dropdownDaysInterval !== "undefined") {
        retObj.dropdownDaysInterval = data.result.dropdownDaysInterval;
        window.newValuesFormTab1.dropdownDaysInterval = data.result.dropdownDaysInterval;
    }
    if (typeof data.result.dropdownMinIntersectedNeighbors !== "undefined") {
        retObj.dropdownMinIntersectedNeighbors = data.result.dropdownMinIntersectedNeighbors;
        window.newValuesFormTab1.dropdownMinIntersectedNeighbors = data.result.dropdownMinIntersectedNeighbors;
    }
    if (typeof data.result.dropdownSortDailyWordsBy !== "undefined") {
        retObj.dropdownSortDailyWordsBy = data.result.dropdownSortDailyWordsBy;
        window.newValuesFormTab1.dropdownSortDailyWordsBy = data.result.dropdownSortDailyWordsBy;
    }
    if (typeof data.result.satellitesOrderCondition !== "undefined") {
        retObj.satellitesOrderCondition = data.result.satellitesOrderCondition;
        window.newValuesFormTab1.satellitesOrderCondition = data.result.satellitesOrderCondition;
    }
    if (typeof data.result.dropdownDataSelectCache !== "undefined") {
        retObj.dropdownDataSelectCache = data.result.dropdownDataSelectCache;
        window.newValuesFormTab1.dropdownDataSelectCache = data.result.dropdownDataSelectCache;
    }
    if (typeof data.result.dropdownValueMaxDepth !== "undefined") {
        retObj.dropdownValueMaxDepth = data.result.dropdownValueMaxDepth;
        window.newValuesFormTab1.dropdownValueMaxDepth = data.result.dropdownValueMaxDepth;
    }
    if (typeof data.result.dropdownMaxDistance !== "undefined") {
        retObj.dropdownMaxDistance = data.result.dropdownMaxDistance;
        window.newValuesFormTab1.dropdownMaxDistance = data.result.dropdownMaxDistance;
    }
    if (typeof data.result.dropdownValueVIndicator !== "undefined") {
        retObj.dropdownValueVIndicator = data.result.dropdownValueVIndicator;
        window.newValuesFormTab1.dropdownValueVIndicator = data.result.dropdownValueVIndicator;
    }
    if (typeof data.result.dropdownsSortField !== "undefined") {
        retObj.dropdownsSortField = data.result.dropdownsSortField;
        window.newValuesFormTab1.dropdownsSortField = data.result.dropdownsSortField;
    }
    if (typeof data.result.checkboxAppearanceFullTimeValue !== "undefined") {
        retObj.checkboxAppearanceFullTimeValue = data.result.checkboxAppearanceFullTimeValue;
        window.newValuesFormTab1.checkboxAppearanceFullTimeValue = data.result.checkboxAppearanceFullTimeValue;
    }
    if (typeof data.result.hiddenLegendItems !== "undefined") {
        if (firstLoadChart == 1 && formDataStr != '') {
            retObj.hiddenLegendItems = data.result.hiddenLegendItems;
        } else {
            retObj.hiddenLegendItems = JSON.parse(data.result.hiddenLegendItems);
            window.newValuesFormTab1.hiddenLegendItems = JSON.parse(data.result.hiddenLegendItems);
            //console.log(data.result.hiddenLegendItems);
        }
    } else {
        retObj.hiddenLegendItems = {};
        window.newValuesFormTab1.hiddenLegendItems = {};
    }
    if (typeof data.result.fixedNodesArr !== "undefined") {
        retObj.fixedNodesArr = data.result.fixedNodesArr;
        window.newValuesFormTab1.fixedNodesArr = data.result.fixedNodesArr;
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        window.molecularData[chartIndex].fixedNodesArr = data.result.fixedNodesArr;
    }
    if (typeof data.result.changedRadLevelColors !== "undefined") {
        retObj.changedRadLevelColors = data.result.changedRadLevelColors;
        window.newValuesFormTab1.changedRadLevelColors = data.result.changedRadLevelColors;
        setChangedRadLevelColors(data.result.changedRadLevelColors);
    }

    //window.newValuesFormTab1
    /*Set data for the tab2 (Drawing)*/
    if (typeof data.result.drawingValueIndicators !== "undefined") {
        if (firstLoadChart == 1 && formDataStr != '') {
            retObj.drawingValueIndicators = data.result.drawingValueIndicators;
            //window.newValuesFormTab1.drawingValueIndicators = JSON.parse(data.result.drawingValueIndicators);
        } else {
            if (typeof chartIndex === "string") {
                retObj.drawingValueIndicators = JSON.parse(data.result.drawingValueIndicators);
            } else {
                retObj.drawingValueIndicators = data.result.drawingValueIndicators;
            }
            window.newValuesFormTab1.drawingValueIndicators = window.newValuesFormTab2.drawingValueIndicators = retObj.drawingValueIndicators;

            //setDrawingValueIndicatorsChecked(retObj.drawingValueIndicators);
        }
    } else {
        retObj.drawingValueIndicators = [1];
        window.newValuesFormTab1.drawingValueIndicators = window.newValuesFormTab2.drawingValueIndicators = retObj.drawingValueIndicators;
    }
    if (typeof data.result.textBoxTitleTimeCharts !== "undefined") {
        retObj.textBoxTitleTimeCharts = data.result.textBoxTitleTimeCharts;
        window.newValuesFormTab1.textBoxTitleTimeCharts = window.newValuesFormTab2.textBoxTitleTimeCharts
            = data.result.textBoxTitleTimeCharts;
    }
    if (typeof data.result.textBoxSubtitleTimeCharts !== "undefined") {
        retObj.textBoxSubtitleTimeCharts = data.result.textBoxSubtitleTimeCharts;
        window.newValuesFormTab1.textBoxSubtitleTimeCharts = window.newValuesFormTab2.textBoxSubtitleTimeCharts
            = data.result.textBoxSubtitleTimeCharts;
    }
    if (typeof data.result.textBoxDescriptionTimeCharts !== "undefined") {
        retObj.textBoxDescriptionTimeCharts = data.result.textBoxDescriptionTimeCharts;
        window.newValuesFormTab1.textBoxTitleTimeCharts = window.newValuesFormTab2.textBoxDescriptionTimeCharts
            = data.result.textBoxDescriptionTimeCharts;
    }
    if (typeof data.result.nubmberBoxValueMaxWordsTotalTextual !== "undefined") {
        retObj.nubmberBoxValueMaxWordsTotalTextual = data.result.nubmberBoxValueMaxWordsTotalTextual;
        window.newValuesFormTab1.nubmberBoxValueMaxWordsTotalTextual
            = window.newValuesFormTab2.nubmberBoxValueMaxWordsTotalTextual
            = data.result.nubmberBoxValueMaxWordsTotalTextual;
    }
    if (typeof data.result.nubmberBoxValueFontSize !== "undefined") {
        retObj.nubmberBoxValueFontSize = data.result.nubmberBoxValueFontSize;
        window.newValuesFormTab1.nubmberBoxValueFontSize
            = window.newValuesFormTab2.nubmberBoxValueFontSize
            = data.result.nubmberBoxValueFontSize;
    }

    if (typeof data.result.dropdownMaxNodes !== "undefined") {
        retObj.dropdownMaxNodes = data.result.dropdownMaxNodes;
        window.newValuesFormTab2.dropdownMaxNodes = window.newValuesFormTab1.dropdownMaxNodes
            = data.result.dropdownMaxNodes;
    }
    if (typeof data.result.dropdownColorsSelection !== "undefined") {
        retObj.dropdownColorsSelection = data.result.dropdownColorsSelection;
        window.newValuesFormTab2.dropdownColorsSelection = window.newValuesFormTab1.dropdownColorsSelection
            = data.result.dropdownColorsSelection;
    }
    if (typeof data.result.dropdownConnectorLineWidthIndicator !== "undefined") {
        retObj.dropdownConnectorLineWidthIndicator = data.result.dropdownConnectorLineWidthIndicator;
        window.newValuesFormTab2.dropdownConnectorLineWidthIndicator = window.newValuesFormTab1.dropdownConnectorLineWidthIndicator
            = data.result.dropdownConnectorLineWidthIndicator;
    }
    if (typeof data.result.dropdownConnectorLinesColor !== "undefined") {
        retObj.dropdownConnectorLinesColor = data.result.dropdownConnectorLinesColor;
        window.newValuesFormTab2.dropdownConnectorLinesColor = window.newValuesFormTab1.dropdownConnectorLinesColor
            = data.result.dropdownConnectorLinesColor;
    }
    if (typeof data.result.dropdownColorsSet !== "undefined") {
        retObj.dropdownColorsSet = data.result.dropdownColorsSet;
        window.newValuesFormTab2.dropdownColorsSet = window.newValuesFormTab1.dropdownColorsSet
            = data.result.dropdownColorsSet;
    }
    if (typeof data.result.dropdownChartBackgroundColor !== "undefined") {
        retObj.dropdownChartBackgroundColor = data.result.dropdownChartBackgroundColor;
        window.newValuesFormTab2.dropdownChartBackgroundColor = window.newValuesFormTab1.dropdownChartBackgroundColor
            = data.result.dropdownChartBackgroundColor;
    }
    if (typeof data.result.dropdownChartTextColor !== "undefined") {
        retObj.dropdownChartTextColor = data.result.dropdownChartTextColor;
        window.newValuesFormTab2.dropdownChartTextColor = window.newValuesFormTab1.dropdownChartTextColor
            = data.result.dropdownChartTextColor;
    }
    if (typeof data.result.changedCTColor !== "undefined") {
        retObj.changedCTColor = data.result.changedCTColor;
        window.newValuesFormTab2.changedCTColor = window.newValuesFormTab1.changedCTColor
            = data.result.changedCTColor;
        $('.chart-text-color').css('background-color', data.result.changedCTColor);
        $('td.colorColumnChartText .chart-text-new-value').val(data.result.changedCTColor);
    }
    if (typeof data.result.dropdownBallsText !== "undefined") {
        retObj.dropdownBallsText = data.result.dropdownBallsText;
        window.newValuesFormTab2.dropdownBallsText = window.newValuesFormTab1.dropdownBallsText
            = data.result.dropdownBallsText;
    }
    if (typeof data.result.dropdownBallsTextLayout !== "undefined") {
        retObj.dropdownBallsTextLayout = data.result.dropdownBallsTextLayout;
        window.newValuesFormTab2.dropdownBallsTextLayout = window.newValuesFormTab1.dropdownBallsTextLayout
            = data.result.dropdownBallsTextLayout;
    }
    if (typeof data.result.dropdownAutoAdjust !== "undefined") {
        retObj.dropdownAutoAdjust = data.result.dropdownAutoAdjust;
        window.newValuesFormTab2.dropdownAutoAdjust = window.newValuesFormTab1.dropdownAutoAdjust
            = data.result.dropdownAutoAdjust;
    }
    if (typeof data.result.dropdownAutoAdjustCnt !== "undefined") {
        retObj.dropdownAutoAdjustCnt = data.result.dropdownAutoAdjustCnt;
        window.newValuesFormTab2.dropdownAutoAdjustCnt = window.newValuesFormTab1.dropdownAutoAdjustCnt
            = data.result.dropdownAutoAdjustCnt;
    }
    if (typeof data.result.dropdownChartView !== "undefined") {
        retObj.dropdownChartView = data.result.dropdownChartView;
        window.newValuesFormTab2.dropdownChartView = window.newValuesFormTab1.dropdownChartView
            = data.result.dropdownChartView;
    }
    if (typeof data.result.checkboxConnectorsLinesValue !== "undefined") {
        retObj.checkboxConnectorsLinesValue = data.result.checkboxConnectorsLinesValue;
        window.newValuesFormTab2.checkboxConnectorsLinesValue = window.newValuesFormTab1.checkboxConnectorsLinesValue
            = data.result.checkboxConnectorsLinesValue;
    }
    if (typeof data.result.circleSizeMult !== "undefined") {
        retObj.circleSizeMult = data.result.circleSizeMult * 1;
        window.newValuesFormTab2.circleSizeMult = window.newValuesFormTab1.circleSizeMult = data.result.circleSizeMult * 1;
    }
    if (typeof data.result.fontSizeMult !== "undefined") {
        retObj.fontSizeMult = data.result.fontSizeMult * 1;
        window.newValuesFormTab2.fontSizeMult = window.newValuesFormTab1.fontSizeMult = data.result.fontSizeMult * 1;
    }
    if (typeof data.result.sourcePointsSizeMult !== "undefined") {
        retObj.sourcePointsSizeMult = data.result.sourcePointsSizeMult * 1;
        window.newValuesFormTab2.sourcePointsSizeMult = window.newValuesFormTab1.sourcePointsSizeMult = data.result.sourcePointsSizeMult * 1;
    }
    if (typeof data.result.lineWidthMult !== "undefined") {
        retObj.lineWidthMult = data.result.lineWidthMult;
        window.newValuesFormTab2.lineWidthMult = window.newValuesFormTab1.lineWidthMult = data.result.lineWidthMult * 1;
    }
    if (typeof data.result.gravityValue !== "undefined") {
        retObj.gravityValue = data.result.gravityValue;
        window.newValuesFormTab2.gravityValue = window.newValuesFormTab1.gravityValue = data.result.gravityValue * 1;
    }
    if (typeof data.result.indentValue !== "undefined") {
        retObj.indentValue = data.result.indentValue;
        window.newValuesFormTab2.indentValue = window.newValuesFormTab1.indentValue = data.result.indentValue * 1;
    }
    if (typeof data.result.radiusValue !== "undefined") {
        retObj.radiusValue = data.result.radiusValue;
        window.newValuesFormTab2.radiusValue = window.newValuesFormTab1.radiusValue = data.result.radiusValue * 1;
    }
    if (typeof data.result.checkboxIndentValuesRelatedValue !== "undefined") {
        retObj.checkboxIndentValuesRelatedValue = data.result.checkboxIndentValuesRelatedValue;
        window.newValuesFormTab2.checkboxIndentValuesRelatedValue
            = window.newValuesFormTab1.checkboxIndentValuesRelatedValue
            = data.result.checkboxIndentValuesRelatedValue;
    }
    if (typeof data.result.checkboxNotIncludeSingleNodesValue !== "undefined") {
        retObj.checkboxNotIncludeSingleNodesValue = data.result.checkboxNotIncludeSingleNodesValue;
        window.newValuesFormTab2.checkboxNotIncludeSingleNodesValue
            = window.newValuesFormTab1.checkboxNotIncludeSingleNodesValue
            = data.result.checkboxNotIncludeSingleNodesValue;
    }
    if (typeof data.result.checkboxReduceOverlapValue !== "undefined") {
        retObj.checkboxReduceOverlapValue = data.result.checkboxReduceOverlapValue;
        window.newValuesFormTab2.checkboxReduceOverlapValue
            = window.newValuesFormTab1.checkboxReduceOverlapValue
            = data.result.checkboxReduceOverlapValue;
    }
    if (typeof data.result.checkboxShowHideLegendValue !== "undefined") {
        retObj.checkboxShowHideLegendValue = data.result.checkboxShowHideLegendValue;
        window.newValuesFormTab2.checkboxShowHideLegendValue
            = window.newValuesFormTab1.checkboxShowHideLegendValue
            = data.result.checkboxShowHideLegendValue;
    }
    if (typeof data.result.checkboxShowHideSeriesLabelValue !== "undefined") {
        retObj.checkboxShowHideSeriesLabelValue = data.result.checkboxShowHideSeriesLabelValue;
        window.newValuesFormTab2.checkboxShowHideSeriesLabelValue
            = window.newValuesFormTab1.checkboxShowHideSeriesLabelValue
            = data.result.checkboxShowHideSeriesLabelValue;
    }
    if (typeof data.result.checkboxShowHideTitleValue !== "undefined") {
        retObj.checkboxShowHideTitleValue = data.result.checkboxShowHideTitleValue;
        window.newValuesFormTab2.checkboxShowHideTitleValue
            = window.newValuesFormTab1.checkboxShowHideTitleValue
            = data.result.checkboxShowHideTitleValue;
    }
    if (typeof data.result.checkboxAddNodesFixLevel !== "undefined") {
        retObj.checkboxAddNodesFixLevel = data.result.checkboxAddNodesFixLevel;
        window.newValuesFormTab2.checkboxAddNodesFixLevel
            = window.newValuesFormTab1.checkboxAddNodesFixLevel
            = data.result.checkboxAddNodesFixLevel;
    }
    if (typeof data.result.checkboxShowHideMenuValue !== "undefined") {
        retObj.checkboxShowHideMenuValue = data.result.checkboxShowHideMenuValue;
        window.newValuesFormTab2.checkboxShowHideMenuValue
            = window.newValuesFormTab1.checkboxShowHideMenuValue
            = data.result.checkboxShowHideMenuValue;
    }
    if (typeof data.result.checkboxShowHideTableHeaderValue !== "undefined") {
        retObj.checkboxShowHideTableHeaderValue = data.result.checkboxShowHideTableHeaderValue;
        window.newValuesFormTab2.checkboxShowHideTableHeaderValue
            = window.newValuesFormTab1.checkboxShowHideTableHeaderValue
            = data.result.checkboxShowHideTableHeaderValue;
    }
    if (typeof data.result.checkboxOrientationHorizontalVertical !== "undefined") {
        retObj.checkboxOrientationHorizontalVertical = data.result.checkboxOrientationHorizontalVertical;
        window.newValuesFormTab2.checkboxOrientationHorizontalVertical
            = window.newValuesFormTab1.checkboxOrientationHorizontalVertical
            = data.result.checkboxOrientationHorizontalVertical;
    }
    if (typeof data.result.checkboxShowHideMetadataColumnValue !== "undefined") {
        retObj.checkboxShowHideMetadataColumnValue = data.result.checkboxShowHideMetadataColumnValue;
        window.newValuesFormTab2.checkboxShowHideMetadataColumnValue
            = window.newValuesFormTab1.checkboxShowHideMetadataColumnValue
            = data.result.checkboxShowHideMetadataColumnValue;
    }
    if (typeof data.result.checkboxShowHidePaginationValue !== "undefined") {
        retObj.checkboxShowHidePaginationValue = data.result.checkboxShowHidePaginationValue;
        window.newValuesFormTab2.checkboxShowHidePaginationValue
            = window.newValuesFormTab1.checkboxShowHidePaginationValue
            = data.result.checkboxShowHidePaginationValue;
    }
    if (typeof data.result.checkboxConnectNullsValue !== "undefined") {
        if ((data.result.checkboxConnectNullsValue === true) || (data.result.checkboxConnectNullsValue === "true")) {
            data.result.checkboxConnectNullsValue = 2;
        }
        if ((data.result.checkboxConnectNullsValue === false) || (data.result.checkboxConnectNullsValue === "false")) {
            data.result.checkboxConnectNullsValue = 1;
        }
        retObj.checkboxConnectNullsValue = data.result.checkboxConnectNullsValue;
        window.newValuesFormTab2.checkboxConnectNullsValue
            = window.newValuesFormTab1.checkboxConnectNullsValue
            = data.result.checkboxConnectNullsValue;
    }
    if (typeof data.result.dropdownConnectorLinesSourceValue !== "undefined") {
        retObj.dropdownConnectorLinesSourceValue = data.result.dropdownConnectorLinesSourceValue;
        window.newValuesFormTab2.dropdownConnectorLinesSourceValue
            = window.newValuesFormTab1.dropdownConnectorLinesSourceValue
            = data.result.dropdownConnectorLinesSourceValue;
    }
    if (typeof data.result.checkboxSourceDataPointsValue !== "undefined") {
        retObj.checkboxSourceDataPointsValue = data.result.checkboxSourceDataPointsValue;
        window.newValuesFormTab2.checkboxSourceDataPointsValue
            = window.newValuesFormTab1.checkboxSourceDataPointsValue
            = data.result.checkboxSourceDataPointsValue;
    }
    if (typeof data.result.checkboxAxisTurnOnOffValue !== "undefined") {
        retObj.checkboxAxisTurnOnOffValue = data.result.checkboxAxisTurnOnOffValue;
        window.newValuesFormTab2.checkboxAxisTurnOnOffValue
            = window.newValuesFormTab1.checkboxAxisTurnOnOffValue
            = data.result.checkboxAxisTurnOnOffValue;
    }
    /*Set data for the tab3*/
    if (typeof data.result.dropdownDataOmission !== "undefined") {
        retObj.dropdownDataOmission = data.result.dropdownDataOmission;
        window.newValuesFormTab1.dropdownDataOmission
            = window.newValuesFormTab3.dropdownDataOmission
            = data.result.dropdownDataOmission;
    }
    if (typeof data.result.checkboxPreserveDateRange !== "undefined") {
        retObj.checkboxPreserveDateRange = data.result.checkboxPreserveDateRange;
        window.newValuesFormTab1.checkboxPreserveDateRange
            = window.newValuesFormTab3.checkboxPreserveDateRange
            = data.result.checkboxPreserveDateRange;
    }
    var scaleSmoothValues = separationScaleSmoothValuesForBackwardCompatibility(
        data.result.dropdownValueVScale,
        data.result.dropdownValueVSmooth
    );
    //if (typeof data.result.dropdownValueVScale !== "undefined") {
    retObj.dropdownValueVScale = scaleSmoothValues.valueVScale;
    window.newValuesFormTab1.dropdownValueVScale
        = window.newValuesFormTab3.dropdownValueVScale
        = scaleSmoothValues.valueVScale;
    //}
    //if (typeof data.result.dropdownValueVSmooth !== "undefined") {
    retObj.dropdownValueVSmooth = scaleSmoothValues.valueVSmooth;
    window.newValuesFormTab1.dropdownValueVSmooth
        = window.newValuesFormTab3.dropdownValueVSmooth
        = scaleSmoothValues.valueVSmooth;
    //}
    if (typeof data.result.numberBoxDegree !== "undefined") {
        retObj.numberBoxDegree = data.result.numberBoxDegree;
        window.newValuesFormTab1.numberBoxDegree = window.newValuesFormTab3.numberBoxDegree = data.result.numberBoxDegree;
    }
    if (typeof data.result.numberBoxFilterThreshold !== "undefined") {
        retObj.numberBoxFilterThreshold = data.result.numberBoxFilterThreshold;
        window.newValuesFormTab1.numberBoxFilterThreshold
            = window.newValuesFormTab3.numberBoxFilterThreshold
            = data.result.numberBoxFilterThreshold;
    }
    if (typeof data.result.numberBoxClustersNumber !== "undefined") {
        retObj.numberBoxClustersNumber = data.result.numberBoxClustersNumber;
        window.newValuesFormTab1.numberBoxClustersNumber
            = window.newValuesFormTab3.numberBoxClustersNumber
            = data.result.numberBoxClustersNumber;
    }
    if (typeof data.result.numberBoxEpsilon !== "undefined") {
        retObj.numberBoxEpsilon = data.result.numberBoxEpsilon;
        window.newValuesFormTab1.numberBoxEpsilon
            = window.newValuesFormTab3.numberBoxEpsilon
            = data.result.numberBoxEpsilon;
    }
    if (typeof data.result.numberBoxMinItemsPerCluster !== "undefined") {
        retObj.numberBoxMinItemsPerCluster = data.result.numberBoxMinItemsPerCluster;
        window.newValuesFormTab1.numberBoxMinItemsPerCluster
            = window.newValuesFormTab3.numberBoxMinItemsPerCluster
            = data.result.numberBoxMinItemsPerCluster;
    }
    if (typeof data.result.dropdownModel !== "undefined") {
        retObj.dropdownModel = data.result.dropdownModel;
        window.newValuesFormTab1.dropdownModel
            = window.newValuesFormTab3.dropdownModel
            = data.result.dropdownModel;
    }
    if (typeof data.result.dropdownFilterDirection !== "undefined") {
        retObj.dropdownFilterDirection = data.result.dropdownFilterDirection;
        window.newValuesFormTab1.dropdownFilterDirection
            = window.newValuesFormTab3.dropdownFilterDirection
            = data.result.dropdownFilterDirection;
    }
    if (typeof data.result.dropdownFactorsData !== "undefined") {
        retObj.dropdownFactorsData = data.result.dropdownFactorsData;
        window.newValuesFormTab1.dropdownFactorsData
            = window.newValuesFormTab3.dropdownFactorsData
            = data.result.dropdownFactorsData;
    }
    if (typeof data.result.dropdownCentroidInitialization !== "undefined") {
        retObj.dropdownCentroidInitialization = data.result.dropdownCentroidInitialization;
        window.newValuesFormTab1.dropdownCentroidInitialization
            = window.newValuesFormTab3.dropdownCentroidInitialization
            = data.result.dropdownCentroidInitialization;
    }
    if (typeof data.result.dropdownClusterizationModel !== "undefined") {
        retObj.dropdownClusterizationModel = data.result.dropdownClusterizationModel;
        window.newValuesFormTab1.dropdownClusterizationModel
            = window.newValuesFormTab3.dropdownClusterizationModel
            = data.result.dropdownClusterizationModel;
    }
    if (typeof data.result.dropdownInterpolationPrediction !== "undefined") {
        retObj.dropdownInterpolationPrediction = data.result.dropdownInterpolationPrediction;
        window.newValuesFormTab1.dropdownInterpolationPrediction
            = window.newValuesFormTab3.dropdownInterpolationPrediction
            = data.result.dropdownInterpolationPrediction;
    }
    if (typeof data.result.dropdownFilterModel !== "undefined") {
        retObj.dropdownFilterModel = data.result.dropdownFilterModel;
        window.newValuesFormTab1.dropdownFilterModel = window.newValuesFormTab3.dropdownFilterModel
            = data.result.dropdownFilterModel;
    }
    /*Set data for the tab4*/
    if (typeof data.result.wordsList !== "undefined") {
        retObj.wordsList = data.result.wordsList;
        window.newValuesFormTab4.wordsList = window.newValuesFormTab1.wordsList = data.result.wordsList;
    }
    if (typeof data.result.changedColors !== "undefined") {
        retObj.changedColors = JSON.stringify(data.result.changedColors);
        window.newValuesFormTab4.changedColors = window.newValuesFormTab1.changedColors = data.result.changedColors;
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        if ((typeof window.newValuesFormTab1.chartType !== "undefined") && window.newValuesFormTab1.chartType == CHART_TYPE_MOLECULAR) {
            window.molecularData[chartIndex].changedColors = data.result.changedColors;
        } else if ((typeof window.newValuesFormTab1.chartType !== "undefined") && window.newValuesFormTab1.chartType == CHART_TYPE_TIMESERIES) {
            window.timeSeriesData[chartIndex].changedColors = data.result.changedColors;
        } else if ((typeof window.newValuesFormTab1.chartType !== "undefined") && window.newValuesFormTab1.chartType == CHART_TYPE_TIMELINE) {
            window.timelineData[chartIndex].changedColors = data.result.changedColors;
        } else if ((typeof window.newValuesFormTab1.chartType !== "undefined") && window.newValuesFormTab1.chartType == CHART_TYPE_STREAMGRAPH) {
            window.streamgraphData[chartIndex].changedColors = data.result.changedColors;
        } else if ((typeof window.newValuesFormTab1.chartType !== "undefined") && window.newValuesFormTab1.chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
            window.sankeyData[chartIndex].changedColors = data.result.changedColors;
        }
    }
    if (typeof data.result.wordsListDataArr !== "undefined") {
        retObj.wordsListDataArr = data.result.wordsListDataArr;
    }
    if (typeof data.result.hiddenLegendItems !== "undefined") {
        if (firstLoadChart == 1 && formDataStr != '') {
            retObj.hiddenLegendItems = data.result.hiddenLegendItems;
        } else {
            retObj.hiddenLegendItems = JSON.parse(data.result.hiddenLegendItems);
            window.newValuesFormTab1.hiddenLegendItems = JSON.parse(data.result.hiddenLegendItems);
            //console.log(data.result.hiddenLegendItems);
        }
    }
    if (typeof data.result.checkboxPOSAcronymValue !== "undefined") {
        retObj.checkboxPOSAcronymValue = data.result.checkboxPOSAcronymValue;
    }
    if (typeof data.result.checkboxPOSGeoValue !== "undefined") {
        retObj.checkboxPOSGeoValue = data.result.checkboxPOSGeoValue;
    }
    if (typeof data.result.dropdownUniqueWords !== "undefined") {
        retObj.dropdownUniqueWords = data.result.dropdownUniqueWords;
        window.newValuesFormTab1.dropdownUniqueWords =
            window.newValuesFormTab4.dropdownUniqueWords = data.result.dropdownUniqueWords;
    }
    if (typeof data.result.dropdownSortOrderWords !== "undefined") {
        retObj.dropdownSortOrderWords = data.result.dropdownSortOrderWords;
        window.newValuesFormTab1.dropdownSortOrderWords =
            window.newValuesFormTab4.dropdownSortOrderWords = data.result.dropdownSortOrderWords;
    }

    //Satellites Type Mask
    if (typeof data.result.checkboxMultiWordSatellitesTypeMask !== "undefined") {
        retObj.checkboxMultiWordSatellitesTypeMask = data.result.checkboxMultiWordSatellitesTypeMask;
    }
    if (typeof data.result.checkboxProperSatellitesTypeMask !== "undefined") {
        retObj.checkboxProperSatellitesTypeMask = data.result.checkboxProperSatellitesTypeMask;
    }
    if (typeof data.result.checkboxNormalizedSatellitesTypeMask !== "undefined") {
        retObj.checkboxNormalizedSatellitesTypeMask = data.result.checkboxNormalizedSatellitesTypeMask;
    }
    if (typeof data.result.checkboxReadableSatellitesTypeMask !== "undefined") {
        retObj.checkboxReadableSatellitesTypeMask = data.result.checkboxReadableSatellitesTypeMask;
    }
    if (typeof data.result.checkboxCommonSatellitesTypeMask !== "undefined") {
        retObj.checkboxCommonSatellitesTypeMask = data.result.checkboxCommonSatellitesTypeMask;
    }
    if (typeof data.result.checkboxNumbersSatellitesTypeMask !== "undefined") {
        retObj.checkboxNumbersSatellitesTypeMask = data.result.checkboxNumbersSatellitesTypeMask;
    }


    if (typeof data.result.checkboxPOSPersonNameValue !== "undefined") {
        retObj.checkboxPOSPersonNameValue = data.result.checkboxPOSPersonNameValue;
    }
    if (typeof data.result.checkboxPOSWikiValue !== "undefined") {
        retObj.checkboxPOSWikiValue = data.result.checkboxPOSWikiValue;
    }
    if (typeof data.result.checkboxWordsGroupSubentries !== "undefined") {
        retObj.checkboxWordsGroupSubentries = data.result.checkboxWordsGroupSubentries;
    }
    if (typeof data.result.checkboxValueIndicatorLimitsStrict !== "undefined") {
        retObj.checkboxValueIndicatorLimitsStrict = data.result.checkboxValueIndicatorLimitsStrict;
    }
    if (typeof data.result.fixedNodesArr !== "undefined") {
        retObj.fixedNodesArr = data.result.fixedNodesArr;
        window.newValuesFormTab1.fixedNodesArr = data.result.fixedNodesArr;
        if (typeof chartIndex === "undefined") {
            chartIndex = 0;
        }
        window.molecularData[chartIndex].fixedNodesArr = data.result.fixedNodesArr;
    }
    if (typeof data.result.svgHeight !== "undefined") {
        retObj.svgHeight = data.result.svgHeight;
        window.newValuesFormTab1.svgHeight = data.result.svgHeight;
    }
    if (typeof data.result.svgWidth !== "undefined") {
        retObj.svgWidth = data.result.svgWidth;
        window.newValuesFormTab1.svgWidth = data.result.svgWidth;
    }
    if (typeof data.result.selectedTab !== "undefined") {
        retObj.selectedTab = data.result.selectedTab;
        window.newValuesFormTab1.selectedTab = data.result.selectedTab;
    }

    var resData = setWidgetUrlParams(data, retObj);
    data = resData['data'];
    retObj = resData['retObj']

    window.formInitData = retObj;
    if (firstLoadChart == 1 && formDataStr != '') {
        chartType = window.newValuesFormTab1.chartType;
        if (isWidget == 0) {
            selectMenuItemByChartType(chartType);
        }

        setNewTabFormValues(data);
    } else {
        createLayout();
    }

    setShareValuesForm(retObj);
    firstLoadChart = 1;
    //if (!(firstLoadChart == 1 && formDataStr != '')) {
    //window.btnMain.click();
    setTimeout(function () {
        window.btnMain.click();
    }, 1200);
//}
    window.formDataStrSave = formDataStr = '';
}

function selectMenuItemByChartType(chartType) {
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_MOLECULAR)) {//molecular
        if ($("#jqxTree").find('li#chartMolecular').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartMolecular')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_RADIAL)) {//radial
        if ($("#jqxTree").find('li#chartRadial').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartRadial')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL)) {//
        if ($("#jqxTree").find('li#chartDendrogram').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartDendrogram')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TIMESERIES)) {//timeseries
        if ($("#jqxTree").find('li#chartTimeSeries').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartTimeSeries')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TIMELINE)) {//timeline
        if ($("#jqxTree").find('li#chartTimeLine').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartTimeLine')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TEXTUALWORDS)) {//textual
        if ($("#jqxTree").find('li#chartTextualWords').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartTextualWords')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_TEXTUALWORDSMETADATA)) {
        if ($("#jqxTree").find('li#chartTextualWordsMetadata').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartTextualWordsMetadata')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_STREAMGRAPH)) {//Streamgraph'
        if ($("#jqxTree").find('li#chartStreamgraph').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartStreamgraph')[0]);
        }
    }
    if ((chartType == 'clearAll') || (chartType == CHART_TYPE_SANKEY_NEIGHBORS1)) {//Sankey'
        if ($("#jqxTree").find('li#chartSankey').length > 0) {
            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#chartSankey')[0]);
        }
    }
}

function setNewTabFormValues(data) {
    if (isDebugg) { console.log(data.result); }
    /*Set data for the chart type*/
    if (typeof data.result.chartType !== "undefined") {
        var chartType = data.result.chartType;
    }
    if (($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) || ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS)) {
        retObj.chartType = CHART_TYPE_TIMESERIES;
        $('#chartType').val();
        chartType = CHART_TYPE_TIMESERIES;
        window.newValuesFormTab1.chartType = CHART_TYPE_TIMESERIES;
    }
    var selectedIndex = getIdBychartType(chartType);
    /*Set data for the tab1*/
    if (typeof data.result.dropdownValueDSource !== "undefined") {
        $('#dropdownValueDSource').val(data.result.dropdownValueDSource);
    }
    if (typeof data.result.dropdownValueDSource !== "undefined") {

        var dropdownValueDSourceVal = data.result.dropdownValueDSource;

        if (typeof namesIndicatorsBySources[dropdownValueDSourceVal] !== "undefined") {
            namesIndicators = namesIndicatorsBySources[dropdownValueDSourceVal];
        } else if (typeof namesIndicatorsBySources.namesDef !== "undefined") {
            namesIndicators = namesIndicatorsBySources.namesDef;
        } else {
            namesIndicators = {};
        }

        if (typeof chartTypesConfigurationBySources[dropdownValueDSourceVal] !== "undefined") {
            chartTypesConfiguration = chartTypesConfigurationBySources[dropdownValueDSourceVal];
        } else if (typeof chartTypesConfigurationBySources.chartTypesConfigurationDef !== "undefined") {
            chartTypesConfiguration = chartTypesConfigurationBySources.chartTypesConfigurationDef;
        } else {
            chartTypesConfiguration = {};
        }

        setChartTypesConfiguration(selectedIndex);
    }
    if (typeof data.result.dropdownValueDSource2 !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownValueDSource2').val(data.result.dropdownValueDSource2);
    }
    if (typeof data.result.dropdownDatesInterval !== "undefined") {
        $('#dropdownDatesInterval').val(data.result.dropdownDatesInterval);
    }
    if (typeof data.result.dropdownValueIWords !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownValueIWords').val(data.result.dropdownValueIWords);
    }
    if (typeof data.result.dropdownDaysInterval !== "undefined") {
        //window.sampleForm.jqxForm('getComponentByName', 'dropdownDaysInterval').val(data.result.dropdownDaysInterval);
        $('#dropdownDaysInterval').val(data.result.dropdownDaysInterval);
    }
    if (typeof data.result.dropdownMinIntersectedNeighbors !== "undefined") {
        //window.sampleForm.jqxForm('getComponentByName', 'dropdownMinIntersectedNeighbors').val(data.result.dropdownMinIntersectedNeighbors);
        $('#dropdownMinIntersectedNeighbors').val(data.result.dropdownMinIntersectedNeighbors);
    }
    if (typeof data.result.dropdownSortDailyWordsBy !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownSortDailyWordsBy').val(data.result.dropdownSortDailyWordsBy);
        //$('#dropdownSortDailyWordsBy').val(data.result.dropdownSortDailyWordsBy);
    }
    if (typeof data.result.satellitesOrderCondition !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'satellitesOrderCondition').val(data.result.satellitesOrderCondition);
    }
    if (typeof data.result.dropdownDataSelectCache !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownDataSelectCache').val(data.result.dropdownDataSelectCache);
    }
    if (typeof data.result.dropdownValueMaxDepth !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val(data.result.dropdownValueMaxDepth);
    }
    if (typeof data.result.dropdownMaxDistance !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownMaxDistance').val(data.result.dropdownMaxDistance);
    }
    if (typeof data.result.dropdownValueVIndicator !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownValueVIndicator').val(data.result.dropdownValueVIndicator);

        if (typeof data.result.dropdownValueDSource !== "undefined") {
            window.newValuesFormTab1.dropdownValueDSource = data.result.dropdownValueDSource;

            var dropdownValueDSourceVal = data.result.dropdownValueDSource;
            var dropdownValueVIndicator = window.sampleForm.jqxForm('getComponentByName', 'dropdownValueVIndicator');
            dropdownValueVIndicator.jqxDropDownList('val', valueDefault);

            if (typeof namesIndicatorsBySources[dropdownValueDSourceVal] !== "undefined") {
                namesIndicators = namesIndicatorsBySources[dropdownValueDSourceVal];
            } else if (typeof namesIndicatorsBySources.namesDef !== "undefined") {
                namesIndicators = namesIndicatorsBySources.namesDef;
            } else {
                namesIndicators = {};
            }

            if (typeof chartTypesConfigurationBySources[dropdownValueDSourceVal] !== "undefined") {
                chartTypesConfiguration = chartTypesConfigurationBySources[dropdownValueDSourceVal];
            } else if (typeof chartTypesConfigurationBySources.chartTypesConfigurationDef !== "undefined") {
                chartTypesConfiguration = chartTypesConfigurationBySources.chartTypesConfigurationDef;
            } else {
                chartTypesConfiguration = {};
            }

            if (typeof data.result.chartType !== "undefined") {
                var chartType = data.result.chartType;
                var selectedIndex = getIdBychartType(chartType);
            }
            setChartTypesConfiguration(selectedIndex);
        }
    }



    if (typeof data.result.dropdownsSortField !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownsSortField').val(data.result.dropdownsSortField);

        if (typeof data.result.dropdownValueDSource !== "undefined") {
            window.newValuesFormTab1.dropdownValueDSource = data.result.dropdownValueDSource;

            var dropdownValueDSourceVal = data.result.dropdownValueDSource;
            var dropdownsSortField = window.sampleForm.jqxForm('getComponentByName', 'dropdownsSortField');
            dropdownsSortField.jqxDropDownList('val', valueDefault);

            if (typeof namesIndicatorsBySources[dropdownValueDSourceVal] !== "undefined") {
                namesIndicators = namesIndicatorsBySources[dropdownValueDSourceVal];
            } else if (typeof namesIndicatorsBySources.namesDef !== "undefined") {
                namesIndicators = namesIndicatorsBySources.namesDef;
            } else {
                namesIndicators = {};
            }

            if (typeof chartTypesConfigurationBySources[dropdownValueDSourceVal] !== "undefined") {
                chartTypesConfiguration = chartTypesConfigurationBySources[dropdownValueDSourceVal];
            } else if (typeof chartTypesConfigurationBySources.chartTypesConfigurationDef !== "undefined") {
                chartTypesConfiguration = chartTypesConfigurationBySources.chartTypesConfigurationDef;
            } else {
                chartTypesConfiguration = {};
            }

            if (typeof data.result.chartType !== "undefined") {
                var chartType = data.result.chartType;
                var selectedIndex = getIdBychartType(chartType);
            }
            setChartTypesConfiguration(selectedIndex);
        }
    }



    var scaleSmoothValues = separationScaleSmoothValuesForBackwardCompatibility(data.result.dropdownValueVScale, data.result.dropdownValueVSmooth);
    //if (typeof data.result.dropdownValueVScale !== "undefined") {
    window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownValueVScale').val(scaleSmoothValues.valueVScale);
    //}
    //if (typeof data.result.dropdownValueVSmooth !== "undefined") {
    window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownValueVSmooth').val(scaleSmoothValues.valueVSmooth);
    //}
    if (typeof data.result.dropdownDataOmission !== "undefined") {
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownDataOmission').val(data.result.dropdownDataOmission);
    }
    if (typeof data.result.checkboxPreserveDateRange !== "undefined") {
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'checkboxPreserveDateRange').val(data.result.checkboxPreserveDateRange);
    }
    if (typeof data.result.checkboxAppearanceFullTimeValue !== "undefined") {
        window.sampleForm.jqxForm('getComponentByName', 'checkboxAppearanceFullTimeValue').val(data.result.checkboxAppearanceFullTimeValue);
    }


    /*Set data for the tab2 (Drawing) */
    if (typeof data.result.drawingValueIndicators !== "undefined") {
        if (firstLoadChart == 1 && formDataStr != '') {
            retObj.drawingValueIndicators = data.result.drawingValueIndicators;
        } else {
            retObj.drawingValueIndicators = JSON.parse(data.result.drawingValueIndicators);
            window.newValuesFormTab1.drawingValueIndicators = JSON.parse(data.result.drawingValueIndicators);
            setDrawingValueIndicatorsChecked(retObj.drawingValueIndicators);
        }
    } else {
        setDrawingValueIndicatorsChecked(drawingValueIndicatorCheckboxesDefaultChecked);
    }
    if (typeof data.result.dropdownChartBackgroundColor !== "undefined") {
        chartBackgroundColor = data.result.dropdownChartBackgroundColor;
        if ((chartBackgroundColor == 0)) {
            chartBackgroundColor = setAutoBackgroundColor();
        }
        $('#dataContaner').css('background-color', chartBackgroundColor);
    }
    if (typeof data.result.dropdownChartTextColor !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartTextColor').val(data.result.dropdownChartTextColor);
    }
    if (typeof data.result.changedCTColor !== "undefined") {
        window.newValuesFormTab1.changedCTColor = window.newValuesFormTab2.changedCTColor = data.result.changedCTColor;
        $('.chart-text-color').css('background-color', data.result.changedCTColor);
        $('td.colorColumnChartText .chart-text-new-value').val(data.result.changedCTColor);
    }
    if (typeof data.result.dropdownBallsText !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsText').val(data.result.dropdownBallsText);
    }
    if (typeof data.result.dropdownBallsTextLayout !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsTextLayout').val(data.result.dropdownBallsTextLayout);
    }
    if (typeof data.result.checkboxConnectorsLinesValue !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxConnectorsLines').val(data.result.checkboxConnectorsLinesValue);
    }
    if (typeof data.result.checkboxShowHideLegendValue !== "undefined") {
        if (data.result.checkboxShowHideLegendValue == 1 || data.result.checkboxShowHideLegendValue == true) {
            var checkboxShowHideLegendValue = true;
        } else {
            var checkboxShowHideLegendValue = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideLegend').val(checkboxShowHideLegendValue);
    }
    if (typeof data.result.checkboxShowHideSeriesLabelValue !== "undefined") {
        if (data.result.checkboxShowHideSeriesLabelValue == 1 || data.result.checkboxShowHideSeriesLabelValue == true) {
            var checkboxShowHideSeriesLabelValue = true;
        } else {
            if (typeof data.result.checkboxShowHideSeriesLabelValue === "undefined") {
                var checkboxShowHideSeriesLabelValue = true;
            } else {
                var checkboxShowHideSeriesLabelValue = false;
            }
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideSeriesLabel').val(checkboxShowHideSeriesLabelValue);
    }
    if (typeof data.result.checkboxShowHideTitleValue !== "undefined") {
        if (data.result.checkboxShowHideTitleValue == 1 || data.result.checkboxShowHideTitleValue == true) {
            var checkboxShowHideTitleValue = true;
        } else {
            var checkboxShowHideTitleValue = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTitle').val(checkboxShowHideTitleValue);
    }
    if (typeof data.result.checkboxAddNodesFixLevel !== "undefined") {
        if (data.result.checkboxAddNodesFixLevel == 1 || data.result.checkboxAddNodesFixLevel == true) {
            var checkboxAddNodesFixLevel = true;
        } else {
            var checkboxAddNodesFixLevel = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAddNodesFixLevel').val(checkboxAddNodesFixLevel);
    }
    if (typeof data.result.checkboxShowHideMenuValue !== "undefined") {
        if (data.result.checkboxShowHideMenuValue == 1 || data.result.checkboxShowHideMenuValue == true) {
            var checkboxShowHideMenuValue = true;
        } else {
            var checkboxShowHideMenuValue = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMenu').val(checkboxShowHideMenuValue);
    }
    if (typeof data.result.checkboxShowHideTableHeaderValue !== "undefined") {
        if (data.result.checkboxShowHideTableHeaderValue == 1 || data.result.checkboxShowHideTableHeaderValue == true) {
            var checkboxShowHideTableHeaderValue = true;
        } else {
            var checkboxShowHideTableHeaderValue = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTableHeader').val(checkboxShowHideTableHeaderValue);
    }
    if (typeof data.result.checkboxOrientationHorizontalVertical !== "undefined") {
        if (data.result.checkboxOrientationHorizontalVertical == 1 || data.result.checkboxOrientationHorizontalVertical == true) {
            var checkboxOrientationHorizontalVertical = true;
        } else {
            var checkboxOrientationHorizontalVertical = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical').val(checkboxOrientationHorizontalVertical);
    }
    if (typeof data.result.checkboxShowHideMetadataColumnValue !== "undefined") {
        if (data.result.checkboxShowHideMetadataColumnValue == 1 || data.result.checkboxShowHideMetadataColumnValue == true) {
            var checkboxShowHideMetadataColumnValue = true;
        } else {
            var checkboxShowHideMetadataColumnValue = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMetadataColumn').val(checkboxShowHideMetadataColumnValue);
    }
    if (typeof data.result.checkboxShowHidePaginationValue !== "undefined") {
        if (data.result.checkboxShowHidePaginationValue == 1 || data.result.checkboxShowHidePaginationValue == true) {
            var checkboxShowHidePaginationValue = true;
        } else {
            var checkboxShowHidePaginationValue = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHidePagination').val(checkboxShowHidePaginationValue);
    }
    if (typeof data.result.checkboxConnectNullsValue !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxConnectNulls').val(data.result.checkboxConnectNullsValue);
    }
    if (typeof data.result.dropdownConnectorLinesSourceValue !== "undefined") {
        window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLinesSource').val(data.result.dropdownConnectorLinesSourceValue);
    }
    if (typeof data.result.checkboxAxisTurnOnOffValue !== "undefined") {
        if (data.result.checkboxAxisTurnOnOffValue == 1 || data.result.checkboxAxisTurnOnOffValue == true) {
            var checkboxAxisTurnOnOffValue = true;
        } else {
            var checkboxAxisTurnOnOffValue = false;
        }
        window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAxisTurnOnOff').val(checkboxAxisTurnOnOffValue);
    }
    /*Set data for the tab3*/
    if (typeof data.result.dropdownClusterizationModel !== "undefined") {
        window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownClusterizationModel').val(data.result.dropdownClusterizationModel);
    }
    /*Set data for the tab4*/
    if (typeof data.result.wordsList !== "undefined") {
        retObj.wordsList = data.result.wordsList;
        window.newValuesFormTab4.wordsList = window.newValuesFormTab1.wordsList = data.result.wordsList;
    }
    if (typeof data.result.changedColors !== "undefined") {
        retObj.changedColors = JSON.stringify(data.result.changedColors);
        window.newValuesFormTab4.changedColors = window.newValuesFormTab1.changedColors = data.result.changedColors;
    }
    if (typeof data.result.wordsListDataArr !== "undefined") {
        retObj.wordsListDataArr = data.result.wordsListDataArr;
    }
    if (typeof data.result.textBoxValueSearchWord !== "undefined") {
        window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val(data.result.textBoxValueSearchWord);
        setTimeout(function () {
            window.sampleFormWords.jqxForm('getComponentByName', 'textBoxValueSearchWord').val(data.result.textBoxValueSearchWord);
        }, 1000);
    }
    if (typeof data.result.hiddenLegendItems !== "undefined") {
        if (firstLoadChart == 1 && formDataStr != '') {
            retObj.hiddenLegendItems = data.result.hiddenLegendItems;
        } else {
            retObj.hiddenLegendItems = JSON.parse(data.result.hiddenLegendItems);
            window.newValuesFormTab1.hiddenLegendItems = JSON.parse(data.result.hiddenLegendItems);
        }
    }
    if (typeof data.result.checkboxPOSAcronymValue !== "undefined") {
        retObj.checkboxPOSAcronymValue = data.result.checkboxPOSAcronymValue;
    }
    if (typeof data.result.checkboxPOSGeoValue !== "undefined") {
        retObj.checkboxPOSGeoValue = data.result.checkboxPOSGeoValue;
    }
    if (typeof data.result.dropdownUniqueWords !== "undefined") {
        window.sampleFormWords.jqxForm('getComponentByName', 'dropdownUniqueWords').val(data.result.dropdownUniqueWords);
        retObj.dropdownUniqueWords = data.result.dropdownUniqueWords;
    }
    if (typeof data.result.dropdownSortOrderWords !== "undefined") {
        window.sampleFormWords.jqxForm('getComponentByName', 'dropdownSortOrderWords').val(data.result.dropdownSortOrderWords);
        retObj.dropdownSortOrderWords = data.result.dropdownSortOrderWords;
    }

    //Satellites Type Mask
    if (typeof data.result.checkboxMultiWordSatellitesTypeMask !== "undefined") {
        retObj.checkboxMultiWordSatellitesTypeMask = data.result.checkboxMultiWordSatellitesTypeMask;
    }
    if (typeof data.result.checkboxProperSatellitesTypeMask !== "undefined") {
        retObj.checkboxProperSatellitesTypeMask = data.result.checkboxProperSatellitesTypeMask;
    }
    if (typeof data.result.checkboxNormalizedSatellitesTypeMask !== "undefined") {
        retObj.checkboxNormalizedSatellitesTypeMask = data.result.checkboxNormalizedSatellitesTypeMask;
    }
    if (typeof data.result.checkboxReadableSatellitesTypeMask !== "undefined") {
        retObj.checkboxReadableSatellitesTypeMask = data.result.checkboxReadableSatellitesTypeMask;
    }
    if (typeof data.result.checkboxCommonSatellitesTypeMask !== "undefined") {
        retObj.checkboxCommonSatellitesTypeMask = data.result.checkboxCommonSatellitesTypeMask;
    }
    if (typeof data.result.checkboxNumbersSatellitesTypeMask !== "undefined") {
        retObj.checkboxNumbersSatellitesTypeMask = data.result.checkboxNumbersSatellitesTypeMask;
    }

    if (typeof data.result.checkboxPOSPersonNameValue !== "undefined") {
        retObj.checkboxPOSPersonNameValue = data.result.checkboxPOSPersonNameValue;
    }
    if (typeof data.result.checkboxPOSWikiValue !== "undefined") {
        retObj.checkboxPOSWikiValue = data.result.checkboxPOSWikiValue;
    }
    if (typeof data.result.checkboxWordsGroupSubentries !== "undefined") {
        retObj.checkboxWordsGroupSubentries = data.result.checkboxWordsGroupSubentries;
    }
    if (typeof data.result.checkboxValueIndicatorLimitsStrict !== "undefined") {
        retObj.checkboxValueIndicatorLimitsStrict = data.result.checkboxValueIndicatorLimitsStrict;
    }
    if (typeof data.result.fixedNodesArr !== "undefined") {
        retObj.fixedNodesArr = data.result.fixedNodesArr;
        window.newValuesFormTab1.fixedNodesArr = data.result.fixedNodesArr;
    }
    if (typeof data.result.svgHeight !== "undefined") {
        retObj.svgHeight = data.result.svgHeight;
        window.newValuesFormTab1.svgHeight = data.result.svgHeight;
    }
    if (typeof data.result.svgWidth !== "undefined") {
        retObj.svgWidth = data.result.svgWidth;
        window.newValuesFormTab1.svgWidth = data.result.svgWidth;
    }
    //set default values
    //$('#nubmberBoxValue1 > input:nth-child(1)').val(1);
    //retObj.nubmberBoxValue1 = 1;

    //$("#nubmberBoxAppearanceMin input").val(1);
    //retObj.nubmberBoxAppearanceMin = 1;

    if (($('#chartType').val() == CHART_TYPE_RADIAL) || ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
        window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val(2);
        retObj.dropdownValueMaxDepth = 2;

        window.sampleForm.jqxForm('getComponentByName', 'dropdownMaxDistance').val(4);
        retObj.dropdownMaxDistance = 4;

        $('#nubmberBoxNeighborsMaxItems > input:nth-child(1)').val(10);
        retObj.nubmberBoxNeighborsMaxItems = 10;

    }
    if (($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) || ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS)) {
        retObj.chartType = CHART_TYPE_TIMESERIES;
        $('#chartType').val();
        chartType = CHART_TYPE_TIMESERIES;
        window.newValuesFormTab1.chartType = CHART_TYPE_TIMESERIES;
    }
    if ($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) {
        //retObj.chartType = CHART_TYPE_TIMESERIES;
    }
    window.formInitData = retObj;

    if (firstLoadChart == 1 && formDataStr != '') {
        chartType = window.newValuesFormTab1.chartType;
        if (isWidget == 0) {
            selectMenuItemByChartType(chartType);
        }
    } else {
        createLayout();
    }
    setShareValuesForm(retObj);
    firstLoadChart = 1;
    if (!(firstLoadChart == 1 && formDataStr != '')) {
        window.btnMain.click();
        setTimeout(function () {
            window.btnMain.click();
        }, 2000);
    }
}

function makeRemoveClassHandler(regex) {
    return function (index, classes) {
        return classes.split(/\s+/).filter(function (el) { return regex.test(el); }).join(' ');
    }
}

/**
 * Create jqxLayout(Docking layout) from json.
 * Initializes the creation of forms and menus,
 * sets the initial values for individual elements of the forms,
 * assigns events for the elements of the interface.
 * @returns
 */
function createLayout() {
    if ($('body').find('#jqxLayout').length > 0) {
        $('#jqxLayout').removeClass('beforeLoadLayout');
        var contentWidth = $('#content').width() - 29;
        var leftWidht = Math.round(((175 / contentWidth) * 100) * 100) / 100;
        var rightWidht = Math.round(((320 / contentWidth) * 100) * 100) / 100;
        var centerWidth = Math.round((((contentWidth - 175 - 320 - 2) / contentWidth) * 100) * 100) / 100;
        // the 'layout' JSON array defines the internal structure of the layout
        if (isWidget == 0) {
            var layout =
                [
                    {
                        type: 'layoutGroup', //layoutGroup level 1 start
                        width: '100%',
                        height: '100%',
                        orientation: 'vertical',
                        items:
                            [
                                {//layout start
                                    type: 'layoutGroup',//layoutGroup level 2 START
                                    width: '100%',
                                    height: '80%',
                                    orientation: 'vertical',
                                    items:
                                        [
                                            {//layoutGroup level 3 START
                                                width: '100%',
                                                height: '100%',
                                                type: 'layoutGroup',
                                                orientation: 'horizontal',
                                                items:
                                                    [
                                                        {//tabbedGroup level 4 START
                                                            type: 'tabbedGroup',
                                                            orientation: 'vertical',
                                                            width: leftWidht + '%',
                                                            minWidth: '175px',
                                                            alignment: 'left',
                                                            items:
                                                                [
                                                                    {//layoutPanel level 5 START
                                                                        type: 'layoutPanel',
                                                                        height: '100%',
                                                                        minHeight: 500,
                                                                        title: 'Drawing Type',
                                                                        contentContainer: 'DrawingTypePanel',
                                                                        selected: true
                                                                    }
                                                                ]
                                                        },
                                                        {//layoutGroup level 4 START
                                                            type: 'layoutGroup',
                                                            orientation: 'vertical',
                                                            width: centerWidth + '%',//'calc(100% - 470px)',
                                                            minWidth: '600px',
                                                            items:
                                                                [
                                                                    {//documentGroty up level 5 START
                                                                        type: 'documentGroup',
                                                                        height: '100%',
                                                                        minHeight: 200,
                                                                        items:
                                                                            [
                                                                                {
                                                                                    type: 'documentPanel',
                                                                                    title: 'Drawing',
                                                                                    contentContainer: 'DrawingMolecularPanel',
                                                                                    initContent: function () {
                                                                                    }
                                                                                }
                                                                            ]
                                                                    }
                                                                ]
                                                        },
                                                        {//tabbedGroup level 4 START
                                                            type: 'documentGroup',
                                                            width: rightWidht + '%',
                                                            minWidth: '320px',
                                                            items:
                                                                [
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab1"></span>Data select',
                                                                        contentContainer: 'SampleFormDataSelectTabPanel',
                                                                        selected: true,
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormDataSelectTemplate();
                                                                            createFormDataSelect(template);
                                                                        }
                                                                    },
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab4"></span>Words select',
                                                                        contentContainer: 'SampleFormWordsTabPanel',
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormTemplateWords();
                                                                            createFormWords(template);
                                                                        }
                                                                    },
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab2"></span>Drawing',
                                                                        contentContainer: 'SampleFormDrawingTabPanel',
                                                                        selected: true,
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormTemplateDrawing();
                                                                            createFormDrawing(template);
                                                                        }
                                                                    },
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab3"></span>Analysis',
                                                                        contentContainer: 'SampleFormAnalysisTabPanel',
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormTemplateAnalysis();
                                                                            createFormAnalysis(template);
                                                                        }
                                                                    }
                                                                ]
                                                        }
                                                    ]//layoutGroup 1 end
                                            }, //layoutGroup level 3 END
                                        ]
                                },//layoutGroup level 2 END
                                {//tabbedGroup level 3 START
                                    type: 'tabbedGroup',
                                    orientation: 'horizontal',
                                    width: '100%',
                                    height: '20%',
                                    unpinnedHeight: 100,
                                    //unpinnedWidth: '100%',
                                    //allowPin: true,
                                    //allowUnpin: true,
                                    items:
                                        [
                                            {//layoutPanel level 4 START
                                                type: 'layoutPanel',
                                                title: 'Info',
                                                contentContainer: 'InfoPanel'
                                            },
                                            {//layoutPanel level 4 START
                                                type: 'layoutPanel',
                                                title: 'Console',
                                                contentContainer: 'ErrorListPanel'
                                            },
                                            {//layoutPanel level 4 START
                                                type: 'layoutPanel',
                                                title: 'Debug',
                                                contentContainer: 'DebugPanel'
                                            }
                                        ]
                                }//tabbedGroup level 3 END
                            ]
                    }  //layoutGroup level 1 end
                ];
            //layout end
        } else {
            var layout =
                [
                    {
                        type: 'layoutGroup', //layoutGroup level 1 start
                        width: '100%',
                        height: '100%',
                        orientation: 'vertical',
                        items:
                            [
                                {//layout start
                                    type: 'layoutGroup',//layoutGroup level 2 START
                                    width: '100%',
                                    height: '80%',
                                    orientation: 'vertical',
                                    items:
                                        [
                                            {//layoutGroup level 3 START
                                                width: '100%',
                                                height: '100%',
                                                type: 'layoutGroup',
                                                orientation: 'horizontal',
                                                items:
                                                    [
                                                        /*{//tabbedGroup level 4 START
                                                            type: 'tabbedGroup',
                                                            orientation: 'vertical',
                                                            width: leftWidht + '%',
                                                            minWidth: '150px',
                                                            alignment: 'left',
                                                            items: 
                                                            [
                                                                {//layoutPanel level 5 START
                                                                    type: 'layoutPanel',
                                                                    height: '100%',
                                                                    minHeight: 400,
                                                                    title: 'Drawing Type',
                                                                    contentContainer: 'DrawingTypePanel',
                                                                    selected: true
                                                                }
                                                            ]
                                                        },*/
                                                        {//layoutGroup level 4 START
                                                            type: 'layoutGroup',
                                                            orientation: 'vertical',
                                                            width: centerWidth + '%',//'calc(100% - 470px)',
                                                            minWidth: '600px',
                                                            items:
                                                                [
                                                                    {//documentGroty up level 5 START
                                                                        type: 'documentGroup',
                                                                        height: '100%',
                                                                        minHeight: 200,
                                                                        items:
                                                                            [
                                                                                {
                                                                                    type: 'documentPanel',
                                                                                    title: 'Drawing',
                                                                                    contentContainer: 'DrawingMolecularPanel'
                                                                                }
                                                                            ]
                                                                    }
                                                                ]
                                                        },
                                                        {//tabbedGroup level 4 START
                                                            type: 'documentGroup',
                                                            width: rightWidht + '%',
                                                            minWidth: '320px',
                                                            items:
                                                                [
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab1"></span>Data select',
                                                                        contentContainer: 'SampleFormDataSelectTabPanel',
                                                                        selected: true,
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormDataSelectTemplate();
                                                                            createFormDataSelect(template);
                                                                        }
                                                                    },
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab4"></span>Words select',
                                                                        contentContainer: 'SampleFormWordsTabPanel',
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormTemplateWords();
                                                                            createFormWords(template);
                                                                        }
                                                                    },
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab2"></span>Drawing',
                                                                        contentContainer: 'SampleFormDrawingTabPanel',
                                                                        selected: true,
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormTemplateDrawing();
                                                                            createFormDrawing(template);
                                                                        }
                                                                    },
                                                                    {//layoutPanel level 5 START
                                                                        type: 'documentPanel',
                                                                        title: '<span id="formTab3"></span>Analysis',
                                                                        contentContainer: 'SampleFormAnalysisTabPanel',
                                                                        // FORM content
                                                                        initContent: function () {
                                                                            var template = getFormTemplateAnalysis();
                                                                            createFormAnalysis(template);
                                                                        }
                                                                    }
                                                                ]
                                                        }
                                                    ]//layoutGroup 1 end
                                            }, //layoutGroup level 3 END
                                        ]
                                },//layoutGroup level 2 END
                                /*{//tabbedGroup level 3 START
                                    type: 'tabbedGroup',
                                    orientation: 'horizontal',
                                    width: '100%',
                                    height: '20%',
                                    unpinnedHeight: 100,
                                    //unpinnedWidth: '100%',
                                    //allowPin: true,
                                    //allowUnpin: true,
                                    items: 
                                    [
                                        {//layoutPanel level 4 START
                                            type: 'layoutPanel',
                                            title: 'Info',
                                            contentContainer: 'InfoPanel'
                                        },
                                        {//layoutPanel level 4 START
                                            type: 'layoutPanel',
                                            title: 'Console',
                                            contentContainer: 'ErrorListPanel'
                                        },
                                        {//layoutPanel level 4 START
                                            type: 'layoutPanel',
                                            title: 'Debug',
                                            contentContainer: 'DebugPanel'
                                        }
                                    ]
                                }//tabbedGroup level 3 END
                                */
                            ]
                    }  //layoutGroup level 1 end
                ];
            //layout end
        }
        $('#jqxLayout').jqxLayout({ width: getWidth('layout', 'jqxLayout'), height: '100%', layout: layout });
        $('#chartType').val(window.formInitData.chartType);
        if (firstLoadChart == 1 && isShare && hash != '') {
        } else if (firstLoadPage == 1) {
            if ($('#chartType').val() == CHART_TYPE_MOLECULAR) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
            } else if ($('#chartType').val() == CHART_TYPE_RADIAL) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
            } else if ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
            } else if ($('#chartType').val() == CHART_TYPE_TIMESERIES) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            } else if ($('#chartType').val() == CHART_TYPE_TIMELINE) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            } else if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            } else if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);


            } else {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            }
        }


        if (isDebugg) { console.log('30'); }
        // create jqxcalendar. START
        var elDateRangeCont = $('.form-dates-range-input').parent().parent().parent().parent().next().find('div>div>input').parent();
        elDateRangeCont.find('input').attr('hidden', 'hidden');
        elDateRangeCont.append('<div id="jqxWidgetDateRange"></div>');
        $("#jqxWidgetDateRange").prev().css("display", "none");
        $("#jqxWidgetDateRange").jqxDateTimeInput({ width: '100%', height: 29, selectionMode: 'range', formatString: "yyyy-MM-dd"/*, editMode: 'full'*/ });
        $("#jqxWidgetDateRange").on('change', function (event) {
            var selection = $("#jqxWidgetDateRange").jqxDateTimeInput('getRange');
        });
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        var setYesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
        var weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        var setWeekAgo = new Date(weekAgo.getFullYear(), weekAgo.getMonth(), weekAgo.getDate());
        $("#jqxWidgetDateRange").jqxDateTimeInput('setRange', setWeekAgo, setYesterday);

        $("input#inputjqxWidgetDateRange").on("keyup paste input", function () {
            $('#dropdownDatesInterval').val('0');
        });

        $("#jqxWidgetDateRange").on('open', function (event) {
            isChangeDropdownDatesInterval = 0;
            $('#dropdownDatesInterval').val('0');
        });

        $("#jqxWidgetDateRange").on('close', function (event) {
            if (isChangeDropdownDatesInterval == 0) {
                if (firstLoadChart == 0) {
                    $('#dropdownDatesInterval').val('0');
                }
            }
        });

        $("#nubmberBoxValue1 input").on('keyup', function (event) {
            if ($("#nubmberBoxValue1 input").val() > 1000) {
                $("#nubmberBoxValue1 input").val(1000);
            }
        });
        $("#nubmberBoxValue2 input").on('keyup', function (event) {
            if ($("#nubmberBoxValue2 input").val() > 1000) {
                $("#nubmberBoxValue2 input").val(1000);
            }
        });
        $("#nubmberBoxNeighborsFreqMin input").on('keyup', function (event) {
            if ($("#nubmberBoxNeighborsFreqMin input").val() > 1000) {
                $("#nubmberBoxNeighborsFreqMin input").val(1000);
            }
        });
        $("#nubmberBoxNeighborsFreqMax input").on('keyup', function (event) {
            if ($("#nubmberBoxNeighborsFreqMax input").val() > 1000) {
                $("#nubmberBoxNeighborsFreqMax input").val(1000);
            }
        });
        $("#nubmberBoxSatellitesFreqMin input").on('keyup', function (event) {
            if ($("#nubmberBoxSatellitesFreqMin input").val() > 1000) {
                $("#nubmberBoxSatellitesFreqMin input").val(1000);
            }
        });
        $("#nubmberBoxSatellitesFreqMax input").on('keyup', function (event) {
            if ($("#nubmberBoxSatellitesFreqMax input").val() > 1000) {
                $("#nubmberBoxSatellitesFreqMax input").val(1000);
            }
        });
        $("#nubmberBoxAppearanceMin input").on('keyup', function (event) {
            if (isDebugg) { console.log('31'); }
            if ($("#nubmberBoxAppearanceMin input").val() > 1000) {
                $("#nubmberBoxAppearanceMin input").val(1000);
            }
        });
        $("#nubmberBoxAppearanceMax input").on('keyup', function (event) {
            if ($("#nubmberBoxAppearanceMax input").val() > 1000) {
                $("#nubmberBoxAppearanceMax input").val(1000);
            }
        });
        $("#numberBoxDegree input").on('keyup', function (event) {
            if (isDebugg) { console.log('31d'); }
            if ($("#numberBoxDegree input").val() > 32) {
                $("#numberBoxDegree input").val(32);
            }
            if ($("#numberBoxDegree input").val() < 3) {
                $("#numberBoxDegree input").val(3);
            }
        });
        $("#numberBoxFilterThreshold input").on('keyup', function (event) {
            if (isDebugg) { console.log('31e'); }
            if ($("#numberBoxFilterThreshold input").val() > 1) {
                $("#numberBoxFilterThreshold input").val(1);
            }
            if ($("#numberBoxFilterThreshold input").val() < 0) {
                $("#numberBoxFilterThreshold input").val(0);
            }
        });
        $("#numberBoxClustersNumber input").on('keyup', function (event) {
            if (isDebugg) { console.log('31f'); }
            if ($("#numberBoxClustersNumber input").val() > 32) {
                $("#numberBoxClustersNumber input").val(32);
            }
            if ($("#numberBoxClustersNumber input").val() < 2) {
                $("#numberBoxClustersNumber input").val(2);
            }
        });

        $("#numberBoxEpsilon input").on('keyup mouseup change', function (event) {
            if (isDebugg) { console.log('31g'); }
            if ($("#numberBoxEpsilon input").val() > 100) {
                $("#numberBoxEpsilon input").val(100);
            }
            if ($("#numberBoxEpsilon input").val() < 0.1) {
                $("#numberBoxEpsilon input").val(0.1);
            }
        });


        var date1 = new Date();
        date1.setFullYear(2017, 7, 7);
        var date2 = new Date();
        date2.setFullYear(2017, 7, 15);
        elDateRangeCont.jqxDateTimeInput('setRange', date1, date2);
        // create jqxcalendar. END

        if (isDebugg) { console.log('33'); }
        if ($('#nubmberBoxValue2 > input:nth-child(1)').val() == "         0") {
            $('#nubmberBoxValue2 > input:nth-child(1)').val('');
        }
        if ($('#nubmberBoxValue1 > input:nth-child(1)').val() == "         0") {
            $('#nubmberBoxValue1 > input:nth-child(1)').val('');
        }
        if ($('#nubmberBoxNeighborsFreqMin > input:nth-child(1)').val() == "         0") {
            $('#nubmberBoxNeighborsFreqMin > input:nth-child(1)').val('');
        }
        if ($('#nubmberBoxNeighborsFreqMax > input:nth-child(1)').val() == "         0") {
            $('#nubmberBoxNeighborsFreqMax > input:nth-child(1)').val('');
        }
        if ($('#nubmberBoxSatellitesFreqMin > input:nth-child(1)').val() == "         0") {
            $('#nubmberBoxSatellitesFreqMin > input:nth-child(1)').val('');
        }
        if ($('#nubmberBoxSatellitesFreqMax > input:nth-child(1)').val() == "         0") {
            $('#nubmberBoxSatellitesFreqMax > input:nth-child(1)').val('');
        }
        $('#jqxWidgetDateRange .jqx-icon').click(function () {
            isResize = true;
            isCalendarClick = true;
            dataContanerWidth = Math.round($('#dataContaner').parent().width());
            dataContanerHeight = Math.round($('#dataContaner').parent().height());
            if (isDebugg) { console.log('34'); }
        });

        $('#formTab2').parent().click();
        $('#formTab3').parent().click();
        $('#formTab4').parent().click();
        $('#formTab1').parent().click();

        if (isDebugg) { console.log('35'); }
        if (isWidget == 0) {
            var buttonContentTab1 = $('.sampleFormContaner input[type=button].jqx-button')
                .closest(" tr[id^='rowWrap_el_'] ~  tr[id^='rowWrap_el_']>td>table");
        } else {
            var buttonContentTab1 = $('.sampleFormContaner input[type=button].jqx-button')
                .closest(" tr[id^='rowWrap_el_'] ~ tr[id^='rowWrap_el_']>td>table");
        }
        var buttonContentParentTab1 = buttonContentTab1.parent();
        $('#sampleFormButtonContaner .sampleFormInner').append(buttonContentTab1);
        $('#sampleFormButtonContaner .sampleFormInner td:first-child').css({ 'width': '30%', 'padding-left': '1px' });
        $('#sampleFormButtonContaner .sampleFormInner td:last-child').css('width', '30%');
        buttonContentParentTab1.find('table').remove();

        if (isDebugg) { console.log('36'); }
        var buttonContentTab2 = $('.sampleFormDrawingContaner input[type=button].jqx-button')
            .closest(" tr[id^='rowWrap_el_'] ~  tr[id^='rowWrap_el_']>td>table");
        var buttonContentParentTab2 = buttonContentTab2.parent();
        $('#sampleFormDrawingButtonContaner .sampleFormDrawingInner').append(buttonContentTab2);
        $('#sampleFormDrawingButtonContaner .sampleFormDrawingInner td:first-child').css({ 'width': '30%', 'padding-left': '1px' });
        $('#sampleFormDrawingButtonContanebbbbbr .sampleFormDrawingInner td:last-child').css('width', '30%');
        //buttonContentParentTab2.find('table').remove();

        if (isDebugg) { console.log('37'); }
        var buttonContentTab3 = $('.sampleFormAnalysisContaner input[type=button].jqx-button')
            .closest(" tr[id^='rowWrap_el_'] ~  tr[id^='rowWrap_el_']>td>table");
        var buttonContentParentTab3 = buttonContentTab3.parent();
        $('#sampleFormAnalysisButtonContaner .sampleFormAnalysisInner').append(buttonContentTab3);
        $('#sampleFormAnalysisButtonContaner .sampleFormAnalysisInner td:first-child').css({ 'width': '30%', 'padding-left': '1px' });
        $('#sampleFormAnalysisButtonContaner .sampleFormAnalysisInner td:last-child').css('width', '30%');
        //buttonContentParentTab3.find('table').remove();

        if (isDebugg) { console.log('38'); }
        var buttonContentTab4 = $('.sampleFormWordsContaner input[type=button].jqx-button')
            .closest(" tr[id^='rowWrap_el_'] ~  tr[id^='rowWrap_el_']>td>table");
        var buttonContentParentTab4 = buttonContentTab4.parent();
        $('#sampleFormWordsButtonContaner .sampleFormWordsInner').append(buttonContentTab4);
        $('#sampleFormWordsButtonContaner .sampleFormWordsInner td:first-child').css({ 'width': '30%', 'padding-left': '1px' });
        $('#sampleFormWordsButtonContaner .sampleFormWordsInner td:last-child').css('width', '30%');
        //buttonContentParentTab4.find('table').remove();
        //Radial
        window.wordsListHead.radialHead = {};
        window.wordsListHead.radialHead.row1 = '<tr id="theadTrRadial" class="wordsListHead">'
            + '<th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="70%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" class="wordsSizeColumn" id="wordsHeadValue">Value'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.radialHead.row2 = '<tr id="tbodyTrRadial" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="70%" class="wordsNameColumn">'
            + 'Check/uncheck all</td><td width="10%" class="wordsSizeColumn">'
            + '</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.radial = '<thead>'
            + window.wordsListHead.radialHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.radialHead.row2 + '</tbody>';
        //DendrogramHorizontal
        window.wordsListHead.dendrogramHorizontalHead = {};
        window.wordsListHead.dendrogramHorizontalHead.row1 = '<tr id="theadTrDendrogramHorizontal" class="wordsListHead">'
            + '<th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="70%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" class="wordsSizeColumn" id="wordsHeadValue">Value'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.dendrogramHorizontalHead.row2 = '<tr id="tbodyTrRadial" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="70%" class="wordsNameColumn">'
            + 'Check/uncheck all</td><td width="10%" class="wordsSizeColumn">'
            + '</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.dendrogramHorizontal = '<thead>'
            + window.wordsListHead.dendrogramHorizontalHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.dendrogramHorizontalHead.row2 + '</tbody>';
        //Molecular
        window.wordsListHead.molecularHead = {};
        window.wordsListHead.molecularHead.row1 = '<tr id="theadTrMolecular" class="wordsListHead"><th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="5%" class="wordsColorColumn" id="wordsHeadColor"></th>'
            + '<th width="65%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" class="wordsSizeColumn" id="wordsHeadValue">Value'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.molecularHead.row2 = '<tr id="tbodyTrMolecular" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="5%" class="wordsNumColumn"></td>'
            + '<td width="65%" class="wordsNameColumn">'
            + 'Check/uncheck all</td><td width="10%" class="wordsSizeColumn">'
            + '</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.molecular = '<thead>'
            + window.wordsListHead.molecularHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.molecularHead.row2 + '</tbody>';
        //TimeSeries
        window.wordsListHead.timeSeriesHead = {};
        window.wordsListHead.timeSeriesHead.row1 = '<tr id="theadTrTimeSeries" class="wordsListHead"><th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="5%" class="wordsColorColumn" id="wordsHeadColor"></th>'
            + '<th width="65%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" class="wordsSizeColumn" id="wordsHeadValue"><!-- Value -->'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.timeSeriesHead.row2 = '<tr id="tbodyTrTimeSeries" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="5%" class="wordsNumColumn"></td>'
            + '<td width="65%" class="wordsNameColumn" colspan="2">'
            + 'Check/uncheck all</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.timeSeries = '<thead>'
            + window.wordsListHead.timeSeriesHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.timeSeriesHead.row2 + '</tbody>';
        //Timeline
        window.wordsListHead.timelineHead = {};
        window.wordsListHead.timelineHead.row1 = '<tr id="theadTrTimeline" class="wordsListHead"><th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="5%" class="wordsColorColumn" id="wordsHeadColor"></th>'
            + '<th width="65%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" class="wordsSizeColumn" id="wordsHeadValue"><!-- Value -->'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.timelineHead.row2 = '<tr id="tbodyTrTimeline" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="5%" class="wordsNumColumn"></td>'
            + '<td width="65%" class="wordsNameColumn" colspan="2">'
            + 'Check/uncheck all</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.timeline = '<thead>'
            + window.wordsListHead.timelineHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.timelineHead.row2 + '</tbody>';
        //Streamgraph
        window.wordsListHead.streamgraphHead = {};
        window.wordsListHead.streamgraphHead.row1 = '<tr id="theadTrStreamgraph" class="wordsListHead"><th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="5%" class="wordsColorColumn" id="wordsHeadColor"></th>'
            + '<th width="65%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" class="wordsSizeColumn" id="wordsHeadValue"><!-- Value -->'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.streamgraphHead.row2 = '<tr id="tbodyTrStreamgraph" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="5%" class="wordsNumColumn"></td>'
            + '<td width="65%" class="wordsNameColumn" colspan="2">'
            + 'Check/uncheck all</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.streamgraph = '<thead>'
            + window.wordsListHead.streamgraphHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.streamgraphHead.row2 + '</tbody>';
        //Sankey
        window.wordsListHead.sankeyHead = {};
        window.wordsListHead.sankeyHead.row1 = '<tr id="theadTrSankey" class="wordsListHead"><th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="5%" class="wordsColorColumn" id="wordsHeadColor"></th>'
            + '<th width="65%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" class="wordsSizeColumn" id="wordsHeadValue"><!-- Value -->'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.sankeyHead.row2 = '<tr id="tbodyTrSankey" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="5%" class="wordsNumColumn"></td>'
            + '<td width="65%" class="wordsNameColumn" colspan="2">'
            + 'Check/uncheck all</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.sankey = '<thead>'
            + window.wordsListHead.sankeyHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.sankeyHead.row2 + '</tbody>';
        //Textual
        window.wordsListHead.textualWordsHead = {};
        window.wordsListHead.textualWordsHead.row1 = '<tr id="theadTrTextualWords" class="wordsListHead"><th width="10%" class="wordsNumColumn" id="wordsHeadNum">#</th>'
            + '<th width="65%" class="wordsNameColumn" id="wordsHeadName">Word'
            + '</th><th width="10%" id="wordsHeadDisable">Disable</th><th width="5%" id="wordsMetadata"></th></tr>';
        window.wordsListHead.textualWordsHead.row2 = '<tr id="tbodyTrTextualWords" class="wordsListAll">'
            + '<td width="10%" class="wordsNumColumn"></td>'
            + '<td width="65%" class="wordsNameColumn" style="text-align: right">'
            + 'Check/uncheck all</td><td width="10%"><input type="checkbox" name=""'
            + ' id="wordsCheckboxAll"'
            + '></td><td width="5%" class="wordsMetadata"></td></tr>';
        window.wordsListHead.textualWords = '<thead>'
            + window.wordsListHead.textualWordsHead.row1 + '</thead>'
            + '<tbody>' + window.wordsListHead.textualWordsHead.row2 + '</tbody>';

        var chartType = $('#chartType').val();
        if (chartType == CHART_TYPE_MOLECULAR) {
            var wordsListHead = window.wordsListHead.molecular;
        } else if (chartType == CHART_TYPE_RADIAL) {
            var wordsListHead = window.wordsListHead.radial;
        } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
            var wordsListHead = window.wordsListHead.dendrogramHorizontal;
        } else if (chartType == CHART_TYPE_TIMESERIES) {
            var wordsListHead = window.wordsListHead.timeSeries;
        } else if (chartType == CHART_TYPE_TIMELINE) {
            var wordsListHead = window.wordsListHead.timeLine;
        } else if (chartType == CHART_TYPE_STREAMGRAPH) {
            var wordsListHead = window.wordsListHead.streamgraph;
        } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
            var wordsListHead = window.wordsListHead.sankey;
        } else if (chartType == CHART_TYPE_TEXTUALWORDS) {
            var wordsListHead = window.wordsListHead.textualWords;
        }

        addColorpickersSelection(chartType);
        addColorpickerTextColor(chartType);


        //$('#checkboxIsChangeTab4').closest("tr[id^='rowWrap_el_']>td>table").prepend(wordsListHead);
        $('#wordsListContaner').closest("table").attr('id', 'wordsListTableContaner');
        $('#wordsListTableContaner thead').remove();
        $('#wordsListTableContaner tbody').remove();
        $('#wordsListTableContaner').prepend(wordsListHead);
        //Tooltips for the tab Words select
        //$('#wordsHeadNum').jqxTooltip({ position: 'top', autoHideDelay: 10000, content: 'An order number.' });

        // Create jqxTree
        if (isWidget == 0) {
            $('#jqxTree').jqxTree({ height: '500px', width: '172px' });
            $('#jqxTree').css('visibility', 'visible');
            var clickedItem = null;
            //disabledMenu hasSubMenuItems
            var attachContextMenu = function () {
                if (isDebugg) { console.log('39'); }
                $("#jqxTree li").on('click', function (event) {
                    var target = $(event.target).parents('li:first')[0];
                    window.prevChartType = $('#chartType').val();
                    setChartTypesConfiguration($(target).attr('id'));
                    if ($(target).hasClass('disabledMenu') || $(target).hasClass('hasSubMenuItems')) {
                        $('#jqxTree').jqxTree('disableItem', target);

                        $(target).removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $(target).removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
                        $(target).find('div').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $(target).find('div').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));

                        return false;
                    }
                });
                $('#jqxTree').on('select', function (event) {
                    var args = event.args;
                    var item = $('#jqxTree').jqxTree('getItem', args.element);
                    if ($('#' + item.id).hasClass('hasSubMenuItems')) {
                        $('#' + item.id).removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $('#' + item.id).removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
                        $('#' + item.id + ' div').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $('#' + item.id + ' div').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
                    }
                });
                // open the context menu when the user presses the mouse right button.
                $("#jqxTree li").on('mousedown', function (event) {
                    var target = $(event.target).parents('li:first')[0];
                    if ($(target).hasClass('disabledMenu') || $(target).hasClass('hasSubMenuItems')) {
                        if ($(target).hasClass('disabledMenu')) {
                            $('#jqxTree').jqxTree('disableItem', target);
                        }
                        $(target).removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $(target).removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
                        $(target).find('div').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $(target).find('div').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));

                        return false;
                    }
                    var rightClick = isRightClick(event);
                    if (rightClick && target != null) {
                        if ($(target).hasClass('disabledMenu') || $(target).hasClass('hasSubMenuItems')) {
                            if ($(target).hasClass('disabledMenu')) {
                                $('#jqxTree').jqxTree('disableItem', target);
                            }
                            return false;
                        } else {
                            $("#jqxTree").jqxTree('selectItem', target);
                            var scrollTop = $(window).scrollTop();
                            var scrollLeft = $(window).scrollLeft();
                            contextMenu.jqxMenu('open', parseInt(event.clientX) + 5 + scrollLeft, parseInt(event.clientY) + 5 + scrollTop);
                            return false;
                        }
                    }
                });
            }
            attachContextMenu();

            if (isDebugg) { console.log('40'); }
            // disable the default browser's context menu.
            $(document).on('contextmenu', function (e) {
                if (isDebugg) { console.log('41'); }
                if ($(e.target).parents('.jqx-tree').length > 0) {
                    return false;
                }
                return true;
            });
        }
        dataContanerWidth = Math.round($('#dataContaner').parent().width());
        dataContanerHeight = Math.round($('#dataContaner').parent().height());
        contentWidth = Math.round($('#content').width());
        contentHeight = Math.round($('#content').height());
        isResize = false;
        isCalendarClick = false;

        $("#chartMolecular").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartMolecular');
            if (firstLoadPage == 1) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            if ((typeof chartTypesConfiguration.chartMolecular !== "undefined")
                && (chartTypesConfiguration.chartMolecular.enabled === false)) {
                if ($("#jqxTree").find('li#chartMolecular').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartMolecular')[0]);
                }
            } else {
                $('#d3script').attr('src', 'http://d3js.org/d3.v3.min.js');
                if (isDebugg) { console.log('43'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                var chartType = $('#chartType').val();
                if (prevChartType != CHART_TYPE_MOLECULAR) {
                    window.molecularData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
                }
                window.radialData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.timelineData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.streamgraphData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_MOLECULAR);

                $('#lineWidthSliderElement').val(1);

                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });

        $('#wordsCheckboxAll').on('click', function (e) {
            if (isDebugg) { console.log('431'); }
            var elem = $(this);
            var checkboxList = $('.wordsCheckboxItem');
            if (elem.is(':checked')) {
                $.each(checkboxList, function (index, item) {
                    $(item).prop("checked", true);
                });
            } else {
                $.each(checkboxList, function (index, item) {
                    $(item).prop("checked", false);
                });
            }
        });
        $("#chartRadial").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartRadial');
            if (firstLoadPage == 1) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            if ((typeof chartTypesConfiguration.chartRadial !== "undefined")
                && (chartTypesConfiguration.chartRadial.enabled === false)) {
                if ($("#jqxTree").find('li#chartRadial').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartRadial')[0]);
                }
            } else {
                $('#d3script').attr('src', 'http://d3js.org/d3.v3.min.js');
                if (isDebugg) { console.log('44'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                var chartType = $('#chartType').val();
                if (prevChartType != CHART_TYPE_RADIAL) {
                    window.radialData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    firstLoadChart = 0;
                    $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
                }
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.molecularData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.timelineData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                window.streamgraphData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_RADIAL);

                $('#lineWidthSliderElement').val(1);

                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });
        //DendrogramHorizontal
        $("#chartDendrogram").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartDendrogram');
            if (firstLoadPage == 1) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(500);
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            if ((typeof chartTypesConfiguration.chartRadial !== "undefined")
                && (chartTypesConfiguration.chartRadial.enabled === false)) {
                if ($("#jqxTree").find('li#chartDendrogram').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartDendrogram')[0]);
                }
            } else {
                $('#d3script').attr('src', 'http://d3js.org/d3.v3.min.js');
                if (isDebugg) { console.log('44'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                var chartType = $('#chartType').val();
                if (prevChartType != CHART_TYPE_DENDROGRAMHORIZONTAL) {
                    window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    firstLoadChart = 0;
                    $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
                }

                $('#lineWidthSliderElement').val(0);

                window.radialData[chartIndex].wordsListReset = true;
                window.molecularData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.timelineData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                window.streamgraphData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_DENDROGRAMHORIZONTAL);
                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });

        $("#chartTimeSeries").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartTimeSeries');
            if (firstLoadPage == 1) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            //$('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            if ((typeof chartTypesConfiguration.chartTimeSeries !== "undefined")
                && (chartTypesConfiguration.chartTimeSeries.enabled === false)) {
                if ($("#jqxTree").find('li#chartTimeSeries').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartTimeSeries')[0]);
                }
            } else {
                var chartType = $('#chartType').val();
                if (isDebugg) { console.log('43'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                if (prevChartType != CHART_TYPE_TIMESERIES) {
                    window.timeSeriesData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(50);
                }
                window.timelineData[chartIndex].wordsListReset = true;
                window.molecularData[chartIndex].wordsListReset = true;
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.radialData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                window.streamgraphData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_TIMESERIES);

                $('#lineWidthSliderElement').val(1);

                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });
        $("#chartTimeLine").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartTimeLine');
            if (firstLoadPage == 1) {
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            var disabled = $('#chartTimeLine').jqxTree('disabled');
            if ((typeof chartTypesConfiguration.chartTimeLine !== "undefined")
                && (chartTypesConfiguration.chartTimeLine.enabled === false)) {
                if ($("#jqxTree").find('li#chartTimeLine').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartTimeLine')[0]);
                }
            } else {
                //$('#d3script').attr('src', 'http://d3js.org/d3.v5.min.js');
                if (isDebugg) { console.log('43'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                var chartType = $('#chartType').val();
                if (prevChartType != CHART_TYPE_TIMELINE) {
                    window.timelineData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
                }
                window.molecularData[chartIndex].wordsListReset = true;
                window.radialData[chartIndex].wordsListReset = true;
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                window.streamgraphData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_TIMELINE);
                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });

        $("#chartStreamgraph").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartStreamgraph');
            if (firstLoadPage == 1) {
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            var disabled = $('#chartStreamgraph').jqxTree('disabled');
            if ((typeof chartTypesConfiguration.chartStreamgraph !== "undefined")
                && (chartTypesConfiguration.chartStreamgraph.enabled === false)) {
                if ($("#jqxTree").find('li#chartStreamgraph').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartStreamgraph')[0]);
                }
            } else {
                //$('#d3script').attr('src', 'http://d3js.org/d3.v5.min.js');
                if (isDebugg) { console.log('43'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                var chartType = $('#chartType').val();
                if (prevChartType != CHART_TYPE_STREAMGRAPH) {
                    window.streamgraphData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
                }
                window.molecularData[chartIndex].wordsListReset = true;
                window.radialData[chartIndex].wordsListReset = true;
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.timelineData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_STREAMGRAPH);
                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });
        //Sankey
        $("#chartSankey").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartSankey');
            if (firstLoadPage == 1) {
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('2');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('2');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val('');
            var disabled = $('#chartSankey').jqxTree('disabled');
            if ((typeof chartTypesConfiguration.chartSankey !== "undefined")
                && (chartTypesConfiguration.chartSankey.enabled === false)) {
                if ($("#jqxTree").find('li#chartSankey').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartSankey')[0]);
                }
            } else {
                //$('#d3script').attr('src', 'http://d3js.org/d3.v5.min.js');
                if (isDebugg) { console.log('43'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                var chartType = $('#chartType').val();
                if (prevChartType != CHART_TYPE_SANKEY_NEIGHBORS1) {
                    window.sankeyData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    $('#nubmberBoxValueMaxWordsTotalTextual').jqxNumberInput({
                        width: '100%',
                        height: '30px',
                        decimal: '',
                        decimalDigits: 0,
                        min: 0,
                        max: 10000,
                        promptChar: '',
                        groupSeparator: ' ',
                        allowNull: false
                    });

                }
                window.molecularData[chartIndex].wordsListReset = true;
                window.radialData[chartIndex].wordsListReset = true;
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.timelineData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_SANKEY_NEIGHBORS1);
                
                $('#lineWidthSliderElement').val(1);
                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });

        $("#chartTextualWords").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartTextualWords');
            if (firstLoadPage == 1) {
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            /*if (firstLoadPage == 1) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            }*/
            if ((typeof chartTypesConfiguration.chartTextual.chartTextualWords !== "undefined")
                && (chartTypesConfiguration.chartTextual.chartTextualWords.enabled === false)) {
                if ($("#jqxTree").find('li#chartTextualWords').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartTextualWords')[0]);
                }
            } else {
                if (isDebugg) { console.log('43'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                var chartType = $('#chartType').val();
                if (prevChartType != CHART_TYPE_TEXTUALWORDS) {
                    window.textualWordsData[chartIndex].wordsListReset = true;
                    $('#wordsListTableContaner').find('.wordsList').remove();
                    $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
                }
                window.molecularData[chartIndex].wordsListReset = true;
                window.radialData[chartIndex].wordsListReset = true;
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.timelineData[chartIndex].wordsListReset = true;
                window.streamgraphData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#chartType').val(CHART_TYPE_TEXTUALWORDS);
                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });

        $("#chartTextualWordsMetadata").click(function () {
            if (typeof window.prevChartType === "undefined") {
                var prevChartType = $('#chartType').val();
            } else {
                var prevChartType = window.prevChartType;
            }
            setChartTypesConfiguration('chartTextualWordsMetadata');
            if (firstLoadPage == 1) {
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            }
            window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            $('#nubmberBoxValueMaxWordsTotalTextual > input:nth-child(1)').val(1000);
            /*if (firstLoadPage == 1) {
                $('#nubmberBoxValue1 > input:nth-child(1)').val(50);
            }*/
            if ((typeof chartTypesConfiguration.chartTextual.chartTextualWordsMetadata !== "undefined")
                && (chartTypesConfiguration.chartTextual.chartTextualWordsMetadata.enabled === false)) {
                if ($("#jqxTree").find('li#chartTextualWordsMetadata').length > 0) {
                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#chartTextualWordsMetadata')[0]);
                }
            } else {
                var chartType = $('#chartType').val();
                if (isDebugg) { console.log('43'); }
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                window.molecularData[chartIndex].wordsListReset = true;
                window.radialData[chartIndex].wordsListReset = true;
                window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                window.timeSeriesData[chartIndex].wordsListReset = true;
                window.timelineData[chartIndex].wordsListReset = true;
                window.textualWordsData[chartIndex].wordsListReset = true;
                window.streamgraphData[chartIndex].wordsListReset = true;
                window.sankeyData[chartIndex].wordsListReset = true;
                $('#wordsListTableContaner').find('.wordsList').remove();
                $('#chartType').val(CHART_TYPE_TEXTUALWORDSMETADATA);
                showHideTypeDependentItems();
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
            return false;
        });

        $('#home').click(function () {
            document.location.href = '/';
        });
        $('#dataContaner').on('resize', function (e) {
            if (isDebugg) { console.log('45'); }
            var elem = $(this);
            isResize = true;
            setTimeout(function () {
                if ((!isEqualSizeIndent(dataContanerWidth, Math.round($('#dataContaner').parent().width()), 2))
                    || (!isEqualSizeIndent(dataContanerHeight, Math.round($('#dataContaner').parent().height()), 5))) {
                    dataContanerResize(true);
                } else {
                    dataContanerResize();
                }
                setFormTooltipTabs();
            }, 500);
        }, 250);
        $('#content').on('resize', function (e) {
            if (isDebugg) { console.log('46'); }
            curDataContanerHeight = Math.round($('#dataContaner').parent().height());
            isResize = true;
            setTimeout(function () {
                if ((!isEqualSizeIndent(dataContanerWidth, Math.round($('#dataContaner').parent().width()), 2))
                    || (!isEqualSizeIndent(dataContanerHeight, Math.round($('#dataContaner').parent().height()), 5))) {
                    dataContanerResize(true);
                } else {
                    dataContanerResize();
                }
                setFormTooltipTabs();
            }, 500);
        });

        // Add contaner for the Loader
        if (isWidget == 1) {
            $('#jqxLoaderWidget').jqxLoader({ width: '98%', height: '50%', imagePosition: 'center' });
        } else {
            $('#jqxLoader').jqxLoader({ width: '98%', height: '50%', imagePosition: 'center' });
        }

        changeLayoutToTable(true);
        if (isDebugg) { console.log('47'); }
        $(window).on('resize', function (e) {
            $('#content').width($('#ownerPanel').width() - 2);
            setTimeout(function () {
                if (isDebugg) { console.log('48'); }
                if ((!isEqualSizeIndent(dataContanerWidth, Math.round($('#dataContaner').parent().width()), 2))
                    || (!isEqualSizeIndent(dataContanerHeight, Math.round($('#dataContaner').parent().height()), 5))) {
                    if (isDebugg) { console.log('472', 'window resize 2'); }
                    dataContanerResize(false);
                } else {
                    if (isDebugg) { console.log('473', 'dataContanerResize()'); }
                    dataContanerResize(true);
                }
                setFormTooltipTabs();
            }, 500);

            if ($('#chartType').val() == CHART_TYPE_TIMELINE) {
                setTimeout(function () {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    if (typeof window.timelineData[chartIndex].colorsDrawArr != "undefined") {
                        $.each(window.timelineData[chartIndex].colorsDrawArr, function (index, item) {
                            $('.highcharts-legend-item.highcharts-series-' + index + ' .highcharts-point').attr('fill', item);
                        });
                    }
                }, 1500);
            }

        });
        $(".sampleFormContaner").keypress(function (e) {
            if (isDebugg) { console.log('49'); }
            if (e.which == 13) {
                var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                $(submitButtonSelector).click();
            }
        });
        var dropdownDatesInterval = $('#dropdownDatesInterval');
        dropdownDatesInterval.on('change', function (event) {
            isChangeDropdownDatesInterval = 1;
            var dropdownDatesIntervalValue = $('#dropdownDatesInterval').val();

            setDatesInterval(dropdownDatesIntervalValue, $('#inputjqxWidgetDateRange').val());
            setTimeout(function () {
                isChangeDropdownDatesInterval = 0;
            }, 1000);
        });


        $('#sizeSliderElement, #sizeSliderElement .jqx-slider-left').mouseup(function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.circleSizeMult
                            = window.newValuesFormTab2.circleSizeMult
                            = new Number($('#sizeSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTS, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.circleSizeMult
                            = window.newValuesFormTab2.circleSizeMult
                            = new Number($('#sizeSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTL, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.circleSizeMult
                            = window.newValuesFormTab2.circleSizeMult
                            = new Number($('#sizeSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartDH, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.circleSizeMult
                            = window.newValuesFormTab2.circleSizeMult
                            = new Number($('#sizeSliderElement').val() * 1).toPrecision(3);
                            showChart(curDataChartSK, window.newValuesFormTab1);
                    }, 500);
                }

            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.circleSizeMult
                            = window.newValuesFormTab2.circleSizeMult
                            = new Number($('#sizeSliderElement').val() * 1).toPrecision(3);
                        showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                    }, 500);
                }
            }
        });

        $('#fontSizeSliderElement, #fontSizeSliderElement .jqx-slider-left').mouseup(function (event) {
            var chartType = $('#chartType').val();
            if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_MOLECULAR)) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.fontSizeMult
                            = window.newValuesFormTab2.fontSizeMult
                            = new Number($('#fontSizeSliderElement').val() * 1).toPrecision(3);
                        showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                    }, 500);
                }
            }
            if ((chartType == CHART_TYPE_STREAMGRAPH)) {
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.fontSizeMult
                            = window.newValuesFormTab2.fontSizeMult
                            = new Number($('#fontSizeSliderElement').val() * 1).toPrecision(3);
                        showChart(JSON.parse(curDataChartSG), window.newValuesFormTab1);
                    }, 500);
                }
            }
            if ((chartType == CHART_TYPE_SANKEY_NEIGHBORS1)) {
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.fontSizeMult
                            = window.newValuesFormTab2.fontSizeMult
                            = new Number($('#fontSizeSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartSK, window.newValuesFormTab1);
                    }, 500);
                }
            }
            if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.fontSizeMult
                            = window.newValuesFormTab2.fontSizeMult
                            = new Number($('#fontSizeSliderElement').val() * 1).toPrecision(3);
                        showChart(JSON.parse(curDataChartDH), window.newValuesFormTab1);
                    }, 500);
                }
            }
        });

        $('#nubmberBoxValueFontSize > input:nth-child(1)').on('keyup mouseup change', function (event) {
            var chartType = $('#chartType').val();
            if ((chartType == CHART_TYPE_RADIAL) || (chartType == CHART_TYPE_MOLECULAR)) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        if ($('#nubmberBoxValueFontSize > input:nth-child(1)').val() == '') {
                            var nubmberBoxValueFontSize = '';
                        } else {
                            var nubmberBoxValueFontSize = new Number($('#nubmberBoxValueFontSize > input:nth-child(1)').val() * 1);
                        }
                        window.newValuesFormTab1.nubmberBoxValueFontSize
                            = window.newValuesFormTab2.nubmberBoxValueFontSize
                            = nubmberBoxValueFontSize;
                        showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                    }, 500);
                }
            }
            if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        if ($('#nubmberBoxValueFontSize > input:nth-child(1)').val() == '') {
                            var nubmberBoxValueFontSize = '';
                        } else {
                            var nubmberBoxValueFontSize = new Number($('#nubmberBoxValueFontSize > input:nth-child(1)').val() * 1);
                        }
                        window.newValuesFormTab1.nubmberBoxValueFontSize
                            = window.newValuesFormTab2.nubmberBoxValueFontSize
                            = nubmberBoxValueFontSize;
                        showChart(JSON.parse(curDataChartDH), window.newValuesFormTab1);
                    }, 500);
                }
            }
            if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        if ($('#nubmberBoxValueFontSize > input:nth-child(1)').val() == '') {
                            var nubmberBoxValueFontSize = '';
                        } else {
                            var nubmberBoxValueFontSize = new Number($('#nubmberBoxValueFontSize > input:nth-child(1)').val() * 1);
                        }
                        window.newValuesFormTab1.nubmberBoxValueFontSize
                            = window.newValuesFormTab2.nubmberBoxValueFontSize
                            = nubmberBoxValueFontSize;
                        showChart(curDataChartSK, window.newValuesFormTab1);
                    }, 500);
                }
            }

        });

        $('#sizeSourcePointsSliderElement, #sizeSourcePointsSliderElement .jqx-slider-left').mouseup(function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.sourcePointsSizeMult
                            = window.newValuesFormTab2.sourcePointsSizeMult
                            = new Number($('#sizeSourcePointsSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTS, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.sourcePointsSizeMult
                            = window.newValuesFormTab2.sourcePointsSizeMult
                            = new Number($('#sizeSourcePointsSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTL, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.sourcePointsSizeMult
                            = window.newValuesFormTab2.sourcePointsSizeMult
                            = new Number($('#sizeSourcePointsSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartSG, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.sourcePointsSizeMult
                            = window.newValuesFormTab2.sourcePointsSizeMult
                            = new Number($('#sizeSourcePointsSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartSK, window.newValuesFormTab1);
                    }, 500);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.sourcePointsSizeMult
                            = window.newValuesFormTab2.sourcePointsSizeMult
                            = new Number($('#sizeSourcePointsSliderElement').val() * 1).toPrecision(3);
                        showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                    }, 500);
                }
            }
        });
        $('#lineWidthSliderElement, #lineWidthSliderElement .jqx-slider-left').mouseup(function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTS, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTL, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartSG, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartSK, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_RADIAL) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChart, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartDH, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_MOLECULAR) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        //console.log(curDataChart);
                        showChart(curDataChart, window.newValuesFormTab1);
                    }, 500);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                    }, 500);
                }
            }
        });
        $('#gravitySliderElement, #gravitySliderElement .jqx-slider-left').mouseup(function () {
            if (typeof curDataChart != "undefined") {
                initPrepareFormData();
                setTimeout(function () {
                    window.newValuesFormTab1.gravityValue
                        = window.newValuesFormTab2.gravityValue
                        = new Number($('#gravitySliderElement').val() * 1).toPrecision(3);
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }, 500);
            }
        });
        $('#indentSliderElement, #indentSliderElement .jqx-slider-left').mouseup(function (event) {
            if (typeof curDataChart != "undefined") {
                initPrepareFormData();
                setTimeout(function () {
                    window.newValuesFormTab1.indentValue
                        = window.newValuesFormTab2.indentValue
                        = new Number($('#indentSliderElement').val() * 1).toPrecision(3);
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }, 500);
                //showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        });
        $('#radiusSliderElement, #radiusSliderElement .jqx-slider-left').mouseup(function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTS, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartTL, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_RADIAL) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChart, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(curDataChartDH, window.newValuesFormTab1);
                    }, 500);
                }
            } else if (chartType == CHART_TYPE_MOLECULAR) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        //console.log(curDataChart);
                        showChart(curDataChart, window.newValuesFormTab1);
                    }, 500);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    setTimeout(function () {
                        window.newValuesFormTab1.lineWidthMult
                            = window.newValuesFormTab2.lineWidthMult
                            = new Number($('#lineWidthSliderElement').val() * 1).toPrecision(3);
                        showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                    }, 500);
                }
            }
        });
        var checkboxConnectorsLines = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxConnectorsLines');
        checkboxConnectorsLines.on('change', function (event) {
            if (typeof curDataChart != "undefined") {
                //Set form tab values to the window object.
                initPrepareFormData();
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        });

        $('.valueIndicatorCheckboxItem').on('click', function () {
            window.newValuesFormTab1.drawingValueIndicators = getDrawingValueIndicators();
            if (typeof curDataChartTW != "undefined") {
                initPrepareFormData();
                if (typeof curDataChartTW === "string") {
                    showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartTW, window.newValuesFormTab1);
                }
            }
        });


        var dropdownMaxNodes = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownMaxNodes');
        dropdownMaxNodes.on('change', function (event) {
            if (typeof curDataChart != "undefined") {
                //Set form tab values to the window object.
                initPrepareFormData();
                resetWordsList(CHART_TYPE_MOLECULAR, chartIndex, 0);
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                if (window.molecularData[chartIndex].force) {
                    window.molecularData[chartIndex].force.stop();
                }
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
            if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
                if (typeof curDataChartDH != "undefined") {
                    //Set form tab values to the window object.
                    initPrepareFormData();
                    resetWordsList(CHART_TYPE_DENDROGRAMHORIZONTAL, chartIndex, 0);
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    showChart(JSON.parse(curDataChartDH), window.newValuesFormTab1);
                }
            }
        });

        var dropdownChartView = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartView');
        dropdownChartView.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            }
        });

        var checkboxShowHideLegend = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideLegend');
        checkboxShowHideLegend.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    /*if (checkboxShowHideLegend.val()) {
                        chartHC.update({legend: {
                            enabled: true
                        }});
                    } else {
                        chartHC.update({legend: {
                            enabled: false
                        }});
                    }*/
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    /*if (checkboxShowHideLegend.val()) {
                        chartTimelineHC.update({legend: {
                            enabled: true
                        }});
                    } else {
                        chartTimelineHC.update({legend: {
                            enabled: false
                        }});
                    }*/
                    showChart(curDataChartTL, window.newValuesFormTab1);
                    setTimeout(function () {
                        if (typeof chartIndex === "undefined") {
                            chartIndex = 0;
                        }
                        if (typeof window.timelineData[chartIndex].colorsDrawArr != "undefined") {
                            $.each(window.timelineData[chartIndex].colorsDrawArr, function (index, item) {
                                $('.highcharts-legend-item.highcharts-series-' + index + ' .highcharts-point').attr('fill', item);
                            });
                        }
                    }, 1000);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var checkboxShowHideSeriesLabel = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideSeriesLabel');
        checkboxShowHideSeriesLabel.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var checkboxShowHideTitle = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTitle');
        checkboxShowHideTitle.on('change', function (event) {
            showHideTitle();
        });

        var checkboxAddNodesFixLevel = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAddNodesFixLevel');
        checkboxAddNodesFixLevel.on('change', function (event) {
            initPrepareFormData();
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
            //if (firstLoadChart != 1) {
                $(submitButtonSelector).click();
            //}
        });

        var checkboxShowHideMenu = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMenu');
        checkboxShowHideMenu.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var checkboxShowHideTableHeader = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTableHeader');
        checkboxShowHideTableHeader.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (typeof curDataChartTW != "undefined") {
                initPrepareFormData();
                if (typeof curDataChartTW === "string") {
                    showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartTW, window.newValuesFormTab1);
                }
            }
        });
        var checkboxOrientationHorizontalVertical = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical');
        checkboxOrientationHorizontalVertical.on('change', function (event) {
            window.newValuesFormTab1.checkboxOrientationHorizontalVertical = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical').val();
            var chartType = $('#chartType').val();
            if (($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) && (typeof curDataChartSK != "undefined")) {
                if (typeof curDataChartSK === "string") {
                    showChart(JSON.parse(curDataChartSK), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) && typeof curDataChartDH != "undefined") {
                if (typeof curDataChartDH === "string") {
                    showChart(JSON.parse(curDataChartDH), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            }
        });

        var checkboxShowHideMetadataColumn = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMetadataColumn');
        checkboxShowHideMetadataColumn.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (typeof curDataChartTW != "undefined") {
                initPrepareFormData();
                if (typeof curDataChartTW === "string") {
                    showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartTW, window.newValuesFormTab1);
                }
            }
        });

        var checkboxShowHidePagination = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHidePagination');
        checkboxShowHidePagination.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (typeof curDataChartTW != "undefined") {
                initPrepareFormData();
                if (typeof curDataChartTW === "string") {
                    showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartTW, window.newValuesFormTab1);
                }
            }
        });

        var checkboxConnectNulls = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxConnectNulls');
        checkboxConnectNulls.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    //initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var dropdownConnectorLinesSource = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLinesSource');
        dropdownConnectorLinesSource.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    //initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var checkboxSourceDataPoints = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxSourceDataPoints');
        checkboxSourceDataPoints.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    //initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var checkboxAxisTurnOnOff = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAxisTurnOnOff');
        checkboxAxisTurnOnOff.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var dropdownAutoAdjust = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownAutoAdjust');
        dropdownAutoAdjust.on('change', function (event) {
            var autoAdjustVal = $("#jqxWidgetAutoAdjust").jqxDropDownList('val');
            if (typeof autoAdjustVal != "undefined") {
                autoAdjustVal *= 1;
                var indentValue = new Number($('#indentSliderElement').val()).toPrecision(3);
                if (typeof indentValue != "undefined") {
                    indentValue *= 1;
                } else {
                    indentValue = -300;
                }
                if (autoAdjustVal > 2) {
                    if (indentValue == -300) {
                        indentValue = -100;
                    }
                } else {
                    if (indentValue == -100) {
                        indentValue = -300;
                    }
                }
                $('#indentSliderElement').val(indentValue);
            }
            if (typeof curDataChart != "undefined") {
                //Set form tab values to the window object.
                initPrepareFormData();
                if (typeof chartIndex === "undefined") {
                    chartIndex = 0;
                }
                if (firstLoadChart == 1 && isShare && hash != '') {
                    window.molecularData[chartIndex].fixedNodesArr = {};
                    window.newValuesFormTab1.fixedNodesArr = {};
                }
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        });
        var dropdownAutoAdjustCnt = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownAutoAdjustCnt');
        dropdownAutoAdjustCnt.on('change', function (event) {
            if (typeof curDataChart != "undefined") {
                //Set form tab values to the window object.
                initPrepareFormData();
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        });
        var checkboxIndentValuesRelated = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxIndentValuesRelated');
        checkboxIndentValuesRelated.on('change', function (event) {
            if (typeof curDataChart != "undefined") {
                //Set form tab values to the window object.
                initPrepareFormData();
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        });
        var checkboxNotIncludeSingleNodes = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxNotIncludeSingleNodes');
        checkboxNotIncludeSingleNodes.on('change', function (event) {
            if (typeof curDataChart != "undefined") {
                //Set form tab values to the window object.
                initPrepareFormData();
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        });
        var checkboxReduceOverlap = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxReduceOverlap');
        checkboxReduceOverlap.on('change', function (event) {
            if (typeof curDataChart != "undefined") {
                //Set form tab values to the window object.
                initPrepareFormData();
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        });
        var checkboxShowHideSeriesLabel = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideSeriesLabel');
        checkboxShowHideSeriesLabel.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timrseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });
        var checkboxShowHideTitle = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTitle');
        checkboxShowHideTitle.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });
        var checkboxAddNodesFixLevel = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAddNodesFixLevel');
        checkboxAddNodesFixLevel.on('change', function (event) {
            initPrepareFormData();
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
            if (firstLoadChart != 1) {
                $(submitButtonSelector).click();
            }
        });

        var checkboxShowHideMenu = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMenu');
        checkboxShowHideMenu.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });
        var checkboxShowHideTableHeader = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideTableHeader');
        checkboxShowHideTableHeader.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (typeof curDataChartTW != "undefined") {
                initPrepareFormData();
                if (typeof curDataChartTW === "string") {
                    showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartTW, window.newValuesFormTab1);
                }
            }
        });
        var checkboxOrientationHorizontalVertical = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical');
        checkboxOrientationHorizontalVertical.on('change', function (event) {
            window.newValuesFormTab1.checkboxOrientationHorizontalVertical = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxOrientationHorizontalVertical').val();
            var chartType = $('#chartType').val();
            if (($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) && (typeof curDataChartSK != "undefined")) {
                if (typeof curDataChartSK === "string") {
                    showChart(JSON.parse(curDataChartSK), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) && typeof curDataChartDH != "undefined") {
                if (typeof curDataChartDH === "string") {
                    showChart(JSON.parse(curDataChartDH), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            }
        });
        var checkboxShowHideMetadataColumn = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHideMetadataColumn');
        checkboxShowHideMetadataColumn.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (typeof curDataChartTW != "undefined") {
                initPrepareFormData();
                if (typeof curDataChartTW === "string") {
                    showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartTW, window.newValuesFormTab1);
                }
            }
        });
        var checkboxShowHidePagination = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxShowHidePagination');
        checkboxShowHidePagination.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (typeof curDataChartTW != "undefined") {
                initPrepareFormData();
                if (typeof curDataChartTW === "string") {
                    showChart(JSON.parse(curDataChartTW), window.newValuesFormTab1);
                } else {
                    showChart(curDataChartTW, window.newValuesFormTab1);
                }
            }
        });
        var checkboxConnectNulls = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxConnectNulls');
        checkboxConnectNulls.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    //initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });
        var dropdownConnectorLinesSource = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLinesSource');
        dropdownConnectorLinesSource.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    //initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });
        var dropdownColorsSelection = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSelection');
        dropdownColorsSelection.on('change', function (event) {
            var chartType = $('#chartType').val();
            var dropdownColorsSelectionVal = $('.form-dropdown-colors-selection-label').closest("tr").find('input').val();
            var dropdownColorsSetVal = $('.form-dropdown-colors-set-label').closest("tr").find('input').val();
            if (dropdownColorsSelectionVal * 1 == 10) {
                if (dropdownColorsSetVal * 1 <= 8) {
                    var dropdownColorsSetAutoSelect = 1;
                    window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSet').jqxDropDownList('selectIndex', 10);
                }
            } else {
                if ((dropdownColorsSetVal * 1 >= 10) && (dropdownColorsSetVal * 1 <= 12)) {
                    var dropdownColorsSetAutoSelect = 1;
                    window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSet').jqxDropDownList('selectIndex', 0);
                }
            }
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.timelineData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.streamgraphData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.sankeyData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });

        var dropdownConnectorLineWidthIndicator = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLineWidthIndicator');
        dropdownConnectorLineWidthIndicator.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    //showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    //showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    //showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });


        var dropdownConnectorLinesColor = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownConnectorLinesColor');
        dropdownConnectorLinesColor.on('change', function (event) {

            var chartType = $('#chartType').val();
            var dropdownConnectorLinesColorVal = $('.form-connector-lines-color-label').closest("tr").find('input').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    //showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.timelineData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.streamgraphData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    //showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.sankeyData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    //showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });
        var dropdownColorsSet = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownColorsSet');
        dropdownColorsSet.on('change', function (event) {
            if ((typeof dropdownColorsSetAutoSelect != "undefined") && (dropdownColorsSetAutoSelect == 1)) {
                dropdownColorsSetAutoSelect = 0;
            } else {
                var chartType = $('#chartType').val();
                if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                    if (typeof curDataChartTS != "undefined") {
                        if (typeof chartIndex === "undefined") {
                            chartIndex = 0;
                        }
                        window.timeSeriesData[chartIndex].wordsListReset = true;
                        initPrepareFormData();
                        showChart(curDataChartTS, window.newValuesFormTab1);
                    }
                } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                    if (typeof curDataChartTL != "undefined") {
                        if (typeof chartIndex === "undefined") {
                            chartIndex = 0;
                        }
                        window.timelineData[chartIndex].wordsListReset = true;
                        initPrepareFormData();
                        showChart(curDataChartTL, window.newValuesFormTab1);
                    }
                } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                    if (typeof curDataChartSG != "undefined") {
                        if (typeof chartIndex === "undefined") {
                            chartIndex = 0;
                        }
                        window.streamgraphData[chartIndex].wordsListReset = true;
                        initPrepareFormData();
                        showChart(curDataChartSG, window.newValuesFormTab1);
                    }
                } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                    if (typeof curDataChartSK != "undefined") {
                        if (typeof chartIndex === "undefined") {
                            chartIndex = 0;
                        }
                        window.sankeyData[chartIndex].wordsListReset = true;
                        initPrepareFormData();
                        showChart(curDataChartSK, window.newValuesFormTab1);
                    }
                } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                    if (typeof curDataChartDH != "undefined") {
                        if (typeof chartIndex === "undefined") {
                            chartIndex = 0;
                        }
                        window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
                        initPrepareFormData();
                        showChart(curDataChartDH, window.newValuesFormTab1);
                    }
                } else {
                    if (typeof curDataChart != "undefined") {
                        initPrepareFormData();
                        if (typeof chartIndex === "undefined") {
                            chartIndex = 0;
                        }
                        var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                        $(submitButtonSelector).click();
                    }
                }
            }
        });
        var dropdownBallsText = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsText');
        dropdownBallsText.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.timeSeriesData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    window.timelineData[chartIndex].wordsListReset = true;
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });
        var dropdownBallsTextLayout = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsTextLayout');
        dropdownBallsTextLayout.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_RADIAL) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });
        var dropdownChartBackgroundColor = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartBackgroundColor');
        dropdownChartBackgroundColor.on('change', function (event) {
            chartBackgroundColor = dropdownChartBackgroundColor.val();
            if ((chartBackgroundColor == 0)) {
                chartBackgroundColor = setAutoBackgroundColor();
            }
            $('#dataContaner').css('background-color', chartBackgroundColor);
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });
        var dropdownChartTextColor = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownChartTextColor');
        dropdownChartTextColor.on('change', function (event) {
            var chartType = $('#chartType').val();
            var textColorType = $('.form-dropdown-chart-text-color-label').closest("tr").find('input').val();
            window.newValuesFormTab1.dropdownChartTextColor = textColorType;
            showHideTypeDependentItems();
            if (chartType == CHART_TYPE_MOLECULAR) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    if (textColorType != 10) {
                        var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                        $(submitButtonSelector).click();
                    }
                }
            }
        });
        var dropdownBallsText = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsText');
        dropdownBallsText.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    //initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });
        var dropdownBallsTextLayout = window.sampleFormDrawing.jqxForm('getComponentByName', 'dropdownBallsTextLayout');
        dropdownBallsTextLayout.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_RADIAL) {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    if (typeof chartIndex === "undefined") {
                        chartIndex = 0;
                    }
                    var submitButtonSelector = $('#sampleForm').jqxForm('getComponentByName', 'submitButton').selector;
                    $(submitButtonSelector).click();
                }
            }
        });
        var checkboxSourceDataPoints = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxSourceDataPoints');
        checkboxSourceDataPoints.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    //initPrepareFormData();
                    //showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });
        var checkboxAxisTurnOnOff = window.sampleFormDrawing.jqxForm('getComponentByName', 'checkboxAxisTurnOnOff');
        checkboxAxisTurnOnOff.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var textBoxTitleTimeCharts = window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxTitleTimeCharts');
        textBoxTitleTimeCharts.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseres
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var textBoxSubtitleTimeCharts = window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxSubtitleTimeCharts');
        textBoxSubtitleTimeCharts.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        var textBoxDescriptionTimeCharts = window.sampleFormDrawing.jqxForm('getComponentByName', 'textBoxDescriptionTimeCharts');
        textBoxDescriptionTimeCharts.on('change', function (event) {
            var chartType = $('#chartType').val();
            if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
                if (typeof curDataChartTS != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTS, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
                if (typeof curDataChartTL != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartTL, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
                if (typeof curDataChartSG != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSG, window.newValuesFormTab1);
                }
            } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                if (typeof curDataChartSK != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartSK, window.newValuesFormTab1); 
                }
            } else if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                if (typeof curDataChartDH != "undefined") {
                    initPrepareFormData();
                    showChart(curDataChartDH, window.newValuesFormTab1);
                }
            } else {
                if (typeof curDataChart != "undefined") {
                    initPrepareFormData();
                    showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
                }
            }
        });

        dropdownClusterizationModel = window.sampleFormAnalysis.jqxForm('getComponentByName', 'dropdownClusterizationModel');
        dropdownClusterizationModel.on('change', function (event) {
            showHideTypeDependentItems();
        });
        prepareFixedNodesData();
        saveCurrenFormDataSelectValues();
        var chartType = $('#chartType').val();
        if (chartType == CHART_TYPE_TIMESERIES) {//timeseries
            if (typeof curDataChartTS != "undefined") {
                initPrepareFormData();
                showChart(curDataChartTS, window.newValuesFormTab1);
            }
        } else if (chartType == CHART_TYPE_TIMELINE) {//timeline
            if (typeof curDataChartTL != "undefined") {
                initPrepareFormData();
                showChart(curDataChartTL, window.newValuesFormTab1);
            }
        } else if (chartType == CHART_TYPE_STREAMGRAPH) {//streamgraph
            if (typeof curDataChartSG != "undefined") {
                initPrepareFormData();
                showChart(curDataChartSG, window.newValuesFormTab1);
            }
        } else if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
            if (typeof curDataChartSK != "undefined") {
                initPrepareFormData();
                showChart(curDataChartSK, window.newValuesFormTab1);
            }
        } else {
            if (typeof curDataChart != "undefined") {
                initPrepareFormData();
                showChart(JSON.parse(curDataChart), window.newValuesFormTab1);
            }
        }
        if (isWidget == 0) {
            selectMenuItemByChartType(chartType);
        }
        $('.InfoPanelParentContaner').closest('.jqx-layout-group-tabbed').find('.jqx-layout-pseudo-window-pin-icon').click();

        $("#customWordsColorsFile").on('keyup mouseup change', function (event) {
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            window.radialData[chartIndex].wordsListReset = true;
            window.molecularData[chartIndex].wordsListReset = true;
            window.timeSeriesData[chartIndex].wordsListReset = true;
            window.timelineData[chartIndex].wordsListReset = true;
            window.streamgraphData[chartIndex].wordsListReset = true;
            window.sankeyData[chartIndex].wordsListReset = true;
            window.textualWordsData[chartIndex].wordsListReset = true;
            window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
        });
        $("#synonymsWordsFileCustom").on('keyup mouseup change', function (event) {
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            window.radialData[chartIndex].wordsListReset = true;
            window.molecularData[chartIndex].wordsListReset = true;
            window.timeSeriesData[chartIndex].wordsListReset = true;
            window.timelineData[chartIndex].wordsListReset = true;
            window.streamgraphData[chartIndex].wordsListReset = true;
            window.sankeyData[chartIndex].wordsListReset = true;
            window.textualWordsData[chartIndex].wordsListReset = true;
            window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
        });
        $("#synonymsWordsCustom").on('keyup mouseup change', function (event) {
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            window.radialData[chartIndex].wordsListReset = true;
            window.molecularData[chartIndex].wordsListReset = true;
            window.timeSeriesData[chartIndex].wordsListReset = true;
            window.timelineData[chartIndex].wordsListReset = true;
            window.streamgraphData[chartIndex].wordsListReset = true;
            window.sankeyData[chartIndex].wordsListReset = true;
            window.textualWordsData[chartIndex].wordsListReset = true;
            window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
            window.newValuesFormTab1.synonymsWordsCustom = window.newValuesFormTab4.synonymsWordsCustom =  $('#synonymsWordsCustom').val();
        });
        $("#blacklistWordsFileCustom").on('keyup mouseup change', function (event) {
            if (typeof chartIndex === "undefined") {
                chartIndex = 0;
            }
            window.radialData[chartIndex].wordsListReset = true;
            window.molecularData[chartIndex].wordsListReset = true;
            window.timeSeriesData[chartIndex].wordsListReset = true;
            window.timelineData[chartIndex].wordsListReset = true;
            window.streamgraphData[chartIndex].wordsListReset = true;
            window.sankeyData[chartIndex].wordsListReset = true;
            window.textualWordsData[chartIndex].wordsListReset = true;
            window.dendrogramHorizontalData[chartIndex].wordsListReset = true;
        });
    }

    setTimeout(function () {
        addPlotToolsPanel();
        if (firstLoadChart == 1 && isShare && hash != '') {
        } else if (firstLoadPage == 1) {
            if ($('#chartType').val() == CHART_TYPE_MOLECULAR) {//molecular
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            } else if ($('#chartType').val() == CHART_TYPE_RADIAL) {//radial
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            } else if ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL) {//
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('1');
            } else if ($('#chartType').val() == CHART_TYPE_TIMESERIES) {//time series
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            } else if ($('#chartType').val() == CHART_TYPE_TIMELINE) {//timeline
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            } else if ($('#chartType').val() == CHART_TYPE_STREAMGRAPH) {//streamgraph
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            } else if ($('#chartType').val() == CHART_TYPE_SANKEY_NEIGHBORS1) {//sankey
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            } else {
                window.sampleForm.jqxForm('getComponentByName', 'dropdownValueMaxDepth').val('');
            }
        }
        addColorpickersSelection($('#chartType').val());
        addColorpickerTextColor($('#chartType').val());
    }, 1000);
}

function addPlotToolsPanel() {
    if ($('#plotToolsPanel').length == 0) {
        var dataContanerUL = $('#dataContaner')
            .closest(".jqx-layout-group-default").find('ul');
        dataContanerUL.append('<li id="plotToolsPanel"></li>');
        $('#plotToolsPanel')
            .append(getIconSVG('downloadCsv'))
            .append(getIconSVG('downloadImg'));

    } else {
        if (($('#chartType').val() == CHART_TYPE_TEXTUALWORDS) || ($('#chartType').val() == CHART_TYPE_TIMESERIES)) {
            if ($('#downloadPNG').length > 0) {
                $('#downloadPNG').remove();
            }
        }
        if (($('#chartType').val() == CHART_TYPE_MOLECULAR)
            || ($('#chartType').val() == CHART_TYPE_RADIAL)
            || ($('#chartType').val() == CHART_TYPE_DENDROGRAMHORIZONTAL)) {
            if ($('#downloadCsv').length > 0) {
                $('#downloadCsv').remove();
            }
        }

        if ($('#downloadCsv').length == 0) {
            $('#plotToolsPanel').append(getIconSVG('downloadCsv'))
        }
        if ($('#downloadPNG').length == 0) {
            $('#plotToolsPanel').append(getIconSVG('downloadImg'))
        }
    }
}

/**
 * Helper function for attachContextMenu() function.
 * @param event
 * @returns
 */
function isRightClick(event) {
    if (isDebugg) { console.log('42'); }
    var rightclick;
    if (!event) var event = window.event;
    if (event.which) rightclick = (event.which == 3);
    else if (event.button) rightclick = (event.button == 2);
    return rightclick;
}

function setFormTooltipTabs() {
    try {
        //Tooltip for the title tab Data select
        $("#formTab1").parent().attr('title', "set of options to select \n data for farther processing");
        //Tooltip for the title tab Drawing
        $("#formTab2").parent().attr('title', "set of options to configure \n a chart draw based on selected \n and optionally processed data");
        //Tooltip for the title tab Analysis
        $("#formTab3").parent().attr('title', "a set of options to make an analysis\n processing of a selected data");
        //Tooltip for the title tab Words select
        $("#formTab4").parent().attr(
            'title',
            "a pop-words list filtering\n and configure\n additional options\n like a filtering and coloring"
        );
    } catch (err) {
        console.log('err tooltip function setFormTooltipTabs, err: ' + err);
    }
}

function setFormTooltipButtons() {
    try {
        //Buttons for the tab Data select
        if (typeof window.btnMain !== "undefined")
            window.btnMain.attr('title', "Draw a chart based on all\n configuration options.");
        if (typeof window.shareButtonTab1 !== "undefined")
            window.shareButtonTab1.attr('title', "Save all configuration options\n and provide a share link\n to open same drawing.");
        if (typeof window.saveButtonTab1 !== "undefined")
            window.saveButtonTab1.attr('title', "Save all configuration options\n for the current share.");
        if (typeof window.widgetButtonTab1 !== "undefined")
            window.widgetButtonTab1.attr(
                'title',
                "Save all configuration options\n and provide a HTML code \n to use to insert into \n the content site."
            );
        //Buttons for the tab Drawing
        window.btnDrawing.attr('title', "Draw a chart based on all \n configuration options.");
        window.shareButtonTab2.attr('title', "Save all configuration options \n and provide a share link \n to open same drawing.");
        window.saveButtonTab2.attr('title', "Save all configuration options \n for the current share.");
        window.widgetButtonTab2.attr(
            'title',
            "Save all configuration options \n and provide a HTML code \n to use to insert into \n the content site."
        );
        //Buttons for the tab Analysis
        window.btnAnalysis.attr('title', "Draw a chart based on all \n configuration options.");
        window.shareButtonTab3.attr('title', "Save all configuration options \n and provide a share link \n to open same drawing.");
        window.saveButtonTab3.attr('title', "Save all configuration options \n for the current share.");
        window.widgetButtonTab3.attr(
            'title',
            "Save all configuration options \n and provide a HTML code \n to use to insert into \n the content site."
        );
        //Buttons for the tab Words select
        window.btnWords.attr('title', "configuration options.");
        window.shareButtonTab4.attr('title', "Save all configuration options \n and provide a share link \n to open same drawing.");
        window.saveButtonTab4.attr('title', "Save all configuration options \n for the current share.");
        window.widgetButtonTab4.attr(
            'title',
            "Save all configuration options \n and provide a HTML code \n to use to insert into \n the content site."
        );
    } catch (err) {
        console.log('err tooltip function setFormTooltipButtons, err: ' + err);
    }
}

function setFormTooltipFields(isBrowserTooltip) {
    if (typeof isBrowserTooltip === "undefined") {
        isBrowserTooltip = false;
    }
    try {
        //Tooltips for the tab Data select
        $('.data-source-label').parent().attr('title', "Select a data source set for a daily period. \n"
            + "This set is a result of a processing of a set of articles \n"
            + "corresponds with a category of some target project like \n"
            + "a WebCompass for that day and pop-words collection detection.");
        $('.dates-interval-label').parent().attr('title', "Select a period to set into the calendar.");
        $('.form-dates-range-input').parent().attr('title', "Select a period to get a pop-words \n detected daily for this dates range.");
        $('.form-exclude-periods-label').parent().attr(
            'title', "dates list CSV - YYYY-mm-dd or MO,TU,WE,TH,FR,SA,SU \n to exclude week days");
        $('.frequency-min-max-label').parent().attr('title', "Select of a min and max a frequency to get a pop-words for this restriction.");
        $('.neighbors-freq-min-max-label').parent().attr('title', "Select of a min and max a neighbors frequency \n to get a pop-words for this restriction.");
        $('.satellites-freq-min-max-label').parent().attr('title', "Select of a min and max a satellites frequency \n to get a pop-words for this restriction.");
        $('.intermediate-words-label').parent().attr('title', "Select of a mode of selection of an intermediate words. \n"
            + "Intermediate words have positions between a pop-words \n"
            + "that are selected for analysis. It can be any kind \n"
            + "of words from an article`s context, so no information \n"
            + "about them are available but distance. \n"
            + "If no intermediate mode selected - it is means that \n"
            + "only direct linked pop-words are selected.");

        $('.days-interval-label').parent().attr('title', "Select of a mode of days interval.");
        $('.min-intersected-neighbors-label').parent().attr('title', "Select of a mode of min intersected neighbor.");
        $('.sort-daily-words-by-label').parent().attr('title', "Select of a mode of days interval.");

        $('.words-selection-order-label').parent().attr('title', "Select of a mode of words selection order.");
        $('.dropdown-data-select-cache-label').parent().attr('title', "Select of a mode of data select cache.");
        $('.unique-words-label').parent().attr('title', "Select of a mode of unique words.");
        $('.sort-order-words-label').parent().attr('title', "Select of a mode of sort order words.");
        $('.max-depth-label').parent().attr('title', "Select of a mode of selection of a max depth words.");
        $('.distance-label').parent().attr('title', "Select of a mode of selection of a max distance words.");
        $('.value-indicator-label').parent().attr('title', "Select a value indicator to be used for a numeric \n"
            + "computations and visualization on charts. \n"
            + "Each value indicator calculated for a pop-word \n"
            + "during a pop-word detection and reflects natural \n"
            + "(like a words`s frequency) or metadata based \n"
            + "(like an articles number, and several rates).");
        $('.sort-field-label').parent().attr('title', "Select a sort field value indicator to be used for a \n"
            + "sortinf pop words and visualization on charts.");
        $('.checkbox-pop-words-intersected-neighbors-label').parent().attr('title', "Check relations pop-words\n");
        $('.checkbox-pop-words-1-in-neighbors-2-label').parent().attr('title', "Check relations pop-words\n");
        $('.checkbox-pop-words-2-in-neighbors-1-label').parent().attr('title', "Check relations pop-words\n");
        $('.checkbox-intersected-neighbors-label').parent().attr('title', "Check relations pop-words\n");
        $('.checkbox-articles-list-label').parent().attr('title', "Check relations pop-words\n");
        $('.checkbox-repeat-selection-unlim-label').parent().attr('title', "If checked, selected top words are used\n"
            + "as searched in next selection with \n"
            + "indicator value min: 1 and max: UNLIMITED. ");
        $('.value-scale-label').parent().attr('title', "Select a value scale that will be used \n"
            + "to represent a value indicator. \n Now only a percentage is supported.");
        $('.value-smooth-label').parent().attr('title', "Select a value smoothing that will be used \n to represent a value indicator.");
        $('.data-omission-label').parent().attr('title', "Select a data-omission \n ...todo help.");
        $('#checkboxPreserveDateRange').parent().attr('title', "Chose a mode of preserve date range \n ...todo help.");
        $('.appearance-time-label').parent().attr('title', "Select appearance interval. \n ...todo help.");
        $('.form-max-words-total-label').parent().attr('title', "Type a value max words per day that will be used \n for data selection.");
        $('.form-max-neighbors-words-label').parent().attr('title', "Type a value max neighbors words that will be used \n for data selection.");
        //Tooltips for the tab Drawing
        $('.form-textual-max-words-total-label').parent().attr('title', "Type a value max words total that will be used \n"
            + 'for list display limit.');
        $('.form-font-size-label').parent().attr('title', "Type a value font size that will be used \n"
            + "for balls text. \n If set to empty, then adaptive font size is applied.");
        $('.auto-adjust-label').parent().attr('title', "Select a mode of a nodes positions will be set \n"
            + "automatically right after chart was shown. \n It uses a several different algorithms"
            + "for nodes positioning include a chart \n and fixed geometry path algorithms.");
        $('#checkboxConnectorsLines').parent().attr('title', "Chose a mode of connectors lines display. \n"
            + "If a connectors lines are not shown \n a double click on node can be used \n to show only this node links.");
        $('.sizeSliderLabel').attr('title', "Select a circles size for a molecular chart \n"
            + "or radius for a radial chart or point size \n for a Time series charts.");
        $('.fontSizeSliderLabel').attr('title', "Select a font size multiplyer for a radial chart.");
        $('.sizeSourcePointsSliderLabel').attr('title', "Select a source points size \n for a Time series charts.");
        $('#checkboxOrientationHorizontalVertical').attr('title', "Select the chart orientation,\n horizontal or vertical.");
        $('.lineWidthSliderLabel').attr('title', "Select a lines width \n for a Time series charts.");
        $('.gravitySliderLabel').attr('title', "Select a gravity level for a molecular chart. \n"
            + "A gravity - is a level of mutual \n nodes forces that directed to group \n them in the center of a chart.");
        $('.indentSliderLabel').attr('title', "Select an indentation level that will be used \n"
            + "to set of a top, bottom, left and right \n indentation for a molecular chart."
            + "Indentation prevents a node to be \n moved out of visual area during \n an automated nodes positioning.");
        $('.radiusSliderLabel').attr('title', "Select a circles size for a molecular chart \n or radius for a radial chart.");
        $('#checkboxIndentValuesRelated').parent().attr('title', "Chose a mode of a not include single nodes. \n"
            + "A values related mode opposite to a basic \n uses a weighted multipliers based on \n a value indicator.");
        $('#checkboxNotIncludeSingleNodes').parent().attr('title', "Chose a mode not include single nodes");
        $('.form-dropdown-balls-text-label').parent().attr('title', "Chose a mode for a caption \n text on a balls.");
        $('.form-dropdown-balls-text-layout-label').parent().attr('title', "Chose a mode for a caption \n text on a balls.");
        $('.chart-view-label').parent().attr('title', "Select a chart view mode \n a time-series chart.");
        //Tooltips for the tab Words select
        //$('#wordsHeadNum').jqxTooltip({ position: 'top', autoHideDelay: 10000, content: 'An order number.' });
        //$('#wordsHeadName').jqxTooltip({ position: 'top', autoHideDelay: 10000, content: 'A pop-word text.' });
        //$('#wordsHeadValue').jqxTooltip({ position: 'top', autoHideDelay: 10000, content: 'An indicator value.' });
        /*$('#wordsHeadDisable').jqxTooltip({ position: 'top', autoHideDelay: 10000, content:
            'Disable word for data selection<br>'
            + 'and farther processing.<br>'
            + 'Requires a `Draw` button<br>'
            + 'press to apply.' });*/

        $('.form-search-word-label').parent().attr('title', "words list to search CSV, i.e. - \n"
            + "Paris,%Germany% and optionally command pragmas: \n synonyms - @SYNONYMS:W1,W2,W3;W1,W2,W3, or depth - \n"
            + "@D0: or @D1: or @D2: or @D3:, or POS names CSV list - \n @P=FNAME,LNAME,ACRONYM,GEO \n"
            + "or @NT=0|1 or @NE=0|1 or @LN=en|ja|pl|de|ru|ar|uk or @WD=<N> or @NM=<N> or @ND=<N>");
        $('.form-exclude-word-label').parent().attr('title', "words list to exclude CSV and \n"
            + "optionally command pragmas: POS list CSV - \n P=FNAME,LNAME,ACRONYM,GEO: or language - \n"
            + "@L=EN: or intermediate words max - @IW=0:");
        $('#CopyWordsListButton').attr('title', "Copy words list in clipboard.");
        $('#CopyIncWordsListButton').attr('title', "Set as search expression for selected words.");
        $('#CopyExcWordsListButton').attr('title', "Set as exclude expression for selected words.");

        $('.factors-data-label').parent().attr('title', "Defines what kind of value \n of daily measurements used.");
    } catch (err) {
        console.log('err tooltip function setFormTooltipFields, err: ' + err);
    }
}

function initGlobalOptionsForm() {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    $('.color-row-added').remove();
    $.ajax({
        // the route pointing to the post function 
        url: '/get-global-options',
        type: 'POST',
        // send the csrf-token and the input to the controller 
        data: {
            _token: CSRF_TOKEN,
        },
        dataType: 'JSON',
        // remind that 'data' is the response of the AjaxController
        success: function (data) {
            if (isDebugg) { console.log(data.result); }
            /*Set data for the global options form fields*/
            if (typeof data.result.blacklistWordsFileCustom === "undefined") {
                var blacklistWordsFileCustom = '';
            } else {
                var blacklistWordsFileCustom = data.result.blacklistWordsFileCustom;
            }
            $('#blacklistWordsFileCustom').val(blacklistWordsFileCustom);

            if (typeof data.result.customWordsColorsFile === "undefined") {
                var customWordsColorsFile = '{}';
            } else {
                var customWordsColorsFile = data.result.customWordsColorsFile;
                if (customWordsColorsFile == '') {
                    customWordsColorsFile = '{}';
                }
            }
            var customWordsColors = JSON.parse(customWordsColorsFile);
            $('#customWordsColorsFile').val(customWordsColorsFile);
            $.each(customWordsColors, function (index, item) {
                addPopWordRow(item, index);
            });

            if (typeof data.result.synonymsWordsFileCustom === "undefined") {
                var synonymsWordsFileCustom = '';
            } else {
                var synonymsWordsFileCustom = data.result.synonymsWordsFileCustom;
            }
            $('#synonymsWordsFileCustom').val(synonymsWordsFileCustom);

        },
        error: function (data) {
            $('#ErrorListContaner').text(data.errMsg);
            cleanup();
        }
    });
}

/**
 * Save global options form data items to the files
 * @returns
 */
function saveGlobalOptionsForm() {
    if (isDebugg) { console.log('0501'); }
    //Set form tab values to the window object.
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    var formdata = {};
    formdata.blacklistWordsFileCustom = $('#blacklistWordsFileCustom').val();
    formdata.synonymsWordsFileCustom = $('#synonymsWordsFileCustom').val();
    formdata.customWordsColorsFile = $('#customWordsColorsFile').val();

    var popWordsColors = $('.pop-word-colors-table .color-row');
    var popWordsColorsObj = {};
    $.each(popWordsColors, function (index, item) {
        var popWord = $(item).find('input[name=pop-word-text]').val().trim();
        if (popWord != '') {
            popWordsColorsObj[popWord] = $(item).find('input[name=pop-word-color]').val();
        }
    });
    window.customPopWordsColors = popWordsColorsObj;
    formdata.customWordsColorsFile = JSON.stringify(popWordsColorsObj);
    $.ajax({
        // the route pointing to the post function 
        url: '/save-global-options',
        type: 'POST',
        // send the csrf-token and the input to the controller 
        data: {
            _token: CSRF_TOKEN,
            formdata: formdata
        },
        dataType: 'JSON',
        // remind that 'data' is the response of the AjaxController
        success: function (data) {
            if (data.result) {
                //console.log(data.result);
            } else {
                cleanup();
                $('#ErrorListContaner').text(data.errMsg);
            }
            // hide preloader on ajax succes
            if (isWidget == 1) {
                $('#jqxLoaderWidget').jqxLoader('close');
            } else {
                $('#jqxLoader').jqxLoader('close');
            }
        },
        error: function (data) {
            $('#ErrorListContaner').text(data.errMsg);
            cleanup();
            // hide preloader on ajax error
            if (isWidget == 1) {
                $('#jqxLoaderWidget').jqxLoader('close');
            } else {
                $('#jqxLoader').jqxLoader('close');
            }
        }
    });
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    window.radialData[chartIndex].wordsListReset = true;
    window.molecularData[chartIndex].wordsListReset = true;
    window.timeSeriesData[chartIndex].wordsListReset = true;
    window.timelineData[chartIndex].wordsListReset = true;
    window.streamgraphData[chartIndex].wordsListReset = true;
    window.sankeyData[chartIndex].wordsListReset = true;
    window.textualWordsData[chartIndex].wordsListReset = true;
    window.dendrogramHorizontalData[chartIndex].wordsListReset = true;

    return false;
}
///////////////////////////
function initSynonymsForm() {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    console.log(window.newValuesFormTab1.synonymsWordsCustom);
    console.log(window.currenFormDataSelectValues[chartIndex].synonymsWordsCustom);
    if (typeof  window.newValuesFormTab1.synonymsWordsCustom !== "undefined") {
        var synonymsWordsCustom = window.newValuesFormTab1.synonymsWordsCustom;
    } else if (typeof  window.currenFormDataSelectValues[chartIndex].synonymsWordsCustom !== "undefined") {
        var synonymsWordsCustom = window.currenFormDataSelectValues[chartIndex].synonymsWordsCustom;
    } else {
        var synonymsWordsCustom = '';
    }

    $('#synonymsWordsCustom').val(synonymsWordsCustom);
}

/**
 * Save global options form data items to the files
 * @returns
 */
function saveSynonymsForm() {
    if (isDebugg) { console.log('0501'); }
    //Set form tab values to the window object.
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    var formdata = {};                 
    window.newValuesFormTab1.synonymsWordsCustom = window.newValuesFormTab4.synonymsWordsCustom = $('#synonymsWordsCustom').val();


    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    window.radialData[chartIndex].wordsListReset = true;
    window.molecularData[chartIndex].wordsListReset = true;
    window.timeSeriesData[chartIndex].wordsListReset = true;
    window.timelineData[chartIndex].wordsListReset = true;
    window.streamgraphData[chartIndex].wordsListReset = true;
    window.sankeyData[chartIndex].wordsListReset = true;
    window.textualWordsData[chartIndex].wordsListReset = true;
    window.dendrogramHorizontalData[chartIndex].wordsListReset = true;

    console.log('01 saveSynonymsForm window.saveButtonTab1')

    window.saveButtonTab1.click();

    return false;
}

function getDrawingValueIndicators() {
    var checkedValueIndicators = [];
    if (firstLoadChart == 1 && isShare && hash != '') {
    } else  {
        //checkedValueIndicators.push('1');
        setDrawingValueIndicatorsChecked(drawingValueIndicatorCheckboxesDefaultChecked);
    }
    var checkboxValueIndicatorList = $('.valueIndicatorCheckboxItem:checked');
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    $.each(checkboxValueIndicatorList, function (index, item) {
        if ($(item).is(':checked')) {
            checkedValueIndicators.push($(item).attr('value'));
        }
    });
    return checkedValueIndicators;
}

function setDrawingValueIndicatorsChecked(checkedItems) {
    var checkboxValueIndicatorList = $('.valueIndicatorCheckboxItem');
    $.each(checkboxValueIndicatorList, function (index, item) {
        if (checkedItems.indexOf(($(item).attr('value') + '')) != -1) {
            if ($(item).is(':checked')) {
            } else {
                $(item).click();
            }
            $(item).checked = true;
        } else {
            if ($(item).is(':checked')) {
                $(item).click();
            }
            $(item).checked = false;
        }
    });
    //return checkedValueIndicators;
}
/**
 * Set event (onclick) for the legend items on chart load for the Time series charts
 * @returns
 */
function setDrawingValueIndicators() {
    $('.valueIndicatorCheckboxItem').on('click', function () {
        window.newValuesFormTab1.drawingValueIndicators = getDrawingValueIndicators();
    });
}

/**
 * Get hiden legend items to aray by css selector from HTML content for the Time series charts
 * @param chartType
 * @returns array
 */
function getHiddenLegendItems(chartType) {
    var hiddenLegendItemIndexesArr = [];
    var visibleLegendItemIndexesArr = [];
    if ((chartType == CHART_TYPE_TIMESERIES) || (chartType == CHART_TYPE_TIMELINE)) {
        var highchartsLegendItems = $('.highcharts-legend-item');
        $.each(highchartsLegendItems, function (index, item) {
            var itemClass = $(item).attr('class');
            var itemTextStr = $(item).find('text tspan').html();
            var classArr = itemClass.split(' ');
            var isHidden = itemClass.search("highcharts-legend-item-hidden");
            for (var i = 0; i < classArr.length; i++) {
                if (classArr[i].search('highcharts-series-') >= 0) {
                    var strIndexArr = classArr[i].split('highcharts-series-');
                    var itemIndex = strIndexArr[1];
                }
            }
            if (isHidden == -1) {
                visibleLegendItemIndexesArr.push({ itemIndex: itemIndex, itemTextStr: itemTextStr });
            } else {
                hiddenLegendItemIndexesArr.push({ itemIndex: itemIndex, itemTextStr: itemTextStr });
            }
        });
    }
    return hiddenLegendItemIndexesArr;
}

/**
 * Set event (onclick) for the legend items on chart load for the Time series charts
 * @returns
 */
function setEventLegendItems() {
    $('.highcharts-legend-item').on('click', function () {
        if (!window.isAutoClickLegend) {
            window.newValuesFormTab1.hiddenLegendItems = getHiddenLegendItems($('#chartType').val());
        }
    });
}

/**
 * Set hidden state for the legend items by array saved items
 * @returns
 */
function setHiddenLegendItems() {
    window.isAutoClickLegend = true;

    var highchartsLegendItems = $('.highcharts-legend-item');
    if ((typeof highchartsLegendItems !== "undefined") && (highchartsLegendItems.length > 0)) {
        $.each(highchartsLegendItems, function (index, item) {
            var itemClass = $(item).attr('class');
            var itemTextStr = $(item).find('text tspan').html();
            var classArr = itemClass.split(' ');
            for (var i = 0; i < classArr.length; i++) {
                if (classArr[i].search('highcharts-series-') >= 0) {
                    var strIndexArr = classArr[i].split('highcharts-series-');
                    var itemIndex = strIndexArr[1];
                }
            }
            var isHidden = 0;
            $.each(window.newValuesFormTab1.hiddenLegendItems, function (indexH, itemH) {
                if (itemTextStr == itemH.itemTextStr) {
                    isHidden = 1;
                }
            });
            $('.highcharts-series-' + itemIndex).prepend('<img src="/images/icon_Twitter.png">');
            if (isHidden == 1) {
                $('.highcharts-series-' + itemIndex).click();
            }
        });
    }

    /*$.each(window.newValuesFormTab1.hiddenLegendItems, function(index, item) {
        $('.highcharts-series-' + item.itemIndex).click();
    });*/

    window.isAutoClickLegend = false;
}

function prepareAndShowWidgetHTML() {
    //Set form tab values to the window object.
    prepareFixedNodesData();
    initPrepareFormData();
    prepareFixedNodesData();
    // Show preloader
    $('#jqxLoader').jqxLoader('open');
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    saveCurrenFormDataSelectValues();
    var chartType = $('#chartType').val();
    if (chartType == CHART_TYPE_RADIAL) {
        var wordsList = window.radialData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
    }
    if (chartType == CHART_TYPE_DENDROGRAMHORIZONTAL) {
        var wordsList = window.dendrogramHorizontalData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
    }
    if (chartType == CHART_TYPE_MOLECULAR) {
        var wordsList = window.molecularData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
    }
    if (chartType == CHART_TYPE_TIMELINE) {
        var wordsList = window.timelineData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        window.newValuesFormTab1.hiddenLegendItems = JSON.stringify(getHiddenLegendItems(chartType));
    }
    if (chartType == CHART_TYPE_STREAMGRAPH) {
        var wordsList = window.streamgraphData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
    }
    if (chartType == CHART_TYPE_SANKEY_NEIGHBORS1) {
        var wordsList = window.sankeyData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
    }
    if (chartType == CHART_TYPE_TIMESERIES) {
        var wordsList = window.timeSeriesData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
        window.newValuesFormTab1.hiddenLegendItems = JSON.stringify(getHiddenLegendItems(chartType));
    }
    if ((chartType == CHART_TYPE_TEXTUALWORDS) || (chartType == CHART_TYPE_TEXTUALWORDSMETADATA)) {
        window.newValuesFormTab1.drawingValueIndicators = JSON.stringify(getDrawingValueIndicators());
    }
    if (chartType == CHART_TYPE_TEXTUALWORDS) {
        window.newValuesFormTab1.textualWordsGridSorting = JSON.stringify($('#dataTextualWords').jqxGrid('getsortinformation'));
        var textualWordsGridState = $('#dataTextualWords').jqxGrid('getstate');
        if (typeof textualWordsGridState != "undefined") {
            if (typeof textualWordsGridState.pagenum != "undefined") {
                window.newValuesFormTab1.textualWordsGridPagenum = textualWordsGridState.pagenum;
            }
            if (typeof textualWordsGridState.pagesize != "undefined") {
                window.newValuesFormTab1.textualWordsGridPagesize = textualWordsGridState.pagesize;
            }
        }
    }
    if (chartType == CHART_TYPE_TIMELINE) {
        var wordsList = {}; //window.timeLineData[chartIndex].wordsListFirst;
        window.newValuesFormTab1.wordsListDataArr = JSON.stringify(wordsList);
    }

    window.newValuesFormTab1.selectedTab = getCurSelectedTab();

    $.ajax({
        // the route pointing to the post function 
        url: '/share',
        type: 'POST',
        // send the csrf-token and the input to the controller 
        data: {
            _token: CSRF_TOKEN,
            formdata: window.newValuesFormTab1,
            isWidget: true,
            urljs: window.location.origin,
            sharedURL: window.location.origin + '/drawing?' + theme + '&share=%HASH_TAG%'
        },
        dataType: 'JSON',
        // remind that 'data' is the response of the AjaxController
        success: function (data) {
            if (data.result) {
                if (typeof theme === "undefined") {
                    theme = themeDefault;
                }
                var fistClickSelection = 1;

                dataWidgetResult = data;

                $('<div id="sharedText">'
                    + '<div class="widgetSettingsContainer">'
                    + '<table width="100%"><tbody><tr><td>'
                    + '<input type="checkbox" id="checkboxMacroText" name="checkboxMacroText">'
                    + '<label for="checkboxMacroText">macro text</label> <br/>'
                    
                    + '<input type="checkbox" id="checkboxMacroTextInput" name="checkboxMacroTextInput">'
                    + '<label for="checkboxMacroTextInput">macro text input</label> <br/>'
                    
                    + '<input type="checkbox" id="checkboxDatesInterval" name="checkboxDatesInterval">'
                    + '<label for="checkboxDatesInterval">dates interval</label> <br/>'

                    + '<input type="checkbox" id="checkboxDatesInterval" name="checkboxDatesInterval">'
                    + '<label for="checkboxDatesInterval">dates interval</label> <br/>'

                    + '<input type="checkbox" id="checkboxPopWordReportTooltip" name="checkboxPopWordReportTooltip">'
                    + '<label for="checkboxPopWordReportTooltip">one-pop-word report with tooltip for chart`s data point</label> <br/>'

                    + '</td><td>'
                    + "<button id=\"buttonCopyTextToClipboard\" onclick=\"copyToClipboard('#selectableContainer .selectable')\">Copy</button>"
                    + '</td></tr></tbody></table>'
                    + '</div>'

                    //+ '<textarea class="selectable" cols="53" rows="14" type="text" readonly="readonly">'
                    + getCodeWidgetStr(true)
                    + '</div>')

                    /**/
                .dialog({
                    width: 550, height: 320,
                    title: "Please, copy widget HTML",
                    modal: true
                }).dialog("moveToTop");

                $(".selectable").click(function () {
                    if (fistClickSelection == 1) {
                        $(this).select();
                        fistClickSelection = 0;
                    }
                });
                $('#checkboxMacroText').change(function() {
                    changeCodeWidgetStr();
                });
                $('#checkboxMacroTextInput').change(function() {
                    changeCodeWidgetStr();
                });
                $('#checkboxDatesInterval').change(function() {
                    changeCodeWidgetStr();
                });
                
                $('#checkboxPopWordReportTooltip').change(function() {
                    changeCodeWidgetStr();
                });
                //$("selectableContainer").text();

            } else {
                cleanup();
                $('#ErrorListContaner').text(data.errMsg);
            }
            // hide preloader on ajax succes
            if (isWidget == 1) {
                $('#jqxLoaderWidget').jqxLoader('close');
            } else {
                $('#jqxLoader').jqxLoader('close');
            }
        },
        error: function (data) {
            $('#ErrorListContaner').text(data.errMsg);
            cleanup();
            // hide preloader on ajax error
            if (isWidget == 1) {
                $('#jqxLoaderWidget').jqxLoader('close');
            } else {
                $('#jqxLoader').jqxLoader('close');
            }
        }
    });
}

function getCodeWidgetStr(isFirstLoadWidget) {
    data = dataWidgetResult;
    var widgetStr = '';
    if (isFirstLoadWidget) {
        widgetStr = widgetStr + '<code class="selectable" id="selectableContainer" cols="53" rows="14" type="text" readonly="readonly">';
    }
    widgetStr = widgetStr + '<textarea class="selectable" cols="70" rows="60" type="text" readonly="readonly">'
    + '&lt;!-- ATOOLS COMMON INCLUDE --&gt;' + "\n"
    + '&lt;link rel="stylesheet" href="' + window.location.origin + '/css/site.css" type="text/css" /&gt;' + "\n"
    + '&lt;link rel="stylesheet" href="' + window.location.origin + '/css/styles/ide_styles_widget.css" type="text/css" /&gt;' + "\n"
    + '&lt;script type="text/javascript" src="' + window.location.origin + '/scripts/jquery-1.12.4.min.js"&gt;&lt;/script&gt;' + "\n"
    + '&lt;script type="text/javascript" src="' + window.location.origin + '/js/widget_calendar.js"&gt;&lt;/script&gt;' + "\n"
    + "\n";
    if (!isFirstLoadWidget) {
        if($('#checkboxMacroText')[0].checked) {
            widgetStr = widgetStr + '&lt;!-- ATOOLS CONTAINER WITH MACRO TEXT -->' + "\n"
                + '&lt;!-- &lt;div id="iframe-container-label-0" class="atools-widget unvisible"&gt;%search%&lt;/div&gt; --&gt;' 
                + "\n\n";
        }
        if($('#checkboxMacroTextInput')[0].checked) {
            widgetStr = widgetStr + '&lt;!-- ATOOLS CONTAINER WITH MACRO TEXT INPUT TO SET CORRESPONDENT OPTION --&gt;' + "\n"
                + '&lt;!-- &lt;div id="iframe-container-label-2" class="atools-widget"&gt;' + "\n"
                + '&lt;input type="text" id="iframe-container-input-2" class="atools-widget unvisible" name="search" value="%search%"&gt;' + "\n"
                + '&lt;/div&gt; --&gt;' + "\n\n";
        }
        if($('#checkboxDatesInterval')[0].checked) {
            widgetStr = widgetStr + '&lt;!-- ATOOLS DATES PICKER --&gt;' + "\n"
                + '&lt;!-- &lt;div class="dates-interval-contaner"&gt;&lt;/div&gt; --&gt;' + "\n\n";
        }
        if($('#checkboxDatesInterval')[0].checked) {
            /*{
                "link": {
                    "baseUrl": "TARGET_PROJECT_URL/?#search=%WORD_SINGLE%&datesrange=%DATE%",
                    "onClick":"metadata",
                    "icon": "ICON_METADATA",
                    "newTab": true,
                    "target":"_popup"
                },
                "index": 9,
                "text": "", 
                "title": "metadata",
                "tooltip":"Open words`s metadata"
            },)*/
        }

        
    }


    /*+ '<!-- Textual chart - macro names for a column options' + "\n"
    + '%WORD_SINGLE% - replaced with a clicked word as is, for example - "Donald Trump"' + "\n"
    + '%WORD_MULTI% - replaced with a clicked word`s words in CSV with "%" template, for example - "%Donald%,%Trump%"' + "\n"
    + 'example: #search=%WORD_SINGLE%' + "\n"
    + "\n"
    + 'dataSource=%DATA_SOURCE% - a data source name' + "\n"
    + 'valMin=%VAL_MIN% - value indicator min' + "\n"
    + 'valMax=%VAL_MAX% - value indicator max' + "\n"
    + 'datesinterval=%DATES_INTERVAL%' + "\n"
    + 'maxDepth=%MAX_DEPTH%' + "\n"
    + 'valueIndicator=%VAL_INDICATOR%' + "\n"
    + 'wordsDailyMax=%WORDS_DAILY_MAX%' + "\n"
    + 'appearanceMin=%APPEARANCE_MIN%' + "\n"
    + 'appearanceMax=%APPEARANCE_MAX%' + "\n"
    + '-->' + "\n"
    + "\n"*/
    widgetStr = widgetStr + '&lt;!-- ATOOLS CHART --&gt;' + "\n"
    + '&lt;div&gt;' + "\n"
    + (data.result.widgetContainer)
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        //.replaceAll("\n", "<br/>" + "\n")
    + "\n"
    + '&lt;script type="text/javascript"&gt;' + "\n"
    + 'var iframeOptions' + data.result.hash + ' = {' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'widgetId: "' + data.result.hash + '",' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'openIDEButton: {' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'visibility: false,' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + "buttonHTML: \'&lt;button class=\"%OPEN_IDE_CSS%\" style=\"%INLINE_STYLE%\" title=\"Open IDE to change build parameters\" "
    + "onclick=\"window.open(\\\'" + window.location.origin + '/drawing?' + theme + '&share=' + data.result.hash + "\\\');\"&gt;Open IDE&lt;/button&gt;\'," + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'visualStyle: {' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'styleType:"buttonView"' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '}' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '},' + "\n";

    if((typeof $('#checkboxPopWordReportTooltip') != "undefined") && (typeof $('#checkboxPopWordReportTooltip')[0] != "undefined") && ($('#checkboxPopWordReportTooltip')[0].checked)) {
        widgetStr = widgetStr + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'tooltipOptions: {' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '"link": {' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' 
        + '"baseUrl": "' + window.location.origin + '/drawing?' + theme + '&share=' + data.result.hash + '",' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' 
        + '"icon": "ICON_CLASS_NAME",' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' 
        + '"newTab": true,' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '},' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '"text":' + '"Open report",' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '"tooltip":' + '"Open report",' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '"stylesOverride": {' +  "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '"CSS_SELECTOR": "STYLE",' + "\n"
        //+ '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + "linkHTML: \'&lt;a class=\"%OPEN_IDE_CSS%\" style=\"%INLINE_STYLE%\" title=\"Open report\" "
        //+ "onclick=\"window.open(\\\'" + window.location.origin + '/drawing?' + theme + '&share=' + data.result.hash + "?widget=1%%\\\');\"&gt;Open IDE&lt;/a&gt;\'," + "\n"
        //+ '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'overwriteValues: {' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + '}' + "\n"
        + '&nbsp;&nbsp;&nbsp;&nbsp;' + '},' + "\n";
    }

    widgetStr = widgetStr + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'datesInterval: {' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'valueDatesRange: "' + $('#inputjqxWidgetDateRange').val() + '",' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '&nbsp;&nbsp;&nbsp;&nbsp;' + 'valueDatesInterval: "' + $('#dropdownDatesInterval').jqxDropDownList('val') + '"' + "\n"
    + '&nbsp;&nbsp;&nbsp;&nbsp;' + '}' + "\n"
    + '};' + "\n"
    + '&lt;/script&gt;' + "\n"
    //+ '<script type="text/javascript" src="' +  window.location.origin + data.result.urljs + '"></script>'
    + '&lt;script type="text/javascript" src="' + window.location.origin + '/widgetjs?hash=' + data.result.hash + '"&gt;&lt;/script&gt;' + "\n"
    + '&lt;/div&gt;' + "\n"
    + '</textarea>'
    if (isFirstLoadWidget) {
        widgetStr = widgetStr + '</code>';
    }
    return widgetStr;
}

function changeCodeWidgetStr() {
    $('#selectableContainer').html(getCodeWidgetStr(false));
}

function replaceNamesIndicators(nameStr) {
    $.each(namesIndicators, function (index, item) {
        if (nameStr == index) {
            nameStr = item;
        }
    });
    return nameStr;
}

function setChartTypesConfiguration(selectedIndex, isAutoChange) {
    if (isWidget == 1) {

    } else {
        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));

        $.each(chartTypesConfiguration, function (index, item) {
            $.each(item, function (index2, item2) {
                //$('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#' + index)[0]);
                if ((index == selectedIndex) && (index2 == 'enabled') && (item2 == true)) {
                    if ($("#jqxTree").find('li#' + index).length > 0) {
                        $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + index)[0]);
                        $('#jqxTree').jqxTree('enableItem', $("#jqxTree").find('li#' + index)[0]);
                    }
                } else if (index2 == 'selected') {
                    if (item2 == true) {
                        if ($("#jqxTree").find('li#' + index).length > 0) {
                            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + index)[0]);
                        }
                    } else {
                        if ($("#jqxTree").find('li#' + index).length > 0) {
                            $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#' + index)[0]);
                        }
                    }
                } else if (index2 == 'enabled') {
                    if (item2 == true) {
                        if ($("#jqxTree").find('li#' + index).length > 0) {
                            $('#jqxTree').jqxTree('enableItem', $("#jqxTree").find('li#' + index)[0]);
                        }
                    } else {
                        if ($("#jqxTree").find('li#' + index).length > 0) {
                            $('#jqxTree').jqxTree('disableItem', $("#jqxTree").find('li#' + index)[0]);
                        }
                    }
                } else {
                    if ((index2 !== 'enabled') && (index2 == 'selected')) {
                        if ($("#jqxTree").find('li#' + index2).length > 0) {
                            $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#' + index2)[0]);
                        }
                    }
                    $.each(item2, function (index3, item3) {
                        if ((index2 == selectedIndex) && (index3 == 'enabled') && (item3 == true)) {
                            if ($("#jqxTree").find('li#' + index2).length > 0) {
                                $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + index2)[0]);
                                $('#jqxTree').jqxTree('enableItem', $("#jqxTree").find('li#' + index2)[0]);
                            }
                        } else if (index3 == 'selected') {
                            if (item3 == true) {
                                if ($("#jqxTree").find('li#' + index2).length > 0) {
                                    $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + index2)[0]);
                                }
                            } else {
                                if ($("#jqxTree").find('li#' + index2).length > 0) {
                                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#' + index2)[0]);
                                }
                            }
                        } else if (index3 == 'enabled') {
                            if (item3 == true) {
                                if ($("#jqxTree").find('li#' + index2).length > 0) {
                                    $('#jqxTree').jqxTree('enableItem', $("#jqxTree").find('li#' + index2)[0]);
                                }
                            } else {
                                if ($("#jqxTree").find('li#' + index2).length > 0) {
                                    $('#jqxTree').jqxTree('disableItem', $("#jqxTree").find('li#' + index2)[0]);
                                }
                            }
                        } else {
                            if ((index3 !== 'enabled') && (index3 == 'selected')) {
                                if ($("#jqxTree").find('li#' + index3).length > 0) {
                                    $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#' + index3)[0]);
                                }
                            }
                            $.each(item3, function (index4, item4) {
                                if ((index3 == selectedIndex) && (index4 == 'enabled') && (item4 == true)) {
                                    if ($("#jqxTree").find('li#' + index3).length > 0) {
                                        $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + index3)[0]);
                                        $('#jqxTree').jqxTree('enableItem', $("#jqxTree").find('li#' + index3)[0]);
                                    }
                                } else if (index4 == 'selected') {
                                    if (item4 == true) {
                                        if ($("#jqxTree").find('li#' + index3).length > 0) {
                                            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + index3)[0]);
                                        }
                                    } else {
                                        if ($("#jqxTree").find('li#' + index3).length > 0) {
                                            $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#' + index3)[0]);
                                        }
                                    }
                                } else if (index4 == 'enabled') {
                                    if (item4 == true) {
                                        if ($("#jqxTree").find('li#' + index3).length > 0) {
                                            $('#jqxTree').jqxTree('enableItem', $("#jqxTree").find('li#' + index3)[0]);
                                        }
                                    } else {
                                        if ($("#jqxTree").find('li#' + index3).length > 0) {
                                            $('#jqxTree').jqxTree('disableItem', $("#jqxTree").find('li#' + index3)[0]);
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
            });
        });
        if ((typeof isAutoChange !== "undefined") && (isAutoChange == 1)) {
            var chartType = $('#chartType').val();
            var chartTypeById = getchartTypeById($('li[aria-selected=true]').attr('id'))
            if (chartType != chartTypeById) {
                var prevchartTypeId = getIdBychartType(chartType);
                if ($($("#jqxTree").find('li#' + prevchartTypeId)[0]).hasClass('jqx-tree-item-li')) {
                    if ($($($("#jqxTree").find('li#' + prevchartTypeId)[0]).find('div')).hasClass('jqx-fill-state-disabled')) {
                        $('#chartType').val(chartTypeById);
                        $('li[aria-selected=true]').click();
                        //$('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + prevchartTypeId)[0]);
                        //$("#jqxTree").find('li#' + prevchartTypeId)[0].click();
                    } else {
                        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
                        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                        $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));

                        try {
                            $('#jqxTree').jqxTree('uncheckItem', $("#jqxTree").find('li#' + selectedIndex)[0]);
                        } catch (err) {
                            //console.log('Oops, unable to uncheck Item = ' + selectedIndex);
                        }
                        if ($("#jqxTree").find('li#' + prevchartTypeId).length > 0) {
                            $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + prevchartTypeId)[0]);
                        }
                    }
                }
            }
        } else {
            if ($($("#jqxTree").find('li#' + selectedIndex)[0]).hasClass('jqx-tree-item-li')) {
                //if ($("#jqxTree").find('li#' + prevchartTypeId)[0].attr('class').search('jqx-tree-item-li') >= 0){
                //if (!$($("#jqxTree").find('li#' + selectedIndex)[0]).hasClass('jqx-fill-state-disabled')) {
                if (!$($($("#jqxTree").find('li#' + selectedIndex)[0]).find('div')).hasClass('jqx-fill-state-disabled')) {
                    $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                    $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
                    $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-fill-state-hover/));
                    $('li[aria-selected=true]').removeClass(makeRemoveClassHandler(/^jqx-tree-item-hover/));
                    if ($("#jqxTree").find('li#' + selectedIndex).length > 0) {
                        $('#jqxTree').jqxTree('selectItem', $("#jqxTree").find('li#' + selectedIndex)[0]);
                    }
                    var chartTypeById = getchartTypeById(selectedIndex);
                    $('#chartType').val(chartTypeById);
                }
            }
        }
    }
}

$(document).ready(function () {
    if (firstLoadChart == 1 && isShare && hash != '') {
        initShareValuesForm(hash);

        if (isMetadata) {
            getDataAndShowMeasurePointDialog('current', window.newValuesFormTab1.metadataWord, true);
        }
    } else {
        window.formInitData = {
            chartType: chartTypeDefault,
            checkboxConnectorsLinesValue: true,
            checkboxIndentValuesRelatedValue: false,
            checkboxNotIncludeSingleNodesValue: false,
            checkboxReduceOverlapValue: false,
            checkboxShowHideLegendValue: true,
            checkboxShowHideSeriesLabelValue: true,
            checkboxShowHideTitleValue: true,
            checkboxAddNodesFixLevel: true,
            checkboxShowHideMenuValue: false,
            checkboxShowHideTableHeaderValue: true,
            checkboxOrientationHorizontalVertical: false,
            checkboxShowHideMetadataColumnValue: true,
            checkboxShowHidePaginationValue: true,
            checkboxConnectNullsValue: 2,
            dropdownConnectorLinesSourceValue: 0,
            checkboxSourceDataPointsValue: false,
            checkboxAxisTurnOnOffValue: true,
            circleSizeMult: 1,
            fontSizeMult: 1,
            sourcePointsSizeMult: 1,
            lineWidthMult: 1,
            dropdownMaxNodes: 100,
            dropdownAutoAdjust: 1,
            dropdownChartView: 1,
            dropdownAutoAdjustCnt: 250,
            dropdownValueDSource: sourceDefault,
            dropdownValueDSource2: sourceDefault,
            dropdownDatesInterval: 0,
            dropdownValueIWords: 0,
            dropdownDaysInterval: 0,
            dropdownMinIntersectedNeighbors: 0,
            dropdownSortDailyWordsBy: 0,
            satellitesOrderCondition: 0,
            dropdownDataSelectCache: 0,
            dropdownUniqueWords: 0,
            dropdownSortOrderWords: 0,
            dropdownValueMaxDepth: '',
            dropdownMaxDistance: 0,
            dropdownValueVIndicator: valueDefault,
            dropdownsSortField: sortFieldDefault,
            dropdownValueVScale: 1,
            dropdownValueVSmooth: 0,
            dropdownDataOmission: 0,
            checkboxPreserveDateRange: false,
            dropdownColorsSelection: 1,
            dropdownConnectorLineWidthIndicator: 1,
            dropdownConnectorLinesColor: 2,
            dropdownColorsSet: 0,
            dropdownChartBackgroundColor: 0,
            dropdownChartTextColor: 0,
            changedCTColor: '',
            dropdownBallsText: 1,
            dropdownBallsTextLayout: 1,
            dropdownFilterModel: 0,
            checkboxAppearanceFullTimeValue: 0,
            gravityValue: 0.13,
            indentValue: -300,
            nubmberBoxValue1: 50,
            nubmberBoxValue2: '',
            checkboxRepeatSelectionMinMaxUnlim: false,
            //Relations Type Mask
            checkboxPWIntersectedNeighbors: true,
            checkboxPW1inNeighbors2: false,
            checkboxPW2inNeighbors1: false,
            checkboxIntersectedNeighbors: false,
            checkboxArticlesList: false,

            relationsModeMask: 0,


            nubmberBoxNeighborsFreqMin: '',
            nubmberBoxNeighborsFreqMax: '',
            nubmberBoxSatellitesFreqMin: '',
            nubmberBoxSatellitesFreqMax: '',
            nubmberBoxAppearanceMin: 50,
            nubmberBoxAppearanceMax: '',
            nubmberBoxValueMaxWordsTotal: 20,
            nubmberBoxNeighborsMaxItems: 5,
            nubmberBoxValueMaxWordsTotalTextual: 1000,
            nubmberBoxValueFontSize: '',
            numberBoxDegree: 4,
            numberBoxFilterThreshold: 0.65,
            numberBoxClustersNumber: 2,
            numberBoxEpsilon: 100,
            numberBoxMinItemsPerCluster: 1,
            textBoxValueExcludePeriods: '',
            textBoxValueSearchWord: '',
            synonymsWordsCustom: '',
            textBoxValueExcludeWord: '',
            textBoxTitleTimeCharts: '',
            textBoxSubtitleTimeCharts: 'Data source: %DATA_SOURCE% (%CHART_VIEW%)',
            textBoxDescriptionTimeCharts: 'values of %VALUE_INDICATOR%, period from: %DATE_FROM% to %DATE_TO%',
            radiusValue: 1,
            textBoxValueDatesRange: '',
            textBoxValueExcludePeriods: '',
            textBoxValueMaxWordsTotal: 100,
            wordsList: {},
            filterPOS: 0,
            checkboxesSatellitesTypeMask: 0,
            checkboxPOSAcronymValue: false,
            checkboxPOSGeoValue: false,

            //Satellites Type Mask
            checkboxMultiWordSatellitesTypeMask: false,
            checkboxProperSatellitesTypeMask: false,
            checkboxNormalizedSatellitesTypeMask: false,
            checkboxReadableSatellitesTypeMask: false,
            checkboxCommonSatellitesTypeMask: false,
            checkboxCommonSatellitesTypeMask: false,

            checkboxPOSPersonNameValue: false,
            checkboxPOSWikiValue: false,
            checkboxWordsGroupSubentries: false,
            checkboxValueIndicatorLimitsStrict: false,
        };
        createLayout();
    }

    setTimeout(function () {
        if ($('body').find('#jqxLayout').length > 0) {
            setFormTooltipTabs();
            setFormTooltipButtons();
            setFormTooltipFields();
            setAutoBackgroundColor();
            setTimeout(function () {
                $('#wordsListTableContaner').attr('style', "max-height:" + ($('#dataContaner').height() - 230) + 'px !important');
            }, 1000);
            if (firstLoadChart == 1 && formDataStr != '') {
                window.newValuesFormTab1 = formDataStr;//window.formDataStrSave;
                window.newValuesFormTab1.hiddenLegendItems = [];
                window.newValuesFormTab1.drawingValueIndicators = [1];
                initShareValuesForm('', window.formDataStrSave);
                //window.btnMain.click();
            }
        }
    }, 800);
});


function thenChangeWindowSize() {
    setTimeout(function () {
        addPlotToolsPanel();
    }, 500);
}
$(function () {
    window.addEventListener('resize', thenChangeWindowSize);

    // Bind the resize event. When any test element's size changes, update its
    // corresponding info div.
    // Update all info divs immediately.
    $(window).mouseup(function () {
        setTimeout(function () {
            if ((!isEqualSizeIndent(dataContanerWidth, Math.round($('#dataContaner').parent().width()), 2))
                || (!isEqualSizeIndent(dataContanerHeight, Math.round($('#dataContaner').parent().height()), 5))) {
                if (!isResize) {
                    //$('#dataContaner').resize();
                }
                if (isCalendarClick) {
                    dataContanerWidth = Math.round($('#dataContaner').parent().width());
                    dataContanerHeight = Math.round($('#dataContaner').parent().height());
                    isCalendarClick = false;
                }
            }
            addPlotToolsPanel();
        }, 800);
    });

    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    window.customPopWordsColors = {};
    $.ajax({
        // the route pointing to the post function 
        url: '/get-global-options',
        type: 'POST',
        // send the csrf-token and the input to the controller 
        data: {
            _token: CSRF_TOKEN,
        },
        dataType: 'JSON',
        // remind that 'data' is the response of the AjaxController
        success: function (data) {
            if (isDebugg) { console.log(data.result); }
            /*Set data for the global options form fields*/
            if (typeof data.result.customWordsColorsFile === "undefined") {
                var customWordsColorsFile = '{}';
            } else {
                var customWordsColorsFile = data.result.customWordsColorsFile;
                if (customWordsColorsFile == '') {
                    customWordsColorsFile = '{}';
                }
            }
            window.customPopWordsColors = JSON.parse(customWordsColorsFile);
            //window.customPopWordsColors = JSON.parse(customWordsColorsFile);
        },
        error: function (data) {
            $('#ErrorListContaner').text(data.errMsg);
            cleanup();
        },
        complete: function (data) {
        }
    });

});


