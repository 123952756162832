
window.textualWordsDataInit = {
    data: undefined,
    width: undefined,
    height: undefined,
    json: undefined,
    cluster: undefined,
    link: undefined,
    wordsListFirst: {},
    wordsList: {},
    wordsListSelected: {},
    stopFlag: false,
    wordsListReset: true,
    columnsOptions: undefined,
    addData: undefined
};
window.textualWordsData = {};
window.textualWordsData[0] = window.textualWordsDataInit;

var myLinkField = function(config) {
    jsGrid.Field.call(this, config);
};

myLinkField.prototype = new jsGrid.Field({
    itemTemplate: function(value) {
        return value;
    },
});

jsGrid.fields.myLinkField = myLinkField;
var curColIndex, formData, saveData = [];

function showTextualWords(data, formdata, chartIndex) {

    //saveData = data;
    $.each(data, function(index, item) {
        saveData[index] = item[0];
    });

    formData = formdata;
    if ($("#dataContaner #dataTextualWords").length > 0){
        $("#dataContaner").html('');
    }
    $("#dataContaner").html(
        '<div id="dataTextualWords"></div><div id="gridMenu">' + "\n"
        + '<ul>' + "\n"
        + '<li>Edit Selected Row</li>' + "\n"
        + '<li>Delete Selected Row</li>' + "\n"
        + '</ul>' + "\n"
        + '</div>'
    );
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    
    cleanup(CHART_TYPE_TEXTUALWORDS, chartIndex);
    window.textualWordsData[chartIndex].wordsListSelected = formdata.wordsList;
    curDataChartTW = JSON.stringify(data);
    
    /* Prepare csv string for the scv file saving */
    window.textualWordsData[chartIndex].curDataChartCsv = '';
    data.forEach(function(infoArray, index){
        dataString = infoArray.join(",");
        window.textualWordsData[chartIndex].curDataChartCsv += dataString + "\n";
    });
    
    window.sourceData = JSON.parse(curDataChartTW);
    var ifEmptyWordsList = false;
    if (Object.keys(window.textualWordsData[chartIndex].wordsList).length == 0) {
        window.textualWordsData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    //console.log(hash);
    //if (window.textualWordsData[chartIndex].wordsListReset == true) {
        if ((firstLoadChart == 1 && isShare && hash != '') && !ifEmptyWordsList) {
        } else {
            window.textualWordsData[chartIndex].wordsList = {};
            $.each(data, function(index, item) {
                window.textualWordsData[chartIndex].wordsList[item[0]] = {'name' : item[0], 'size' : 0};
            });
        }
    //}

    if (typeof formdata.checkboxShowHideTableHeaderValue === "undefined") {
        var showHideTableHeader = true;
    } else {
        var showHideTableHeader = formdata.checkboxShowHideTableHeaderValue;
    }
    if (typeof formdata.checkboxShowHideMetadataColumnValue === "undefined") {
        var showHideMetadataColumn = true;
    } else {
        var showHideMetadataColumn = formdata.checkboxShowHideMetadataColumnValue;
    }

    if (typeof formdata.checkboxShowHidePaginationValue === "undefined") {
        var showHidePagination = true;
    } else {
        var showHidePagination = formdata.checkboxShowHidePaginationValue;
    }
    var i = 1;
    var fieldsList = [
        { datafield: 0, text: "Word", type: "text", width: 160}
    ];
    var datafieldsList = [
        {name: '0', type: 'number'},
        {name: '1', type: 'number'}
    ];
    var columnsList = [
        {
            text: '#', sortable: false, filterable: false, editable: false,
            groupable: false, draggable: false, resizable: false,
            datafield: '', columntype: 'number', width: 30,
            cellsrenderer: function (row, column, value) {
                return "<div style='margin:4px;'>" + (value + 1) + "</div>";
            }
        },

        { text: 'Word', dataField: 0, width: 160}
        /*{ text: 'Last Name', dataField: 'lastname', width: 130 },
        { text: 'Product', dataField: 'productname', width: 180 },
        { text: 'Quantity', dataField: 'quantity', width: 80, cellsalign: 'right' },
        { text: 'Unit Price', dataField: 'price', width: 90, cellsalign: 'right', cellsformat: 'c2' },
        { text: 'Total', dataField: 'total', cellsalign: 'right', minwidth: 100, cellsformat: 'c2' }*/
    ];
    $.each(valueIndicatorList, function(index, item) {
        if (formdata.drawingValueIndicators.indexOf(item + '') >= 0) {
            if ((typeof data[0] !== "undefined") && (typeof data[0][item] == "number")) {
                if (data[0][item] % 1 === 0) {
                    var cellsformatSrt = '';
                } else {
                    var cellsformatSrt = 'd3';
                }
            } else {
                var cellsformatSrt = '';
            }
            //datafieldsList[item] = {name: item , type: "number", cellsalign: 'right'};
            //columnsList[item] = {text: index, dataField: item, cellsalign: 'right', cellsformat: cellsformatSrt};
            datafieldsList.push({name: item , type: "number", cellsalign: 'right'});
            columnsList.push({text: index, dataField: item, cellsalign: 'right', cellsformat: cellsformatSrt});
            i++;
        }
    });
    var fieldsCount = 0;
    if (typeof data[0] !== "undefined") {
        fieldsCount = data[0].length;
    }
    //console.log(fieldsCount);
    if ((showHideMetadataColumn == true) && (data.length > 0)) {
        formData = formdata;
        formData.chartType = CHART_TYPE_TEXTUALWORDS;
        formData.metadataWord = '';
        formData.metadataValue = '';
        formData.metadataDate = {};

        var dateStartArr = formData.textBoxValueDatesRange.split('-');
        formData.metadataDate.year = dateStartArr[0];
        formData.metadataDate.month = dateStartArr[1]-1;
        formData.metadataDate.day = dateStartArr[2];
        //columnsList[fieldsCount] = {text: 'metadata', dataField: fieldsCount, cellsalign: 'center', cellsformat: '', width: 50};
        columnsList.push({text: 'metadata', dataField: fieldsCount, cellsalign: 'center', cellsformat: '', width: 50});
        datafieldsList.push({name: fieldsCount , type: 'string'});
        $.each(data, function(index, item) {
            var linkTooltip = 'show metadata dialog';
            var linkText = "<span class=\"ICON_METADATA\"></span>";

            var addColumnContent = 
                "<span"
                + " title=\"" + linkTooltip 
                + "\" onclick=\"return getDataAndShowMeasurePointDialog('current', '" + item[0] + "')\">"
                + linkText + '</span>';
            data[index][fieldsCount] = addColumnContent;
        });
        fieldsCount++;
    }
    //console.log(fieldsCount);
    var actionUrl = window.location.toString().split('&share=');

    var resData = setWidgetUrlParams(data);
    data = resData['data'];

    if (isWidget == 1) {
        if (data.length > 0) {
            window.fieldsCount = fieldsCount;
        } else {
            window.fieldsCount = 0;
        }
        //console.log(window.fieldsCount);
        if (typeof iframeOptionsConfig !== "undefined") {
            if (typeof iframeOptionsConfig.columnsOptions !== "undefined") {
                window.textualWordsDataInit.columnsOptions = iframeOptionsConfig.columnsOptions;
            }
        }
        if ((typeof window.textualWordsDataInit.columnsOptions !== "undefined") && (data.length > 0)){
            if (data.length > 0) {
                curColIndex = data[0].length;
            } else {
                curColIndex = 0;
            }
            var isColumnExist;
            $.each(window.textualWordsDataInit.columnsOptions, function(indexOptions, itemOptions) {
                if ((itemOptions.index == null) || (itemOptions.index >= window.fieldsCount)) {
                    var columnTitle = '';
                    isColumnExist = false;
                    if (typeof itemOptions.title !== "undefined") {
                        if (itemOptions.title !== null) {
                            columnTitle = itemOptions.title;
                        }
                    }
                    if (itemOptions.index == null) {
                        curColIndex = window.fieldsCount;
                        //console.log(curColIndex);
                        //itemOptions.index = curColIndex;
                        window.fieldsCount++;
                    } else {
                        curColIndex = itemOptions.index;
                    }
                    datafieldsList.push({name: curColIndex, type: 'string'});
                    columnsList.push({ text: columnTitle, dataField: curColIndex});
                    //isColumnExist = true;
                } else {
                    isColumnExist = true;
                    curColIndex = itemOptions.index;
                }
                //console.log(window.fieldsCount);
                if ((isColumnExist == true) && (typeof itemOptions.value !== "undefined") && (typeof itemOptions.value== "object")) {
                    if (typeof columnsList[curColIndex] !== "undefined") {
                        columnsList[curColIndex].width = 70;
                        datafieldsList[curColIndex].cellsalign = 'center';
                    }
                }
                
                $.each(data, function(index, item) {
                    if ((isColumnExist == true) && (typeof itemOptions.value !== "undefined") && (typeof itemOptions.value== "object")) {
                        var addColumnContent = '';
                        $.each(itemOptions.value, function(indexV, itemV) {
                            const valueEvalStr = Object.keys(itemV)[0];
                            var valueEval = valueEvalStr.replaceAll('%VALUE%', data[index][curColIndex]);
                            if (eval(valueEval)) {
                                if (typeof itemV[Object.keys(itemV)[0]]["html-tag"] !== "undefined") {
                                    addColumnContent = itemV[Object.keys(itemV)[0]]["html-tag"].replaceAll('%VALUE%', data[index][curColIndex]);
                                    if (typeof itemOptions.tooltip !== "undefined") {
                                        addColumnContent = '<span title="' 
                                            + itemOptions.tooltip.replaceAll('%VALUE%', data[index][curColIndex]) +  '">'
                                            + addColumnContent + '</span>';
                                    }
                                }
                            }
                        });
                        
                        data[index][curColIndex] = addColumnContent;
                    } else if (typeof itemOptions.link == "object") {
                        if ((typeof itemOptions.link.onClick !== "undefined") && (typeof itemOptions.link.icon !== "undefined")
                            && itemOptions.link.onClick == 'metadata') {
                                formData.chartType = CHART_TYPE_TEXTUALWORDS;
                                formData.metadataWord = '';
                                formData.metadataValue = '';
                                formData.metadataDate = {};
                            
                                var dateStartArr = formData.textBoxValueDatesRange.split('-');
                                formData.metadataDate.year = dateStartArr[0];
                                formData.metadataDate.month = dateStartArr[1]-1;
                                formData.metadataDate.day = dateStartArr[2];
                                //columnsList[fieldsCount] = {text: 'metadata', dataField: fieldsCount, cellsalign: 'center', cellsformat: '', width: 50};
                                //datafieldsList.push({name: fieldsCount , type: 'string'});
                            //console.log('metadata');                            
                            var addColumnContent = getMetadataColumnContent(itemOptions, saveData[index]);
                        } else {
                            //console.log('else');
                            var newTabStr = '';
                            if (itemOptions.link.newTab == true) {
                                newTabStr = ' target="_blank"';
                            }
                            if ((typeof itemOptions.link.target !== "undefined") && (itemOptions.link.target !== "_popup")
                                && (newTabStr == '')) {
                                    newTabStr = ' target="' + itemOptions.link.target + '"';
                            }
                            //"target":"_popup"
                            var baseUrl = itemOptions.link.baseUrl;
                            baseUrl = baseUrl.replaceAll('%WORD_SINGLE%', window.sourceData[index][0]);
                            var itemWordsArr = window.sourceData[index][0].split(" ");
                            var wordMultiStr = '';
                            $.each(itemWordsArr, function(indexW, itemW) {
                                wordMultiStr = wordMultiStr + (wordMultiStr == '' ? '' : ',') + '%' + itemW + '%';
                            });
                            baseUrl = baseUrl.replaceAll('%WORD_MULTI%', wordMultiStr);
                            /*
                            //Textual chart - macro names for a column options
                            %WORD_SINGLE% - replaced with a clicked word as is, for example - "Donald Trump"
                            %WORD_MULTI% - replaced with a clicked word's words in CSV with "%" template, for example - "%Donald%,%Trump%"
                            example: #search=%WORD_SINGLE%
                            
                            dataSource=%DATA_SOURCE% - a data source name
                            valMin=%VAL_MIN% - value indicator min
                            valMax=%VAL_MAX% - value indicator max
                            datesInterval=%DATES_INTERVAL%
                            maxDepth=%MAX_DEPTH%
                            valueIndicator=%VAL_INDICATOR%
                            wordsDailyMax=%WORDS_DAILY_MAX%
                            appearanceMin=%APPEARANCE_MIN%
                            appearanceMax=%APPEARANCE_MAX%
                            datesrange=%DATESRANGE%
                            */
                            if (typeof iframeOptionsConfig !== "undefined") {
                                $.each(widgetUrlParamsMapping, function(indexMM, itemMM) {
                                    //console.log('indexMM=', indexMM, 'itemMM=',itemMM);
                                    if (typeof iframeOptionsConfig[indexMM] !== "undefined") {
                                        if (indexMM == 'datesInterval') {
                                            //console.log(formdata[itemMM.formField]);
                                        }
                                        if (typeof itemMM.macro !== "undefined") {
                                            baseUrl = baseUrl.replaceAll(itemMM.macro, iframeOptionsConfig[indexMM]);
                                        }
                                    } else {
                                        if (indexMM == 'datesrange') {
                                            if (typeof itemMM.macro !== "undefined") {
                                                baseUrl = baseUrl.replaceAll(itemMM.macro, '');
                                            }
                                        } else if (indexMM == 'datesInterval') {
                                            if (typeof itemMM.macro !== "undefined") {
                                                baseUrl = baseUrl.replaceAll(itemMM.macro, '');
                                            }
                                        } else {
                                            if ((typeof itemMM.formField !== "undefined") && (typeof itemMM.macro !== "undefined")) {
                                                if (typeof formdata[itemMM.formField] !== "undefined") {
                                                    baseUrl = baseUrl.replaceAll(itemMM.macro, formdata[itemMM.formField]);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                            var linkTooltip = '';
                            if (typeof itemOptions.tooltip !== "undefined") {
                                if (itemOptions.tooltip !== null) {
                                    linkTooltip = itemOptions.tooltip;
                                }
                            } else if (curColIndex < window.fieldsCount) {
                                linkTooltip = item[curColIndex];
                            }
                            var linkText = '';
                            if (curColIndex < window.fieldsCount) {
                                linkText = item[curColIndex];
                            } else if (typeof itemOptions.text !== "undefined") {
                                if (itemOptions.text !== null) {
                                    linkText = itemOptions.text;
                                }
                            }
    
                            var addColumnContent = '<a href="' + baseUrl +'" class="'+itemOptions.link.icon+'"'
                            + newTabStr + 'title="' + linkTooltip + '">' + linkText + '</a>';
                        }
                        data[index][curColIndex] = addColumnContent;
                    } else {
                        var addColumnContent = itemOptions.text;
                    }
                    if (typeof itemOptions.width !== "undefined") {
                        var itemWidth = itemOptions.width.replace('px', '');
                        columnsList[curColIndex].width = itemWidth;
                    }
                });
                curColIndex++;
            });
        }
    }
    if (typeof formdata.textualWordsGridPagenum != "undefined") {
        var textualWordsGridPagenum = formdata.textualWordsGridPagenum;
    } else {
        var textualWordsGridPagenum = 0;
    }
    if (typeof formdata.textualWordsGridPagesize != "undefined") {
        var textualWordsGridPagesize = formdata.textualWordsGridPagesize;
    } else {
        var textualWordsGridPagesize = 20;
    }
    
    if (isWidget == 1) {
        if ((typeof iframeOptionsConfig !== "undefined") && (iframeOptionsConfig !== null)) {
            if (typeof iframeOptionsConfig.sort !== "undefined"){
                if (typeof iframeOptionsConfig.sort.columnIndex !== "undefined"){
                    var sortcolumn = iframeOptionsConfig.sort.columnIndex;
                }
                if (typeof iframeOptionsConfig.sort.direction !== "undefined") {
                    var sortdirection = iframeOptionsConfig.sort.direction;
                } else if (typeof sortcolumn !== "undefined") {
                    var sortdirection = 'desc';
                }
            }
        }
        var tableWidth =  $('#dataContaner').width()-20;
        var tableHeight = $('#dataContaner').height() - 20;
    } else {
        var tableWidth =  $('#dataContaner').parent().width()-10;
        var tableHeight = $('#dataContaner').parent().height() - 20;
    }
    if ((typeof sortdirection === "undefined") && (typeof sortcolumn === "undefined")) {
        var sortdirection = 'desc';
        var sortcolumn = 2;
        if (typeof formdata.textualWordsGridSorting !== "undefined") {
            if (typeof formdata.textualWordsGridSorting == "string") {
                var textualWordsGridSorting = JSON.parse(formdata.textualWordsGridSorting);
            } else if (typeof formdata.textualWordsGridSorting == "object") {
                var textualWordsGridSorting = formdata.textualWordsGridSorting;
            }
            if (typeof textualWordsGridSorting.sortcolumn !== "undefined") {
                var sortcolumn = textualWordsGridSorting.sortcolumn;
                if (typeof textualWordsGridSorting.sortdirection !== "undefined") {
                    if ((typeof textualWordsGridSorting.sortdirection.ascending !== "undefined") 
                        && (textualWordsGridSorting.sortdirection.ascending == true)
                    ) {
                        sortdirection = 'asc';
                    }
                }
            }
        }
    }
    var source = {
        localdata: data,
        datafields: datafieldsList,
        /*[
            { name: 'firstname', type: 'string' },
            { name: 'lastname', type: 'string' },
            { name: 'productname', type: 'string' },
            { name: 'quantity', type: 'number' },
            { name: 'price', type: 'number' },
            { name: 'total', type: 'number' }
        ],*/
        datatype: "json"
    };
    var dataAdapter = new $.jqx.dataAdapter(source);

    //console.log('datafieldsList', datafieldsList);
    //console.log('columnsList', columnsList);
    
    // create data grid.
    $("#dataTextualWords").jqxGrid({
        width: $("#dataTextualWords").width()-20,//'100%',//tableWidth,//getWidth('Grid'),
        //adaptivewidth: '100%',
        //adaptive: true,
        height: tableHeight,
        sortable: true,
        pageable: showHidePagination,
        showheader: showHideTableHeader,
        source: dataAdapter,
        columnsresize: true,
        pagesizeoptions: ['10', '20', '30', '40', '50'],
        pagesize: textualWordsGridPagesize,
        //pagenum: textualWordsGridPagenum,
        rowsheight: 20,
        columns: columnsList, //columns
        ready: function() {
            // Save State
            $('#dataTextualWords').jqxGrid('savestate');
            //$('.jqx-grid-pager-input').val();
            //$('#dataTextualWords').jqxGrid('pagesize');
            $('#dataTextualWords').jqxGrid('gotopage', textualWordsGridPagenum * 1);
            $('#dataTextualWords').jqxGrid('sortby', sortcolumn, sortdirection);
            setTimeout(function() {
                firstLoadChart = 0;
            }, 1000);
        },
    }).jqxGrid('refresh');
    
    // create context menu
    var contextMenu = $("#gridMenu").jqxMenu({ width: 200, autoOpenPopup: false, mode: 'popup'});
    $("#dataTextualWords").on('contextmenu', function () {
        return false;
    });
    // handle context menu clicks.
    $("#gridMenu").on('itemclick', function (event) {
        var args = event.args;
        var rowindex = $("#dataTextualWords").jqxGrid('getselectedrowindex');
        if ($.trim($(args).text()) == "Edit Selected Row") {
            editrow = rowindex;
            var offset = $("#dataTextualWords").offset();
            $("#popupWindow").jqxWindow({ position: { x: parseInt(offset.left) + 60, y: parseInt(offset.top) + 60} });
            // get the clicked row's data and initialize the input fields.
            var dataRecord = $("#dataTextualWords").jqxGrid('getrowdata', editrow);
            //$("#firstName").val(dataRecord.firstname);
            //$("#lastName").val(dataRecord.lastname);
            //$("#product").val(dataRecord.productname);
            //$("#quantity").jqxNumberInput({ decimal: dataRecord.quantity });
            //$("#price").jqxNumberInput({ decimal: dataRecord.price });
            // show the popup window.
            $("#popupWindow").jqxWindow('show');
        }
        else {
            var rowid = $("#dataTextualWords").jqxGrid('getrowid', rowindex);
            $("#dataTextualWords").jqxGrid('deleterow', rowid);
        }
    });
    $("#dataTextualWords").on('rowclick', function (event) {
        if (event.args.rightclick) {
            $("#dataTextualWords").jqxGrid('selectrow', event.args.rowindex);
            var scrollTop = $(window).scrollTop();
            var scrollLeft = $(window).scrollLeft();
            contextMenu.jqxMenu('open', parseInt(event.args.originalEvent.clientX) + 5 + scrollLeft, parseInt(event.args.originalEvent.clientY) + 5 + scrollTop);
            return false;
        }
    });


    setTimeout(function() {
        $("#dataTextualWords").jqxGrid('refresh');
    }, 500);
    
    //if (window.textualWordsData[chartIndex].wordsListReset == true) {
        addWordsCheckboxesAndInfo(
            window.textualWordsData[chartIndex].wordsList,//wordsListFirst,
            window.textualWordsData[chartIndex].wordsListSelected,
            CHART_TYPE_TEXTUALWORDS
        );
        window.textualWordsData[chartIndex].wordsListReset = false;
    //}
}

function showTextualWords_jsGrid(data, formdata, chartIndex) {
    if (typeof chartIndex === "undefined") {
        chartIndex = 0;
    }
    cleanup(CHART_TYPE_TEXTUALWORDS, chartIndex);
    window.textualWordsData[chartIndex].wordsListSelected = formdata.wordsList;
    curDataChartTW = JSON.stringify(data);
    var ifEmptyWordsList = false;
    if (Object.keys(window.textualWordsData[chartIndex].wordsList).length == 0) {
        window.textualWordsData[chartIndex].wordsListReset = true;
        ifEmptyWordsList = true;
    }
    if (window.textualWordsData[chartIndex].wordsListReset == true) {
        if ((firstLoadChart == 1 && isShare && hash != '') && !ifEmptyWordsList) {
        } else {
            $.each(data, function(index, item) {
                window.textualWordsData[chartIndex].wordsList[item[0]] = {'name' : item[0], 'size' : 0};
            });
        }
    }

    if (typeof formdata.checkboxShowHideTableHeaderValue === "undefined") {
        var showHideTableHeader = true;
    } else {
        var showHideTableHeader = formdata.checkboxShowHideTableHeaderValue;
    }
    if (typeof formdata.checkboxShowHideMetadataColumnValue === "undefined") {
        var showHideMetadataColumn = true;
    } else {
        var showHideMetadataColumn = formdata.checkboxShowHideMetadataColumnValue;
    }
    if (typeof formdata.checkboxShowHidePaginationValue === "undefined") {
        var showHidePagination = true;
    } else {
        var showHidePagination = formdata.checkboxShowHidePaginationValue;
    }
    var i = 1;
    //var fieldsList = [
    //    { name: '0', title: "Word", type: "text", width: "auto", validate: "required" }
    //];
    var maxWordLength = 4;
    $.each(data, function(index, item) {
        if (item[0].length > maxWordLength) {
            maxWordLength  = item[0].length;
        }
    });
    var tableWidth = 0;
    var fieldsList = [
        { name: '0', title: "Word", type: "text", width: (maxWordLength * 8) + 'px', validate: "required" }
    ];
    tableWidth += (maxWordLength * 8);
    $.each(valueIndicatorList, function(index, item) {
        if (formdata.drawingValueIndicators.indexOf(item + '') >= 0) {
            fieldsList.push({name: item + '', title: index, type: "number", width: (index.length * 7 + 20) + 'px'});
            tableWidth += (index.length * 8 + 10);
            i++;
        }
    });
    if (showHideMetadataColumn) {
        fieldsList.push({name: '', title: index, type: "number", width: '30px'});
        tableWidth += 30;
    }

    var actionUrl = window.location.toString().split('&share=');

    var resData = setWidgetUrlParams(data);
    data = resData['data'];

    if (isWidget == 1) {
        if (typeof window.textualWordsDataInit.columnsOptions !== "undefined") {
            var curColIndex = window.fieldsCount;
            $.each(window.textualWordsDataInit.columnsOptions, function(indexOptions, itemOptions) {
                
                if ((itemOptions.index == null) || (itemOptions.index >= fieldsList.length)) {
                    if (typeof window.textualWordsDataInit.columnsOptions.width !== "undefined") {
                        var itemWidth = window.textualWordsDataInit.columnsOptions.width.replace('px', '');
                    } else {
                        var itemWidth = (itemOptions.title.length > itemOptions.text.length) ? itemOptions.title.length : itemOptions.text.length;
                    }
                    fieldsList.push({name: curColIndex + '', title: itemOptions.title, type: "myLinkField", width: (itemWidth * 7 + 20) + 'px'});
                    tableWidth += (itemWidth * 7 + 20);
                } else {
                    curColIndex = itemOptions.index;
                    if (typeof window.textualWordsDataInit.columnsOptions.width !== "undefined") {
                        var itemWidth = window.textualWordsDataInit.columnsOptions.width.replace('px', '');
                    } else {
                        var itemWidth = (itemOptions.title.length > itemOptions.text.length) ? itemOptions.title.length : itemOptions.text.length;
                    }
                    tableWidth -= (fieldsList[itemOptions.index].title.length * 7 + 20);
                    fieldsList[itemOptions.index] = {name: itemOptions.index + '', title: itemOptions.title, type: "text", width: (itemWidth * 7 + 20) + 'px'};
                    tableWidth += (itemWidth * 7 + 20);
                }
                $.each(data, function(index, item) {
                    var newTabStr = ''
                    if (itemOptions.link.newTab == true) {
                        newTabStr = ' target="_blank"';
                    }
                    var addColumnContent = '<a href="'+actionUrl[0] + itemOptions.link.baseUrl +'" class="'+itemOptions.link.icon+'"'
                    + newTabStr + 'title="' + itemOptions.text + '">'
                    + itemOptions.text + '</a>';
                    data[index][curColIndex] = addColumnContent;
                });
            });
        }
    }
    $("#dataContaner").jsGrid({
        width: (tableWidth + 20) + 'px',
        height: "620px",
        
        heading: showHideTableHeader,
        filtering: false,
        inserting: false,
        editing: false,
        sorting: true,
        paging: showHidePagination,
        autoload: true,

        data: data,
        fields: fieldsList 
    });
    if (formdata.drawingValueIndicators.indexOf('1') >= 0) {
        $("#dataContaner").jsGrid("sort", { field: "1", order: "asc" });
    } else {
        $("#dataContaner").jsGrid("sort", { field: "0", order: "asc" });
    }
    //if (window.textualWordsData[chartIndex].wordsListReset == true) {
        addWordsCheckboxesAndInfo(
            window.textualWordsData[chartIndex].wordsList,//wordsListFirst,
            window.textualWordsData[chartIndex].wordsListSelected,
            CHART_TYPE_TEXTUALWORDS
        );
        window.textualWordsData[chartIndex].wordsListReset = false;
    //}

    firstLoadChart = 0;
}

function getMetadataColumnContent(itemOptions, dataItem) {
    /*{
        "link": {
            "baseUrl": "TARGET_PROJECT_URL/?#search=%WORD_SINGLE%&datesrange=%DATE%",
            "onClick":"metadata",
            "icon": "ICON_METADATA",
            "newTab": false,
            "target":"_popup"
        },
        "index": 9,
        "text": "", 
        "title": "metadata", 
        "tooltip":"Open words`s metadata"
    }*/
    if (typeof itemOptions.tooltip !== "undefined") {
        var linkTooltip = itemOptions.tooltip;
    } else {
        var linkTooltip = 'show metadata dialog';
    }
    if (typeof itemOptions.icon !== "undefined") {
        var linkText = "<span class=\"" + itemOptions.icon + "\"></span>";
    } else {
        var linkText = "<span class=\"ICON_METADATA\"></span>";
    }
    if ((typeof itemOptions.link == "object") && (typeof itemOptions.link.newTab !== "undefined") && itemOptions.link.newTab == true) {
        var newTab = true;
    } else {
        var newTab = false;
    }

    //console.log('newTab', newTab, dataItem);
    if (newTab == true) {
        var metadataContent = 
            "<span"
            + " title=\"" + linkTooltip 
            + "\" onclick=\"return prepareSharedMetaData('tab', '" + dataItem + "')\">"
            + linkText + '</span>';

    } else {
        var metadataContent = 
            "<span"
            + " title=\"" + linkTooltip 
            + "\" onclick=\"return getDataAndShowMeasurePointDialog('current', '" + dataItem + "'" + ', ' + newTab + ")\">"
            + linkText + '</span>';
    }
    

   return metadataContent;
}

